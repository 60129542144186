import { convertToColumns } from 'pages/Invoices/GenericGrid'

export const syncSettingInit = {
    useAdditionalEncryption: false,
    asyncModeEnabled: false,
    organizationId: null,
    lastSyncDataAsSubject1Utc: null,
    lastSyncDataAsSubject2Utc: null,
    lastSyncDataAsSubject3Utc: null,
    syncRequested: false,
    receptionSyncOnAsSubject1: false,
    receptionSyncOnAsSubject2: false,
    receptionSyncOnAsSubject3: false,
    useWriteSession: false,
    initialReceptionCheckupDate: new Date(Date.UTC(2022, 0, 1)),
    largeWalkingWindowEnabled: false,
    shippingSynchronizationEnabled: false,
  }
  
  export const editPopupTitle = 'Edycja ustawień synchronizacji'
  export const addPopupTitle = 'Dodawanie ustawień synchronizacji'

  export const data = ({t}) => [
    { dataField: "id", caption: t("#_organizationsyncsettings_columns_1"), visible: false },
    { dataField: "lastSyncDataAsSubject1Utc", dataType:"datetime", format: "dd.MM.yyyy HH:mm:ss", caption: t("#_organizationsyncsettings_columns_2"), visible: false },
    { dataField: "lastSyncDataAsSubject2Utc", dataType:"datetime", format: "dd.MM.yyyy HH:mm:ss", caption: t("#_organizationsyncsettings_columns_3"), visible: false },
    { dataField: "lastSyncDataAsSubject3Utc", dataType:"datetime", format: "dd.MM.yyyy HH:mm:ss", caption: t("#_organizationsyncsettings_columns_4"), visible: false },
    { dataField: "organizationNIP", dataType: "string", caption: t("#_organizationsyncsettings_columns_5") },
    { dataField: "organizationName", dataType: "string", caption: t("#_organizationsyncsettings_columns_6") },
    { dataField: "receptionSyncOnAsSubject1", datatype:"boolean", caption: t("#_organizationsyncsettings_columns_7") },
    { dataField: "receptionSyncOnAsSubject2", datatype:"boolean", caption: t("#_organizationsyncsettings_columns_8") },
    { dataField: "receptionSyncOnAsSubject3", datatype:"boolean", caption: t("#_organizationsyncsettings_columns_9") },
    { dataField: "useWriteSession", datatype:"boolean", caption: t("#_organizationsyncsettings_columns_14") },
    { dataField: "asyncModeEnabled", datatype:"boolean", caption: t("#_organizationsyncsettings_columns_10") },
    { dataField: "useAdditionalEncryption", datatype:"boolean", caption: t("#_organizationsyncsettings_columns_11")},
    { dataField: "largeWalkingWindowEnabled", datatype:"boolean", caption: t("#_organizationsyncsettings_columns_12") },
    { dataField: "initialReceptionCheckupDate", dataType:"date", format: "dd.MM.yyyy", caption: t("#_organizationsyncsettings_columns_13") },
    { dataField: "shippingSynchronizationEnabled", datatype:"boolean", caption: t("#_organizationsyncsettings_columns_15") },
]

export const columns = ({t}) => convertToColumns(data({t}))