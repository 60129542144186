import { Form, SimpleItem, Label, GroupItem } from 'devextreme-react/form';
import { useTranslation } from 'react-i18next';
import useFormErrors from 'utils/useFormErrors';
import { useEffect, useState, useRef } from "react"
import { XfXApi } from "../../../api/XfXApi"
import ScrollView from 'devextreme-react/scroll-view';
import notify from "devextreme/ui/notify"
import { commonPopupToolbarItems } from 'components/popup/PopupTools';
import GenericPopup from "components/popup/GenericPopup";

const colCount = 3
const colSpan = 3 

const ContractorManagePopup = ({ 
    setVisible, popupTitle, formRef, fetchcontractors,
    contractorId, setContractorsData, organizationId,
    dataGridContractors, visible, setBlockAutoRefresh }) => {
      
    const { getFirstError, noErrors, setErrors, clearError } = useFormErrors();
    const [euCountryPrefixes, setEuCountryPrefixes] = useState([])
    const [countryCodes, setCountryCodes] = useState([])
    const { t } = useTranslation()
    const [contractor, setContractor] = useState({
      nip: "",
      name: "",
      countryPrefix: 0,
      eoriNo: "",
      email: "",
      phone: "",
      clientNo: "",
      eoriNo: "",
      selfInvoicingContractSigned: false,
      vatueNo: null,
      countryPrefix: null,
      countryCode: null,
      idNo: "",
      withoutTaxId: false,
      organizationId: null,
      address: {
        id: null,
        countryCode: null,
        gln: "",
        adresL1: "",
        adresL2: ""
      },
      correspondenceAddress: {
        id: null,
        countryCode: null,
        gln: "",
        adresL1: "",
        adresL2: ""
      }
    })

    const save = async () => {
      await submit({ contractor: contractor, setContractorsData, dataGridContractors: dataGridContractors, t })
    }
    
    const submit = async({ contractor, setContractorsData, dataGridContractors, t }) => {
      try {
        contractor.organizationId = organizationId;
        const response = contractorId ?
          await XfXApi.Contractor.apiTenantIdContractorIdPut(contractorId, XfXApi.GetTenantId(), contractor) :
          await XfXApi.Contractor.apiTenantIdContractorPost(XfXApi.GetTenantId(), contractor)
        if (response.status === 200) {
          notify({
            message: response.data.messages[0],
            position: {
              my: 'center bottom',
              at: 'center bottom',
            },
          }, 'success', 5000);
          popup.close()
        }
      } catch (error) {
        if (error.response.status === 400) {
          notify({
            message: error.response.data.messages[0],
            position: {
              my: 'center bottom',
              at: 'center bottom',
            },
          }, 'error', 10000);
        }
        else if (error.response.status === 422) {
          setErrors(error.response.data);
        }
      }
      finally {
        await fetchcontractors({ setContractorsData })
        dataGridContractors.current.instance.clearSelection()
      }
    }

    const formFieldDataChanged = (e) => {
        if (e.dataField) {
          clearError(e.dataField);
        }
    }

    useEffect(() => {
      const loadData = async () => {
        const countryCodesRes = await XfXApi.Dictionary.apiTenantIdDictionaryCountryCodesGet(XfXApi.GetTenantId());
        const euPrefixesRes = await XfXApi.Dictionary.apiTenantIdDictionaryEuCountryPrefixesGet(XfXApi.GetTenantId());
        
        setEuCountryPrefixes(euPrefixesRes.data);
        setCountryCodes(countryCodesRes.data);

        setContractor(oldVal => ({ 
          ...oldVal, 
          countryPrefix: null,
          address: {
            ...oldVal.address,
            countryCode: null,
          },
          correspondenceAddress: {
            ...oldVal.correspondenceAddress,
            countryCode: null,
          }
        }))
        
        if (contractorId)
        {
          XfXApi.Contractor.apiTenantIdContractorGet(XfXApi.GetTenantId(), contractorId).then(cont => setContractor(cont.data));
        }
      };
    
      loadData();
      popup.show();
    }, [])

    const popupToolbarItems = commonPopupToolbarItems({ onSave: () => save(), onCancel: () => popup.close(), t: t })
    const popup = GenericPopup({ 
      onHiding: () => popup.close(),
      content:
      <div>
        <ScrollView>
              <Form
                  id="form"
                  ref={formRef}
                  labelMode="static"
                  showColonAfterLabel={false}
                  labelLocation="left"
                  minColWidth={300}
                  colCount={colCount}
                  onFieldDataChanged={formFieldDataChanged}
                  formData={contractor}>
                <GroupItem caption={t("#_contractorspopup_28")} colCount={colCount} colSpan={colSpan}>
                  <SimpleItem dataField="email" editorType="dxTextBox" value={contractor.email}
                    editorOptions={{
                      validationErrors: getFirstError("email"),
                      isValid: noErrors("email")
                    }}>
                    <Label text={t("#_contractorspopup_3")} />
                  </SimpleItem>
                  <SimpleItem dataField="phone" editorType="dxTextBox" value={contractor.phone}>
                    <Label text={t("#_contractorspopup_4")} />
                  </SimpleItem>
                  <SimpleItem dataField="clientNo" editorType="dxTextBox" value={contractor.clientNo}>
                    <Label text={t("#_contractorspopup_5")} />
                  </SimpleItem>
                  <SimpleItem dataField="eoriNo" editorType="dxTextBox" value={contractor.eoriNo}>
                    <Label text={t("#_contractorspopup_6")} />
                  </SimpleItem>
                  <SimpleItem dataField="selfInvoicingContractSigned" editorType="dxCheckBox" value={contractor.selfInvoicingContractSigned}>
                    <Label text={t("#_contractorspopup_7")} />
                  </SimpleItem>
                  <SimpleItem dataField="isSupplier" editorType="dxCheckBox" value={contractor.isSupplier}>
                    <Label text={t("#_contractorspopup_30")} />
                  </SimpleItem>
                  <SimpleItem dataField="isRecipient" editorType="dxCheckBox" value={contractor.isRecipient}>
                    <Label text={t("#_contractorspopup_31")} />
                  </SimpleItem>
                </GroupItem>
                <GroupItem caption={t("#_contractorspopup_11")} colCount={colCount} colSpan={colSpan}>
                  <SimpleItem dataField="nip" editorType="dxTextBox" value={contractor.nip}
                      editorOptions={{
                        validationErrors: getFirstError("nip"),
                        isValid: noErrors("nip")
                      }}
                      cssClass="xfx-required"
                    >
                      <Label text={t("#_contractorspopup_12")} />
                    </SimpleItem>
                    <SimpleItem dataField="countryPrefix" editorType="dxSelectBox" value={contractor.countryPrefix} 
                        editorOptions={{
                          key: "key",
                          valueExpr: "key",
                          displayExpr: "value",
                          dataSource: euCountryPrefixes,
                          value: contractor.countryPrefix
                        }}>
                        <Label text={t("#_contractorspopup_13")} />
                    </SimpleItem>
                    <SimpleItem dataField="vatueNo" editorType="dxTextBox" value={contractor.vatueNo}>
                      <Label text={t("#_contractorspopup_14")} />
                    </SimpleItem>
                    <SimpleItem dataField="countryCode" editorType="dxSelectBox" value={contractor.countryCode} 
                      editorOptions={{
                        key: "key",
                        valueExpr: "key",
                        displayExpr: "value",
                        dataSource: countryCodes,
                        value: contractor.countryCode
                      }}>
                      <Label text={t("#_contractorspopup_15")} />
                    </SimpleItem>
                    <SimpleItem dataField="idNo" editorType="dxTextBox" value={contractor.idNo}>
                      <Label text={t("#_contractorspopup_16")} />
                    </SimpleItem>
                    <SimpleItem dataField="withoutTaxId" editorType="dxCheckBox" value={contractor.withoutTaxId}>
                      <Label text={t("#_contractorspopup_17")} />
                    </SimpleItem>
                    <SimpleItem dataField="name" editorType="dxTextBox" value={contractor.name}
                      editorOptions={{
                        validationErrors: getFirstError("name"),
                        isValid: noErrors("name")
                      }}
                      cssClass="xfx-required"
                      >
                      <Label text={t("#_contractorspopup_18")} />
                    </SimpleItem>
                   </GroupItem>
                   <GroupItem caption={t("#_contractorspopup_19")} colCount={2} colSpan={3}>
                    <SimpleItem dataField="address.countryCode" editorType="dxSelectBox" value={contractor.address.countryCode} 
                      editorOptions={{
                        key: "key",
                        valueExpr: "key",
                        displayExpr: "value",
                        dataSource: countryCodes,
                        value: contractor.address.countryCode
                      }}>
                      <Label text={t("#_contractorspopup_10")} />
                    </SimpleItem>
                    <SimpleItem dataField="address.gln" editorType="dxTextBox" value={contractor.address.gln}>
                      <Label text={t("#_contractorspopup_20")} />
                    </SimpleItem>
                    <SimpleItem dataField="address.adresL1" 
                      editorType="dxTextArea" 
                      editorOptions={{ height: 150, maxLength: 512 }} 
                      value={contractor.address.adresL1}>
                      <Label text={t("#_contractorspopup_21")} />
                    </SimpleItem>
                    <SimpleItem dataField="address.adresL2" 
                      editorType="dxTextArea" 
                      editorOptions={{ height: 150, maxLength: 512 }}  
                      value={contractor.address.adresL2}>
                      <Label text={t("#_contractorspopup_22")} />
                    </SimpleItem>
                  </GroupItem>
                    
                  <GroupItem caption={t("#_contractorspopup_23")} colCount={2} colSpan={3}>
                    <SimpleItem dataField="correspondenceAddress.countryCode" editorType="dxSelectBox" value={contractor.correspondenceAddress.countryCode} 
                      editorOptions={{
                        key: "key",
                        valueExpr: "key",
                        displayExpr: "value",
                        dataSource: countryCodes,
                        value: contractor.correspondenceAddress.countryCode
                      }}>
                      <Label text={t("#_contractorspopup_24")} />
                    </SimpleItem>
                    <SimpleItem dataField="correspondenceAddress.gln" editorType="dxTextBox" value={contractor.correspondenceAddress.gln}>
                      <Label text={t("#_contractorspopup_25")} />
                    </SimpleItem>
                    <SimpleItem dataField="correspondenceAddress.adresL1" 
                      editorType="dxTextArea" 
                      editorOptions={{ height: 150, maxLength: 512 }} 
                      value={contractor.correspondenceAddress.adresL1}>
                      <Label text={t("#_contractorspopup_26")} />
                    </SimpleItem>
                    <SimpleItem dataField="correspondenceAddress.adresL2" 
                      editorType="dxTextArea" 
                      editorOptions={{ height: 150, maxLength: 512 }}
                      value={contractor.correspondenceAddress.adresL2}>
                      <Label text={t("#_contractorspopup_27")} />
                    </SimpleItem>
                  </GroupItem>
                </Form>
                </ScrollView>
      </div>,
      toolbarItems: popupToolbarItems,
      title: popupTitle,
      width: "1250px",
      height: "750px",
      isVisible: visible,
      setIsVisible: setVisible,
      setBlockAutoRefresh: setBlockAutoRefresh
    })

    return (
        <>
            {popup.popup}
        </>
    )
}

export default ContractorManagePopup
