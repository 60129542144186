import { XfXApi } from "api/XfXApi";
import { AxiosResponse } from "axios";
import { transformKeysToStartWithUpperCase } from "../Editor/helpers";
import { EditorTokensSource, EditorType, InvoiceDateType } from "../Editor/model";
import { GetCustomTokens } from "../Editor/EditorOperations/GetCustomTokens";
import { InvoiceEditDto } from "api/openapi-invoice";
import { TOKENS } from "../Editor/customTokens";
import { OrganizationDto } from "api/openapi";
import { extractAndRemovePrefix } from "../Editor/recordHelpers";

export interface GetTokensReturn {

    editableTokens: Record<string, any>,
    beforeTokens: Record<string, any> | null
    orginalType: EditorType,

}

export const GetTokens = async (organization: OrganizationDto | null,
    processId: string | null, // for tokenSource === Invoice this is invoiceId; for tokenSource === Draft this is draftId
    editorType: EditorType, tokensSource: EditorTokensSource)
    : Promise<GetTokensReturn | undefined> => {

    if (organization === null) {
        return undefined
    }
    const organizationId = organization.nip!
    if (processId === null || processId === '') {
        const tokens = {
            ...defaultInvoiceValues(editorType, organization),
            TOKEN_CorrectionNoRowsMode: true
        }
        setOrganizationData(tokens, organization, "Podmiot1")
        return {
            editableTokens: tokens,
            beforeTokens: null,
            orginalType: editorType
        }
    }

    let response: AxiosResponse<InvoiceEditDto, any>
    try {
        switch (tokensSource) {
            case EditorTokensSource.Draft:
                response = await XfXApi.DraftApi.apiTenantIdOrganizationIdInvoicedraftIdGet(processId, XfXApi.GetTenantId(), organizationId)
                break;
            case EditorTokensSource.Invoice:
                response = await XfXApi.EditorApi.apiTenantIdOrganizationIdEditorGet(XfXApi.GetTenantId(), organizationId, processId.toString())
                break;
            case EditorTokensSource.NewInvoice:
                response = await XfXApi.EditorApi.apiTenantIdOrganizationIdEditorNewGet(XfXApi.GetTenantId(), organizationId)
                break
            default:
                return undefined
        }

    } catch (error) {
        console.error(error)
        return undefined
    }

    if (!response.data.tokens) {
        return undefined
    }

    const tokensFromResponse = transformKeysToStartWithUpperCase(response.data.tokens)


    if (tokensSource === EditorTokensSource.Draft) {
        // this is a draft, so we need to return only tokens

        // workaround for KOR without invoice in KSeF
        const keys = Object.keys(tokensFromResponse);
        if (tokensFromResponse["Fa.RodzajFaktury"] === "KOR"
            && !keys.some(x => x === TOKENS._SourceCorrectionId)
            && !keys.some(x => x.startsWith("Fa.DaneFaKorygowanej"))) {
            tokensFromResponse["Fa.DaneFaKorygowanej[0].DataWystFaKorygowanej"] = undefined
        }

        let orginalType = editorType
        let editableTokens = tokensFromResponse
        // todo: extract to function
        if (editorType === EditorType.Unknown && tokensFromResponse !== undefined) {
            if (tokensFromResponse[TOKENS._EditorType] !== undefined) {
                orginalType = tokensFromResponse[TOKENS._EditorType] as EditorType
            } else {
                editableTokens = {
                    ...tokensFromResponse,
                    ...GetCustomTokens(tokensFromResponse)
                }
            }
        }
        const beforeTokens = getBeforeTokens(tokensFromResponse, orginalType, tokensSource)
        return {
            editableTokens,
            beforeTokens,
            orginalType
        }
    }

    // this is draft creation
    const defaultTokensForEditor = defaultInvoiceValues(editorType, organization)

    const originInvoiceType = tokensFromResponse["Fa.RodzajFaktury"]
    const xmlTokens = { ...tokensFromResponse, ...defaultTokensForEditor, }
    const customTokens = GetCustomTokens(xmlTokens)
    const tokens: { [key: string]: any; } = { ...xmlTokens, ...customTokens }
    const tokensKeys = Object.keys(tokens)

    let beforeTokens = null
    if (editorType === EditorType.Correction || editorType === EditorType.CorrectionSelfInvoice) {
        if ("Fa.P_1" in tokens) delete tokens["Fa.P_1"]
        if ("Fa.P_2" in tokens) delete tokens["Fa.P_2"]
        beforeTokens = { ...tokens }
        if ("_ksefiks.KsefNumber" in tokens) {
            tokens["_Fa.DaneFaKorygowanej[0]."] = "TAK"
            if (originInvoiceType === "VAT") {
                /// set correction values for VAT invoice in KSeF
                tokens["Fa.DaneFaKorygowanej[0].NrKSeFFaKorygowanej"] = tokens["_ksefiks.KsefNumber"]
                tokens["Fa.DaneFaKorygowanej[0].NrFaKorygowanej"] = tokens["_ksefiks.InvoiceNumber"]
                tokens["Fa.DaneFaKorygowanej[0].DataWystFaKorygowanej"] = tokens["_ksefiks.KsefDate"]
                tokens["Fa.DaneFaKorygowanej[0].NrKSeF"] = 1
            }
        }

        tokens[TOKENS._SourceCorrectionId] = processId?.toString() // set corrected invoice id

        if (tokens[TOKENS._CorrectionNoRowsMode] === undefined) {
            tokens[TOKENS._CorrectionNoRowsMode] = true
        }
    }


    if (editorType === EditorType.Correction) {
        setOrganizationData(tokens, organization, "Podmiot1")
    }
    if (editorType === EditorType.CorrectionSelfInvoice) {
        tokens["_kefiks.wystawca"] = "Samofakturowanie_Nabywca"
        setOrganizationData(tokens, organization, "Podmiot2")
    }
    if (tokens[TOKENS._EditorType] === undefined) {
        tokens[TOKENS._EditorType] = editorType
    }



    return {
        editableTokens: tokens,
        beforeTokens,
        orginalType: editorType
    }
}

const getBeforeTokens = (tokens: { [key: string]: any; }, orginalType: EditorType, tokensSource: EditorTokensSource) => {
    return (orginalType === EditorType.Correction || orginalType === EditorType.CorrectionSelfInvoice) ?
        (tokensSource === EditorTokensSource.Invoice ?
            tokens :
            extractAndRemovePrefix(tokens)) :
        null
}


const defaultInvoiceValues = (editorType: EditorType, organization: OrganizationDto) => {

    let ret: any = {}

    if (editorType === EditorType.Correction) {
        ret = {
            "Fa.RodzajFaktury": "KOR",
        }
    }
    if (editorType === EditorType.CorrectionSelfInvoice) {
        ret = {
            "Fa.RodzajFaktury": "KOR",
        }
    }
    if (editorType === EditorType.Unknown) {
        return {}
    }

    if (editorType === EditorType.Vat) {
        ret = {
            "Fa.RodzajFaktury": "VAT",
            "Fa.KodWaluty": "PLN",
            "Naglowek.SystemInfo": "",
            "_kefiks.nettobrutto": "netto",
            "_kefiks.wystawca": "Sprzedawca",
            "Fa.Adnotacje.P_16": "2",
            "Fa.Adnotacje.P_18": "2",
            "Fa.Adnotacje.P_18A": "2",
            "Fa.Adnotacje.P_17": "2",
            "Fa.Adnotacje.P_23": "2",
            "Fa.Adnotacje.NoweSrodkiTransportu.P_42_5": "2",

            // workaround for KOR without invoice in KSeF
            "Fa.DaneFaKorygowanej[0].DataWystFaKorygowanej": undefined,
        }
        ret[TOKENS._Fa_P_6] = InvoiceDateType.Common
        if (organization.nip) ret["Podmiot1.DaneIdentyfikacyjne.NIP"] = organization.nip;
    }

    return ret
}

export const setOrganizationData = (tokens: { [key: string]: any; }, organization: OrganizationDto, tokenPrefix: string) => {
    tokens[tokenPrefix + ".DaneIdentyfikacyjne.NIP"] = organization.nip;
    // if (organization.fullName)
    tokens[tokenPrefix + ".DaneIdentyfikacyjne.Nazwa"] = organization.fullName;
    // if (organization.eoriNo)
    tokens[tokenPrefix + ".NrEORI"] = organization.eoriNo;
    // if (organization.countryPrefixStr)
    tokens[tokenPrefix + ".PrefiksPodatnika"] = organization.countryPrefixStr;

    if (organization.address) {
        // if (organization.address.countryCodeStr)
        tokens[tokenPrefix + ".Adres.KodKraju"] = organization.address.countryCodeStr;
        // if (organization.address.adresL1)
        tokens[tokenPrefix + ".Adres.AdresL1"] = organization.address.adresL1;
        // if (organization.address.adresL2)
        tokens[tokenPrefix + ".Adres.AdresL2"] = organization.address.adresL2;
        // if (organization.address.gln)
        tokens[tokenPrefix + ".Adres.GLN"] = organization.address.gln;
    } else {
        tokens[tokenPrefix + ".Adres.KodKraju"] = null;
        tokens[tokenPrefix + ".Adres.AdresL1"] = null;
        tokens[tokenPrefix + ".Adres.AdresL2"] = null;
        tokens[tokenPrefix + ".Adres.GLN"] = null;
    }

    if (organization.correspondenceAddress) {
        // if (organization.correspondenceAddress.countryCodeStr)
        tokens[tokenPrefix + ".AdresKoresp.KodKraju"] = organization.correspondenceAddress.countryCodeStr;
        // if (organization.correspondenceAddress.adresL1)
        tokens[tokenPrefix + ".AdresKoresp.AdresL1"] = organization.correspondenceAddress.adresL1;
        // if (organization.correspondenceAddress.adresL2)
        tokens[tokenPrefix + ".AdresKoresp.AdresL2"] = organization.correspondenceAddress.adresL2;
        // if (organization.correspondenceAddress.gln)
        tokens[tokenPrefix + ".AdresKoresp.GLN"] = organization.correspondenceAddress.gln;
    } else {
        tokens[tokenPrefix + ".AdresKoresp.KodKraju"] = null;
        tokens[tokenPrefix + ".AdresKoresp.AdresL1"] = null;
        tokens[tokenPrefix + ".AdresKoresp.AdresL2"] = null;
        tokens[tokenPrefix + ".AdresKoresp.GLN"] = null;
    }
}