import { copyToClipboard } from 'utils/clipboard'
import axios from "axios";
import { InvoicesEndpoints, XfXApi } from '../../api/XfXApi'
import notify from 'devextreme/ui/notify';

export const CreateArchiveExtensions = {
  Xml: 0,
  Pdf: 1,
}

export const JobCreateArchiveType = {
  UPO: 0,
  Invoice: 1
}

export const copySessionId = (sessionId) => {
  if (sessionId)
    copyToClipboard(sessionId, 'Identyfikator sesji został skopiowany do schowka')
}

export const copyKsefNumber = (number, t) => {
  if (number)
    copyToClipboard(number, t("#_invoicegridactions_1"))
}

export const GetUpoOnDemand = async (organizationId, t) => {
  try 
  {
    await XfXApi.InvoiceApi.apiTenantIdOrganizationIdInvoicesGetUpoOnDemandGet(XfXApi.GetTenantId(), organizationId);
    notify({
      message: t("#_invoicegridactions_upoOnDemandSuccess"),
      position: {
        my: 'center bottom',
        at: 'center bottom',
      },
    }, 'success', 15000);
  }
  catch (ex)
  {
    notify({
      message: t("#_invoicegridactions_upoOnDemandFailure"),
      position: {
        my: 'center bottom',
        at: 'center bottom',
      },
    }, 'error', 10000);
  }
}

export async function MarkAsReceived({ t, organizationId, invoiceId, currentReceivedFlag, reloadGrid }) {
  try
  {
    await XfXApi.InvoiceApi.apiTenantIdOrganizationIdInvoicesSetReceivedPut(
      XfXApi.GetTenantId(), organizationId, [{ invoiceId: invoiceId, receivedByExternalSystem: !currentReceivedFlag }]
    )
    const message = currentReceivedFlag ? t("#_invoicemarkasreceived_1") : t("#_invoicemarkasreceived_2")
    notify({
      message: message,
      position: {
        my: 'center bottom',
        at: 'center bottom',
      },
    }, 'success', 5000);
    await reloadGrid();
  }
  catch (ex)
  {
    notify({
      message: t("#_invoicemarkasreceived_3"),
      position: {
        my: 'center bottom',
        at: 'center bottom',
      },
    }, 'error', 5000);
  }
}

export async function ResendInvoice({ t, organizationId, invoiceId, reloadGrid }) {
  try
  {
    await XfXApi.InvoiceApi.apiTenantIdOrganizationIdInvoicesResendPost(
      XfXApi.GetTenantId(), organizationId, invoiceId
    )
    notify({
      message: t("#_invoicemarkasreceived_send_success"),
      position: {
        my: 'center bottom',
        at: 'center bottom',
      },
    }, 'success', 5000);
    await reloadGrid();
  }
  catch (ex)
  {
    if (ex.response.status == 400) {
      notify({
        message: `${ex?.response?.data?.XfxDetails?.join(', ')}`,
        position: {
          my: 'center bottom',
          at: 'center bottom',
        },
      }, 'error', 5000);
    }
    else {
      notify({
        message: t("#_const_50"),
        position: {
          my: 'center bottom',
          at: 'center bottom',
        },
      }, 'error', 5000);
    }
  }
}

export async function GetPdf({ organizationId, invoiceId, sales = true, t, ksefNumber, subject2VatNumber }) {
  const url = (sales ? InvoicesEndpoints.DispatchPdf(organizationId) : InvoicesEndpoints.ReceptionPdf(organizationId)) + "?invoiceId=" + invoiceId

  await axios
    .request({
      url: url,
      method: "GET",
      responseType: 'blob', //important
    })
    .then((response) => {
      if (response.status !== 200) return
      const downloadUrl = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = downloadUrl;
      link.setAttribute('download', (ksefNumber ? ksefNumber : (subject2VatNumber ? subject2VatNumber + "_" + invoiceId : invoiceId)) + "_fv.pdf");

      document.body.appendChild(link);
      link.click();
      link.remove();
    })
    .catch(() => {
      notify({
        message: t("#_invoicegridactions_4"),
        position: {
          my: 'center bottom',
          at: 'center bottom',
        },
      }, 'error', 3000);
    })
}

export const GetPdfUrl = ({ organizationId, invoiceId, sales = true, asHtml = false }) => {
  if (asHtml) {
    return (sales ? InvoicesEndpoints.DispatchHtml(organizationId) : InvoicesEndpoints.ReceptionHtml(organizationId)) + "?invoiceId=" + invoiceId
  } else {
    return (sales ? InvoicesEndpoints.DispatchPdf(organizationId) : InvoicesEndpoints.ReceptionPdf(organizationId)) + "?invoiceId=" + invoiceId
  }
}

export async function OpenPdf({ organizationId, invoiceId, sales = true }) {
  await GetInvoiceFileAsBlob({
    organizationId,
    invoiceId, sales: sales, setObjectUrl: fileURL => {
      const pdfWindow = window.open();
      pdfWindow.location.href = fileURL;
    }
  })
}

export const GetInvoiceFileAsBlob = async ({ organizationId, invoiceId, sales = true, setObjectUrl, asHtml = false, t }) => {
  const url = GetPdfUrl({ organizationId, invoiceId, sales, asHtml })

  await GetPdfAndSetObjectUrl(url, setObjectUrl, t);
}

export const GetUpoFileAsBlob = async ({ organizationId, invoiceId, setObjectUrl, t }) => {
  const url = InvoicesEndpoints.UpoPdf(organizationId) + "?invoiceId=" + invoiceId;

  await GetPdfAndSetObjectUrl(url, setObjectUrl, t);
}

const GetPdfAndSetObjectUrl = async (url, setObjectUrl, t) =>
{
  await axios
    .request({
      url: url,
      method: "GET",
      responseType: 'blob', //important
    })
    .then((response) => {
      if (response.status !== 200) return

      const type = response.headers["content-type"]
      const file = new Blob([response.data], { type: type });
      //Build a URL from the file
      const fileURL = URL.createObjectURL(file);

      setObjectUrl(fileURL)
    })
    .catch(() => {
      notify({
        message: t("#_invoicegridactions_5"),
        position: {
          my: 'center bottom',
          at: 'center bottom',
        },
      }, 'error', 3000);
    })
  }

  export const CreateArchive = async ({ organizationId, selectedCreateArchiveData, createArchiveType,extension, t, gridId }) => {

    var source
    if (gridId === 'RepositorySales')
        source = 'SaleRepo'
    else if (gridId === 'RepositoryPurchase')
        source = 'PurchaseRepo'
    else if (gridId === 'RepositoryOther')
        source = 'OtherRepo'
    else
        source = gridId

    const parameters = createArchiveType === JobCreateArchiveType.Invoice 
                        ? selectedCreateArchiveData.map(x => ({ targetId: x.Id,
                                                                IsDispatch: x.IsDispatch,
                                                                resultFileName: (x.KsefNumber ? x.KsefNumber : (x.Subject2VatNumber ? x.Subject2VatNumber + "_" + x.Id : x.Id)) + "_fv",
                                                        }))
                        : selectedCreateArchiveData.filter(x => x.IsDispatch).map(x => ({ targetId: x.Id,
                                                                                      IsDispatch: true,
                                                                                                  resultFileName: (x.KsefNumber ? x.KsefNumber : (x.Subject2VatNumber ? x.Subject2VatNumber + "_" + x.Id : x.Id)) + "_upo",
                                                                                                }))

    const postJobCreateArchiveDto = {
      parameters: parameters,
      extension: extension,
      archiveType: createArchiveType,
    }
    

    await XfXApi.InvoiceApi.apiTenantIdOrganizationIdInvoicesCreatearchivePost(XfXApi.GetTenantId(), organizationId, source, postJobCreateArchiveDto)
            .then(x => {
              notify({
                message: t("#_job-create-archive-init-success"),
                position: {
                  my: 'center bottom',
                  at: 'center bottom',
                },
              }, 'success', 5000);
            })
            .catch(e => {
              notify({
                message: t("#_job-create-archive-init-failure"),
                position: {
                  my: 'center bottom',
                  at: 'center bottom',
                },
              }, 'error', 5000);
            }) 
  }