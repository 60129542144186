import { XfXApi } from "api/XfXApi";
import notify from "devextreme/ui/notify";

export const sendDraft = async (draftId: string, organizationId: string, onSuccess: () => void,
        getOrganizationFullNameByNip: (nip: string) => string | null,
        t: (id: string) => string): Promise<boolean> => {
        try {
                const response = await XfXApi.DraftApi.apiTenantIdOrganizationIdInvoicedraftSendIdPut(
                        draftId,
                        XfXApi.GetTenantId(),
                        organizationId
                );

                if (200 <= response.status && response.status < 300) {
                        notify("Faktura została przekazana do wysyłki", "success", 2000);
                        onSuccess();
                        return true;
                }
        } catch (error: any) {
                if (error.response?.status === 403) {
                        const organizationName = getOrganizationFullNameByNip(organizationId);
                        notify(`Faktura została zapisana w Faktury robocze organizacji ${organizationName}. Zaloguj się do organizacji, aby kontynuować wysyłkę.`, "warning", 4000);
                } else {
                        notify(error.response?.data?.XfxDetails?.join(', ') || "Błąd podczas wysyłki", "error", 2000);
                }
                return false;
        }

        return false; // Tylko jeśli warunek 200 nie zostanie spełniony
};
