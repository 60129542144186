import { useState } from "react"
import GenericPopup from "components/popup/GenericPopup"
import { useTranslation } from 'react-i18next';

export const InvoicePreview = (setBlockAutoRefresh) => {
    const { t } = useTranslation()
    const [pdfUrl, setPdfUrl] = useState()
    const [isVisible, setIsVisible] = useState(false)

    const popup = GenericPopup({
        onHiding: () => popup.close(),
        buttons: [{
            text: t("#_invoicepreview_1"),
            location: "after",
            action: popup => popup.close()
        }],
        title: "Podgląd FV",
        width: "80%",
        height: "80%",
        content: <iframe title="pdfPreview" src={pdfUrl} width="100%" height="500px" frameBorder="0"></iframe>,
        isVisible: isVisible,
        setIsVisible: setIsVisible,
        setBlockAutoRefresh: setBlockAutoRefresh
    })

    const showInvoicePreview = (content) => {
        setPdfUrl(content)
        popup.show()
    }

    return { popup: popup.popup, showInvoicePreview: showInvoicePreview }
}
