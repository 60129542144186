import React, { useEffect, useMemo, useState, useRef } from 'react';
import DataGrid, {
    Column, Editing, RowDragging, Lookup, Format, Texts,
    ColumnChooser, ColumnChooserSearch, ColumnChooserSelection, Position, Scrolling
} from 'devextreme-react/data-grid';
import { Fa1InvoiceItem } from "./Fa1InvoiceItem";
import { CheckBox } from 'devextreme-react';
import { Button } from 'devextreme-react/button';
import { Template } from 'devextreme-react/core/template';
import { FieldsVisibilityBeforeCor, CorrectionRowsEditorProps, RowsSummary, FieldsVisibility } from './model';
import { columns, columnsBefore } from './rowDataGridColumns';
import { summaryContainer, lpColumnRender } from './RowsEditorCommon';

// helper functions
const cloneInvoiceItems = (invoiceItems: Fa1InvoiceItem[], editMode: boolean): Fa1InvoiceItem[] => {
    let invoiceItemsCloned: Fa1InvoiceItem[];
    if (editMode) {
        invoiceItemsCloned = structuredClone(invoiceItems);
    }
    else {
        let idCounter = 0;
        invoiceItemsCloned = structuredClone(invoiceItems.filter(x => x.stanPrzed !== true))
        invoiceItemsCloned.forEach(x => {
            x.ID = idCounter.toString();
            idCounter++;
        });
    }

    invoiceItemsCloned.forEach(obj => Object.setPrototypeOf(obj, Fa1InvoiceItem.prototype));

    return invoiceItemsCloned;
}

const CorrectionRowsEditor: React.FC<CorrectionRowsEditorProps> = ({ editMode, invoiceItems, setInvoiceItems, showP_6A, showCurrency,
    setShowOssForBeforeCor, setShowOssForAfterCor, showOssForBeforeCor, showOssForAfterCor, showNetto, initShowNetto, correctedInvIsInKsefix, clearRowsTemp,
    noRowsMode, setNoRowsMode, isReadOnly }) => {

    // INIT
    const firstRender = useRef(true); // to stop some useEffects on first render
    const beforeCorDataGridRef = useRef(null)
    const afterCorDataGridRef = useRef(null)

    const [summaryBeforeCor, setSummaryBeforeCor] = useState<RowsSummary>({ netto: 0, vat: 0, brutto: 0, vatInPln: 0 });
    const [summaryAfterCor, setSummaryAfterCor] = useState<RowsSummary>({ netto: 0, vat: 0, brutto: 0, vatInPln: 0 });
    const [beforeCorrectionRows, setBeforeCorrectionRows] = useState<Fa1InvoiceItem[]>([]);
    const [afterCorrectionRows, setAfterCorrectionRows] = useState<Fa1InvoiceItem[]>([]);
    const [columnsRefreshKey, setColumnsRefreshKey] = useState<number>(0);
    const [columnVisibilityAfterCor, setColumnVisibilityAfterCor] = useState<FieldsVisibility>({
        rabatOpust: false,
        indeks: false,
        towarUsługaZal15: false,
        GTU: false,
        PKWiU: false,
        GTIN: false,
        CN: false,
        PKOB: false,
        procedura: false,
        kwotaPodatkuAkcyzowego: false,
        kwotaPodatku: false
    });
    const [columnVisibilityBeforeCor, setColumnVisibilityBeforeCor] = useState<FieldsVisibilityBeforeCor>({
        rabatOpust: false,
        indeks: false,
        towarUsługaZal15: false,
        GTU: false,
        PKWiU: false,
        GTIN: false,
        CN: false,
        PKOB: false,
        procedura: false,
        kwotaPodatkuAkcyzowego: false,
        kwotaPodatku: false,
        P_6A: false,
        cenaJednostkowaNetto: false,
        cenaJednostkowaBrutto: false,
        wartoscSprzedazyNetto: false,
        wartoscSprzedazyBrutto: false,
        stawkaPodatku: false,
        stawkaPodatkuXII: false,
        kursWaluty: false
    });
    // INIT

    const updateIds = (rows: Fa1InvoiceItem[], stanPrzed: boolean) => {
        for (let index = 0; index < rows.length; index++) {
            const id = index.toString();
            rows[index].ID = id;
            rows[index].lp = stanPrzed ? Number(id) * 2 + 1 : Number(id) * 2 + 2;
        }
        return rows
    }

    const createEmptyRow = (stanPrzed: boolean, resetIdTemp: boolean): Fa1InvoiceItem => {
        let id = 0
        if (!resetIdTemp) {
            if (stanPrzed) {
                id = beforeCorrectionRows.length;
            }
            else {
                id = afterCorrectionRows.length
            }
        }
        const newProduct = new Fa1InvoiceItem(
            {
                ID: id.toString(),
                lp: stanPrzed ? id * 2 + 1 : id * 2 + 2,
                nazwaTowaruLubUslugi: '',
                ilosc: 0,
                miara: '',
                cenaJednostkowaNetto: 0.00,
                cenaJednostkowaBrutto: 0.00,
                rabatOpust: 0.00,
                wartoscSprzedazyNetto: 0.00,
                wartoscSprzedazyBrutto: 0.00,
                kwotaPodatku: 0.00,
                stawkaPodatku: "",
                stawkaPodatkuXII: '',
                indeks: '',
                towarUsługaZal15: false,
                procedura: '',
                GTU: '',
                PKWiU: '',
                GTIN: '',
                CN: '',
                kwotaPodatkuAkcyzowego: 0,
                PKOB: '',
                kursWaluty: 0,
                P_6A: null,
                stanPrzed: stanPrzed
            })


        const dataGridRef = stanPrzed ? beforeCorDataGridRef : afterCorDataGridRef;
        // @ts-ignore
        const kwotaPodatkuInfo = dataGridRef?.current?.instance?.columnOption('kwotaPodatku');
        const updateKwotaPodatku = kwotaPodatkuInfo?.visible;
        newProduct.Calculate(showNetto, updateKwotaPodatku ?? false);

        return newProduct;
    }

    const updateInvoiceItems = (invoiceItemsBeforeCor: Fa1InvoiceItem[], invoiceItemsAfterCor: Fa1InvoiceItem[]) => {
        const combinedArray = [...invoiceItemsBeforeCor, ...invoiceItemsAfterCor];
        combinedArray.sort((obj1, obj2) => obj1.lp - obj2.lp);
        setInvoiceItems(combinedArray);
    }

    const handleColumnHidden = (
        columnNumberMatch: RegExpMatchArray, columnsArr: any, rows: Fa1InvoiceItem[], setRows: (items: Fa1InvoiceItem[]) => void, oppositeRows: Fa1InvoiceItem[],
        setColumnVisibility: (param: any) => void
    ) => {
        const columnNumber = parseInt(columnNumberMatch[1], 10);
        const column = columnsArr[columnNumber];
        setColumnVisibility((old: any) => ({
            ...old,
            [column.dataField]: false
        }));

        const updatedItems: Fa1InvoiceItem[] = rows.map(item =>
            Object.setPrototypeOf(({
                ...item,
                [column.dataField]: (item as any)[column.dataField] === false || (item as any)[column.dataField] === true ? false : null
            }), Fa1InvoiceItem.prototype)
        );
        if (column.dataField === "kwotaPodatku") {
            updatedItems.forEach(x => x.Calculate(showNetto, false))
        }
        setRows(updatedItems)
        updateInvoiceItems(oppositeRows, updatedItems)
    }

    const handleColumnShown = (
        columnNumberMatch: RegExpMatchArray, columnsArr: any, rows: Fa1InvoiceItem[], setRows: (items: Fa1InvoiceItem[]) => void, oppositeRows: Fa1InvoiceItem[],
        setColumnVisibility: (param: any) => void
    ) => {
        const columnNumber = parseInt(columnNumberMatch[1], 10);
        const column = columnsArr[columnNumber];
        setColumnVisibility((old: any) => ({
            ...old,
            [column.dataField]: true
        }));

        if (column.dataField === "kwotaPodatku") {
            const updatedItems: Fa1InvoiceItem[] = rows.map(item =>
                Object.setPrototypeOf(({ ...item }), Fa1InvoiceItem.prototype)
            );
            updatedItems.forEach(x => x.Calculate(showNetto, true))
            setRows(updatedItems)
            updateInvoiceItems(oppositeRows, updatedItems)
        }
    }
    // helper functions

    // next useEffects
    useEffect(() => {
        if (firstRender.current)
            return;

        if (showOssForAfterCor === true) {
            const updatedItems: Fa1InvoiceItem[] = afterCorrectionRows.map(item =>
                Object.setPrototypeOf(({
                    ...item,
                    stawkaPodatku: null
                }), Fa1InvoiceItem.prototype));
            setAfterCorrectionRows(updatedItems)
            updateInvoiceItems(beforeCorrectionRows, updatedItems)
        }
        else {
            const updatedItems: Fa1InvoiceItem[] = afterCorrectionRows.map(item =>
                Object.setPrototypeOf(({
                    ...item,
                    stawkaPodatkuXII: null,
                    towarUsługaZal15: null
                }), Fa1InvoiceItem.prototype));
            setAfterCorrectionRows(updatedItems)
            // sprawdzić czy vat w pln się przelicza
            updateInvoiceItems(beforeCorrectionRows, updatedItems)
        }
    }, [showOssForAfterCor]);

    useEffect(() => {
        if (firstRender.current)
            return;

        if (showOssForBeforeCor === true) {
            const updatedItems: Fa1InvoiceItem[] = beforeCorrectionRows.map(item =>
                Object.setPrototypeOf(({
                    ...item,
                    stawkaPodatku: null
                }), Fa1InvoiceItem.prototype));
            setBeforeCorrectionRows(updatedItems)
            updateInvoiceItems(updatedItems, afterCorrectionRows)
        }
        else {
            const updatedItems: Fa1InvoiceItem[] = afterCorrectionRows.map(item =>
                Object.setPrototypeOf(({
                    ...item,
                    stawkaPodatkuXII: null,
                    towarUsługaZal15: null
                }), Fa1InvoiceItem.prototype));
            setBeforeCorrectionRows(updatedItems)
            // sprawdzić czy vat w pln się przelicza
            updateInvoiceItems(updatedItems, afterCorrectionRows)
        }
    }, [showOssForBeforeCor]);

    useEffect(() => {
        if (firstRender.current)
            return;

        if (showCurrency === false) {
            const updatedAfterCor: Fa1InvoiceItem[] = afterCorrectionRows.map(item =>
                Object.setPrototypeOf(({
                    ...item,
                    kursWaluty: null
                }), Fa1InvoiceItem.prototype));
            setAfterCorrectionRows(updatedAfterCor)

            let updatedBeforeCor = beforeCorrectionRows;
            if (!correctedInvIsInKsefix) {
                updatedBeforeCor = beforeCorrectionRows.map(item =>
                    Object.setPrototypeOf(({
                        ...item,
                        kursWaluty: null
                    }), Fa1InvoiceItem.prototype));
                setBeforeCorrectionRows(updatedBeforeCor)
            }

            updateInvoiceItems(updatedBeforeCor, updatedAfterCor)
        }
    }, [showCurrency]);

    useEffect(() => {
        if (firstRender.current)
            return;

        if (showP_6A === false) {
            const updatedItems: Fa1InvoiceItem[] = afterCorrectionRows.map(item =>
                Object.setPrototypeOf(({
                    ...item,
                    P_6A: null
                }), Fa1InvoiceItem.prototype));
            setAfterCorrectionRows(updatedItems)
            updateInvoiceItems(beforeCorrectionRows, updatedItems)
        }
    }, [showP_6A]);

    useEffect(() => {
        let netto = 0;
        let vat = 0;
        let vatInPln = 0;
        let brutto = 0;

        afterCorrectionRows.forEach((item) => {
            netto += item.wartoscSprzedazyNetto;
            vat += item._vat;
            vatInPln += item._vatInPln;
            brutto += item.wartoscSprzedazyBrutto;
        });

        setSummaryAfterCor({ netto, vat, vatInPln, brutto });
    }, [afterCorrectionRows]);

    useEffect(() => {
        let netto = 0;
        let vat = 0;
        let vatInPln = 0;
        let brutto = 0;

        beforeCorrectionRows.forEach((item) => {
            netto += item.wartoscSprzedazyNetto;
            vat += item._vat;
            vatInPln += item._vatInPln;
            brutto += item.wartoscSprzedazyBrutto;
        });

        setSummaryBeforeCor({ netto, vat, vatInPln, brutto })
    }, [beforeCorrectionRows]);

    // next useEffects

    const initActions = () => {
        const invoiceItemsBeforeCor: Fa1InvoiceItem[] = [] as Fa1InvoiceItem[];
        const invoiceItemsAfterCor: Fa1InvoiceItem[] = [];

        if (invoiceItems?.length === 0 || clearRowsTemp) {
            const initBeforeRow = createEmptyRow(true, true)
            invoiceItemsBeforeCor.push(initBeforeRow);
            const initAfterRow = createEmptyRow(false, true)
            invoiceItemsAfterCor.push(initAfterRow)
        }
        else if (invoiceItems.some(x => x.stanPrzed)) {
            invoiceItems.forEach(item => {
                if (item.stanPrzed) {
                    invoiceItemsBeforeCor.push(item);
                } else {
                    invoiceItemsAfterCor.push(item);
                }
            });
        }
        else {
            const invoiceItemsCloneForBefore = cloneInvoiceItems(invoiceItems, editMode);
            const invoiceItemsCloneForAfter = cloneInvoiceItems(invoiceItems, editMode);

            if (editMode) {
                let idCounter = 0;
                for (const x of invoiceItemsCloneForBefore.filter(x => x.stanPrzed)) {
                    x.ID = idCounter.toString();
                    invoiceItemsBeforeCor.push(x);
                    idCounter++;
                }


                idCounter = 0;
                for (const x of invoiceItemsCloneForAfter.filter(x => !x.stanPrzed)) {
                    x.ID = idCounter.toString();
                    invoiceItemsAfterCor.push(x);
                    idCounter++;
                }

            }
            else {
                let lpCounter = 1;
                for (const x of invoiceItemsCloneForBefore) {
                    x.lp = lpCounter;
                    lpCounter += 2;
                    x.stanPrzed = true;
                    invoiceItemsBeforeCor.push(x);
                }
                lpCounter = 2;
                for (const x of invoiceItemsCloneForAfter) {
                    x.lp = lpCounter;
                    lpCounter += 2
                    x.stanPrzed = false;
                    invoiceItemsAfterCor.push(x);
                }
            }
        }
        setBeforeCorrectionRows(invoiceItemsBeforeCor);
        setAfterCorrectionRows(invoiceItemsAfterCor);
        updateInvoiceItems(invoiceItemsBeforeCor, invoiceItemsAfterCor)

        const optFieldsExistanceBeforeCor: FieldsVisibilityBeforeCor = {
            rabatOpust: invoiceItemsBeforeCor.some(x => x.rabatOpust),
            indeks: invoiceItemsBeforeCor.some(x => x.indeks),
            towarUsługaZal15: invoiceItemsBeforeCor.some(x => x.towarUsługaZal15),
            GTU: invoiceItemsBeforeCor.some(x => x.GTU),
            PKWiU: invoiceItemsBeforeCor.some(x => x.PKWiU),
            GTIN: invoiceItemsBeforeCor.some(x => x.GTIN),
            CN: invoiceItemsBeforeCor.some(x => x.CN),
            PKOB: invoiceItemsBeforeCor.some(x => x.PKOB),
            procedura: invoiceItemsBeforeCor.some(x => x.procedura),
            kwotaPodatkuAkcyzowego: invoiceItemsBeforeCor.some(x => x.kwotaPodatkuAkcyzowego),
            P_6A: invoiceItemsBeforeCor.some(x => x.P_6A),
            cenaJednostkowaNetto: invoiceItemsBeforeCor.some(x => x.cenaJednostkowaNetto),
            cenaJednostkowaBrutto: invoiceItemsBeforeCor.some(x => x.cenaJednostkowaBrutto),
            wartoscSprzedazyNetto: invoiceItemsBeforeCor.some(x => x.wartoscSprzedazyNetto),
            wartoscSprzedazyBrutto: invoiceItemsBeforeCor.some(x => x.wartoscSprzedazyBrutto),
            stawkaPodatku: invoiceItemsBeforeCor.some(x => x.stawkaPodatku),
            stawkaPodatkuXII: invoiceItemsBeforeCor.some(x => x.stawkaPodatkuXII),
            kursWaluty: invoiceItemsBeforeCor.some(x => x.kursWaluty),
            kwotaPodatku: invoiceItemsBeforeCor.some(x => x.kwotaPodatku && x.kwotaPodatku !== 0),
        }
        setColumnVisibilityBeforeCor(optFieldsExistanceBeforeCor);

        const optFieldsExistance: FieldsVisibility = {
            rabatOpust: invoiceItemsAfterCor.some(x => x.rabatOpust),
            indeks: invoiceItemsAfterCor.some(x => x.indeks),
            towarUsługaZal15: invoiceItemsAfterCor.some(x => x.towarUsługaZal15),
            GTU: invoiceItemsAfterCor.some(x => x.GTU),
            PKWiU: invoiceItemsAfterCor.some(x => x.PKWiU),
            GTIN: invoiceItemsAfterCor.some(x => x.GTIN),
            CN: invoiceItemsAfterCor.some(x => x.CN),
            PKOB: invoiceItemsAfterCor.some(x => x.PKOB),
            procedura: invoiceItemsAfterCor.some(x => x.procedura),
            kwotaPodatkuAkcyzowego: invoiceItemsAfterCor.some(x => x.kwotaPodatkuAkcyzowego),
            kwotaPodatku: invoiceItemsBeforeCor.some(x => x.kwotaPodatku && x.kwotaPodatku !== 0),
        }
        setColumnVisibilityAfterCor(optFieldsExistance);

        setColumnsRefreshKey(old => old + 1)
    }


    useEffect(() => {
        if (clearRowsTemp) {
            initActions()
        }
    }, [clearRowsTemp]);

    // INIT useEffect
    useEffect(() => {
        initActions();
        if (firstRender.current) {
            firstRender.current = false;
        }

        // for development in strict mode
        return () => { firstRender.current = true };

    }, []);
    // INIT useEffect

    // event handlers
    const onColumnChoserClickBefore = () => {
        // @ts-ignore
        beforeCorDataGridRef?.current?.instance?.showColumnChooser();
    }

    const onColumnChoserClickAfter = () => {
        // @ts-ignore
        afterCorDataGridRef?.current?.instance?.showColumnChooser();
    }

    const addRow = () => {
        const beforeCorrectionRow = createEmptyRow(true, false)
        const afterCorrectionRow = createEmptyRow(false, false)

        setBeforeCorrectionRows(old => [...old, beforeCorrectionRow])
        setAfterCorrectionRows(old => [...old, afterCorrectionRow])

        const newRows = [beforeCorrectionRow, afterCorrectionRow]
        // @ts-ignore
        setInvoiceItems(prevItems => [...prevItems, ...newRows]);
    };

    const onOptChangedInAfterRows = (e: any) => {
        if (e.name === "columns" && e.fullName.endsWith("visible") && e.value === false) {
            const columnNumberMatch = e.fullName.match(/\[(\d+)\]/);
            if (columnNumberMatch) {
                handleColumnHidden(columnNumberMatch, columnsAfterArr, afterCorrectionRows, setAfterCorrectionRows, beforeCorrectionRows, setColumnVisibilityAfterCor);
                // dataGridRef?.current?.instance?.showColumnChooser();
            }
        }
        if (e.name === "columns" && e.fullName.endsWith("visible") && e.value === true) {
            const columnNumberMatch = e.fullName.match(/\[(\d+)\]/);
            if (columnNumberMatch) {
                handleColumnShown(columnNumberMatch, columnsAfterArr, afterCorrectionRows, setAfterCorrectionRows, beforeCorrectionRows, setColumnVisibilityAfterCor);
                // dataGridRef?.current?.instance?.showColumnChooser();
            }
        }
    }

    const onOptChangedInBeforeRows = (e: any) => {
        if (e.name === "columns" && e.fullName.endsWith("visible") && e.value === false) {
            const columnNumberMatch = e.fullName.match(/\[(\d+)\]/);
            if (columnNumberMatch) {
                handleColumnHidden(columnNumberMatch, columnsBeforeArr, beforeCorrectionRows, setBeforeCorrectionRows, afterCorrectionRows, setColumnVisibilityBeforeCor);
                // dataGridRef?.current?.instance?.showColumnChooser();
            }
        }
        if (e.name === "columns" && e.fullName.endsWith("visible") && e.value === true) {
            const columnNumberMatch = e.fullName.match(/\[(\d+)\]/);
            if (columnNumberMatch) {
                handleColumnShown(columnNumberMatch, columnsBeforeArr, beforeCorrectionRows, setBeforeCorrectionRows, afterCorrectionRows, setColumnVisibilityBeforeCor);
                // dataGridRef?.current?.instance?.showColumnChooser();
            }
        }
    }

    const handleRowUpdatedAfter = (e: any) => {
        const rowsWithUpdated = [...afterCorrectionRows];
        const updatedRowIndex = rowsWithUpdated.findIndex(item => item.ID === e.key);
        if (updatedRowIndex > -1) {
            // @ts-ignore
            const kwotaPodatkuInfo = afterCorDataGridRef?.current?.instance?.columnOption('kwotaPodatku');
            const updateKwotaPodatku = kwotaPodatkuInfo?.visible;
            e.data.Calculate(showNetto, updateKwotaPodatku ?? false);
            rowsWithUpdated[updatedRowIndex] = e.data;
            setAfterCorrectionRows(rowsWithUpdated);
            updateInvoiceItems(beforeCorrectionRows, rowsWithUpdated)
        }
    };

    const handleRowUpdatedBefore = (e: any) => {
        const rowsWithUpdated = [...beforeCorrectionRows];
        const updatedRowIndex = rowsWithUpdated.findIndex(item => item.ID === e.key);
        if (updatedRowIndex > -1) {
            // @ts-ignore
            const kwotaPodatkuInfo = beforeCorDataGridRef?.current?.instance?.columnOption('kwotaPodatku');
            const updateKwotaPodatku = kwotaPodatkuInfo?.visible;
            e.data.Calculate(showNetto, updateKwotaPodatku ?? false);
            rowsWithUpdated[updatedRowIndex] = e.data;
            setBeforeCorrectionRows(rowsWithUpdated);
            updateInvoiceItems(rowsWithUpdated, afterCorrectionRows)
        }
    };

    const removeRowByKey = (key: string) => {
        const beforeCorrectionClone = [...beforeCorrectionRows];
        const afterCorrectionClone = [...afterCorrectionRows];

        const indexBefore = beforeCorrectionClone.findIndex(item => item.ID === key);
        if (indexBefore > -1) {
            beforeCorrectionClone.splice(indexBefore, 1);
            updateIds(beforeCorrectionClone, true)
            setBeforeCorrectionRows(beforeCorrectionClone);
        }
        const indexAfter = afterCorrectionClone.findIndex(item => item.ID === key);
        if (indexAfter > -1) {
            afterCorrectionClone.splice(indexAfter, 1);
            updateIds(afterCorrectionClone, false)
            setAfterCorrectionRows(afterCorrectionClone);
        }

        updateInvoiceItems(beforeCorrectionClone, afterCorrectionClone)
    }
    // event handlers

    // memo variables
    const columnsAfterArr: any = useMemo(() =>
        columns(showP_6A, showNetto, showOssForAfterCor, showCurrency, columnVisibilityAfterCor),
        [showCurrency, showNetto, showOssForAfterCor, showP_6A, columnsRefreshKey]
    );

    const columnsBeforeArr: any = useMemo(() =>
        columnsBefore(initShowNetto, columnVisibilityBeforeCor, correctedInvIsInKsefix, showCurrency, showNetto, showOssForBeforeCor),
        [initShowNetto, correctedInvIsInKsefix, showCurrency, showNetto, showOssForBeforeCor, columnsRefreshKey]
    );
    // memo variables

    return (
        <div className="editor-rows-container">
            {isReadOnly && <div><span title={'Wymagane uprawnienie "Edycja faktury korekty - dane fiskalne"'}>🔒</span></div>}
            <CheckBox
                key={"no-rows-mode"}
                text={'Korekta bez wierszy'}
                defaultValue={noRowsMode}
                disabled={isReadOnly}
                onValueChanged={(e) => { setNoRowsMode(e.value) }}
            />
            {!noRowsMode &&
                <div>
                    <p style={{ margin: 0 }}><b>Wiersze przed korektą</b></p>
                    {!correctedInvIsInKsefix && <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <CheckBox
                            key={"oss-old-rows"}
                            text={'OSS'}
                            defaultValue={showOssForBeforeCor}
                            disabled={isReadOnly}
                            onValueChanged={(e) => setShowOssForBeforeCor(e.value)}
                        />
                        <Button
                            text="Dodatkowe kolumny"
                            stylingMode="outlined"
                            icon="insertcolumnleft"
                            onClick={onColumnChoserClickBefore}
                        />
                    </div>}

                    <DataGrid
                        id="gridContainerOldRows"
                        dataSource={beforeCorrectionRows}
                        keyExpr="ID"
                        width={"100%"}
                        columns={columnsBeforeArr}
                        ref={beforeCorDataGridRef}
                        showBorders={true}
                        showColumnLines={true}
                        sorting={{ mode: 'none' }}
                        allowColumnResizing={true}
                        noDataText='Brak pozycji'
                        onOptionChanged={onOptChangedInBeforeRows}
                        onRowUpdated={(e) => handleRowUpdatedBefore(e)}
                        onRowRemoving={(e) => {
                            removeRowByKey(e.key);
                        }}
                        disabled={isReadOnly}
                    >
                        <Template name="idColumn" render={lpColumnRender} />
                        <Scrolling mode="virtual" preloadEnabled={true} />
                        <Editing
                            mode="cell"
                            allowAdding={!correctedInvIsInKsefix && !isReadOnly}
                            allowUpdating={!correctedInvIsInKsefix && !isReadOnly}
                            allowDeleting={!correctedInvIsInKsefix && !isReadOnly}
                        >
                            <Texts confirmDeleteMessage='Czy ususnąć pozycję?' />
                        </Editing>
                        {!correctedInvIsInKsefix &&
                            <ColumnChooser
                                enabled={true}
                                title={"Wybór widocznych kolumn"}
                                height={500}
                                width={400}
                                mode={'select'}
                            >
                                <Position
                                    my={{ x: "center", y: "center" }}
                                    at={{ x: "center", y: "center" }}
                                />
                                <ColumnChooserSearch
                                    enabled={true}
                                    editorOptions={true} />

                                <ColumnChooserSelection
                                    selectByClick={true}
                                    recursive={true} />
                            </ColumnChooser>
                        }
                    </DataGrid >

                    {summaryContainer(summaryBeforeCor, correctedInvIsInKsefix ? showCurrency ?? true : showCurrency)}

                    <hr style={{ marginTop: 16 }} />

                    <p style={{ margin: 0 }}><b>Wiersze po korekcie</b></p>
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <CheckBox
                            key={"oss-new-rows"}
                            text={'OSS'}
                            defaultValue={showOssForAfterCor}
                            onValueChanged={(e) => setShowOssForAfterCor(e.value)}
                        />
                        <Button
                            text="Dodatkowe kolumny"
                            stylingMode="outlined"
                            icon="insertcolumnleft"
                            onClick={onColumnChoserClickAfter}
                        />
                    </div>

                    <DataGrid
                        id="gridContainerNewRows"
                        dataSource={afterCorrectionRows}
                        keyExpr="ID"
                        showBorders={true}
                        width="100%"
                        ref={afterCorDataGridRef}
                        columnResizingMode="widget"
                        showColumnLines={true}
                        rowDragging={{
                            allowReordering: false,
                        }}
                        columns={columnsAfterArr}
                        onOptionChanged={onOptChangedInAfterRows}
                        sorting={{ mode: 'none' }}
                        onRowUpdated={(e) => handleRowUpdatedAfter(e)}
                        onRowRemoving={(e) => {
                            removeRowByKey(e.key);
                        }}
                        noDataText='Brak pozycji'
                        disabled={isReadOnly}
                    >
                        <Template name="idColumn" render={lpColumnRender} />
                        <Scrolling mode="virtual" preloadEnabled={true} />
                        <Editing
                            mode="cell"
                            allowAdding={!correctedInvIsInKsefix && !isReadOnly}
                            allowUpdating={true && !isReadOnly}
                            allowDeleting={!correctedInvIsInKsefix && !isReadOnly}
                        >
                            <Texts confirmDeleteMessage='Czy ususnąć pozycję?' />
                        </Editing>
                        <ColumnChooser
                            enabled={true}
                            title={"Wybór widocznych kolumn"}
                            height={500}
                            width={400}
                            mode={'select'}
                        >
                            <Position
                                my={{ x: "center", y: "center" }}
                                at={{ x: "center", y: "center" }}
                            />

                            <ColumnChooserSearch
                                enabled={true}
                                editorOptions={true} />

                            <ColumnChooserSelection
                                selectByClick={true}
                                recursive={true} />
                        </ColumnChooser>
                    </DataGrid >

                    {summaryContainer(summaryAfterCor, showCurrency)}
                    {!correctedInvIsInKsefix && <button onClick={addRow} disabled={isReadOnly}>Dodaj wiersz</button>}
                </div>
            }
        </div>
    );
}

export default CorrectionRowsEditor;

