import { useState } from "react";
import GenericPopup from "components/popup/GenericPopup";
import SalesProcessingImporter from 'pages/Invoices/Sale/SalesProcessingImporter';
import { commonPopupToolbarItems } from 'components/popup/PopupTools';
import { postInvoices, ImportMode } from 'pages/Invoices/Sale/actions/postInvoices';
import { useTranslation } from 'react-i18next';

const newPostInvoiceDto =
{
    tags: [],
    invoices: [],
    manualUpload: true,
    vatDate: null,
    anonymize: false,
    draft: false
}

const setNewPostInvoiceDtoDefaults = () => {
    newPostInvoiceDto.tags = []
    newPostInvoiceDto.invoices = []
    newPostInvoiceDto.manualUpload = true
    newPostInvoiceDto.vatDate = null
    newPostInvoiceDto.anonymize = false
    newPostInvoiceDto.draft = false
}

const ImporterPopup = ({
    organizationId,
    dispatchType,
    setSelectedFilesItemKeys,
    selectedFilesItemKeys,
    isDraft,
    afterSave,
    setBlockAutoRefresh,
}) => {

    const [postInvoiceDto, setPostInvoiceDto] = useState(newPostInvoiceDto)
    const [importModeDialogVisible, setImportModeDialogVisible] = useState(false)
    const [importModePromiseResolver, setImportModePromiseResolver] = useState(null)
    const [importerVisible, setImporterVisible] = useState(false)
    const [fileStore, setFileStore] = useState([])
    const { t } = useTranslation()

    const importModeDialog = GenericPopup({
        onHiding: () => setImportModeDialogVisible(false),
        content: <div>Podczas importu wykryto faktury z systemów, do których nie masz uprawnień.</div>,
        title: t("Import_popup_title"),
        buttons: [{
            text: "Importuj wszystkie",
            action: (popupHandler) => {
                importModePromiseResolver(ImportMode.All)
                setImportModeDialogVisible(false)
            },
        },
        {
            text: "Pomiń faktury bez dostępu",
            action: (popupHandler) => {
                importModePromiseResolver(ImportMode.OnlyWithPostImportAccess)
                setImportModeDialogVisible(false)
            },
        }],
        isVisible: importModeDialogVisible,
        setIsVisible: setImportModeDialogVisible,

    })

    const saveButtonOptions = {
        text: t("#_oneinvoicegrid_5"),
        onClick: async () => {
            newPostInvoiceDto.draft = isDraft
            await postInvoices({
                organizationId, postInvoiceDto, dispatchType, getImportMode: () => {
                    setImportModeDialogVisible(true)
                    const promise = new Promise((resolve, reject) => {
                        setImportModePromiseResolver(() => resolve)
                        importModeDialog.show()
                    })
                    return promise
                }
            })
            close()
            setNewPostInvoiceDtoDefaults()
            setPostInvoiceDto(newPostInvoiceDto)
            setSelectedFilesItemKeys([])
            setFileStore([])
            afterSave();
        }
    }

    const cancelButtonOptions = {
        text: t("#_oneinvoicegrid_8"),
        onClick: () => {
            close()
            setNewPostInvoiceDtoDefaults()
            setPostInvoiceDto(newPostInvoiceDto)
            setSelectedFilesItemKeys([])
            setFileStore([])
        }
    }

    const importPopupToolbarItems = commonPopupToolbarItems({
        t: t,
        saveButtonOptions: saveButtonOptions,
        cancelButtonOptions: cancelButtonOptions
    })

    const { popup, show, close } = GenericPopup({
        onHiding: () => close(),
        content:
            <div>
                <SalesProcessingImporter
                    postInvoiceDto={postInvoiceDto}
                    setPostInvoiceDto={setPostInvoiceDto}
                    selectedFilesItemKeys={selectedFilesItemKeys}
                    setSelectedFilesItemKeys={setSelectedFilesItemKeys}
                    fileStore={fileStore}
                    setFileStore={setFileStore}
                    draft={isDraft}
                ></SalesProcessingImporter>
            </div>,
        toolbarItems: importPopupToolbarItems,
        title:  t("Import_popup_title"),
        width: "80%",
        height: "85%",
        isVisible: importerVisible,
        setIsVisible: setImporterVisible,
        setBlockAutoRefresh: setBlockAutoRefresh
    })

    return { popup: [popup, importModeDialog.popup], show, close }
}

export default ImporterPopup;