import FileUploader from 'devextreme-react/file-uploader';
import { ScrollView } from 'devextreme-react/scroll-view';
import { Form, GroupItem } from 'devextreme-react/form';
import { useRef, useState } from 'react';
import { InvoicesEndpoints } from 'api/XfXApi';
import axios from 'axios';
import notify from 'devextreme/ui/notify';
import { useTranslation } from 'react-i18next';
import GenericPopup from "components/popup/GenericPopup";

import DataGrid, {
    Paging,
    Selection,
    Scrolling,
    FilterRow,
    HeaderFilter,
    Search
  } from 'devextreme-react/data-grid';
import { convertToColumns } from './GenericGrid'
import { XfXApi } from '../../api/XfXApi';
import { commonPopupToolbarItems } from 'components/popup/PopupTools';

export const attachmentsPopupData = ({t}) => [
    { dataField: "InvoiceId", dataType: "string", caption: t("#_attachmentstools_1"), visible: false },
    { dataField: "ExternalTrackingId", dataType: "string", caption: t("#_attachmentstools_2"), sortIndex: 2 },
    { dataField: "OriginalFileName", dataType: "string", caption: t("#_attachmentstools_3"), sortIndex: 1 },
    { dataField: "CreationDateUtc", dataType: "datetime", caption: "Data dodania (UTC)", sortIndex: 0, sortOrder: 'desc' }
]

const handleSelectionChange = async ({ dataGrid, setSelectedItem, dataSource, setSelectedIds, setCreateArchiveDisabled }) => {
  await dataGrid.current.instance.getSelectedRowKeys()
    .then(y => {
      setSelectedIds(y.map((x) => { return x.Id._value}))
      if (y.length === 1) {
        const selectedItem = dataSource.items().filter(x => x.Id._value === y[0].Id._value)[0]
        setSelectedItem(selectedItem)
        setCreateArchiveDisabled(true)
      }
      else {
        setSelectedItem(null)
        setCreateArchiveDisabled(y.length === 0 ? true : false)
      }
    })
}

const getFileName = (contentDisposition) =>
{
  const fileNameMatch = contentDisposition ? /filename="?([^"]*)"?;/g.exec(contentDisposition) : undefined;
  return fileNameMatch && fileNameMatch.length > 1 ? fileNameMatch[1] : undefined;
}

export const AttachmentsGrid = ( dataSource, setSelectedItem, gridFilter, setSelectedIds, setCreateArchiveDisabled) => {
  const { t } = useTranslation()
  const dataGridRef = useRef(null)


  const grid =
    <>
      <DataGrid
        dataSource={dataSource}
        showBorders={true}
        height={580}
        rowAlternationEnabled={true}
        showColumnLines={true}
        filterValue={gridFilter}
        ref={dataGridRef}
        onSelectionChanged={s => {
          handleSelectionChange({ dataGrid: dataGridRef, setSelectedItem: setSelectedItem, dataSource: dataSource, setSelectedIds: setSelectedIds, setCreateArchiveDisabled: setCreateArchiveDisabled })
        }}
      >
        <Scrolling mode="virtual" preloadEnabled={true}/>
        <Paging defaultPageSize={100} />
        <HeaderFilter> 
          <Search
            enabled={true}
          >
          </Search>
        </HeaderFilter>
        <FilterRow visible={true} applyFilter={true} />
        <Selection mode="multiple"
            deferred={true}
            showCheckBoxesMode='none'
          />
        {convertToColumns(attachmentsPopupData({t}))}
      </DataGrid>
    </>
  return grid
}

export const getAttachment = async ({ id, t }) => {
  const url = InvoicesEndpoints.Attachments() + `/${id}`

  await axios
    .request({
      url: url,
      method: "GET",
      responseType: 'blob',
    })
    .then((response) => {
      if (response.status !== 200) return
      const contentDisposition = response.headers["content-disposition"]
      const fileName = getFileName(contentDisposition)

      if (fileName === undefined)
      {
        notify({
          message: t("#_attachmentstools_4"),
          position: {
            my: 'center bottom',
            at: 'center bottom',
          },
        }, 'error', 3000);
        return
      }

      const downloadUrl = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = downloadUrl;
      link.setAttribute('download', fileName);

      document.body.appendChild(link);
      link.click();
      link.remove();
    })
    .catch(() => {
      notify({
        message: t("#_attachmentstools_5"),
        position: {
          my: 'center bottom',
          at: 'center bottom',
        },
      }, 'error', 3000);
    })
}

export const postInvoiceAttachments = async ({ postInvoiceAttachmentDto, setAddAttachmentsVisible, dataSourceOptions, t }) => {
    for (const x of postInvoiceAttachmentDto.attachments) {
      try {
        var fileData = new FormData();
        fileData.append("file", x, x.name);
        fileData.append("invoiceId", postInvoiceAttachmentDto.invoiceId)
        await axios.post(`${InvoicesEndpoints.Attachments()}`, fileData, {
          headers: {
            'Content-Type': `multipart/form-data`
          }
        })
      } catch (error) {
        if (error.response.status >= 400) {
          notify({
            message: `${t("#_attachmentstools_18")} ${x.name}`,
            position: {
              my: 'center bottom',
              at: 'center bottom',
            },
          }, 'error', 3000);
        }
      }
    }
    setAddAttachmentsVisible(false)
    dataSourceOptions?.reload()
  }

  export const deleteAttachment = async ({ id, dataSourceOptions, setSelectedItem, t }) => {
    await XfXApi.AttachmentsApi.apiTenantIdAttachmentsIdDelete(id, XfXApi.GetTenantId())
            .then(x => {
            })
            .catch(x => {
              notify({
                message: `${t("#_attachmentstools_17")}`,
                position: {
                  my: 'center bottom',
                  at: 'center bottom',
                },
              }, 'error', 3000);
            })

    dataSourceOptions?.reload()
    setSelectedItem(null)
  }

  export const createArchive = async ({ ids, t }) => {
    const url = InvoicesEndpoints.Attachments() + `/archive`

    await axios
      .request({
        url: url,
        method: "POST",
        responseType: 'blob',
        data: {
          ids: ids
        }
      })
      .then((response) => {
        if (response.status !== 200) return
        const contentDisposition = response.headers["content-disposition"]
        const fileName = getFileName(contentDisposition)

        if (fileName === undefined)
        {
          notify({
            message: t("#_attachmentstools_6"),
            position: {
              my: 'center bottom',
              at: 'center bottom',
            },
          }, 'error', 3000);
          return
        }

        const downloadUrl = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = downloadUrl;
        link.setAttribute('download', fileName);

        document.body.appendChild(link);
        link.click();
        link.remove();
      })
      .catch((e) => {
        notify({
          message: t("#_attachmentstools_7"),
          position: {
            my: 'center bottom',
            at: 'center bottom',
          },
        }, 'error', 3000);
      })
  }

  export const AddAttachmentsPopup = ({ invoiceId, setAddAttachmentsVisible, addAttachmentsVisible, dataSourceOptions }) => {
    const { t } = useTranslation()
    const [postInvoiceAttachmentDto, setPostInvoiceAttachmentDto] = useState({
        invoiceId: invoiceId,
        attachments: []
    })

    const uploadInvoiceAttachment = async (file) => {
        const updated = {
            ...postInvoiceAttachmentDto, attachments: [...postInvoiceAttachmentDto.attachments ?? [], file]
        }
        setPostInvoiceAttachmentDto(updated)
    }

    const saveButtonOptions = {
      text: t("#_savecancelpopup_1"),
      onClick: async () => {
        await postInvoiceAttachments({ postInvoiceAttachmentDto, setAddAttachmentsVisible, dataSourceOptions, t })
      }
    }

    const cancelButtonOptions = {
      text: t("#_savecancelpopup_2"),
      onClick: () => {
        setAddAttachmentsVisible(false)
      }
    }

    const popupToolbarItems = commonPopupToolbarItems({ 
      t: t, 
      saveButtonOptions: saveButtonOptions,
      cancelButtonOptions: cancelButtonOptions
    })

    const popup = GenericPopup({ 
      onHiding: () => popup.close(),
      content:
      <div>
        <ScrollView
                direction="vertical">
                <Form id="form" visible={true}>
                    <GroupItem>
                        <div className="file-uploader-block">
                        <FileUploader 
                            selectButtonText={t("#_attachmentstools_10")} 
                            labelText={t("#_attachmentstools_11")} 
                            multiple={true} 
                            uploadMode="instantly"
                            uploadFile={uploadInvoiceAttachment}
                            invalidFileExtensionMessage={t("#_attachmentstools_12")}
                            uploadedMessage={t("#_attachmentstools_13")}
                            readyToUploadMessage={t("#_attachmentstools_14")}
                            uploadFailedMessage={t("#_attachmentstools_15")}
                            name={'file'}
                        />
                        </div>
                    </GroupItem>
                </Form>
            </ScrollView>
      </div>,
      toolbarItems: popupToolbarItems,
      title: t("#_attachmentstools_16"),
      width: "800px",
      height: "520px",
      isVisible: addAttachmentsVisible,
      setIsVisible: setAddAttachmentsVisible
    })

    return (
      <>
      {popup.popup}
      </> 
    );
  }
