import { useState } from "react"
import GenericPopup from "components/popup/GenericPopup"
import { useTranslation } from 'react-i18next';
import { commonPopupToolbarItems } from 'components/popup/PopupTools';
import  utcToLocal from 'utils/utcToLocal';

export const ErrorRaportPreview = (setBlockAutoRefresh) => {
  const { t } = useTranslation()
  const [businessError, setBusinessError] = useState()
  const [dateTimeToReturn, setDateTimeToReturn] = useState()
  const [isVisible, setIsVisible] = useState(false)

  const popupToolbarItems = commonPopupToolbarItems({ 
    onSave: () => {
      popup.close() //TO DO
    }, 
    onCancel: () => {
      popup.close()
    }, t: t 
  })

  const popup = GenericPopup({
    onHiding: () => popup.close(),
    toolbarItems: popupToolbarItems,
    title: t("#_errorraport_4"),
    width: 860,
    height: 650,
    content: <div>
    <p>
        {t("#_eventlogstools_112")} <span>{dateTimeToReturn}</span>
    </p>
    <p>
        {t("#_eventlogstools_113")} <span>{businessError?.Code}</span>
    </p>
    <p>
        {t("#_eventlogstools_114")} <span>{businessError?.Message}</span>
    </p>
    <p>
        {t("#_eventlogstools_115")} <span>{businessError?.DetailedMessage}</span>
    </p></div>,
    isVisible: isVisible,
    setIsVisible: setIsVisible,
    setBlockAutoRefresh: setBlockAutoRefresh
  })

  return {
    popup: popup.popup,
    showErrors: (content) => {
      setBusinessError(content)
      setDateTimeToReturn((content?.TimeStampUtc !== null && content?.TimeStampUtc !== undefined) 
                           ? utcToLocal(content?.TimeStampUtc) : '')

      popup.show()
    },
    downloadReport: (error) => {

      const date = (error?.TimeStampUtc !== null && error?.TimeStampUtc !== undefined)
                    ? utcToLocal(error?.TimeStampUtc) : '';
      let content = `${t("#_eventlogstools_112")} ${date}
${t("#_eventlogstools_113")} ${error?.Code}
${t("#_eventlogstools_114")} ${error?.Message}
${t("#_eventlogstools_115")} ${error?.DetailedMessage}`

      const downloadUrl = window.URL.createObjectURL(new Blob([content]));
      const link = document.createElement('a');
      link.href = downloadUrl;
      link.setAttribute('download', "report.txt");

      document.body.appendChild(link);
      link.click();
      link.remove();
    }
  }
}
