import React, { ReactElement, useState } from 'react';
import { staticHack } from './hack';

interface SequenceControlProps {
    itemRenderer: (n: number) => ReactElement[] | undefined;
    minItems: number;
    maxItems: number;
    initialItemsCount: number;
    handleRemoveItem: (sequenceIndex: number) => void;
}

const SequenceControl: React.FC<SequenceControlProps> = ({ itemRenderer, minItems, maxItems, initialItemsCount, handleRemoveItem }) => {
    const [itemsCount, setItemsCount] = useState(initialItemsCount);

    const addItem = () => {
        if (itemsCount < maxItems) {
            setItemsCount(itemsCount + 1);
        }
    };

    const removeItem = () => {
        if (itemsCount > minItems) {
            const itemsCountReduced = itemsCount - 1
            setItemsCount(itemsCountReduced)
            handleRemoveItem(itemsCountReduced)
        }
    };

    if (staticHack !== undefined)
        staticHack()

    return (
        <div>
            {Array.from({ length: itemsCount }, (_, index) => (
                <div className="invoice-editor-sequence-element" key={index}>
                    {itemRenderer(index)}
                </div>
            ))}

            <div className='invoice-editor-sequence-summary'>
                {itemsCount < maxItems && <button onClick={addItem}>Dodaj</button>}
                {itemsCount > minItems && <button onClick={removeItem}>Usuń</button>}
                <div>({itemsCount} / {maxItems === Infinity ? "∞" : maxItems})</div>
            </div>
        </div>
    );
};

export default SequenceControl;
