import { DescriptionType, FormField } from "./model"

// Place for custom descriptions visibility logic
// It's too difficult to move this to excel now
export const GetDescriptionVisibility = (field: FormField, updatedValues: Record<string, any>, fieldCorrected: boolean): boolean => {
    const invoiceType = updatedValues["Fa.RodzajFaktury"]
    const invoiceTypeMatch = field.DescriptionType === DescriptionType.OnlyForKorType ?
        invoiceType === "KOR" :
        true;

    if (!(field.Description !== null && invoiceTypeMatch)) {
        return false
    }


    if (field.DescriptionType === DescriptionType.ShowWhenCorrected && !fieldCorrected) {
        return false
    }



    if (field.Identifier === "_Podmiot3[0].DaneIdentyfikacyjne.") {
        const isInStandardRole = updatedValues["_Podmiot3[0].Rola/RolaInna"] === "Rola"
        const roleIsAdditionalBuyer = updatedValues["Podmiot3[0].Rola"] === "Item4"
        return isInStandardRole && roleIsAdditionalBuyer && invoiceTypeMatch
    }

    return true
}
