const RoleNames = ({ t }) => {
    return {
        // //[IndividualRole]
        Owner: t("#_rolenames_1"),

        //[IndividualRole]
        Introspection: t("#_rolenames_2"),

        //[IndividualRole]
        //[InstitutionRole]
        Invoice_read: t("#_rolenames_3"),

        //[IndividualRole]
        //[InstitutionRole]
        Invoice_write: t("#_rolenames_4"),

        //[IndividualRole]
        //[InstitutionRole]
        Payment_confirmation_write: t("#_rolenames_5"),

        //[IndividualRole]
        Credentials_read: t("#_rolenames_6"),

        //[IndividualRole]
        Credentials_manage: t("#_rolenames_7"),

        //[IndividualRole]
        Enforcement_operations: t("#_rolenames_8"),

        //[InstitutionRole]
        Self_invoicing: t("#_rolenames_9"),

        //[InstitutionRole]
        Tax_representative: t("#_rolenames_10"),

        //[InstitutionRole]
        Court_bailiff: t("#_rolenames_11"),

        //[InstitutionRole]
        Enforcement_authority: t("#_rolenames_12")
    }
}



export default RoleNames
