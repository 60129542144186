import { useState } from "react"
import TextBox from 'devextreme-react/text-box';
import { Switch } from 'devextreme-react/switch';
import RoleNames from '../RoleNames'
import SelectBox from 'devextreme-react/select-box';
import { useTranslation } from 'react-i18next';
import { commonPopupToolbarItems } from 'components/popup/PopupTools';
import GenericPopup from "components/popup/GenericPopup";

const credensialsIdRegExp = RegExp("^\\d{10}\\d?$")
const tokenDescriptionRegExp = RegExp(/^.{5,256}$/)

const KsefTokenEditorPopup = ({ title, isVisible, setIsVisible, save, setBlockAutoRefresh }) => {
    const { t } = useTranslation()
    const roleTypes = [t("ksef-role-individual"), t("ksef-role-institutional")]
    const [credensialsId, setCredensialsId] = useState("") // nip or pesel
    const [tokenDescription, setTokenDescription] = useState("")
    const [invoice_read, setInvoice_read] = useState(false)
    const [invoice_write, setInvoice_write] = useState(false)

    const [selectedRoleType, setSelectedRoleType] = useState(roleTypes[0])
    const roleNames = RoleNames({ t })

    const anyRoleSelected =
        invoice_read ||
        invoice_write

    const isCredensialsIdValid = credensialsIdRegExp.test(credensialsId)
    const isTokenDescriptionValid = tokenDescriptionRegExp.test(tokenDescription)

    const isIndividualRoleSelected = () => selectedRoleType === roleTypes[0]

    const clearValues = () => {
        setSelectedRoleType(roleTypes[0])
        setCredensialsId("")
        setTokenDescription("")
        setInvoice_write(false)
        setInvoice_read(false)
    }

    const RoleSwitch = ({ label, value, setValue }) => <div className="dx-field" style={{ margin: "0" }}>
        <div className="dx-field-label">{label}</div>
        <div className="dx-field-value">
            <Switch value={value} onValueChange={setValue} />
        </div>
    </div>

    const saveButtonOptions = {
        text: t("#_selectksefroleandidpopup_10"),
        onClick: () => {
            save({
                credentialsIdentifier: credensialsId,
                isIndividualRole: isIndividualRoleSelected(),
                roles: [
                    invoice_read ? "invoice_read" : null,
                    invoice_write ? "invoice_write" : null].filter(x => x != null),
                tokenDescription: tokenDescription
            })
            popup.close()
            clearValues()
        },
        disabled: !anyRoleSelected || !isCredensialsIdValid || !isTokenDescriptionValid
    }
  
    const cancelButtonOptions = {
        text: t("#_selectksefroleandidpopup_14"),
        onClick: () => {
            popup.close()
            clearValues()
        }
    }

    const popupToolbarItems = commonPopupToolbarItems({ 
        t: t, 
        saveButtonOptions: saveButtonOptions,
        cancelButtonOptions: cancelButtonOptions
    })

    const popup = GenericPopup({ 
        onHiding: () => {
            popup.close()
            clearValues()
        },
        content:
        <div>
          <SelectBox
                items={roleTypes}
                onValueChanged={x => {
                    setSelectedRoleType(x.value)
                }}
                value={selectedRoleType}
                label={t("#_selectksefroleandidpopup_3")}>
            </SelectBox>

            <br />

            <TextBox
                showClearButton={true}
                label={t("#_selectksefroleandidpopup_4")}
                placeholder={isIndividualRoleSelected() ? "NIP/PESEL" : t("#_selectksefroleandidpopup_5")}
                valueChangeEvent="keyup"
                value={credensialsId}
                onValueChanged={v => setCredensialsId(v.value)}
                maxLength={isIndividualRoleSelected() ? 11 : 10}
            />

            <br />

            <TextBox
                showClearButton={true}
                label={t("ksef-role-name-description")}
                valueChangeEvent="keyup"
                value={tokenDescription}
                onValueChanged={v => setTokenDescription(v.value)}
                maxLength={256}
            />

            <div className="dx-fieldset">
                {<RoleSwitch label={roleNames.Invoice_read} value={invoice_read} setValue={setInvoice_read}></RoleSwitch>}
                {<RoleSwitch label={roleNames.Invoice_write} value={invoice_write} setValue={setInvoice_write}></RoleSwitch>}
            </div>
        </div>,
        toolbarItems: popupToolbarItems,
        title: title,
        width: "500px",
        height: "80%",
        isVisible: isVisible,
        setIsVisible: setIsVisible,
        setBlockAutoRefresh: setBlockAutoRefresh
      })

    return (
        <>
            {popup.popup}
        </>
    )
}

export default KsefTokenEditorPopup
