import TextBox from "devextreme-react/text-box";
import SelectBox from 'devextreme-react/select-box';
import FilterBuilder, { CustomOperation } from 'devextreme-react/filter-builder';
import { ScrollView } from 'devextreme-react/scroll-view';
import DropDownBox from 'devextreme-react/drop-down-box';
import DataGrid, {
    Selection, Paging, FilterRow, Scrolling,
} from 'devextreme-react/data-grid';
import { useState, useRef, useEffect } from 'react';
import GenericPopup from "components/popup/GenericPopup"
import i18n from 'localization/i18n';
import axios from "axios"
import { Form, SimpleItem, Label, GroupItem } from 'devextreme-react/form';
import { commonPopupToolbarItems } from 'components/popup/PopupTools';
import { Template } from 'devextreme-react/core/template';
import dxTreeView from "devextreme/ui/tree_view";
import { KsefixEndpoints } from 'api/XfXApi';

dxTreeView.defaultOptions({
  device: { deviceType: "desktop" },
  options: {
      onInitialized: (e) => {
          const comp = e?.component;
          if ((comp?.option())?.cssClass?.includes('dx-filterbuilder-fields')) {
              comp.option('searchEnabled', true);
          }
      }
  }
})
const t = i18n.t
const validationGroup = "validationGroup";
const ModuleProperties = ({ module: step, moduleDefinitions, updateModuleValuesMap, availableUsers, setSettingsVisible, settingsVisible, selectedModule, moduleNames }) => {
    const [localValues, setLocalValues] = useState(step.valuesMap)
    const [userSelectionErrorMessage, setUserSelectionErrorMessage] = useState("");
    const formRef = useRef(null)
    const [nipList, setNipList] = useState([])


    useEffect(() => {
        axios
            .request({
                url: KsefixEndpoints.NipList(),
                method: "GET"
            }).then(x => setNipList(x.data))
    }, []);



    const updateModuleData = (propertyId, value) => {
        let updated = { ...localValues, [propertyId]: value }

        if (propertyId === 'accept-type')
        {
            if (value === 'users')
            {
                const propNameToRemove = 'required-acceptations-count'
                const { [propNameToRemove]: removedProperty, ...rest } = updated
                updated = rest
            }
            else if (value === 'count')
            {
                const propNameToRemove = 'user-selection'
                const { [propNameToRemove]: removedProperty, ...rest } = updated
                updated = { ...rest, 'required-acceptations-count': 1 }
            }
        }
        setLocalValues(updated)
    }

    const updateTag = (property, tagName, value) => {        
        var config = GetTagsConfig(property)
        var tag = config.find(e=>e.Name === tagName);
        tag.Value = value;
        const updated = { ...localValues, [property.id]: config }
        setLocalValues(updated)
    }

    const renderEventIcon = () => {
        return (
          <>
            <div className='dx-icon dx-icon-event'></div>
          </>
        );
      }

    const validate = () => {
        const validationResult = formRef?.current?.instance?.validate();
        
        const moduleResultOptionsIds = ["success", "success-all-opts", "failure", "failure-all-opts"];
        let isManualAcceptSelected = moduleResultOptionsIds.some(x => localValues[x] === "3");
        
        let localUserSelectionErrorMessage = "";
        if (isManualAcceptSelected 
            && (localValues['user-selection'] == null|| localValues['user-selection'].length === 0) 
            && localValues['accept-type'] === 'users'
        )
        {
            localUserSelectionErrorMessage = t("#_moduleproperties_validators_1");
            setUserSelectionErrorMessage(t("#_moduleproperties_validators_1"));
        }
        else if (!isManualAcceptSelected || localValues['accept-type'] !== 'users')
        {
            setUserSelectionErrorMessage("")
        }

        return validationResult.isValid 
            && (
                (userSelectionErrorMessage.length === 0 && localUserSelectionErrorMessage.length === 0) 
                || localValues['accept-type'] !== 'users'
                || !isManualAcceptSelected
            );
    }

    const saveDataInParent = () => {
        const isValid = validate();
        if (!isValid)
            return false;

        updateModuleValuesMap(localValues)

        return true;
    }

    const GetTagsConfig = (property) => {
        var config = localValues[property.id];
        if(config == null)
            config = JSON.parse(property.configuration);
        return config;
    }

    const usersListRender = (propertyId) => {
        return (
            <DataGrid
                dataSource={availableUsers}
                columns={[
                    { key: "firstName", dataField: "firstName", caption: t("#_moduleproperties_10") },
                    { key: "lastName", dataField: "lastName", caption: t("#_moduleproperties_13") }
                ]}
                keyExpr="id"
                selectedRowKeys={localValues[propertyId]}
                hoverStateEnabled={true}
                onSelectionChanged={v => {
                    updateModuleData(propertyId, v.selectedRowKeys)
                    const message = v.selectedRowKeys.length === 0 ? t("#_moduleproperties_validators_1") : "";
                    setUserSelectionErrorMessage(message)
                }}
            height="100%">
                <Selection
                    allowSelectAll={false}
                    deferred={false}
                    mode='multiple' 
                    selectAllMode='allPages'
                    showCheckBoxesMode='always'
                />
                <Scrolling mode="virtual" preloadEnabled={true}/>
                <Paging enabled={true} pageSize={10} />
                <FilterRow visible={true} />
            </DataGrid>
        );
    }

    const popupToolbarItems = commonPopupToolbarItems({ 
        onSave: () => {
            const success = saveDataInParent()
            if (success)
                popup.close()
        }, 
        onCancel: () => popup.close(), 
        t: t 
    })

    var manualAcceptVisible = false;

    const GetControl = (key, property) => {
        const dependentPropertyKey = property.dependentPropertyKey
        const dependentPropertyValue = property.dependentPropertyValue
        
        if(property.manualAcceptEnablingValue != null)
        {
            var activatingValue = localValues[property.id] == property.manualAcceptEnablingValue;
            if(activatingValue)
                manualAcceptVisible = true;
        }
        const manualAcceptVisibility = !property.isManualAcceptProperty || manualAcceptVisible;
        const dependentPropertyVisibility = dependentPropertyKey === null || localValues[dependentPropertyKey] === dependentPropertyValue;
        const visible = manualAcceptVisibility && dependentPropertyVisibility;

        if (property.type === "combobox") {
            let defaultValue = property.values.filter(x => x.id === localValues[property.id])[0]
            if (defaultValue === undefined) {
                defaultValue = property.values[0]
            }
            return <GroupItem key={key} caption={t(property.label)} visible = {visible}>
                    <SelectBox items={property.values}
                        defaultValue={defaultValue}
                        itemRender={x => t(x.label)}
                        fieldRender={x => <TextBox value={t(x.label)} readOnly={true} />}
                        onValueChanged={(v) => updateModuleData(property.id, v.value.id)}
                    />
            </GroupItem>
        }

        if (property.type === "condition") {
            const config = JSON.parse(property.configuration);
            const fields = config.map((x) => {
                let field = { dataField: x.PermanentName, dataType: x.FilterDataType, caption: x.Label ? t(x.Label) : x.PermanentName };

                if (x.DataSource && x.DataSource.length > 0)
                    field.lookup = { dataSource: x.DataSource.map(x => ({ Key: x.Key, Value: t(x.Value) })), valueExpr: "Key", displayExpr: "Value" };

                if (field.dataType === "date") {
                    field.editorOptions = { dateSerializationFormat: "yyyy-MM-dd" };
                }

                return field;
            })

            const EqualEditorComponent = (props) => {
                const onValueChanged = (e) => {
                    props.data.setValue(e.value && e.value.length ? e.value : null);
                }

                const data = fields.map(x => ({ Id: x.dataField, Name: x.caption}));
                return (
                    <SelectBox
                        dataSource={data}
                        valueExpr="Id"
                        displayExpr="Name"
                        searchEnabled={true}
                        width={500} 
                        onValueChanged={onValueChanged}
                    />
                )
            }

            const NotEqualEditorComponent = (props) => {
                const onValueChanged = (e) => {
                    props.data.setValue(e.value && e.value.length ? e.value : null);
                }
                
                const data = fields.map(x => ({ Id: x.dataField, Name: x.caption}));
                return (
                    <SelectBox
                        dataSource={data}
                        valueExpr="Id"
                        displayExpr="Name"
                        searchEnabled={true}
                        width={500} 
                        onValueChanged={onValueChanged}
                    />
                )
            }

            const GreaterThanEditorComponent = (props) => {
                const onValueChanged = (e) => {
                    props.data.setValue(e.value && e.value.length ? e.value : null);
                }
                
                const data = fields.map(x => ({ Id: x.dataField, Name: x.caption}));
                return (
                    <SelectBox
                        dataSource={data}
                        valueExpr="Id"
                        displayExpr="Name"
                        searchEnabled={true}
                        width={500} 
                        onValueChanged={onValueChanged}
                    />
                )
            }

            const LessThanEditorComponent = (props) => {
                const onValueChanged = (e) => {
                    props.data.setValue(e.value && e.value.length ? e.value : null);
                }
                
                const data = fields.map(x => ({ Id: x.dataField, Name: x.caption}));
                return (
                    <SelectBox
                        dataSource={data}
                        valueExpr="Id"
                        displayExpr="Name"
                        searchEnabled={true}
                        width={500} 
                        onValueChanged={onValueChanged}
                    />
                )
            }

            const IsOnNipListEditorComponent = (props) => {
                const onValueChanged = (e) => {
                    props.data.setValue(e.value && e.value.length ? e.value : null);
                }
                
                console.error("nipList");
                console.error(nipList);

                const data = nipList.map(x => ({ Id: x.key, Name: x.value}));
                return (
                    <SelectBox
                        dataSource={data}
                        valueExpr="Id"
                        displayExpr="Name"
                        searchEnabled={true}
                        width={500} 
                        onValueChanged={onValueChanged}
                    />
                )
            }

            const customizeText = (e) => {
                if (e.value) {
                    return fields.find(x => x.dataField == e.value).caption;
                }

                return "";
            }

            const customizeNipText = (e) => {
                if (e.value) {
                    return nipList.find(x => x.key == e.value).value;
                }

                return "";
            }

            return <GroupItem key={key} caption={t(property.label)} visible = {visible}>
                    <FilterBuilder fields={fields}
                        defaultValue={localValues[property.id]}
                        groupOperations={["and", "or"]}
                        onValueChanged={(v) => { updateModuleData(property.id, v.value); }}
                    >
                        <CustomOperation
                            name="equalToField"
                            caption="Równe wartości pola"
                            icon="equal"
                            dataTypes={['string', 'number', 'date', 'boolean', 'object', 'datetime']}
                            customizeText={customizeText}
                            editorComponent={EqualEditorComponent}
                        />
                        <CustomOperation
                            name="notEqualToField"
                            caption="Różne od wartości pola"
                            icon="notequal"
                            dataTypes={['string', 'number', 'date', 'boolean', 'object', 'datetime']}
                            customizeText={customizeText}
                            editorComponent={NotEqualEditorComponent}
                        />
                        <CustomOperation
                            name="greaterThanField"
                            caption="Większe od wartości pola"
                            icon="greater"
                            dataTypes={['number', 'date', 'datetime']}
                            customizeText={customizeText}
                            editorComponent={GreaterThanEditorComponent}
                        />
                        <CustomOperation
                            name="lessThanField"
                            caption="Mniejsze od wartości pola"
                            icon="less"
                            dataTypes={['number', 'date', 'datetime']}
                            customizeText={customizeText}
                            editorComponent={LessThanEditorComponent}
                        />
                        <CustomOperation
                            name="isContractor"
                            caption="NIP jest w bazie kontrahentów"
                            icon="user"
                            dataTypes={['string']}
                            hasValue={false}
                        />
                        <CustomOperation
                            name="isOnNipList"
                            caption="Jest na liście NIPów"
                            icon="group"
                            dataTypes={['string']}
                            customizeText={customizeNipText}
                            editorComponent={IsOnNipListEditorComponent}
                        />
                    </FilterBuilder>
            </GroupItem>
        }

        if (property.type === "userSelection") {
            return <GroupItem key={key} caption={t(property.label)}  visible = {visible} >
                    <DropDownBox
                        deferRendering={false}
                        showClearButton={false}
                        contentRender={() => usersListRender(property.id)}
                        validationErrors={[{"type": "custom", "message": [userSelectionErrorMessage]}]}
                        isValid={userSelectionErrorMessage.length === 0}
                        fieldRender={x => {
                            const currentControlValue = localValues[property.id]
                            var countSelected = (currentControlValue === undefined || currentControlValue === null) ? 0 : currentControlValue.length
                            return <TextBox readOnly={true} value={(countSelected === 0) ? t("#_moduleproperties_18") : t("#_moduleproperties_19") + currentControlValue.length}></TextBox >
                        }}
                    >
                    </DropDownBox>
            </GroupItem>
        }

        if(property.type == "tags"){
            const config = GetTagsConfig(property)
            return  <GroupItem key={key} colCount={2} colSpan={3} caption={t(property.label)} itemType="group" visible = {visible}>
                    {config.map((tag)=> <SimpleItem>
                                            <TextBox label={tag.Name}
                                                labelMode="static"
                                                 value={tag.Value} 
                                                 onValueChanged={(v)=>{updateTag(property,tag.Name,v.value)}}/></SimpleItem>)}
            </GroupItem>
        }

        if(property.type == "datePicker"){
            const dateBoxEditorOptions = { 
                type: 'date', pickerType: 'calendar', 
                readOnly: false, visible: true,
                showDropDownButton: true, dropDownButtonTemplate: 'renderEventIcon',
                invalidDateMessage: t("invalidDateMessage"),
                acceptCustomValue: false,
                openOnFieldClick: true,
                showClearButton: true,
                onValueChanged: (v) => updateModuleData(property.id, v.value)
              }

            return <GroupItem key={key} caption={t(property.label)} visible = {visible}>
                    <SimpleItem editorType="dxDateBox"
                        editorOptions = {dateBoxEditorOptions}>
                        <Template name='renderEventIcon' render={renderEventIcon} />
                    </SimpleItem>
            </GroupItem>
        }

        return <div key={key}></div>
    }

    const moduleDefinition = moduleDefinitions.filter(x => x.id === step.moduleId)
    // if (moduleDefinition.length !== 1) return <>Błąd</>
    const currentModule = moduleDefinition[0]
    var key = 1

    const htmlDescription = {__html:t(currentModule.detailedDescription)}
    const popup = GenericPopup({ 
        onHiding: () => popup.close(),
        content:
        <div>
          <ScrollView>
            <div className="widget-container">
                <Form key="propForm" className={"xfx-form"} ref={formRef} validationGroup={validationGroup}>        
                    <SimpleItem>
                        <Label><div dangerouslySetInnerHTML={htmlDescription}/></Label>
                    </SimpleItem>
                    {currentModule.properties !== null && currentModule.properties.filter(p=>p.id === "condition").map(p => GetControl(key++, p))}
                    <SimpleItem>
                        <Label>{t("#_moduleproperties_24")}</Label>
                    </SimpleItem>
                    {currentModule.properties !== null && currentModule.properties.filter(p=>p.id !== "condition").map(p => GetControl(key++, p))}
                </Form>
            </div>
            </ScrollView>
        </div>,
        toolbarItems: popupToolbarItems,
        title: t("#_pipelineeditor_19") + (selectedModule !== null ? moduleNames[selectedModule.moduleId] : ""),
        width: "1200px",
        height: "800px",
        isVisible: settingsVisible,
        setIsVisible: setSettingsVisible
      })

    return <>
        {popup.popup}
    </>;
}

export default ModuleProperties;
