import { useEffect, useState, FC } from "react"
import 'devextreme-react/autocomplete';
import BreadCrumbs from 'components/breadCrumbs/BreadCrumbs';
import { getNode } from 'components/breadCrumbs/getNode';
import { useTranslation } from 'react-i18next';
import GenericOdataGrid from 'pages/Invoices/GenericOdataGrid';
import { OdataEndpoints } from 'api/XfXApi';
import { utcToLocalWithSeconds } from 'utils/utcToLocal';

interface SecurityLogProps {
  organizationId: string;
}

interface ColumnData {
  dataField: string;
  dataType?: string;
  caption: string;
  width: string;
  minWidth?: number;
  visible: boolean;
  sortOrder?: string;
  allowFiltering?: false;
  cellRender?: (x: any) => any;
}

const FailuresLog: FC<SecurityLogProps> = ({ organizationId }) => {
  const { t } = useTranslation();
  const [columns, setColumns] = useState<ColumnData[]>([]);

  const [dataSourceOptions, setDataSourceOptions] = useState()
  const [blockAutoRefresh, setBlockAutoRefresh] = useState(false)

  useEffect(() => {
    const data = ({ t }: { t: any }): ColumnData[] => [
      { dataField: "Id", caption: "Id", width: "auto", visible: false, sortOrder: 'desc' },

      {
        dataField: "FailureEvent", dataType: "string", caption: t("security-event-type"), width: "*", minWidth: 130, visible: true, allowFiltering: false,
        cellRender: x => t("failure-type-" + x.value?.toLowerCase())
      },
      { dataField: "TimestampUtc", dataType: "datetime", caption: t("security-event-date"), minWidth: 130, width: "auto", visible: true, cellRender: x => utcToLocalWithSeconds(x.value) },
    ];
    setColumns(data({ t }));
  }, [t]);

  return (
    <>
      <div style={{ marginBottom: "20px" }}>
        <BreadCrumbs node={getNode({ componentName: 'FailuresLog', t })} breadCrumbs={undefined} />
      </div>
      {/* {detailsPopup.popup} */}
      <div id="dataGrid-documentTemplates" className="dataGrid">
        {GenericOdataGrid({
          data: columns,
          endpoint: OdataEndpoints.FailureLog(),
          setSelectedItem: undefined,
          id: undefined, ref: undefined,
          setSelectedItems: undefined,
          defaultFilter: undefined,
          defaultSort: undefined,
          filterButtons: undefined,
          updateDataGridFilter: undefined,
          multiSelectionMode: undefined,
          setLastUsedQueryParms: undefined,
          setDefaultSort: undefined,
          gridKey: undefined,
          useFilterBuilder: false,
          dataSourceOptions: dataSourceOptions,
          setDataSourceOptions,
          masterDetail: undefined,
          odataCustomFilterFieldName: undefined,
          blockAutoRefresh: blockAutoRefresh,
          setBlockAutoRefresh: setBlockAutoRefresh
        })}
      </div>
    </>
  );
}

export default FailuresLog;