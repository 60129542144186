import React, {
  useState,
  useMemo,
  useRef,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from 'react';
import Accordion from 'devextreme-react/accordion';
import DataGrid, { Column, FilterRow, Selection } from 'devextreme-react/data-grid';
import { CheckBox } from 'devextreme-react/check-box';
import CustomItem from './CustomItem';
import { XfXApi } from 'api/XfXApi';
import { ExportableConfigurationItemsDto } from 'api/openapi';
import { useTranslation } from 'react-i18next';
import notify from 'devextreme/ui/notify';
import LoadPanel from 'devextreme-react/load-panel';

interface AccordionItem {
  title: string;
  content: React.ReactNode;
  dataGridRef: React.RefObject<DataGrid>;
}

export interface ExpandersWithGridsRef {
  save: () => Promise<boolean>;
}

interface ExpandersWithGridsProps {
  setAnySelected: (anySelected: boolean) => void;
}

const ExpandersWithGrids = forwardRef<ExpandersWithGridsRef, ExpandersWithGridsProps>(
  (props, ref) => {
    const { setAnySelected } = props;

    const [selectAll, setSelectAll] = useState(false);
    const [allData, setAllData] = useState<ExportableConfigurationItemsDto | null>(null);
    const [loading, setLoading] = useState(true);
    const { t } = useTranslation();

    useEffect(() => {
      setLoading(true);
      XfXApi.ConfigExportApi.apiTenantIdConfigExportGet(XfXApi.GetTenantId())
        .then((response) => {
          setAllData(response.data);
          setLoading(false);
        })
        .catch((error) => {
          notify(t('exporter_error_fetch_data'), 'error', 5000);
          setLoading(false);
        });
    }, []);

    const organizationGridRef = useRef<DataGrid>(null);
    const userGridRef = useRef<DataGrid>(null);
    const roleGridRef = useRef<DataGrid>(null);
    const seriesGridRef = useRef<DataGrid>(null);
    const systemGridRef = useRef<DataGrid>(null);
    const pathsGridRef = useRef<DataGrid>(null);

    const checkIfAnySelected = () => {
      const grids = [
        organizationGridRef.current,
        userGridRef.current,
        roleGridRef.current,
        seriesGridRef.current,
        systemGridRef.current,
        pathsGridRef.current,
      ];

      const anySelected = grids.some((gridRef) => {
        const selectedKeys = gridRef?.instance.getSelectedRowKeys() || [];
        return selectedKeys.length > 0;
      });

      setAnySelected(anySelected);
    };

    const handleSelectAllChange = (value: boolean) => {
      setSelectAll(value);

      if (value) {
        organizationGridRef.current?.instance.selectAll();
        userGridRef.current?.instance.selectAll();
        roleGridRef.current?.instance.selectAll();
        seriesGridRef.current?.instance.selectAll();
        systemGridRef.current?.instance.selectAll();
        pathsGridRef.current?.instance.selectAll();
      } else {
        organizationGridRef.current?.instance.deselectAll();
        userGridRef.current?.instance.deselectAll();
        roleGridRef.current?.instance.deselectAll();
        seriesGridRef.current?.instance.deselectAll();
        systemGridRef.current?.instance.deselectAll();
        pathsGridRef.current?.instance.deselectAll();
      }

      checkIfAnySelected();
    };

    const save = async () => {
      const organizationIds = organizationGridRef.current?.instance.getSelectedRowKeys() || [];
      const userIds = userGridRef.current?.instance.getSelectedRowKeys() || [];
      const roleIds = roleGridRef.current?.instance.getSelectedRowKeys() || [];
      const seriesIds = seriesGridRef.current?.instance.getSelectedRowKeys() || [];
      const systemIds = systemGridRef.current?.instance.getSelectedRowKeys() || [];
      const exitPathIds = pathsGridRef.current?.instance.getSelectedRowKeys() || [];

      try {
        const response = await XfXApi.ConfigExportApi.apiTenantIdConfigExportPost(
          XfXApi.GetTenantId(),
          {
            organizationIds,
            userIds,
            roleIds,
            seriesIds,
            systemIds,
            exitPathIds,
          }
        );

        const date = new Date();
        const formattedDate = `${date.getFullYear()}-${String(
          date.getMonth() + 1
        ).padStart(2, '0')}-${String(date.getDate()).padStart(2, '0')}`;
        const formattedTime = `${String(date.getHours()).padStart(2, '0')}${String(
          date.getMinutes()
        ).padStart(2, '0')}${String(date.getSeconds()).padStart(2, '0')}`;

        const filename = `Eksport konfiguracji_${formattedDate}_${formattedTime}.json`;

        const formattedJson = JSON.stringify(response.data, null, 2);

        const blob = new Blob([formattedJson], { type: 'application/json' });

        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = filename;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(url);

        return true;
      } catch (error) {
        notify(t('exporter_error_save_config'), 'error', 5000);
        return false;
      }
    };

    useImperativeHandle(ref, () => ({
      save,
    }));

    const renderGrid = (
      data: any[],
      keyField: string,
      gridRef: React.RefObject<DataGrid>,
      columns: React.ReactNode[]
    ) => (
      <DataGrid
        className="xfx-exporter-grid"
        dataSource={data}
        keyExpr={keyField}
        ref={gridRef}
        selection={{ mode: 'multiple', showCheckBoxesMode: 'always' }}
        onSelectionChanged={checkIfAnySelected}
      >
        <FilterRow visible={true} applyFilter={'auto'} />

        <Selection mode="multiple" />
        {columns}
      </DataGrid>
    );

    const items: AccordionItem[] | null = useMemo(() => {
      if (!allData) {
        return null;
      }
      return [
        {
          title: t('exporter_organizations') as string,
          content: renderGrid(
            allData.organizationData ?? [],
            'id',
            organizationGridRef,
            [
              <Column key="taxId" dataField="taxId" caption={t('exporter_tax_id') as string} />,
              <Column
                key="organizationName"
                dataField="organizationName"
                caption={t('exporter_organization_name') as string}
              />,
            ]
          ),
          dataGridRef: organizationGridRef,
        },
        {
          title: t('exporter_users') as string,
          content: renderGrid(
            allData.userData ?? [],
            'id',
            userGridRef,
            [
              <Column key="login" dataField="login" caption={t('exporter_username') as string} />,
              <Column
                key="firstName"
                dataField="firstName"
                caption={t('exporter_first_name') as string}
              />,
              <Column
                key="lastName"
                dataField="lastName"
                caption={t('exporter_last_name') as string}
              />,
            ]
          ),
          dataGridRef: userGridRef,
        },
        {
          title: t('exporter_roles') as string,
          content: renderGrid(
            allData.roleData ?? [],
            'id',
            roleGridRef,
            [
              <Column
                key="roleName"
                dataField="roleName"
                caption={t('exporter_role_name') as string}
              />,
              <Column
                key="roleDescription"
                dataField="roleDescription"
                caption={t('exporter_role_description') as string}
              />,
            ]
          ),
          dataGridRef: roleGridRef,
        },
        {
          title: t('exporter_series') as string,
          content: renderGrid(
            allData.seriesData ?? [],
            'id',
            seriesGridRef,
            [
              <Column key="name" dataField="name" caption={t('exporter_name') as string} />,
              <Column key="format" dataField="format" caption={t('exporter_format') as string} />,
              <Column
                key="invoiceType"
                dataField="invoiceType"
                caption={t('exporter_invoice_type') as string}
              />,
            ]
          ),
          dataGridRef: seriesGridRef,
        },
        {
          title: t('exporter_systems') as string,
          content: renderGrid(
            allData.systemData ?? [],
            'id',
            systemGridRef,
            [
              <Column key="name" dataField="name" caption={t('exporter_system_name') as string} />,
              <Column
                key="organizations"
                dataField="organizations"
                caption={t('exporter_organizations_field') as string}
              />,
            ]
          ),
          dataGridRef: systemGridRef,
        },
        {
          title: t('exporter_paths') as string,
          content: renderGrid(
            allData.pathsData ?? [],
            'id',
            pathsGridRef,
            [
              <Column key="name" dataField="name" caption={t('exporter_name') as string} />,
              <Column key="taxId" dataField="taxId" caption={t('exporter_tax_id') as string} />,
              <Column
                key="organization"
                dataField="organization"
                caption={t('exporter_organization_field') as string}
              />,
            ]
          ),
          dataGridRef: pathsGridRef,
        },
      ];
    }, [allData]);

    return (
      <div id="accordion-container">
        {loading && (
          <LoadPanel
            visible={loading}
            shading={true}
            showIndicator={true}
            showPane={true}
          />
        )}
        {!loading && (
          <>
            <p>{t('exporter_select_areas_export')}</p>
            <CheckBox
              className="xfx-exporter-select-all"
              text={t('exporter_select_all') as string}
              value={selectAll}
              onValueChanged={(e) => handleSelectAllChange(e.value)}
            />
            {items && (
              <Accordion
                collapsible={true}
                multiple={true}
                deferRendering={false}
                items={items}
                id="accordion"
                itemRender={CustomItem}
                defaultSelectedIndex={-1}
              />
            )}
          </>
        )}
      </div>
    );
  }
);

export default ExpandersWithGrids;
