export const isValidNIP = (value: string): boolean => {
    const weights = [6, 5, 7, 2, 3, 4, 5, 6, 7];
    let sum = 0;

    if (value.length !== 10) {
        return false;
    }

    for (let i = 0; i < 9; i++) {
        sum += weights[i] * parseInt(value[i], 10);
    }

    return sum % 11 === parseInt(value[9], 10);
}