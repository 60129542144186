import { useEffect, useState } from "react"
import GenericPopup from 'components/popup/GenericPopup';
import { XfXApi } from 'api/XfXApi';
import { convertToColumns } from 'pages/Invoices/GenericGrid'
import { GenericGrid } from 'pages/Invoices/GenericGrid'
import { RestrictionsColumnRenderer } from './Renderers'
import SelectBox from 'devextreme-react/select-box';
import { useTranslation } from 'react-i18next';

const systemContextId = "system"
const systemContext = ( t ) => {
  return {
    id: systemContextId, label: t("#_effectiveprivileges_2")
  }
}

const EffectivePrivileges = (id2Privilege, setBlockAutoRefresh) => {
  const { t } = useTranslation()
  const [data, setData] = useState()
  const [dataForContext, setDataForContext] = useState()
  const [availableContexts, setAvailableContexts] = useState([])
  const [selectedContextId, setSelectedContextId] = useState(systemContextId)
  const [dataSourceOptions, setDataSourceOptions] = useState()
  const [isVisible, setIsVisible] = useState(false)

  useEffect(() => {
    XfXApi.Organization.apiTenantIdOrganizationOrganizationsGet(XfXApi.GetTenantId())
      .then((resp) => {
        setAvailableContexts([systemContext(t), ...resp.data.map(x => { return { id: x.nip, label: `${x.fullName ?? ''} (${x.nip ?? ''})` } })])
      })
  }, [t])

  useEffect(() => {
    if (data) {
      setDataForContext(data[selectedContextId] ?? null)
    }
  }, [data, selectedContextId])

  const columns = convertToColumns([
    { key: "priviligeName", dataField: "privilegeId", caption: t("#_effectiveprivileges_3"), dataType: "string", visible: true, cellRender: ({ value }) => value ? id2Privilege[value].name : "-" },
    { key: "roles", dataField: "roleNames", caption: t("#_effectiveprivileges_5"), dataType: "string" },
    {
      key: "saleDocuments", caption: t("#_effectiveprivileges_7"), cellRender: x => RestrictionsColumnRenderer(t("#_effectiveprivileges_8"), x.row.data.salesNips, x.row.data.saleTags, t),
      visible: selectedContextId !== null && selectedContextId !== systemContextId
    },
    {
      key: "purchaseDocuments", caption: t("#_effectiveprivileges_10"), cellRender: x => RestrictionsColumnRenderer(t("#_effectiveprivileges_11"), x.row.data.purchaseNips, x.row.data.purchaseTags, t),
      visible: selectedContextId !== null && selectedContextId !== systemContextId
    }
  ])

  const grid = GenericGrid({ 
    dataSource: dataForContext, 
    columns: columns, 
    visible: true,
    dataSourceOptions: dataSourceOptions
  })

  const popup = GenericPopup({
    onHiding: () => popup.close(),
    buttons: [{
      text: t("#_effectiveprivileges_12"),
      location: "after",
      action: popup => popup.close()
    }],
    title: t("#_effectiveprivileges_14"),
    width: "80%",
    height: "80%",
    content:
      <>
        <SelectBox
          placeholder={t("#_effectiveprivileges_15")}
          showClearButton={false}
          items={availableContexts}
          valueExpr="id"
          displayExpr="label"
          label={t("#_effectiveprivileges_17")}
          key={'id'}
          value={selectedContextId}
          onValueChanged={({ value }) => {
            setSelectedContextId(value)
          }}
        />
        {grid}
      </>,
    isVisible: isVisible,
    setIsVisible: setIsVisible,
    setBlockAutoRefresh: setBlockAutoRefresh
  })

  const showUserPrivileges = (user) => {
    if (user === null) return

    XfXApi.User.apiTenantIdUserUserPrivilegesGet(XfXApi.GetTenantId(), user.id ? user.id : (user.Id?._value ?? user.Id))
      .then((response) => {
        setData(response.data)
        popup.show()
      })
  }

  return { showUserPrivileges, popup }
}

export default EffectivePrivileges
