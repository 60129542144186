import { FormField } from "./model";
import { hideCssClass, showCssClass } from "./styleHelpers";

export let staticHack: any | undefined

export const hack = (fieldsVisiblityMap: { [key: string]: { isVisible: boolean, field: FormField }; },
    invoiceValues: { [key: string]: any; }) => {

    staticHack = () => {
        hack(fieldsVisiblityMap, invoiceValues)
    }
    function findKeyByFieldIdentifier(obj: any, identifier: string) {
        return Object.keys(obj).find(key => obj[key].field.Identifier === identifier);
    }

    const keyForTest = findKeyByFieldIdentifier(fieldsVisiblityMap, "Fa.DaneFaKorygowanej");
    if (keyForTest !== undefined) {
        const addr = fieldsVisiblityMap[keyForTest].field.DependentFields?.find(x => x.Identifier === "Fa.DaneFaKorygowanej[].NrKSeFFaKorygowanej")?.Address

        if (addr !== undefined) {
            Object.keys(invoiceValues).forEach(key => {
                const match = key.match(/_Fa\.DaneFaKorygowanej\[(\d+)\]\./);
                if (match) {
                    if (invoiceValues[key] === "TAK") {
                        showCssClass(`invoice-editor-field-${addr}_${match[1]}`);
                    } else {
                        hideCssClass(`invoice-editor-field-${addr}_${match[1]}`);
                    }
                }
            })
        };
    }
}