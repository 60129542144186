import React, { useMemo } from 'react';
import { Fa1InvoiceItem, TStawkaPodatku } from './Fa1InvoiceItem';
import DataGrid, { Column, Format, Summary, TotalItem } from 'devextreme-react/data-grid';
import { FieldGroup, FormField } from './model';

interface TaxRatesSummaryProps {
    invoiceItems: Fa1InvoiceItem[];
    fieldsVisiblityMap: { [key: string]: { isVisible: boolean, field: FormField }; }
    FieldGroup: FieldGroup,
    showCurrency: boolean;
    noRowsMode: boolean;
    isCorrection: boolean | null;
}

interface TaxRatesSummaryRow {
    isSpecified: boolean;
    netto: number;
    vat: number;
    vatPln: number;
    brutto: number;
}

const calculateRateSummary = (taxRate: string, invoiceItems: Fa1InvoiceItem[]): TaxRatesSummaryRow => {
    const isSpecified = invoiceItems.some(item => item.stawkaPodatku === taxRate);

    const netto = invoiceItems
        .filter(item => item.stawkaPodatku === taxRate)
        .reduce((acc, item) => acc + item.wartoscSprzedazyNetto, 0);

    const brutto = invoiceItems
        .filter(item => item.stawkaPodatku === taxRate)
        .reduce((acc, item) => acc + item.wartoscSprzedazyBrutto, 0);

    const vat = invoiceItems
        .filter(item => item.stawkaPodatku === taxRate)
        .reduce((acc, item) => acc + item._vat, 0);

    const vatPln = invoiceItems
        .filter(item => item.stawkaPodatku === taxRate)
        .reduce((acc, item) => acc + item._vatInPln, 0);

    return { isSpecified, netto, vat, brutto, vatPln };
};

const calculateRateSummaryForCorrection = (taxRate: string, invoiceItems: Fa1InvoiceItem[]): TaxRatesSummaryRow => {
    const isSpecified = invoiceItems.some(item => item.stawkaPodatku === taxRate);

    const netto = invoiceItems
        .filter(item => item.stawkaPodatku === taxRate)
        .reduce((acc, item) => item.stanPrzed ? acc - item.wartoscSprzedazyNetto : acc + item.wartoscSprzedazyNetto, 0);

    const brutto = invoiceItems
        .filter(item => item.stawkaPodatku === taxRate)
        .reduce((acc, item) => item.stanPrzed ? acc - item.wartoscSprzedazyBrutto : acc + item.wartoscSprzedazyBrutto, 0);

    const vat = invoiceItems
        .filter(item => item.stawkaPodatku === taxRate)
        .reduce((acc, item) => item.stanPrzed ?  acc - item._vat : acc + item._vat, 0);

    const vatPln = invoiceItems
        .filter(item => item.stawkaPodatku === taxRate)
        .reduce((acc, item) => item.stanPrzed ? acc - item._vatInPln : acc + item._vatInPln, 0);

    return { isSpecified, netto, vat, brutto, vatPln };
};
 
export const getTokensForSummary = (invoiceItems: Fa1InvoiceItem[], withCurrency: boolean, forCorrection: boolean | null, noRowsMode: boolean) => {
    if (forCorrection === true) {
        return getTokensForSummaryInternal(invoiceItems, withCurrency, noRowsMode, forCorrection);
    }
    else {
        return getTokensForSummaryInternal(invoiceItems, withCurrency, noRowsMode, forCorrection);
    }
}

const getTokensForSummaryInternal = (
    invoiceItems: Fa1InvoiceItem[], withCurrency: boolean, noRowsMode: boolean, forCorrection: boolean | null
) => {
    let ret: { [key: string]: any } = {}
    let calculateFunc: (rate: TStawkaPodatku, items: Fa1InvoiceItem[]) => TaxRatesSummaryRow;
    if (forCorrection === true) {
        calculateFunc = calculateRateSummaryForCorrection;
    }
    else {
        calculateFunc = calculateRateSummary
    }

    if (noRowsMode && forCorrection) {
        ret["Fa.P_15"] = 0;
        return ret;
    }

    const r23 = calculateFunc(TStawkaPodatku.Item23, invoiceItems)
    const r22 = calculateFunc(TStawkaPodatku.Item22, invoiceItems)
    const r8 = calculateFunc(TStawkaPodatku.Item8, invoiceItems)
    const r7 = calculateFunc(TStawkaPodatku.Item7, invoiceItems)
    const r5 = calculateFunc(TStawkaPodatku.Item5, invoiceItems)
    const r4 = calculateFunc(TStawkaPodatku.Item4, invoiceItems)
    const r3 = calculateFunc(TStawkaPodatku.Item3, invoiceItems)
    const r0WDT = calculateFunc(TStawkaPodatku.Item0WDT, invoiceItems)  
    const r0Eksport = calculateFunc(TStawkaPodatku.Item0Eksport, invoiceItems)  
    const r0Krajowe = calculateFunc(TStawkaPodatku.Item0Krajowe, invoiceItems)  
    const rnpNaPodstawie0 = calculateFunc(TStawkaPodatku.npNaPodstawie, invoiceItems)  
    const rnpZWylaczeniem = calculateFunc(TStawkaPodatku.npZWylaczeniem, invoiceItems)  
    const oo = calculateFunc(TStawkaPodatku.oo, invoiceItems)  
    const zw = calculateFunc(TStawkaPodatku.zw, invoiceItems)
    const vatMarza = calculateFunc(TStawkaPodatku.vatMarza, invoiceItems)

    if (r23.isSpecified || r22.isSpecified) {
        ret["Fa.P_13_1"] = r23.netto + r22.netto
        ret["Fa.P_14_1"] = r23.vat + r22.vat
        if (withCurrency) {
            ret["Fa.P_14_1W"] = r23.vatPln + r22.vatPln
        }
    }

    if (r8.isSpecified || r7.isSpecified) {
        ret["Fa.P_13_2"] = r8.netto + r7.netto
        ret["Fa.P_14_2"] = r8.vat + r7.vat
        if (withCurrency) {
            ret["Fa.P_14_2W"] = r8.vatPln + r7.vatPln
        }
    }

    if (r5.isSpecified) {
        ret["Fa.P_13_3"] = r5.netto
        ret["Fa.P_14_3"] = r5.vat
        if (withCurrency) {
            ret["Fa.P_14_3W"] = r5.vatPln
        }
    }

    if (r3.isSpecified || r4.isSpecified) {
        ret["Fa.P_13_4"] = r3.netto + r4.netto
        ret["Fa.P_14_4"] = r3.vat + r4.vat
        if (withCurrency) {
            ret["Fa.P_14_4W"] = r5.vatPln
        }
    }

    if (oo.isSpecified) {
        ret["Fa.P_13_10"] = oo.netto
    }

    if (r0WDT.isSpecified) {
        ret["Fa.P_13_6_2"] = r0WDT.netto
    }

    if (r0Eksport.isSpecified) {
        ret["Fa.P_13_6_3"] = r0Eksport.netto
    }

    if (r0Krajowe.isSpecified) {
        ret["Fa.P_13_6_1"] = r0Krajowe.netto
    }

    if (rnpNaPodstawie0.isSpecified) {
        ret["Fa.P_13_9"] = rnpNaPodstawie0.netto
    }

    if (rnpZWylaczeniem.isSpecified) {
        ret["Fa.P_13_8"] = rnpZWylaczeniem.netto
    }

    if (zw.isSpecified) {
        ret["Fa.P_13_7"] = zw.netto
    }

    if (vatMarza.isSpecified) {
        ret["Fa.P_13_11"] = vatMarza.netto
    }
    
    ret["Fa.P_15"] = r23.brutto + r22.brutto + r8.brutto + r7.brutto + r5.brutto + r4.brutto + r3.brutto + zw.brutto  + oo.brutto + r0WDT.brutto + r0Eksport.brutto + r0Krajowe.brutto + rnpNaPodstawie0.brutto + rnpZWylaczeniem.brutto + vatMarza.brutto 
    return ret
}

const TaxRatesSummary: React.FC<TaxRatesSummaryProps> = ({ invoiceItems, fieldsVisiblityMap, FieldGroup, showCurrency, noRowsMode, isCorrection }) => {

    let calculateFunc: (rate: TStawkaPodatku, items: Fa1InvoiceItem[]) => TaxRatesSummaryRow;
    if (isCorrection === true) {
        calculateFunc = calculateRateSummaryForCorrection;
    }
    else {
        calculateFunc = calculateRateSummary
    }

    const dataSource = [
        { description: "Suma wartości sprzedaży ze stawką 23%", ...calculateFunc(TStawkaPodatku.Item23, invoiceItems), },
        { description: "Suma wartości sprzedaży ze stawką 22%", ...calculateFunc(TStawkaPodatku.Item22, invoiceItems) },
        { description: "Suma wartości sprzedaży ze stawką 8%", ...calculateFunc(TStawkaPodatku.Item8, invoiceItems) },
        { description: "Suma wartości sprzedaży ze stawką 7%", ...calculateFunc(TStawkaPodatku.Item7, invoiceItems) },
        { description: "Suma wartości sprzedaży ze stawką 5%", ...calculateFunc(TStawkaPodatku.Item5, invoiceItems) },
        { description: "Suma wartości sprzedaży ze stawką 4%", ...calculateFunc(TStawkaPodatku.Item4, invoiceItems) },
        { description: "Suma wartości sprzedaży ze stawką 3%", ...calculateFunc(TStawkaPodatku.Item3, invoiceItems) },
        { description: "Suma wartości sprzedaży ze stawką 0% - WDT", ...calculateFunc(TStawkaPodatku.Item0WDT, invoiceItems) },
        { description: "Suma wartości sprzedaży ze stawką 0% - Eksport", ...calculateFunc(TStawkaPodatku.Item0Eksport, invoiceItems) },
        { description: "Suma wartości sprzedaży ze stawką 0% - Krajowe", ...calculateFunc(TStawkaPodatku.Item0Krajowe, invoiceItems) },
        { description: "Suma wartości sprzedaży ze stawką np z wyłączeniem art. 100 ust 1 pkt 4", ...calculateFunc(TStawkaPodatku.npNaPodstawie, invoiceItems) },
        { description: "Suma wartości sprzedaży ze stawką np na podstawie  art. 100 ust 1 pkt 4", ...calculateFunc(TStawkaPodatku.npZWylaczeniem, invoiceItems) },
        { description: "Suma wartości sprzedaży ze stawką oo", ...calculateFunc(TStawkaPodatku.oo, invoiceItems) },
        { description: "Suma wartości zwolnionej", ...calculateFunc(TStawkaPodatku.zw, invoiceItems) },
    ];


    return (
        <>
            {(!noRowsMode || !isCorrection) && <DataGrid dataSource={dataSource} editing={{ allowUpdating: false }}>
                <Column dataField="description" caption="Kwoty podsumowań" ></Column>
                <Column dataField="netto" caption="Kwota netto" >
                    <Format type="fixedPoint" precision={2} />
                </Column>
                <Column dataField="vat" caption="Kwota VAT" >
                    <Format type="fixedPoint" precision={2} />
                </Column>
                <Column visible={showCurrency} dataField="vatPln" caption="Kwota VAT w PLN" >
                    <Format type="fixedPoint" precision={2} />
                </Column>
                <Column dataField="brutto" caption="Kwota brutto" >
                    <Format type="fixedPoint" precision={2} />
                </Column>
                <Summary>
                    <TotalItem
                        column="brutto"
                        summaryType="sum"
                        displayFormat="Suma: {0}"
                        valueFormat={{
                            type: 'fixedPoint',
                            precision: 2,
                        }}
                    />
                </Summary>
            </DataGrid>}
        </>
    );
};

export default TaxRatesSummary;