import React, { useEffect, useState, useRef } from "react"
import 'devextreme-react/autocomplete';
import notify from 'devextreme/ui/notify';
import CustomStore from 'devextreme/data/custom_store';
import { Form, GroupItem, SimpleItem, Label } from 'devextreme-react/form';
import GenericToolbar from "../Toolbars/GenericToolbar";
import { XfXApi } from 'api/XfXApi';
import BreadCrumbs from 'components/breadCrumbs/BreadCrumbs';
import { getNode } from 'components/breadCrumbs/getNode';
import { useTranslation } from 'react-i18next';
import useFormErrors from 'utils/useFormErrors';
import { commonPopupToolbarItems } from 'components/popup/PopupTools';
import GenericPopup from "components/popup/GenericPopup";
import DeletionPopup from 'components/popup/DeletionPopup';
import OrganizationsDropDown from  'components/dropdown/OrganizationsDropDown.tsx'
import { OdataEndpoints } from 'api/XfXApi';
import { columns } from "./const";
import GenericOdataGrid from 'pages/Invoices/GenericOdataGrid';
import useDataGridFilter from 'utils/useDataGridFilter'

const accountingSystemInit = {
  Id: null,
  Name: '',
  OrganizationIds: []
}

const labelMode = 'static'
const labelLocation = 'left'
const readOnly = false
const showColonAfterLabel = false
const minColWidth = 300
const colCount = 1

const AccountingSystems = () => {
  const { t } = useTranslation()

  const [data, setData] = useState([])

  const editPopupTitle = t("#_accountingsystems_4")
  const addPopupTitle = t("#_accountingsystems_5")

  const [visible, setVisible] = useState(false)

  const [accountingSystem, setAccountingSystem] = useState(accountingSystemInit)

  const [popupTitle, setPopupTitle] = useState(editPopupTitle)
  const [accountingSystemEdit, setAccountingSystemEdit] = useState(false)

  const [organizations, setOrganizations] = useState([])
  const [organizationsValue, setOrganizationsValue] = useState([])
  const [deletionPopupVisible, setDeletionPopupVisible] = useState(false)
  const [deletionPopupContent, setDeletionPopupContent] = useState("")

  const [reloadKey, setReloadKey] = useState(0)

  const [dataSourceOptions, setDataSourceOptions] = useState()

  const [blockAutoRefresh, setBlockAutoRefresh] = useState(false)

  const deletionPopup = DeletionPopup({
    onConfirm: async () => {
      await XfXApi.AccountingSystem.apiTenantIdAccountingSystemDelete(selectedAccountingSystem?.Id?._value ?? selectedAccountingSystem?.Id, XfXApi.GetTenantId())
      setReloadKey(oldVal => oldVal + 1)
    },
    content: deletionPopupContent,
    title: t("#_DeletionPopup_delete"),
    isVisible: deletionPopupVisible,
    setIsVisible: setDeletionPopupVisible,
    t: t,
    setBlockAutoRefresh: setBlockAutoRefresh
  })

  const deleteAccountingSystem = () => {
    setDeletionPopupContent(t("#_DeletionPopup"))
    deletionPopup.show()
  }

  const initialFilter = []
  const { dataGridFilter, updateDataGridFilter } = useDataGridFilter({ initialFilter })
  const defaultSort = { selector: "Name", desc: false }

  const { getFirstError, noErrors, setErrors, clearError } = useFormErrors()

  const [selectedAccountingSystem, setSelectedAccountingSystem] = useState(null)

  const vgBasicAccountingSystemForm = useRef(null)
  const dataGridRef = useRef(null)

  const saveAction = async (e) => {
    try {
      const response = accountingSystemEdit ?
        await XfXApi.AccountingSystem.apiTenantIdAccountingSystemIdPut(accountingSystem.Id, XfXApi.GetTenantId(), accountingSystem) :
        await XfXApi.AccountingSystem.apiTenantIdAccountingSystemPost(XfXApi.GetTenantId(), accountingSystem)

      if (response.status === 200) {
        notify({
          message: `${accountingSystemEdit ? t("#_accountingsystems_13") : t("#_accountingsystems_14")}`,
          position: {
            my: 'center bottom',
            at: 'center bottom',
          },
        }, 'success', 5000);
        hideInfo()
        setReloadKey(oldVal => oldVal + 1)
        dataGridRef.current.instance.clearSelection()
      }
    } catch (error) {
      if (error.response.status === 400) {
        notify({
          message: `${accountingSystemEdit ? t("#_accountingsystems_15") : t("#_accountingsystems_16")} \r\n \r\n` + error.response.data.errors.join('\r\n'),
          position: {
            my: 'center bottom',
            at: 'center bottom',
          },
        }, 'error', 10000);
      }
      else if (error.response.status === 422) {
        setErrors(error.response.data)
      }
    }
  }

  const submit = async (e) => {
    const validationResult = vgBasicAccountingSystemForm.current.instance.validate()
    if (!validationResult.isValid)
      return
    else
      saveAction(e)
  }

  const saveButtonOptions = {
    text: t("#_accountingsystems_11"),
    onClick: submit
  }

  const cancelButtonOptions = {
    text: t("#_accountingsystems_12"),
    onClick: hideInfo
  }

  const fetchOrganizations = async () => {
    try {
      const result = await XfXApi.Organization.apiTenantIdOrganizationOrganizationsGet(XfXApi.GetTenantId())
      setOrganizations(makeAsyncDataSource(result.data))
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    fetchOrganizations()
  }, [])

  useEffect(()=>{
    setData(columns({t}))
  }, [t, setData])

  function setAccountingSystemDefaults() {
    accountingSystemInit.Id = null
    accountingSystemInit.Name = ''
    accountingSystemInit.OrganizationIds = []
  }

  function editAccountingSystem() {
    const selected = dataGridRef.current.instance.getSelectedRowsData()

    if (selected.length === 0) 
      return

    const accountingSystem = {
      Id: selected[0].Id,
      Name: selected[0].Name,
      AccountingSystemOrganizations: [],
      OrganizationIds: selected[0].OrganizationIds
    }

    setAccountingSystem(accountingSystem)
    setVisible(true)

    setOrganizationsValue(selected[0].OrganizationIds)
    setPopupTitle(editPopupTitle)
    setAccountingSystemEdit(true)
  }

  function addAccountingSystem() {
    dataGridRef.current.instance.clearSelection()

    setAccountingSystemDefaults()
    setAccountingSystem(accountingSystemInit)
    setVisible(true)

    setOrganizationsValue([])
    setPopupTitle(addPopupTitle)
    setAccountingSystemEdit(false)
  }

  const formFieldDataChanged = (e) => {
    setAccountingSystem(e.component.option("formData"))
    if (e.dataField) {
      clearError(e.dataField);
    }
  }

  function makeAsyncDataSource(data) {
    return new CustomStore({
      loadMode: 'raw',
      key: 'id',
      load() {
        return data
      },
    });
  }

  function hideInfo() {
    popup.close()
    clearError('organizationIds');
    clearError('name');
  }

  const toolbarButtons = [
    { icon: 'plus', text: t("#_accountingsystems_190"), onClick: addAccountingSystem },
    { icon: 'edit', text: t("#_accountingsystems_210"), onClick: editAccountingSystem, disabled: (selectedAccountingSystem === null || dataGridRef.current.instance.getSelectedRowsData()[0].Name ==='KSEF Insight')},
    { icon: 'trash', text: t("#_accountingsystems_230"), onClick: deleteAccountingSystem, disabled: (selectedAccountingSystem === null || dataGridRef.current.instance.getSelectedRowsData()[0].Name ==='KSEF Insight' )}
  ]

  const popupToolbarItems = commonPopupToolbarItems({
    t: t,
    saveButtonOptions: saveButtonOptions,
    cancelButtonOptions: cancelButtonOptions
  })

  const popup = GenericPopup({
    onHiding: hideInfo,
    content:
      <div>
        <Form
          id="formBasicAccountingSystem"
          ref={vgBasicAccountingSystemForm}
          labelMode={labelMode}
          readOnly={readOnly}
          showColonAfterLabel={showColonAfterLabel}
          labelLocation={labelLocation}
          minColWidth={minColWidth}
          colCount={colCount}
          onFieldDataChanged={formFieldDataChanged}
          formData={accountingSystem}>
          <GroupItem>
            <SimpleItem dataField="Name" editorType="dxTextBox" value={accountingSystem.Name} cssClass="xfx-required"
              editorOptions={{
                validationErrors: getFirstError("name"),
                isValid: noErrors("name")
              }}>
              <Label text={t("#_accountingsystems_18")} />
            </SimpleItem>
            <SimpleItem cssClass="xfx-required">
                <Label text={t("#_accountingsystems_22")} />
                <OrganizationsDropDown
                    value={accountingSystem.OrganizationIds}
                    t={t}
                    setValue={v => { setAccountingSystem({ ...accountingSystem, OrganizationIds: v })}}
                    allOrganizations={organizations}
                    useFluentValidation={true}
                    validationErrors={getFirstError("organizationIds")}
                    isValid={noErrors("organizationIds")}
                    placeholder={t("#_accountingsystems_21")}
                    clearError={clearError}
                    clearErrorField={'organizationIds'}
                ></OrganizationsDropDown>
            </SimpleItem>
          </GroupItem>
        </Form>
      </div>,
    toolbarItems: popupToolbarItems,
    title: popupTitle,
    width: "1000px",
    height: "500px",
    isVisible: visible,
    setIsVisible: setVisible,
    setBlockAutoRefresh: setBlockAutoRefresh
  })

  return (
    <div id="dataGrid-accountingSystems">
      {popup.popup}
      {deletionPopup.popup}
      <BreadCrumbs node={getNode({ componentName: 'AccountingSystems', t })}></BreadCrumbs>
        <GenericToolbar className="xfx-toolbar-bigbuttons" header={t("#_accountingsystems_26")} buttons={toolbarButtons}></GenericToolbar>
        <div id="dataGrid-accountingSystems" className="dataGrid">
          {GenericOdataGrid({ 
            ref: dataGridRef,
            data: data, 
            endpoint: OdataEndpoints.AccountingSystems(), 
            setSelectedItem: setSelectedAccountingSystem, 
            defaultFilter: dataGridFilter,
            defaultSort: defaultSort, 
            updateDataGridFilter: updateDataGridFilter,
            autoReload: true,
            gridKey: reloadKey,
            dataSourceOptions: dataSourceOptions,
            odataCustomFilterFieldName: "Organizations",
            setDataSourceOptions,
            blockAutoRefresh: blockAutoRefresh,
            setBlockAutoRefresh: setBlockAutoRefresh
          })}
        </div>
      </div>
  );
}

export default AccountingSystems
