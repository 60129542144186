import Drawer from 'devextreme-react/drawer';
import ScrollView from 'devextreme-react/scroll-view';
import React, { useState, useCallback, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router';
import { Header, SideNavigationMenu, Footer } from '../../components';
import './side-nav-outer-toolbar.scss';
import { useScreenSize } from '../../utils/media-query';
import { Template } from 'devextreme-react/core/template';
import { useMenuPatch } from '../../utils/patches';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const SideNavBarLayout = ({ title, children }) => {
  const { t } = useTranslation()
  const scrollViewRef = useRef();
  const navigate = useNavigate();
  const { isXSmall, isLarge } = useScreenSize();
  const [patchCssClass, onMenuReady] = useMenuPatch();
  const [paddingVisible, setPaddingVisible] = useState(true);
  const [contentPaddingStyle, setContentPaddingStyle] = useState({});
  const [menuStatus, setMenuStatus] = useState(
    isLarge ? MenuStatus.TemporaryOpened : MenuStatus.Closed
  );
  const [menuIcon, setMenuIcon] = useState(
    isLarge ? 'ksefixInsight' : 'insightBlackSignet');

  const location = useLocation();

  function checkIfHomePage() {
    return window.location.href.includes('home')
  }

  // const toggleMenu = useCallback(({ event }) => {
  //   setMenuIcon(menuStatus === MenuStatus.Closed
  //     ? 'bell'
  //     : 'insightBlackSignet')
  //   setMenuStatus(
  //     prevMenuStatus => prevMenuStatus === MenuStatus.Closed
  //     ? MenuStatus.TemporaryOpened
  //     : MenuStatus.Closed
  //   );
  //   event.stopPropagation();
  //   return;
  // }, []);

  const temporaryOpenMenu = useCallback(() => {
    setMenuStatus(
      prevMenuStatus => prevMenuStatus === MenuStatus.Closed
        ? MenuStatus.TemporaryOpened
        : prevMenuStatus
    );
    setMenuIcon(!isLarge || menuStatus === MenuStatus.TemporaryOpened
      ? 'ksefixInsight'
      : 'insightBlackSignet')
  }, []);

  const onOutsideClick = useCallback(() => {
    setMenuStatus(
      prevMenuStatus => prevMenuStatus !== MenuStatus.Closed && !isLarge
        ? MenuStatus.Closed
        : prevMenuStatus
    );
    if (!isLarge) {
      setMenuIcon(menuStatus !== MenuStatus.Closed
        ? 'ksefixInsight'
        : 'insightBlackSignet')
    }
  }, [isLarge]);

  const onExpanderClick = () => {
    if (menuStatus === MenuStatus.Closed)
    {
      temporaryOpenMenu();
    }
    
    if (menuStatus === MenuStatus.TemporaryOpened) {
        scrollViewRef.current.instance.scrollTo(0);
        setMenuStatus(MenuStatus.Closed);
        setMenuIcon('insightBlackSignet')
        return;
      }
  }

  const onNavigationChanged = useCallback(({ itemData: { path }, event, node }) => {
    if (menuStatus === MenuStatus.Closed || !path || node.selected) {
      event.preventDefault();
      return;
    }

    navigate(path);
    scrollViewRef.current.instance.scrollTo(0);

    if (!isLarge) { //|| menuStatus === MenuStatus.TemporaryOpened) {
      setMenuStatus(MenuStatus.Closed);
      setMenuIcon('insightBlackSignet')
      event.stopPropagation();
    }
  }, [navigate, menuStatus, isLarge]);

  useEffect(() => {
    if (paddingVisible === checkIfHomePage())
    {
      if (paddingVisible)
      {
        setContentPaddingStyle({ padding: '0px' })
        setPaddingVisible(false)
      }
      else
      {
        setContentPaddingStyle({})
        setPaddingVisible(true)
      }
    }
  }, [location])

  return (
    <div className={'side-nav-outer-toolbar'}>
      <Header
        className={'layout-header'}
        menuToggleEnabled
        //toggleMenu={toggleMenu}
        title={title}
        menuIcon={menuIcon}
      />
      <Drawer
        className={['drawer', patchCssClass].join(' ')}
        position={'before'}
        hideOnOutsideClick={onOutsideClick}
        openedStateMode={isLarge ? 'shrink' : 'overlap'}
        revealMode={isXSmall ? 'slide' : 'expand'}
        minSize={isXSmall ? 0 : 60}
        maxSize={383}
        shading={isLarge ? false : true}
        opened={menuStatus === MenuStatus.Closed ? false : true}
        template={'menu'}
      >
        <div className={'container'} style={contentPaddingStyle}>
          <ScrollView ref={scrollViewRef} className={'layout-body with-footer'}>
            <div className={'content'}>
              {React.Children.map(children, item => {
                return item.type !== Footer && item;
              })}
            </div>
            <div className={'content-block'}>
              {React.Children.map(children, item => {
                return item.type === Footer && item;
              })}
            </div>
          </ScrollView>
        </div>
        <Template name={'menu'}>
            <div className="menu-outer-container">
              <SideNavigationMenu
                compactMode={menuStatus === MenuStatus.Closed}
                selectedItemChanged={onNavigationChanged}
                openMenu={temporaryOpenMenu}
                onMenuReady={onMenuReady}
                >
              </SideNavigationMenu>
            </div>
          <div className="bottom-expander" onClick={onExpanderClick}>
            <div className="expander-content">
              <i className={"menu-arrow dx-icon" + (menuStatus === MenuStatus.Closed ? " dx-icon-arrowRightCircle" : " dx-icon-arrowLeftCircle")}></i>
              {t("menu-hide")}
            </div>
          </div>
        </Template>
      </Drawer>
    </div>
  );
}

const MenuStatus = {
  Closed: 1,
  Opened: 2,
  TemporaryOpened: 3
};


export default SideNavBarLayout