import 'devextreme-react/autocomplete';
import DataGrid, {
    Paging,
    FilterRow,
    Scrolling,
    Selection,
} from 'devextreme-react/data-grid';
import DropDownBox from 'devextreme-react/drop-down-box';

const eventsColumns = ({ t }) => [{ dataField: 'Event', caption: t("#_exitpatheditor_41") }, { dataField: 'Description', caption: t("#_exitpatheditor_42") }]

const eventsDropDownOptions = {
    minWidth: '900px'
}

const EventsDropDown = ({ value, setValue, eventsDataSource, t, useFluentValidation, 
    validationErrors, isValid, clearError, clearErrorField }) => {

    const eventsGridRender = (value, setValue) => {
        return (
                <DataGrid
                    className='dataGrid'
                    dataSource={eventsDataSource}
                    columns={eventsColumns({ t })}
                    hoverStateEnabled={true}
                    selectedRowKeys={value}
                    onSelectionChanged={e => {
                        setValue((e.selectedRowKeys.length && e.selectedRowKeys) || [])

                        if (clearError && clearErrorField) 
                            clearError(clearErrorField)
                    }}
                    height='100%'>
                    <Selection mode="multiple" />
                    <Scrolling mode='virtual' preloadEnabled={true}/>
                    <Paging enabled={true} pageSize={100} />
                    <FilterRow visible={true} />
                </DataGrid>
        )
    }

    return <DropDownBox
        label={t("exitpatheditor_event_kind")}
        value={value}
        valueExpr='Id'
        deferRendering={false}
        displayExpr='Description'
        placeholder={t('#_organizationsdropdown_5')}
        showClearButton={true}
        dataSource={eventsDataSource}
        onSelectionChanged={e => {
            setValue(e.value || [])
        }}
        onValueChanged={e => {
            setValue(e.value || [])
        }}
        contentRender={() => eventsGridRender(value, setValue)}
        dropDownOptions={eventsDropDownOptions}
        {...(useFluentValidation && { validationErrors: validationErrors })}
        {...(useFluentValidation && { isValid: isValid })}
    />
}

export default EventsDropDown