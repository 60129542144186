import notify from 'devextreme/ui/notify';

const copyToClipboard = (text, message, timeout) => {
    navigator.clipboard
        .writeText(text)
        .then(() => {
            if (message)
                notify({
                    message: message,
                    position: {
                        my: 'center bottom',
                        at: 'center bottom',
                    },
                }, 'success', timeout ?? 2000);
        })
        .catch((e) => {
            console.log(e)
        });
}

export { copyToClipboard }