import React, { useState } from 'react';
import DropDownBox from 'devextreme-react/drop-down-box';
import DataGrid, { Column, Selection, FilterRow } from 'devextreme-react/data-grid';
import { FormField, IOrganization } from '../model';
import OrganizationsDropDown from 'components/dropdown/OrganizationsDropDown';
import { OrganizationDto } from 'api/openapi';

const OrganizationNip = (address: string, availableOrganizations: OrganizationDto[], value: any,
    handleValueChanged2: (values: Record<string, any>) => void, field: FormField, fieldCorrected: any, t: any) => {

    return (
        <>
            <OrganizationsDropDown
                value={value}
                setValue={x => {
                    if (x.length === 0) { return }

                    handleValueChanged2({ value: x[0].nip })
                }}
                allOrganizations={availableOrganizations}
                disabled={field.IsReadOnly}
                t={t}
                useFluentValidation={false}
                validationErrors={[]}
                isValid={true}
                mode="single"
                dropDownValueExpr="nip"
                placeholder="NIP"
                label="NIP"
                clearError={() => { }}
                clearErrorField=""
                displayExpr="nip"
            />
            {fieldCorrected && <div className='invoice-editor-description'>Po zmianie NIP w danych wystawiającego dokument faktura nie będzie widoczna w kontekście wybranej organizacji. W celu potwierdzenia wysyłki faktury należy przełączyć się na kontekst nowej organizacji.</div>}
        </>
    );

}


export default OrganizationNip;