import GenericPopup from "components/popup/GenericPopup";
import { useMemo, useState } from 'react';
import 'devextreme-react/autocomplete';
import DataGrid, {
    Paging,
    FilterRow,
    Scrolling,
    Selection,
} from 'devextreme-react/data-grid';
import { useOrganizations } from "contexts/OrganizationContext";
import { useTranslation } from "react-i18next";
import { XfXApi } from 'api/XfXApi';

interface IMigrationPopupProps {
    setBlockAutoRefresh: (blockAutoRefresh: boolean) => void;
    organizationId: string;
    onSaved?: () => void;
    gridId: string;
    // selectedItems: any[]
}

const MigrationPopup = ({
    setBlockAutoRefresh, organizationId, onSaved, gridId
}: IMigrationPopupProps) => {
    const [isVisible, setIsVisible] = useState(false)
    const [selectedOrganization, setSelectedOrganization] = useState<any>(null)
    const { organizations } = useOrganizations();
    const [invoicesIds, setInvoicesIds] = useState<string[]>([])
    const { t } = useTranslation()
    const organizationsColumns = useMemo(() => [{ dataField: 'fullName', caption: t("#_organizationsdropdown_1") }, { dataField: 'nip', caption: t("#_organizationsdropdown_2") }], [t])

    const content = useMemo(() => {
        if (!organizations) {
            return <></>
        }
        return <div>
            <p>{t("migration-dialog-description")}</p>
            
            <DataGrid
                className='dataGrid'
                dataSource={organizations
                    .filter(x => x.isActive)
                    .filter(x => x.nip !== organizationId)}
                columns={organizationsColumns}
                hoverStateEnabled={true}
                // selectedRowKeys={value}
                onSelectionChanged={e => {
                    const selectedOrganization = e.selectedRowsData[0]
                    setSelectedOrganization(selectedOrganization ?? null)
                }}
                height='auto'
            >
                <Selection
                    allowSelectAll={false}
                    deferred={false}
                    mode={'single'}
                    selectAllMode='allPages'
                    showCheckBoxesMode='always'
                />
                <Scrolling mode='virtual' preloadEnabled={true} />
                <Paging enabled={true} pageSize={100} />
                <FilterRow visible={true} />

            </DataGrid>

        </div>
    }, [organizationId, organizations, organizationsColumns])

    const { popup, close, show } = GenericPopup({
        onHiding: () => close(),
        buttons: [
            {
                text: t("migration-dialog-save"),
                location: "after",
                action: () => {
                    if (selectedOrganization === null) return;

                    if (gridId === "RepositorySales") {
                        XfXApi.MigrationApi.apiTenantIdOrganizationIdMigrationSalesMigrateToPost(organizationId, selectedOrganization.nip, XfXApi.GetTenantId(), invoicesIds,)
                    }
                    else if (gridId === "RepositoryPurchase") {
                        XfXApi.MigrationApi.apiTenantIdOrganizationIdMigrationPurchaseMigrateToPost(organizationId, selectedOrganization.nip, XfXApi.GetTenantId(), invoicesIds)
                    }
                    else if (gridId === "RepositoryOther") {
                        // XfXApi.MigrationApi.apiTenantIdOrganizationIdMigrationOtherMigrateToPost(value.nip, XfXApi.GetTenantId(), invoicesIds)
                    } else {
                        console.error("Unknown gridId")
                    }

                    onSaved && onSaved();
                    close();
                },
                disabled: selectedOrganization === null
            },
            {
                text: t("migration-dialog-cancel"),
                location: "after",
                action: () => close()
            }],
        title: t("migration-dialog-title"),
        width: "650px",
        height: "600px",
        content: content,
        isVisible: isVisible,
        setIsVisible: setIsVisible,
        showTitle: true,
        toolbarItems: undefined,
        setBlockAutoRefresh: setBlockAutoRefresh
    });

    return {
        popup,
        show: (invoicesIds: string[]) => {
            setInvoicesIds(invoicesIds)
            setSelectedOrganization(null)
            show()
        },
        close
    };
}

export default MigrationPopup;