import { useState, useEffect, useRef } from 'react';
import GenericToolbar from '../Toolbars/GenericToolbar';
import GenericOdataGrid from 'pages/Invoices/GenericOdataGrid';
import { InvoicesEndpoints, OdataEndpoints } from 'api/XfXApi';
import { EventLogsPopup, EventLogsTypes } from '../../Invoices/EventLogsPopup';
import { useNavigate } from 'react-router';
import BreadCrumbs from 'components/breadCrumbs/BreadCrumbs';
import { getNode } from 'components/breadCrumbs/getNode';
import useDataGridFilter from 'utils/useDataGridFilter'
import { useAuth } from 'contexts/auth'
import { getDisabled } from '../Tools/Tools';
import { toolbarButtonsNavigateRepositories, source } from '../Tools/toolbarButtonsNavigateRepositiories';
import { filterParameter, columns, queryParameter } from './const';
import { useTranslation } from 'react-i18next';
import { useUserSettingsContext } from '../../../contexts/UserSettingsProvider';
import { XfXApi } from '../../../api/XfXApi';

const KsefSessions = () => {
    const { t } = useTranslation()
    const { hasAnyPrivilege } = useAuth()
    const [selectedItem, setSelectedItem] = useState()
    const initialFilter = []
    const { dataGridFilter, updateDataGridFilter } = useDataGridFilter({ initialFilter })
    const [eventLogsVisible, setEventLogsVisible] = useState(false)
    const navigate = useNavigate();
    const [data, setData] = useState([])
    const dataGridRef = useRef(null)
    const [blockAutoRefresh, setBlockAutoRefresh] = useState(false)

    useEffect(() => {
        setData(columns({ t }))
    }, [t, setData])

    const { getOrganizationContextSettings } = useUserSettingsContext()
    const organizationId = getOrganizationContextSettings()?.NIP

    const [salesArchiveDisabled, setSalesArchiveDisabled] = useState(true)
    const [purchaseArchiveDisabled, setPurchaseArchiveDisabled] = useState(true)
    const [otherArchiveDisabled, setOtherArchiveDisabled] = useState(true)
    const [unableToTerminate, setUnableToTerminate] = useState(true)
    const [reloadKey, setReloadKey] = useState(0)
    const defaultSort = { selector: "DateCreatedUtc", desc: true };
    const [dataSourceOptions, setDataSourceOptions] = useState()


    useEffect(() => {
        const rs = async () => { return await getDisabled(selectedItem?.Id, InvoicesEndpoints.RepositorySales(organizationId), hasAnyPrivilege, queryParameter) }
        const rp = async () => { return await getDisabled(selectedItem?.Id, InvoicesEndpoints.RepositoryPurchase(organizationId), hasAnyPrivilege, queryParameter) }
        const ro = async () => { return await getDisabled(selectedItem?.Id, InvoicesEndpoints.RepositoryOther(organizationId), hasAnyPrivilege, queryParameter) }

        rs().then((x) => { setSalesArchiveDisabled(x) })
        rp().then((x) => { setPurchaseArchiveDisabled(x) })
        ro().then((x) => { setOtherArchiveDisabled(x) })

        setUnableToTerminate(selectedItem === null || selectedItem?.ScheduledForSessionTermination)

    }, [hasAnyPrivilege, selectedItem, organizationId])

    const closeSessionButton ={
        icon: 'xCircle', 
        text: t("#_toolbarbuttonsnavigaterepositiories_10"),
        disabled: unableToTerminate,
        onClick: () => {
            XfXApi.InvoiceApi.apiTenantIdOrganizationIdInvoicesSessionSessionIdMarkToClosePost(selectedItem.Id,XfXApi.GetTenantId(), organizationId)
            .then(x => setReloadKey(reloadKey + 1)) 
        }

    }

    return (<>
        {eventLogsVisible && 
            <EventLogsPopup 
                type={EventLogsTypes.Sessions} 
                guid={selectedItem?.Id} 
                setEventLogsVisible={setEventLogsVisible} 
                eventLogsVisible={eventLogsVisible}
                setBlockAutoRefresh={setBlockAutoRefresh}>
            </EventLogsPopup>
        }
        <BreadCrumbs node={getNode({ componentName: 'KsefSessions', t })}></BreadCrumbs>
        <GenericToolbar className="xfx-toolbar-bigbuttons"
            buttons={[ ...toolbarButtonsNavigateRepositories({
                selectedItem: selectedItem, salesArchiveDisabled: salesArchiveDisabled, purchaseArchiveDisabled: purchaseArchiveDisabled,
                otherArchiveDisabled: otherArchiveDisabled, setEventLogsVisible, navigate, parameterName: filterParameter, gridSource: source.Sessions, t
            }), closeSessionButton]}></GenericToolbar>
        <div id="dataGrid-ksefSessions" className="dataGrid">
            {GenericOdataGrid({
                data, endpoint: OdataEndpoints.KsefSessions(organizationId), setSelectedItem: setSelectedItem,
                defaultFilter: dataGridFilter, defaultSort: defaultSort, updateDataGridFilter: updateDataGridFilter,
                ref: dataGridRef, id: "KsefSessions", gridKey: reloadKey, dataSourceOptions: dataSourceOptions, 
                setDataSourceOptions, blockAutoRefresh: blockAutoRefresh, setBlockAutoRefresh: setBlockAutoRefresh
            })}
        </div>
    </>)
}

export default KsefSessions