import {InvoiceDraftGrid,DispatchSaleProcessingGrid,DispatchSaleProcessingOfflineGrid,DispatchSaleAcceptanceGrid,DispatchSaleBatchToSendGrid,DispatchSelfInvoicingProcessingGrid,DispatchSelfInvoicingProcessingOfflineGrid,DispatchSelfInvoicingAcceptanceGrid,DispatchSelfInvoicingBatchToSendGrid,ReceptionPurchaseProcessingGrid,ReceptionPurchaseAlertGrid,ReceptionPurchaseP3ProcessingGrid,ReceptionPurchaseP3AlertGrid,ReceptionSelfInvoicingSaleGrid,ReceptionSelfInvoicingAlertGrid,ReceptionRepresentationProcessingGrid,ReceptionSaleProcessingGrid,ReceptionOtherProcessingGrid,RepositorySalesGrid,RepositoryPurchaseGrid,RepositoryOtherGrid,DispatchSaleFailedGrid,DispatchSelfInvoicingFailedGrid,ReceptionPurchaseRevokedGrid,ReceptionPurchaseP3RevokedGrid,ReceptionSelfInvoicingRevokedGrid} from './invoices-grids'
import { StaticViews } from 'staticViews'
export const invoicesRoutes = [
{
"requiredPrivilage": ['dispatch_invoice_draft'],
"path": "drafts",
"component": InvoiceDraftGrid
 },
{
"requiredPrivilage": ['dispatch_sales_processing'],
"path": "dispatch-sale-processing",
"component": DispatchSaleProcessingGrid
 },
{
"requiredPrivilage": ['dispatch_sales_processing'],
"path": "dispatch-sale-processing-offline",
"component": DispatchSaleProcessingOfflineGrid
 },
{
"requiredPrivilage": ['dispatch_sales_acceptance'],
"path": "dispatch-sale-acceptance",
"component": DispatchSaleAcceptanceGrid
 },
{
"requiredPrivilage": ['dispatch_sales_batchsend'],
"path": "dispatch-sale-create-batch-package",
"component": DispatchSaleBatchToSendGrid
 },
{
"requiredPrivilage": ['dispatch_sales_batchsend'],
"path": "dispatch-sale-batch-packages",
"component": () => StaticViews.BatchPackagesSales({breadCrumbs: ['menu/dispatch', 'menu/dispatch/sale', 'menu/dispatch/sale/batch-packages']})
 },
{
"requiredPrivilage": ['dispatch_selfinvoicing_processing'],
"path": "dispatch-selfinvoicing-processing",
"component": DispatchSelfInvoicingProcessingGrid
 },
{
"requiredPrivilage": ['dispatch_selfinvoicing_processing'],
"path": "dispatch-selfinvoicing-processing-offline",
"component": DispatchSelfInvoicingProcessingOfflineGrid
 },
{
"requiredPrivilage": ['dispatch_selfinvoicing_acceptance'],
"path": "dispatch-selfinvoicing-acceptance",
"component": DispatchSelfInvoicingAcceptanceGrid
 },
{
"requiredPrivilage": ['dispatch_selfinvoicing_batchsend'],
"path": "dispatch-selfinvoicing-create-batch-package2",
"component": DispatchSelfInvoicingBatchToSendGrid
 },
{
"requiredPrivilage": ['dispatch_selfinvoicing_batchsend'],
"path": "dispatch-selfinvoicing-batch-packages2",
"component": () => StaticViews.BatchPackagesSelfInvoicing({breadCrumbs: ['menu/dispatch', 'menu/dispatch/selfinvoicing', 'menu/dispatch/selfinvoicing/batch-packages2']})
 },
{
"requiredPrivilage": ['reception_purchaser_processing'],
"path": "reception-purchase-processing",
"component": ReceptionPurchaseProcessingGrid
 },
{
"requiredPrivilage": ['reception_purchaser_acceptance'],
"path": "reception-purchase-alert",
"component": ReceptionPurchaseAlertGrid
 },
{
"requiredPrivilage": ['reception_additional_buyer_processing'],
"path": "reception-purchasep3-processing",
"component": ReceptionPurchaseP3ProcessingGrid
 },
{
"requiredPrivilage": ['reception_additional_buyer_acceptance'],
"path": "reception-purchasep3-alert",
"component": ReceptionPurchaseP3AlertGrid
 },
{
"requiredPrivilage": ['reception_selfinvoicing_processing'],
"path": "reception-selfinvoicing-sale",
"component": ReceptionSelfInvoicingSaleGrid
 },
{
"requiredPrivilage": ['reception_selfinvoicing_acceptance'],
"path": "reception-selfinvoicing-alert",
"component": ReceptionSelfInvoicingAlertGrid
 },
{
"requiredPrivilage": ['reception_ext_issuer_processing'],
"path": "reception-representation-processing",
"component": ReceptionRepresentationProcessingGrid
 },
{
"requiredPrivilage": ['reception_ext_sales_processing'],
"path": "reception-sale-processing",
"component": ReceptionSaleProcessingGrid
 },
{
"requiredPrivilage": ['reception_other_processing'],
"path": "reception-other-processing",
"component": ReceptionOtherProcessingGrid
 },
{
"requiredPrivilage": ['sales'],
"path": "repository-sales",
"component": RepositorySalesGrid
 },
{
"requiredPrivilage": ['purchaser'],
"path": "repository-purchase",
"component": RepositoryPurchaseGrid
 },
{
"requiredPrivilage": ['other'],
"path": "repository-other",
"component": RepositoryOtherGrid
 },
{
"requiredPrivilage": ['dispatch_sales_failed'],
"path": "repository-dispatcherrors-failed",
"component": DispatchSaleFailedGrid
 },
{
"requiredPrivilage": ['dispatch_selfinvoicing_failed'],
"path": "repository-dispatcherrors-selfinvoicing",
"component": DispatchSelfInvoicingFailedGrid
 },
{
"requiredPrivilage": ['reception_purchaser_failed'],
"path": "repository-receptionerrors-revoked",
"component": ReceptionPurchaseRevokedGrid
 },
{
"requiredPrivilage": ['reception_additional_buyer_failed'],
"path": "repository-receptionerrors-failed",
"component": ReceptionPurchaseP3RevokedGrid
 },
{
"requiredPrivilage": ['reception_selfinvoicing_failed'],
"path": "repository-receptionerrors-selfinvoicing",
"component": ReceptionSelfInvoicingRevokedGrid
 },
]
