import { useState } from 'react';
import { Toast } from 'devextreme-react/toast';


const useToast = () => {

    const [toastConfig, setToastConfig] = useState({
        isVisible: false,
        type: 'info',
        message: '',
    });

    function onToastHiding() {
        setToastConfig({
            ...toastConfig,
            isVisible: false,
        });
    }

    const showToastError = (message) => {
        setToastConfig({
            ...toastConfig,
            isVisible: true,
            type: "error",
            message: message,
        });
    }

    const showToastInfo = (message) => {
        setToastConfig({
            ...toastConfig,
            isVisible: true,
            type: "info",
            message: message,
        });
    }

    const toast = <Toast
        visible={toastConfig.isVisible}
        message={toastConfig.message}
        type={toastConfig.type}
        onHiding={onToastHiding}
        displayTime={2000}
    />

    return { toast, showToastError, showToastInfo }
}

export default useToast