import { useState, useEffect, useRef, useMemo } from 'react';
import GenericToolbar from 'pages/settings/Toolbars/GenericToolbar';
import { InvoicesEndpoints, XfXApi } from 'api/XfXApi';
import { getDisabled, columns } from './BatchTools.js'
import SignBatchPopup from '../BatchAuthentication/SignBatchPopup'
import { EventLogsPopup, EventLogsTypes } from '../../Invoices/EventLogsPopup'
import BreadCrumbs from 'components/breadCrumbs/BreadCrumbs';
import useDataGridFilter from 'utils/useDataGridFilter'
import OrganizationContextOdataGrid from '../../Invoices/OrganizationContextOdataGrid';
import { useNavigate } from 'react-router';
import { batchPackageGridType, buttonTypes } from './const.jsx';
import { useTranslation } from 'react-i18next';
import { useUserSettingsContext } from '../../../contexts/UserSettingsProvider.jsx';
import { useAuth } from 'contexts/auth';
import DeletionPopup from 'components/popup/DeletionPopup';
import { toolbarButtonsBatchPackages } from './toolbarButtonsBatchPackages.jsx';
import RadioGroup from 'devextreme-react/radio-group';
import { useOrganizations } from 'contexts/OrganizationContext';
import notify from 'devextreme/ui/notify';

const BatchPackagesGrid = ({ breadCrumbs, endpoint, id }) => {

  const { t } = useTranslation()

  const [gridType, setGridType] = useState(endpoint === InvoicesEndpoints.DispatchSaleBatchSending ? batchPackageGridType.DispatchType :
    endpoint === InvoicesEndpoints.DispatchSelfInvoicingBatchSending ? batchPackageGridType.SelfInvoicingType : batchPackageGridType.OtherType)

  const defaultRemoveProcessingInvoices = false
  
  const navigate = useNavigate();
  const [selectedItem, setSelectedItem] = useState(null)
  const [signPopupVisible, setSignPopupVisible] = useState(false)
  const [eventLogsVisible, setEventLogsVisible] = useState(false)
  const { hasAnyPrivilege } = useAuth()
  const initialFilter = []
  const { dataGridFilter, updateDataGridFilter, activeQueryArguments, deleteActiveQueryArgument } = useDataGridFilter({ initialFilter, queryArguments: ["Id"] })
  const filterButtons = activeQueryArguments.map(x => { return { text: x, onClick: () => deleteActiveQueryArgument(x) } })

  const [processingDisabled, setProcessingDisabled] = useState(true)
  const [failedDisabled, setFailedDisabled] = useState(true)
  const [repositoryDisabled, setRepositoryDisabled] = useState(true)
  const [offlineDisabled, setOfflineDisabled] = useState(true)

  const [autosignAuthChallenge, setAutosignAuthChallenge] = useState(false)

  const [dataSourceOptions, setDataSourceOptions] = useState()

  const dataGridRef = useRef(null)

  const [data, setData] = useState([])
  const [batchProcessingStatusDataSource, setBatchProcessingStatusDataSource] = useState([])

  const defaultSort = { selector: "CreationDateUtc", desc: true };

  const [deletionPopupVisible, setDeletionPopupVisible] = useState(false)
  const [batchSettings, setBatchSettings] = useState(null)

  const [blockAutoRefresh, setBlockAutoRefresh] = useState(false)

  const [removeProcessingInvoices, setRemoveProcessingInvoices] = useState(defaultRemoveProcessingInvoices)

  const deleteAction = async (remProcessingInvoices) => {
    await XfXApi.BatchApi.apiTenantIdOrganizationIdBatchDelete(selectedItem?.Id?._value ?? selectedItem?.Id, remProcessingInvoices ? remProcessingInvoices : removeProcessingInvoices, XfXApi.GetTenantId(), organizationId)
      .then(x => {
        notify({
          message: `${t("#_deleteBatchSuccess")}`,
          position: {
            my: 'center bottom',
            at: 'center bottom',
          },
        }, 'success', 5000);
      })
      .catch(x => {
        notify({
          message: `${t("#_deleteBatchFailure")}`,
          position: {
            my: 'center bottom',
            at: 'center bottom',
          },
        }, 'error', 5000);
      })
    dataGridRef?.current?.instance?.refresh()
  }

  const deletionPopupContentExtended = (remProcessingInvoices) => {
    const radioGroupItems = [
      { value: false, text: t('#_cellrenderers_5') },
      { value: true, text: t('#_cellrenderers_6') },
    ]
    
    return (
      <div>
        <div>{t('#_batchDeleteRemoveProcessingInvoices')}</div>
        <RadioGroup
          items={radioGroupItems}
          value={remProcessingInvoices}
          valueExpr={"value"}
          displayExpr={"text"}
          onValueChange={
            x => setRemoveProcessingInvoices(x)
          } 
        />
      </div>
    ) 
  }

  const deletionPopup = DeletionPopup({
    onConfirm: async () => {
      await deleteAction(removeProcessingInvoices)
    },
    content: deletionPopupContentExtended(removeProcessingInvoices),
    title: t("#_DeletionPopup_delete"),
    isVisible: deletionPopupVisible,
    setIsVisible: setDeletionPopupVisible,
    t: t,
    setBlockAutoRefresh: setBlockAutoRefresh,
    confirmText: t("#_DeletionPopup_3")
  })

  const deleteBatch = async () => {
    const remProcessingInvoices = batchSettings?.hasOwnProperty('deleteBatchKeepProcessingInvoices') ? !batchSettings.deleteBatchKeepProcessingInvoices : defaultRemoveProcessingInvoices
    const deleteBatchShowConfirmationModal = batchSettings?.hasOwnProperty('deleteBatchShowConfirmationModal') ? batchSettings.deleteBatchShowConfirmationModal : true

    setRemoveProcessingInvoices(remProcessingInvoices)

    if (deleteBatchShowConfirmationModal)
      deletionPopup.show()
    else
      await deleteAction(remProcessingInvoices)
  }

  useEffect(() => {
    XfXApi.KsefAuthApi.apiTenantIdKsefAuthAutosignGet(XfXApi.GetTenantId()).then((x) => { setAutosignAuthChallenge(x.data) })
    XfXApi.SettingsApi.apiTenantIdSettingsBatchSettingsGet(XfXApi.GetTenantId())
      .then((x) => { 
        if (x.status === 200) //for 204 there is no point to set empty data i guess
          setBatchSettings(x.data)
      })
  }, [])


  useEffect(() => {
    async function getDictionariesAndSetData() {
      const batchProcessingStatusesPromise = XfXApi.DictionaryInvoice.apiTenantIdDictionaryBatchprocessingstatusesGet(XfXApi.GetTenantId())
      const batchProcessingStatusData = (await batchProcessingStatusesPromise).data

      setBatchProcessingStatusDataSource(batchProcessingStatusData)
      setData(columns({
        t: t,
        batchProcessingStatusDataSource: batchProcessingStatusData
      }))
    }

    getDictionariesAndSetData()
  }, [t, setData])

  const eventHandler = (e) => {
    if (e.detail.message.businessEvent?.startsWith("Batch")) {
      dataGridRef?.current?.instance?.clearSelection()
      dataSourceOptions.reload()
    }
  }

  useEffect(() => {
    XfXApi.KsefAuthApi.apiTenantIdKsefAuthAutosignGet(XfXApi.GetTenantId()).then((x) => { setAutosignAuthChallenge(x.data) })
  }, [])

  const { getOrganizationContextSettings } = useUserSettingsContext()
  const organizationId = getOrganizationContextSettings()?.NIP


  const { isOrganizationDisabled } = useOrganizations()
  const currentOrganizationDisabled = useMemo(() => isOrganizationDisabled(organizationId), [organizationId, isOrganizationDisabled])


  useEffect(() => {
    document.addEventListener("XfxNotification", eventHandler)
    return () => {
      document.removeEventListener("XfxNotification", eventHandler)
    }
  })

  useEffect(() => {
    const processing = async () => { return await getDisabled(selectedItem?.Id, hasAnyPrivilege, organizationId, gridType, buttonTypes[0]) }
    const failed = async () => { return await getDisabled(selectedItem?.Id, hasAnyPrivilege, organizationId, gridType, buttonTypes[1]) }
    const repo = async () => { return await getDisabled(selectedItem?.Id, hasAnyPrivilege, organizationId, gridType, buttonTypes[2]) }
    const offline = async () => { return await getDisabled(selectedItem?.Id, hasAnyPrivilege, organizationId, gridType, buttonTypes[3]) }

    processing().then((x) => { setProcessingDisabled(x) })
    failed().then((x) => { setFailedDisabled(x) })
    repo().then((x) => { setRepositoryDisabled(x) })
    offline().then((x) => { setOfflineDisabled(x) })

  }, [hasAnyPrivilege, selectedItem, organizationId, gridType])

  return (
    <>

      {eventLogsVisible &&
        <EventLogsPopup
          type={EventLogsTypes.Batch}
          guid={selectedItem?.Id}
          setEventLogsVisible={setEventLogsVisible}
          eventLogsVisible={eventLogsVisible}
          setBlockAutoRefresh={setBlockAutoRefresh}>
        </EventLogsPopup>}
      <BreadCrumbs breadCrumbs={breadCrumbs.map(x => t(x))}></BreadCrumbs>
      <div id="dataGrid-batchPackages" className="dataGrid">
        <GenericToolbar className="xfx-toolbar-bigbuttons" buttons={toolbarButtonsBatchPackages({
          deleteBatch: async () => await deleteBatch(), dataGridRef,
          organizationId,
          selectedItem, processingDisabled, failedDisabled,
          repositoryDisabled, offlineDisabled, setSignPopupVisible, 
          setEventLogsVisible, navigate, dataSourceOptions,
          setSelectedItem, reloadGrid: () => {
            dataGridRef?.current?.instance?.refresh()
          }, gridType: gridType,
          currentOrganizationDisabled,
          t
        })}
          icon='dx-icon-export'>
        </GenericToolbar>
        {deletionPopup.popup}
        {OrganizationContextOdataGrid({
          id: id,
          ref: dataGridRef,
          data: data,
          endpoint,
          setSelectedItem: setSelectedItem,
          defaultFilter: dataGridFilter,
          defaultSort: defaultSort,
          filterButtons: filterButtons,
          updateDataGridFilter: updateDataGridFilter,
          dataSourceOptions: dataSourceOptions,
          setDataSourceOptions,
          blockAutoRefresh: blockAutoRefresh,
          setBlockAutoRefresh: setBlockAutoRefresh
        })}
        {selectedItem !== null && <SignBatchPopup
          organizationId={organizationId}
          organizationNip={selectedItem.OrganizationNip}
          isVisible={signPopupVisible}
          setIsVisible={setSignPopupVisible}
          reloadGrid={() => {
            dataGridRef?.current?.instance?.clearSelection()
            dataGridRef?.current?.instance?.refresh()
          }}
          batchId={selectedItem?.Id?._value ?? selectedItem?.Id}
          autosignAuthChallenge={autosignAuthChallenge}
          setSelectedItem={setSelectedItem}
          setBlockAutoRefresh={setBlockAutoRefresh}
        ></SignBatchPopup>
        }
      </div>
    </>
  );
}

export default BatchPackagesGrid