import { BEFORE_CORRECTION_TOKEN_PREFIX as prefix, BEFORE_CORRECTION_TOKEN_PREFIX_LEN as len } from "./customTokens";

export function removePrefix(obj: Record<string, any>): Record<string, any> {
    const newObj: Record<string, any> = {};
    for (const key in obj) {
        if (obj.hasOwnProperty(key)) {
            const newKey = key.startsWith(prefix) ? key.slice(len) : key;
            newObj[newKey] = obj[key];
        }
    }
    return newObj;
}

export function addPrefix(obj: Record<string, any>): Record<string, any> {
    const newObj: Record<string, any> = {};
    for (const key in obj) {
        if (obj.hasOwnProperty(key)) {
            const newKey = key.startsWith(prefix) ? key : prefix + key;
            newObj[newKey] = obj[key];
        }
    }
    return newObj;
}

export function extractAndRemovePrefix(obj: Record<string, any>): Record<string, any> {
    const newObj: Record<string, any> = {};
    for (const key in obj) {
        if (obj.hasOwnProperty(key) && key.startsWith(prefix)) {
            const newKey = key.slice(len);
            newObj[newKey] = obj[key];
        }
    }
    return newObj;
}