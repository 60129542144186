import { useState, useEffect, useRef } from 'react';
import GenericToolbar from '../Toolbars/GenericToolbar';
import GenericOdataGrid from 'pages/Invoices/GenericOdataGrid';
import { OdataEndpoints } from 'api/XfXApi';
import BreadCrumbs  from 'components/breadCrumbs/BreadCrumbs';
import { getNode } from 'components/breadCrumbs/getNode';
import useDataGridFilter from 'utils/useDataGridFilter'
import notify from 'devextreme/ui/notify';
import { XfXApi, KsefixEndpoints } from 'api/XfXApi';
import { columns } from './const';
import CustomStore from 'devextreme/data/custom_store';
import GenericPopup from "components/popup/GenericPopup"
import DocumentTemplateEditor from './DocumentTemplateEditor'
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import useFormErrors from 'utils/useFormErrors';
import { commonPopupToolbarItems } from 'components/popup/PopupTools';
import DeletionPopup from 'components/popup/DeletionPopup';

const DocumentTemplates = () => {
  const { t } = useTranslation()
  const [data, setData] = useState([])
    const editPopupTitle = t("#_documenttemplates_1")
    const addPopupTitle = t("#_documenttemplates_2")
    const emptyState = {
        id: null,
        name: null,
        isDispatch: false,
        invoiceKind: null,
        organizationIds: [],
        templateFileType: null
      }
    
    const { setErrors, getFirstError, noErrors, clearError, clearAllErrors } = useFormErrors();
    const [formKey, setFormKey] = useState(0)
    const [selectedItem, setSelectedItem] = useState(null)
    const initialFilter = []
    const { dataGridFilter, updateDataGridFilter } = useDataGridFilter({ initialFilter })
    const [editMode, setEditMode] = useState(false)
    const [popupTitle, setPopupTitle] = useState(editPopupTitle)
    
    const [state, setState] = useState(emptyState)
    const vgForm = useRef(null);
    const [allOrganizationsCustomStore, setAllOrganizationsCustomStore] = useState()
    const [organizations, setOrganizations] = useState([])
    const [templateFile, setTemplateFile] = useState([])
    const [reloadKey, setReloadKey] = useState(0)
    const [dataSourceOptions, setDataSourceOptions] = useState()
    const [isVisible, setIsVisible] = useState(false)
    const dataGridRef = useRef(null);
    const [deletionPopupVisible, setDeletionPopupVisible] = useState(false)
    const [deletionPopupContent, setDeletionPopupContent] = useState("")
    const [blockAutoRefresh, setBlockAutoRefresh] = useState(false)
    const [invKindDataSource, setInvKindDataSource] = useState([])
    const [templateFileTypesDataSource, setTemplateFileTypesDataSource] = useState([])

    const defaultSort = { selector: "CreationDateUtc", desc: true };
    const header = t("#_documenttemplates_3")

    function makeAsyncDataSource(data) {
        return new CustomStore({
          loadMode: 'raw',
          key: 'id',
          load() {
            return data
          },
        });
      }

    const editor = DocumentTemplateEditor({
        state: state,
        setState: setState,
        vgForm: vgForm,
        allOrganizationsCustomStore: allOrganizationsCustomStore,
        setTemplateFile: setTemplateFile,
        templateFile: templateFile,
        invKindDataSource: invKindDataSource,
        t: t,
        formKey: formKey,
        getFirstError, 
        noErrors, 
        clearError,
        templateFileTypesDataSource: templateFileTypesDataSource
      })

    const fetchOrganizations = async () => {
        try {
            const result = await XfXApi.Organization.apiTenantIdOrganizationOrganizationsGet(XfXApi.GetTenantId())
            setOrganizations(result.data)
            setAllOrganizationsCustomStore(makeAsyncDataSource(result.data))
        } catch (error) {
            console.error(error)
        }
    }

    useEffect(() => {
      fetchOrganizations()
    }, [])

    useEffect(() => {
      async function fetchDictionaries() {
        const invoiceKindsPromise = XfXApi.DictionaryInvoice.apiTenantIdDictionaryInvoiceKindsGet(XfXApi.GetTenantId())
        const templateFileTypesPromise = XfXApi.Dictionary.apiTenantIdDictionaryTemplateFileTypesGet(XfXApi.GetTenantId())
        
        const invoiceKindsData = (await invoiceKindsPromise).data
        const templateFileTypesData = (await templateFileTypesPromise).data

        setInvKindDataSource(invoiceKindsData)
        setTemplateFileTypesDataSource(templateFileTypesData)

        setData(columns({t, invKindDataSource: invoiceKindsData, templateFileTypesDataSource: templateFileTypesData }))
      }

      fetchDictionaries()
    }, [t])

    const saveAction = async () => {
      try {
        if (!editMode) {
          state.id = -1
        }
  
        var formData = new FormData()
        if (state.isDispatch !== null && state.isDispatch !== undefined)
          formData.append("isDispatch", state.isDispatch)

        if (templateFile !== null && templateFile !== undefined && templateFile.length > 0)
          formData.append("file", templateFile[0], templateFile[0].name)

        if (state.name !== null && state.name !== undefined)
          formData.append("name", state.name)

        if (state.invoiceKind !== null && state.invoiceKind !== undefined)
          formData.append("invoiceKind", state.invoiceKind)

        if (state.templateFileType !== null && state.templateFileType !== undefined)
          formData.append("templateFileType", state.templateFileType)

        if (state.organizationIds !== null && state.organizationIds !== undefined)
        {
          state.organizationIds.forEach((value, index) => {
            formData.append(`organizationIds[${index}]`, value);
          });
        }

        var response = null;
        if (editMode)
        {
          response = await axios.put(KsefixEndpoints.DocumentTemplatesEdit(state.id), formData, {
            headers: {
                'Content-Type': `multipart/form-data`
            }
        });
        }
        else
        {
          response = await axios.post(KsefixEndpoints.DocumentTemplates(), formData, {
              headers: {
                  'Content-Type': `multipart/form-data`
              }
          });
        }

        if (response.status === 200) {
          dataGridRef?.current?.instance?.clearSelection()
          notify({
            message: `${editMode ? t("#_documenttemplates_4") : t("#_documenttemplates_5") }`,
            position: {
              my: 'center bottom',
              at: 'center bottom',
            },
          }, 'success', 5000);
          return true;
        }
        } catch (error) {
          if (error.response.status === 400) {
            notify({
              message: `${editMode ? t("#_documenttemplates_6") : t("#_documenttemplates_7") } : \r\n \r\n` + error.response.data.errors.join('\r\n'),
              position: {
                my: 'center bottom',
                at: 'center bottom',
              },
            }, 'error', 10000);
          }
          else if (error.response.status === 422) {
            setErrors(error.response.data);
          }
        }
      }

      const deletionPopup = DeletionPopup({
        onConfirm: () =>{
          XfXApi.DocumentTemplate.apiTenantIdDocumentTemplateDelete(selectedItem.Id.toString(), XfXApi.GetTenantId())
              .then(x => setReloadKey(reloadKey + 1)) 
              dataGridRef?.current?.instance?.clearSelection()
        },
        content: deletionPopupContent, 
        title: t("#_DeletionPopup_delete"),        
        isVisible: deletionPopupVisible, 
        setIsVisible: setDeletionPopupVisible, 
        t: t,
        setBlockAutoRefresh: setBlockAutoRefresh
      })

      const deleteTemplate = () => {
        if (selectedItem === null) return
        setDeletionPopupContent(t("#_DeletionPopup"));        
        deletionPopup.show();
      };

      const popupToolbarItems = commonPopupToolbarItems({ 
        onSave: async () => {
          const sent = await saveAction()
          if (sent) {
            popup.close()
            clearAllErrors()
            setState({ ...emptyState })
            setTemplateFile([])
            setReloadKey(reloadKey + 1);
          }
        }, 
        onCancel: () => {
          popup.close()
          clearAllErrors()
          setState({ ...emptyState })
          setTemplateFile([])
        }, t: t 
      })

      const popup = GenericPopup({ 
        onHiding: () => popup.close(),
        content:
        <div>
          {editor}
        </div>,
        toolbarItems: popupToolbarItems,
        title: popupTitle,
        width: "812",
        height: "80%",
        isVisible: isVisible,
        setIsVisible: setIsVisible,
        setBlockAutoRefresh: setBlockAutoRefresh
      })

      const addTemplate = () => {
        setState({ ...emptyState })
        setTemplateFile([])
    
        setPopupTitle(addPopupTitle)
        setEditMode(false)
        popup.show()
      }

      const editTemplate = async () => {
        if (selectedItem === null) return

        const invKind = invKindDataSource?.find(x => x.value === selectedItem.InvoiceKind)?.key;
        const tempFileType = templateFileTypesDataSource?.find(x => x.value === selectedItem.TemplateFileType)?.key;

        const newState = {
            id: selectedItem.Id,
            name: selectedItem.Name,
            isDispatch: selectedItem.IsDispatch,
            invoiceKind: invKind,
            organizationIds: selectedItem.OrganizationIds,
            templateFileType: tempFileType
        }
  
        const url =  KsefixEndpoints.DocumentTemplatesFile() + `?templateId=${selectedItem.Id}`
  
        const fileResp = await axios
          .request({
            url: url,
            method: "GET",
            responseType: 'blob',
          });

          setState(newState)
          setPopupTitle(editPopupTitle)
          if (fileResp)
          {
            const contentDisposition = fileResp.headers["content-disposition"];
            const fileNameMatch = contentDisposition.match(/filename\*=UTF-8''(.+)/);
            let fileName = null;
            if (fileNameMatch && fileNameMatch.length > 1) {
              fileName = decodeURIComponent(fileNameMatch[1]);
            }
            const myBlob = new Blob([fileResp.data]);
            const templateFileBlob = new File([myBlob], fileName, { type: fileResp.data.type });
            
            setTemplateFile([templateFileBlob])
          }
          setEditMode(true)
          popup.show()
          setFormKey(oldKey => oldKey + 1)
        }

      const toolbarButtons = [
        { icon: 'trash', text: t("#_documenttemplates_12"), onClick: () => deleteTemplate(), disabled: selectedItem === null },
        { icon: 'edit', text: t("#_documenttemplates_13"), onClick: async () => await editTemplate(), disabled: selectedItem === null },
        { icon: 'plus', text: t("#_documenttemplates_14"), onClick: () => addTemplate(), disabled: false }
    ]

    return (<>
        {popup?.popup}
        {deletionPopup.popup}
        <BreadCrumbs node={ getNode({ componentName: 'DocumentTemplates', t })} />
        <GenericToolbar className="xfx-toolbar-bigbuttons" header={header} buttons={toolbarButtons} />
        <div id="dataGrid-documentTemplates" className="dataGrid">
            {GenericOdataGrid({ 
                ref: dataGridRef,
                data: data, 
                endpoint: OdataEndpoints.DocumentTemplates(), 
                setSelectedItem: setSelectedItem, 
                defaultFilter: dataGridFilter,
                defaultSort: defaultSort, 
                updateDataGridFilter: updateDataGridFilter,
                autoReload: true,
                gridKey: reloadKey,
                odataCustomFilterFieldName: "Organizations",
                dataSourceOptions: dataSourceOptions,
                setDataSourceOptions,
                blockAutoRefresh: blockAutoRefresh,
                setBlockAutoRefresh: setBlockAutoRefresh
             })}
        </div>
    </>)
}

export default DocumentTemplates