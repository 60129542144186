import { useState, useEffect, useRef } from "react"
import GenericToolbar from '../Toolbars/GenericToolbar';
import DataGrid, {
    Scrolling, Paging, HeaderFilter, FilterRow, Selection, Search
} from 'devextreme-react/data-grid';
import useDataSourceAutoReload from '../../../utils/useDataSourceReload'
import useToast from '../../../utils/useToast'
import KsefLoginPopup from '../KsefLoginPopup'
import KsefTokenEditorPopup from './Tokens/KsefTokenEditorPopup'
import { useRefreshButton as reload } from "utils/refresh";
import BreadCrumbs from 'components/breadCrumbs/BreadCrumbs';
import { getNode } from 'components/breadCrumbs/getNode';
import { useOrganizationDropDownHandler } from 'utils/organizationDropDownHandler';
import { initSessionByTokenId, onSuccessfulLogin, syncWithKsef, isLoginByTokenDisabled, addNewToken } from "./Tokens/actions";
import { toolbarButtons } from "./Tokens/toolbarButtons";
import { columns } from "./Tokens/columns";
import { dataSource } from "./Tokens/dataSource";
import { XfXApi } from 'api/XfXApi';
import { useTranslation } from 'react-i18next';
import { useUserSettingsContext } from "../../../contexts/UserSettingsProvider";
import DeletionPopup from 'components/popup/DeletionPopup';

const KsefTokens = () => {
    const { t } = useTranslation()
    const { getOrganizationContextSettings } = useUserSettingsContext()
    const organizationId = getOrganizationContextSettings()?.NIP
    const [selectedItem, setSelectedItem] = useState(null)
    const [editVisible, setEditVisible] = useState(false)
    const [popupTitle, setPopupTitle] = useState(t("#_kseftokens_1"))
    const [selectedOrganizationContext, setSelectedOrganizationContext] = useState(null)
    const [ksefLoginVisible, setKsefLoginVisible] = useState(false)
    const dataGridRef = useRef(null)

    const [ds, setDs] = useState(null)

    const { toast, showToastError } = useToast()
    const [autosignAuthChallenge, setAutosignAuthChallenge] = useState(false)
    const [deletionPopupVisible, setDeletionPopupVisible] = useState(false)
    const [deletionPopupContent, setDeletionPopupContent] = useState("")

    const [refreshKey, setRefreshKey] = useState(0)
    const [blockAutoRefresh, setBlockAutoRefresh] = useState(false)

    const refresh = () => {
        ds.reload()
        setRefreshKey(refreshKey + 1)
    }

    reload(refresh)

    const deletionPopup = DeletionPopup({
        onConfirm: async () => {
            await XfXApi.KsefCredentialsApi.apiTenantIdOrganizationIdKsefCredentialsTokenMarkToRevokePost(
                XfXApi.GetTenantId(),
                selectedOrganizationContext?.NIP,
                selectedItem.Id.toString())

        },
        content: deletionPopupContent,
        title: t("#_DeletionPopup_delete"),
        isVisible: deletionPopupVisible,
        setIsVisible: setDeletionPopupVisible,
        t: t,
        setBlockAutoRefresh: setBlockAutoRefresh
    })

    const deleteToken = () => {
        setDeletionPopupContent(t("#_DeletionPopup"));
        deletionPopup.show();
    }

    useEffect(() => {
        setDs(dataSource(organizationId))
        setSelectedOrganizationContext(getOrganizationContextSettings())
        XfXApi.KsefAuthApi.apiTenantIdKsefAuthAutosignGet(XfXApi.GetTenantId()).then((x) => { setAutosignAuthChallenge(x.data) })
    }, [])

    useOrganizationDropDownHandler(async (e) => {
        dataGridRef?.current?.instance?.clearSelection()
        const organizationContextFromDetails = e?.detail?.OrganizationContext

        setDs(dataSource(organizationContextFromDetails?.NIP))
        setSelectedOrganizationContext(organizationContextFromDetails)

        ds.reload()
    })

    useDataSourceAutoReload({ dataSource: dataSource, onDataReload: refresh, blockAutoRefresh: blockAutoRefresh })

    return (<>
        {toast}
        {deletionPopup.popup}
        {selectedOrganizationContext !== null && selectedOrganizationContext !== undefined && 
            <KsefLoginPopup organizationNip={selectedOrganizationContext?.NIP}
                isVisible={ksefLoginVisible}
                setIsVisible={setKsefLoginVisible}
                showToastError={showToastError}
                onSuccessfulLogin={() => onSuccessfulLogin({
                    setSelectedItem, selectedOrganizationContext: selectedOrganizationContext,
                    dataGridRef: dataGridRef, t
                })}
                autosignAuthChallenge={autosignAuthChallenge}
                setBlockAutoRefresh={setBlockAutoRefresh}>
            </KsefLoginPopup>
        }
        <KsefTokenEditorPopup
            title={popupTitle}
            isVisible={editVisible}
            setIsVisible={setEditVisible}
            save={(e) => addNewToken({ e: e, selectedOrganizationContext: selectedOrganizationContext, dataSource: ds, t })}
            isTokenPopup={true}
            setBlockAutoRefresh={setBlockAutoRefresh}>
        </KsefTokenEditorPopup>
        <BreadCrumbs node={getNode({ componentName: 'KsefTokens', t })}></BreadCrumbs>
        <GenericToolbar
            className="xfx-toolbar-bigbuttons"
            buttons={toolbarButtons({
                selectedItem: selectedItem, setSelectedItem, selectedOrganizationContext: selectedOrganizationContext, setEditVisible,
                deleteToken: () => deleteToken(), isLoginByTokenDisabled, dataSource: ds, syncWithKsef, initSessionByTokenId, setKsefLoginVisible, t
            })}
            showIcon={true}>
        </GenericToolbar>
        {selectedOrganizationContext !== null && selectedOrganizationContext !== undefined &&
            <div className="dataGrid">
                <DataGrid
                    ref={dataGridRef}
                    dataSource={ds}
                    showBorders={true}
                    height={800}
                    rowAlternationEnabled={true}
                    showColumnLines={true}
                    onSelectionChanged={s => {
                        setSelectedItem(s.selectedRowsData.length > 0 ? s.selectedRowsData[0] : null)
                    }}
                    loadPanel={
                        { enabled: false }
                    }
                >
                    <Selection mode="single" />
                    <Scrolling mode="virtual" preloadEnabled={true} />
                    <Paging defaultPageSize={100} />
                    <HeaderFilter>
                        <Search
                            enabled={true}
                        >
                        </Search>
                    </HeaderFilter>
                    <FilterRow visible={true} applyFilter={true} />
                    {columns({ t })}
                </DataGrid>
            </div>
        }
    </>)
}

export default KsefTokens;
