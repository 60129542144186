import { useState, useRef, useEffect } from "react"
import { Popup, ToolbarItem } from 'devextreme-react/popup';
import ScrollView from 'devextreme-react/scroll-view';

const GenericPopup = ({ buttons, title, content, width, height, toolbarItems, onHiding, showTitle, isVisible, setIsVisible, setBlockAutoRefresh }) => {
    const scrollRef = useRef(null);
    const [windowHeight, setWindowHeight] = useState(window.innerHeight);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        if (setBlockAutoRefresh && isVisible)
            setBlockAutoRefresh(isVisible)
    }, [isVisible])

    useEffect(() => {
        const handleResize = () => {
            setWindowHeight(window.innerHeight);
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);
        
        return () => window.removeEventListener('resize', handleResize);
    }, []);


    let key = 0;
    const GetButton = b => {
        const onClickArgument = {
            close: () => {
                setIsVisible(false)
                if (setBlockAutoRefresh)
                    setBlockAutoRefresh(false)
            }
        }
        const options = {
            disabled: b.disabled !== undefined ? b.disabled : false,
            icon: b.icon,
            text: b.text,
            onClick: () => {
                if (b.action !== undefined) b.action(onClickArgument)
            },
            useSubmitBehavior: b.useSubmitBehavior,

        }
        return <ToolbarItem key={key++}
            widget="dxButton"
            toolbar="bottom"
            location={b.location}
            options={options}
            visible={b.action !== undefined}
            disabled={b.disabled ?? false}
        />
    }
    const buttonsOption = toolbarItems ? toolbarItems : (buttons !== undefined ? buttons.map(GetButton) : undefined)

    let heightProps = {};
    let widthProps = {};
    let divInsideScrollViewStyle = {};

    if (width)
    {
        widthProps.width = width;
    }
    else
    {
        const maxWidth = windowWidth - 100
        widthProps.width = 'auto';
        widthProps.maxWidth = maxWidth
        widthProps.minWidth = 700
    }

    if (height)
    {
        heightProps.height = height;
    }
    else
    {
        const maxHeight =  windowHeight - 100
        heightProps.height = 'auto';
        heightProps.maxHeight = maxHeight;
        divInsideScrollViewStyle.maxHeight = (maxHeight - 224) + "px"
    }
    let onHidingProps = {};
    if (onHiding) {
        onHidingProps.onHiding = onHiding;
    }

    const popup = <Popup
        visible={isVisible}
        dragEnabled={false}
        hideOnOutsideClick={false}
        showCloseButton={true}
        title={title}
        container=".dx-viewport"
        resizeEnabled={true}
        showTitle={showTitle ?? true}
        {...heightProps}
        {...widthProps}
        {...onHidingProps}
    >
        {buttonsOption}
        {isVisible && <ScrollView ref={scrollRef}>
        <div style={{...divInsideScrollViewStyle}}>
            {content}
        </div>
        </ScrollView>}
    </Popup>

    const show = () => {
        setIsVisible(true)
        if (setBlockAutoRefresh)
            setBlockAutoRefresh(true)
    }
    
    const close = () => {
        setIsVisible(false)
        if (setBlockAutoRefresh)
            setBlockAutoRefresh(false)
    }

    return { popup, show, close }
}

export default GenericPopup