import { FormField } from './model';
import { Fa1InvoiceItem } from './Fa1InvoiceItem';

export const groupByRow = (fields: FormField[]): { [key: number]: FormField[] } => {
    return fields.reduce((acc, field) => {
        if (!acc[field.Row]) {
            acc[field.Row] = [];
        }
        acc[field.Row].push(field);
        return acc;
    }, {} as { [key: number]: FormField[] });
}
