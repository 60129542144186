import GenericPopup from "./GenericPopup";


const DeletionPopup = ({ onConfirm, title, content, isVisible, setIsVisible, t, setBlockAutoRefresh, confirmText }) => {
    const buttons = [
        {
            text: confirmText ? confirmText : t("#_DeletionPopup_1"),
            action: onConfirm === undefined ? undefined : async (popupHandler) => {
                await onConfirm()
                popupHandler.close()
            },
            location: "center"
        },
        {
            text: t("#_DeletionPopup_2"),
            action: (popupHandler) => {
                popupHandler.close()
            },
            location: "center"
        }
    ]
    const width = 400;
    const height = 370;
    const onHiding = () => popup.close()
    const popup = GenericPopup({ buttons, title, content, width, height, onHiding: onHiding, isVisible: isVisible, setIsVisible: setIsVisible, setBlockAutoRefresh: setBlockAutoRefresh })
    return popup;
}

export default DeletionPopup