import { useState } from 'react';
import { DateBox, Template, TextBox } from 'devextreme-react';
import GenericPopup from 'components/popup/GenericPopup';
import { XfXApi } from "api/XfXApi"
import notify from "devextreme/ui/notify"
import { commonPopupToolbarItems } from 'components/popup/PopupTools';
import useFormErrors from 'utils/useFormErrors';

export const subjectTypes =
{
    Subject1: {
        Id: 0,
        Name: "#_createbatchpopup_1"
    },
    Subject2: {
        Id: 1,
        Name: "#_createbatchpopup_2"
    },
    Subject3: {
        Id: 2,
        Name: "#_createbatchpopup_3"
    }
}

export const createPackage = async ({
    organizationId, selectedDocumentsIds, batchDispatchType, externalTrackingId, scheduledDateUtc, afterSave, t, setSaveButtonDisabled, setLoading, setErrors, popup,
    setScheduledDateUtc, setExternalTrackingId
}) => {
    setSaveButtonDisabled(true)
    setLoading(true)

    await XfXApi.BatchApi.apiTenantIdOrganizationIdBatchPost(XfXApi.GetTenantId(), organizationId, { 
            senderVatNumber: organizationId, invoicesIds: selectedDocumentsIds, batchDispatchType: batchDispatchType.Id, externalTrackingId: externalTrackingId,
            scheduledDateUtc: scheduledDateUtc 
        })
        .then(() => {
            afterSave()
            popup.close()
            setExternalTrackingId(null)
            setScheduledDateUtc(null)
        })
        .catch(x => {
            if (x.response.status === 400) {
                notify({
                    message: x?.response?.data?.XfxDetails?.join(', ') ?? t("#_createbatchpopup_4"),
                    position: {
                        my: 'center bottom',
                        at: 'center bottom',
                    },
                }, 'error', 10000);
                popup.close()
                setExternalTrackingId(null)
                setScheduledDateUtc(null)
            }
            else if (x.response.status === 422) {
                setErrors(x.response.data)
            }
        })
        .finally(() => {
            setSaveButtonDisabled(false)
            setLoading(false)
        })
}



export const CreateBatchPopup = ({ organizationId, selectedDocumentsIds, batchDispatchType, externalTrackingId, 
    setExternalTrackingId, afterSave, t, isVisible, setIsVisible, setBlockAutoRefresh }) => {
    const { getFirstError, noErrors, setErrors, clearError } = useFormErrors()

    const [loading, setLoading] = useState(false)
    const [saveButtonDisabled, setSaveButtonDisabled] = useState(false)
    const [scheduledDateUtc, setScheduledDateUtc] = useState(null)

    const popupToolbarItems = commonPopupToolbarItems({ 
        onSave: async () => {
            await createPackage({
                organizationId: organizationId,
                selectedDocumentsIds: selectedDocumentsIds,
                batchDispatchType: batchDispatchType,
                externalTrackingId: externalTrackingId,
                scheduledDateUtc: scheduledDateUtc,
                afterSave: afterSave, t, setSaveButtonDisabled,
                setLoading, setErrors: setErrors, popup: popup,
                setScheduledDateUtc, setExternalTrackingId })
        },
        onCancel: ()=> {            
            popup.close()
            setExternalTrackingId(null)
            setScheduledDateUtc(null)
        },
        disabled: saveButtonDisabled,
        loading: loading,
        t: t
    })

    const renderEventIcon = () => {
        return (
          <>
            <div className='dx-icon dx-icon-event'></div>
          </>
        )
    }

    const popup = GenericPopup({ 
        onHiding: () => popup.close(),
        content:
        <>
                <TextBox 
                    value={organizationId} 
                    label={t("#_createbatchpopup_7")} 
                    readOnly={true}>
                </TextBox>
                <br />
                <TextBox 
                    value={selectedDocumentsIds?.length} 
                    label={t("#_createbatchpopup_8")} 
                    readOnly={true}>
                </TextBox>
                <br />
                <TextBox 
                    value={t(batchDispatchType?.Name)} 
                    readOnly={true}>
                </TextBox>
                <br />
                <TextBox 
                    value={externalTrackingId} 
                    label={t("#_batchtools_14")}
                    onValueChanged={(v) => setExternalTrackingId(v.value)}>
                </TextBox>
                <br />
                <DateBox 
                    type='date'
                    label={t("#_createbatchpopup_9")}
                    pickerType='calendar'
                    readOnly={false}
                    visible={true}
                    showDropDownButton={true}
                    dropDownButtonTemplate='renderEventIcon'
                    invalidDateMessage={t("invalidDateMessage")}
                    acceptCustomValue={false}
                    openOnFieldClick={true}
                    showClearButton={true}
                    value={scheduledDateUtc}
                    onValueChange={(v) => {
                        clearError('scheduledDateUtc')
                        setScheduledDateUtc(v?.value ?? v)
                    }}
                    validationErrors={getFirstError("scheduledDateUtc")} 
                    isValid={noErrors("scheduledDateUtc")}>
                    <Template
                        name='renderEventIcon' 
                        render={renderEventIcon}>
                    </Template>
                </DateBox>
        </>,
        toolbarItems: popupToolbarItems,
        title: t("#_createbatchpopup_5"),
        width: 400,
        height: 570,
        isVisible: isVisible,
        setIsVisible: setIsVisible,
        setBlockAutoRefresh: setBlockAutoRefresh
    })

    return popup
}
