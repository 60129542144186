import { addOrganization, editOrganization, copyToken } from "./actions"

export const toolbarButtons=  ({ deleteOrganization, selectedOrganization, dataGridOrganizations, setOrganizationId, setOrganizationNip, setVisible, setPopupTitle, t }) => {
    return [
        { icon: 'plus', text: t("button-add"), onClick: () => addOrganization({ dataGridOrganizations, setOrganizationId, setOrganizationNip, setVisible, setPopupTitle, t }) },
        { icon: 'edit', text: t("button-edit"), onClick: async() => await editOrganization({ 
            dataGridOrganizations, setOrganizationId, setOrganizationNip, setVisible, setPopupTitle, t
         }), disabled: selectedOrganization === null },
         { icon: 'copy', text: t("button-copy-api-base"), onClick: () => copyToken({ dataGridOrganizations, t }), disabled: selectedOrganization === null },
        { icon: 'trash', text: t("button-delete"), onClick: async() => await deleteOrganization(), disabled: selectedOrganization === null }
    ]
}