
import { useEffect, useState } from 'react';
import './home.scss';
import { XfXApi, GetAuthHeader, GetAuthHeaderForce, GetXfxSession } from 'api/XfXApi';
import { useUserSettingsContext } from 'contexts/UserSettingsProvider';
import { useMsal } from "@azure/msal-react";
import { useLocation } from 'react-router-dom';

const HomePage = () => {

  const [isFormLoaded, setIsFormLoaded] = useState(false)
    const [iframeWindow, setIframeWindow] = useState(false)
  const { getOrganizationContextSettings, getLanguageSettings } = useUserSettingsContext()
  const { instance, accounts } = useMsal();
  const location = useLocation();

  const organizationId = getOrganizationContextSettings()?.NIP
  const lang = getLanguageSettings()
  const tenantId = XfXApi.GetTenantId()

  const targetDomain = window.XFX_DASHBOARD_DOMAIN;

  const scheduleAccessTokenRefresh = (expiresOn) => {
    const nowUTC = new Date(); 
    const expiresOnUTC = new Date(expiresOn.toISOString()); 
    const diffInMs = expiresOnUTC - nowUTC;
    const msToForceRefresh = Math.max(diffInMs - 60 * 1000, 1);
    setTimeout(() => {
      if (location.pathname === "/home")
      {
        GetAuthHeaderForce(instance, accounts[0]).then(x =>
        {
          const iframeWindowObj = document.getElementById('dashboardIframe').contentWindow;
          const authHeaderMessage = { msgType: "authHeader", content: { [x.key]: x.value } };
          iframeWindowObj.postMessage(authHeaderMessage, targetDomain);
          scheduleAccessTokenRefresh(x.expiresOn);
        });
      }
    }, msToForceRefresh);
  }

  const onIframeLoad = () => {
    setIsFormLoaded(true);
    const iframeWindowObj = document.getElementById('dashboardIframe').contentWindow;
    setIframeWindow(iframeWindowObj);
    if (organizationId)
    {
      const endpoint = window.XFX_WEBAPI_INVOICE + "/api/" + tenantId + "/" + organizationId + "/dashboard"
      const endpointMessage = { msgType: "endpoint", content: endpoint };
      iframeWindowObj.postMessage(endpointMessage, targetDomain);
    }

    const langMessage = { msgType: "language", content: lang };
    iframeWindowObj.postMessage(langMessage, targetDomain);
    const xfxSessionMessage = { msgType: "xfxSession", content: GetXfxSession() };
    iframeWindowObj.postMessage(xfxSessionMessage, targetDomain);
    GetAuthHeader(instance, accounts[0]).then(x =>
    {
        const authHeaderMessage = { msgType: "authHeader", content: { [x.key]: x.value } };
        iframeWindowObj.postMessage(authHeaderMessage, targetDomain);
        scheduleAccessTokenRefresh(x.expiresOn);
    });
  }

  useEffect(() => {
    if (isFormLoaded)
    {
      if (organizationId)
      {
        const endpoint = window.XFX_WEBAPI_INVOICE + "/api/" + tenantId + "/" + organizationId + "/dashboard"
        const endpointMessage = { msgType: "endpoint", content: endpoint };
        iframeWindow.postMessage(endpointMessage, targetDomain);
      }
    }
  }, [organizationId, tenantId]);

  useEffect(() => {
    if (isFormLoaded)
    {
      const langMessage = { msgType: "language", content: lang };
      const iframeWindowObj = document.getElementById('dashboardIframe').contentWindow;
      iframeWindowObj.postMessage(langMessage, targetDomain);
    }
  }, [lang]);

  return (
    <>
        <iframe id="dashboardIframe" onLoad={onIframeLoad} title='dashboard' style={{ position : 'absolute', top : '0px', left: '0px', right : '0px', bottom: '0px', width: '100%', height: '100%' }} src={window.XFX_DASHBOARD_PATH}/>
    </>
  )
};

export default HomePage;
