import { XfXApi } from 'api/XfXApi';
import notify from "devextreme/ui/notify"

const acceptInvoices = async ({ organizationId, selectedInvoiceIdsWithIsDispatch, reloadGrid,t }) => {
  let authorizationError = false;
  let hasSuccessfullAccept = false;

  for (const idWithIsDispatch of selectedInvoiceIdsWithIsDispatch) {
    try {
      idWithIsDispatch.IsDispatch 
        ? await XfXApi.InvoiceApi.apiTenantIdOrganizationIdInvoicesDispatchManualAcceptPost(XfXApi.GetTenantId(), organizationId, idWithIsDispatch.Id._value, false)
        : await XfXApi.InvoiceApi.apiTenantIdOrganizationIdInvoicesReceptionManualAcceptPost(XfXApi.GetTenantId(), organizationId, idWithIsDispatch.Id._value, false)
      
        hasSuccessfullAccept = true;
    } catch (error) { 
      if (error.response.status === 400) {
        authorizationError = true;
      }
      console.log(error)
    }
  }
  
  if(authorizationError)
  {
      showAcceptErrorToast(selectedInvoiceIdsWithIsDispatch, hasSuccessfullAccept, t)
  }
  reloadGrid()
}

const showAcceptErrorToast = (selectedInvoiceIdsWithIsDispatch, hasSuccessfullAccept, t)=>
{
  let message = t("#_manual_accept_authorization_error");
  
  if(selectedInvoiceIdsWithIsDispatch.length > 1)
  {
    if(hasSuccessfullAccept)
    {
      message = t("#_manual_accept_authorization_error_multiple_rows")
    }else{
      message = t("#_manual_accept_authorization_error_all_rows")
    }
  }

  notify({
    message: message,
    position: {
      my: 'center bottom',
      at: 'center bottom',
    },
  }, 'error', 10000);
}

export { acceptInvoices }