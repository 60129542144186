import React, { useRef, useState } from 'react';
import GenericPopup from "components/popup/GenericPopup";
import { commonPopupToolbarItems } from 'components/popup/PopupTools';
import ImporterEditor from "./ImporterEditor";
import useFormErrors from 'utils/useFormErrors';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import notify from 'devextreme/ui/notify';
import { KsefixEndpoints } from 'api/XfXApi';

interface ImportPopupProps {
    state: any;
    setState: (state: any) => void;
    file: any[];
    setFile: (file: any[]) => void;
    reloadKey: number;
    setReloadKey: (key: number) => void;
    blockAutoRefresh: boolean;
    setBlockAutoRefresh: (block: boolean) => void;
}



const ImportPopup = ({
    state, setState, file, setFile,
    reloadKey, setReloadKey, blockAutoRefresh, setBlockAutoRefresh
} : ImportPopupProps) => {
    const { setErrors, getFirstError, noErrors, clearError, clearAllErrors } = useFormErrors();
    const vgForm = useRef(null);
    const { t } = useTranslation();
    const emptyState = { name: null };
    const [isVisible, setIsVisible] = useState(false);
    
    const saveAction = async () => {
        try {
            const formData = new FormData();
            if (file !== null && file !== undefined && file.length > 0) {
                formData.append("file", file[0]);
            }
            if (state.name !== null && state.name !== undefined) {
                formData.append("name", state.name);
            }

            const response = await axios.post(KsefixEndpoints.ConfigImport(), formData, {
                headers: { 'Content-Type': `multipart/form-data` },
            });

            if (response.status === 200) {
                notify({ message: t("ImportExportConfig_SuccessMessage"), position: { my: 'center bottom', at: 'center bottom' } }, 'success', 5000);
                return true;
            }
        } catch (error: any) {
            if (error.response.status === 400) {
                notify({ message: t("ImportExportConfig_ErrorMessage"), position: { my: 'center bottom', at: 'center bottom' } }, 'error', 10000);
            } else if (error.response.status === 422) {
                setErrors(error.response.data);
            }
        }
        return false;
    };


    const popupToolbarItems = commonPopupToolbarItems({
        onSave: async () => {
            const sent = await saveAction();
            if (sent) {
                clearAllErrors();
                setState({ ...emptyState });
                setFile([]);
                setReloadKey(reloadKey + 1);
                setIsVisible(false);
            }
        },
        onCancel: () => {
            clearAllErrors();
            setState({ ...emptyState });
            setFile([]);
            setIsVisible(false);
        },
        t: t,
        saveLabel: undefined,
        cancelLabel: undefined,
        includeDraft: undefined,
        onSaveDraft: undefined,
        saveButtonOptions: undefined,
        cancelButtonOptions: undefined,
        saveDraftLabel: undefined,
        saveDraftButtonOptions: undefined,
        loading: undefined,
    });

    const importerEditor = ImporterEditor({
        state: state,
        setFile: setFile,
        file: file,
        t: t,
        getFirstError, noErrors, clearError,
        vgForm: vgForm,
    });

    
  
    // @ts-ignore
    const popup = GenericPopup({
        onHiding: () => popup.close(),
        content:
            <div>
                {importerEditor}
            </div>,
        toolbarItems: popupToolbarItems,
        title: t("ImportExportConfig_ImportConfigTitle"),
        width: "812",
        height: "80%",
        isVisible: isVisible,
        setIsVisible: setIsVisible,
        setBlockAutoRefresh: setBlockAutoRefresh
    })

    return popup
};

export default ImportPopup;
