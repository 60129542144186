import { XfXApi } from "../../../../api/XfXApi"
import notify from "devextreme/ui/notify"
import RoleTypes from "../RoleTypes";
import RoleNames from "../RoleNames";
import Xfx from "App";

export const roleToString = (r, t) => {
    if (r === RoleTypes.Owner) return RoleNames({ t }).Owner;
    if (r === RoleTypes.Introspection) return RoleNames({ t }).Introspection;
    if (r === RoleTypes.Invoice_read) return RoleNames({ t }).Invoice_read;
    if (r === RoleTypes.Invoice_write) return RoleNames({ t }).Invoice_write;
    if (r === RoleTypes.Payment_confirmation_write) return RoleNames({ t }).Payment_confirmation_write;
    if (r === RoleTypes.Credentials_read) return RoleNames({ t }).Credentials_read;
    if (r === RoleTypes.Credentials_manage) return RoleNames({ t }).Credentials_manage;
    if (r === RoleTypes.Self_invoicing) return RoleNames({ t }).Self_invoicing;
    if (r === RoleTypes.Tax_representative) return RoleNames({ t }).Tax_representative;
    if (r === RoleTypes.Enforcement_operations) return RoleNames({ t }).Enforcement_operations;
    if (r === RoleTypes.Court_bailiff) return RoleNames({ t }).Court_bailiff;
    if (r === RoleTypes.Enforcement_authority) return RoleNames({ t }).Enforcement_authority;
    return "-"
}

export const statusToString = (s, t) => {
    if (s.Status === "ToAdd") return t("#_actions_2")
    if (s.Status === "ToRemove") return t("#_actions_3")
    if (s.Status === "DuringRemoval") return t("#_actions_30")
    if (s.Status === "Active") return t("#_actions_4")
    if (s.Status === "DuringActivation") return t("#_actions_31")
    if (s.Status === "ToUpdate") return t("#_actions_6")
    if (s.Status === "Error") return t("#_actions_7")

    return s.Status
}

export const rolesFromPayload = (row, t) => {

    return JSON.parse(row.Payload).GenerateToken.CredentialsRoleList
        .map(x => x.RoleType.toLowerCase())
        .map(y => roleToString(y, t)).join(', ')
}

export const deleteToken = async ({ selectedItem, setSelectedItem, selectedOrganizationContext, dataSource, t }) => {
    try {
        const response = await XfXApi.KsefCredentialsApi.apiTenantIdOrganizationIdKsefCredentialsTokenMarkToRevokePost(                                        
                                        XfXApi.GetTenantId(),
                                        selectedOrganizationContext?.NIP,
                                        selectedItem.Id.toString())

        if (response.status === 200) {
            setSelectedItem(null)
        }
    } catch (error) {
        if (error.response.status === 400) {
            notify({
                message: `${t("_ksefroles_33")} \r\n \r\n` + error.response.data.errors.join('\r\n'),
                position: {
                    my: 'center bottom',
                    at: 'center bottom',
                },
            }, 'error', 10000);
        }
    } finally {
        dataSource.reload()
    }
}

export const initSessionByTokenId = async ({ selectedItem, selectedOrganizationContext, t }) => {
    try {
        const response = await XfXApi.KsefAuthApi.apiTenantIdKsefAuthInitSessionByTokenIdPost
            (
                selectedItem.Id._value,
                selectedOrganizationContext?.NIP,
                XfXApi.GetTenantId()
            )

        if (response.status === 200) {
            notify({
                message: t("#_actions_9"),
                position: {
                    my: 'center bottom',
                    at: 'center bottom',
                },
            }, 'success', 5000);
        }
    } catch (error) {
        if (error.response.status === 400) {
            notify({
                message: `${t("_ksefroles_34")} \r\n \r\n` + error.response.data.errors.join('\r\n'),
                position: {
                    my: 'center bottom',
                    at: 'center bottom',
                },
            }, 'error', 10000);
        }
    }
}

export const onSuccessfulLogin = async ({ setSelectedItem, selectedOrganizationContext, dataGridRef, t }) => {
    try {
        const response = await XfXApi.KsefCredentialsApi.apiTenantIdOrganizationIdKsefCredentialsTokenSyncWithKsefPost
            (
                XfXApi.GetTenantId(),
                selectedOrganizationContext?.NIP
            )

        if (response.status === 200) {
            if (response.data.hasOwnProperty("infoMessage"))
            {
                notify({
                    message: response.data.infoMessage,
                    position: {
                        my: 'center bottom',
                        at: 'center bottom',
                    },
                }, 'warning', 5000);
            }
            else
            {
                notify({
                    message: t("#_actions_10"),
                    position: {
                        my: 'center bottom',
                        at: 'center bottom',
                    },
                }, 'success', 5000);
            }
        }
    } catch (error) {
        if (error.response.status === 400) {
            notify({
                message: `${t("_ksefroles_35")} \r\n \r\n` + error.response.data.errors.join('\r\n'),
                position: {
                    my: 'center bottom',
                    at: 'center bottom',
                },
            }, 'error', 10000);
        }
    } finally {
        setSelectedItem(null)
        dataGridRef.current.instance.clearSelection()
    }
}

export const syncWithKsef = async ({ selectedOrganizationContext, setKsefLoginVisible, dataSource, t }) => {
    try {
        const tokenCheck = await XfXApi.KsefCredentialsApi.apiTenantIdOrganizationIdKsefCredentialsPrivilegesByTokenGet(XfXApi.GetTenantId(), selectedOrganizationContext?.NIP)

        if(tokenCheck.data.tokenActive && tokenCheck.data.credentialsManageAvalailable)
        {
            const response = await XfXApi.KsefCredentialsApi.apiTenantIdOrganizationIdKsefCredentialsTokenSyncWithKsefPost(
                XfXApi.GetTenantId(),
                selectedOrganizationContext?.NIP)
        
            if (response.status === 200) {
                notify({
                    message: t("#_actions_14"),
                    position: {
                        my: 'center bottom',
                        at: 'center bottom',
                    },
                }, 'success', 5000);
            }
        }
        else
        {
            setKsefLoginVisible(true)
        }
    } catch (error) {
        if (error.response.status === 400) {
            notify({
                message: `${t("_ksefroles_36")} \r\n \r\n` + error.response.data.errors.join('\r\n'),
                position: {
                    my: 'center bottom',
                    at: 'center bottom',
                },
            }, 'error', 10000);
        }
    } finally {
        dataSource.reload()
    }
}

const createCredentialsRoleList = (e, t) => {
    const credList = []
    e.roles.forEach(element => {
        credList.push({ RoleType: element, RoleDescription: roleToString(element.toLowerCase(), t) })
    })

    return credList
}

export const addNewToken = async ({ e, selectedOrganizationContext, dataSource, t }) => {
    try {
        const generateTokenRequest =
        {
            GenerateToken: {
                Description: e.tokenDescription,
                CredentialsRoleList: createCredentialsRoleList(e, t)
            }
        }

        const response = await XfXApi.KsefCredentialsApi.apiTenantIdOrganizationIdKsefCredentialsTokenCreateLocallyPost
            (
                e.credentialsIdentifier,
                XfXApi.GetTenantId(),
                selectedOrganizationContext?.NIP,
                generateTokenRequest
            )

        if (response.status === 200) {
            notify({
                message: t("#_actions_15"),
                position: {
                    my: 'center bottom',
                    at: 'center bottom',
                },
            }, 'success', 5000);
        }
    } catch (error) {
        if (error.response.status === 400) {
            notify({
                message: `${t("_ksefroles_37")} \r\n \r\n`+ error.response.data.errors.join('\r\n'),
                position: {
                    my: 'center bottom',
                    at: 'center bottom',
                },
            }, 'error', 10000);
        }
    } finally {
        dataSource.reload()
    }
}

export const isLoginByTokenDisabled = ({ selectedItem }) => {
    if (selectedItem !== null) {
        if (selectedItem.ProcessingCode !== null &&  selectedItem.Status === 'Active')
            return false
    }

    return true
}
