import { useState, useEffect } from 'react';
import DataGrid, {
    Scrolling, Paging, Column
} from 'devextreme-react/data-grid';
import GenericToolbar from './Toolbars/GenericToolbar'
import { useRefreshButton } from 'utils/refresh';
import BreadCrumbs from 'components/breadCrumbs/BreadCrumbs';
import { getNode } from 'components/breadCrumbs/getNode';
import { XfXApi } from 'api/XfXApi';
import { useTranslation } from 'react-i18next';


const isAvailableToString = (value, t) => {
    return value.isAvailable === true ? t("#_modules_2") : t("#_modules_3")
};

function descriptionRenderer(data) {
    const htmlDescription = {__html:data.value}
    return <div style={{ whiteSpace: "break-spaces" }} dangerouslySetInnerHTML={htmlDescription}/>;
};

const columns = ({ t }) => [
    <Column dataField="name" caption={t("#_modules_4")} width={"auto"} />,
    <Column dataField="description" caption={t("#_modules_6")} cellRender={descriptionRenderer} />,
    <Column dataField="isAvailable" caption={t("#_modules_7")} width={"auto"} calculateCellValue={x => isAvailableToString(x, t)} />,
    <Column dataField="id" caption="Id" width={"auto"} />,
]

export const fetchModules = async ({ setModules }) => {
    try {
        const { data } = await XfXApi.ModuleApi.apiTenantIdModuleGet(XfXApi.GetTenantId())
        setModules(data)
    } catch (error) {
        console.error(error)
    }
}


const ModulesGrid = () => {
    const { t } = useTranslation()
    const [modules, setModules] = useState()

    useRefreshButton(() => fetchModules({ setModules }))

    useEffect(() => {
        fetchModules({ setModules })
    }, [t])

    return (
        <>
            <BreadCrumbs node={getNode({ componentName: 'ModulesGrid', t })}></BreadCrumbs>
            <GenericToolbar className="xfx-toolbar-bigbuttons" header={t("#_modules_9")}></GenericToolbar>
            <div id="dataGrid-failedInvoices" className="dataGrid">
                <DataGrid
                    dataSource={modules}
                    showBorders={true}
                    rowAlternationEnabled={true}
                    showColumnLines={true}
                >
                    <Scrolling mode="virtual" preloadEnabled={true} />
                    <Paging defaultPageSize={100} />
                    {columns({ t })}
                </DataGrid>
            </div>
        </>
    );
}


export default ModulesGrid 
