import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router';
import { useEffect } from 'react';
import { useAuth } from 'contexts/auth'
import { useUserSettingsContext } from "contexts/UserSettingsProvider";
import { navigation } from 'app-navigation';
import notify from 'devextreme/ui/notify';
import { useTranslation } from 'react-i18next';

const PrivsOrganizationContextChecker = () => {
  const { t } = useTranslation()
  const navigate = useNavigate();
  const location = useLocation();
  const { hasAnyPrivilege, isAdmin } = useAuth()
  const { getOrganizationContextSettings } = useUserSettingsContext()
  const organizationId = getOrganizationContextSettings()?.NIP

  useEffect(() => {
    const checkPrivilegeAndOrganizationContext = () => {
      const elementWithGivenPathName = findElementWithGivenPath(navigation, location.pathname)
      const arrayOfPrivs = elementWithGivenPathName?.requiredPrivilage;
      const adminRequired = elementWithGivenPathName?.adminOnly;
      const worksInOrganizationContext = elementWithGivenPathName?.worksInOrganizationContext;

      if ((adminRequired && !isAdmin) || (arrayOfPrivs && arrayOfPrivs.length > 0 && !hasAnyPrivilege(arrayOfPrivs, organizationId))) {
        navigate('/home')

        let noPrivsText = t("#_privscheker_noprivs")

        if (organizationId)
          noPrivsText = t("#_privscheker_noprivsfororganization")
          
        notify(noPrivsText, 'warning', 10000)
      }
      
      if (worksInOrganizationContext && (organizationId === undefined || organizationId === null)) {
        navigate('/home')
        notify(t("#_organizationContext_undefined"), 'warning', 10000);
      }
    }

    checkPrivilegeAndOrganizationContext()
  }, [location, navigate, organizationId])

  return null;
}

function findElementWithGivenPath(array, targetPath) {
  if (!array || array.length === 0)
    return null;

  for (let i = 0; i < array.length; i++) {
      if (array[i].path === targetPath) {
          return array[i];
      } else if (array[i].items) {
          let result = findElementWithGivenPath(array[i].items, targetPath);
          if (result) {
              return result;
          }
      }
  }
  return null;
}


export default PrivsOrganizationContextChecker