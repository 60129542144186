import { useEffect, useRef, useCallback, useState } from 'react';
import { XfXApi } from 'api/XfXApi';
import { autoLogoutOptions } from 'pages/settings/Security/const.jsx'
import { useAuth } from 'contexts/auth'
import { custom } from 'devextreme/ui/dialog';
import { useTranslation } from 'react-i18next';

const useInactivityLogout = (logoutFunc, runLogout = true) => {
    const [timeoutInMs, setTimeoutInMs] = useState(-1)
    const timerRef = useRef(null);
    const { currentTenantId } = useAuth()
    const { t } = useTranslation()
    const dialogClickedRef = useRef(false);
    const dialogDisplayTime = 15 * 1000;

    const resetTimer = useCallback(() => {
        if (timeoutInMs > 0)
        {
            if (timerRef.current) {
                clearTimeout(timerRef.current);
            }

            timerRef.current = setTimeout(() => {
                if (runLogout)
                {
                    let encodedMessage = t("#_InactivityLogout_1");
                    let dialog = custom({
                        title: t("#_InactivityLogout_2"),
                        messageHtml: encodedMessage,
                        buttons: [{
                            text: t("#_InactivityLogout_3"),
                            onClick: (e) => {
                                dialogClickedRef.current = true
                            }
                        }, 
                        ]
                    });
                    dialog.show();
                    setTimeout(() => {
                        if (dialogClickedRef.current === true)
                        {
                            dialogClickedRef.current = false;
                        }
                        else
                        {
                            logoutFunc();
                        }
                    }, dialogDisplayTime)
                }
            }, timeoutInMs);
        }
    }, [logoutFunc, timeoutInMs]);

    useEffect(() => {
        if (currentTenantId)
        {
            XfXApi.SettingsApi.apiTenantIdSettingsSecurityGet(XfXApi.GetTenantId())
            .then(x => {
                const timeoutInMin = autoLogoutOptions({t}).find(y => y.key == x.data.autoLogout).valueInMin;
                setTimeoutInMs((timeoutInMin * 60 * 1000) - dialogDisplayTime);
            })
            .catch(() => {});
        }
    }, [currentTenantId]);

    const handleMessageEvent = (event) => {
        // @ts-ignore
        if (event.origin !== window.XFX_DASHBOARD_DOMAIN) 
          return;
      
        const data = event.data;
    
        switch (data.msgType) {
          case "userInteraction":
            resetTimer(); 
            break;
          default:
        }
      }

    useEffect(() => {
        resetTimer();

        const events = ['mousemove', 'mousedown', 'keypress', 'scroll', 'touchstart'];

        window.addEventListener('message', handleMessageEvent);

        events.forEach(event => {
            window.addEventListener(event, resetTimer);
        });

        return () => {
            if (timerRef.current) {
                clearTimeout(timerRef.current);
            }
            events.forEach(event => {
                window.removeEventListener(event, resetTimer);
            });
            window.removeEventListener('message', handleMessageEvent);
        };
    }, [resetTimer]);

    return resetTimer;
}

export default useInactivityLogout;
