import GenericOdataGrid from './GenericOdataGrid'
import { FilterUsageTypes } from './GenericOdataGrid'
import { useUserSettingsContext } from '../../contexts/UserSettingsProvider'

const OrganizationContextOdataGrid = ({ id, ref, data, endpoint, setSelectedItem, setSelectedItems,
    defaultFilter, defaultSort, filterButtons, updateDataGridFilter, multiSelectionMode, setLastUsedQueryParms, 
    setDefaultSort, useFilterBuilder, gridKey, dataSourceOptions, setDataSourceOptions, filterUsageType = FilterUsageTypes.ODataSourceOptions,
    blockAutoRefresh, setBlockAutoRefresh }) => {

    const { getOrganizationContextSettings } = useUserSettingsContext()
    const organizationId = getOrganizationContextSettings()?.NIP
    const contextedEndpoint = endpoint(organizationId)

    return GenericOdataGrid({
        id, ref, data, endpoint: contextedEndpoint, setSelectedItem, setSelectedItems,
        defaultFilter, defaultSort, filterButtons, updateDataGridFilter, multiSelectionMode, setLastUsedQueryParms, 
        setDefaultSort, useFilterBuilder: useFilterBuilder, gridKey: gridKey, dataSourceOptions, setDataSourceOptions,
        filterUsageType: filterUsageType, blockAutoRefresh: blockAutoRefresh, setBlockAutoRefresh: setBlockAutoRefresh
    })
}

export default OrganizationContextOdataGrid