import { Decimal } from 'decimal.js';

export enum TStawkaPodatku {
    Item23 = "Item23",
    Item22 = "Item22",
    Item8 = "Item8",
    Item7 = "Item7",
    Item5 = "Item5",
    Item4 = "Item4",
    Item3 = "Item3",
    Item0WDT = "Item0WDT",
    Item0Eksport = "Item0Eksport",
    Item0Krajowe = "Item0Krajowe",
    zw = "zw",
    oo = "oo",
    npNaPodstawie = "npNaPodstawie",
    npZWylaczeniem = "npZWylaczeniem",
    vatMarza = "vatMarza"
}

export default function deserializeTStawkaPodatku(value: string): TStawkaPodatku | undefined {
    if (value in TStawkaPodatku) {
        return TStawkaPodatku[value as keyof typeof TStawkaPodatku];
    }
    return undefined;
}


export class Fa1InvoiceItem {
    ID: string;
    lp: number;
    nazwaTowaruLubUslugi: string;
    ilosc: number;
    miara: string;
    cenaJednostkowaNetto: number;
    cenaJednostkowaBrutto: number;
    rabatOpust: number | undefined;
    wartoscSprzedazyNetto: number;
    wartoscSprzedazyBrutto: number;
    kwotaPodatku: number;
    stawkaPodatku: string;
    stawkaPodatkuXII: string; //stawkaPodatkuOss
    indeks: string;
    towarUsługaZal15: boolean;
    procedura: string;
    GTU: string;
    PKWiU: string;
    GTIN: string;
    CN: string;
    PKOB: string;
    kursWaluty: number;
    kwotaPodatkuAkcyzowego: number;
    _vat: number; 
    _vatInPln: number; 
    P_6A: Date | null; // data dostawy
    stanPrzed: boolean;

    Calculate(useNetto: boolean, setKwotaPodatku: boolean) {
        const stawkaPodatkuDecimal = this.CalculateTaxRate();
        const iloscDec = new Decimal(this.ilosc);

        if (useNetto) {
            let cenaJednostkowaNettoDec = new Decimal(this.cenaJednostkowaNetto)
            if (this.rabatOpust !== undefined) {
                cenaJednostkowaNettoDec = cenaJednostkowaNettoDec.minus(new Decimal(this.rabatOpust));
            }

            const wartoscSprzedazyNettoDec = iloscDec.times(cenaJednostkowaNettoDec);
            this.wartoscSprzedazyNetto = wartoscSprzedazyNettoDec.toDecimalPlaces(2).toNumber();

            this._vat = wartoscSprzedazyNettoDec.times(stawkaPodatkuDecimal).toDecimalPlaces(2).toNumber();
            this._vatInPln = this.kursWaluty ? wartoscSprzedazyNettoDec.times(stawkaPodatkuDecimal).times(new Decimal(this.kursWaluty)).toDecimalPlaces(2).toNumber() : 0;
            if (setKwotaPodatku) {
                this.kwotaPodatku = this._vat;
            } 
            else {
                this.kwotaPodatku = 0;
            }

            const cenaJednostkowaBruttoDec = cenaJednostkowaNettoDec.times(new Decimal(1).plus(stawkaPodatkuDecimal));
            this.cenaJednostkowaBrutto = cenaJednostkowaBruttoDec.toDecimalPlaces(2).toNumber();

            const wartoscSprzedazyBruttoDec = iloscDec.times(cenaJednostkowaBruttoDec);
            this.wartoscSprzedazyBrutto = wartoscSprzedazyBruttoDec.toDecimalPlaces(2).toNumber();
        }
        else {
            let cenaJednostkowaBruttoDec = new Decimal(this.cenaJednostkowaBrutto)

            const wartoscSprzedazyBruttoDec = iloscDec.times(cenaJednostkowaBruttoDec);
            this.wartoscSprzedazyBrutto = wartoscSprzedazyBruttoDec.toDecimalPlaces(2).toNumber();

            let cenaJednostkowaNettoDec = cenaJednostkowaBruttoDec.dividedBy(new Decimal(1).plus(stawkaPodatkuDecimal)) 
            if (this.rabatOpust !== undefined) {
                cenaJednostkowaNettoDec = cenaJednostkowaNettoDec.plus(new Decimal(this.rabatOpust));
            }
            this.cenaJednostkowaNetto = cenaJednostkowaNettoDec.toDecimalPlaces(2).toNumber();

            let wartoscSprzedazyNettoDec = iloscDec.times(cenaJednostkowaNettoDec)
            this.wartoscSprzedazyNetto = wartoscSprzedazyNettoDec.toDecimalPlaces(2).toNumber();
            
            this._vat = wartoscSprzedazyNettoDec.times(stawkaPodatkuDecimal).toDecimalPlaces(2).toNumber();
            this._vatInPln = this.kursWaluty ? wartoscSprzedazyNettoDec.times(stawkaPodatkuDecimal).times(new Decimal(this.kursWaluty)).toDecimalPlaces(2).toNumber() : 0;
            if (setKwotaPodatku) {
                this.kwotaPodatku = this._vat;
            }
            else {
                this.kwotaPodatku = 0;
            }
        }
    }

    CalculateTaxRate(): Decimal {
        const tStawkaPodatku = deserializeTStawkaPodatku(this.stawkaPodatku);

        let stawkaPodatku = 0
        switch (tStawkaPodatku) {
            case TStawkaPodatku.Item23: stawkaPodatku = 23; break;
            case TStawkaPodatku.Item22: stawkaPodatku = 22; break;
            case TStawkaPodatku.Item8: stawkaPodatku = 8; break;
            case TStawkaPodatku.Item7: stawkaPodatku = 7; break;
            case TStawkaPodatku.Item5: stawkaPodatku = 5; break;
            case TStawkaPodatku.Item4: stawkaPodatku = 4; break;
            case TStawkaPodatku.Item3: stawkaPodatku = 3; break;
            case TStawkaPodatku.Item0WDT:
            case TStawkaPodatku.Item0Krajowe:
            case TStawkaPodatku.Item0Eksport:
            case TStawkaPodatku.npNaPodstawie:
            case TStawkaPodatku.npZWylaczeniem:
            case TStawkaPodatku.vatMarza:
            case TStawkaPodatku.oo:
            case TStawkaPodatku.zw:
                stawkaPodatku = 0;
                break;
            default:
                console.error("Unknown TStawkaPodatku: " + this.stawkaPodatku)
                break;
        }

        return new Decimal(stawkaPodatku).dividedBy(100)
    }

    constructor({ID,
        lp,
        nazwaTowaruLubUslugi,
        ilosc,
        miara,
        cenaJednostkowaNetto,
        cenaJednostkowaBrutto,
        rabatOpust,
        wartoscSprzedazyNetto,
        wartoscSprzedazyBrutto,
        kwotaPodatku,
        stawkaPodatku,
        stawkaPodatkuXII, 
        indeks,
        towarUsługaZal15,
        procedura,
        GTU,
        PKWiU,
        GTIN,
        CN,
        PKOB,
        kursWaluty,
        kwotaPodatkuAkcyzowego,
        stanPrzed,
        // _vat, 
        // _vatInPln, 
        P_6A }: 
        {
        ID: string,
        lp: number,
        nazwaTowaruLubUslugi: string,
        ilosc: number,
        miara: string,
        cenaJednostkowaNetto: number,
        cenaJednostkowaBrutto: number,
        rabatOpust: number,
        wartoscSprzedazyNetto: number,
        wartoscSprzedazyBrutto: number,
        kwotaPodatku: number,
        stawkaPodatku: string,
        stawkaPodatkuXII: string, 
        indeks: string,
        towarUsługaZal15: boolean,
        procedura: string,
        GTU: string,
        PKWiU: string,
        GTIN: string,
        CN: string,
        PKOB: string,
        kursWaluty: number,
        kwotaPodatkuAkcyzowego: number,
        stanPrzed: boolean,
        // _vat: number, 
        // _vatInPln: number, 
        P_6A: Date | null 
    }
    ) {
        this.ID = ID;
        this.lp = lp;
        this.nazwaTowaruLubUslugi = nazwaTowaruLubUslugi;
        this.ilosc = ilosc;
        this.miara = miara;
        this.cenaJednostkowaNetto = cenaJednostkowaNetto;
        this.cenaJednostkowaBrutto = cenaJednostkowaBrutto;
        this.rabatOpust = isNaN(rabatOpust) ? 0 : rabatOpust;
        this.wartoscSprzedazyNetto = wartoscSprzedazyNetto;
        this.wartoscSprzedazyBrutto = wartoscSprzedazyBrutto;
        this.kwotaPodatku = kwotaPodatku;
        this.stawkaPodatku = stawkaPodatku;
        this.stawkaPodatkuXII = stawkaPodatkuXII;
        this.indeks = indeks;
        this.procedura = procedura;
        this.towarUsługaZal15 = towarUsługaZal15;
        this.kwotaPodatkuAkcyzowego = kwotaPodatkuAkcyzowego;
        this.GTU = GTU;
        this.PKWiU = PKWiU;
        this.GTIN = GTIN;
        this.CN = CN;
        this.PKOB = PKOB;
        this.kursWaluty = kursWaluty;
        this.stanPrzed = stanPrzed
        this._vat = 0
        this._vatInPln = 0
        this.P_6A = P_6A;
    }
}