import { SingleCard } from './layouts';
import { useTranslation } from 'react-i18next';

const NoPermissions = () => {
  const { t } = useTranslation()

  return (<SingleCard>
            <span className={'message-text login-element'}>{t("#_unauthenticatedcontent_2")}</span>
          </SingleCard>)
}

export default NoPermissions