import { FormField } from './model';
import { Fa1InvoiceItem } from './Fa1InvoiceItem';

export const p12PossibleValues = [
  { XmlValue: 'Item23', PrintableValue: '23%' },
  { XmlValue: 'Item22', PrintableValue: '22%' },
  { XmlValue: 'Item8', PrintableValue: '8%' },
  { XmlValue: 'Item7', PrintableValue: '7%' },
  { XmlValue: 'Item5', PrintableValue: '5%' },
  { XmlValue: 'Item4', PrintableValue: '4%' },
  { XmlValue: 'Item3', PrintableValue: '3%' },
  { XmlValue: 'zw', PrintableValue: 'zw' },
  { XmlValue: 'oo', PrintableValue: 'oo' },
  { XmlValue: 'Item0Krajowe', PrintableValue: '0% - Krajowe' },
  { XmlValue: 'Item0WDT', PrintableValue: '0% - WDT' },
  { XmlValue: 'Item0Eksport', PrintableValue: '0% - Eksport' },
  { XmlValue: 'npZWylaczeniem', PrintableValue: 'np z wyłączeniem art. 100 ust 1 pkt 4' },
  { XmlValue: 'npNaPodstawie', PrintableValue: 'np na podstawie  art. 100 ust 1 pkt 4' },
  { XmlValue: 'vatMarza', PrintableValue: 'VAT marża' },
]

export const gtuPossibleValues = [
  {
      XmlValue: "GTU_01",
      PrintableValue: "GTU_01"
    },
    {
      XmlValue: "GTU_02",
      PrintableValue: "GTU_02"
    },
    {
      XmlValue: "GTU_03",
      PrintableValue: "GTU_03"
    },
    {
      XmlValue: "GTU_04",
      PrintableValue: "GTU_04"
    },
    {
      XmlValue: "GTU_05",
      PrintableValue: "GTU_05"
    },
    {
      XmlValue: "GTU_06",
      PrintableValue: "GTU_06"
    },
    {
      XmlValue: "GTU_07",
      PrintableValue: "GTU_07"
    },
    {
      XmlValue: "GTU_08",
      PrintableValue: "GTU_08"
    },
    {
      XmlValue: "GTU_09",
      PrintableValue: "GTU_09"
    },
    {
      XmlValue: "GTU_10",
      PrintableValue: "GTU_10"
    },
    {
      XmlValue: "GTU_11",
      PrintableValue: "GTU_11"
    },
    {
      XmlValue: "GTU_12",
      PrintableValue: "GTU_12"
    },
    {
      XmlValue: "GTU_13",
      PrintableValue: "GTU_13"
    },
    {
      XmlValue: null,
      PrintableValue: ""
    }
]

export const proceduraPossibleValues = [
  {
      XmlValue: "WSTO_EE",
      PrintableValue: "WSTO_EE"
    },
    {
      XmlValue: "IED",
      PrintableValue: "IED"
    },
    {
      XmlValue: "TT_D",
      PrintableValue: "TT_D"
    },
    {
      XmlValue: "I_42",
      PrintableValue: "I_42"
    },
    {
      XmlValue: "I_63",
      PrintableValue: "I_63"
    },
    {
      XmlValue: "B_SPV",
      PrintableValue: "B_SPV"
    },
    {
      XmlValue: "B_SPV_DOSTAWA",
      PrintableValue: "B_SPV_DOSTAWA"
    },
    {
      XmlValue: "B_MPV_PROWIZJA",
      PrintableValue: "B_MPV_PROWIZJA"
    },
    {
      XmlValue: null,
      PrintableValue: ""
    }
]