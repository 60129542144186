import { FindFieldById, FindFieldsByPredicate } from "./EditorOperations/CalculateFormula"
import { EditorTokensSource, EditorType, FieldType, FormField, Form as FormModel } from "./model"
import devModelKOR from "./Models/KOR.json"
import devModelNEW from "./Models/NEW.json"
import { UserInfo } from "./model"
import { Privileges } from "privileges"

interface EditorModelReturnType {
    model: FormModel | null
    title: string
}

export const getEditor = (t: any, type: EditorType, tokens: { [key: string]: any; }, userInfo: UserInfo, tokensSource: EditorTokensSource): EditorModelReturnType => {
    let title: string
    let model: FormModel | null = null
    switch (type) {
        case EditorType.Vat:
            title = "Nowa faktura"
            break;
        case EditorType.Unknown:
            title = "Edycja faktury"
            break;
        case EditorType.Correction:
            title = "Korekta faktury"
            break;
        case EditorType.CorrectionSelfInvoice:
            title = "Korekta faktury (samofakturowanie)"
            break;
        default:
            title = ""
            break
    }

    const keys = Object.keys(tokens)

    const noneShallPass = "Nikt nie może edytować"

    switch (type) {
        case EditorType.Vat:
        case EditorType.Unknown:
            model = structuredClone(devModelNEW) as unknown as FormModel
            makeFieldReadOnly(model, "Podmiot1.DaneIdentyfikacyjne.NIP", noneShallPass) // XEF-1616 - field always readonly for new invoices
            break;
        case EditorType.Correction:
        case EditorType.CorrectionSelfInvoice:
            model = structuredClone(devModelKOR) as unknown as FormModel
            makeFieldReadOnly(model, "_kefiks.wystawca", noneShallPass)

            if (keys.some(key => key.startsWith("Podmiot3[0]"))) {
                makeFieldReadOnly(model, "_Podmiot3[0].Dodaj", noneShallPass) // XEF-1477 cannot remove Podmiot3[0] if it's declared in source invoice
                makeFieldReadOnly(model, "_Podmiot3[0].Rola/RolaInna", noneShallPass)
                makeFieldReadOnly(model, "Podmiot3[0].Rola", noneShallPass)
                makeFieldReadOnly(model, "Podmiot3[0].Udzial", noneShallPass)
            }

            if (type === EditorType.Correction) {
                setFieldType(model, "Podmiot1.DaneIdentyfikacyjne.NIP", FieldType.OrganizationNip)
            } else {
                setFieldType(model, "Podmiot2.DaneIdentyfikacyjne.NIP", FieldType.OrganizationNip)
            }

            if (!userInfo.isAdmin) {
                const subjectFieldRegex = /Podmiot1\.|Podmiot2\.|Podmiot3\[0\]\./;
                let subjectFieldsFields = FindFieldsByPredicate(model, field => subjectFieldRegex.test(field.Identifier))

                if (!userInfo.privileges.includes(Privileges.DraftCorrectionEditAddress)) {
                    subjectFieldsFields.forEach(field => {
                        field.IsReadOnly = true;
                        field.LockInfo = `Wymagane uprawnienie "${t(`privilege-${Privileges.DraftCorrectionEditAddress}-name`)}"`
                    })
                }
            }

            break;
        default:
            throw new Error("Unknown editor type")
    }

    if (tokensSource !== EditorTokensSource.NewInvoice) {
        makeFieldReadOnly(model, "Fa.RodzajFaktury", noneShallPass)
    }
   
    setSequenceMaximum(model, "Fa.DaneFaKorygowanej", 1)

    return { model, title }
}

const pushFieldIfFound = (model: FormModel, fields: FormField[], id: string) => {
    const field = FindFieldById(model, id)
    if (field) fields.push(field)
}

function makeFieldReadOnly(model: FormModel, id: string, info: string) {
    const field = FindFieldById(model, id)
    if (field) {
        field.IsReadOnly = true
        field.LockInfo = info
    } else {
        console.warn("Field not found: " + id)
    }
}

function makeFieldReadOnlyByPredicate(model: FormModel, predicate: (field: FormField) => boolean) {
    const fields = FindFieldsByPredicate(model, predicate)
    if (fields.length > 0) {
        fields.forEach(field => {
            field.IsReadOnly = true
        })
    } else {
        console.warn("No fields found with the given predicate")
    }
}

function setSequenceMaximum(model: FormModel, id: string, max: number) {
    const field = FindFieldById(model, id)
    if (field) {
        field.MaxOccurs = max
    } else {
        console.warn("Field not found: " + id)
    }
}
function setFieldType(model: FormModel, id: string, type: FieldType,) {
    const field = FindFieldById(model, id)
    if (field) {
        field.Type = type
    } else {
        console.warn("Field not found: " + id)
    }
}