export const possibleSecurityEvents = [
{ key: 1, id: "Session", text: "security-event-type-session"},
{ key: 100, id: "AccountingSystem_POST", text: "security-event-type-accountingsystem_post"},
{ key: 101, id: "AccountingSystem_PUT", text: "security-event-type-accountingsystem_put"},
{ key: 102, id: "AccountingSystem_DELETE", text: "security-event-type-accountingsystem_delete"},
{ key: 103, id: "AccountingSystem_GetAccountingSystemsWithOrganizations_GET", text: "security-event-type-accountingsystem_getaccountingsystemswithorganizations_get"},
{ key: 104, id: "Contractor_POST", text: "security-event-type-contractor_post"},
{ key: 105, id: "Contractor_PUT", text: "security-event-type-contractor_put"},
{ key: 106, id: "Contractor_GET", text: "security-event-type-contractor_get"},
{ key: 107, id: "Contractor_DELETE", text: "security-event-type-contractor_delete"},
{ key: 108, id: "Contractor_UploadContractors_POST", text: "security-event-type-contractor_uploadcontractors_post"},
{ key: 109, id: "ExitPath_GET", text: "security-event-type-exitpath_get"},
{ key: 110, id: "ExitPath_POST", text: "security-event-type-exitpath_post"},
{ key: 111, id: "ExitPath_PUT", text: "security-event-type-exitpath_put"},
{ key: 112, id: "ExitPath_DELETE", text: "security-event-type-exitpath_delete"},
{ key: 113, id: "File_GET", text: "security-event-type-file_get"},
{ key: 115, id: "Info_UserContext_GET", text: "security-event-type-info_usercontext_get"},
{ key: 116, id: "KsefRole_GET", text: "security-event-type-ksefrole_get"},
{ key: 117, id: "KsefRole_PUT", text: "security-event-type-ksefrole_put"},
{ key: 118, id: "KsefRole_DELETE", text: "security-event-type-ksefrole_delete"},
{ key: 120, id: "License_UploadLicense_POST", text: "security-event-type-license_uploadlicense_post"},
{ key: 123, id: "License_DELETE", text: "security-event-type-license_delete"},
{ key: 125, id: "Organization_POST", text: "security-event-type-organization_post"},
{ key: 126, id: "Organization_PUT", text: "security-event-type-organization_put"},
{ key: 127, id: "Organization_DELETE", text: "security-event-type-organization_delete"},
{ key: 128, id: "Organization_GET", text: "security-event-type-organization_get"},
{ key: 129, id: "Organization_GetOrganizationsBasicInfo_GET", text: "security-event-type-organization_getorganizationsbasicinfo_get"},
{ key: 130, id: "Organization_GetUserOrganizations_GET", text: "security-event-type-organization_getuserorganizations_get"},
{ key: 133, id: "Pipeline_POST", text: "security-event-type-pipeline_post"},
{ key: 134, id: "Pipeline_PUT", text: "security-event-type-pipeline_put"},
{ key: 135, id: "Pipeline_DELETE", text: "security-event-type-pipeline_delete"},
{ key: 136, id: "Role_POST", text: "security-event-type-role_post"},
{ key: 137, id: "Role_DELETE", text: "security-event-type-role_delete"},
{ key: 138, id: "Role_PUT", text: "security-event-type-role_put"},
{ key: 139, id: "Role_GET", text: "security-event-type-role_get"},
{ key: 140, id: "Role_GetRoles_GET", text: "security-event-type-role_getroles_get"},
{ key: 141, id: "Role_GetPrivileges_GET", text: "security-event-type-role_getprivileges_get"},
{ key: 142, id: "Role_GetRolesWithUsersAndPrivileges_GET", text: "security-event-type-role_getroleswithusersandprivileges_get"},
{ key: 143, id: "Token_POST", text: "security-event-type-token_post"},
{ key: 144, id: "User_PUT", text: "security-event-type-user_put"},
{ key: 145, id: "User_POST", text: "security-event-type-user_post"},
{ key: 146, id: "User_GET", text: "security-event-type-user_get"},
{ key: 147, id: "User_DELETE", text: "security-event-type-user_delete"},
{ key: 148, id: "User_GetUsers_GET", text: "security-event-type-user_getusers_get"},
{ key: 149, id: "User_GetUsersWithTokens_GET", text: "security-event-type-user_getuserswithtokens_get"},
{ key: 150, id: "User_GetUserPrivileges_GET", text: "security-event-type-user_getuserprivileges_get"},
{ key: 151, id: "KsefCredentials_GenerateToken_POST", text: "security-event-type-ksefcredentials_generatetoken_post"},
{ key: 152, id: "KsefCredentials_RevokeToken_POST", text: "security-event-type-ksefcredentials_revoketoken_post"},
{ key: 153, id: "KsefCredentials_RevokeTokenV2_POST", text: "security-event-type-ksefcredentials_revoketokenv2_post"},
{ key: 154, id: "KsefCredentials_GrantCredentials_POST", text: "security-event-type-ksefcredentials_grantcredentials_post"},
{ key: 155, id: "KsefCredentials_CredentialsStatus_GET", text: "security-event-type-ksefcredentials_credentialsstatus_get"},
{ key: 156, id: "KsefCredentials_QueryCredentialsSync_POST", text: "security-event-type-ksefcredentials_querycredentialssync_post"},
{ key: 157, id: "KsefCredentials_RevokeCredentials_POST", text: "security-event-type-ksefcredentials_revokecredentials_post"},
{ key: 158, id: "KsefCredentials_Token_POST", text: "security-event-type-ksefcredentials_token_post"},
{ key: 159, id: "KsefCredentials_Token_PUT", text: "security-event-type-ksefcredentials_token_put"},
{ key: 160, id: "KsefCredentials_Token_DELETE", text: "security-event-type-ksefcredentials_token_delete"},
{ key: 161, id: "KsefCredentials_Tokens_GET", text: "security-event-type-ksefcredentials_tokens_get"},
{ key: 162, id: "KsefCredentials_GetRawToken_GET", text: "security-event-type-ksefcredentials_getrawtoken_get"},
{ key: 163, id: "KsefCredentials_GetAvailableTokensToCheck_GET", text: "security-event-type-ksefcredentials_getavailabletokenstocheck_get"},
{ key: 164, id: "KsefCredentials_TokensByRefs_PUT", text: "security-event-type-ksefcredentials_tokensbyrefs_put"},
{ key: 165, id: "KsefCredentials_TokenWithRequiredPrivs_POST", text: "security-event-type-ksefcredentials_tokenwithrequiredprivs_post"},
{ key: 166, id: "KsefCredentials_TestToken_POST", text: "security-event-type-ksefcredentials_testtoken_post"},
{ key: 167, id: "KsefTokens_GET", text: "security-event-type-kseftokens_get"},
{ key: 168, id: "KsefAuth_GetAuthchallengeConfig_GET", text: "security-event-type-ksefauth_getauthchallengeconfig_get"},
{ key: 169, id: "KsefAuth_GenerateChallengeToSign_POST", text: "security-event-type-ksefauth_generatechallengetosign_post"},
{ key: 170, id: "KsefAuth_SignChallenge_POST", text: "security-event-type-ksefauth_signchallenge_post"},
{ key: 171, id: "KsefAuth_InitSession_POST", text: "security-event-type-ksefauth_initsession_post"},
{ key: 172, id: "KsefAuth_InitSessionByToken_POST", text: "security-event-type-ksefauth_initsessionbytoken_post"},
{ key: 173, id: "KsefAuth_InitSessionByTokenId_POST", text: "security-event-type-ksefauth_initsessionbytokenid_post"},
{ key: 179, id: "OrganizationSyncSetting_GET", text: "security-event-type-organizationsyncsetting_get"},
{ key: 180, id: "OrganizationSyncSetting_POST", text: "security-event-type-organizationsyncsetting_post"},
{ key: 181, id: "OrganizationSyncSetting_PUT", text: "security-event-type-organizationsyncsetting_put"},
{ key: 182, id: "OrganizationSyncSetting_DELETE", text: "security-event-type-organizationsyncsetting_delete"},
{ key: 183, id: "Settings_DisableViews_POST", text: "security-event-type-settings_disableviews_post"},
{ key: 184, id: "DocumentTemplate_Post_POST", text: "security-event-type-documenttemplate_post_post"},
{ key: 185, id: "DocumentTemplate_Put_PUT", text: "security-event-type-documenttemplate_put_put"},
{ key: 186, id: "DocumentTemplate_Delete_DELETE", text: "security-event-type-documenttemplate_delete_delete"},
{ key: 187, id: "DocumentTemplate_GetFile_GET", text: "security-event-type-documenttemplate_getfile_get"},
{ key: 188, id: "DocumentTemplate_GetTemplate_GET", text: "security-event-type-documenttemplate_gettemplate_get"},
{ key: 189, id: "DocumentTemplateOdata_Get_GET", text: "security-event-type-documenttemplateodata_get_get"},
{ key: 191, id: "UserSettings_POST", text: "security-event-type-usersettings_post"},
{ key: 192, id: "UserSettings_PUT", text: "security-event-type-usersettings_put"},
{ key: 193, id: "UserSettings_GET", text: "security-event-type-usersettings_get"},
{ key: 194, id: "UserSettings_DELETE", text: "security-event-type-usersettings_delete"},
{ key: 195, id: "Contractor_GetDetails_GET", text: "security-event-type-contractor_getdetails_get"},
{ key: 198, id: "NotificationConfig_POST", text: "security-event-type-notificationconfig_post"},
{ key: 199, id: "NotificationConfig_PUT", text: "security-event-type-notificationconfig_put"},
{ key: 200, id: "NotificationConfig_DELETE", text: "security-event-type-notificationconfig_delete"},
{ key: 201, id: "NotificationConfig_GET", text: "security-event-type-notificationconfig_get"},
{ key: 202, id: "NotificationConfig_All_GET", text: "security-event-type-notificationconfig_all_get"},
{ key: 203, id: "NotificationProperties_GET", text: "security-event-type-notificationproperties_get"},
{ key: 204, id: "Settings_SecuritySettings_POST", text: "security-event-type-settings_securitysettings_post"},
{ key: 207, id: "Settings_SecurityLogSettings_POST", text: "security-event-type-settings_securitylogsettings_post"},
{ key: 208, id: "NumberingSerie_Post_POST", text: "security-event-type-numberingserie_post_post"},
{ key: 209, id: "NumberingSerie_Put_PUT", text: "security-event-type-numberingserie_put_put"},
{ key: 210, id: "NumberingSerie_Delete_DELETE", text: "security-event-type-numberingserie_delete_delete"},
{ key: 211, id: "NumberingSerie_GetSingle_GET", text: "security-event-type-numberingserie_getsingle_get"},
{ key: 1002, id: "Attachments_CreateArchive_POST", text: "security-event-type-attachments_createarchive_post"},
{ key: 1003, id: "Attachments_DeleteAttachment_DELETE", text: "security-event-type-attachments_deleteattachment_delete"},
{ key: 1004, id: "Attachments_GetAttachment_GET", text: "security-event-type-attachments_getattachment_get"},
{ key: 1005, id: "Attachments_PostAttachment_POST", text: "security-event-type-attachments_postattachment_post"},
{ key: 1006, id: "AttachmentsOdata_GET", text: "security-event-type-attachmentsodata_get"},
{ key: 1007, id: "Batch_DeleteBatch_DELETE", text: "security-event-type-batch_deletebatch_delete"},
{ key: 1008, id: "Batch_GetInitRequestDataUnsignedAsync_GET", text: "security-event-type-batch_getinitrequestdataunsignedasync_get"},
{ key: 1010, id: "Batch_GetUpoAsync_GET", text: "security-event-type-batch_getupoasync_get"},
{ key: 1011, id: "Batch_PostBatch_POST", text: "security-event-type-batch_postbatch_post"},
{ key: 1012, id: "Batch_SignBatch_POST", text: "security-event-type-batch_signbatch_post"},
{ key: 1013, id: "DispatchOtherAcceptance_GET", text: "security-event-type-dispatchotheracceptance_get"},
{ key: 1014, id: "DispatchOtherBatchSending_GET", text: "security-event-type-dispatchotherbatchsending_get"},
{ key: 1015, id: "DispatchOtherBatchToSend_GET", text: "security-event-type-dispatchotherbatchtosend_get"},
{ key: 1016, id: "DispatchOtherFailed_GET", text: "security-event-type-dispatchotherfailed_get"},
{ key: 1017, id: "DispatchOtherProcessing_GET", text: "security-event-type-dispatchotherprocessing_get"},
{ key: 1018, id: "DispatchSaleAcceptance_GET", text: "security-event-type-dispatchsaleacceptance_get"},
{ key: 1019, id: "DispatchSaleBatchSending_GET", text: "security-event-type-dispatchsalebatchsending_get"},
{ key: 1020, id: "DispatchSaleBatchToSend_GET", text: "security-event-type-dispatchsalebatchtosend_get"},
{ key: 1021, id: "DispatchSaleFailed_GET", text: "security-event-type-dispatchsalefailed_get"},
{ key: 1022, id: "DispatchSaleProcessing_GET", text: "security-event-type-dispatchsaleprocessing_get"},
{ key: 1023, id: "DispatchSelfInvoicingAcceptance_GET", text: "security-event-type-dispatchselfinvoicingacceptance_get"},
{ key: 1024, id: "DispatchSelfInvoicingBatchSending_GET", text: "security-event-type-dispatchselfinvoicingbatchsending_get"},
{ key: 1025, id: "DispatchSelfInvoicingBatchToSend_GET", text: "security-event-type-dispatchselfinvoicingbatchtosend_get"},
{ key: 1026, id: "DispatchSelfInvoicingFailed_GET", text: "security-event-type-dispatchselfinvoicingfailed_get"},
{ key: 1027, id: "DispatchSelfInvoicingProcessing_GET", text: "security-event-type-dispatchselfinvoicingprocessing_get"},
{ key: 1028, id: "Invoices_GetDispatchInvoiceBusinessLogAsync_GET", text: "security-event-type-invoices_getdispatchinvoicebusinesslogasync_get"},
{ key: 1029, id: "Invoices_GetDispatchInvoiceHtmlAsync_GET", text: "security-event-type-invoices_getdispatchinvoicehtmlasync_get"},
{ key: 1030, id: "Invoices_GetDispatchInvoicePdfAsync_GET", text: "security-event-type-invoices_getdispatchinvoicepdfasync_get"},
{ key: 1031, id: "Invoices_GetDispatchInvoiceXmlAsync_GET", text: "security-event-type-invoices_getdispatchinvoicexmlasync_get"},
{ key: 1033, id: "Invoices_GetReceptionCheckupsBusinessLogAsync_GET", text: "security-event-type-invoices_getreceptioncheckupsbusinesslogasync_get"},
{ key: 1034, id: "Invoices_GetReceptionInvoiceBusinessLogAsync_GET", text: "security-event-type-invoices_getreceptioninvoicebusinesslogasync_get"},
{ key: 1035, id: "Invoices_GetReceptionInvoiceHtmlAsync_GET", text: "security-event-type-invoices_getreceptioninvoicehtmlasync_get"},
{ key: 1036, id: "Invoices_GetReceptionInvoicePdfAsync_GET", text: "security-event-type-invoices_getreceptioninvoicepdfasync_get"},
{ key: 1037, id: "Invoices_GetReceptionInvoicesAsync_GET", text: "security-event-type-invoices_getreceptioninvoicesasync_get"},
{ key: 1038, id: "Invoices_GetReceptionInvoiceXmlAsync_GET", text: "security-event-type-invoices_getreceptioninvoicexmlasync_get"},
{ key: 1039, id: "Invoices_GetSessionsBusinessLogAsync_GET", text: "security-event-type-invoices_getsessionsbusinesslogasync_get"},
{ key: 1040, id: "Invoices_GetUpoAsync_GET", text: "security-event-type-invoices_getupoasync_get"},
{ key: 1041, id: "Invoices_GetUpoPdfAsync_GET", text: "security-event-type-invoices_getupopdfasync_get"},
{ key: 1042, id: "Invoices_ManualAcceptAsync_POST", text: "security-event-type-invoices_manualacceptasync_post"},
{ key: 1043, id: "Invoices_PostInvoice_POST", text: "security-event-type-invoices_postinvoice_post"},
{ key: 1044, id: "Invoices_PostInvoices_POST", text: "security-event-type-invoices_postinvoices_post"},
{ key: 1045, id: "Invoices_SearchAnyInvoiceAsync_GET", text: "security-event-type-invoices_searchanyinvoiceasync_get"},
{ key: 1046, id: "Invoices_SearchDispatchInvoiceAsync_GET", text: "security-event-type-invoices_searchdispatchinvoiceasync_get"},
{ key: 1047, id: "Invoices_SearchReceptionInvoiceAsync_GET", text: "security-event-type-invoices_searchreceptioninvoiceasync_get"},
{ key: 1048, id: "OtherRepository_GET", text: "security-event-type-otherrepository_get"},
{ key: 1049, id: "PurchaseRepository_GET", text: "security-event-type-purchaserepository_get"},
{ key: 1050, id: "ReceptionCheckups_GET", text: "security-event-type-receptioncheckups_get"},
{ key: 1051, id: "ReceptionOtherAlert_GET", text: "security-event-type-receptionotheralert_get"},
{ key: 1052, id: "ReceptionOtherProcessing_GET", text: "security-event-type-receptionotherprocessing_get"},
{ key: 1053, id: "ReceptionOtherRevoked_GET", text: "security-event-type-receptionotherrevoked_get"},
{ key: 1054, id: "ReceptionPurchaseAlert_GET", text: "security-event-type-receptionpurchasealert_get"},
{ key: 1055, id: "ReceptionPurchaseP3Alert_GET", text: "security-event-type-receptionpurchasep3alert_get"},
{ key: 1056, id: "ReceptionPurchaseP3Processing_GET", text: "security-event-type-receptionpurchasep3processing_get"},
{ key: 1057, id: "ReceptionPurchaseP3Revoked_GET", text: "security-event-type-receptionpurchasep3revoked_get"},
{ key: 1058, id: "ReceptionPurchaseProcessing_GET", text: "security-event-type-receptionpurchaseprocessing_get"},
{ key: 1059, id: "ReceptionPurchaseRevoked_GET", text: "security-event-type-receptionpurchaserevoked_get"},
{ key: 1060, id: "ReceptionRepresentationAlert_GET", text: "security-event-type-receptionrepresentationalert_get"},
{ key: 1061, id: "ReceptionRepresentationProcessing_GET", text: "security-event-type-receptionrepresentationprocessing_get"},
{ key: 1062, id: "ReceptionRepresentationRevoked_GET", text: "security-event-type-receptionrepresentationrevoked_get"},
{ key: 1063, id: "ReceptionSalesAlert_GET", text: "security-event-type-receptionsalesalert_get"},
{ key: 1064, id: "ReceptionSalesProcessing_GET", text: "security-event-type-receptionsalesprocessing_get"},
{ key: 1065, id: "ReceptionSalesRevoked_GET", text: "security-event-type-receptionsalesrevoked_get"},
{ key: 1066, id: "ReceptionSelfInvoicingAlert_GET", text: "security-event-type-receptionselfinvoicingalert_get"},
{ key: 1067, id: "ReceptionSelfInvoicingProcessing_GET", text: "security-event-type-receptionselfinvoicingprocessing_get"},
{ key: 1068, id: "ReceptionSelfInvoicingRevoked_GET", text: "security-event-type-receptionselfinvoicingrevoked_get"},
{ key: 1069, id: "SalesRepository_GET", text: "security-event-type-salesrepository_get"},
{ key: 1070, id: "Sessions_GET", text: "security-event-type-sessions_get"},
{ key: 1071, id: "Tags_Post", text: "security-event-type-tags_post"},
{ key: 1072, id: "Job_GET", text: "security-event-type-job_get"},
{ key: 1073, id: "Job_DELETE", text: "security-event-type-job_delete"},
{ key: 1074, id: "SetReceivedFlagOnInvoices_PUT", text: "security-event-type-setreceivedflagoninvoices_put"},
{ key: 1075, id: "InvoiceIntegration_PostInvoice_POST", text: "security-event-type-invoiceintegration_postinvoice_post"},
{ key: 1076, id: "InvoiceIntegration_PostInvoices_POST", text: "security-event-type-invoiceintegration_postinvoices_post"},
{ key: 1077, id: "InvoiceIntegration_SetReceivedFlagOnInvoices_PUT", text: "security-event-type-invoiceintegration_setreceivedflagoninvoices_put"},
{ key: 1078, id: "InvoiceIntegration_SearchDispatchInvoiceAsync_GET", text: "security-event-type-invoiceintegration_searchdispatchinvoiceasync_get"},
{ key: 1079, id: "InvoiceIntegration_GetDispatchInvoiceXmlAsync_GET", text: "security-event-type-invoiceintegration_getdispatchinvoicexmlasync_get"},
{ key: 1080, id: "InvoiceIntegration_GetReceptionInvoicesAsync_GET", text: "security-event-type-invoiceintegration_getreceptioninvoicesasync_get"},
{ key: 1081, id: "InvoiceIntegration_SearchReceptionInvoiceAsync_GET", text: "security-event-type-invoiceintegration_searchreceptioninvoiceasync_get"},
{ key: 1082, id: "InvoiceIntegration_GetReceptionInvoiceXmlAsync_GET", text: "security-event-type-invoiceintegration_getreceptioninvoicexmlasync_get"},
{ key: 1083, id: "InvoiceIntegration_GetUpoAsync_GET", text: "security-event-type-invoiceintegration_getupoasync_get"},
{ key: 1084, id: "DashboardData_GetKsefixVersion_GET", text: "security-event-type-dashboarddata_getksefixversion_get"},
{ key: 1085, id: "DashboardData_GetSalesVsPurchase_GET", text: "security-event-type-dashboarddata_getsalesvspurchase_get"},
{ key: 1086, id: "DashboardData_GetVatDueVsIncluded_GET", text: "security-event-type-dashboarddata_getvatduevsincluded_get"},
{ key: 1087, id: "DashboardData_GetDispatchVsReceptionVsErrors_GET", text: "security-event-type-dashboarddata_getdispatchvsreceptionvserrors_get"},
{ key: 1088, id: "DashboardMenu_GetMenuItemsQuantity_GET", text: "security-event-type-dashboardmenu_getmenuitemsquantity_get"},
{ key: 1089, id: "Invoices_GetReceptionSalesInvoicesAsync_GET", text: "security-event-type-invoices_getreceptionsalesinvoicesasync_get"},
{ key: 1090, id: "Invoices_GetReceptionOtherInvoicesAsync_GET", text: "security-event-type-invoices_getreceptionotherinvoicesasync_get"},
{ key: 1091, id: "InvoiceIntegration_GetReceptionSalesInvoicesAsync_GET", text: "security-event-type-invoiceintegration_getreceptionsalesinvoicesasync_get"},
{ key: 1092, id: "InvoiceIntegration_GetReceptionOtherInvoicesAsync_GET", text: "security-event-type-invoiceintegration_getreceptionotherinvoicesasync_get"},
{ key: 1096, id: "Invoices_SessionMarkToCloseAsync_POST", text: "security-event-type-invoices_sessionmarktocloseasync_post"},
{ key: 1097, id: "Invoices_PostDraftInvoice_POST", text: "security-event-type-invoices_postdraftinvoice_post"},
]
