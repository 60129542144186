const orgatizationsRender = (orgString) => {
    if (orgString.data.Organizations === "*") {
      return orgString.data.Organizations;
    }

    const orgList = orgString.data.Organizations.split(";;")

    return (
        <ul>
            {orgList?.map(e => <li>{e}</li>)}
        </ul>
    )
}

export const columns = ({ t })=> [
    { dataField: "Id", visible: false },
    { dataField: "OrganizationIds", visible: false },
    { dataField: "Name", width: '400px', dataType: "string", caption: t("#_accountingsystems_28") },
    { dataField: "Organizations", dataType: "object", cellRender: (x) => orgatizationsRender(x), caption: t("#_accountingsystems_30") },
]