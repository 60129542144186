import { renderDateFromUtcCell, renderDateToUtcCell } from 'utils/cellRenderers'
import { utcToLocalWithSeconds } from 'utils/utcToLocal';

export const columns = ({ t }) => 
[ 
    { dataField: "Id", dataType: "string", visible: false },
    { dataField: "Mode", dataType: "string", caption: t("#_Failure_ModeColumn"), width: "12%", visible: true,
        lookupValueExpr: "name", lookupDisplayExpr: "description", lookupDataSource: modeDataSource(t),
        editorOptions: {
            dropDownOptions: {
                minWidth: '250px'
            }
        }
    },
    { dataField: "Kind", dataType: "string", caption: t("#_Failure_KindColumn"), width: "12%", visible: true,
        lookupValueExpr: "name", lookupDisplayExpr: "description", lookupDataSource: kindDataSource(t),
        editorOptions: {
            dropDownOptions: {
                minWidth: '250px'
            }
        }
    },
    { dataField: "Type", dataType: "string", caption: t("#_Failure_TypeColumn"), width: "12%", visible: true,
        lookupValueExpr: "name", lookupDisplayExpr: "description", lookupDataSource: typeDataSource(t),
        editorOptions: {
            dropDownOptions: {
                minWidth: '250px'
            }
        }
    },
    { dataField: "DateFromUtc", dataType: "datetime", caption: t("#_Failure_DateFromUtcColumn"), width: "12%", cellRender: x => utcToLocalWithSeconds(x.value), visible: true },
    { dataField: "DateToUtc", dataType: "datetime", caption: t("#_Failure_DateToUtcColumn"), width: "12%", cellRender: x => utcToLocalWithSeconds(x.value), visible: true },
    { dataField: "Annotation", dataType: "string", caption: t("#_Failure_AnnotationColumn"), width: "40%", visible: true },
]

const modeDataSource = (t) => {
    return {
      store: {
        type: 'array',
        data: [
          { name: "Manual", description: t("#_FailureMode_Manual") },
          { name: "Automatic", description: t("#_FailureMode_Automatic") },
        ],
        key: 'name'
      },
    }
  }

const kindDataSource = (t) => {
    return {
      store: {
        type: 'array',
        data: [
          { name: "Offline", description: t("#_FailureKind_Offline") },
          { name: "Disaster", description: t("#_FailureKind_Disaster") },
        ],
        key: 'name'
      },
    }
  }

const typeDataSource = (t) => {
    return {
      store: {
        type: 'array',
        data: [
          { name: "Internal", description: t("#_FailureType_Internal") },
          { name: "KSeF", description: t("#_FailureType_KSeF") },
        ],
        key: 'name'
      },
    }
  }