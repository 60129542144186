import { useEffect, useState, useMemo, useRef, useCallback, forwardRef, useImperativeHandle } from 'react';
import Form from 'devextreme-react/form';
import { Form as FormModel, FormField, FieldGroup, EditorType, EditorTokensSource, InvoiceDateType, UserInfo, IOrganization } from "./model"
import { useUserSettingsContext } from "contexts/UserSettingsProvider";
import { Button, CheckBox } from 'devextreme-react';
import Tabs from 'devextreme-react/tabs';
import RowsEditor from './RowsEditor';
import CorrectionRowsEditor from './CorrectionRowsEditor';
import './styles.css'
import { getInvoiceItems, createTokensFromInvoiceItems, getSequenceFields } from './Fa1InvoiceItemsConverters';
import { Fa1InvoiceItem } from './Fa1InvoiceItem';
import TaxRatesSummary, { getTokensForSummary } from './TaxRatesSummary';
import { CalculateFieldsVisibility } from './EditorOperations/CalculateFieldsVisibility';
import { GetGroup } from './GetGroup';
import { GetFormValues } from './EditorOperations/GetFormValues';
import { findValue } from './FormFieldComponent';
import { hideCssClass, showCssClass, showTab } from './styleHelpers';
import { GetFormErrors } from './EditorOperations/GetFormErrors';
import { InvoicePreview } from "pages/Invoices/Popups/InvoicePreview";
import { cleanObject, filterKeys } from './helpers';
import { CalculateFormulas } from './EditorOperations/CalculateFormula';
import { getHtml, getPdf, saveXml } from './EditorOperations/FileOperations';
import { hack } from './hack';
import { XfXApi } from 'api/XfXApi';
import { TOKENS } from './customTokens';
import { applyCustomEditorRules } from './Actions/applyCustomEditorRules';
import { InvoiceDraftDto } from 'api/openapi-invoice';
import { useTranslation } from 'react-i18next';
import { Privileges } from 'privileges';
import { setOrganizationData } from '../Actions/GetTokens';
import { OrganizationDto } from 'api/openapi';

interface EditorProps {
  editorType: EditorType;
  tokensSource: EditorTokensSource;
  selectedEditorType: EditorType;
  model: FormModel;
  tokens: { [key: string]: any; };
  beforeCorrectionTokens: Record<string, any> | null;
  getXml: (download: boolean, sourceIncoiceId: string, draft: InvoiceDraftDto) => Promise<string>;
  userInfo: UserInfo;
  availableOrganizations: OrganizationDto[]
}

export interface DraftData {
  tokens: { [key: string]: any; };
  keysForXml: string[];
}

export interface EditorHandles {
  getTokens: () => { [key: string]: any; };
  getTokensForDraft: () => DraftData;
}

const printValues = (values: Record<string, any>) => {
  //  console.debug(JSON.stringify(values, null, 2)) 
}

interface SectionSettings {
  isVisible: boolean;
  selectedTabIndex: number;
}

const Editor = forwardRef<EditorHandles, EditorProps>((props, ref) => {
  const { model, tokens, editorType, beforeCorrectionTokens, tokensSource, selectedEditorType, getXml: saveAction, userInfo, availableOrganizations } = props;
  const [invoiceValues, setInvoiceValues] = useState<{ [key: string]: any; }>(CalculateFormulas(tokens, model))
  const [formErrors, setFormErrors] = useState<{ [key: string]: string | null; }>({})
  const [fieldsVisiblityMap, setFieldsVisibilityMap] = useState<{ [key: string]: { isVisible: boolean, field: FormField }; }>({})
  const [sectionsVisibility, setSectionsVisibility] = useState<SectionSettings[]>(model.Sections.map(x => ({ isVisible: true, selectedTabIndex: 0 })))
  const { getOrganizationContextSettings }: any = useUserSettingsContext()
  const [showDateForRows, setShowDateForRows] = useState<boolean>(false)
  const [showNetto, setShowNetto] = useState<boolean>(false)
  const [isCorrection, setIsCorrection] = useState<boolean | null>(null)
  const [invoiceKind, setInvoiceKind] = useState<string | null>(null)
  const isCorrectionRef = useRef(null);
  const [initShowNetto, setInitShowNetto] = useState<boolean | null>(null)
  const [showOss, setShowOss] = useState<boolean>(false)
  const [isCorrectionChangedTemp, setIsCorrectionChangedTemp] = useState<boolean>(false)
  const [showOssForBeforeCor, setShowOssForBeforeCor] = useState<boolean>(false)
  const [createdFromNewButton, setCreatedFromNewButton] = useState<boolean>(false)
  const [showOssForAfterCor, setShowOssForAfterCor] = useState<boolean>(false)
  const [showCurrencyInRowsEditor, setShowCurrencyInRowsEditor] = useState<boolean>(false)
  const [noRowsMode, setNoRowsMode] = useState<boolean>(true)
  const [availableFkSystems, setAvailableFkSystems] = useState<string[] | null>(null);
  const [numberingSeriesDataSource, setNumberingSeriesDataSource] = useState<any>([])

  const organizationId = getOrganizationContextSettings()?.NIP
  const invoicePreview = InvoicePreview()

  const [invoiceItems, setInvoiceItems] = useState<Fa1InvoiceItem[]>();

  const { t } = useTranslation()

  const getTokens = () => {
    const editedTokens = getAllTokensAndValues(invoiceValues)
    if (beforeCorrectionTokens === null) {
      return editedTokens
    }

    return { ...editedTokens }
  };

  useImperativeHandle(ref, () => ({
    getTokens,
    getTokensForDraft
  }));

  const validate = (model: FormModel, formValues: { [key: string]: any; }) => {
    const errors = GetFormErrors(model, formValues)
    setFormErrors(errors)
  }

  useEffect(() => {
    if (invoiceValues === undefined) return
    if (invoiceItems == undefined || invoiceItems.length == 0) {
      const items = getInvoiceItems(invoiceValues);
      setInvoiceItems(items);
    }
    printValues(invoiceValues)

    validate(model, invoiceValues)
  }, [invoiceValues, model])

  useEffect(() => {
    XfXApi.User.apiTenantIdUserAccountingSystemsGet(XfXApi.GetTenantId(), organizationId).then((x: any) => {
      let purchaseSystems = ["", ...x.data.purchaseAccountingSystems]
      let saleSystems = ["", ...x.data.saleAccountingSystems]

      if (selectedEditorType === EditorType.Vat) {
        delete invoiceValues['Naglowek.SystemInfo']
        if (x.data.saleAccountingSystems.length == 1) {
          let values = { ['Naglowek.SystemInfo']: x.data.saleAccountingSystems[0], ...invoiceValues }
          setInvoiceValues(values);
        }
        else {
          let values = { ['Naglowek.SystemInfo']: 'KSEF Insight', ...invoiceValues }
          setInvoiceValues(values);
        }
      } else {
        if (invoiceValues['Naglowek.SystemInfo'] !== undefined && invoiceValues['Naglowek.SystemInfo'] !== null && invoiceValues['Naglowek.SystemInfo'] !== '') {
          var invoiceSystem = invoiceValues['Naglowek.SystemInfo'];
          if (!purchaseSystems.includes(invoiceSystem))
            purchaseSystems = [...purchaseSystems, invoiceSystem];
          if (!saleSystems.includes(invoiceSystem))
            saleSystems = [...saleSystems, invoiceSystem];
        }
      }
      if (invoiceValues['_kefiks.wystawca'] === 'Sprzedawca') {
        setAvailableFkSystems(saleSystems);
      }
      else {
        setAvailableFkSystems(purchaseSystems);
      }
    });

  }, [])

  useEffect(() => {
    model.Sections.forEach((section, sectionIndex) => {
      showTab(section, sectionIndex, sectionsVisibility[sectionIndex].selectedTabIndex, sectionsVisibility[sectionIndex].isVisible)
    });
  }, [sectionsVisibility])


  useEffect(() => {
    const fields: FormField[] = [];
    model.Sections.forEach(section => {
      section.Groups.forEach(group => {
        group.Fields.forEach(field => {
          fields.push(field);
        });
      });

      const newFieldsVisibilityMap = fields.reduce<Record<string, { isVisible: boolean, field: FormField }>>((acc, field) => {
        acc[field.Address] = {
          isVisible: false,
          field: field
        };
        return acc;
      }, {});
      setFieldsVisibilityMap(newFieldsVisibilityMap)
    });
    setNoRowsMode(invoiceValues[TOKENS._CorrectionNoRowsMode] === true)
  }, [])

  useEffect(() => {
    // hide fields
    for (const key in fieldsVisiblityMap) {
      if (fieldsVisiblityMap.hasOwnProperty(key)) {
        const { isVisible, field } = fieldsVisiblityMap[key];
        const className = `invoice-editor-field-${field.Address}`
        if (isVisible) {
          showCssClass(className)
        } else {
          hideCssClass(className)
        }
      }
    }

    // hide sections if all fields are hidden
    model.Sections.forEach((section, sectionIndex) => {
      const className = `invoice-editor-section-${sectionIndex}`
      let showSection = false
      for (let i = 0; i < section.Groups.length; i++) {
        const group = section.Groups[i];

        if (group.Fields.every(x => fieldsVisiblityMap[x.Address] !== undefined && !fieldsVisiblityMap[x.Address].isVisible)) {

        } else {
          showSection = true
          break
        }
      }
      if (showSection) {
        showCssClass(className)
      } else {
        hideCssClass(className)
      }
    })
  }, [fieldsVisiblityMap])

  const updateSectionVisibility = (sectionIndex: number, isVisible: boolean, selectedTab: number | null) => {
    const newSectionsVisibility = [...sectionsVisibility]
    if (sectionsVisibility[sectionIndex].isVisible !== isVisible ||
      (selectedTab !== null && sectionsVisibility[sectionIndex].selectedTabIndex !== selectedTab)) {
      newSectionsVisibility[sectionIndex].isVisible = isVisible
      if (selectedTab !== null)
        newSectionsVisibility[sectionIndex].selectedTabIndex = selectedTab
      setSectionsVisibility(newSectionsVisibility)
    }
  }

  const collapseAllSections = useCallback(() => {
    for (let i = 0; i < sectionsVisibility.length; i++) {
      const section = sectionsVisibility[i];
      showTab(model.Sections[i], i, section.selectedTabIndex, false)
    }
    setSectionsVisibility(sectionsVisibility.map(x => ({ ...x, isVisible: false })))
  }, [sectionsVisibility])

  const setValuesAndRecalculateFormulas = useCallback((values: Record<string, any>) => {
    const update = CalculateFormulas(values, model)
    printValues(update)
    setInvoiceValues(update)
  }, []);

  useEffect(() => {
    if (tokens === undefined) return
    setValuesAndRecalculateFormulas(tokens)
  }, [tokens, setValuesAndRecalculateFormulas])

  // Update fields visibility
  useEffect(() => {
    if (Object.keys(fieldsVisiblityMap).length === 0) return

    const ret = CalculateFieldsVisibility({ fieldsVisiblityMap, invoiceValues })
    if (ret === undefined) return
    const { anythingChanged, update } = ret

    if (anythingChanged) {
      setFieldsVisibilityMap(update)
    }

    const errors = GetFormErrors(model, invoiceValues)
    setFormErrors(errors)
    const _faP6 = invoiceValues[TOKENS._Fa_P_6]
    setShowDateForRows(_faP6 !== undefined && _faP6 !== null && _faP6 === InvoiceDateType.Various)
    setShowCurrencyInRowsEditor(invoiceValues["Fa.KodWaluty"] !== undefined && invoiceValues["Fa.KodWaluty"] !== null && invoiceValues["Fa.KodWaluty"] !== "PLN")
    hack(fieldsVisiblityMap, invoiceValues)
    setShowOssForBeforeCor(invoiceValues["_ksefix.ossBeforeCorrection"] !== undefined && invoiceValues["_ksefix.ossBeforeCorrection"] !== null && invoiceValues["_ksefix.ossBeforeCorrection"] === "true")
    setShowOssForAfterCor(invoiceValues["_ksefix.ossAfterCorrection"] !== undefined && invoiceValues["_ksefix.ossAfterCorrection"] !== null && invoiceValues["_ksefix.ossAfterCorrection"] === "true")
    setShowOss(invoiceValues["_ksefix.oss"] !== undefined && invoiceValues["_ksefix.oss"] !== null && invoiceValues["_ksefix.oss"] === "true")
    setCreatedFromNewButton(editorType === EditorType.Vat || (invoiceValues["_ksefix.createdFromNewButton"] !== undefined && invoiceValues["_ksefix.createdFromNewButton"] !== null && invoiceValues["_ksefix.createdFromNewButton"] === "true"))
    const showNetto = invoiceValues["_kefiks.nettobrutto"] !== undefined && invoiceValues["_kefiks.nettobrutto"] !== null && invoiceValues["_kefiks.nettobrutto"] === "netto";
    setShowNetto(showNetto)
    if (initShowNetto === null) {
      setInitShowNetto(showNetto)
    }

    setIsCorrection(invoiceValues["Fa.RodzajFaktury"] === "KOR")
    setInvoiceKind(invoiceValues["Fa.RodzajFaktury"])

  }, [invoiceValues, fieldsVisiblityMap])

  useEffect(() => {
    if (invoiceKind) {
      // @ts-ignore
      XfXApi.NumberingSeries.apiTenantIdNumberingSeriesGetByInvKindGet(XfXApi.GetTenantId(), invoiceKind, organizationId)
        .then((x: any) => setNumberingSeriesDataSource(x.data))
    }
  }, [invoiceKind])

  const customCommonTabsTemp = (clearRowsTemp: boolean, userInfo: UserInfo) => {
    const rowsEditor = invoiceItems === undefined ? (<></>) : <RowsEditor showP_6A={showDateForRows} showCurrency={showCurrencyInRowsEditor}
      invoiceItems={invoiceItems} setInvoiceItems={setInvoiceItems} showNetto={showNetto} clearRowsTemp={clearRowsTemp}
      fieldsVisiblityMap={fieldsVisiblityMap} setShowOss={setShowOss} showOss={showOss}
      FieldGroup={model.Sections[7].Groups[1 - 1]}

    /> // section 7_1
    const taxRatesSummary = invoiceItems === undefined ? (<></>) : <TaxRatesSummary invoiceItems={invoiceItems} showCurrency={showCurrencyInRowsEditor}
      fieldsVisiblityMap={fieldsVisiblityMap} noRowsMode={noRowsMode} isCorrection={isCorrection}
      FieldGroup={model.Sections[7].Groups[1 - 1]} />

    const correctionRowsEditor = invoiceItems === undefined ? (<></>) : <CorrectionRowsEditor
      editMode={editorType !== EditorType.Vat && editorType !== EditorType.Correction && editorType !== EditorType.CorrectionSelfInvoice}
      showP_6A={showDateForRows} showCurrency={showCurrencyInRowsEditor} clearRowsTemp={clearRowsTemp}
      setShowOssForBeforeCor={setShowOssForBeforeCor} setShowOssForAfterCor={setShowOssForAfterCor}
      showOssForBeforeCor={showOssForBeforeCor} showOssForAfterCor={showOssForAfterCor}
      showNetto={showNetto} initShowNetto={initShowNetto}
      invoiceItems={invoiceItems} setInvoiceItems={setInvoiceItems} correctedInvIsInKsefix={!createdFromNewButton}
      fieldsVisiblityMap={fieldsVisiblityMap}
      FieldGroup={model.Sections[7].Groups[1 - 1]}
      noRowsMode={noRowsMode}
      setNoRowsMode={setNoRowsMode}
      isReadOnly={(editorType === EditorType.Correction || editorType === EditorType.CorrectionSelfInvoice) && !userInfo.privileges.includes(Privileges.DraftCorrectionEditFiscal)}
    />

    const ret: { [key: string]: any; } = {}

    if (isCorrection === true) {
      ret["7_1"] = correctionRowsEditor
    }
    else {
      ret["7_1"] = rowsEditor
    }
    ret["7_2"] = taxRatesSummary

    return ret
  }

  const customTabs = useMemo(() => {
    return customCommonTabsTemp(false, userInfo)
  }, [invoiceItems, showDateForRows, showCurrencyInRowsEditor, fieldsVisiblityMap, model.Sections, showOssForAfterCor, showOssForBeforeCor, showNetto, initShowNetto,
    noRowsMode, setNoRowsMode, isCorrection, userInfo])

  const customTabsTemp = useMemo(() => {
    return customCommonTabsTemp(true, userInfo)
  }, [invoiceItems, showDateForRows, showCurrencyInRowsEditor, fieldsVisiblityMap, model.Sections, showOssForAfterCor, showOssForBeforeCor, showNetto, initShowNetto,
    noRowsMode, setNoRowsMode, isCorrection, userInfo])

  useEffect(() => {
    if (isCorrectionRef.current !== null && isCorrection !== null) {
      if (isCorrection !== isCorrectionRef.current) {
        setIsCorrectionChangedTemp(true);
      }
    }

    if (isCorrectionRef.current === null && isCorrection !== null) {
      // @ts-ignore
      isCorrectionRef.current = isCorrection;
    }
  }, [isCorrection])

  const getCustomTokens = () => {
    let customTokens: { [key: string]: string } = {};
    customTokens["_ksefix.ossBeforeCorrection"] = showOssForBeforeCor.toString();
    customTokens["_ksefix.ossAfterCorrection"] = showOssForAfterCor.toString();
    customTokens["_ksefix.oss"] = showOss.toString();
    customTokens["_ksefix.createdFromNewButton"] = createdFromNewButton.toString();

    return customTokens;
  }

  const getAllTokensAndValues = (updatedValues: { [x: string]: any }): { [x: string]: any } => {
    const tokensFromVisibleFields = Object.keys(fieldsVisiblityMap)
      .map(x => fieldsVisiblityMap[x])
      .filter(x => x.isVisible)
      .map(x => x.field.Identifier)

    const filteredUpdatedFields = filterKeys(updatedValues, tokensFromVisibleFields)

    return getAllTokensAndValues2(filteredUpdatedFields)
  }

  const getTokensForDraft = () => {
    const allTokens = getAllTokensAndValues2(invoiceValues)
    const tokensForXml = getTokensForXml(invoiceValues)
    return {
      tokens: allTokens,
      keysForXml: Object.keys(tokensForXml)
    }
  }

  const getAllTokensAndValues2 = (tokens: { [x: string]: any }): { [x: string]: any } => {
    const tokensForSummary = getTokensForSummary(invoiceItems || [], showCurrencyInRowsEditor, isCorrection, noRowsMode);

    let toSend = {
      ...getCustomTokens(),
      ...tokens,
      ...tokensForSummary,
      ...createTokensFromInvoiceItems(invoiceItems || [], showDateForRows, showCurrencyInRowsEditor, showNetto, initShowNetto),
      ...getSequenceFields(invoiceValues, fieldsVisiblityMap)
    }

    toSend[TOKENS._EditorType] = editorType
    if (tokens[TOKENS._SourceCorrectionId] !== undefined) {
      toSend[TOKENS._SourceCorrectionId] = tokens[TOKENS._SourceCorrectionId]
    }

    if (!noRowsMode || !isCorrection) {
      toSend = {
        ...toSend,
        ...createTokensFromInvoiceItems(invoiceItems || [], showDateForRows, showCurrencyInRowsEditor, showNetto, initShowNetto)
      }
    }

    toSend[TOKENS._CorrectionNoRowsMode] = noRowsMode

    toSend = applyCustomEditorRules(toSend)
    return toSend
  }

  const getTokensForXml = (valuesDictionary: { [x: string]: any }) => {
    const isXmlToken = (x: string, v: any) => x.includes(".") && !x.startsWith("_")
    const valueIsNotEmptyString = (x: string, v: any) => v !== ""
    const toSend = getAllTokensAndValues(valuesDictionary)

    let toSendCleard = cleanObject(toSend, isXmlToken)
    toSendCleard = cleanObject(toSendCleard, valueIsNotEmptyString)
    toSendCleard[TOKENS._CorrectionNoRowsMode] = noRowsMode

    return toSendCleard
  }

  const editor = useMemo(() => {
    if (invoiceValues === undefined) {
      return
    }

    console.debug("render editor")

    let updatedValues = { ...invoiceValues }

    const generateXml = async (download: boolean, valuesDictionary: { [x: string]: any }): Promise<any> => {
      const draftData = getTokensForDraft()
      return saveAction(download, organizationId, draftData)
    }

    const setValue = (key: string, address: string, v: any, printableValue: any) => {
      if (v === undefined) return

      console.debug("setValue " + key + " " + address + " " + v + " " + printableValue)

      const updated = { ...updatedValues }
      var keyOldValue = findValue(updatedValues, key)
      if (keyOldValue.key !== undefined) {
        if (v !== keyOldValue.value) {

          console.debug("new value " + keyOldValue.key + " " + keyOldValue.value + " -> " + v)
          updated[keyOldValue.key] = v

          if (editorType === EditorType.Correction &&  key === "Podmiot1.DaneIdentyfikacyjne.NIP") {
            const organization = availableOrganizations.find(x => x.nip === v)
            if (organization !== undefined) {
              setOrganizationData(updated, organization, "Podmiot1")
            }
          }

          if (editorType === EditorType.CorrectionSelfInvoice &&  key === "Podmiot2.DaneIdentyfikacyjne.NIP") {
            const organization = availableOrganizations.find(x => x.nip === v)
            if (organization !== undefined) {
              setOrganizationData(updated, organization, "Podmiot2")
            }
          }

          setValuesAndRecalculateFormulas(updated)
          updatedValues = updated
        }
      } else {
        console.debug("set new key with value " + key + " " + v)
        updated[key] = v
        setValuesAndRecalculateFormulas(updated)
        updatedValues = updated
      }    
    }

    const buttons = <div className='invoice-editor-buttons'>
      <Button onClick={async () => getHtml(await generateXml(false, updatedValues), false, organizationId, (x: any) => invoicePreview.showInvoicePreview(x))}>Podgląd</Button>
      <Button onClick={async () => getPdf(await generateXml(false, updatedValues), organizationId)}>Zapisz PDF</Button>
      <Button onClick={async () => getHtml(await generateXml(false, updatedValues), true, organizationId, (x: any) => invoicePreview.showInvoicePreview(x))}>Zapisz HTML</Button>
      <Button onClick={async () => saveXml(await generateXml(false, updatedValues), true)}>Zapisz XML</Button>
      <Button onClick={collapseAllSections}>Zwiń wszystkie sekcje</Button ></div>

    return <>
      <div className='invoice-editor-version'>{model.Version}</div>
      {buttons}

      <div>
        {updatedValues === undefined ? <></> : model.Sections
          .filter(x => !(x.Groups.length === 1 && x.Groups[0].Name === "UNKNOWN"))
          .map((s, sectionIndex) => {
            let sectionVisible = sectionsVisibility[sectionIndex].isVisible
            let tabIndex = 0

            let sectoinCssClass = "invoice-editor-section invoice-editor-section-" + sectionIndex

            return (<div className={sectoinCssClass}>
              <div className='invoice-editor-header'>
                <Tabs
                  width={"auto"}
                  dataSource={s.Groups.map(x => x.Name)}
                  defaultSelectedIndex={0}
                  onSelectedIndexChange={i => {
                    tabIndex = i
                    updateSectionVisibility(sectionIndex, sectionVisible, tabIndex)
                  }}
                  onItemClick={(e) => {
                    if (!sectionsVisibility[sectionIndex].isVisible) {
                      updateSectionVisibility(sectionIndex, true, null)
                    }
                  }}
                />
                <CheckBox text={sectionVisible ? 'Zwiń sekcję' : 'Rozwiń sekcję'}
                  onValueChange={(x) => {
                    sectionVisible = x === true
                    updateSectionVisibility(sectionIndex, sectionVisible, null)
                  }}
                  value={sectionVisible}
                ></CheckBox>
              </div>
              <Form className={"xfx-form"}>
                {s.Groups
                  // .filter(x => x.Id !== "8_2")
                  .map((group: FieldGroup, index: number) => {
                    return GetGroup({
                      s_i: sectionIndex,
                      index: index,
                      group: group,
                      updatedValues: updatedValues,
                      setValue: setValue,
                      customGroups: isCorrectionChangedTemp ? customTabsTemp : customTabs,
                      formErrors: formErrors,
                      editorType: editorType,
                      beforeCorrectionValues: beforeCorrectionTokens,
                      numberingSeriesDataSource: numberingSeriesDataSource,
                      availableFkSystems: availableFkSystems,
                      setValuesAndRecalculateFormulas: setValuesAndRecalculateFormulas,
                      availableOrganizations,
                      t: t
                    })
                  }
                  )}
              </Form>
            </div>)
          })
        }   </div>
      {buttons}
    </>


  }, [organizationId, sectionsVisibility, customTabs, invoiceValues, formErrors, isCorrectionChangedTemp, numberingSeriesDataSource, availableFkSystems])

  const component = <>
    {invoicePreview.popup}
    {editor}
  </>

  return component
})

export default Editor