import { useState, createContext, useContext, useEffect } from 'react';
import { useAuth } from './auth';
import { XfXApi } from '../api/XfXApi';
import useToast from '../utils/useToast';

const UserSettingsProvider = (props) => {
  const { userSettings, setUserSettings } = useAuth();
  const { toast, showToastError } = useToast()

  const getAutoRefreshSettings = () => userSettings?.settings?.AutoRefresh
  const getLanguageSettings = () => userSettings?.settings?.Language
  const getOrganizationContextSettings = () => userSettings?.settings?.OrganizationContext
  const getColumnsVisible = (dataGridId) => userSettings?.settings?.ColumnsVisible !== undefined
    && userSettings?.settings?.ColumnsVisible !== null ? userSettings?.settings?.ColumnsVisible[dataGridId]
    : userSettings?.settings?.ColumnsVisible
  const getColumnsFixed = (dataGridId) => userSettings?.settings?.ColumnsFixed !== undefined
    && userSettings?.settings?.ColumnsFixed !== null ? userSettings?.settings?.ColumnsFixed[dataGridId]
    : userSettings?.settings?.ColumnsFixed

  const getColumnsToSort = (dataGridId) => userSettings?.settings?.ColumnsToSort !== undefined
    && userSettings?.settings?.ColumnsToSort !== null ? userSettings?.settings?.ColumnsToSort[dataGridId]
    : userSettings?.settings?.ColumnsToSort

  const getEmptySorting = (dataGridId) => userSettings?.settings?.EmptySorting !== undefined
    && userSettings?.settings?.EmptySorting !== null ? userSettings?.settings?.EmptySorting[dataGridId]
    : userSettings?.settings?.EmptySorting

  const getColumnsAvailableInChooser = (dataGridId) => userSettings?.settings?.ColumnsAvailableInChooser !== undefined 
                                                    && userSettings?.settings?.ColumnsAvailableInChooser !== null ? userSettings?.settings?.ColumnsAvailableInChooser[dataGridId] 
                                                                                                                  : userSettings?.settings?.ColumnsAvailableInChooser;

  const getColumnVisibleIndex = (dataGridId) => userSettings?.settings?.VisibleIndex !== undefined
    && userSettings?.settings?.VisibleIndex !== null ? userSettings?.settings?.VisibleIndex[dataGridId]
    : userSettings?.settings?.VisibleIndex


  const getUserSettingsFromDb = async (userId) => {
    return await XfXApi.UserSettings.apiTenantIdUserSettingsUserIdGet(XfXApi.GetTenantId(), userId)
      .then(us => {
        return { ...us.data, settings: JSON.parse(us.data.settings) }
      })
      .catch(e => {
        return null
      })
  }

  const updateUserSettingsInDb = async (id, userSettingsDto) => {
    await XfXApi.UserSettings.apiTenantIdUserSettingsIdPut(id, XfXApi.GetTenantId(), userSettingsDto)
      .then(x => {
        setUserSettings({ ...x.data, settings: JSON.parse(x.data.settings) })
      })
      .catch(e => {
        showToastError(`${e?.response}`)
      })
  }

  const postUserSettingsIntoDb = async (userSettingsDto) => {
    await XfXApi.UserSettings.apiTenantIdUserSettingsPost(XfXApi.GetTenantId(), userSettingsDto)
      .then(x => {
        setUserSettings({ ...x.data, settings: JSON.parse(x.data.settings) })
      })
      .catch(e => {
        showToastError(`${e?.response}`)
      })
  }

  return (
    <UserSettingsContext.Provider value={{
      getUserSettingsFromDb, updateUserSettingsInDb, postUserSettingsIntoDb,
      getAutoRefreshSettings, getLanguageSettings, getOrganizationContextSettings,
      getColumnsVisible, getColumnsFixed, getColumnsToSort, getEmptySorting, getColumnsAvailableInChooser,
      getColumnVisibleIndex
    }} {...props} />
  );
}

const UserSettingsContext = createContext({})
const useUserSettingsContext = () => useContext(UserSettingsContext)

export { UserSettingsProvider, useUserSettingsContext }