import Toolbar, { Item } from 'devextreme-react/toolbar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from 'devextreme-react/button';
import DropDownButton from 'devextreme-react/drop-down-button';
import './style.css'
import '../../../styles/toolbarIcons.css'


const GenericToolbar = ({ header, buttons, showIcon = true, icon = "dx-icon-preferences",
  customHeaderRenderer, useFa = false, faIcon = null, toolbarId,
  className, showSimpleHeader = false, customHeaderRendererLocation = "after" }) => {

  function renderLabel() {
    return (
      <div className="toolbar-label">
        {handleIcon(showIcon)}
        {header}
      </div>
    )
  }

  function renderItemToLabel(item) {
    return (
      <div className="toolbar-button-label">
        {item.text ?? ''}
      </div>
    )
  }

  const renderItemToDropdownButton = ({ options }) => {
    return (
      <DropDownButton
        className='xfx-ribbon-button'
        splitButton={true}
        useSelectMode={true}
        icon={options?.icon}
        items={options?.dropdownItems}
        displayExpr={options?.displayExpr}
        keyExpr={options?.keyExpr}
        onButtonClick={options?.onButtonClick}
        onItemClick={options?.onItemClick}
        selectedItemKey={options?.initialKey}
      />
    )
  }

  const toItem = (options, key) => {
    if (options.isItem) {
      return <Item key={key} location="after"
        locateInMenu="auto"
        render={() => options.item} />
    }

    return (
      options?.isLabel ?
        <Item key={key} location="after"
          locateInMenu="auto"
          text={options?.text}
          render={renderItemToLabel} /> :
        options?.isDropdownButton ?
          <Item key={key} location="after"
            locateInMenu="auto"
            render={() => renderItemToDropdownButton({ options })} /> :
          <Item key={key}
            location={options.location ?? "after"}
            locateInMenu={options.locateInMenu ?? "auto"}
            widget="dxButton"
            menuItemRender={x => {

              return <Button
                className='xfx-ribbon-button'
                disabled={x.options.disabled}
                text={x.options.text}
                icon={x.options.icon}
                visible={x.options.visible}
                onClick={() => x.options.onClick()}
              />
            }}
            options={{
              ...options
            }}
          />


    )
  }

  function handleIcon(showIcon) {
    if (useFa && faIcon !== null)
      return <FontAwesomeIcon icon={faIcon} />

    if (showIcon)
      return <i className={icon}></i>
  }

  let buttonKey = 0
  return (
    <div className={className}>
      <Toolbar id={toolbarId}>
        {customHeaderRenderer === undefined && showSimpleHeader && <Item location="before"
          locateInMenu="never"
          render={renderLabel} />}
        {buttons !== undefined && buttons.filter(y => y.location === 'before').map(x => toItem(x, buttonKey++))}
        {customHeaderRenderer && <Item location={customHeaderRendererLocation}
          locateInMenu="never"
          render={customHeaderRenderer} />}
        {buttons !== undefined && buttons.filter(y => y.location !== 'before').map(x => toItem(x, buttonKey++))}
      </Toolbar>
    </div>
  );
}

export default GenericToolbar