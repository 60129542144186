import { useState, useRef } from 'react'
import List from 'devextreme-react/list';
import { Button, DropDownBox } from 'devextreme-react';
import { SingleCard } from './layouts';
import { useTranslation } from 'react-i18next';

const TenantSelector = ({ availableTenats, selectCallback }) => {
  const { t } = useTranslation()

  const [selectedTenantId, setSelectedTenantId] = useState(null)
  const dropDownBoxRef = useRef(null)

  function tenantsRender() {
    return (
        <List
          className='list-tenants'
          dataSource={availableTenats}
          selectionMode='single'
          onSelectedItemsChange={x => {
              setSelectedTenantId(x[0]?.id)
          }}
          itemRender={x => x.name}
          scrollingEnable={true}
        >
        </List>
    )
  }

  return (<SingleCard title={t("#_unauthenticatedcontent_1")}>

    <div className='xfx-select-tenant'>
      <DropDownBox
                    ref={dropDownBoxRef}
                    value={selectedTenantId}
                    valueExpr="id"
                    deferRendering={false}
                    displayExpr="name"
                    placeholder={t("#_tenantSelector_placeHolder")}
                    showClearButton={false}
                    onValueChanged={e => {
                      dropDownBoxRef?.current?.instance?.close()
                    }}
                    dataSource={availableTenats}
                    label={t("#_tenantSelector_label")}
                    contentRender={tenantsRender}
                  >
        </DropDownBox>
    </div>

    <Button className={'login-button login-element'}
      disabled={selectedTenantId === null} onClick={() => selectCallback.onClick(selectedTenantId)}
      type={'default'}
      useSubmitBehavior={true}>Dalej</Button>
  </SingleCard >)
}

export default TenantSelector