import { rolesFromPayload, statusToString } from "./actions"
import { Column } from 'devextreme-react/data-grid';

export const columns = ({ t }) => {
    return [
        <Column dataField={t("#_columns_1")} width="auto" caption={t("#_columns_2")} visible={false} dataType='string' key='Nip' />,
        <Column dataField="CredentialsIdentifier" width="auto" caption="Kontekst użytkownika (NIP/PESEL)" dataType='string' key='CredentialsIdentifier' />,
        <Column dataField="Description" caption={t("#_columns_7")} dataType='string' key='Description' />,
        <Column dataField="Payload" width="auto" caption={t("#_columns_10")} calculateCellValue={(r) => rolesFromPayload(r, t)} dataType='string' key='Payload' />,
        <Column dataField={t("#_columns_12")} width="auto" caption={t("#_columns_13")} calculateCellValue={(s) => statusToString(s, t)} dataType='string' key='Status' />,
    ]
}
