import { addUser, editUser, copyToken, saveReport } from "./actions"


export const toolbarButtons = ({
    deleteUser, dataGridRef, selectedUser, showUserPrivileges, setIsUserNatural,
    setUser, setIsAdmin, setVisible, setRolesValue, setPopupTitle, setUserEdit, t
 }) => {
    return [
        {
            icon: 'plus', text: t("#_toolbarbuttons_18"), onClick: async () => {
                await addUser({
                    dataGridRef, setIsUserNatural, setUser, setIsAdmin, setVisible,
                    setRolesValue, setPopupTitle, setUserEdit, t
                })
            }
        },
        {
            icon: 'edit', text: t("#_toolbarbuttons_20"), onClick: async () => {
                await editUser({
                    dataGridRef, setIsUserNatural, setIsAdmin, setUser, setVisible,
                    setRolesValue, setPopupTitle, setUserEdit, t
                })
            }, disabled: selectedUser === null
        },
        {
            icon: 'trash', text: t("#_toolbarbuttons_22"), onClick: async () => {
                await deleteUser()
            }, disabled: selectedUser === null || selectedUser.Admin
        },
        { icon: 'copy', text: t("#_toolbarbuttons_24"), onClick: () => copyToken({ dataGridRef, t }), disabled: selectedUser === null },
        { icon: 'user', text: t("#_toolbarbuttons_26"), onClick: () => showUserPrivileges(selectedUser), disabled: selectedUser === null },
        { icon: 'inboxBlack', text: t("#_toolbarbuttons_30"), onClick: () => saveReport() }
    ]
}
