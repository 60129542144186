import { useState, useEffect } from "react"
import { XfXApi, invoicesEndpointUrl, ksefixEndpointUrl } from 'api/XfXApi';
import { Button } from 'devextreme-react/button';
import FileUploader from 'devextreme-react/file-uploader';
import { LoadIndicator } from 'devextreme-react/load-indicator';
import axios from 'axios'
import { useTranslation } from 'react-i18next';
import notify from 'devextreme/ui/notify';
import GenericPopup from "components/popup/GenericPopup";

const SignBatchPopup = ({ organizationId, organizationNip, isVisible, setIsVisible, reloadGrid, batchId, autosignAuthChallenge, setSelectedItem, setBlockAutoRefresh }) => {
    const { t } = useTranslation()
    const [loading, setLoading] = useState(false)
    const [step, setStep] = useState(0)
    const [signedFile, setSignedFile] = useState(null)
    const [buttonDisabled, setButtonDisabled] = useState(false)

    const signFile = async (file) => {
        var formData = new FormData()
        formData.append("file", file, file.name)
        const sf = await axios.post(`${ksefixEndpointUrl}/api/${XfXApi.GetTenantId()}/${organizationNip}/KsefAuthWithOrg/SignChallenge?identifierToGenerateCertificateFor=${organizationNip}&identifierType=NIP`, formData)
            .then(x => {
                var blob = new Blob([x.data], { 'type': x.headers["content-type"] })
                return new File([blob], "KSeF.xml")
            })
            .catch(x => {
                if (x.response.data.errors) {
                    x.response.data.errors.forEach(error => {
                        notify({
                            message: error,
                            position: {
                              my: 'center bottom',
                              at: 'center bottom',
                            },
                          }, 'error', 2000);
                    })
                } else {
                    notify({
                        message: x.message,
                        position: {
                          my: 'center bottom',
                          at: 'center bottom',
                        },
                      }, 'error', 2000);
                }
            })
            .finally(() => {
            })

        return sf
    }

    const step0_GetFile = async () => {
        setLoading(true)
        await XfXApi.BatchApi.apiTenantIdOrganizationIdBatchUnsigneddataGet(batchId, XfXApi.GetTenantId(), organizationNip)
            .then(x => {
                var a = document.createElement('a')
                var blob = new Blob([x.data], { 'type': x.headers["content-type"] })
                a.href = window.URL.createObjectURL(blob)
                a.download = "KSeF.xml"
                a.click()
                setStep(1)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const startProcess = async () => {
        setButtonDisabled(true)
        setLoading(true)
        await getUnsignedFile()
            .then(x => {
                signFile(x)
                    .then(y => {
                        step2_SendSigned(y)
                    })
            })
            .catch(x => {

            })
            .finally(() => {
                setLoading(false)
            })
    }

    const getUnsignedFile = async () => {
        const unsf = await XfXApi.BatchApi.apiTenantIdOrganizationIdBatchUnsigneddataGet(batchId, XfXApi.GetTenantId(), organizationNip)
            .then(x => {
                var blob = new Blob([x.data], { 'type': x.headers["content-type"] })
                return new File([blob], "KSeF.xml")
            })
            .finally(() => {
            })

        return unsf
    }

    const step1_LoadSignedFile = (file) => {
        setSignedFile(file)
        setStep(2);
    }

    const step2_SendSigned = async (file) => {
        var formData = new FormData()
        formData.append("file", file, file.name)
        formData.append("batchId", batchId)
        setLoading(true)
        axios.post(`${invoicesEndpointUrl}/api/${XfXApi.GetTenantId()}/${organizationId}/batch/sign`, formData, {
            headers: {
                'Content-Type': `multipart/form-data`
            }
        })
            .then(x => {
                setStep(1)
                popup.close()
            })
            .catch(x => {
                if (x?.response?.data?.Message || x?.response?.data?.XfxDetails)
                {
                    notify({
                        message: x?.response?.data?.Message ?? x?.response?.data?.XfxDetails?.join('\r\n'),
                        position: {
                          my: 'center bottom',
                          at: 'center bottom',
                        },
                      }, 'error', 2000);
                }
            })
            .finally(() => {
                setButtonDisabled(false)
                setLoading(false)
                setSelectedItem(null)
                reloadGrid()
            })
    }

    const closeDialog = () => {
        setStep(0)
        setSignedFile(null)
        popup.close()
    }

    const popup = GenericPopup({ 
        onHiding: () => closeDialog(),
        content:
        <div>
          {loading && <>
                <LoadIndicator></LoadIndicator>
            </>}

            {!loading && <>
                {step === 0 && <Button 
                                    onClick={async () => !autosignAuthChallenge ? await step0_GetFile() : await startProcess()}
                                    disabled={buttonDisabled}>
                                    {!autosignAuthChallenge ? t("#_signbatchpopup_2") : t("#_signbatchpopup_3")}
                                </Button>}
                {step > 0 &&
                    <>
                        <FileUploader
                            selectButtonText={t("#_signbatchpopup_4")}
                            labelText={t("#_signbatchpopup_5")}
                            multiple={false}
                            uploadMode="instantly"
                            invalidFileExtensionMessage={t("#_signbatchpopup_6")}
                            uploadedMessage={t("#_signbatchpopup_7")}
                            readyToUploadMessage={t("#_signbatchpopup_8")}
                            uploadFailedMessage={t("#_signbatchpopup_9")}
                            name={'file'}
                            uploadFile={file => step1_LoadSignedFile(file)}
                        />
                        {step === 2 &&
                            <Button
                                disabled={buttonDisabled}
                                isVisible={step === 2}
                                onClick={async () => {
                                    setButtonDisabled(true)
                                    await step2_SendSigned(signedFile)
                                }}>Zaloguj</Button>
                        }
                    </>
                }
            </>}
        </div>,
        title: t("#_signbatchpopup_1"),
        width: "500px",
        height: "300px",
        isVisible: isVisible,
        setIsVisible: setIsVisible,
        setBlockAutoRefresh: setBlockAutoRefresh
      })

    return (
        <>
            {popup.popup}
        </>
    )
}

export default SignBatchPopup
