import { Form, SimpleItem, Label } from 'devextreme-react/form';
import { useTranslation } from 'react-i18next';
import useFormErrors from 'utils/useFormErrors';
import { useEffect, useState, useRef } from "react"
import { XfXApi } from "api/XfXApi"
import CustomStore from 'devextreme/data/custom_store';
import notify from "devextreme/ui/notify"
import { commonPopupToolbarItems } from 'components/popup/PopupTools';
import GenericPopup from "components/popup/GenericPopup";
import OrganizationsDropDown from  'components/dropdown/OrganizationsDropDown.tsx'

const colCount = 1
const colSpan = 1

const NumberingSeriesPopup = ({ 
    setVisible, popupTitle, 
    numberingSeriesId, visible, reloadGrid,
    setBlockAutoRefresh }) => {
      
    const { getFirstError, noErrors, setErrors, clearError } = useFormErrors();
    const { t } = useTranslation()
    const [invKindDataSource, setInvKindDataSource] = useState([])
    const [allOrganizationsCustomStore, setAllOrganizationsCustomStore] = useState()
    const [resetIdPeriodDataSource, setResetIdPeriodDataSource] = useState([])
    const [numberingSeries, setNumberingSeries] = useState({
      name: "",
      invoiceKind: null,
      mask: "",
      resetIdPeriod: null,
      organizationIds: []
    })
    const formRef = useRef(null);


    const makeAsyncDataSource = (data) => {
      return new CustomStore({
        loadMode: 'raw',
        key: 'id',
        load() {
          return data
        },
      });
    }

    const fetchOrganizations = async () => {
      try {
          const result = await XfXApi.Organization.apiTenantIdOrganizationOrganizationsGet(XfXApi.GetTenantId())
          setAllOrganizationsCustomStore(makeAsyncDataSource(result.data))
      } catch (error) {
          console.error(error)
      }
  }

    useEffect(() => {
      const loadData = async () => {
        fetchOrganizations()
        if (invKindDataSource.length === 0)
        {
            XfXApi.DictionaryInvoice.apiTenantIdDictionaryInvoiceKindsGet(XfXApi.GetTenantId())
                .then(x => setInvKindDataSource(x.data));
        }
        if (resetIdPeriodDataSource.length === 0)
        {
          XfXApi.DictionaryInvoice.apiTenantIdDictionaryResetIdPeriodGet(XfXApi.GetTenantId())
                .then(x => setResetIdPeriodDataSource(x.data));
        }
        if (numberingSeriesId)
        {
          XfXApi.NumberingSeries.apiTenantIdNumberingSeriesSingleGet(XfXApi.GetTenantId(), numberingSeriesId).then(x => setNumberingSeries(x.data));
        }
      };
    
      loadData();
      popup.show();
    }, [])

    const save = async () => {
      await submit({ numberingSeries: numberingSeries })
    }
    
    const submit = async({ numberingSeries }) => {
      try {
        const response = numberingSeriesId ?
          await XfXApi.NumberingSeries.apiTenantIdNumberingSeriesIdPut(numberingSeriesId, XfXApi.GetTenantId(), numberingSeries) :
          await XfXApi.NumberingSeries.apiTenantIdNumberingSeriesPost(XfXApi.GetTenantId(), numberingSeries)
          console.error(response);
        if (response.status === 200) {
          const message = response.data.reverse().join('\n')
          notify({
            message: message,
            position: {
              my: 'center bottom',
              at: 'center bottom',
            },
          }, 'success', 1000 + 70 * message.length);
          popup.close()
        }
      } catch (error) {
        if (error.response.status === 400) {
          notify({
            message: error?.response?.data?.XfxDetails?.join(', '),
            position: {
              my: 'center bottom',
              at: 'center bottom',
            },
          }, 'error', 10000);
        }
        else if (error.response.status === 422) {
          setErrors(error.response.data);
        }
      }
      finally {
        reloadGrid()
      }
    }

    const formFieldDataChanged = (e) => {
        if (e.dataField) {
          clearError(e.dataField);
        }
    }

    const popupToolbarItems = commonPopupToolbarItems({ onSave: () => save(), onCancel: () => popup.close(), t: t })
    const popup = GenericPopup({ 
      onHiding: () => popup.close(),
      content:
      <div>
          <Form
            id="form"
            labelMode="static"
            showColonAfterLabel={false}
            labelLocation="left"
            minColWidth={300}
            colCount={colCount}
            onFieldDataChanged={formFieldDataChanged}
            formData={numberingSeries}
            ref={formRef}
          >
            <SimpleItem dataField="name" editorType="dxTextBox" value={numberingSeries.name} cssClass="xfx-required"
              editorOptions={{
                validationErrors: getFirstError("name"),
                isValid: noErrors("name")
              }}
            >
              <Label text={t("#_NumberingSeriesPopup_name")} />
            </SimpleItem>
            <SimpleItem dataField="mask" editorType="dxTextBox" value={numberingSeries.mask} cssClass="xfx-required"
              editorOptions={{
                validationErrors: getFirstError("mask"),
                isValid: noErrors("mask")
              }}
            >
              <Label text={t("#_NumberingSeriesPopup_format")} />
            </SimpleItem>
            <SimpleItem editorType="dxTextArea" 
              editorOptions={{
                readOnly: true,
                height: 320,
                text: t("#_NumberingSeriesPopup_formathelper")
              }}
            >
            </SimpleItem>
            <SimpleItem cssClass="xfx-required" dataField="invoiceKind" editorType="dxSelectBox" value={numberingSeries.invoiceKind}
              editorOptions={{
                dataSource: invKindDataSource,
                value: numberingSeries.invoiceKind,
                key: "key",
                valueExpr: "key",
                displayExpr: "value",
                validationErrors: getFirstError("invoiceKind"),
                isValid: noErrors("invoiceKind")
              }}>
                <Label text={t("#_NumberingSeriesPopup_invoicekind")} />
            </SimpleItem>
            <SimpleItem cssClass="xfx-required" dataField="resetIdPeriod" editorType="dxSelectBox" value={numberingSeries.resetIdPeriod}
              editorOptions={{
                dataSource: resetIdPeriodDataSource,
                value: numberingSeries.resetIdPeriod,
                key: "key",
                valueExpr: "key",
                displayExpr: "value",
                validationErrors: getFirstError("resetIdPeriod"),
                isValid: noErrors("resetIdPeriod")
              }}>
                <Label text={t("#_NumberingSeriesPopup_resetid")} />
            </SimpleItem>
            <SimpleItem>
                <Label text={t("#_NumberingSeriesPopup_organizations")} />
                <OrganizationsDropDown
                    value={numberingSeries.organizationIds}
                    t={t}
                    setValue={v => { setNumberingSeries({ ...numberingSeries, organizationIds: v });}}
                    allOrganizations={allOrganizationsCustomStore}
                    useFluentValidation={true}
                    validationErrors={getFirstError("organizationIds")}
                    isValid={noErrors("organizationIds")}
                    clearError={clearError}
                    clearErrorField={'organizationIds'}
                ></OrganizationsDropDown>
            </SimpleItem>
                </Form>
      </div>,
      toolbarItems: popupToolbarItems,
      title: popupTitle,
      width: "50%",
      height: "85%",
      isVisible: visible,
      setIsVisible: setVisible,
      setBlockAutoRefresh: setBlockAutoRefresh
    })

    return (
        <>
            {popup.popup}
        </>
    )
}

export default NumberingSeriesPopup
