import {  DateTimeFormat } from 'utils/cellRenderers'
import { renderDateCell } from 'utils/cellRenderers'
export const queryParameter = 'SessionId'
export const filterParameter = 'sessionId'

export const columns = ({ t }) => [
    { dataField: "Id", caption: t("#_const_4"), width: "auto", visible: true },
    { dataField: "OrganizationVatNumber", dataType: "string", caption: t("#_const_5"), width: "auto", visible: true },
    { dataField: "SessionKind", dataType: "string", caption: t("#_const_7"), width: "auto", visible: true },
    { dataField: "ActiveLocally", dataType: "boolean", caption: t("#_const_88"), width: "auto", visible: true },
    { dataField: "ActiveInKsef", dataType: "boolean", caption: t("#_const_8"), width: "auto", visible: true },
    { dataField: "DateCreatedUtc", dataType: "datetime", caption: t("#_const_10"), width: "auto", cellRender: x=>renderDateCell(x.data?.DateCreatedUtc), visible: true },
    { dataField: "SessionInitialisationTimeStampUtc", dataType: "datetime", caption: t("#_const_12"), cellRender: x=>renderDateCell(x.data?.SessionInitialisationTimeStampUtc), width: "auto", visible: true },
    { dataField: "ScheduledForSessionTermination", dataType: "boolean", caption: t("#_const_13"), width: "auto", visible: true },
    { dataField: "ScheduledForSessionTerminationTimeStampUtc", dataType: "datetime", caption: t("#_const_14"), cellRender: x=>renderDateCell(x.data?.ScheduledForSessionTerminationTimeStampUtc), width: "auto", visible: true },
    { dataField: "SessionTerminationInitiated", dataType: "boolean", caption: t("#_const_18"), width: "auto", visible: true },
    { dataField: "SessionTerminationInitiationTimestampUtc", dataType: "datetime", caption: t("#_const_20"), cellRender: x=>renderDateCell(x.data?.SessionTerminationInitiationTimestampUtc), width: "auto", visible: true },
    { dataField: "SessionTerminated", dataType: "boolean", caption: t("#_const_22"), width: "auto", visible: true },
    { dataField: "LastSessionStatusCheckupTimestampUtc", dataType: "datetime", caption: t("#_const_24"), cellRender: x=>renderDateCell(x.data?.LastSessionStatusCheckupTimestampUtc), width: "auto", visible: true },
    { dataField: "HasSessionException", dataType: "boolean", caption: t("#_const_25"), width: "auto", visible: true },
    { dataField: "HasUpo", dataType: "boolean", caption: t("#_const_27"), width: "auto", visible: true },
]
