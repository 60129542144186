import { useEffect, createContext, useContext } from "react"

const refreshEventName = "refreshButtonClicked"

const refreshContext = createContext({ buttonDisabled: false })

const useRefreshButton = (eventHandler) => {
    useEffect(() => {
        document.addEventListener(refreshEventName, eventHandler)
        return () => {
            document.removeEventListener(refreshEventName, eventHandler)
        }
    })
}

const riseRefreshEvent = () => {
    document.dispatchEvent(new CustomEvent(refreshEventName))
}

export { useRefreshButton, riseRefreshEvent, refreshContext }