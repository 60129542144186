import { XfXApi } from 'api/XfXApi';

export const getUpoForInvoice = async (organizationId, invoiceId, ksefNumber) => {
    const invId = invoiceId?._value ? invoiceId?._value : invoiceId

    XfXApi.InvoiceApi.apiTenantIdOrganizationIdInvoicesUpoGet(XfXApi.GetTenantId(), organizationId, invId, true)
        .then(response => {

            if (response.status !== 200) return
            const downloadUrl = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = downloadUrl;

            link.setAttribute('download', ksefNumber + "_upo.xml");
            document.body.appendChild(link);
            link.click();
            link.remove();
        })
}