import { useState, useRef, useEffect } from 'react';
import { ToolbarItem } from 'devextreme-react/popup';
import { Form, GroupItem, SimpleItem, Label } from 'devextreme-react/form';
import List from 'devextreme-react/list';
import { Button } from 'devextreme-react/button';
import { ScrollView } from 'devextreme-react/scroll-view';
import FileUploader from 'devextreme-react/file-uploader';
import GenericToolbar from '../../settings/Toolbars/GenericToolbar';
import CustomStore from 'devextreme/data/custom_store';
import { useTranslation } from 'react-i18next';
import { Template } from 'devextreme-react/core/template';
import AnsiCodesDropDown from './AnsiCodesDropDown';

const fileExtensionsCSV = ['.csv'];
const fileTypesCSV = ".csv"

const SalesProcessingFromTokensImporter = ({ postInvoicesFromTokensDto, setPostInvoicesFromTokensDto, selectedFilesItemKeys, 
    setSelectedFilesItemKeys, fileStore, setFileStore, ansiCodesDataSource }) => {
        
    const { t } = useTranslation()

    const fileUploaderRefCSV = useRef()
    const filesListRef = useRef()

    const [ansi, setAnsi] = useState({
        checkbox: false,
        importOnlyValid: true,
        ansiCode: []
    })

    const [metaData, setMetaData] = useState({
        vatDate: null,
        anonymize: false
    })

    const [tags, setTags] = useState({
        Tag1: null,
        Tag2: null,
        Tag3: null,
        Tag4: null,
        Tag5: null,
        Tag6: null,
        Tag7: null,
        Tag8: null,
        Tag9: null,
        Tag10: null
    })

    const [ansiCodesDropdownVisible, setAnsiCodesDropdownVisible] = useState(ansi.checkbox)

    useEffect(() => {
        const updatedFromTokens = { ...postInvoicesFromTokensDto, ansiCode: ansi.ansiCode?.length > 0 ? ansi.ansiCode[0] : null }
        setPostInvoicesFromTokensDto(updatedFromTokens)
    }, [ansi.ansiCode])

    const deleteFile = ({ id }) => {
        fileStore.remove(id)
    }

    const deleteFiles = () => {
        selectedFilesItemKeys.forEach(id => {
            deleteFile({ id: id })
        })
    }

    const FileInfo = ({ id, file }) => {
        return <div className='moduleListItem'>
            <div>Nazwa: {file.name}</div>
            <Button icon="trash" onClick={() => deleteFile({ id })} />
        </div>
    }

    const uploadInvoicesFromTokensFile = async (file) => {
        postInvoicesFromTokensDto.invoices = [...postInvoicesFromTokensDto.invoices, { id: crypto.randomUUID(), file: file }]
        setFileStore(makeAsyncDataSource(postInvoicesFromTokensDto.invoices))
    }

    const onFilesSelectedItemKeysChange = (args) => {
        if (args.name === 'selectedItemKeys')
            setSelectedFilesItemKeys(args.value !== args.previousValue ? args.value : [])
    }

    function makeAsyncDataSource(data) {
        return new CustomStore({
            loadMode: 'raw',
            key: 'id',
            cacheRawData: false,
            remove: (key) => {
                setSelectedFilesItemKeys(selectedFilesItemKeys.filter(x => x.id !== key))
                fileUploaderRefCSV?.current?.instance?.removeFile(data.filter(x => x.id === key)[0].file)

                data = data.filter(x => x.id !== key)
                setFileStore(makeAsyncDataSource(data.filter(x => x.id !== key)))

                postInvoicesFromTokensDto.invoices = postInvoicesFromTokensDto.invoices.filter(x => x.id !== key)
                setPostInvoicesFromTokensDto({ ...postInvoicesFromTokensDto, invoices: postInvoicesFromTokensDto.invoices.filter(x => x.id !== key) })
            },
            load() {
                return data
            },
        })
    }

    const dateBoxEditorOptions = {
        type: 'date', pickerType: 'calendar',
        readOnly: false, visible: true,
        showDropDownButton: true, dropDownButtonTemplate: 'renderEventIcon',
        invalidDateMessage: t("invalidDateMessage"),
        acceptCustomValue: false,
        openOnFieldClick: true,
        showClearButton: true
    }

    const renderEventIcon = () => {
        return (
            <>
                <div className='dx-icon dx-icon-event'></div>
            </>
        );
    }

    const toolbarButtons = [
        { icon: 'trash', text: t("#_salesprocessingimporter_5"), location: 'before', disabled: selectedFilesItemKeys.length === 0, onClick: () => deleteFiles() },
    ]

    return <>
        <ToolbarItem
            widget="dxButton"
            toolbar="bottom"
            location="before"
            options={{
                icon: 'save',
                text: t("#_salesprocessingimporter_9")
            }}
        />
        <ToolbarItem
            widget="dxButton"
            toolbar="bottom"
            location="after"
            options={{
                text: t("#_salesprocessingimporter_120"),

            }} />
        <ScrollView
            direction="vertical">
             <GenericToolbar className="xfx-toolbar-bigbuttons" buttons={toolbarButtons}></GenericToolbar>
                <Form formData={ansi} onFieldDataChanged={x => {
                    setAnsi(ansi)
                    if (x.dataField === 'checkbox')
                    {
                        const updatedFromTokens = { ...postInvoicesFromTokensDto, ansiCode: x.value ? 1250 : null }
                        setPostInvoicesFromTokensDto(updatedFromTokens)
                        setAnsi({ ...ansi, ansiCode: x.value ? [1250] : [] })
                        setAnsiCodesDropdownVisible(x.value)
                    }
                    else if (x.dataField === 'importOnlyValid')
                    {
                        const updatedFromTokens = { ...postInvoicesFromTokensDto, importOnlyValid: x.value }
                        setPostInvoicesFromTokensDto(updatedFromTokens)
                    }
                }}>
                    <GroupItem colCount={3} colSpan={1}>
                        <SimpleItem dataField="checkbox" cssClass="verticalAlignMiddle" editorType="dxCheckBox"
                            editorOptions={{
                                text: t("#_salesprocessingimporter_19")
                            }}>
                            <Label visible={false} />
                        </SimpleItem>
                        <SimpleItem dataField="importOnlyValid" cssClass="verticalAlignMiddle" editorType="dxCheckBox"
                            editorOptions={{
                                text: t("#_salesprocessingimporter_21")
                            }}>
                            <Label visible={false} />
                        </SimpleItem>
                    </GroupItem>
                        {ansiCodesDropdownVisible && <GroupItem colCount={5} colSpan={1}>
                            <div className="dx-field">
                                <div className="dx-field-value">
                                    <AnsiCodesDropDown
                                        value={ansi.ansiCode}
                                        setValue={v => 
                                            setAnsi(oldVal => ({ ...oldVal, ansiCode: v })
                                        )}
                                        ansiCodesDataSource={ansiCodesDataSource}
                                        t={t}
                                    >
                                    </AnsiCodesDropDown>
                                </div>
                            </div>
                    </GroupItem>}
                </Form>
                    <div className="file-uploader-block">
                        <FileUploader
                            disabled={postInvoicesFromTokensDto.invoices?.length > 0}
                            ref={fileUploaderRefCSV}
                            accept={fileTypesCSV}
                            allowCanceling={false}
                            selectButtonText={t("#_salesprocessingimporter_20")}
                            labelText={t("#_salesprocessingimporter_12")}
                            multiple={false}
                            showFileList={false}
                            uploadMode="instantly"
                            uploadFile={async (file) => await uploadInvoicesFromTokensFile(file)}
                            allowedFileExtensions={fileExtensionsCSV}
                            invalidFileExtensionMessage={t("#_salesprocessingimporter_14")}
                            uploadedMessage={t("#_salesprocessingimporter_15")}
                            readyToUploadMessage={t("#_salesprocessingimporter_16")}
                            uploadFailedMessage={t("#_salesprocessingimporter_17")}
                            name={'file'}
                        />
                    </div>
                    <List
                        ref={filesListRef}
                        dataSource={fileStore}
                        selectionMode='all'
                        selectAllMode='allPages'
                        showSelectionControls={true}
                        repaintChangesOnly={true}
                        visible={postInvoicesFromTokensDto.invoices?.length > 0}
                        itemRender={(x) => FileInfo({ id: x.id, file: x.file })}
                        selectedItemKeys={selectedFilesItemKeys}
                        onOptionChanged={onFilesSelectedItemKeysChange}>
                    </List>
            <Form formData={metaData} onFieldDataChanged={x => {
                setPostInvoicesFromTokensDto({ ...postInvoicesFromTokensDto, ...metaData })
                setMetaData(metaData)
            }}>
                <GroupItem colCount={5} colSpan={1}>
                    <SimpleItem dataField="vatDate" editorType="dxDateBox"
                        editorOptions={dateBoxEditorOptions}>
                        <Label text={t("column-vatdate")} />
                        <Template name='renderEventIcon' render={renderEventIcon} />
                    </SimpleItem>
                </GroupItem>
                <GroupItem colCount={5} colSpan={1}>
                    <SimpleItem dataField="anonymize" cssClass="verticalAlignMiddle" editorType="dxCheckBox"
                        editorOptions={{
                            text: t("#_salesprocessingimporter_18")
                        }}>
                        <Label visible={false} />
                    </SimpleItem>
                </GroupItem>
            </Form>
            <Form formData={tags} colCount={5} onFieldDataChanged={x => {
                setPostInvoicesFromTokensDto({ ...postInvoicesFromTokensDto, ...tags })
                setTags(tags)}
            }>
            </Form>
        </ScrollView>
    </>
}

export default SalesProcessingFromTokensImporter;
