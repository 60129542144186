import GetControl from './Controls/GetControl';
import Form, { GroupItem } from 'devextreme-react/form';
import { Form as FormModel, FormField, FieldType, FieldGroup, Section, EditorType, PossibleValue, IOrganization } from "./model"
import { groupByRow } from './modelHelpers';
import { findValue } from './FormFieldComponent'
import React from 'react'
import { OrganizationDto } from 'api/openapi';

interface GetGroupArgs {
  s_i: number;
  index: number;
  group: FieldGroup;
  updatedValues: Record<string, any>;
  beforeCorrectionValues: Record<string, any> | null;
  formErrors: Record<string, any>;
  customGroups: { [key: string]: any; };
  setValue: (k: string, address: string, v: any, printableValue: any) => void;
  editorType: EditorType;
  numberingSeriesDataSource: any;
  availableFkSystems: any,
  setValuesAndRecalculateFormulas: (values: Record<string, any>) => void,
  availableOrganizations: OrganizationDto[],
  t: any
}



export const GetGroup = ({ s_i, index, group, updatedValues, setValue, customGroups, formErrors, editorType, beforeCorrectionValues,
  numberingSeriesDataSource, availableFkSystems, setValuesAndRecalculateFormulas, availableOrganizations, t }: GetGroupArgs) => {
  const groupCssClass = "invoice-editor-section-" + s_i + "-" + index
  if (customGroups[group.Id] !== undefined) {
    return <GroupItem cssClass={groupCssClass} key={index}>
      {customGroups[group.Id]}
    </GroupItem>
  }

  const groupedFieldsByRow = groupByRow(group.Fields);


  return <GroupItem cssClass={"invoice-editor-section-" + s_i + "-" + index + " "} key={index}>
    {
      Object.entries(groupedFieldsByRow).map(([row, fieldsInRow]) => {
        return (
          <div className={"invoice-editor-row"}>
            {
              fieldsInRow.map((field, fieldIndex) => {

                let value = findValue(updatedValues, field.Identifier).value

                const handleValueChanged = (e: any, address: any) => {
                  var possibleValue = field.PossibleValues?.find(x => x.XmlValue === e.value)
                  const printableValue = possibleValue?.PrintableValue ?? e.value
                  setValue(e.key, address, e.value, printableValue);
                };

                const handleValueChangedForCheckbox = (fieldId: string, value: any, address: any) => {
                  if (value === undefined) return


                  let possibleValue: PossibleValue | undefined
                  if (field.Identifier !== fieldId) {
                    if (field.Type === FieldType.Sequence) {
                      var definitionFieldId = fieldId.replace(/\[\d+\]/, '[]');
                      const definitionField = field.DependentFields?.find(x => x.Identifier === definitionFieldId)
                      if (definitionField === undefined) {
                        console.error("definitionField === undefined")
                        return
                      }
                      possibleValue = definitionField.PossibleValues?.find(x => x.PrintableValue.toLowerCase() === (value === true ? "tak" : "nie"))
                    } else {
                      console.warn("field.Identifier !== fieldId")
                      return
                    }
                  } else {
                    possibleValue = field.PossibleValues?.find(x => x.PrintableValue.toLowerCase() ===
                      (value === true ? "tak" : "nie"))
                  }

                  const val = possibleValue?.XmlValue
                  const printableValue = possibleValue?.XmlValue
                  setValue(fieldId, address, val, printableValue);
                };

                const error = formErrors[field.Address]

                const { control } = GetControl({
                  field: field,
                  handleValueChanged: handleValueChanged,
                  handleValueChangedForCheckbox: handleValueChangedForCheckbox,
                  value: value,
                  error: error,
                  updatedValues: updatedValues,
                  editorType: editorType,
                  beforeCorrectionValues: beforeCorrectionValues,
                  numberingSeriesDataSource: numberingSeriesDataSource,
                  availableFkSystems: availableFkSystems,
                  setValuesAndRecalculateFormulas: setValuesAndRecalculateFormulas,
                  availableOrganizations,
                  t
                })


                return control
              })
            }
          </div>
        );
      })
    }
  </GroupItem>
}