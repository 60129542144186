import { useEffect, useState, FC } from "react"
import 'devextreme-react/autocomplete';
import BreadCrumbs from 'components/breadCrumbs/BreadCrumbs';
import { getNode } from 'components/breadCrumbs/getNode';
import { useTranslation } from 'react-i18next';
import GenericOdataGrid from 'pages/Invoices/GenericOdataGrid';
import { OdataEndpoints } from 'api/XfXApi';
import { utcToLocalWithSeconds } from 'utils/utcToLocal';
import GenericToolbar from 'pages/settings/Toolbars/GenericToolbar';
import GenericPopup from "components/popup/GenericPopup"
import { ToolbarItem } from 'devextreme-react/popup';
import { SecurityEventLog, SecurityEventLogDetails } from './Types'
import ChangeLog from './ChangeLog'

interface SecurityLogProps {
  organizationId: string;
}

interface ColumnData {
  dataField: string;
  dataType?: string;
  caption: string;
  width: string;
  minWidth?: number;
  visible: boolean;
  sortOrder?: string;
  allowFiltering?: false;
  cellRender?: (x: any) => any;
}

const hasDetails = (x: SecurityEventLogDetails) => x !== null && ((x.Changes !== null && x.Changes.length > 0) || (x.Parameters !== undefined && x.Parameters.length > 0))

const SecurityLog: FC<SecurityLogProps> = ({ organizationId }) => {
  const { t } = useTranslation();
  const [columns, setColumns] = useState<ColumnData[]>([]);
  const [selectedItem, setSelectedItem] = useState<SecurityEventLog | null>(null);
  const [dataSourceOptions, setDataSourceOptions] = useState()
  const [blockAutoRefresh, setBlockAutoRefresh] = useState(false)

  useEffect(() => {
    const data = ({ t }: { t: any }): ColumnData[] => [
      { dataField: "Id", caption: "Id", width: "auto", visible: false, sortOrder: 'desc' },
      { dataField: "TimestampUtc", dataType: "datetime", caption: t("security-event-date"), minWidth: 130, width: "auto", visible: true, cellRender: x => utcToLocalWithSeconds(x.value) },
      {
        dataField: "SecurityEvent", dataType: "string", caption: t("security-event-type"), width: "*", minWidth: 130, visible: true, allowFiltering: false,
        cellRender: x => t("security-event-type-" + x.value?.toLowerCase())
      },
      { dataField: "UserName", dataType: "string", caption: t("security-event-user"), width: "auto", minWidth: 130, visible: true },
      { dataField: "XfxSession", dataType: "string", caption: "Sesja", width: "auto", minWidth: 130, visible: true, allowFiltering: false },
      { dataField: "HttpStatus", dataType: "number", caption: t("security-event-status"), width: "auto", minWidth: 130, visible: true },
      {
        dataField: "Details", dataType: "string", caption: t("security-event-details"), width: "auto", minWidth: 130, visible: true, allowFiltering: false,
        cellRender: x => hasDetails(x.value) ? "Tak" : ""
      },
    ];
    setColumns(data({ t }));
  }, [t]);

  const popupToolbarItems = [
    <ToolbarItem
      cssClass="popup-toolbarItem-cancel"
      widget="dxButton"
      toolbar="bottom"
      location="before"
      options={{
        text: t("#_errorraport_2"),
        onClick: async () => {
          detailsPopup.close()
        }
      }}
    />
  ]

  const [isPopupVisible, setIsPopupVisible] = useState(false)

  const popupConfig = {
    toolbarItems: popupToolbarItems,
    title: t("security-event-details"),
    width: 860,
    height: 650,
    buttons: undefined,
    content: <ChangeLog details={selectedItem?.Details}></ChangeLog>,
    onHiding: () => detailsPopup.close(),
    showTitle: true,
    isVisible: isPopupVisible,
    setIsVisible: setIsPopupVisible,
    setBlockAutoRefresh: setBlockAutoRefresh
  }
  const detailsPopup = GenericPopup(popupConfig)

  const toolbarButtons = [
    {
      icon: 'info', text: t("security-event-details"),
      disabled: selectedItem === null || !selectedItem.Details || !hasDetails(selectedItem.Details), onClick: () => {
        detailsPopup.show()
      }
    }
  ]

  return (
    <>
      <BreadCrumbs node={getNode({ componentName: 'SecurityLog', t })} breadCrumbs={undefined} />
      <GenericToolbar className="xfx-toolbar-bigbuttons" buttons={toolbarButtons}
        header={undefined} customHeaderRenderer={undefined} toolbarId={undefined} />
      {detailsPopup.popup}
      <div id="dataGrid-documentTemplates" className="dataGrid">
        {GenericOdataGrid({
          data: columns,
          endpoint: OdataEndpoints.SecurityLog(),
          setSelectedItem: setSelectedItem,
          id: undefined, ref: undefined,
          setSelectedItems: undefined,
          defaultFilter: undefined,
          defaultSort: undefined,
          filterButtons: undefined,
          updateDataGridFilter: undefined,
          multiSelectionMode: undefined,
          setLastUsedQueryParms: undefined,
          setDefaultSort: undefined,
          gridKey: undefined,
          useFilterBuilder: false,
          dataSourceOptions: dataSourceOptions,
          setDataSourceOptions,
          masterDetail: undefined,
          odataCustomFilterFieldName: undefined,
          blockAutoRefresh: blockAutoRefresh,
          setBlockAutoRefresh: setBlockAutoRefresh
        })}
      </div>
    </>
  );
}

export default SecurityLog;