import React, { useState, useRef, useContext, useEffect, useReducer, useCallback } from 'react';
import './header.scss';
import GenericToolbar from '../../pages/settings/Toolbars/GenericToolbar';
import { useAuth } from '../../contexts/auth';
import { riseRefreshEvent } from 'utils/refresh'
import OrganizationFilter from 'utils/organizationFilter'
import { useUserSettingsContext } from '../../contexts/UserSettingsProvider';
import { XfXApi } from '../../api/XfXApi';
import useToast from '../../utils/useToast';
import DropDownButton from 'devextreme-react/drop-down-button';
import { useTranslation } from 'react-i18next';
import { Languages, getFirstAvailableLanguage, BasicLanguageSetup } from 'localization/i18n';
import './header.css';

const Header = ({ menuToggleEnabled, title, toggleMenu, menuIcon }) => {
  const [langs, setLangs] = useState(Languages[0])
  const { user, signOut, userId, userSettings, clearTenant, canChangeTenant } = useAuth()
  const { getAutoRefreshSettings, getLanguageSettings, updateUserSettingsInDb, postUserSettingsIntoDb } = useUserSettingsContext()
  const { toast, showToastError } = useToast()
  const { t } = useTranslation()

  const SetLocalization = async (code) => {
    BasicLanguageSetup(code)
    const updated = {
      ...userSettings.settings, Language: code
    }
    await updateUserSettingsInDb(userSettings?.id, { userId: userId, settings: updated })
  }

  const logOut = async () => {
    await signOut()
  }

  const autoRefreshSettings = [
    { id: 1, description: t('refresh-no'), enabled: false },//'Brak odświeżania'
    { id: 2, description: t('refresh-every30seconds'), enabled: true, reloadIntervalMs: 30000 },
    { id: 3, description: t('refresh-every1minute'), enabled: true, reloadIntervalMs: 60000 },
    { id: 4, description: t('refresh-every2.5minutes'), enabled: true, reloadIntervalMs: 150000 },
    { id: 5, description: t('refresh-every5minutes'), enabled: true, reloadIntervalMs: 300000 },
    { id: 6, description: t('refresh-every10minutes'), enabled: true, reloadIntervalMs: 600000 },
  ];

  const [autoRefreshSettingsAvalailable, setAutoRefreshSettingsAvalailable] = useState(autoRefreshSettings)

  const handleUserSettingsWithAutoRefreshAvailability = async () => {
    const langSettInDb = getLanguageSettings()
    const firstAvailableLang = getFirstAvailableLanguage()

    if (langSettInDb && langSettInDb !== firstAvailableLang)
      BasicLanguageSetup(langSettInDb)

    await XfXApi.UserSettings.apiTenantIdUserSettingsAutorefreshGet(XfXApi.GetTenantId())
      .then(async x => {
        setAutoRefreshSettingsAvalailable(x.data)

        const defaultAutoRefreshPick = x.data.filter(_ => _.id === 2)[0]
        const autoRefreshExists = userSettings?.settings?.AutoRefresh !== null && userSettings?.settings?.AutoRefresh !== undefined

        if (userSettings?.settings) {
          if (!autoRefreshExists) {
            const updated = {
              ...userSettings.settings, AutoRefresh: defaultAutoRefreshPick
            }
            updateUserSettingsInDb({ id: userSettings?.id, userSettingsDto: { userId: userId, settings: updated } })
          }
        }
        else
          postUserSettingsIntoDb({ userId: userId, settings: { AutoRefresh: defaultAutoRefreshPick, Language: getFirstAvailableLanguage() } })
      })
      .catch(e => {
        showToastError(`${t("#_headerGetUserSettingsFailed")}`)
      })
  }

  useEffect(() => {
    handleUserSettingsWithAutoRefreshAvailability()
  }, [])

  const downloadInstruction = () => {
    const now = new Date();
    const isoTimestampString = now.toISOString();
    const urlFriendlyTimestamp = encodeURIComponent(isoTimestampString);

    const baseUrl = window.XFX_AAD_REDIRECT_URI.endsWith("/") ? window.XFX_AAD_REDIRECT_URI.slice(0, -1) : window.XFX_AAD_REDIRECT_URI;
    const urlToPdf = encodeURI(baseUrl + '/Instrukcja Uzytkonika aplikacji KSeF Insight - XFX.pdf')
    
    window.open(urlToPdf + '?t=' + urlFriendlyTimestamp, '_blank');
  }

  const toolbarButtons = [
    { icon: menuIcon, location: 'before', locateInMenu: 'never', onClick: () => { } },
    {
      icon: 'refreshCustom', text: 'Odśwież', isDropdownButton: true,
      initialKey: getAutoRefreshSettings()?.Id,
      onItemClick: async (x) => {
        const updated = {
          ...userSettings.settings, AutoRefresh: x.itemData
        }
        await updateUserSettingsInDb(userSettings?.id, { userId: userId, settings: updated })
      }, onButtonClick: () => { riseRefreshEvent() },
      dropdownItems: autoRefreshSettings, id: 'mainRefresh', displayExpr: 'description', keyExpr: 'id'
    },
    { icon: 'bell', text: t("#_header_10"), onClick: () => { } },
    {
      isItem: true,
      item: <DropDownButton
        text={user}
        displayExpr="name"
        keyExpr="id"
        items={[
          { id: 0, name: t("header-switch-tenant"), icon: 'logOutBlack', onClick: clearTenant, visible: canChangeTenant },
          { id: 1, name: t("header-logout"), icon: 'logOutBlack', onClick: logOut },
          { id: 4, name: t("header-manual"), icon: 'info', onClick: downloadInstruction },
          { id: 2, name: 'PL', icon: 'plflag', onClick: async () => { await SetLocalization(langs.PL) } },
          { id: 3, name: 'EN', icon: 'enflag', onClick: async () => { await SetLocalization(langs.EN) } }
        ]}
        onItemClick={({ itemData }) => {
          itemData?.onClick()
        }}
      />
    }
  ]

  const toolbarHeaderRenderer = () => <OrganizationFilter />

  return (
    <header className={'header-component'}>
      <GenericToolbar buttons={toolbarButtons} showIcon={false} toolbarId='mainToolbar' className='toolbar' customHeaderRenderer={toolbarHeaderRenderer}
      />
    </header>
  );
}

export default Header;
