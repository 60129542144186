import { useEffect, useState, useRef} from "react"
import ScrollView from 'devextreme-react/scroll-view';
import { Form, GroupItem, SimpleItem, Label } from 'devextreme-react/form';
import RadioGroup from 'devextreme-react/radio-group'
import useFormErrors from 'utils/useFormErrors';
import DropDownBox from 'devextreme-react/drop-down-box'
import OrganizationsDropDown from  'components/dropdown/OrganizationsDropDown.tsx'
import { XfXApi } from 'api/XfXApi';
import notify from "devextreme/ui/notify"
import List from 'devextreme-react/list';
import GenericPopup from "components/popup/GenericPopup"
import TextBox from 'devextreme-react/text-box'
import DataGrid, {
    Paging,
    FilterRow,
    Scrolling,
    Selection
} from 'devextreme-react/data-grid';
import { useTranslation } from 'react-i18next';
import { commonPopupToolbarItems } from 'components/popup/PopupTools';

const readOnly = false
const showColonAfterLabel = false
const minColWidth = 300

const emptyNotification = ()=>{ return {
    id: null,
    name: "",
    notificationType: 0,
    notificationMode: 0,
    notificationPeriod: 0,
    attachPdf: false,
    attachXml: false,
    sendToContractor: false,

    users: [],
    organizations: [],
    additionalEmails: [],
  }
}
const NotificationEditor = ({
    setVisible,
    notificationId,
    userDataSource,
    userColums,
    notificationTypes,
    notificationTypesDataStore,
    notificationModesDataStore,
    notificationPeriodsDataStore,
    allOrganizationsCustomStore,
    onSaved,
    visible,
    setBlockAutoRefresh }) => {
    const { t } = useTranslation()

    const { getFirstError, noErrors, setErrors, clearError } = useFormErrors();

    const popupTitle = t("#_notificationconfiguration_26")

    const [notification, setNotification] = useState(emptyNotification())

    const [attachmentsVisible, setAttachmentsVisible] = useState(true)
    const [periodsVisible, setPeriodsVisible] = useState(true)
    const [modeSelectionVisible, setmodeSelectionVisible] = useState(true)    
    const [modeTextVisible, setModeTextVisible] = useState(true)
    const [usersVisible, setUsersVisible] = useState(true)
    const [contractorVisible, setContractorVisible] = useState(true)

    const [modeText, setModeText] = useState('')

    function usersRender() {
        return (
            <DataGrid
                dataSource={userDataSource}
                columns={userColums}
                hoverStateEnabled={true}
                selectedRowKeys={notification.users}
                onSelectionChanged={usersOnSelectionChanged}
                height="100%">
                <Selection
                    allowSelectAll={false}
                    deferred={false}
                    mode='multiple'
                    selectAllMode='allPages'
                    showCheckBoxesMode='always'
                />
                <Scrolling mode="virtual" preloadEnabled={true} />
                <Paging enabled={true} pageSize={100} />
                <FilterRow visible={true} />
            </DataGrid>
        );
    }

    function syncUsersSelection(e) {
        let selectedUsers = e.value || []
        setNotification(oldVal => ({...oldVal, users: selectedUsers}))
    }

    function usersOnSelectionChanged(e) {
        setNotification(oldVal => ({...oldVal, users: (e.selectedRowKeys.length && e.selectedRowKeys) || []}))
    }

    function typeOnSelectionChanged(e)
    {
        updateOptions(e.value, notification.notificationMode)
    }

    function updateOptions(type, mode)
    {        
        let selectedType = notificationTypes[type]
        setAttachmentsVisible(selectedType.canHaveAttachments)
        setPeriodsVisible(selectedType.canBePeriodic)
        setUsersVisible(selectedType.canConfigureUsers)     
        setContractorVisible(selectedType.canSelectContractor)

        let selectableMode = selectedType.canBePeriodic && selectedType.canBeOnEvent
        setmodeSelectionVisible(selectableMode)
        setModeTextVisible(!selectableMode)      

        if(selectableMode)
        {                
            setModeText('')
            modeOnSelectionChanged(mode)
        }
        else 
        {
            setPeriodsVisible(selectedType.canBePeriodic)
            setAttachmentsVisible(selectedType.canBeOnEvent && selectedType.canHaveAttachments)

            if(selectedType.canBeOnEvent)
            {
                setModeText(t('#_notificationconfiguration_11'))
                setNotification(oldVal => ({...oldVal, notificationMode: 1}))
            }
            else
            {
                setModeText(t('#_notificationconfiguration_12'))
                setNotification(oldVal => ({...oldVal, notificationMode: 0}))
            }
        }
    }

    function modeOnSelectionChanged(e)
    {
        let isEvent = e == 1

        if(isEvent)
        {
            setAttachmentsVisible(true)
            setPeriodsVisible(false)
            setNotification(oldVal => ({...oldVal,  notificationMode: e, notificationPeriod: null}))
        }
        else 
        {                
            setAttachmentsVisible(false)
            setPeriodsVisible(true)
            setNotification(oldVal => ({...oldVal, notificationMode: e,  attachXml: false, attachPdf: false}))
        }
    }
    
    const saveButtonOptions = {
        text: t("#_notificationconfiguration_28"),
        onClick: async() => {
          await submit({ onSaved })
        }
    };

    const submit = async({ onSaved }) => {
        try {
            setNotification(oldVal => ({...oldVal, id: notificationId}))

          const response = notificationId 
            ? await XfXApi.NotificationConfiguration.apiTenantIdNotificationConfigurationPut(XfXApi.GetTenantId(),notification)
            : await XfXApi.NotificationConfiguration.apiTenantIdNotificationConfigurationPost(XfXApi.GetTenantId(),notification)
          if (response.status === 200) {
            notify({
              message: response.data.messages[0],
              position: {
                my: 'center bottom',
                at: 'center bottom',
              },
            }, 'success', 5000);
            popup.close()
            onSaved()
          }
        } catch (error) {
          if (error.response.status === 400) {
            notify({
              message: error.response.data.messages[0],
              position: {
                my: 'center bottom',
                at: 'center bottom',
              },
            }, 'error', 10000);
          }
          else if (error.response.status === 422) {
            setErrors(error.response.data);
          }
        }
      }
      
    const cancelButtonOptions = {
      text: t("#_savecancelpopup_2"),
      onClick: () => { 
        popup.close()
      }
    };

    useEffect(() => {
        const loadData = async () => {
            if(notificationId)
            {
                await XfXApi.NotificationConfiguration.apiTenantIdNotificationConfigurationIdGet(notificationId,XfXApi.GetTenantId())
                                                      .then(e=>{
                                                        const state = e.data.stateObject
                                                        setNotification(old => state)
                                                        updateOptions(state.notificationType, state.notificationMode)
                                                        })
            }else
            {                
                setNotification(emptyNotification)
                typeOnSelectionChanged({value: notification.notificationType})
            }
        }

        loadData()
        popup.show()
    },[])

    const formFieldDataChanged = (e) => {
        if (e.dataField) {
          clearError(e.dataField);
        }
    }

    const AdditionalEmails = (value, setValue, validationErrors, isValid) => {
        const ref = useRef()
         return <>
        <TextBox
            ref={ref}
            onEnterKey={(x) => {
                if (!x.component._changedValue) return

                var newValue = x.component._changedValue.trim()
                if (newValue.length === 0) return
                
                var newMails = newValue.split(',')
                var filtered = newMails.filter(
                   m=>!value.includes(m));
                if (filtered.length > 0) {
                   setValue([...value, ...filtered])
                   ref.current.instance.reset()
                }}}
            validationErrors={validationErrors}
            isValid={isValid}/>
        <List
            dataSource={value}
            selectionMode="none"
            itemDeleteMode="static"
            allowItemDeleting={true}
            onItemDeleted={({ itemData }) => {
                setValue(value.filter(x => x !== itemData))
            }}/>
        </> 
    }

    const popupToolbarItems = commonPopupToolbarItems({ 
        t: t, 
        saveButtonOptions: saveButtonOptions,
        cancelButtonOptions: cancelButtonOptions
    })

    const popup = GenericPopup({ 
        onHiding: () => popup.close(),
        content:
        <div>
          <ScrollView>
            <Form
                id="form"
                labelMode="outside"
                readOnly={readOnly}
                showColonAfterLabel={showColonAfterLabel}
                minColWidth={minColWidth}
                className={"xfx-form"}
                formData={notification}                
                onFieldDataChanged={formFieldDataChanged}>                
                <GroupItem caption={t("#_notificationconfiguration_10")}>
                    <SimpleItem dataField="notificationType" 
                        editorType="dxSelectBox" 
                        value={notification.notificationType}
                        labelMode="floating"
                        editorOptions={{
                        key: "id",
                        dataSource: notificationTypesDataStore,
                        displayExpr: "name",
                        valueExpr: "id",
                        onValueChanged: typeOnSelectionChanged,
                        readOnly: false,
                        validationErrors: getFirstError("notificationType"),
                        isValid: noErrors("notificationType")
                        }}>
                        <Label text={t("#_notificationconfiguration_4")}/>
                    </SimpleItem>
                    <SimpleItem visible={modeTextVisible} itemType="group">
                        <Label text={modeText} />
                    </SimpleItem>
                </GroupItem>
                {modeSelectionVisible && 
                    <GroupItem caption={t("#_notificationconfiguration_31")}>
                    <SimpleItem >
                            <RadioGroup 
                                dataField="notificationMode"
                                value={notification.notificationMode}
                                onValueChange={modeOnSelectionChanged}
                                layout="vertical"
                                valueExpr="id"
                                displayExpr="name"
                                dataSource={notificationModesDataStore}                            
                                editorOptions={{
                                    validationErrors: getFirstError("notificationMode"),
                                    isValid: noErrors("notificationMode")
                                }}/>
                        </SimpleItem>
                    </GroupItem> 
                }
                {attachmentsVisible &&
                    <GroupItem caption={t("#_notificationconfiguration_16")}>
                        <SimpleItem dataField="attachPdf"
                                    editorType="dxCheckBox" 
                                    value={notification.attachPdf}
                                    editorOptions={{
                                        text: t("#_notificationconfiguration_17")
                                    }}>
                                    <Label visible={false}/>
                        </SimpleItem>
                        <SimpleItem dataField="attachXml" editorType="dxCheckBox" 
                                    value={notification.attachXml}
                                    editorOptions={{
                                        text: "XML"}}>
                                    <Label visible={false}/>
                        </SimpleItem>
                    </GroupItem>
                }
                {periodsVisible &&
                    <GroupItem caption={t("#_notificationconfiguration_13")}>
                        <SimpleItem dataField="notificationPeriod" 
                            editorType="dxSelectBox" 
                            value={notification.notificationPeriod}
                            editorOptions={{
                            key: "id",
                            dataSource: notificationPeriodsDataStore,
                            displayExpr: "name",
                            valueExpr: "id",
                            readOnly: false
                            }}>
                            <Label text={t("#_roleseditor_46")} mode="floating"/>
                        </SimpleItem>
                    </GroupItem>
                }
                <GroupItem caption={t("#_notificationconfiguration_18")}>
                        <SimpleItem key="nameBox" dataField="name" editorType="dxTextBox"  cssClass="xfx-required"
                                    value={notification.name}
                                    editorOptions={{
                                    validationErrors: getFirstError("name"),
                                    isValid: noErrors("name")
                                }}>
                                    <Label key="nameLabel" text={t("#_notificationconfiguration_1")}/>
                        </SimpleItem>
                </GroupItem>
                <GroupItem caption={t("#_notificationconfiguration_19")} >
                    <SimpleItem cssClass="xfx-required">
                        <OrganizationsDropDown
                            value={notification.organizations}
                            setValue={v => setNotification(oldVal => ({...oldVal, organizations: v }))}
                            allOrganizations={allOrganizationsCustomStore}
                            t={t}
                            useFluentValidation={true}
                            validationErrors={getFirstError("organizations")}
                            isValid={noErrors("organizations")}
                            clearError={clearError}
                            clearErrorField={'organizations'}
                        />
                    </SimpleItem>
                </GroupItem>
                {usersVisible &&
                    <GroupItem caption={t("#_notificationconfiguration_20")}>
                        <SimpleItem itemType="group" visible={notification.notificationType == 2 || notification.notificationType == 3}>
                            <Label text={t("#_notificationconfiguration_34")} />
                        </SimpleItem>
                        <SimpleItem>
                        <DropDownBox
                            dataField="users" 
                            value={notification.users}
                            valueExpr="id"
                            deferRendering={false}
                            displayExpr="login"
                            placeholder={t("#_roleseditor_46")}
                            showClearButton={true}
                            dataSource={userDataSource}
                            onValueChanged={syncUsersSelection}
                            contentRender={usersRender}
                            label={t("#_roleseditor_47")}
                            labelMode="hidden"/>
                            </SimpleItem>
                    </GroupItem>
                }
                {usersVisible &&
                    <GroupItem caption={t("#_notificationconfiguration_30")}>
                        <SimpleItem itemType="group">
                            <Label text={t("#_notificationconfiguration_33")} />
                        </SimpleItem>
                        <SimpleItem>
                            {AdditionalEmails(notification.additionalEmails,
                                            e=> {setNotification(oldVal => ({...oldVal, additionalEmails: e}))},
                                            getFirstError("additionalEmails"),
                                            noErrors("additionalEmails")
                                            )}
                        </SimpleItem>
                    </GroupItem>
                }
                {contractorVisible &&
                    <GroupItem caption={t("#_notificationconfiguration_32")}>
                        <SimpleItem dataField="sendToContractor" 
                                    editorType="dxCheckBox" 
                                    value={notification.sendToContractor}>
                            <Label visible={false}/>
                        </SimpleItem>
                    </GroupItem>
                }
            </Form>
            </ScrollView>
        </div>,
        toolbarItems: popupToolbarItems,
        title: popupTitle,
        width: "800px",
        height: "80%",
        isVisible: visible,
        setIsVisible: setVisible,
        setBlockAutoRefresh: setBlockAutoRefresh
      })

    return ( 
        <>
            {popup.popup}
        </>
     );
}

export default NotificationEditor