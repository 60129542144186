import React, { useState, useEffect, useRef } from 'react';
import GenericToolbar from '../Toolbars/GenericToolbar';
import { getNode } from 'components/breadCrumbs/getNode';
import BreadCrumbs  from 'components/breadCrumbs/BreadCrumbs';
import { columns } from './const';
import useDataGridFilter from 'utils/useDataGridFilter'
import { OdataEndpoints, XfXApi } from 'api/XfXApi';
import GenericOdataGrid from 'pages/Invoices/GenericOdataGrid';
import { useTranslation } from 'react-i18next';
import NumberingSeriesPopup from "./NumberingSeriesPopup";
import DeletionPopup from 'components/popup/DeletionPopup';

const NumberingSeries = () => {
    const { t } = useTranslation()
    const [data, setData] = useState([])
    const [selectedItem, setSelectedItem] = useState(null)
    const initialFilter = []
    const { dataGridFilter, updateDataGridFilter } = useDataGridFilter({ initialFilter })
    const defaultSort = { selector: "CreationDateUtc", desc: true };
    const [reloadKey, setReloadKey] = useState(0)
    const [dataSourceOptions, setDataSourceOptions] = useState()
    const dataGridRef = useRef(null);
    const [deletionPopupVisible, setDeletionPopupVisible] = useState(false)
    const [numberingSeriesId, setNumberingSeriesId] = useState(null)
    const [deletionPopupContent, setDeletionPopupContent] = useState("")
    const [resetIdPeriodDataSource, setResetIdPeriodDataSource] = useState([])
    const [invKindDataSource, setInvKindDataSource] = useState([])
    const [blockAutoRefresh, setBlockAutoRefresh] = useState(false)

    useEffect(() => {
      async function getDictionariesAndSetColumns() {
        const resetIdPeriod = await XfXApi.DictionaryInvoice.apiTenantIdDictionaryResetIdPeriodGet(XfXApi.GetTenantId())
        setResetIdPeriodDataSource(resetIdPeriod.data);
        const invoiceKinds = await XfXApi.DictionaryInvoice.apiTenantIdDictionaryInvoiceKindsGet(XfXApi.GetTenantId());
        setInvKindDataSource(invoiceKinds.data);

        const col = columns({
          t,
          resetIdPeriodDataSource: resetIdPeriod.data,
          invKindDataSource: invoiceKinds.data
        })
        setData(col);
      }
  
      if (resetIdPeriodDataSource?.length !== 0 && invKindDataSource?.length !== 0) {
          const col = columns({
            t,
            resetIdPeriodDataSource: resetIdPeriodDataSource,
            invKindDataSource: invKindDataSource
          })
          setData(col);
      }
      else {
        getDictionariesAndSetColumns()
      }
    }, [setData, t])

    const editPopupTitle = t("#_NumberingSeries_editPopupTitle")
    const addPopupTitle = t("#_NumberingSeries_addPopupTitle") 
    const [popupVisible, setPopupVisible] = useState(false)
    const [popupTitle, setPopupTitle] = useState(editPopupTitle)
    const formRef = useRef(null);
    const header = t("#_NumberingSeries_header")

      const deletionPopup = DeletionPopup({
        onConfirm: async () =>{
          await XfXApi.NumberingSeries.apiTenantIdNumberingSeriesDelete(selectedItem.Id.toString(), XfXApi.GetTenantId())
          reloadGrid()
          dataGridRef?.current?.instance?.clearSelection()
        },
        content: deletionPopupContent, 
        title: t("#_DeletionPopup_delete"),        
        isVisible: deletionPopupVisible, 
        setIsVisible: setDeletionPopupVisible, 
        t: t,
        setBlockAutoRefresh: setBlockAutoRefresh
      })

      const reloadGrid = () => {
        setReloadKey(reloadKey + 1)
      }


      const addSeries = () => {
        setNumberingSeriesId(null)
        setPopupTitle(addPopupTitle)
        setPopupVisible(true)
      }

      const editSeries = () => {
        if (selectedItem)
        {
          setNumberingSeriesId(selectedItem.Id._value)
          setPopupTitle(editPopupTitle)
          setPopupVisible(true)
        }
      }

      const deleteSeries = () => {
        if (selectedItem === null) return
        setDeletionPopupContent(t("#_DeletionPopup"));        
        deletionPopup.show();
      };

      const toolbarButtons = [
        { icon: 'plus', text: t("#_contractors_10"), onClick: addSeries },
        { icon: 'edit', text: t("#_contractors_120"), onClick: editSeries, disabled: selectedItem === null },
        { icon: 'trash', text: t("#_contractors_140"), onClick: deleteSeries, disabled: selectedItem === null }
      ]

      return (
        <>
          {deletionPopup.popup}
          {popupVisible && 
            <NumberingSeriesPopup
              setVisible={setPopupVisible}
              visible={popupVisible}
              popupTitle={popupTitle}
              formRef={formRef}
              numberingSeriesId={numberingSeriesId}
              reloadGrid={reloadGrid}
              setBlockAutoRefresh={setBlockAutoRefresh}/>
          }
          <BreadCrumbs node={ getNode({ componentName: 'NumberingSeries', t })} />
          <GenericToolbar className="xfx-toolbar-bigbuttons" header={header} buttons={toolbarButtons} />
          <div id="dataGrid-numberingSeries" className="dataGrid">
              {GenericOdataGrid({ 
                  ref: dataGridRef,
                  data: data, 
                  endpoint: OdataEndpoints.NumberingSeries(), 
                  setSelectedItem: setSelectedItem, 
                  defaultFilter: dataGridFilter,
                  defaultSort: defaultSort, 
                  updateDataGridFilter: updateDataGridFilter,
                  autoReload: true,
                  gridKey: reloadKey,
                  dataSourceOptions: dataSourceOptions,
                  odataCustomFilterFieldName: "Organizations",
                  setDataSourceOptions,
                  blockAutoRefresh: blockAutoRefresh,
                  setBlockAutoRefresh: setBlockAutoRefresh
              })}
          </div>
        </>
      );
}  

export default NumberingSeries;
