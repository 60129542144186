import React, { useState, RefObject } from 'react';
import RadioGroup from 'devextreme-react/radio-group';
import GenericPopup from "components/popup/GenericPopup";

export enum ExportType {
    CSV = 'Csv',
    XLSX = 'Xlsx'
}

interface IExportPopupProps {
    t: (key: string) => string;
    isVisible: boolean;
    setIsVisible: (visible: boolean) => void;
    exportAction: (selectedFormat: ExportType, t: (key: string) => string) => void;
    setBlockAutoRefresh: (blockAutoRefresh: boolean) => void;
}

const ExportPopup = ({
    t,
    isVisible,
    setIsVisible,
    exportAction,
    setBlockAutoRefresh
}: IExportPopupProps) => {
    const [selectedFormat, setSelectedFormat] = useState<ExportType>(ExportType.CSV);

    const { popup, close, show } = GenericPopup({
        onHiding: () => close(),
        buttons: [{
            text: t("job-close-window"),
            location: "after",
            action: () => close()
        }, {
            text: t("job-generate"),
            location: "after",
            action: () => {
                exportAction(selectedFormat, t);
                close();
            }
        }],
        title: t("job-dialog-title"),
        width: "550px",
        height: "290px",
        content: <div>
            <RadioGroup items={Object.values(ExportType).map(x => { return { value: x, text: t(x) } })}
                defaultValue={selectedFormat}
                valueExpr={"value"}
                displayExpr={"text"}
                onValueChange={
                    x => setSelectedFormat(x as ExportType)
                } />
        </div>,
        isVisible: isVisible,
        setIsVisible: setIsVisible,
        showTitle: true,
        toolbarItems: undefined,
        setBlockAutoRefresh: setBlockAutoRefresh
    });

    return {
        popup,
        show,
        close
    };
}

export default ExportPopup;