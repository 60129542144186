import { renderIsDispatchCell, renderModificationDateCell, renderCreationDateCell } from 'utils/cellRenderers'

const mapLookupDataSource = (t, dataSource, prefix) => {
    return dataSource?.map(({ key, value }) => ({ name: value, description: t(`${prefix}${value.toLowerCase()}`) }))
  }
  
  const lookupDataSource = (t, dataSource, prefix) => {
    return {
      store: {
        type: 'array',
        data: mapLookupDataSource(t, dataSource, prefix),
        key: 'name'
      },
    }
  }

export const columns = ({ t, invKindDataSource, templateFileTypesDataSource }) => 
[ 
    { dataField: "Name", dataType: "string", caption: t("#_document_templates_const_1"), width: "30%", visible: true },
    { dataField: "IsDispatch", dataType: "boolean", caption: t("column-isdispatch"), cellRender: x => renderIsDispatchCell(x, t),
        allowSorting: false, allowFiltering: false, width: "5%" },
    {
      dataField: "InvoiceKind", dataType: "string", caption: t("#_document_templates_const_2"), width: "5%", visible: true,
      lookupDataSource: invKindDataSource?.map(x => x.value),
      editorOptions: {
        dropDownOptions: {
          minWidth: '130px'
        }
      }
    },
    { dataField: "Organizations", dataType: "object", caption: t("#_document_templates_const_3"), width: "30%", 
        cellRender: x => orgatizationsRender(x), allowSorting: false, allowFiltering: true, visible: true },
    {
      dataField: "TemplateFileType", dataType: "string", caption: t("#_document_templates_const_6"), width: "5%", visible: true,
      lookupDataSource: templateFileTypesDataSource?.map(x => x.value),
      editorOptions: {
        dropDownOptions: {
          minWidth: '130px'
        }
      }
    },
    { dataField: "CreationDateUtc", dataType: "datetime", caption: t("#_document_templates_const_4"), width: "15%", cellRender: renderCreationDateCell, visible: true },
    { dataField: "ModificationDateUtc", dataType: "datetime", caption: t("#_document_templates_const_5"), width: "15%", cellRender: renderModificationDateCell, visible: true },
    { dataField: "OrganizationIds", visible: false },
]

const orgatizationsRender = (orgList) => {
    return (
    <ul>
        {orgList?.value?.map(e => <li>{e}</li>)}
    </ul>)
}