const BreadCrumbs = ({ node, breadCrumbs }) => {

  if (!breadCrumbs) {
    breadCrumbs = []

    if (node) {
      while (node !== null) {
        breadCrumbs.push(node.text)
        node = node.parent
      }
      breadCrumbs.reverse()
    }
  }

  let key = 0;

  if (breadCrumbs.length === 1)
    return <span className='xfx-singlebreadcrumb' key={"bread-crumb" + key}>{breadCrumbs[0]}</span>

  return breadCrumbs.map(x => {
    const className = breadCrumbs.length - 1 === key ? 'xfx-breadcrumb' : 'xfx-breadcrumb xfx-breadcrumb-arrow'
    return <span className={className} key={"bread-crumb-" + key++}>{x}</span>
  })
}

export default BreadCrumbs