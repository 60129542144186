import './polyfills';
import React from 'react';
import './index.css';
import App from './App';
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { msalConfig } from "./authConfig";
import reportWebVitals from './reportWebVitals';
import { createRoot } from 'react-dom/client';
import './localization/i18n'

const msalInstance = new PublicClientApplication(msalConfig);

const container = document.getElementById('root')
const root =  createRoot(container)

root.render(
//Addnotation -> React.StrictMode causes double render which for example post userSettings on initial render twice if u want to see PROD behavior remove strictmode
<React.StrictMode>
  <MsalProvider instance={msalInstance}>
    <App />
  </MsalProvider>
</React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
