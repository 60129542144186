import { useEffect, useState } from "react"
import 'devextreme-react/autocomplete';
import { XfXApi } from 'api/XfXApi';
import DataGrid, { Column, GroupPanel, Paging, Selection, HeaderFilter, Search } from 'devextreme-react/data-grid';
import notify from 'devextreme/ui/notify';
import GenericToolbar from "./Toolbars/GenericToolbar";
import { useRefreshButton } from "utils/refresh";
import BreadCrumbs from 'components/breadCrumbs/BreadCrumbs';
import { getNode } from 'components/breadCrumbs/getNode';
import { useTranslation } from 'react-i18next';
import GenericPopup from "components/popup/GenericPopup";
import DeletionPopup from 'components/popup/DeletionPopup';

const LicenseData = () => {
  const { t } = useTranslation()
  const [licensePeriods, setLicensePeriods] = useState(null)
  const [mergedLicense, setMergedLicense] = useState(null)
  const [selectedItem, setSelectedItem] = useState(null)
  const [popupVisible, setPopupVisible] = useState(false)

  const [deletionPopupVisible, setDeletionPopupVisible] = useState(false)
  const [deletionPopupContent, setDeletionPopupContent] = useState("")

  const [blockAutoRefresh, setBlockAutoRefresh] = useState(false)

  const deletionPopup = DeletionPopup({
    onConfirm: async () => {
      await XfXApi.License.apiTenantIdLicenseDelete(selectedItem.licenseId, XfXApi.GetTenantId())
      fetchLicenseData()
    },
    content: deletionPopupContent,
    title: t("#_DeletionPopup_delete"),
    isVisible: deletionPopupVisible,
    setIsVisible: setDeletionPopupVisible,
    t: t
  })

  const deleteLicence = () => {
    setDeletionPopupContent(t("#_DeletionPopup"));
    deletionPopup.show();
  }

  const fetchLicenseData = async () => {
    await XfXApi.License.apiTenantIdLicenseGet(XfXApi.GetTenantId()).then(result => {
      setLicensePeriods(result.data.stateObject)
    })
    await XfXApi.License.apiTenantIdLicenseMergedGet(XfXApi.GetTenantId()).then(result => {
      setMergedLicense(result.data.stateObject)
    })
  }

  useRefreshButton(() => fetchLicenseData())

  useEffect(() => {
    fetchLicenseData()
  }, [t])


  async function pick() {
    var filepicker = document.createElement("input");
    filepicker.setAttribute("type", "file");
    filepicker.click();
    return new Promise((resolve, reject) => {
      filepicker.addEventListener("change", e => {
        var reader = new FileReader();
        reader.addEventListener('load', file => resolve(file.target.result));
        reader.readAsDataURL(e.target.files[0]);
      });
    });
  }

  const showError = (msg) => {
    notify({
      message: msg,
      position: {
        my: 'center bottom',
        at: 'center bottom',
      },
    }, 'error', 10000);
  }

  const HandleApiCall = (call) => {
    call.then(x => {
      fetchLicenseData()
    })
      .catch(x => {
        if (x.response !== undefined && x.response.data !== undefined) {
          x.response.data.errors.forEach(error => {
            showError(error)
          })
        }
      })
  }

  const addLicense = async () => {
    var file = await pick();
    if (file === null) console.log("no file")
    HandleApiCall(XfXApi.License.apiTenantIdLicensePost(XfXApi.GetTenantId(), file))
  }

  const getLicenseInfo = (license) => {
    let key = 0
    return <ul>
      {license.features.map(item => {
        return <li key={key++}>{item}</li>;
      })}
    </ul>
  }

  const toolbarButtons = [
    { icon: 'add', text: t("button-add"), onClick: addLicense },
    { icon: 'info', text: t("button-info"), disabled: selectedItem === null, onClick: () => { popup.show() } },
    { icon: 'trash', text: t("button-delete"), disabled: selectedItem === null, onClick: deleteLicence }
  ]

  const popup = GenericPopup({
    onHiding: () => popup.close(),
    content:
      <div>
        <>
          {selectedItem !== null && getLicenseInfo(selectedItem)}
        </>
      </div>,
    title: t("licenses-info"),
    width: "700px",
    height: "500px",
    isVisible: popupVisible,
    setIsVisible: setPopupVisible,
    setBlockAutoRefresh: setBlockAutoRefresh
  })

  return (
    <>
      {popup.popup}
      {deletionPopup.popup}
      <BreadCrumbs node={getNode({ componentName: 'LicenseData', t })}></BreadCrumbs>
      <div id="dataGrid-license">
        <GenericToolbar className="xfx-toolbar-bigbuttons" header={t("licenses")} buttons={toolbarButtons}></GenericToolbar>
        <div className="dataGrid">
          <DataGrid
            dataSource={licensePeriods}
            keyExpr="licenseId"
            allowColumnReordering={true}
            showBorders={true}
            alignItems={"center"}
            rowAlternationEnabled={true}
            showColumnLines={true}
            onSelectionChanged={s => {
              setSelectedItem(s.selectedRowsData.length > 0 ? s.selectedRowsData[0] : null)
            }}
          >
            <GroupPanel visible={false} />

            <HeaderFilter>
              <Search
                enabled={true}
              >
              </Search>
            </HeaderFilter>

            <Paging defaultPageSize={10} />
            <Selection mode="single" />

            <Column dataField="creationDate" dataType="date" caption={t("licenses-column-creation-date")} />
            <Column dataField="licenseId" dataType="string" caption={t("licenses-column-identifier")} />
          </DataGrid>
        </div>

        {mergedLicense && getLicenseInfo(mergedLicense)}

      </div>
    </>
  );
}

export default LicenseData