import React from 'react';
import ScrollView from 'devextreme-react/scroll-view';
import './single-card.scss';

export default ({ title, description, children }) => (
  <ScrollView height={'100%'} width={'100%'} className={'with-footer single-card'}>
    <div className={'login-container'}>
      <div className={'top-left-shape'}>
        <svg width="88" height="89" viewBox="0 0 88 89" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M51.7895 36.2091H22.5V0.5H87.5V66.5H52.2895V36.7091V36.2091H51.7895Z" stroke="#417B94"/>
            <path d="M0.5 45.5H43.5V88.5H0.5V45.5Z" stroke="#417B94"/>
        </svg>
      </div>
      <div className={'top-right-shape'}>
        <svg width="228" height="229" viewBox="0 0 228 229" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M160.077 66.9271H68.5V-45.5H271.5V160.522H160.577V67.4271V66.9271H160.077Z" stroke="#417B94"/>
            <path d="M0.5 93.5449H135.5V228.5H0.5V93.5449Z" stroke="#417B94"/>
        </svg>
      </div>
      <div className={'top-right-shape-bottom-part'}>
          <svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M0.5 0.5H43.5V43.5H0.5V0.5Z" stroke="#417B94"/>
          </svg>
      </div>
      <div className={'top-right-shape-top-part'}>
          <svg width="66" height="67" viewBox="0 0 66 67" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M29.7895 36.2091H0.5V0.5H65.5V66.5H30.2895V36.7091V36.2091H29.7895Z" stroke="#417B94"/>
          </svg>
      </div>
      <div className={'bottom-left-shape'}>
        <svg width="228" height="232" viewBox="0 0 228 232" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M116.077 112.927H24.5V0.5H227.5V206.522H116.577V113.427V112.927H116.077Z" stroke="#417B94"/>
          <path d="M-43.5 139.545H91.5V274.5H-43.5V139.545Z" stroke="#417B94"/>
        </svg>
      </div>
      <div className={'bottom-right-shape'}>
        <svg width="272" height="275" viewBox="0 0 272 275" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M160.077 112.927H68.5V0.5H271.5V206.522H160.577V113.427V112.927H160.077Z" stroke="#417B94"/>
            <path d="M0.5 139.545H135.5V274.5H0.5V139.545Z" stroke="#417B94"/>
        </svg>
      </div>
      <div className={'login-wrapper'}>
        <svg width="567" height="65" viewBox="0 0 567 65" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_1679_685)">
            <path d="M244.17 2.31006H236.07V62.7201H244.17V2.31006Z" fill="white"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M312.78 53.28L260.3 0L260.23 11.49L268.18 19.67V19.64L312.83 64.88L312.78 53.28Z" fill="white"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M260.23 22.3101L268.34 30.5001V62.7101H260.23V22.3101Z" fill="white"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M304.76 2.31006H312.78V43.2501L304.76 34.9801V2.31006Z" fill="white"/>
            <path d="M192.6 12.3797V3.40967H166.11H164.84H159.93V62.6397H169.75V35.8997H191.75V27.1797H169.75V12.3797H192.6Z" fill="white"/>
            <path d="M90.6701 34.3798C89.2301 32.9698 87.6201 31.7898 85.8501 30.8298C84.0801 29.8698 82.2801 29.0798 80.4801 28.4598C77.3201 27.3298 74.7501 26.2298 72.7801 25.1598C70.8101 24.0898 69.3501 22.9498 68.4201 21.7298C67.4901 20.5198 67.0201 19.1498 67.0201 17.6298C67.0201 15.9398 67.6701 14.4598 68.9701 13.1898C70.2701 11.9198 72.3001 11.2898 75.0601 11.2898C77.0301 11.2898 78.7701 11.6698 80.2601 12.4298C81.7501 13.1898 83.0701 14.2098 84.1901 15.4798C85.3201 16.7498 86.2801 18.1198 87.0701 19.5798L95.1101 15.0098C94.1501 12.9198 92.7701 10.9198 90.9601 8.99982C89.1501 7.07982 86.9501 5.48982 84.3601 4.21982C81.7601 2.94982 78.7201 2.31982 75.2201 2.31982C71.7201 2.31982 68.4901 2.99982 65.7001 4.34982C62.9101 5.69982 60.6901 7.57982 59.0601 9.97982C57.4201 12.3798 56.6101 15.1598 56.6101 18.3198C56.6101 21.0798 57.1501 23.4398 58.2201 25.3898C59.2901 27.3398 60.6501 28.9698 62.2801 30.2998C63.9201 31.6298 65.6401 32.7298 67.4401 33.5998C69.2501 34.4698 70.8801 35.1698 72.3501 35.6698C74.8301 36.5698 77.0301 37.4898 78.9501 38.4198C80.8701 39.3498 82.3501 40.4798 83.3901 41.7998C84.4301 43.1298 84.9601 44.8598 84.9601 46.9998C84.9601 49.3698 84.1101 51.2898 82.4201 52.7498C80.7301 54.2198 78.5301 54.9498 75.8201 54.9498C73.6201 54.9498 71.6001 54.4698 69.7701 53.5098C67.9401 52.5498 66.2701 51.2298 64.7801 49.5298C63.2901 47.8398 61.8901 45.8298 60.5901 43.5198L53.1401 48.7698C54.4401 51.4798 56.2001 53.9898 58.4301 56.2998C60.6601 58.6098 63.2501 60.4598 66.2201 61.8398C69.1801 63.2198 72.3801 63.9098 75.8201 63.9098C78.3601 63.9098 80.8001 63.4998 83.1401 62.6798C85.4801 61.8598 87.5801 60.7098 89.4401 59.2098C91.3001 57.7198 92.7801 55.8698 93.8801 53.6698C94.9801 51.4698 95.5301 48.9898 95.5301 46.2198C95.5301 43.6198 95.0801 41.3498 94.1801 39.4098C93.2801 37.4598 92.1101 35.7898 90.6701 34.3698V34.3798Z" fill="white"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M19.29 31.1597L44.34 3.40967H32.24L10.24 28.0897V3.40967H0V62.6397H10.24V34.9797L33.26 62.6397H45.61L19.29 31.1597Z" fill="white"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M143.77 32.0496C142.25 29.0896 140.03 26.8196 137.13 25.2396C134.22 23.6596 130.71 22.8696 126.59 22.8696C122.47 22.8696 118.89 23.6996 115.84 25.3696C112.79 27.0296 110.44 29.3896 108.77 32.4396C107.11 35.4896 106.27 39.0696 106.27 43.1896C106.27 47.3096 107.09 50.8096 108.72 53.8496C110.36 56.8996 112.68 59.2696 115.7 60.9596C118.72 62.6496 122.2 63.4996 126.15 63.4996C130.66 63.4996 134.53 62.6096 137.74 60.8296C140.96 59.0496 143.61 56.4196 145.69 52.9196L137.74 49.7896C136.56 51.8796 135.03 53.4296 133.17 54.4396C131.31 55.4596 129.11 55.9596 126.57 55.9596C124.2 55.9596 122.2 55.4496 120.56 54.4396C118.92 53.4196 117.67 51.9296 116.79 49.9596C116.19 48.5996 115.82 47.0396 115.66 45.3096H145.94C145.94 45.0796 145.95 44.7196 145.98 44.2096C146.01 43.6996 146.02 43.2196 146.02 42.7696C146.02 38.5996 145.26 35.0296 143.74 32.0696L143.77 32.0496ZM116.9 35.7296C117.77 33.8096 119.02 32.3496 120.62 31.3296C122.23 30.3096 124.19 29.8096 126.5 29.8096C128.42 29.8096 130.1 30.2296 131.53 31.0796C132.97 31.9296 134.1 33.1096 134.92 34.6296C135.59 35.8796 135.97 37.3196 136.09 38.9496H115.91C116.13 37.7696 116.46 36.6896 116.89 35.7296H116.9Z" fill="white"/>
            <path d="M388.54 3.41016H378.39V62.6402H388.54V3.41016Z" fill="white"/>
            <path d="M363.37 34.3798C361.93 32.9698 360.32 31.7898 358.55 30.8298C356.77 29.8698 354.98 29.0798 353.18 28.4598C350.02 27.3298 347.45 26.2298 345.48 25.1598C343.51 24.0898 342.05 22.9498 341.12 21.7298C340.19 20.5198 339.72 19.1498 339.72 17.6298C339.72 15.9398 340.37 14.4598 341.67 13.1898C342.97 11.9198 345 11.2898 347.76 11.2898C349.73 11.2898 351.47 11.6698 352.96 12.4298C354.46 13.1898 355.77 14.2098 356.9 15.4798C358.03 16.7498 358.99 18.1198 359.78 19.5798L367.82 15.0098C366.86 12.9198 365.48 10.9198 363.67 8.99982C361.86 7.07982 359.66 5.48982 357.07 4.21982C354.47 2.94982 351.43 2.31982 347.93 2.31982C344.43 2.31982 341.2 2.99982 338.41 4.34982C335.62 5.69982 333.4 7.57982 331.77 9.97982C330.13 12.3798 329.32 15.1598 329.32 18.3198C329.32 21.0798 329.86 23.4398 330.93 25.3898C332 27.3398 333.36 28.9698 334.99 30.2998C336.63 31.6298 338.35 32.7298 340.15 33.5998C341.96 34.4698 343.59 35.1698 345.06 35.6698C347.54 36.5698 349.74 37.4898 351.66 38.4198C353.58 39.3498 355.06 40.4798 356.1 41.7998C357.14 43.1298 357.67 44.8598 357.67 46.9998C357.67 49.3698 356.82 51.2898 355.13 52.7498C353.44 54.2198 351.24 54.9498 348.53 54.9498C346.33 54.9498 344.31 54.4698 342.48 53.5098C340.65 52.5498 338.98 51.2298 337.49 49.5298C336 47.8398 334.6 45.8298 333.3 43.5198L325.85 48.7698C327.15 51.4798 328.91 53.9898 331.14 56.2998C333.37 58.6098 335.96 60.4598 338.93 61.8398C341.89 63.2198 345.09 63.9098 348.53 63.9098C351.07 63.9098 353.51 63.4998 355.85 62.6798C358.19 61.8598 360.29 60.7098 362.15 59.2098C364.01 57.7198 365.49 55.8698 366.59 53.6698C367.69 51.4698 368.24 48.9898 368.24 46.2198C368.24 43.6198 367.79 41.3498 366.89 39.4098C365.99 37.4598 364.82 35.7898 363.38 34.3698L363.37 34.3798Z" fill="white"/>
            <path d="M429.84 31.67V40.13H448.03C447.52 42.1 446.76 43.97 445.75 45.71C444.73 47.46 443.47 49.01 441.94 50.36C440.42 51.71 438.61 52.76 436.52 53.49C434.43 54.22 432.06 54.59 429.41 54.59C425.52 54.59 422.02 53.69 418.92 51.88C415.82 50.07 413.36 47.55 411.56 44.31C409.75 41.07 408.85 37.3 408.85 33.01C408.85 28.72 409.77 24.9 411.6 21.71C413.43 18.52 415.92 16.04 419.05 14.26C422.18 12.48 425.64 11.59 429.42 11.59C433.54 11.59 437.05 12.44 439.96 14.13C442.87 15.82 445.14 18.08 446.77 20.9L454.89 15.48C453.14 12.72 451.03 10.36 448.54 8.41002C446.06 6.46002 443.22 4.95002 440.04 3.88002C436.85 2.81002 433.31 2.27002 429.42 2.27002C424.96 2.27002 420.85 3.02002 417.07 4.51002C413.29 6.00002 409.99 8.12002 407.17 10.86C404.35 13.6 402.16 16.84 400.61 20.59C399.06 24.34 398.28 28.47 398.28 32.99C398.28 37.51 399.04 41.65 400.56 45.43C402.08 49.21 404.24 52.48 407.03 55.25C409.82 58.01 413.11 60.14 416.89 61.64C420.67 63.13 424.79 63.88 429.24 63.88C433.98 63.88 438.18 63.02 441.85 61.3C445.52 59.58 448.59 57.22 451.07 54.23C453.55 51.24 455.41 47.81 456.65 43.95C457.89 40.09 458.46 35.98 458.34 31.64H429.82L429.84 31.67Z" fill="white"/>
            <path d="M525.63 3.41016V12.6302H541.29V62.6402H551.27V12.6302H566.93V3.41016H525.63Z" fill="white"/>
            <path d="M508.37 3.41016V26.2502H478.58V3.41016H468.6V62.6402H478.58V35.4802H508.37V62.6402H518.36V3.41016H508.37Z" fill="white"/>
            </g>
            <defs>
            <clipPath id="clip0_1679_685">
            <rect width="566.93" height="64.88" fill="white"/>
            </clipPath>
            </defs>
        </svg>
        {children}
      </div>
    </div>
  </ScrollView>
);
