import { findValue } from "../FormFieldComponent";
import { FieldType, FormField } from "../model";
import { Parser } from 'hot-formula-parser'
import { Form as FormModel, } from "../model"

interface CalculateFormulaArgs {
  fieldsVisiblityMap: { [key: string]: { isVisible: boolean, field: FormField }; };
  invoiceValues: { [key: string]: any; };
  formulaToParse: string;
}

interface CalculateFormulaReturn {
  result: any;

}

export const CalculateFormula = ({ formulaToParse }: CalculateFormulaArgs): CalculateFormulaReturn | undefined => {
  const cells: { [key: string]: any; } = {}


  const parser = new Parser()
  parser.on('callCellValue', (cellCoord, done) => {
    // const ret = cells[cellCoord.label]
    // if (ret === undefined) {
    //   //console.log("required " + ret + " for " + cellCoord.label)
    // }
    const ret = "test"
    done(ret)
  });

  const { result, error } = parser.parse(formulaToParse);




  return { result }
}

export const FindFieldByAddress = (model: FormModel, address: string): FormField | null => {
  for (const section of model.Sections) {
    for (const group of section.Groups) {
      for (const field of group.Fields) {
        if (field.Address === address) {
          return field
        }
      }
    }
  }
  return null
}

export const FindFieldById = (model: FormModel, id: string): FormField | null => {
  for (const section of model.Sections) {
    for (const group of section.Groups) {
      for (const field of group.Fields) {
        if (field.Identifier === id) {
          return field
        }
      }
    }
  }
  return null
}

export const FindFieldsByPredicate = (model: FormModel, predicate: (field: FormField) => boolean): FormField[] => {
  const fields: FormField[] = []
  for (const section of model.Sections) {
    for (const group of section.Groups) {
      for (const field of group.Fields) {
        if (predicate(field)) {
          fields.push(field)
        }
      }
    }
  }
  return fields
}

function sumValuesMatchingPattern(pattern: RegExp, values: Record<string, any>): number {
  let sum = 0;
  for (const key in values) {
    if (pattern.test(key)) {
      const value = values[key];
      if (typeof value === 'number') {
        sum += value;
      }
    }
  }
  return sum;
}

const extractCellAddress = (input: string): string | null => {
  const regexExtended = /^=([A-Z]+\d+)$/i;
  const match = input.match(regexExtended);

  if (match) {
    return match[1];
  } else {
    return null;
  }
}

const CalculateSingleAddressFormula = (values: Record<string, any>, model: FormModel) => {
  const formulaFields = model.Sections.flatMap(x => x.Groups.flatMap(y => y.Fields)).filter(x => x.Formula !== null && x.Formula !== undefined)
  formulaFields.forEach(field => {
    const formula = field.Formula
    if (formula !== null) {
      var singleCellAddress = extractCellAddress(formula)
      if (singleCellAddress !== null) {
        const sourceField = FindFieldByAddress(model, singleCellAddress)
        if (sourceField !== null) {
          const val = values[sourceField.Identifier]
          const option = sourceField?.PossibleValues?.find(x => x.XmlValue === val)
          values[field.Address] = option?.PrintableValue
        }
      }
    }
  })
}

export const CalculateFormulas = (values: Record<string, any>, model: FormModel) => {
  CalculateSingleAddressFormula(values, model)

  if (Object.keys(values).some(x => x.includes("Fa.Rozliczenie"))) {
    values["Fa.Rozliczenie.SumaObciazen"] = sumValuesMatchingPattern(/Fa\.Rozliczenie\.Obciazenia\[\d+\]\.Kwota/, values)
    values["Fa.Rozliczenie.SumaOdliczen"] = sumValuesMatchingPattern(/Fa\.Rozliczenie\.Odliczenia\[\d+\]\.Kwota/, values)
  } else {
    delete values["Fa.Rozliczenie.SumaObciazen"]
    delete values["Fa.Rozliczenie.SumaOdliczen"]
  }

  const allFields = model.Sections.flatMap(x => x.Groups.flatMap(y => y.Fields))
  const fieldsWithFormulaAndWybor1 = allFields.filter(x => x.Formula !== null && x.Formula !== undefined)
    .filter(x => x.MfType === "TWybor1")



  fieldsWithFormulaAndWybor1.forEach(field => {
    values[field.Identifier] = 1
  })

  return values
}