import { SelectBox } from 'devextreme-react';
import { FormField } from "../model"
import OrganizationsDropDown from 'components/dropdown/OrganizationsDropDown';
import {
    Validator,
    RequiredRule,
} from 'devextreme-react/validator';

function SystemInfoComboBox(address: string, availableFkSystems: any, value: any, handleValueChanged2: (values: Record<string, any>) => void, field: FormField): any {
    return <SelectBox
        key={address}
        dataSource={availableFkSystems}
        value={value}
        onValueChanged={handleValueChanged2}
        searchEnabled={true}
        wrapItemText={true}
        readOnly={field.IsReadOnly}>
        <Validator>
            {field.IsRequired &&
                <RequiredRule message="Pole jest wymagane" />}
        </Validator>
    </SelectBox>;
}

export default SystemInfoComboBox;