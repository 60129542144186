import { useEffect, useState, useRef } from "react"
import { useRefreshButton } from "utils/refresh";
import { syncSettingInit, editPopupTitle, columns } from "./const";
import { fetchOrganizationSyncSettings, editOrganizationSyncSetting, addOrganizationSyncSetting, handleSelectionChanged, fetchOrganizations } from "./actions";
import BreadCrumbs from 'components/breadCrumbs/BreadCrumbs';
import { getNode } from 'components/breadCrumbs/getNode';
import DataGrid, { Paging, FilterRow, HeaderFilter, Selection, Scrolling, Search } from 'devextreme-react/data-grid';
import GenericToolbar from "../Toolbars/GenericToolbar";
import AddEditPopup from "./AddEditPopup";
import { useTranslation } from 'react-i18next';
import DeletionPopup from 'components/popup/DeletionPopup';
import { XfXApi } from 'api/XfXApi';
import notify from "devextreme/ui/notify"
import { toolbarButtons } from "./toolbarButtons";


const OrganizationSyncSettings = () => {
  const [organizations, setOrganizations] = useState([])
  const [organizationSyncSettings, setOrganizationSyncSettings] = useState([])
  const [organizationSyncSetting, setOrganizationSyncSetting] = useState(syncSettingInit)
  const [selectedOrganizationSyncSetting, setSelectedOrganizationSyncSetting] = useState(null)
  const [organizationSyncSettingEdit, setOrganizationSyncSettingEdit] = useState(false)

  const [popupTitle, setPopupTitle] = useState(editPopupTitle)
  const [visible, setVisible] = useState(false)
  const [deletionPopupVisible, setDeletionPopupVisible] = useState(false)
  const [deletionPopupContent, setDeletionPopupContent] = useState("")

  const vgBasicOrganizationSyncSettingForm = useRef(null);
  const dataGridOrganizationSyncSettings = useRef(null);

  const [blockAutoRefresh, setBlockAutoRefresh] = useState(false)

  const { t } = useTranslation()

  useRefreshButton(() => fetchOrganizationSyncSettings({ setOrganizationSyncSettings }))

  const deletionPopup = DeletionPopup({
    onConfirm: async () => {

      try {
        const selected = dataGridOrganizationSyncSettings.current.instance.getSelectedRowsData()

        const response = await XfXApi.OrganizationSyncSetting.apiTenantIdOrganizationSyncSettingIdDelete(selected[0].id, XfXApi.GetTenantId())
        if (response.status === 200) {
          notify({
            message: t("#_organizationsyncsettings_actions_1"),
            position: {
              my: 'center bottom',
              at: 'center bottom',
            },
          }, 'success', 5000);
        }
      } catch (error) {
        if (error.response.status >= 400) {
          notify({
            message: `${error?.response?.data?.message}`,
            position: {
              my: 'center bottom',
              at: 'center bottom',
            },
          }, 'error', 10000);
        }
      }
      finally {
        await fetchOrganizationSyncSettings({ setOrganizationSyncSettings })
      }


    },
    content: deletionPopupContent,
    title: t("#_DeletionPopup_delete"),
    isVisible: deletionPopupVisible,
    setIsVisible: setDeletionPopupVisible,
    t: t
  })

  const deleteOrganizationSyncSettings = () => {
    setDeletionPopupContent(t("#_DeletionPopup"));
    deletionPopup.show();
  }
  useEffect(() => {
    fetchOrganizationSyncSettings({ setOrganizationSyncSettings })
    fetchOrganizations({ setOrganizations })
  }, [])

  return (
    <div id="dataGrid-organizationSyncSettings"> 
      {deletionPopup.popup}
      {visible && 
        <AddEditPopup
          visible={visible}
          setVisible={setVisible}
          popupTitle={popupTitle}
          vgBasicOrganizationSyncSettingForm={vgBasicOrganizationSyncSettingForm}
          organizationSyncSettingEdit={organizationSyncSettingEdit}
          organizationSyncSetting={organizationSyncSetting}
          setOrganizationSyncSetting={setOrganizationSyncSetting}
          setOrganizationSyncSettings={setOrganizationSyncSettings}
          dataGridOrganizationSyncSettings={dataGridOrganizationSyncSettings}
          organizations={organizations}
          setBlockAutoRefresh={setBlockAutoRefresh}>
        </AddEditPopup>
      }
      <BreadCrumbs node={getNode({ componentName: t("#_organizationSyncSettings_1"), t })}></BreadCrumbs>
      <GenericToolbar
        className="xfx-toolbar-bigbuttons"
        buttons={toolbarButtons({
          deleteOrganizationSyncSettings: () => deleteOrganizationSyncSettings(),
          selectedOrganizationSyncSetting: selectedOrganizationSyncSetting, dataGridOrganizationSyncSettings: dataGridOrganizationSyncSettings,
          setOrganizationSyncSettings, setOrganizationSyncSetting, setVisible, setPopupTitle, setOrganizationSyncSettingEdit, t
        })}>
      </GenericToolbar>
      <div className="dataGrid">
        <DataGrid
          ref={dataGridOrganizationSyncSettings}
          dataSource={organizationSyncSettings}
          keyExpr="id"
          allowColumnReordering={true}
          showColumnLines={true}
          showBorders={true}
          alignItems={"center"}
          rowAlternationEnabled={true}
          onSelectionChanged={s => {
            handleSelectionChanged({ s: s, setSelectedOrganizationSyncSetting })
          }}>
          <HeaderFilter>
            <Search
              enabled={true}
            >
            </Search>
          </HeaderFilter>
          <FilterRow visible={true} applyFilter={true} />
          <Scrolling mode="virtual" preloadEnabled={true} />
          <Paging defaultPageSize={100} />
          <Selection mode="single" />
          {columns({ t })}
        </DataGrid>
      </div>
    </div>
  );
}

export default OrganizationSyncSettings