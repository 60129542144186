import { useEffect } from "react"

const organizationChangedEventName = "organizationValueChanged"

const useOrganizationDropDownHandler = (eventHandler) => {
    useEffect(() => {
        document.addEventListener(organizationChangedEventName, eventHandler)
        return () => {
            document.removeEventListener(organizationChangedEventName, eventHandler)
        }
    })
}

const riseOrganizationChangedEvent = ({ OrganizationContext }) => {
    document.dispatchEvent(new CustomEvent(organizationChangedEventName, { detail: { OrganizationContext: OrganizationContext }}))
}

export { useOrganizationDropDownHandler, riseOrganizationChangedEvent }