import React, { useRef, useState } from 'react';
import GenericPopup from "components/popup/GenericPopup";
import { ExpandersWithGridsRef } from "./ExporterEditor";
import { useTranslation } from 'react-i18next';
import ExpandersWithGrids from './ExporterEditor';
import "./style.css"

interface ExporterPopupProps {
    state: any;
    setState: (state: any) => void;
    file: any[];
    setFile: (file: any[]) => void;
    reloadKey: number;
    setReloadKey: (key: number) => void;
    blockAutoRefresh: boolean;
    setBlockAutoRefresh: (block: boolean) => void;
}

const ExporterPopup = ({
    reloadKey, setReloadKey, blockAutoRefresh, setBlockAutoRefresh
}: ExporterPopupProps) => {
    const { t } = useTranslation();
    const [isVisible, setIsVisible] = useState(false);
    const [anySelected, setAnySelected] = useState(false);
    const expandersRef = useRef<ExpandersWithGridsRef>(null);


    // @ts-ignore
    const popup = GenericPopup({
        onHiding: () => popup.close(),
        content:
            <div>
                <ExpandersWithGrids ref={expandersRef} setAnySelected={setAnySelected}></ExpandersWithGrids>
            </div >,
        buttons: [{
            text: t("exporter_export"),
            location: "after",
            disabled: !anySelected,
            action: async () => {
                const sent = await expandersRef.current?.save();
                if (sent) {
                    setReloadKey(reloadKey + 1);
                    setIsVisible(false);
                    setAnySelected(false);
                }
            },
        },
        {
            text: t("#_savecancelpopup_2"),
            location: "after",

            action: () => {
                setIsVisible(false);
                setAnySelected(false);
            },
        }],
        title: t("ImportExportConfig_ExportConfigTitle"),
        width: "812",
        height: "80%",
        isVisible: isVisible,
        setIsVisible: setIsVisible,
        setBlockAutoRefresh: setBlockAutoRefresh,
    })

    return popup
};

export default ExporterPopup;