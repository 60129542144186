import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { XfXApi } from 'api/XfXApi';
import BreadCrumbs from 'components/breadCrumbs/BreadCrumbs';
import { getNode } from 'components/breadCrumbs/getNode';
import Toolbar, { Item } from 'devextreme-react/toolbar';
import RadioGroup from 'devextreme-react/radio-group';
import notify from 'devextreme/ui/notify';

const BatchSettings = () => {
    const { t } = useTranslation()
    const [batchSettings, setBatchSettings] = useState(null)

    useEffect(() => {
        XfXApi.SettingsApi.apiTenantIdSettingsBatchSettingsGet(XfXApi.GetTenantId())
            .then((x) => { 
                if (x.status === 200) //for 204 there is no point to set empty data i guess
                    setBatchSettings(x.data)
            })
    }, [])

    const save = () => {
        XfXApi.SettingsApi.apiTenantIdSettingsBatchSettingsPost(XfXApi.GetTenantId(), batchSettings)
            .then(() => {
                notify({
                    message: `${t("#_batchSettings3")}`,
                    position: {
                      my: 'center bottom',
                      at: 'center bottom',
                    },
                  }, 'success', 5000);
            })
            .catch(() => {
                notify({
                    message: `${t("#_batchSettings4")}`,
                    position: {
                      my: 'center bottom',
                      at: 'center bottom',
                    },
                  }, 'error', 5000);
            })
    }

    const radioGroupItems = [
        { value: true, text: t('#_cellrenderers_5') },
        { value: false, text: t('#_cellrenderers_6') },
    ]

    const saveDisabled = () => {
        return !batchSettings?.hasOwnProperty('deleteBatchKeepProcessingInvoices')
            || !batchSettings?.hasOwnProperty('deleteBatchShowConfirmationModal')
    }

    const radioGroup1 = () => {
        return (
            <div>
              <div>{t('#_batchSettings1')}</div>
              <RadioGroup
                items={radioGroupItems}
                value={batchSettings?.deleteBatchKeepProcessingInvoices}
                valueExpr={"value"}
                displayExpr={"text"}
                layout={'horizontal'}
                onValueChange={v =>
                    setBatchSettings(oldVal => ({...oldVal, deleteBatchKeepProcessingInvoices: v}))
                } 
              />
            </div>
        ) 
    }

    const radioGroup2 = () => {
        return (
            <div>
              <div>{t('#_batchSettings2')}</div>
              <RadioGroup
                items={radioGroupItems}
                value={batchSettings?.deleteBatchShowConfirmationModal}
                valueExpr={"value"}
                displayExpr={"text"}
                layout={'horizontal'}
                onValueChange={v =>
                    setBatchSettings(oldVal => ({...oldVal, deleteBatchShowConfirmationModal: v}))
                } 
              />
            </div>
        ) 
    }

    return <>
        <BreadCrumbs node={getNode({ componentName: 'BatchSettings', t })}></BreadCrumbs>
        <p>{t('batchSettings_description')}</p>
        {radioGroup1()}
        {radioGroup2()}
        <Toolbar className='basicToolbar'>
            <Item
                widget="dxButton"
                toolbar="bottom"
                location="before"
                disabled={saveDisabled()}
                options={{
                    text: t('#_savecancelpopup_1'),
                    onClick: save
                }}
            />
        </Toolbar>
    </>
}

export default BatchSettings