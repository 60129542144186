import DataSource from 'devextreme/data/data_source';
import 'devextreme/data/odata/store'
import { GetAuthHeader, GetRecentAuthHeader, GetXfxSession } from 'api/XfXApi';
import moment from 'moment';
import ODataStore from 'devextreme/data/odata/store';
import { setCustomOdataFilter } from 'utils/odataCustomFilters';

const addAuthHeaders = async (e) => {
    const authHeader = GetRecentAuthHeader()
    e.headers[authHeader.key] = authHeader.value
    await GetAuthHeader()
}

const addXfxSessionHeader = (e) => {
    e.headers["Xfx-Session"] = GetXfxSession()
}
 
export const GetOdataDataSource = (endpoint, defaultSort, fields, filter, setLastUsedQueryParms, idKeyType, setIsAccessForbidden, odataCustomFilterFieldName, paginate) => {
    
    return new DataSource({
        paginate: paginate ?? true,
        store: {
            type: 'odata',
            url: endpoint,
            key: ['Id'], //can't have multiple keys otherwise datagrid onselectionchange etc doesnt work properly
            version: 4,
            keyType: {
                'Id': idKeyType ? idKeyType : 'Guid',
                'SessionId': 'Guid',
                'SyncProcessId': 'Guid',
                'BatchId': 'Guid'
                //TO DO: think if we wanna specify keys array as another param
                //This has to be here even though we don't specify in keys, otherwise when navigating through pages
                //A binary operator with incompatible types was detected. Found operand types 'Edm.Guid' and 'Edm.String' for operator kind 'Equal'.
            },
            beforeSend: request => {
 
                addAuthHeaders(request)
                addXfxSessionHeader(request)

                if (setLastUsedQueryParms) {
                    setLastUsedQueryParms(request.params)
                }

                try
                {
                    let params_ = request.params["$filter"];  
                    let pattern = /\d{2,4}(\/|-|.)\d{2,4}(\/|-|.)\d{2,4}[a-zA-Z\s]\d{1,2}(:)\d{1,2}(:)\d{1,2}/g;
                    let dates_ = params_.match(pattern);  

                    dates_.forEach((d) => { 
                        const date_ = new Date(d);                         
                        const dateReplace = new Date(date_.toString().split('GMT')[0]+' UTC').toISOString().split('.')[0];
                        const utcDate = moment(date_).utc().format(); 
                        params_ = params_.replace(dateReplace, utcDate).replace('ZZ', 'Z');
                        request.params['$filter'] = params_;
                     });  
                }
                catch{ console.log("incorrect param"); }

                if (odataCustomFilterFieldName) {
                    setCustomOdataFilter(request.params, odataCustomFilterFieldName);
                }
            },
            errorHandler: e => {
                if (e.httpStatus === 401 || e.httpStatus === 403) {
                    if (setIsAccessForbidden !== undefined && setIsAccessForbidden !== null) {
                        setIsAccessForbidden(true)
                    }
                }
            },
            onLoaded: () => {
                if (setIsAccessForbidden !== undefined && setIsAccessForbidden !== null) {
                    setIsAccessForbidden(false)
                }
            }
        },
        select: fields,
        filter: filter != null && filter.length > 0 ? filter : null,
        sort: defaultSort
    });
}


export const GetOdataDataFilterString = (filter) => {
    let odataFilter

    const store = new ODataStore({
        beforeSend: (e) => {
            odataFilter = e.params.$filter;
            return
        }
    })

    store.load({ filter: filter })

    return odataFilter
}