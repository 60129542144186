import { useState } from 'react';

const useFormErrors = () => {
  const [internalErrors, setInternalErrors] = useState([]);

  return {
    getFirstError: (fieldName) => {
      const errorObj = internalErrors.find(e => e.fieldName === fieldName);
      const errorsArray = errorObj && errorObj.errors && errorObj.errors.length > 0 ? [errorObj.errors[0]] : [];

      if (errorsArray.length > 0)
      {
        return [{"type": "custom", "message": errorsArray}];
      }
      else
      {
        return [];
      }
    },

    clearAllErrors: () => {
      setInternalErrors([]);
    },

    clearError: (fieldName) => {
      if (internalErrors.filter(e => e.fieldName === fieldName).length > 0)
      {
        setInternalErrors(oldErrors => oldErrors.filter(e => e.fieldName != fieldName));
      }
    },

    noErrors: (fieldName) => {
      return !internalErrors.some(e => e.fieldName === fieldName);
    },

    setErrors: (newErrors) => {
      if (newErrors && Array.isArray(newErrors))
      {
        setInternalErrors(newErrors);
      }
      else
      {
        setInternalErrors([]);
      }
    }
  }
}

export default useFormErrors;
