import React, { useState } from "react"
import { XfXApi } from '../../api/XfXApi';
import { Button } from 'devextreme-react/button';
import FileUploader from 'devextreme-react/file-uploader';
import { LoadIndicator } from 'devextreme-react/load-indicator';
import axios from 'axios'
import { useTranslation } from 'react-i18next';
import GenericPopup from "components/popup/GenericPopup";

const KsefLoginPopup = ({ organizationNip, isVisible, setIsVisible, showToastError, onSuccessfulLogin, autosignAuthChallenge, setBlockAutoRefresh }) => {
    const { t } = useTranslation()
    const [loading, setLoading] = useState(false)
    const [step, setStep] = useState(0)
    const [signedFile, setSignedFile] = useState(null)

    const step0_GetFile = async (organizationNip) => {
        setLoading(true)
        await XfXApi.KsefAuthApi.apiTenantIdKsefAuthGenerateChallengeToSignPost(XfXApi.GetTenantId(), "nip:" + organizationNip)
            .then(x => {
                var a = document.createElement('a')
                var blob = new Blob([x.data], { 'type': x.headers["content-type"] })
                if(autosignAuthChallenge)
                    step2(new File([blob], "KSeF.xml"))
                else
                {
                    a.href = window.URL.createObjectURL(blob)
                    a.download = "KSeF.xml"
                    a.click()
                    setStep(1)
                }
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const step1_LoadSignedFile = (file) => {
        setSignedFile(file)
        setStep(2);
    }

    const step2 = (file) => {
        var fileData = new FormData()
        fileData.append("file", file, file.name)
        setLoading(true)
        axios.post((window.XFX_WEBAPI ?? "") + `/api/${XfXApi.GetTenantId()}/KsefAuth/InitSession`, fileData, {
            headers: {
                'Content-Type': `multipart/form-data`
            }
        })
            .then(x => {
                setStep(0)
                popup.close()
                onSuccessfulLogin()
            })
            .catch(x => {
                if (x.response.data.errors) {
                    x.response.data.errors.forEach(error => {
                        showToastError(error)
                    });
                } else {
                    showToastError(x.message)
                }
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const closeDialog = () => {
        setStep(0)
        setSignedFile(null)
        popup.close()
    }

    const popup = GenericPopup({ 
        onHiding: () => closeDialog(),
        content:
        <div>
          {loading && <>
                <LoadIndicator></LoadIndicator>
            </>}

            {!loading && <>
                {step === 0 && <Button onClick={() => step0_GetFile(organizationNip)}>{autosignAuthChallenge ? t("#_ksefloginpopup_40") : t("#_ksefloginpopup_50")}</Button>}

                {step > 0 &&
                    <>
                        <FileUploader
                            selectButtonText={t("#_ksefloginpopup_4")}
                            labelText={t("#_ksefloginpopup_5")}
                            multiple={false}
                            uploadMode="instantly"
                            invalidFileExtensionMessage={t("#_ksefloginpopup_7")}
                            uploadedMessage={t("#_ksefloginpopup_8")}
                            readyToUploadMessage={t("#_ksefloginpopup_9")}
                            uploadFailedMessage={t("#_ksefloginpopup_10")}
                            name={'file'}
                            uploadFile={file => step1_LoadSignedFile(file)}
                        />
                        {step === 2 &&
                            <Button
                                isVisible={step === 2}
                                onClick={() => {
                                    step2(signedFile)

                                }}>Zaloguj</Button>
                        }
                    </>
                }
            </>}
        </div>,
        title: t("#_ksefloginpopup_3") + organizationNip + ")",
        width: "500px",
        height: "300px",
        isVisible: isVisible,
        setIsVisible: setIsVisible,
        setBlockAutoRefresh: setBlockAutoRefresh
      })

    return (
        <>
            {popup.popup}
        </>
    )
}

export default KsefLoginPopup
