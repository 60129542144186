import { useEffect, useState, useRef, useCallback } from "react"
import 'devextreme-react/autocomplete';
import DataGrid, {
  Column,
  Paging,
  FilterRow,
  Selection,
  HeaderFilter,
  Scrolling,
  Label,
  Search
} from 'devextreme-react/data-grid';
import { OrganizationsColumnRenderer, RecipientsRenderer } from './Renderers'
import NotificationEditor from './NotificationEditor'
import CustomStore from 'devextreme/data/custom_store';
import { useRefreshButton } from 'utils/refresh';

import GenericToolbar from '../Toolbars/GenericToolbar'
import { XfXApi } from 'api/XfXApi';
import BreadCrumbs from 'components/breadCrumbs/BreadCrumbs';
import { getNode } from 'components/breadCrumbs/getNode';
import { useTranslation } from 'react-i18next';
import DeletionPopup from 'components/popup/DeletionPopup';

const userColums = ['login']
function mapUsers(users) {
  return users.map(v => ({ id: v.id, login: v.login }))
}

const NotificationsConfigurations = () => {
    const { t } = useTranslation()

    const [existingConfigurations, setExistingConfigurations] = useState([])
    
    const [organizations, setOrganizations] = useState([])
    const [allOrganizationsCustomStore, setAllOrganizationsCustomStore] = useState()
    const [users, setUsers] = useState([])

    const [notificationTypes, setNotificationTypes] = useState([])
    const [notificationPeriods, setNotificationPeriods] = useState([])
    const [notificationModes, setNotificationModes] = useState([])

    const [notificationId, setNotificationId] = useState(null)
    
    const [notificationTypesCustomStore, setNotificationTypesCustomStore] = useState([])
    const [notificationPeriodsCustomStore, setNotificationPeriodsCustomStore] = useState([])
    const [notificationModesCustomStore, setNotificationModesCustomStore] = useState([])
    const [deletionPopupVisible, setDeletionPopupVisible] = useState(false)
    const [deletionPopupContent, setDeletionPopupContent] = useState("")

    const [visible, setVisible] = useState(false)

    const dataGridRef = useRef(null);
    const [selectedItem, setSelectedItem] = useState(null)

    const [blockAutoRefresh, setBlockAutoRefresh] = useState(false)

    const fetchExistingConfigurations = async () => {
      try {
        const { data } = await XfXApi.NotificationConfiguration.apiTenantIdNotificationConfigurationGet(XfXApi.GetTenantId())
        setExistingConfigurations(data)
        if (selectedItem !== null) {
          const updatedSelctedItem = data.find(x => x.id === selectedItem.id)
          setSelectedItem(updatedSelctedItem)
        }
      } catch (error) {
        console.error(error)
      }
    }
    
  useRefreshButton(fetchExistingConfigurations)
  
  useEffect(() => {
    fetchExistingConfigurations()
  }, [t])

  const fetchOrganizations = async () => {
    try {
      const result = await XfXApi.Organization.apiTenantIdOrganizationOrganizationsGet(XfXApi.GetTenantId())
      setOrganizations(result.data)
      setAllOrganizationsCustomStore(makeAsyncDataSource(result.data))
    } catch (error) {
      console.error(error)
    }
  }
  useEffect(() => {
    fetchOrganizations()
  }, [])

  useEffect(() => {
  const fetchNotificationTypes = async () => {
    try {
      const result = await XfXApi.NotificationConfiguration.apiTenantIdNotificationConfigurationPropertiesGet(XfXApi.GetTenantId());
      setNotificationTypes(result.data)
      setNotificationTypesCustomStore(makeAsyncDataSource(result.data))
    } catch (error) {
      console.error(error)
    }
  }
  fetchNotificationTypes()
  }, [t])

  useEffect(() => {
  const fetchNotificationModes = async () => {
    try {
      const result = await XfXApi.NotificationConfiguration.apiTenantIdNotificationConfigurationModesGet(XfXApi.GetTenantId());
      setNotificationModes(result.data)
      setNotificationModesCustomStore(makeAsyncDataSource(result.data))
    } catch (error) {
      console.error(error)
    }
  }
  fetchNotificationModes()
  }, [t])
  
  useEffect(() => {
  const fetchNotificationPeriods = async () => {
    try {
      const result = await XfXApi.NotificationConfiguration.apiTenantIdNotificationConfigurationPeriodsGet(XfXApi.GetTenantId());
      setNotificationPeriods(result.data)
      setNotificationPeriodsCustomStore(makeAsyncDataSource(result.data))
    } catch (error) {
      console.error(error)
    }
  }
    fetchNotificationPeriods()
  }, [t])

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const { data: { stateObject } } = await XfXApi.User.apiTenantIdUserUsersGet(XfXApi.GetTenantId())
        setUsers(makeAsyncDataSource(mapUsers(stateObject)))
      } catch (error) {
        console.error(error)
      }
    }

    fetchUsers()
  }, [])
  
  function makeAsyncDataSource(data) {
    return new CustomStore({
      loadMode: 'raw',
      key: 'id',
      load() {
        return data
      },
    });
  }

  const onSaved = async ()=>
  {
      await fetchExistingConfigurations()
  }

  const deletionPopup = DeletionPopup({
    onConfirm: () =>{
      XfXApi.NotificationConfiguration.apiTenantIdNotificationConfigurationIdDelete(selectedItem.id, XfXApi.GetTenantId())
                .then(fetchExistingConfigurations)
    },
    content: deletionPopupContent, 
    title: t("#_DeletionPopup_delete"),    
    isVisible: deletionPopupVisible, 
    setIsVisible: setDeletionPopupVisible, 
    t: t
  })

    const deleteConfiguration = useCallback(() => {
        if (selectedItem === null) return
        setDeletionPopupContent(t("#_DeletionPopup"));        
        deletionPopup.show();
      }, [selectedItem])

      function addConfiguration(e)
      {
          setNotificationId(null)
          setVisible(true)
      }
  
      function editConfiguration(e)
        {
          const selected = dataGridRef.current.instance.getSelectedRowsData()
          if (selected.length === 0) return
  
          setNotificationId(selected[0].id)
          setVisible(true)
        }

      const calculateOrganizationFilter = (value) => {
        return [["organizations", "contains", value]];
      }

  const toolbarButtons = [
    { icon: 'plus', text: t("#_notificationconfiguration_7"), onClick: addConfiguration },
    { icon: 'edit', text: t("#_notificationconfiguration_8"), onClick: editConfiguration, disabled: selectedItem === null },
    { icon: 'trash', text: t("#_notificationconfiguration_9"), onClick: deleteConfiguration, disabled: selectedItem === null },
  ]

  return (
    <>
    {deletionPopup.popup}
     <div id="dataGrid-configurations">
       {visible && 
        <NotificationEditor 
            setVisible={setVisible}
            notificationId={notificationId}      
            userDataSource={users}
            userColums={userColums}
            notificationTypes={notificationTypes}
            notificationTypesDataStore={notificationTypesCustomStore}
            notificationModesDataStore={notificationModesCustomStore}
            notificationPeriodsDataStore={notificationPeriodsCustomStore}
            allOrganizationsCustomStore={allOrganizationsCustomStore}
            onSaved={onSaved}
            visible={visible}
            setBlockAutoRefresh={setBlockAutoRefresh}
        />
       }
       <BreadCrumbs node={getNode({ componentName: 'Notifications', t })}></BreadCrumbs>
       <div className="dataGrid">
         <GenericToolbar className="xfx-toolbar-bigbuttons" header={t("#_roles_16")} buttons={toolbarButtons}></GenericToolbar>
         {
          <DataGrid
          dataSource={existingConfigurations}
          keyExpr="id"
          allowColumnReordering={true}
          showColumnLines={true}
          showBorders={true}
          alignItems={"center"}
          rowAlternationEnabled={true}
          ref={dataGridRef}
          onSelectionChanged={s => {
            setSelectedItem(s.selectedRowsData.length > 0 ? s.selectedRowsData[0] : null)
          }}>
          <HeaderFilter> 
            <Search
              enabled={true}
            >
            </Search>
          </HeaderFilter>
          <FilterRow visible={true} applyFilter={true} />
          <Scrolling mode="virtual" preloadEnabled={true}/>
          <Paging defaultPageSize={100} />
          <Selection mode="single" />

          <Column dataField="id" dataType="number" caption="ID" visible={false} />
          <Column dataField="name" width={"200px"} dataType="string" caption={t("#_notificationconfiguration_1")} />
          <Column dataField="organizations" calculateFilterExpression={calculateOrganizationFilter} dataType="object" caption={t("#_notificationconfiguration_2")} cellRender={OrganizationsColumnRenderer} />
          <Column dataField="notificationMode"  dataType="string" caption={t("#_notificationconfiguration_3")}/>
          <Column dataField="notificationType"  dataType="string" caption={t("#_notificationconfiguration_4")}/>
          <Column dataField="notificationPeriod"   dataType="string" caption={t("#_notificationconfiguration_5")}/>
          <Column dataField="recipients" dataType="string" caption={t("#_notificationconfiguration_6")} cellRender={RecipientsRenderer} />
        </DataGrid> 
    }
       </div>
     </div>
    </>
  );
}

export default NotificationsConfigurations