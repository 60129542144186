import { RowsSummary } from './model';
import React from 'react';

const formatNumber = (x: number) => x.toLocaleString('pl-PL', { minimumFractionDigits: 2, maximumFractionDigits: 2 });

export const lpColumnRender = (data: any) => {
    return <div>{data.value + 1}</div>;
  };

export const summaryContainer = (summary: RowsSummary, showCurrency: boolean) => {
    return (
        <div>
            <div className='invoice-editor-rows-summary-row'>
                <div className='invoice-editor-rows-summary-row-header'>Suma netto</div>
                <div>{formatNumber(summary.netto)}</div>
            </div>
            <div className='invoice-editor-rows-summary-row'>
                <div className='invoice-editor-rows-summary-row-header'>VAT</div>
                <div>{formatNumber(summary.vat)}</div>
            </div>
            {showCurrency && <div className='invoice-editor-rows-summary-row'>
                <div className='invoice-editor-rows-summary-row-header'>VAT w PLN</div>
                <div>{formatNumber(summary.vatInPln)}</div>
            </div>}
            <div className='invoice-editor-rows-summary-row'>
                <div className='invoice-editor-rows-summary-row-header'>Suma brutto</div>
                <div>{formatNumber(summary.brutto)}</div>
            </div>
        </div>
    );
}