import { renderDateCell } from 'utils/cellRenderers'

export const queryParameter = 'SyncProcessId'

export const filterParameter = 'syncProcessId'

export const columns = ({ t }) => [
    { dataField: "Id", caption: t("#_const_33"), width: "auto", visible: true },
    { dataField: "VatNo", dataType: "string", caption: t("#_const_34"), width: "auto" },
    { dataField: "DateFromUtc", dataType: "datetime", cellRender: x=>renderDateCell(x.data?.DateFromUtc), caption: t("#_const_36"), width: "auto" },
    { dataField: "DateToUtc", dataType: "datetime", cellRender: x=>renderDateCell(x.data?.DateToUtc), caption: t("#_const_38"), width: "auto" },
    { dataField: "SyncStartUtc", dataType: "datetime", cellRender: x=>renderDateCell(x.data?.SyncStartUtc), caption: t("#_const_40"), width: "auto" },
    { dataField: "SyncEndUtc", dataType: "datetime", cellRender: x=>renderDateCell(x.data?.SyncEndUtc), caption: t("#_const_42"), width: "auto" },
    { dataField: "DateScheduledUtc", dataType: "datetime", cellRender: x=>renderDateCell(x.data?.DateScheduledUtc), caption: t("#_const_44"), width: "auto" },
    { dataField: "BusinessErrorTimeStampUtc", dataType: "datetime", cellRender: x=>renderDateCell(x.data?.BusinessErrorTimeStampUtc), caption: t("#_const_46"), width: "auto" },
    { dataField: "InvoiceCountTotal", dataType: "number", caption: t("#_const_47"), width: "auto" },
    { dataField: "InvoiceCountReceived", dataType: "number", caption: t("#_const_48"), width: "auto" },
    { dataField: "HasBusinessError", dataType: "boolean", caption: t("#_const_50"), width: "auto", visible: false },
    { dataField: "SessionId", caption: t("#_const_51"), visible: true }
]
