import { TOKENS } from "../customTokens";
import { InvoiceDateType } from "../model";

export const GetCustomTokens = (tokens: { [key: string]: any; }): { [key: string]: any; } => {
    const keys = Object.keys(tokens)
    const ret: any = {};

    const addIfNotExists = (key: string, value: string) => {
        if (!tokens.hasOwnProperty(key)) {
            ret[key] = value;
        }
    };

    addIfNotExists("_Stopka", keys.some(x => x.startsWith("Stopka.Informacje")) ? "TAK" : "NIE");
    const invoiceIssuerRole = tokens["Fa.Adnotacje.P_17"] === '1' ? "Samofakturowanie_Nabywca" : "Sprzedawca";
    addIfNotExists("_kefiks.wystawca", invoiceIssuerRole);
    addIfNotExists("_kefiks.nettobrutto", keys.some(x => x.includes(".P_9A")) ? "netto" : "brutto");
    addIfNotExists("_kefiks.OSS", keys.some(x => x.startsWith("Fa.FaWiersz.P_12_XII")) ? "TAK" : "NIE");
    addIfNotExists("_Fa.Podmiot1K.", keys.some(x => x.startsWith("Fa.Podmiot1K.")) ? "TAK" : "NIE");
    addIfNotExists("_Podmiot1.AdresKoresp.", keys.some(x => x.startsWith("Podmiot1.AdresKoresp.")) ? "TAK" : "NIE");
    addIfNotExists("_Podmiot2.DaneIdentyfikacyjne.", GetIdType("Podmiot2", keys));
    addIfNotExists("_Fa.Podmiot2K[].", keys.some(x => x.startsWith("Fa.Podmiot2K[].")) ? "TAK" : "NIE");
    addIfNotExists("_Fa.Podmiot2K[].DaneIdentyfikacyjne.", keys.some(x => x.startsWith("Fa.Podmiot2K[].DaneIdentyfikacyjne.")) ? "TAK" : "NIE");
    addIfNotExists("_Podmiot2.AdresKoresp.", keys.some(x => x.startsWith("Podmiot2.AdresKoresp.")) ? "TAK" : "NIE");
    addIfNotExists("_Podmiot3[].Dodaj", keys.some(x => x.startsWith("Podmiot3.")) ? "TAK" : "NIE");
    addIfNotExists("_Podmiot3[].Rola/RolaInna", keys.some(x => x.startsWith("Podmiot3.Rola")) ? "TAK" : "NIE");
    addIfNotExists("_Podmiot3[].DaneIdentyfikacyjne.", GetIdType("Podmiot3[]", keys));
    addIfNotExists("_Podmiot3[0].DaneIdentyfikacyjne.", GetIdType("Podmiot3[0]", keys));
    addIfNotExists("_Podmiot3.AdresKoresp.", keys.some(x => x.startsWith("Podmiot3.AdresKoresp.")) ? "TAK" : "NIE");
    addIfNotExists("_PodmiotUpowazniony.AdresKoresp.", keys.some(x => x.startsWith("PodmiotUpowazniony.AdresKoresp.")) ? "TAK" : "NIE");
    addIfNotExists("_Fa.FakturaZaliczkowa[].ZaliczkawKSeF1", keys.some(x => x.startsWith("Fa.FakturaZaliczkowa.")) ? "TAK" : "NIE");
    addIfNotExists("_Fa.FakturaZaliczkowa[].ZaliczkawKSeF2", keys.some(x => x.startsWith("Fa.FakturaZaliczkowa.NrKSeFFaZaliczkowej")) ? "TAK" : "NIE");
    addIfNotExists("_Fa.NrFaKorygowany", keys.some(x => x.startsWith("Fa.NrFaKorygowany")) ? "TAK" : "NIE");
    addIfNotExists("_Fa.DaneFaKorygowanej[].", keys.some(x => x.startsWith("Fa.DaneFaKorygowanej.")) ? "TAK" : "NIE");
    addIfNotExists("_Fa.Adnotacje.PMarzy.Vat marża", keys.some(x => x.startsWith("Fa.Adnotacje.PMarzy.P_PMarzy_")) ? "TAK" : "NIE");
    addIfNotExists("_Fa.Adnotacje.Zwolnienie.ZW", keys.some(x => x === "Fa.Adnotacje.Zwolnienie.") ? "TAK" : "NIE");
    addIfNotExists("_Fa.Adnotacje.P_19A. Przepis ustawy", keys.some(x => x.startsWith("Fa.Adnotacje.Zwolnienie.P_19A")) ? "TAK" : "NIE");
    addIfNotExists("_Fa.Adnotacje.P_19B.Przepis dyrektywy", keys.some(x => x.startsWith("Fa.Adnotacje.Zwolnienie.P_19B")) ? "TAK" : "NIE");
    addIfNotExists("_Fa.Adnotacje.P_19C.Inna podstawa", keys.some(x => x.startsWith("Fa.Adnotacje.Zwolnienie.P_19C")) ? "TAK" : "NIE");
    addIfNotExists("_Fa.Adnotacje.NoweSrodkiTransportu.WDT", keys.some(x => x === "Fa.Adnotacje.NoweSrodkiTransportu.P_22") ? "TAK" : "NIE");
    addIfNotExists("_Fa.Adnotacje.NoweSrodkiTransportu.NowySrodekTransportu.1", keys.some(x => x.startsWith("Fa.Adnotacje.NoweSrodkiTransportu.NowySrodekTransportu.P_22B")) ? "TAK" : "NIE");
    addIfNotExists("_Fa.Adnotacje.NoweSrodkiTransportu.NowySrodekTransportu.2", keys.some(x => x.startsWith("Fa.Adnotacje.NoweSrodkiTransportu.NowySrodekTransportu.P_22C")) ? "TAK" : "NIE");
    addIfNotExists("_Fa.Adnotacje.NoweSrodkiTransportu.NowySrodekTransportu.3", keys.some(x => x.startsWith("Fa.Adnotacje.NoweSrodkiTransportu.NowySrodekTransportu.P_22D")) ? "TAK" : "NIE");
    addIfNotExists("_Fa.ZaliczkaCzesciowa[].zaliczkaczesciowa", keys.some(x => x.startsWith("Fa.ZaliczkaCzesciowa.")) ? "TAK" : "NIE");
    addIfNotExists("_Fa.Rozliczenie.", keys.some(x => x.startsWith("Fa.Rozliczenie.")) ? "TAK" : "NIE");
    addIfNotExists("_Fa.Platnosc.", keys.some(x => x.startsWith("Fa.Platnosc.")) ? "TAK" : "NIE");
    addIfNotExists("_Fa.Platnosc.DataZaplaty", keys.some(x => x.startsWith("Fa.Platnosc.DataZaplaty")) ? "TAK" : "NIE");
    addIfNotExists("_Fa.Platnosc.Zaplacono", keys.some(x => x.startsWith("Fa.Platnosc.Zaplacono")) ? "TAK" : "NIE");
    addIfNotExists("_Fa.Platnosc.FormaPlatnosci", keys.some(x => x.startsWith("Fa.Platnosc.FormaPlatnosci")) ? "TAK" : "NIE");
    addIfNotExists("_Fa.Platnosc.Skonto.", keys.some(x => x.startsWith("Fa.Platnosc.Skonto.")) ? "TAK" : "NIE");
    addIfNotExists("_Fa.WarunkiTransakcji.1", keys.some(x => x.startsWith("Fa.WarunkiTransakcji.")) ? "TAK" : "NIE");
    addIfNotExists("_Fa.WarunkiTransakcji.Waluta", keys.some(x => x.startsWith("Fa.WarunkiTransakcji.Waluta")) ? "TAK" : "NIE");
    addIfNotExists("_Fa.WarunkiTransakcji.Transport[].Przewoznik.", keys.some(x => x.startsWith("Fa.WarunkiTransakcji.Transport.Przewoznik.")) ? "TAK" : "NIE");
    addIfNotExists("_Fa.WarunkiTransakcji.Transport[].WysylkaZ", keys.some(x => x.startsWith("Fa.WarunkiTransakcji.Transport.WysylkaZ")) ? "TAK" : "NIE");
    addIfNotExists("_Fa.WarunkiTransakcji.Transport[].WysylkaDo", keys.some(x => x.startsWith("Fa.WarunkiTransakcji.Transport.WysylkaDo")) ? "TAK" : "NIE");
    addIfNotExists("_Stopka.Informacje", keys.some(x => x.startsWith("Stopka")) ? "TAK" : "NIE");

    addIfNotExists(TOKENS._Fa_P_6, getInvoiceDateType(keys));

    if (keys.some(key => key.startsWith("Podmiot3[0]"))) {
        ret["_Podmiot3[0].Dodaj"] = "TAK"
        if (tokens["Podmiot3[0].Rola"] !== undefined) {
            ret["_Podmiot3[0].Rola/RolaInna"] = "Rola"
        } else if (tokens["Podmiot3[0].OpisRoli"] !== undefined) {
            ret["_Podmiot3[0].Rola/RolaInna"] = "Rola Inna"
        }

        if (keys.some(key => key.startsWith("Podmiot3[0].AdresKoresp"))) {
            ret["_Podmiot3[0].AdresKoresp."] = "TAK"
        }
    }

    return ret
}

const getInvoiceDateType = (keys: string[]): InvoiceDateType => {
    if (keys.some(x => x.endsWith(".P_6A"))) {
        return InvoiceDateType.Various;
    } else if (keys.some(x => x.startsWith("Fa.OkresFa.P_6_Od")) || keys.some(x => x.startsWith("Fa.OkresFa.P_6_Do"))) {
        return InvoiceDateType.Period;
    }
    else if (keys.some(x => x.startsWith("Fa.P_1")) && keys.some(x => x.startsWith("Fa.P_6"))) {
        return InvoiceDateType.Common
    } else {
        return InvoiceDateType.SameAsIssueDate;
    }
}

function GetIdType(prefix: string, keys: string[]): string {
    if (keys.some(x => x.startsWith(prefix + ".DaneIdentyfikacyjne.NIP"))) return "NIP"
    if (keys.some(x => x.startsWith(prefix + ".DaneIdentyfikacyjne.NrID"))) return "Identyfikator podatkowy inny"
    if (keys.some(x => x.startsWith(prefix + ".DaneIdentyfikacyjne.NrVatUE"))) return "Nr Vat-UE"
    if (keys.some(x => x.startsWith(prefix + ".DaneIdentyfikacyjne.BrakID"))) return "BrakID"
    return "NIP"
}
