import React from 'react';
import { CheckBox, TextBox, DateBox, NumberBox, SelectBox, DropDownBox } from 'devextreme-react';
import { FormField, FieldType, EditorType, IOrganization } from "../model"
import {
    Validator,
    RequiredRule,
} from 'devextreme-react/validator';
import SequenceControl from '../SequenceControl';
import { ExtendedEditor } from '../../settings';
import { findMaxNumberInPattern } from '../helpers';
import { GetDescriptionVisibility } from '../GetDescriptionVisibility';
import { Tooltip } from 'devextreme-react/tooltip';
import SystemInfoComboBox from './SystemInfoComboBox';
import OrganizationNip from './OrganizationNip';
import { Organization, OrganizationDto } from 'api/openapi';

interface GetControlProps {
    field: FormField;
    handleValueChanged: (values: Record<string, any>, address: any) => void,
    handleValueChangedForCheckbox: (fieldId: string, value: any, address: any) => void,
    sequenceIndex?: number,
    value: any,
    error: string | null,
    updatedValues: Record<string, any>,
    beforeCorrectionValues: Record<string, any> | null,
    editorType: EditorType;
    numberingSeriesDataSource: any;
    availableFkSystems: any,
    setValuesAndRecalculateFormulas: (values: Record<string, any>) => void,
    availableOrganizations: OrganizationDto[],
    t: any
}

interface InvoiceNumberModel {
    invoiceNumber: string;
    useNumberingSeries: boolean;
    numberingSeriesId: string | null;
    canBeChanged: boolean;
}

const GetControl = ({ field, value, handleValueChanged, handleValueChangedForCheckbox, sequenceIndex, error, updatedValues, editorType, beforeCorrectionValues,
    numberingSeriesDataSource, availableFkSystems, setValuesAndRecalculateFormulas, availableOrganizations, t }: GetControlProps) => {
    let address = field.Address
    let label = field.Name + (field.IsRequired ? "*" : "");

    if (sequenceIndex !== undefined) {
        address = address + "[" + sequenceIndex + "]"
    }

    const fieldId = field.Identifier.replace("[]", "[" + sequenceIndex + "]")
    value = value ?? updatedValues[fieldId]
    let beforeCorrectionValue = beforeCorrectionValues?.[fieldId]

    const handleValueChanged2: (values: Record<string, any>) => void = x => {
        handleValueChanged({ key: fieldId, value: x.value }, address)
    }

    const handleValueChangedForCheckbox2: (values: Record<string, any>) => void = x => {
        handleValueChangedForCheckbox(fieldId, x.value, address)
    }

    const fieldCorrected = (editorType === EditorType.Correction || editorType === EditorType.CorrectionSelfInvoice) &&
        beforeCorrectionValue && value !== beforeCorrectionValue

    let control;
    switch (field.Type) {
        case FieldType.InvNumberCustom:
            const createObjIfEmpty = (value: InvoiceNumberModel): InvoiceNumberModel => {
                if (value === null || value === undefined || Object.keys(value).length === 0) {
                    return { invoiceNumber: "", useNumberingSeries: false, numberingSeriesId: null, canBeChanged: true };
                }

                return value;
            }
            const handleUseNumberingSeriesValueChanged: (values: Record<string, any>) => void = x => {

                let objectToSet = Object.assign({}, value) as InvoiceNumberModel;
                objectToSet = createObjIfEmpty(objectToSet);
                objectToSet.useNumberingSeries = x.value;
                if (objectToSet.useNumberingSeries === true) {
                    objectToSet.invoiceNumber = "";
                }
                else {
                    objectToSet.numberingSeriesId = null;
                }

                handleValueChanged({ key: fieldId, value: objectToSet }, address)
            }
            const handleInvoiceNumberValueChanged: (values: Record<string, any>) => void = x => {
                let objectToSet = Object.assign({}, value) as InvoiceNumberModel;
                objectToSet = createObjIfEmpty(objectToSet);
                objectToSet.invoiceNumber = x.value;

                handleValueChanged({ key: fieldId, value: objectToSet }, address)
            }
            const handleNumberingSeriesValueChanged: (values: Record<string, any>) => void = x => {
                let objectToSet = Object.assign({}, value) as InvoiceNumberModel;
                objectToSet = createObjIfEmpty(objectToSet);
                objectToSet.numberingSeriesId = x.value;
                if (x.value == null) {
                    objectToSet.useNumberingSeries = false;
                }

                handleValueChanged({ key: fieldId, value: objectToSet }, address)
            }

            if (value !== undefined && typeof value !== 'object') {
                try {
                    const obj = JSON.parse(value)
                    if ('invoiceNumber' in obj) {
                        handleValueChanged({ key: fieldId, value: obj }, address)
                    }
                    else {
                        handleValueChanged({ key: fieldId, value: { invoiceNumber: value, useNumberingSeries: false, numberingSeriesId: null, canBeChanged: true } }, address)
                    }
                }
                catch {
                    handleValueChanged({ key: fieldId, value: { invoiceNumber: value, useNumberingSeries: false, numberingSeriesId: null, canBeChanged: true } }, address)
                }
            }

            // we don't want to show prev inv number when correcting
            beforeCorrectionValue = null;

            const castedValue = value as InvoiceNumberModel;
            if ((error === null || error === "") && castedValue !== undefined
                && (
                    (castedValue.useNumberingSeries === false && castedValue.invoiceNumber === "")
                    || (castedValue.useNumberingSeries === true && castedValue.numberingSeriesId === null)
                )
            ) {
                error = "Pole wymagane"
            }
          
            let invoiceNumberReadOnly = false;
            let numberingSeriesReadOnly = true;
            let useNumberingSeriesDefaultValue = false;
            let invoiceNumberDefaultValue = "";
            let numberingSeriesDefaultValue: string | null = null;
            let numberingSeriesVisible = false;
            let useNumberingSeriesReadOnly = false;

            if (value !== null && value !== undefined) {
                useNumberingSeriesDefaultValue = castedValue.useNumberingSeries;
                invoiceNumberReadOnly = castedValue.useNumberingSeries || !castedValue.canBeChanged;
                numberingSeriesReadOnly = !castedValue.canBeChanged;
                useNumberingSeriesReadOnly = !castedValue.canBeChanged;
                invoiceNumberDefaultValue = castedValue.invoiceNumber;
                numberingSeriesVisible = castedValue.useNumberingSeries;
                numberingSeriesDefaultValue = castedValue.numberingSeriesId;
            }

            control = (
                <>
                    <TextBox
                        key={"InvNumberTextBox"}
                        defaultValue={invoiceNumberDefaultValue}
                        onValueChanged={handleInvoiceNumberValueChanged}
                        readOnly={invoiceNumberReadOnly}
                        disabled={invoiceNumberReadOnly}
                        value={invoiceNumberDefaultValue}
                    >
                    </TextBox>
                    <div className="flex-container">
                        <CheckBox
                            className='inv-number-check-box'
                            key={"InvNumberCheckBox"}
                            defaultValue={useNumberingSeriesDefaultValue}
                            onValueChanged={handleUseNumberingSeriesValueChanged}
                            text={"Nadaj numer automatycznie"}
                            readOnly={useNumberingSeriesReadOnly}
                            disabled={useNumberingSeriesReadOnly}
                        >
                        </CheckBox>
                        <i id="tooltip-invoice-number" className={`dx-icon dx-icon-info invoice-number-tooltip-icon`}></i>
                        <Tooltip
                            target="#tooltip-invoice-number"
                            showEvent="dxhoverstart"
                            hideEvent="dxhoverend"
                            position={"top"}
                        >
                            <>Numer faktury zostaje nadany i jest prezentowany <br />
                                dla faktur w statusie “Gotowa do wysyłki”</>
                        </Tooltip>
                    </div>
                    <SelectBox
                        key={"InvNumberSelectBox"}
                        items={numberingSeriesDataSource}
                        defaultValue={numberingSeriesDefaultValue}
                        value={numberingSeriesDefaultValue}
                        onValueChanged={handleNumberingSeriesValueChanged}
                        displayExpr="name"
                        valueExpr="id"
                        searchEnabled={true}
                        wrapItemText={true}
                        visible={numberingSeriesVisible}
                        readOnly={numberingSeriesReadOnly}
                        disabled={numberingSeriesReadOnly}
                        placeholder='Wybierz serię numeracji...'
                    >
                    </SelectBox>
                </>
            );
            break;
        case FieldType.CheckBox:
            const possibleValue = field.PossibleValues?.find(x => x.XmlValue === value)
            const initalValue = possibleValue?.PrintableValue && possibleValue.PrintableValue.toLowerCase() === 'tak' ? true : false

            control = (
                <CheckBox
                    key={address}
                    defaultValue={initalValue}
                    onValueChanged={handleValueChangedForCheckbox2}
                    readOnly={field.IsReadOnly}
                >
                    {/* <Validator>
                        {field.IsRequired &&
                            <RequiredRule message="Pole jest wymagane" />}
                    </Validator> */}
                </CheckBox>
            );
            break;
        case FieldType.TextBox:
            let validators: any[] = []
            let maxLength: number | undefined = undefined
            if (field.MfType === "TNrNIP") {
                maxLength = 10
            }
            control = (
                <TextBox
                    key={address}
                    value={value}
                    onValueChanged={handleValueChanged2}
                    maxLength={maxLength}
                    readOnly={field.Formula !== null || field.IsReadOnly}
                >
                    <Validator>
                        {field.IsRequired && <RequiredRule message="Pole jest wymagane" />}
                        {validators.map((validator, index) => React.cloneElement(validator, { key: index }))}
                    </Validator>
                </TextBox>
            );
            break;
        case FieldType.DateBox:
            var date = value !== undefined ? new Date(value) : undefined;
            control = (

                <DateBox
                    key={address}
                    defaultValue={date}
                    onValueChanged={handleValueChanged2}
                    className='invoice-editor-field-datebox'
                    type='date'
                    dateSerializationFormat='yyyy-MM-ddTHH:mm:ss'
                    readOnly={field.IsReadOnly}
                >
                    <Validator>
                        {field.IsRequired &&
                            <RequiredRule message="Pole jest wymagane" />}
                    </Validator>
                </DateBox>
            );
            break;
        case FieldType.DateTimeBox:
            var dateTime = value !== undefined ? new Date(value) : undefined;

            control = (
                <DateBox
                    key={address}
                    defaultValue={dateTime}
                    onValueChanged={handleValueChanged2}
                    className='invoice-editor-field-datebox'
                    type='datetime'
                    dateSerializationFormat='yyyy-MM-ddTHH:mm:ss'
                    readOnly={field.IsReadOnly}
                >
                    <Validator>
                        {field.IsRequired &&
                            <RequiredRule message="Pole jest wymagane" />}
                    </Validator>
                </DateBox>
            );
            break;
        case FieldType.IntBox:
        case FieldType.DecimalBox:
            const formatType = field.Type === FieldType.DecimalBox ? "decimal" : "fixedPoint";
            control = (
                <NumberBox
                    key={address}
                    format={formatType}
                    defaultValue={value}
                    onValueChanged={handleValueChanged2}
                    readOnly={field.IsReadOnly}
                >
                    <Validator>
                        {field.IsRequired &&
                            <RequiredRule message="Pole jest wymagane" />}
                    </Validator>
                </NumberBox>
            );
            break;
        case FieldType.ComboBox:
            control = (
                <SelectBox
                    key={address}
                    items={field.PossibleValues}
                    value={value}
                    onValueChanged={handleValueChanged2}
                    displayExpr="PrintableValue"
                    valueExpr="XmlValue"
                    searchEnabled={true}
                    wrapItemText={true}
                    readOnly={field.IsReadOnly}
                >
                    <Validator>
                        {field.IsRequired &&
                            <RequiredRule message="Pole jest wymagane" />}
                    </Validator>
                </SelectBox>
            );
            break;

        case FieldType.Sequence:
            const itemRenderer = (n: number) => {
                return field.DependentFields?.map((field, index) => {

                    const ret = (<div key={field.Address + "_" + n}>{GetControl({
                        field, value: null, error: "", handleValueChanged, handleValueChangedForCheckbox,
                        sequenceIndex: n, updatedValues: updatedValues, editorType, beforeCorrectionValues,
                        numberingSeriesDataSource, availableFkSystems, setValuesAndRecalculateFormulas,
                        availableOrganizations, t
                    }).control}</div>)

                    if (field.Type === FieldType.CheckBox) {
                        const fieldId = field.Identifier.replace("[]", "[" + n + "]")
                        if (updatedValues[fieldId] === undefined) {
                            handleValueChangedForCheckbox(fieldId, false, field.Address)
                        }
                    }
                    return ret
                })
            }

            const initialItemsCount = field.Identifier ? findMaxNumberInPattern(field.Identifier, Object.keys(updatedValues)) + 1 : 0

            const handleRemoveItem = (sequenceIndex: number) => {
                const fieldsIdentifiersToRemove: string[] = []

                field?.DependentFields?.forEach((element) => {
                    const fieldId = element?.Identifier?.replace("[]", "[" + sequenceIndex + "]")
                    fieldsIdentifiersToRemove.push(fieldId)
                })

                fieldsIdentifiersToRemove?.forEach((element) => {
                    if (element in updatedValues) {
                        const propToRemoveName = `${element}`
                        const { [propToRemoveName]: propToRemoveValue, ...newValues } = updatedValues

                        updatedValues = newValues

                        setValuesAndRecalculateFormulas(updatedValues)

                        console.debug("Removing property: " + propToRemoveName)
                    }
                })
            }

            control = (
                <>
                    <div className="invoice-editor-sequence">
                        <SequenceControl itemRenderer={itemRenderer}
                            minItems={field.MinOccurs ?? 0}
                            maxItems={field.MaxOccurs ?? Infinity}
                            initialItemsCount={initialItemsCount}
                            handleRemoveItem={handleRemoveItem}
                        ></SequenceControl>
                    </div>
                </>
            )
            break;
        case FieldType.Formula:
            label = label + " (\"" + field.Formula + "\")"

            const tb = <TextBox
                key={address}
                disabled={true}
                value={value}
                readOnly={true}
            >
            </TextBox>

            control = (<>{tb}</>);

            break;
        case FieldType.SystemInfoComboBox:
            control = (
                SystemInfoComboBox(address, availableFkSystems, value, handleValueChanged2, field)
            );
            break;

        case FieldType.OrganizationNip:
            control = (
                OrganizationNip(address, availableOrganizations, value, handleValueChanged2, field, fieldCorrected, t)
            );
            break;
        default:
            control = null;
    }

    let cssClass = 'invoice-editor-field-' + field.Address
    if (sequenceIndex !== undefined) {
        cssClass += '_' + sequenceIndex
    }
    if (field.Invisible) {
        cssClass += ' invoice-editor-field-invisible'
    }

    label = (ExtendedEditor ? ("(" + field.Address + "/" + field.Identifier + "/" + field.MfType + ") ") : "") + label



    const showDescription = GetDescriptionVisibility(field, updatedValues, fieldCorrected)

    const lockIcon = field.IsReadOnly && field.LockInfo ? <span //title={field.LockInfo}
    >🔒</span> : "";

    const returnControl = (
        <div className={cssClass + " invoice-editor-field"}>
            <label className='invoice-editor-field-label'>{label}{lockIcon}</label>
            {control}
            {fieldCorrected && <div className='invoice-editor-change'>Wartość przed korektą: {beforeCorrectionValue} </div>}
            {showDescription && <div className='invoice-editor-description'>{field.Description}</div>}
            {/* {field.Identifier} */}
            <label className='invoice-editor-field-error'>{error}</label>
        </div>
    )

    return {
        control: returnControl,
    }
}


export default GetControl;
