import { Fa1InvoiceItem, TStawkaPodatku } from "./Fa1InvoiceItem";
import { FormField } from "./model";

export const enum InvoiceRowsMode {
    netto,
    brutto
}

const changeTaxRateToToken = (taxRate: string): string | null => {
    switch (taxRate) {
        case TStawkaPodatku.vatMarza:
            return null;
        case TStawkaPodatku.Item0WDT:
        case TStawkaPodatku.Item0Krajowe:
        case TStawkaPodatku.Item0Eksport:
            return "Item0";
        case TStawkaPodatku.npNaPodstawie:
        case TStawkaPodatku.npZWylaczeniem:
            return "np";
        default:
            return taxRate;
    }
}

const changeTokenToTaxRate = (token: string): string => {
    switch (token) {
        case "Item0":
            return TStawkaPodatku.Item0Krajowe;
        case "np": 
            return TStawkaPodatku.npZWylaczeniem;
        default:
            return token;
    }
}

export const createTokensFromInvoiceItems = (
    produkty: Fa1InvoiceItem[], showDateForRows: boolean, showCurrencyInRowsEditor: boolean, showNetto: boolean, initShowNetto: boolean | null
) //showNetto: boolean, showOss: boolean
    : { [key: string]: string } => {
    const values: { [key: string]: string } = {};
    const addValueIfNotEmpty = (key: string, value: any) => {
        if (value !== undefined && value !== null && value !== '') {
            values[key] = value;
        }
    }

    produkty.forEach((produkt, index) => {
        if (produkt.stanPrzed) addValueIfNotEmpty(`${rowPrefix}[${index}].StanPrzed`, produkt.stanPrzed);
        if (produkt.kwotaPodatku !== 0) addValueIfNotEmpty(`${rowPrefix}[${index}].P_11Vat`, produkt.kwotaPodatku);
        addValueIfNotEmpty(`${rowPrefix}[${index}].Indeks`, produkt.indeks);
        if (produkt.towarUsługaZal15) addValueIfNotEmpty(`${rowPrefix}[${index}].P_12_Zal_15`, produkt.towarUsługaZal15);
        if (produkt.kwotaPodatkuAkcyzowego !== 0) addValueIfNotEmpty(`${rowPrefix}[${index}].KwotaAkcyzy`, produkt.kwotaPodatkuAkcyzowego);
        addValueIfNotEmpty(`${rowPrefix}[${index}].NrWierszaFa`, produkt.lp);
        addValueIfNotEmpty(`${rowPrefix}[${index}].P_7`, produkt.nazwaTowaruLubUslugi);
        addValueIfNotEmpty(`${rowPrefix}[${index}].P_8B`, produkt.ilosc);
        addValueIfNotEmpty(`${rowPrefix}[${index}].P_8A`, produkt.miara);
        if(produkt.rabatOpust !== 0) addValueIfNotEmpty(`${rowPrefix}[${index}].P_10`, produkt.rabatOpust);
        addValueIfNotEmpty(`${rowPrefix}[${index}].P_12`, changeTaxRateToToken(produkt.stawkaPodatku));
        addValueIfNotEmpty(`${rowPrefix}[${index}].P_12_XII`, produkt.stawkaPodatkuXII);
        addValueIfNotEmpty(`${rowPrefix}[${index}].P_12_Procedura`, produkt.procedura);
        addValueIfNotEmpty(`${rowPrefix}[${index}].GTU`, produkt.GTU);
        addValueIfNotEmpty(`${rowPrefix}[${index}].PKWiU`, produkt.PKWiU);
        addValueIfNotEmpty(`${rowPrefix}[${index}].GTIN`, produkt.GTIN);
        addValueIfNotEmpty(`${rowPrefix}[${index}].CN`, produkt.CN);
        addValueIfNotEmpty(`${rowPrefix}[${index}].PKOB`, produkt.PKOB);
        if (showCurrencyInRowsEditor) addValueIfNotEmpty(`${rowPrefix}[${index}].KursWaluty`, produkt.kursWaluty);
        if (showDateForRows) addValueIfNotEmpty(`${rowPrefix}[${index}].P_6A`, produkt.P_6A);

        if (produkt.stanPrzed) {
            if (initShowNetto) {
                addValueIfNotEmpty(`${rowPrefix}[${index}].P_9A`, produkt.cenaJednostkowaNetto);
                addValueIfNotEmpty(`${rowPrefix}[${index}].P_11`, produkt.wartoscSprzedazyNetto);
            }
            else {
                addValueIfNotEmpty(`${rowPrefix}[${index}].P_9B`, produkt.cenaJednostkowaBrutto);
                addValueIfNotEmpty(`${rowPrefix}[${index}].P_11A`, produkt.wartoscSprzedazyBrutto);
            }
        }
        else {
            if (showNetto) {
                addValueIfNotEmpty(`${rowPrefix}[${index}].P_9A`, produkt.cenaJednostkowaNetto);
                addValueIfNotEmpty(`${rowPrefix}[${index}].P_11`, produkt.wartoscSprzedazyNetto);
            }
            else {
                addValueIfNotEmpty(`${rowPrefix}[${index}].P_9B`, produkt.cenaJednostkowaBrutto);
                addValueIfNotEmpty(`${rowPrefix}[${index}].P_11A`, produkt.wartoscSprzedazyBrutto);
            }
        }
    });

    return values;
}

export const getSequenceFields = (values: { [key: string]: string }, fieldsVisiblityMap: { [key: string]: { isVisible: boolean, field: FormField }; }): { [key: string]: string } => {
    const tokenPrefixes = Object.keys(fieldsVisiblityMap) // seq id is prefix of token inside sequence
        .filter(x => x.startsWith("seq"))
        .map(x => fieldsVisiblityMap[x])
        .filter(x => x.isVisible)
        .map(x => x.field.Identifier)

    let ret: { [key: string]: string } = {}

    tokenPrefixes.forEach(prefix => {
        const toAdd = Object.keys(values).filter(x => x.startsWith(prefix + "["))
        toAdd.forEach(x => ret[x] = values[x])
    })

    return ret
}

const stringToBool = (s: string | boolean): boolean => {
    if (typeof s === "boolean") return s;
    if (!s)
        return false;

    if (s === "1")
        return true;

    // @ts-ignore
    if (s === true)
        return true;

    return JSON.parse(s.toLowerCase());
}

const stringToNumber = (s: string) => {
    return s ? parseFloat(s) : 0;
}

const rowPrefix = "Fa.FaWiersz"

export const getInvoiceItems = (values: { [key: string]: string }): Fa1InvoiceItem[] => {
    let items: Fa1InvoiceItem[] = [];
    let itemIndex = 0;

    while (values[`${rowPrefix}[${itemIndex}].NrWierszaFa`] !== undefined) {
        const P_6Astr = values[`${rowPrefix}[${itemIndex}].P_6A`];
        const item = new Fa1InvoiceItem(
            {
                ID: itemIndex.toString(),
                lp: parseInt(values[`${rowPrefix}[${itemIndex}].NrWierszaFa`]),
                nazwaTowaruLubUslugi: values[`${rowPrefix}[${itemIndex}].P_7`],
                ilosc: parseFloat(values[`${rowPrefix}[${itemIndex}].P_8B`]),
                miara: values[`${rowPrefix}[${itemIndex}].P_8A`],
                cenaJednostkowaNetto: parseFloat(values[`${rowPrefix}[${itemIndex}].P_9A`]),
                cenaJednostkowaBrutto: parseFloat(values[`${rowPrefix}[${itemIndex}].P_9B`]),
                rabatOpust: parseFloat(values[`${rowPrefix}[${itemIndex}].P_10`]),
                wartoscSprzedazyNetto: parseFloat(values[`${rowPrefix}[${itemIndex}].P_11`]),
                wartoscSprzedazyBrutto: parseFloat(values[`${rowPrefix}[${itemIndex}].P_11A`]),
                kwotaPodatku: stringToNumber(values[`${rowPrefix}[${itemIndex}].P_11Vat`]), 
                stawkaPodatku: changeTokenToTaxRate(values[`${rowPrefix}[${itemIndex}].P_12`]),
                stawkaPodatkuXII: values[`${rowPrefix}[${itemIndex}].P_12_XII`],
                indeks: values[`${rowPrefix}[${itemIndex}].Indeks`],
                towarUsługaZal15: stringToBool(values[`${rowPrefix}[${itemIndex}].P_12_Zal_15`]),
                procedura: values[`${rowPrefix}[${itemIndex}].P_12_Procedura`],
                GTU: values[`${rowPrefix}[${itemIndex}].GTU`],
                PKWiU: values[`${rowPrefix}[${itemIndex}].PKWiU`],
                GTIN: values[`${rowPrefix}[${itemIndex}].GTIN`],
                CN: values[`${rowPrefix}[${itemIndex}].CN`],
                kwotaPodatkuAkcyzowego: stringToNumber(values[`${rowPrefix}[${itemIndex}].KwotaAkcyzy`]),
                PKOB: values[`${rowPrefix}[${itemIndex}].PKOB`],
                kursWaluty: stringToNumber(values[`${rowPrefix}[${itemIndex}].KursWaluty`]),
                stanPrzed: stringToBool(values[`${rowPrefix}[${itemIndex}].StanPrzed`]),
                P_6A: P_6Astr === undefined ? null : new Date(P_6Astr)
            }
        );

        const useNetto = values["_kefiks.nettobrutto"] !== undefined && values["_kefiks.nettobrutto"] !== null && values["_kefiks.nettobrutto"] === "netto"

        item.Calculate(useNetto, false);
        items.push(item);
        itemIndex++;
    }

    return items;
}