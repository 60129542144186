import { useEffect, useState } from "react"
import GenericToolbar from '../settings/Toolbars/GenericToolbar';
import { ToolbarItem } from 'devextreme-react/popup';
import useDataGridFilter from 'utils/useDataGridFilter'
import { AddAttachmentsPopup, AttachmentsGrid, deleteAttachment, getAttachment, createArchive, attachmentsPopupData } from './AttachmentsTools';
import { useUserSettingsContext } from "../../contexts/UserSettingsProvider";
import { OdataEndpoints } from 'api/XfXApi';
import { GetOdataDataSource } from 'utils/dataSourceHelper'
import { useTranslation } from 'react-i18next';
import GenericPopup from "components/popup/GenericPopup";

export const AttachmentsPopup = ({ invoice, invoiceId, setAttachmentsVisible, attachmentsVisible, setBlockAutoRefresh }) => {
  const { t } = useTranslation()
  const [selectedItem, setSelectedItem] = useState(null)
  const [selectedIds, setSelectedIds] = useState(null)
  const [addAttachmentsVisible, setAddAttachmentsVisible] = useState(false)
  const [createArchiveDisabled, setCreateArchiveDisabled] = useState(true)
  const initialFilter = [['InvoiceId', '=', invoiceId]]
  const { dataGridFilter, updateDataGridFilter } = useDataGridFilter({ initialFilter })

  const { getOrganizationContextSettings } = useUserSettingsContext()
  const organizationId = getOrganizationContextSettings()?.NIP

  const dataSourceOptions =
    GetOdataDataSource(OdataEndpoints.Attachments(organizationId),
      ['Id', ...attachmentsPopupData({ t }).map(x => x.dataField).filter(x => x !== "")], null)

  const toolbarButtons = [
    { icon: 'download', text: t("#_attachmentspopup_1"), disabled: createArchiveDisabled, onClick: async () => createArchive({ ids: selectedIds, t }) },
    { icon: 'file', text: t("#_attachmentspopup_2"), disabled: selectedItem === null, onClick: async () => getAttachment({ id: selectedItem?.Id, t }) },
    { icon: 'add', text: t("#_attachmentspopup_3"), onClick: async () => setAddAttachmentsVisible(true) },
    { icon: 'trash', text: t("#_attachmentspopup_4"), disabled: selectedItem === null, onClick: async () => deleteAttachment({ id: selectedItem?.Id, dataSourceOptions: dataSourceOptions, setSelectedItem, t }) },
  ]

  const popup = GenericPopup({ 
    onHiding:() => popup.close(),
    content:
    <div>
      <div id="dataGrid-attachments" className="dataGrid">
        <GenericToolbar className="xfx-toolbar-bigbuttons" buttons={toolbarButtons} showIcon={true} icon='dx-icon-textdocument' showSimpleHeader={true}></GenericToolbar>
        {AttachmentsGrid(dataSourceOptions, setSelectedItem, dataGridFilter, setSelectedIds, setCreateArchiveDisabled)}
      </div>
    </div>,
    toolbarItems: [<ToolbarItem
      widget="dxButton"
      toolbar="bottom"
      location="after"
      options={{
        text: t("#_attachmentspopup_5"),
        onClick: () => {
          popup.close()
        }
      }} />],
    title: "",
    width: "812",
    height: "85%",
    showTitle: false,
    isVisible: attachmentsVisible,
    setIsVisible: setAttachmentsVisible,
    setBlockAutoRefresh: setBlockAutoRefresh
  })

  useEffect(() => {
    if (attachmentsVisible)
      popup.show()
    else
      popup.close()
  }, [attachmentsVisible])

  return (
    <>
      {popup.popup}
      {addAttachmentsVisible && 
        <AddAttachmentsPopup
          invoiceId={invoiceId} 
          setAddAttachmentsVisible={setAddAttachmentsVisible}
          addAttachmentsVisible={addAttachmentsVisible} 
          dataSourceOptions={dataSourceOptions}>
        </AddAttachmentsPopup>
      }
    </>
  );
}

export default AttachmentsPopup 
