import { useEffect } from 'react';
import { useUserSettingsContext } from '../contexts/UserSettingsProvider';

const useDataSourceAutoReload = ({ dataSource, onDataReload, blockAutoRefresh }) => {
    const { getAutoRefreshSettings } = useUserSettingsContext()
    const autoRefreshSettings = getAutoRefreshSettings()

    useEffect(() => {
        if(autoRefreshSettings?.Enabled)
        {
            const interval = setInterval(() => {
                if (dataSource?.reload && !blockAutoRefresh)
                {
                    dataSource?.reload()
                    .then(x => {
                        if (onDataReload && dataSource?.items)
                            onDataReload(dataSource?.items())
                    })
                }
            }, autoRefreshSettings?.ReloadIntervalMs)
            return () => {
                clearInterval(interval)
            }
        }
    }, [dataSource, onDataReload, autoRefreshSettings]);
}


export default useDataSourceAutoReload
