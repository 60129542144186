export const invoiceViewsIds = [
	'BatchPackagesSales',
	'BatchPackagesSelfInvoicing',
	'DispatchSaleAcceptance',
	'DispatchSaleBatchToSend',
	'DispatchSaleFailed',
	'DispatchSaleProcessing',
	'DispatchSaleProcessingOffline',
	'DispatchSelfInvoicingAcceptance',
	'DispatchSelfInvoicingBatchToSend',
	'DispatchSelfInvoicingFailed',
	'DispatchSelfInvoicingProcessing',
	'DispatchSelfInvoicingProcessingOffline',
	'InvoiceDraft',
	'ReceptionOtherProcessing',
	'ReceptionPurchaseAlert',
	'ReceptionPurchaseP3Alert',
	'ReceptionPurchaseP3Processing',
	'ReceptionPurchaseP3Revoked',
	'ReceptionPurchaseProcessing',
	'ReceptionPurchaseRevoked',
	'ReceptionRepresentationProcessing',
	'ReceptionSaleProcessing',
	'ReceptionSelfInvoicingAlert',
	'ReceptionSelfInvoicingRevoked',
	'ReceptionSelfInvoicingSale',
	'RepositoryOther',
	'RepositoryPurchase',
	'RepositorySales',
]
export const invoicesMenu = 
[
  {
    "text": "menu/drafts",
    "icon": "folderPlus",
    "path": "/drafts",
    "requiredPrivilage": [
      "dispatch_invoice_draft"
    ],
    "id": "InvoiceDraft",
    "worksInOrganizationContext": true
  },
  {
    "text": "menu/dispatch",
    "items": [
      {
        "text": "menu/dispatch/sale",
        "items": [
          {
            "text": "menu/dispatch/sale/processing",
            "path": "/dispatch-sale-processing",
            "requiredPrivilage": [
              "dispatch_sales_processing"
            ],
            "id": "DispatchSaleProcessing",
            "worksInOrganizationContext": true
          },
          {
            "text": "menu/dispatch/sale/processing-offline",
            "path": "/dispatch-sale-processing-offline",
            "requiredPrivilage": [
              "dispatch_sales_processing"
            ],
            "id": "DispatchSaleProcessingOffline",
            "worksInOrganizationContext": true
          },
          {
            "text": "menu/dispatch/sale/acceptance",
            "path": "/dispatch-sale-acceptance",
            "requiredPrivilage": [
              "dispatch_sales_acceptance"
            ],
            "id": "DispatchSaleAcceptance",
            "worksInOrganizationContext": true
          },
          {
            "text": "menu/dispatch/sale/create-batch-package",
            "path": "/dispatch-sale-create-batch-package",
            "requiredPrivilage": [
              "dispatch_sales_batchsend"
            ],
            "id": "DispatchSaleBatchToSend",
            "worksInOrganizationContext": true
          },
          {
            "text": "menu/dispatch/sale/batch-packages",
            "path": "/dispatch-sale-batch-packages",
            "requiredPrivilage": [
              "dispatch_sales_batchsend"
            ],
            "id": "BatchPackagesSales",
            "worksInOrganizationContext": true
          }
        ]
      },
      {
        "text": "menu/dispatch/selfinvoicing",
        "items": [
          {
            "text": "menu/dispatch/selfinvoicing/processing",
            "path": "/dispatch-selfinvoicing-processing",
            "requiredPrivilage": [
              "dispatch_selfinvoicing_processing"
            ],
            "id": "DispatchSelfInvoicingProcessing",
            "worksInOrganizationContext": true
          },
          {
            "text": "menu/dispatch/selfinvoicing/processing-offline",
            "path": "/dispatch-selfinvoicing-processing-offline",
            "requiredPrivilage": [
              "dispatch_selfinvoicing_processing"
            ],
            "id": "DispatchSelfInvoicingProcessingOffline",
            "worksInOrganizationContext": true
          },
          {
            "text": "menu/dispatch/selfinvoicing/acceptance",
            "path": "/dispatch-selfinvoicing-acceptance",
            "requiredPrivilage": [
              "dispatch_selfinvoicing_acceptance"
            ],
            "id": "DispatchSelfInvoicingAcceptance",
            "worksInOrganizationContext": true
          },
          {
            "text": "menu/dispatch/selfinvoicing/create-batch-package2",
            "path": "/dispatch-selfinvoicing-create-batch-package2",
            "requiredPrivilage": [
              "dispatch_selfinvoicing_batchsend"
            ],
            "id": "DispatchSelfInvoicingBatchToSend",
            "worksInOrganizationContext": true
          },
          {
            "text": "menu/dispatch/selfinvoicing/batch-packages2",
            "path": "/dispatch-selfinvoicing-batch-packages2",
            "requiredPrivilage": [
              "dispatch_selfinvoicing_batchsend"
            ],
            "id": "BatchPackagesSelfInvoicing",
            "worksInOrganizationContext": true
          }
        ]
      }
    ],
    "icon": "UploadCustom"
  },
  {
    "text": "menu/reception",
    "items": [
      {
        "text": "menu/reception/purchase",
        "items": [
          {
            "text": "menu/reception/purchase/processing",
            "path": "/reception-purchase-processing",
            "requiredPrivilage": [
              "reception_purchaser_processing"
            ],
            "id": "ReceptionPurchaseProcessing",
            "worksInOrganizationContext": true
          },
          {
            "text": "menu/reception/purchase/alert",
            "path": "/reception-purchase-alert",
            "requiredPrivilage": [
              "reception_purchaser_acceptance"
            ],
            "id": "ReceptionPurchaseAlert",
            "worksInOrganizationContext": true
          }
        ]
      },
      {
        "text": "menu/reception/purchasep3",
        "items": [
          {
            "text": "menu/reception/purchasep3/processing",
            "path": "/reception-purchasep3-processing",
            "requiredPrivilage": [
              "reception_additional_buyer_processing"
            ],
            "id": "ReceptionPurchaseP3Processing",
            "worksInOrganizationContext": true
          },
          {
            "text": "menu/reception/purchasep3/alert",
            "path": "/reception-purchasep3-alert",
            "requiredPrivilage": [
              "reception_additional_buyer_acceptance"
            ],
            "id": "ReceptionPurchaseP3Alert",
            "worksInOrganizationContext": true
          }
        ]
      },
      {
        "text": "menu/reception/selfinvoicing",
        "items": [
          {
            "text": "menu/reception/selfinvoicing/sale",
            "path": "/reception-selfinvoicing-sale",
            "requiredPrivilage": [
              "reception_selfinvoicing_processing"
            ],
            "id": "ReceptionSelfInvoicingSale",
            "worksInOrganizationContext": true
          },
          {
            "text": "menu/reception/selfinvoicing/alert",
            "path": "/reception-selfinvoicing-alert",
            "requiredPrivilage": [
              "reception_selfinvoicing_acceptance"
            ],
            "id": "ReceptionSelfInvoicingAlert",
            "worksInOrganizationContext": true
          }
        ]
      },
      {
        "text": "menu/reception/representation",
        "items": [
          {
            "text": "menu/reception/representation/processing",
            "path": "/reception-representation-processing",
            "requiredPrivilage": [
              "reception_ext_issuer_processing"
            ],
            "id": "ReceptionRepresentationProcessing",
            "worksInOrganizationContext": true
          }
        ]
      },
      {
        "text": "menu/reception/sale",
        "items": [
          {
            "text": "menu/reception/sale/processing",
            "path": "/reception-sale-processing",
            "requiredPrivilage": [
              "reception_ext_sales_processing"
            ],
            "id": "ReceptionSaleProcessing",
            "worksInOrganizationContext": true
          }
        ]
      },
      {
        "text": "menu/reception/other",
        "items": [
          {
            "text": "menu/reception/other/processing",
            "path": "/reception-other-processing",
            "requiredPrivilage": [
              "reception_other_processing"
            ],
            "id": "ReceptionOtherProcessing",
            "worksInOrganizationContext": true
          }
        ]
      }
    ],
    "icon": "DownloadCustom"
  },
  {
    "text": "menu/repository",
    "items": [
      {
        "text": "menu/repository/sales",
        "path": "/repository-sales",
        "requiredPrivilage": [
          "sales"
        ],
        "id": "RepositorySales",
        "worksInOrganizationContext": true
      },
      {
        "text": "menu/repository/purchase",
        "path": "/repository-purchase",
        "requiredPrivilage": [
          "purchaser"
        ],
        "id": "RepositoryPurchase",
        "worksInOrganizationContext": true
      },
      {
        "text": "menu/repository/other",
        "path": "/repository-other",
        "requiredPrivilage": [
          "other"
        ],
        "id": "RepositoryOther",
        "worksInOrganizationContext": true
      },
      {
        "text": "menu/repository/dispatcherrors",
        "items": [
          {
            "text": "menu/repository/dispatcherrors/failed",
            "path": "/repository-dispatcherrors-failed",
            "requiredPrivilage": [
              "dispatch_sales_failed"
            ],
            "id": "DispatchSaleFailed",
            "worksInOrganizationContext": true
          },
          {
            "text": "menu/repository/dispatcherrors/selfinvoicing",
            "path": "/repository-dispatcherrors-selfinvoicing",
            "requiredPrivilage": [
              "dispatch_selfinvoicing_failed"
            ],
            "id": "DispatchSelfInvoicingFailed",
            "worksInOrganizationContext": true
          }
        ]
      },
      {
        "text": "menu/repository/receptionerrors",
        "items": [
          {
            "text": "menu/repository/receptionerrors/revoked",
            "path": "/repository-receptionerrors-revoked",
            "requiredPrivilage": [
              "reception_purchaser_failed"
            ],
            "id": "ReceptionPurchaseRevoked",
            "worksInOrganizationContext": true
          },
          {
            "text": "menu/repository/receptionerrors/failed",
            "path": "/repository-receptionerrors-failed",
            "requiredPrivilage": [
              "reception_additional_buyer_failed"
            ],
            "id": "ReceptionPurchaseP3Revoked",
            "worksInOrganizationContext": true
          },
          {
            "text": "menu/repository/receptionerrors/selfinvoicing",
            "path": "/repository-receptionerrors-selfinvoicing",
            "requiredPrivilage": [
              "reception_selfinvoicing_failed"
            ],
            "id": "ReceptionSelfInvoicingRevoked",
            "worksInOrganizationContext": true
          }
        ]
      }
    ],
    "icon": "ArchiveCustom"
  }
]