import React, { useEffect, useRef, useCallback, useMemo, useContext, useState } from 'react';
import TreeView from 'devextreme-react/tree-view';
import { navigation } from '../../app-navigation';
import { useNavigation } from '../../contexts/navigation';
import { useScreenSize } from '../../utils/media-query';
import './side-navigation-menu.scss';
import { useAuth } from 'contexts/auth'
import { useTranslation } from 'react-i18next';
import { useUserSettingsContext } from 'contexts/UserSettingsProvider';

import * as events from 'devextreme/events';

const SideNavigationMenu = (props) => {
  const {
    children,
    selectedItemChanged,
    openMenu,
    compactMode,
    onMenuReady
  } = props;

  const { t } = useTranslation()
  const [menuItems, setMenuItems] = useState()
  const { hasAnyPrivilege, disabledViews, hasFeature, isAdmin } = useAuth()
  const [expandedNodeText, setExpandedNodeText] = useState("");
  const [selected, setSelected] = useState(null);
  const [selectedFirstLvl, setSelectedFirstLvl] = useState(null);
  const { getOrganizationContextSettings } = useUserSettingsContext()

  const organizationId = getOrganizationContextSettings()?.NIP

  const topLevelItemClicked = (e) => {
    const clickedItem = e.itemData;
    if(clickedItem.path !== undefined){
      selectedItemChanged(e)
      return
    }
    const treeView = treeViewRef.current && treeViewRef.current.instance;
    const onlyCollapse = expandedNodeText == clickedItem.text;
    treeView.collapseAll();
    if (!onlyCollapse) {
      let keysToExpand = [];
      if (clickedItem.items) {
        clickedItem.items.forEach(x => {
          if (x.items && x.items[0]) {
            keysToExpand.push(x.items[0].path);
          }
          else {
            keysToExpand.push(x.path);
          }
        }
        );
      }
      else {
        keysToExpand.push(clickedItem.path);
      }
      keysToExpand.forEach(k => treeView.expandItem(k));
    }
    setExpandedNodeText(onlyCollapse ? "" : clickedItem.text);
  };

  const setVisibility = (node) => {

    const isViewTurnedOn = node.id === undefined || !disabledViews?.includes(node.id)
    const items = node.items === undefined ? undefined : node.items.map(x => setVisibility(x))
    const isVisible = (node.requiredPrivilage === undefined || 
      (
        (node.worksInOrganizationContext !== undefined && node.worksInOrganizationContext === false && hasAnyPrivilege(node.requiredPrivilage))
        || ((node.worksInOrganizationContext === undefined || node.worksInOrganizationContext === true) && hasAnyPrivilege(node.requiredPrivilage, organizationId))
      )) 
      && (node.feature === undefined || hasFeature(node.feature))
      && (node.adminOnly === undefined || isAdmin)
    const childrenVisible = items === undefined || items.some(x => x.visible)
    const nodeHasPath = node.path !== undefined
    return { ...node, items: items, visible: isVisible && isViewTurnedOn && (childrenVisible || nodeHasPath) }
  }

  function prepareItems() {
    const itemsWithVisibility = navigation.map(x => setVisibility(x))
    var mappedItems = itemsWithVisibility.map((item) => {
        if (item.path && !(/^\//.test(item.path))) {
          item.path = `/${item.path}`;
        }

        item.level = 1;
        item.className = "item-first-level";

        const visibleSecondLvlElements = item.items?.filter(x => x.visible);
        if (visibleSecondLvlElements) {
          visibleSecondLvlElements.forEach((itemSecondLvl, secondLvlIterator) => {
            itemSecondLvl.className = "item-second-level"
            itemSecondLvl.level = 2;
            itemSecondLvl.firstLvlParent = item.text;

            if (secondLvlIterator === 0)
            {
              itemSecondLvl.className += " item-second-level-after-first-level"
            }
            else if (visibleSecondLvlElements[secondLvlIterator - 1].items == null || visibleSecondLvlElements[secondLvlIterator - 1].items.length === 0) 
            {
              itemSecondLvl.className += " item-second-level-prev-no-child"
            }
            else
            {
              itemSecondLvl.className += " item-second-level-standard"
            }

            if (itemSecondLvl.items && itemSecondLvl.items.length > 0)
            {
              itemSecondLvl.className += " item-second-level-with-children"
            }

            const visibleThirdLvlElements = itemSecondLvl.items?.filter(x => x.visible);
            if (visibleThirdLvlElements) {
              visibleThirdLvlElements.forEach((itemThirdLvl, thirdLvlIterator) => {
                itemThirdLvl.className = "item-third-level"
                itemThirdLvl.level = 3;
                itemThirdLvl.firstLvlParent = item.text;

                if (thirdLvlIterator === visibleThirdLvlElements.length - 1 && secondLvlIterator === visibleSecondLvlElements.length - 1)
                {
                  itemThirdLvl.className += " item-third-level-before-first-lvl"
                }
              });
            }
          });
        }
        return { ...item, expanded: false };
      });

      // purpose of this line is that when the scroll is at the bottom, no element is obscured by a shadow
      mappedItems.push({className: "empty-item"});

      return mappedItems;
  }

  function handleItems() {
    setMenuItems(prepareItems())
  }

  useEffect(() => {
    handleItems()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [disabledViews, organizationId])

  // let items = useMemo(
  //   prepareItems,
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  //   []
  // );

  const { navigationData: { currentPath } } = useNavigation();

  const treeViewRef = useRef();
  const wrapperRef = useRef();
  const getWrapperRef = useCallback((element) => {
    const prevElement = wrapperRef.current;
    if (prevElement) {
      events.off(prevElement, 'dxclick');
    }

    wrapperRef.current = element;
    events.on(element, 'dxclick', e => {
      openMenu(e);
    });
  }, [openMenu]);

  useEffect(() => {
    const treeView = treeViewRef.current && treeViewRef.current.instance;
    if (!treeView) {
      return;
    }

    if (currentPath !== undefined) {
      treeView.selectItem(currentPath);
      treeView.expandItem(currentPath);
    }

    if (compactMode) {
      treeView.collapseAll();
    }
  }, [currentPath, compactMode]);

  useEffect(() => {
    if (compactMode) {
      setExpandedNodeText("");
    }
  }, [compactMode]);

  const customItemRender = (item, index) => {
    if (item) {
      const className = item.className + (selected && item.text === selected ? " selected-item-xfx" : "") 
        + (selectedFirstLvl && item.text === selectedFirstLvl ? " selected-item-first-lvl-xfx" : "");
      return (
        <div className={className}>
          {(item.icon && item.level !== 2) &&
            <div className="menu-item-icon">
              <i className={`dx-icon dx-icon-${item.icon}`}></i>
            </div>
          }
          <div className="menu-item-text">
            <span>{t(item.text)}</span>
          </div>
          {(item.level === 1 && item.expanded && item.items?.length > 0) && <i className="dx-icon-chevron dx-icon-ChevronUp"></i>}
          {(item.level === 1 && !item.expanded && item.items?.length > 0) && <i className="dx-icon-chevron dx-icon-ChevronDown"></i>}
        </div>
      );
    }
  };

  return (
    <div
      className={'dx-swatch-additional side-navigation-menu'}
      ref={getWrapperRef}
    >
      <div className={'menu-container'}>
        <div style={{ display: 'flex', flex: 1, position: 'relative' }}>
          <TreeView
            ref={treeViewRef}
            items={menuItems}
            keyExpr={'path'}
            hoverStateEnabled={false}
            selectionMode={'single'}
            focusStateEnabled={false}
            itemRender={customItemRender}
            virtualModeEnabled={false}
            onItemClick={(e) => { 
              setSelectedFirstLvl(oldVal => e.itemData.path && e.itemData.level !== 1 ? e.itemData.firstLvlParent : (e.itemData.level === 1 && e.itemData.path ? "" : oldVal));
              setSelected(oldVal => !e.itemData.items || e.itemData.items.length === 0 ? e.itemData.text : oldVal); 
              e.itemData.level === 1 ? topLevelItemClicked(e) : selectedItemChanged(e) 
            }}
            onContentReady={onMenuReady}
            width={'100%'}
          />
          <div style={{
              position: 'absolute',
              bottom: '-10px',
              left: '0',
              right: '10px',
              height: '47px',
              // background: 'linear-gradient(180deg, rgba(10, 53, 72, 0) 0%, #0A3548 56.25%)', Commenting out this value since it is not important for us (otherwise change it as required). It disrupts vertical scrollbar
              pointerEvents: 'none',
              zIndex: 2,
              display: 'block'
          }} />
        </div>
      </div>
    </div>
  );
}


export default SideNavigationMenu