import { useEffect,useRef, useState, useCallback} from "react"
import RadioGroup from 'devextreme-react/radio-group'
import { Form, GroupItem, SimpleItem, Label, RequiredRule } from 'devextreme-react/form';
import PrivilegesDropDown from './PrivilegesDropDown'
import OrganizationsDropDown from  'components/dropdown/OrganizationsDropDown.tsx'
import ColumnsDropDown from './ColumnsDropDown'
import List from 'devextreme-react/list';
import DropDownButton from 'devextreme-react/drop-down-button';
import TextBox from 'devextreme-react/text-box'
import DataGrid, {
    Paging,
    FilterRow,
    Scrolling,
    Selection
} from 'devextreme-react/data-grid'; 
import CustomStore from 'devextreme/data/custom_store';
import { Button } from "devextreme-react";
import { SaleColumnsForRestrictions, PurchaseColumnsForRestrictions, OtherColumnsForRestrictions } from './ColumnsForRestrctions'
import { useTranslation } from 'react-i18next';
import DropDownBox from 'devextreme-react/drop-down-box';
import { OdataEndpoints } from 'api/XfXApi';
import { GetOdataDataSource } from 'utils/dataSourceHelper'

const labelMode = 'outside'
const labelLocation = 'top'
const readOnly = false
const showColonAfterLabel = false
const minColWidth = 300

const RolesEditor = ({
    state,
    setState,
    allSalesPrivileges,
    allPurchasePrivileges,
    allAdministrationPrivileges,
    usersValue,
    dataSource,
    setUsersValue,
    userColums,
    salesFkValue, 
    setSalesFkValue,
    purchaseFkValue, 
    setPurchaseFkValue,
    vgRolesForm,
    validationRolesFormGroup,
    allOrganizationsCustomStore,
    treeViewRefSaleColumnsForRestrictions,
    treeViewRefPurchaseColumnsForRestrictions,
    treeViewRefOtherColumnsForRestrictions,
    selfInvoiceSalesAccessLabels,
    selfInvoicePurchaseAccessLabels,
    whiteBlackLabels
}) => {
    const NULL_SYMBOL = "*"

    const { t } = useTranslation()
    const [saleColumnsForRestrictions, setSaleColumnsForRestrictions] = useState(SaleColumnsForRestrictions)
    const [purchaseColumnsForRestrictions, setPurchaseColumnsForRestrictions] = useState(PurchaseColumnsForRestrictions)
    const [otherColumnsForRestrictions, setOtherColumnsForRestrictions] = useState(OtherColumnsForRestrictions)
    const [availableSystems, setAvailableSystems] = useState()

    const gridColumns = t => [{ dataField: 'Name', caption: t("#_privilegesdropdown_1") }];

    const wrapNull = (v) => v === null ? NULL_SYMBOL : v

    const getAvailableSystems = async () => {
        const dataSource = GetOdataDataSource(OdataEndpoints.AccountingSystems(), null, ['Id', 'OrganizationIds', 'Name', 'Organizations'],
            null, null, null, null, null, false)
        
        await dataSource.load()
            .then((x) => {
                setAvailableSystems(makeAsyncDataSource(x))
            }).catch(e => {
                console.log(e)
            })
    }

    useEffect(() => {
        getAvailableSystems()
    }, [])

    function makeAsyncDataSource(data) {
        return new CustomStore({
          loadMode: 'raw',
          key: 'Name',
          load() {
            return data
          },
        });
      }

    const accountingSystemGridSales = useCallback(
        () => (
          <DataGrid
            height={345}
            dataSource={availableSystems}
            columns={gridColumns(t)}
            keyExpr="Name"
            hoverStateEnabled={true}
            selectedRowKeys={salesFkValue}
            onSelectionChanged={dataGridOnSelectionChangedSales}
          >
            <Selection mode="multiple" /> 
            <Scrolling mode="virtual" />
            <Paging
              enabled={true}
              pageSize={10}
            />
            <FilterRow visible={true} />
          </DataGrid>
        ),
        [availableSystems, salesFkValue],
      );

      const accountingSystemGrid = useCallback(
        () => (
          <DataGrid
            height={345}
            dataSource={availableSystems}
            columns={gridColumns(t)}
            keyExpr="Name"
            hoverStateEnabled={true}
            selectedRowKeys={purchaseFkValue}
            onSelectionChanged={dataGridOnSelectionChanged}
          >
            <Selection mode="multiple"/> 
            <Scrolling mode="virtual" />
            <Paging
              enabled={true}
              pageSize={10}
            />
            <FilterRow visible={true} />
          </DataGrid>
        ),
        [availableSystems, purchaseFkValue],
      );


      function syncDataGridSelection(e) {
        setPurchaseFkValue(e.value || []);
        state.purchaseAccountingSystems = e.value || []
      }

      function syncDataGridSelectionSales (e) {
        setSalesFkValue(e.value || []);
        state.saleAccountingSystems = e.value || []
      }

      function syncUsersSelection(e) {
        setUsersValue(e.value || [])
        state.usersIds = e.value || []
    }

    function dataGridOnSelectionChanged(e) {
        setPurchaseFkValue((e.selectedRowKeys.length && e.selectedRowKeys) || []);
        state.purchaseAccountingSystems = (e.selectedRowKeys.length && e.selectedRowKeys) || []

      }

      function dataGridOnSelectionChangedSales(e) {
        setSalesFkValue((e.selectedRowKeys.length && e.selectedRowKeys) || []);
        state.saleAccountingSystems = (e.selectedRowKeys.length && e.selectedRowKeys) || []

      }
 
    function usersOnSelectionChanged(e) {
        setUsersValue((e.selectedRowKeys.length && e.selectedRowKeys) || [])
        state.usersIds = (e.selectedRowKeys.length && e.selectedRowKeys) || []
    }

    function usersRender() {
        return (
            <DataGrid
                dataSource={dataSource}
                columns={userColums}
                hoverStateEnabled={true}
                selectedRowKeys={usersValue}
                onSelectionChanged={usersOnSelectionChanged}
                height="80%">
                <Selection
                    allowSelectAll={false}
                    deferred={false}
                    mode='multiple'
                    selectAllMode='allPages'
                    showCheckBoxesMode='always'
                />
                <Scrolling mode="virtual" preloadEnabled={true} />
                <Paging enabled={true} pageSize={100} />
                <FilterRow visible={true} />
            </DataGrid>
        );
    }

    

    const NipBox = (value, setValue) => {
        const ref = useRef()
        return <>
            <TextBox
                ref={ref}
                onEnterKey={(x) => {
                    if (!x.component._changedValue) return

                    const newValue = x.component._changedValue.trim()
                    if (newValue.length === 0) return

                    if (!value.includes(newValue)) {
                        setValue([...value, newValue])
                        ref.current.instance.reset()
                    }
                }}>
            </TextBox>
            <List
                dataSource={value}
                selectionMode="none"
                itemDeleteMode="static"
                allowItemDeleting={true}
                onItemDeleted={({ itemData }) => {
                    setValue(value.filter(x => x !== itemData))
                }}
            ></List>
        </>
    }

    const TagEditor = (name, mode, setMode, values, setValues) => <GroupItem visible={values !== null} itemType="group">
        <Label text={name} />
        <RadioGroup value={mode} onValueChange={setMode}
            items={whiteBlackLabels} layout="horizontal" />

        <Button text={t("#_roleseditor_12") + name} onClick={() => setValues(null)}></Button>
        {NipBox(values, setValues)}
    </GroupItem>

    const TagsEditor = (state, setState) => {
        return <GroupItem>
            <SimpleItem itemType="group">
                <Label text={t("#_roleseditor_14")}></Label>
            </SimpleItem>
            {TagEditor("Tag1", state.tag1Mode, e => { setState({ ...state, tag1Mode: e }) }, state.tag1Values, v => setState({ ...state, tag1Values: v }))}
            {TagEditor("Tag2", state.tag2Mode, e => { setState({ ...state, tag2Mode: e }) }, state.tag2Values, v => setState({ ...state, tag2Values: v }))}
            {TagEditor("Tag3", state.tag3Mode, e => { setState({ ...state, tag3Mode: e }) }, state.tag3Values, v => setState({ ...state, tag3Values: v }))}
            {TagEditor("Tag4", state.tag4Mode, e => { setState({ ...state, tag4Mode: e }) }, state.tag4Values, v => setState({ ...state, tag4Values: v }))}
            {TagEditor("Tag5", state.tag5Mode, e => { setState({ ...state, tag5Mode: e }) }, state.tag5Values, v => setState({ ...state, tag5Values: v }))}
            {TagEditor("Tag6", state.tag6Mode, e => { setState({ ...state, tag6Mode: e }) }, state.tag6Values, v => setState({ ...state, tag6Values: v }))}
            {TagEditor("Tag7", state.tag7Mode, e => { setState({ ...state, tag7Mode: e }) }, state.tag7Values, v => setState({ ...state, tag7Values: v }))}
            {TagEditor("Tag8", state.tag8Mode, e => { setState({ ...state, tag8Mode: e }) }, state.tag8Values, v => setState({ ...state, tag8Values: v }))}
            {TagEditor("Tag9", state.tag9Mode, e => { setState({ ...state, tag9Mode: e }) }, state.tag9Values, v => setState({ ...state, tag9Values: v }))}
            {TagEditor("Tag10", state.tag10Mode, e => { setState({ ...state, tag10Mode: e }) }, state.tag10Values, v => setState({ ...state, tag10Values: v }))}
            <SimpleItem visible={
                state.tag1Values === null ||
                state.tag2Values === null ||
                state.tag3Values === null ||
                state.tag4Values === null ||
                state.tag5Values === null ||
                state.tag6Values === null ||
                state.tag7Values === null ||
                state.tag8Values === null ||
                state.tag9Values === null ||
                state.tag10Values === null
            }>
                <Label text={t("#_roleseditor_25")} />
                <DropDownButton
                    splitButton={true}
                    useSelectMode={false}
                    text={t("#_roleseditor_26")}
                    items={[
                        { id: 1, name: "Tag 1", visible: state.tag1Values === null, onClick: () => setState({ ...state, tag1Values: [] }) },
                        { id: 2, name: "Tag 2", visible: state.tag2Values === null, onClick: () => setState({ ...state, tag2Values: [] }) },
                        { id: 3, name: "Tag 3", visible: state.tag3Values === null, onClick: () => setState({ ...state, tag3Values: [] }) },
                        { id: 4, name: "Tag 4", visible: state.tag4Values === null, onClick: () => setState({ ...state, tag4Values: [] }) },
                        { id: 5, name: "Tag 5", visible: state.tag5Values === null, onClick: () => setState({ ...state, tag5Values: [] }) },
                        { id: 6, name: "Tag 6", visible: state.tag6Values === null, onClick: () => setState({ ...state, tag6Values: [] }) },
                        { id: 7, name: "Tag 7", visible: state.tag7Values === null, onClick: () => setState({ ...state, tag7Values: [] }) },
                        { id: 8, name: "Tag 8", visible: state.tag8Values === null, onClick: () => setState({ ...state, tag8Values: [] }) },
                        { id: 9, name: "Tag 9", visible: state.tag9Values === null, onClick: () => setState({ ...state, tag9Values: [] }) },
                        { id: 10, name: "Tag 10", visible: state.tag10Values === null, onClick: () => setState({ ...state, tag10Values: [] }) },
                    ]}
                    displayExpr="name"
                    keyExpr="id"
                    onItemClick={({ itemData }) => {
                        itemData.onClick()
                    }}
                />
            </SimpleItem>
        </GroupItem >
    }

    return <> <Form
        id="form"
        ref={vgRolesForm}
        validationGroup={validationRolesFormGroup}
        labelMode={labelMode}
        readOnly={readOnly}
        showColonAfterLabel={showColonAfterLabel}
        labelLocation={labelLocation}
        minColWidth={minColWidth}
        formData={state}
        className={"xfx-form"}>
        <GroupItem caption={t("#_roleseditor_38")}>
            <SimpleItem dataField="name" editorType="dxTextBox" value={state.name}>
                <Label text={t("#_roleseditor_39")} />
                <RequiredRule message={t("#_roleseditor_40")} />
            </SimpleItem>
            <SimpleItem dataField="description" editorType="dxTextBox" value={state.description}>
                <Label text={t("#_roleseditor_42")} />
            </SimpleItem>


            <SimpleItem dataField="description" editorType="dxDropDownBox">
                <Label text={t("#_roleseditor_44")} />
                <DropDownBox
                    value={usersValue}
                    valueExpr="id"
                    deferRendering={false}
                    displayExpr="login"
                    placeholder={t("#_roleseditor_46")}
                    showClearButton={true}
                    dataSource={dataSource}
                    onValueChanged={syncUsersSelection}
                    contentRender={usersRender}
                    label={t("#_roleseditor_47")}
                    labelMode="hidden"
                />
            </SimpleItem>
        </GroupItem>

        <GroupItem caption={t("#_roleseditor_49")}>
            <SimpleItem editorType="dxDropDownBox">
                <Label text={t("#_roleseditor_50")} />
                <PrivilegesDropDown
                    value={state.selectedSalesPrivileges}
                    setValue={x => setState({ ...state, selectedSalesPrivileges: x })}
                    allPrivileges={allSalesPrivileges}
                    t={t}
                ></PrivilegesDropDown>
            </SimpleItem>

            <SimpleItem editorType="dxDropDownBox">
                <Label text={t("#_roleseditor_51")} />
                <PrivilegesDropDown
                    value={state.selectedPurchasePrivileges}
                    setValue={x => setState({ ...state, selectedPurchasePrivileges: x })}
                    allPrivileges={allPurchasePrivileges}
                    t={t}
                ></PrivilegesDropDown>
            </SimpleItem>

            <SimpleItem editorType="dxDropDownBox">
                <Label text={t("#_roleseditor_52")} />
                <PrivilegesDropDown
                    value={state.selectedAdministrationPrivileges}
                    setValue={x => setState({ ...state, selectedAdministrationPrivileges: x })}
                    allPrivileges={allAdministrationPrivileges}
                    t={t}
                ></PrivilegesDropDown>
            </SimpleItem>
        </GroupItem>


        <GroupItem caption={t("#_roleseditor_53")}>
            <SimpleItem itemType="group">
                <Label text={t("#_roleseditor_84")}></Label>
            </SimpleItem>
            <SimpleItem>
                <RadioGroup value={state.organizationListMode}
                    onValueChange={e => setState({ ...state, organizationListMode: e })}
                    items={whiteBlackLabels} layout="horizontal" />
            </SimpleItem>

            <SimpleItem>
                <Label text={t("#_roleseditor_70")} />
                <OrganizationsDropDown
                    value={state.organizationsList}
                    setValue={v => setState({ ...state, organizationsList: v })}
                    allOrganizations={allOrganizationsCustomStore}
                    t={t}
                ></OrganizationsDropDown>
            </SimpleItem>
        </GroupItem>

        <GroupItem caption={t("#_roleseditor_57")}>
            <SimpleItem itemType="group">
                <Label text={t("#_roleseditor_59")} itemType="empty"></Label>
            </SimpleItem>

            <SimpleItem itemType="group">
                <Label text={t("#_roleseditor_62")}></Label>
            </SimpleItem>


            <SimpleItem itemType="group">
                <RadioGroup value={state.saleNipsListMode} onValueChange={e => {
                    setState({ ...state, saleNipsListMode: e })
                }}
                    items={whiteBlackLabels} layout="horizontal" />
            </SimpleItem>

            <SimpleItem>
                <Label text={t("#_roleseditor_65")} />
                {NipBox(state.saleNipsList, v => { setState({ ...state, saleNipsList: v }) })}
            </SimpleItem>
            {TagsEditor(state.saleTags, (v) => { setState({ ...state, saleTags: v }) })}
        </GroupItem>


        <GroupItem caption={t("#_roleseditor_66")}>
            <SimpleItem itemType="group">
                <Label text={t("#_roleseditor_68")}></Label>
            </SimpleItem>

            <SimpleItem itemType="group">
                <Label text={t("#_roleseditor_70")}></Label>
            </SimpleItem>


            <SimpleItem itemType="group">
                <RadioGroup value={state.purchaseNipsListMode}
                    onValueChange={e => setState({ ...state, purchaseNipsListMode: e })}
                    items={whiteBlackLabels} layout="horizontal" />
            </SimpleItem>

            <SimpleItem>
                <Label text={t("#_roleseditor_73")} />
                {NipBox(state.purchaseNipsList, v => setState({ ...state, purchaseNipsList: v }))}
            </SimpleItem>
            {TagsEditor(state.purchaseTags, (v) => { setState({ ...state, purchaseTags: v }) })}
   </GroupItem>

   <GroupItem caption={t("#_roleseditor_60")}>
        
            <SimpleItem itemType="group">
                <Label text={t("#_roleseditor_78")} itemType="empty"></Label>
            </SimpleItem>

            <SimpleItem itemType="group">
                <Label text={t("#_roleseditor_70")}></Label>
            </SimpleItem>


            <SimpleItem itemType="group">
                <RadioGroup value={state.saleAccountingSystemsMode} onValueChange={e => {
                    setState({ ...state, saleAccountingSystemsMode: e })
                }}
                    items={whiteBlackLabels} layout="horizontal" />
            </SimpleItem>
            <SimpleItem itemType="group">   
                <div className="dx-field">
                    <div className="dx-field-value">
                        <DropDownBox
                            value={state.saleAccountingSystems}
                            valueExpr="Name"
                            deferRendering={false} 
                            displayExpr="Name"
                            placeholder="SYSTEM FK..."
                            showClearButton={true}
                            dataSource={availableSystems}
                            onValueChanged={syncDataGridSelectionSales}
                            contentRender={accountingSystemGridSales}
                        /> 
                    </div>
                </div>
            </SimpleItem> 
       
            <SimpleItem itemType="group">
                <Label text={t("#_roleseditor_80")}></Label>
            </SimpleItem>

            <SimpleItem itemType="group">
                <Label text={t("#_roleseditor_70")}></Label>
            </SimpleItem>

            <SimpleItem itemType="group">
                <RadioGroup value={state.purchaseAccountingSystemsMode}
                    onValueChange={e => setState({ ...state, purchaseAccountingSystemsMode: e })}
                    items={whiteBlackLabels} layout="horizontal" />
            </SimpleItem>

            <SimpleItem>
                <div className="dx-field">
                        <div className="dx-field-value">
                        <DropDownBox
                                value={state.purchaseAccountingSystems}
                                valueExpr="Name"
                                deferRendering={false} 
                                displayExpr="Name"
                                placeholder="System FK..."
                                showClearButton={true}
                                dataSource={availableSystems}
                                onValueChanged={syncDataGridSelection}
                                contentRender={accountingSystemGrid}
                            /> 
                        </div>
                    </div>
            </SimpleItem>
        </GroupItem>

        <GroupItem caption={t("#_roleseditor_74")}>
            <SimpleItem itemType="group">
                <Label text={t("#_roleseditor_76")}></Label>
            </SimpleItem>
            <SimpleItem itemType="group">
                <Label text={t("#_roleseditor_78")}></Label>
                <ColumnsDropDown 
                    value={state.forbiddenSaleColumns} 
                    setValue={(x) => { setState({ ...state, forbiddenSaleColumns: x })}} 
                    columns={saleColumnsForRestrictions} 
                    treeViewRef={treeViewRefSaleColumnsForRestrictions}>   
                </ColumnsDropDown>
            </SimpleItem>

            <SimpleItem itemType="group">
                <Label text={t("#_roleseditor_80")}></Label>
                <ColumnsDropDown 
                    value={state.forbiddenPurchaseColumns} 
                    setValue={(x) => { setState({ ...state, forbiddenPurchaseColumns: x })}}
                    columns={purchaseColumnsForRestrictions}
                    treeViewRef={treeViewRefPurchaseColumnsForRestrictions}>
                </ColumnsDropDown>
            </SimpleItem>

            <SimpleItem itemType="group">
                <Label text={t("#_roleseditor_82")}></Label>
                <ColumnsDropDown 
                    value={state.forbiddenOtherColumns} 
                    setValue={(x) => { setState({ ...state, forbiddenOtherColumns: x })}} 
                    columns={otherColumnsForRestrictions} 
                    treeViewRef={treeViewRefOtherColumnsForRestrictions}>
                </ColumnsDropDown>
            </SimpleItem>
        </GroupItem>

        <GroupItem caption={t("#_roleseditor_85")}>
            <SimpleItem itemType="group">
                <Label text={t("#_roleseditor_86")}></Label>
                <RadioGroup value={state.salesSelfInvoicingVisibility}
                    onValueChange={e => setState({ ...state, salesSelfInvoicingVisibility: e })}
                    items={selfInvoiceSalesAccessLabels} layout="horizontal" />
            </SimpleItem>                    

            <SimpleItem itemType="group">
                <Label text={t("#_roleseditor_87")}></Label>
                <RadioGroup value={state.purchaseSelfInvoicingVisibility}
                    onValueChange={e => setState({ ...state, purchaseSelfInvoicingVisibility: e })}
                    items={selfInvoicePurchaseAccessLabels} layout="horizontal" />
            </SimpleItem>
        </GroupItem>
    </Form></>
}

export default RolesEditor
