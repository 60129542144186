import { useEffect, useState, useRef } from "react"
import 'devextreme-react/autocomplete';
import { Form, SimpleItem, EmailRule, RequiredRule, Label, GroupItem } from 'devextreme-react/form';
import GenericToolbar from '../Toolbars/GenericToolbar'
import BreadCrumbs from 'components/breadCrumbs/BreadCrumbs';
import { getNode } from 'components/breadCrumbs/getNode';
import EffectivePrivileges from '../Roles/EffectivePrivileges'
import {
  userInit, labelMode, labelLocation, showColonAfterLabel, minColWidth,
  colCount, editPopupTitle, validationFirstFormGroup, validationSecondFormGroup,
  closeButtonOptions, saveButtonOptions, columns
} from "./const";
import { useTranslation } from 'react-i18next';
import { commonPopupToolbarItems } from 'components/popup/PopupTools';
import GenericPopup from "components/popup/GenericPopup";
import DeletionPopup from 'components/popup/DeletionPopup';
import { XfXApi } from 'api/XfXApi';
import { toolbarButtons } from "./toolbarButtons";
import {
  fetchPriviliges, fetchRoles, formFieldDataChanged, onUserTypeChanged, onIsAdminChanged, RolesDropDown,
} from "./actions";
import GenericOdataGrid from 'pages/Invoices/GenericOdataGrid';
import { OdataEndpoints } from 'api/XfXApi';
import useDataGridFilter from 'utils/useDataGridFilter'
import notify from "devextreme/ui/notify"

const Users = () => {
  const { t } = useTranslation()
  const [roles, setRoles] = useState([])
  const [isUserNatural, setIsUserNatural] = useState(true)
  const [user, setUser] = useState(userInit)

  const [rolesValue, setRolesValue] = useState([])
  const [visible, setVisible] = useState(false)
  const [typesEditorOptions, setTypesEditorOptions] = useState()
  const [popupTitle, setPopupTitle] = useState(editPopupTitle)
  const [userEdit, setUserEdit] = useState(false)
  const [isAdmin, setIsAdmin] = useState(false)

  const [deletionPopupVisible, setDeletionPopupVisible] = useState(false)
  const [deletionPopupContent, setDeletionPopupContent] = useState("")

  const [data, setData] = useState([])
  const [selectedItem, setSelectedItem] = useState(null)
  const [reloadKey, setReloadKey] = useState(0)
  const [dataSourceOptions, setDataSourceOptions] = useState()

  const initialFilter = []
  const { dataGridFilter, updateDataGridFilter } = useDataGridFilter({ initialFilter })
  const defaultSort = { selector: "Login", desc: false }

  const [blockAutoRefresh, setBlockAutoRefresh] = useState(false)

  const deletionPopup = DeletionPopup({
    onConfirm: async () => {
      try {
        const response = await XfXApi.User.apiTenantIdUserDelete(XfXApi.GetTenantId(), selectedItem.Id?._value ?? selectedItem?.Id)
    
        if (response.status === 200) {
          notify({
            message: t("#_actions_27"),
            position: {
              my: 'center bottom',
              at: 'center bottom',
            },
          }, 'success', 5000);
        }
      } catch (error) {
        if (error.response.status === 400) {
          notify({
            message: `${t("#_users_59")} \r\n \r\n` + error.response.data.errors.join('\r\n'),
            position: {
              my: 'center bottom',
              at: 'center bottom',
            },
          }, 'error', 10000);
        }
      }
      finally {
        setReloadKey(reloadKey + 1)
        dataGridRef.current.instance.clearSelection()
      }
    },
    content: deletionPopupContent,
    title: t("#_DeletionPopup_delete"),
    isVisible: deletionPopupVisible,
    setIsVisible: setDeletionPopupVisible,
    t: t,
    blockAutoRefresh: blockAutoRefresh
  })

  const deleteUser = () => {
    setDeletionPopupContent(t("#_DeletionPopup"))
    deletionPopup.show()
  }

  useEffect(() => {
    setTypesEditorOptions([{
      ID: 0,
      Name: t("#_data_1")
    }, {
      ID: 1,
      Name: 'API'
    }])
  }, [t])

  useEffect(()=>{
    setData(columns({t}))
  }, [t, setData])

  const secretEditorOptions = { stylingMode: 'filled', mode: 'password' };
  const vgFirstForm = useRef(null);
  const vgSecondForm = useRef(null);
  const dataGridRef = useRef(null);

  const [id2Privilege, setId2Privilege] = useState(null)

  useEffect(() => {
    fetchPriviliges({ setId2Privilege })
    fetchRoles({ setRoles })
  }, [])

  const { showUserPrivileges, popup: effectivePrivilegesPopup } = EffectivePrivileges(id2Privilege, setBlockAutoRefresh);

  const popupToolbarItems = commonPopupToolbarItems({
    t: t,
    saveButtonOptions: saveButtonOptions({
      vgFirstForm: vgFirstForm, vgSecondForm: vgSecondForm, userEdit: userEdit, user: user,
      setVisible, dataGridRef: dataGridRef, setReloadKey, t
    }),
    cancelButtonOptions: closeButtonOptions({ setVisible })
  })

  const popup = GenericPopup({
    onHiding: () => popup.close(),
    content:
      <div>
        <Form
          id="form1"
          ref={vgFirstForm}
          validationGroup={validationFirstFormGroup}
          labelMode={labelMode}
          showColonAfterLabel={showColonAfterLabel}
          labelLocation={labelLocation}
          minColWidth={minColWidth}
          colCount={colCount}>
          <GroupItem caption={t("#_users_6")}>
            <RolesDropDown
              roles={roles}
              rolesValue={rolesValue}
              setRolesValue={setRolesValue}
              user={user}>
            </RolesDropDown>
          </GroupItem>
        </Form>
        <Form
          id="form2"
          ref={vgSecondForm}
          validationGroup={validationSecondFormGroup}
          labelMode={labelMode}
          showColonAfterLabel={showColonAfterLabel}
          labelLocation={labelLocation}
          minColWidth={minColWidth}
          colCount={colCount}
          onFieldDataChanged={(e) => formFieldDataChanged({ e: e, setUser, setIsAdmin })}
          formData={user}>
          <GroupItem caption={t("#_users_8")}>
            <SimpleItem dataField="FirstName" editorType="dxTextBox" visible={isUserNatural} value={user.FirstName}><Label text={t("#_users_10")} />
              <RequiredRule message={t("#_users_11")} />
            </SimpleItem>
            <SimpleItem dataField="LastName" editorType="dxTextBox" visible={isUserNatural} value={user.LastName}><Label text={t("#_users_13")} />
              <RequiredRule message={t("#_users_14")} />
            </SimpleItem>
            <SimpleItem dataField="Name" editorType="dxTextBox" visible={!isUserNatural} value={user.Name}><Label text={t("#_users_15")} />
              <RequiredRule message={t("#_users_16")} />
            </SimpleItem>
            <SimpleItem dataField="Secret" editorType="dxTextBox" visible={!isUserNatural} value={user.Secret} 
                    editorOptions={secretEditorOptions}>
              <Label text={t("#_users_51")} />
              {!userEdit && <RequiredRule message={t("#_users_52")} />}
            </SimpleItem>
            <SimpleItem dataField="Email" editorType="dxTextBox" visible={isUserNatural} value={user.Email}>
              <EmailRule message={t("#_users_18")} />
              <RequiredRule message={t("#_users_19")} />
            </SimpleItem>
            <SimpleItem dataField="Type" editorType="dxSelectBox" value={user.Type}
              editorOptions={{
                key: "ID",
                dataSource: typesEditorOptions,
                displayExpr: "Name",
                valueExpr: "ID",
                value: user.Type,
                onValueChanged: (e) => onUserTypeChanged({ e: e, setIsUserNatural, user: user }),
                readOnly: userEdit ? true : false
              }}><Label text={t("#_users_20")} />
              <RequiredRule message={t("#_users_21")} />
            </SimpleItem>
            <SimpleItem dataField="Admin" editorType="dxCheckBox" value={isAdmin}
              helpText={t("#_users_23")}
              editorOptions={{
                onValueChanged: (e) => onIsAdminChanged({ e: e, setIsAdmin, vgFirstForm: vgFirstForm }),
                value: isAdmin
              }}>
              <Label text={t("#_users_24")} />
            </SimpleItem>
            {userEdit && <SimpleItem dataField="Disabled" editorType="dxCheckBox" value={user.Disabled}>
              <Label text={t("#_users_25")} />
            </SimpleItem>
            }
          </GroupItem>
        </Form>
      </div>,
    toolbarItems: popupToolbarItems,
    title: popupTitle,
    width: "1000px",
    height: "800px",
    isVisible: visible,
    setIsVisible: setVisible,
    setBlockAutoRefresh: setBlockAutoRefresh
  })

  return (
    <div id="dataGrid-users">
      {effectivePrivilegesPopup.popup} 
      {popup.popup}
      {deletionPopup.popup}
      <BreadCrumbs node={getNode({ componentName: 'Users', t })}></BreadCrumbs>
      <GenericToolbar
        className="xfx-toolbar-bigbuttons"
        header={t("#_users_26")}
        buttons={toolbarButtons({
          deleteUser: () => deleteUser(),
          dataGridRef: dataGridRef, selectedUser: selectedItem, showUserPrivileges, 
          setIsUserNatural, setUser, setIsAdmin, setVisible, setRolesValue,
          setPopupTitle, setUserEdit, t, setReloadKey, deletionPopupContent, deletionPopupVisible, 
          setDeletionPopupVisible, setDeletionPopupContent
        })}>
      </GenericToolbar>
      <div id="dataGrid-users" className="dataGrid">
            {GenericOdataGrid({ 
                ref: dataGridRef,
                data: data, 
                endpoint: OdataEndpoints.Users(), 
                setSelectedItem: setSelectedItem, 
                defaultFilter: dataGridFilter,
                defaultSort: defaultSort, 
                updateDataGridFilter: updateDataGridFilter,
                autoReload: true,
                gridKey: reloadKey,
                dataSourceOptions: dataSourceOptions,
                setDataSourceOptions,
                blockAutoRefresh: blockAutoRefresh,
                setBlockAutoRefresh: setBlockAutoRefresh
             })}
        </div>
    </div>
  )
}

export default Users

