import React, { useState, useEffect } from "react"
import { XfXApi } from 'api/XfXApi';
import useToast from '../../../utils/useToast'
import TextBox from 'devextreme-react/text-box';
import { Switch } from 'devextreme-react/switch';
import RoleNames from './RoleNames'
import SelectBox from 'devextreme-react/select-box';
import { useTranslation } from 'react-i18next';
import { commonPopupToolbarItems } from 'components/popup/PopupTools';
import GenericPopup from "components/popup/GenericPopup";

const credensialsIdRegExp = RegExp("^\\d{10}\\d?$")

const SelectKsefRoleEditorPopup = ({ role, organization, isVisible, setIsVisible, onClose, setBlockAutoRefresh }) => {
    const { t } = useTranslation()
    const roleTypes = [t("ksef-role-individual"), t("ksef-role-institutional")]
    const [credensialsId, setCredensialsId] = useState(role?.UserName ?? "") // nip or pesel
    const [invoice_read, setInvoice_read] = useState(role?.Roles?.includes("invoice_read") ?? false)
    const [invoice_write, setInvoice_write] = useState(role?.Roles?.includes("invoice_write") ?? false)
    const [credentials_read, setCredentials_read] = useState(role?.Roles?.includes("credentials_read") ?? false)
    const [credentials_manage, setCredentials_manage] = useState(role?.Roles?.includes("credentials_manage") ?? false)
    const [self_invoicing, setSelf_invoicing] = useState(role?.Roles?.includes("self_invoicing") ?? false)
    const [tax_representative, setTax_representative] = useState(role?.Roles?.includes("tax_representative") ?? false)
    const [enforcement_operations, setEnforcement_operations] = useState(role?.Roles?.includes("enforcement_operations") ?? false)
    const [enforcement_authority, setEnforcement_authority] = useState(role?.Roles?.includes("enforcement_authority") ?? false)
    const [court_bailiff, setCourt_bailiff] = useState(role?.Roles?.includes("court_bailiff") ?? false)
    const [title, setTitle] = useState(role == null ? t("#_ksefroles_11") : t("#_ksefroles_39"))

    const { toast, showToastError } = useToast()

    const allRolesSetters = {"invoice_read" : setInvoice_read, 
                             "invoice_write" : setInvoice_write, 
                             "credentials_read": setCredentials_read, 
                             "credentials_manage" : setCredentials_manage, 
                             "self_invoicing" : setSelf_invoicing, 
                             "tax_representative" : setTax_representative,
                             "enforcement_operations" : setEnforcement_operations,
                             "enforcement_authority" : setEnforcement_authority,
                             "court_bailiff" : setCourt_bailiff}

    const GetRoleType=(editedRole)=>
        {
            if(editedRole == null || editedRole.IsIndividualRole === true)
                return roleTypes[0]
            return roleTypes[1]
        }

    const [selectedRoleType, setSelectedRoleType] = useState(GetRoleType(role))
    const roleNames = RoleNames({ t })
    const isCreateNew = role == null
    const anyRoleSelected =
        invoice_read ||
        invoice_write ||
        credentials_read ||
        credentials_manage ||
        self_invoicing ||
        tax_representative ||
        enforcement_operations

    const isCredensialsIdValid = credensialsIdRegExp.test(credensialsId)

    const isIndividualRoleSelected = () => selectedRoleType === roleTypes[0]

    const clearValues = () => {
        setSelectedRoleType(roleTypes[0])
        setCredensialsId(role?.UserName ?? "")
        for(const[key, setter] of Object.entries(allRolesSetters))
        {
            setter(role?.Roles?.includes(key) ?? false)
        }
    }

    const RoleSwitch = ({ label, value, setValue }) => <div className="dx-field" style={{ margin: "0" }}>
        <div className="dx-field-label">{label}</div>
        <div className="dx-field-value">
            <Switch value={value} onValueChange={setValue} />
        </div>
    </div>

    const show_invoice_read = () => true
    const show_invoice_write = () => true
    const show_credentials_read = () => isIndividualRoleSelected()
    const show_credentials_manage = () => false /* isIndividualRoleSelected()*/
    const show_enforcement_operations = () => false  /* isIndividualRoleSelected()*/

    const show_self_invoicing = () => !isIndividualRoleSelected()
    const show_tax_representative = () => !isIndividualRoleSelected()
    const show_court_bailiff = () => false /*  !isIndividualRoleSelected()*/
    const show_enforcement_authority = () => false /* !isIndividualRoleSelected()*/

    const close = () => {
        popup.close()
        clearValues()
        onClose()
    }

    const getRoles = () => {
        return [
            invoice_read ? "invoice_read" : null,
            invoice_write ? "invoice_write" : null,
            credentials_read ? "credentials_read" : null,
            credentials_manage ? "credentials_manage" : null,
            self_invoicing ? "self_invoicing" : null,
            tax_representative ? "tax_representative" : null,
            enforcement_operations ? "enforcement_operations" : null,
            enforcement_authority ? "enforcement_authority" : null,
            court_bailiff ? "court_bailiff" : null].filter(x => x != null)
    }

    const createNewRole = async () => 
        {
            try{
                await XfXApi.KsefRoleApi.apiTenantIdOrganizationIdKsefRolePost(
                    XfXApi.GetTenantId(),
                    organization,
                    {
                        credentialsIdentifier: credensialsId,
                        isIndividualRole: isIndividualRoleSelected(),
                        roles: getRoles()
                    })
            }
            catch(error){
                showToastError(t("#_ksefroles_32"))
            } 
        } 
    

    const editExistingRole = async () =>
    {
        try{
            await XfXApi.KsefRoleApi.apiTenantIdOrganizationIdKsefRoleIdPut(
                role.Id,
                XfXApi.GetTenantId(),
                organization,
                { roles: getRoles()
                })
        }
        catch(error){
            showToastError(t("#_ksefroles_32"))
        } 
    }

    const saveButtonOptions = {
        text: t("#_selectksefroleandidpopup_10"),
        onClick: async () => {
            if(isCreateNew)
            {
                await createNewRole(); 
            }
            else
            {
                await editExistingRole();
            }
            close()
        },
        disabled: !anyRoleSelected || !isCredensialsIdValid
    }
  
    const cancelButtonOptions = {
        text: t("#_selectksefroleandidpopup_14"),
        onClick: close
    }

    const popupToolbarItems = commonPopupToolbarItems({ 
        t: t, 
        saveButtonOptions: saveButtonOptions,
        cancelButtonOptions: cancelButtonOptions
    })

    const popup = GenericPopup({ 
        onHiding: close,
        content:
        <div>
          <SelectBox
                disabled={!isCreateNew}
                items={roleTypes}
                onValueChanged={x => {
                    setSelectedRoleType(x.value)
                }}
                value={selectedRoleType}
                label={t("#_selectksefroleandidpopup_3")}>
            </SelectBox>

            <br />

            <TextBox
                showClearButton={true}
                disabled={!isCreateNew}
                label={t("#_selectksefroleandidpopup_4")}
                placeholder={isIndividualRoleSelected() ? "NIP/PESEL" : t("#_selectksefroleandidpopup_5")}
                valueChangeEvent="keyup"
                value={credensialsId}
                onValueChanged={v => setCredensialsId(v.value)}
                maxLength={isIndividualRoleSelected() ? 11 : 10}
            />

            <br />

            <div className="dx-fieldset">
                {show_invoice_read() && <RoleSwitch label={roleNames.Invoice_read} value={invoice_read} setValue={setInvoice_read}></RoleSwitch>}
                {show_invoice_write() && <RoleSwitch label={roleNames.Invoice_write} value={invoice_write} setValue={setInvoice_write}></RoleSwitch>}
                {show_credentials_read() && <RoleSwitch label={roleNames.Credentials_read} value={credentials_read} setValue={setCredentials_read}></RoleSwitch>}
                {show_credentials_manage() && <RoleSwitch label={roleNames.Credentials_manage} value={credentials_manage} setValue={setCredentials_manage}></RoleSwitch>}
                {show_enforcement_operations() && <RoleSwitch label={roleNames.Enforcement_operations} value={enforcement_operations} setValue={setEnforcement_operations}></RoleSwitch>}

                {show_self_invoicing() && <RoleSwitch label={roleNames.Self_invoicing} value={self_invoicing} setValue={setSelf_invoicing}></RoleSwitch>}
                {show_tax_representative() && <RoleSwitch label={roleNames.Tax_representative} value={tax_representative} setValue={setTax_representative}></RoleSwitch>}
                {show_court_bailiff() && <RoleSwitch label={roleNames.Court_bailiff} value={court_bailiff} setValue={setCourt_bailiff}></RoleSwitch>}
                {show_enforcement_authority() && <RoleSwitch label={roleNames.Enforcement_authority} value={enforcement_authority} setValue={setEnforcement_authority}></RoleSwitch>}
            </div>
        </div>,
        toolbarItems: popupToolbarItems,
        title: title,
        width: "500px",
        height: "80%",
        isVisible: isVisible,
        setIsVisible: setIsVisible,
        setBlockAutoRefresh: setBlockAutoRefresh
      })

    return (
        <>
            {popup.popup}
        </>
    )
}

export default SelectKsefRoleEditorPopup
