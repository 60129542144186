import BreadCrumbs from 'components/breadCrumbs/BreadCrumbs';
import { getNode } from 'components/breadCrumbs/getNode';
import { useTranslation } from 'react-i18next';
import GenericToolbar from '../Toolbars/GenericToolbar';
import { useState, useRef, useEffect } from 'react';
import CustomStore from 'devextreme/data/custom_store';
import { XfXApi } from 'api/XfXApi';
import ReportBuilderEditor from './ReportBuilderEditor';
import { 
    sendInvoiceColumns, 
    receivedInvoiceColumns,
    repositoryDispatchColumns, 
    repositoryReceivedInvoiceColumns, 
    dispatchFailedInvoiceColumns, 
    receivedFailedInvoiceColumns
} from 'pages/Invoices/OneGridColumns'
import { columns } from './const';
import { OdataEndpoints } from 'api/XfXApi';
import GenericOdataGrid from 'pages/Invoices/GenericOdataGrid';
import useDataGridFilter from 'utils/useDataGridFilter'
import DeletionPopup from 'components/popup/DeletionPopup';

const ReportBuilder = () => {
    const { t } = useTranslation()
    const userColums = ['login']

    const [data, setData] = useState([])
    const [dataSourceOptions, setDataSourceOptions] = useState()
    const [reloadKey, setReloadKey] = useState(0)

    const [configId, setConfigId] = useState(null)
    const [visible, setVisible] = useState(false)

    const dataGridRef = useRef(null)
    const [selectedItem, setSelectedItem] = useState(null)
    const initialFilter = []
    const { dataGridFilter, updateDataGridFilter } = useDataGridFilter({ initialFilter })
    const defaultSort = { selector: "CreationDateUtc", desc: true };

    const [organizations, setOrganizations] = useState([])
    const [allOrganizationsCustomStore, setAllOrganizationsCustomStore] = useState()

    const [users, setUsers] = useState([])

    const [reportTypes, setReportTypes] = useState([])
    const [reportTypesDataStore, setReportTypesDataStore] = useState([])

    const [reportPeriods, setReportPeriods] = useState([])
    const [reportPeriodsDataStore, setReportPeriodsDataStore] = useState([])

    const [reportExportParametersOutputFileTypes, setReportExportParametersOutputFileTypes] = useState([])
    const [reportExportParametersOutputFileTypesDataStore, setReportExportParametersOutputFileTypesDataStore] = useState([])

    const [allReportExportParametersDataSources, setAllReportExportParametersDataSources] = useState([])
    const [allReportExportParametersDataSourcesDataStore, setAllReportExportParametersDataSourcesDataStore] = useState([])

    const [availableReportExportParametersDataSources, setAvailableReportExportParametersDataSources] = useState([])
    const [availableReportExportParametersDataSourcesDataStore, setAvailableReportExportParametersDataSourcesDataStore] = useState([])

    const [invKindDataSource, setInvKindDataSource] = useState([])
    const [subject3RoleDataSource, setSubject3RoleDataSource] = useState([])
    const [currencyCodesDataSource, setCurrencyCodesDataSource] = useState([])
    const [processingStatusDataSource, setProcessingStatusDataSource] = useState([])
    const [saleDateTagsDataSource, setSaleDateTagsDataSource] = useState([])
    const [processingModeDataSource, setProcessingModeDataSource] = useState([])

    const [sendInvColumns, setSendInvColumns] = useState()
    const [dispatchFailedInvColumns, setDispatchFailedInvColumns] = useState()
    const [repoDispatchColumns, setRepoDispatchColumns] = useState()
    const [receivedInvColumns, setReceivedInvColumns] = useState()
    const [receivedFailedInvColumns, setReceivedFailedInvColumns] = useState()
    const [repositoryReceivedInvColumns, setRepositoryReceivedInvColumns] = useState()

    const [deletionPopupContent, setDeletionPopupContent] = useState("")
    const [deletionPopupVisible, setDeletionPopupVisible] = useState(false)

    const [reportData, setReportData] = useState(null)

    const [blockAutoRefresh, setBlockAutoRefresh] = useState(false)

    async function fetchOneGridDictionaries() { 
        const invoiceKindsPromise = XfXApi.DictionaryInvoice.apiTenantIdDictionaryInvoiceKindsGet(XfXApi.GetTenantId())
        const subject3RolesPromise = XfXApi.DictionaryInvoice.apiTenantIdDictionarySubject3RolesGet(XfXApi.GetTenantId())
        const currencyCodesPromise = XfXApi.DictionaryInvoice.apiTenantIdDictionaryCurrencycodesGet(XfXApi.GetTenantId())
        const processingstatusesPromise = XfXApi.DictionaryInvoice.apiTenantIdDictionaryProcessingstatusesGet(XfXApi.GetTenantId())
        const saleDateTagsPromise = XfXApi.DictionaryInvoice.apiTenantIdDictionarySaleDateTagsGet(XfXApi.GetTenantId())
        const processingModesPromise = XfXApi.DictionaryInvoice.apiTenantIdDictionaryProcessingmodesGet(XfXApi.GetTenantId())

        const invKindData = (await invoiceKindsPromise).data
        const subject3RoleData = (await subject3RolesPromise).data
        const currencyCodesData = (await currencyCodesPromise).data
        const processingStatusData = (await processingstatusesPromise).data
        const saleDateTagsData = (await saleDateTagsPromise).data
        const processingModesData = (await processingModesPromise).data

        setInvKindDataSource(invKindData)
        setSubject3RoleDataSource(subject3RoleData)
        setCurrencyCodesDataSource(currencyCodesData)
        setProcessingStatusDataSource(processingStatusData)
        setSaleDateTagsDataSource(saleDateTagsData)
        setProcessingModeDataSource(processingModesData)

        setSendInvColumns(
            sendInvoiceColumns({ 
                t: t,
                invKindDataSource: invKindData,
                subject3RoleDataSource: subject3RoleData,
                currencyCodesDataSource: currencyCodesData,
                processingStatusDataSource: processingStatusData,
                saleDateTagsDataSource: saleDateTagsData,
                processingModeDataSource: processingModesData
        }))

        setDispatchFailedInvColumns(
            dispatchFailedInvoiceColumns({ 
                t: t,
                invKindDataSource: invKindData,
                subject3RoleDataSource: subject3RoleData,
                currencyCodesDataSource: currencyCodesData,
                processingStatusDataSource: processingStatusData,
                saleDateTagsDataSource: saleDateTagsData,
                processingModeDataSource: processingModesData
        }))

        setRepoDispatchColumns(
            repositoryDispatchColumns({ 
                t: t,
                invKindDataSource: invKindData,
                subject3RoleDataSource: subject3RoleData,
                currencyCodesDataSource: currencyCodesData,
                processingStatusDataSource: processingStatusData,
                saleDateTagsDataSource: saleDateTagsData,
                processingModeDataSource: processingModeDataSource
        }))

        setReceivedInvColumns(
            receivedInvoiceColumns({ 
                t: t,
                invKindDataSource: invKindData,
                subject3RoleDataSource: subject3RoleData,
                currencyCodesDataSource: currencyCodesData,
                processingStatusDataSource: processingStatusData,
                saleDateTagsDataSource: saleDateTagsData
        }))

        setReceivedFailedInvColumns(
            receivedFailedInvoiceColumns({ 
                t: t,
                invKindDataSource: invKindData,
                subject3RoleDataSource: subject3RoleData,
                currencyCodesDataSource: currencyCodesData,
                processingStatusDataSource: processingStatusData,
                saleDateTagsDataSource: saleDateTagsData
        }))

        setRepositoryReceivedInvColumns(
            repositoryReceivedInvoiceColumns({ 
                t: t,
                invKindDataSource: invKindData,
                subject3RoleDataSource: subject3RoleData,
                currencyCodesDataSource: currencyCodesData,
                processingStatusDataSource: processingStatusData,
                processingModeDataSource: processingModeDataSource
        }))
    }

    useEffect(() => {
        async function fetchData() {
          const organizationsPromise = XfXApi.Organization.apiTenantIdOrganizationUserOrganizationsGet(XfXApi.GetTenantId())
          const usersPromise = XfXApi.User.apiTenantIdUserUsersGet(XfXApi.GetTenantId())

          const organizationsData = (await organizationsPromise).data
          const usersData = (await usersPromise).data.stateObject

          setOrganizations(organizationsData)
          setAllOrganizationsCustomStore(makeAsyncDataSource(organizationsData))
          setUsers(makeAsyncDataSource(mapUsers(usersData)))
        }
    
        fetchData()
      }, [])

    useEffect(() => {
        async function fetchDictionaries() {
          const reportPeriodsPromise = XfXApi.DictionaryInvoice.apiTenantIdDictionaryReportPeriodsGet(XfXApi.GetTenantId())
          const reportTypesPromise = XfXApi.DictionaryInvoice.apiTenantIdDictionaryReportTypesGet(XfXApi.GetTenantId())
          const reportExportParametersOutputFileTypesPromise = XfXApi.DictionaryInvoice.apiTenantIdDictionaryExportparametersoutputfiletypeGet(XfXApi.GetTenantId())
          const allReportExportParametersDataSourcesPromise = XfXApi.DictionaryInvoice.apiTenantIdDictionaryExportparametersdatasourcesGet(XfXApi.GetTenantId())
          const availableReportExportParametersDataSourcesPromise = XfXApi.ReportApi.apiTenantIdReportAvailableDataSourcesGet(XfXApi.GetTenantId())
          
          const reportPeriodsData = (await reportPeriodsPromise).data
          const reportTypesData = (await reportTypesPromise).data
          const reportExportParametersOutputFileTypesData = (await reportExportParametersOutputFileTypesPromise).data
          const allReportExportParametersDataSourcesData = (await allReportExportParametersDataSourcesPromise).data
          const availableReportExportParametersDataSourcesData = (await availableReportExportParametersDataSourcesPromise).data

          const mappedReportPeriods = mapDictionary({ dictionary: reportPeriodsData, t })
          const mappedReportTypes = mapDictionary({ dictionary: reportTypesData, t })
          const mappedReportExportParametersOutputFileTypes = mapDictionary({ dictionary: reportExportParametersOutputFileTypesData, t })
          const mappedAllReportExportParametersDataSources = mapDictionary({ dictionary: allReportExportParametersDataSourcesData, t })
          const mappedAvailableReportExportParametersDataSources = mapDictionary({ dictionary: availableReportExportParametersDataSourcesData, t })

          setReportPeriods(mappedReportPeriods)
          setReportTypes(mappedReportTypes)
          setReportExportParametersOutputFileTypes(mappedReportExportParametersOutputFileTypes)
          setAllReportExportParametersDataSources(mappedAllReportExportParametersDataSources)
          setAvailableReportExportParametersDataSources(mappedAvailableReportExportParametersDataSources)
          
          setReportPeriodsDataStore(makeAsyncDataSource(mappedReportPeriods))
          setReportTypesDataStore(makeAsyncDataSource(mappedReportTypes))
          setReportExportParametersOutputFileTypesDataStore(makeAsyncDataSource(mappedReportExportParametersOutputFileTypes))
          setAllReportExportParametersDataSourcesDataStore(makeAsyncDataSource(mappedAllReportExportParametersDataSources))
          setAvailableReportExportParametersDataSourcesDataStore(makeAsyncDataSource(mappedAvailableReportExportParametersDataSources))

          setData(columns({ t, dataSource: allReportExportParametersDataSourcesData, reportTypeDataSource: reportTypesData }))
        }
    
        fetchDictionaries()
        fetchOneGridDictionaries()
    }, [t])

    useEffect(() => {
        async function fetchReportData() {
            const reportPromise = XfXApi.ReportApi.apiTenantIdReportGet(XfXApi.GetTenantId(), selectedItem.Id?._value ?? selectedItem.Id)
            const reportData = (await reportPromise).data
            setReportData(reportData)
        }
    
        if (selectedItem !== null)
            fetchReportData()
        else
            setReportData(null)
    }, [selectedItem])

    const makeAsyncDataSource = (data) => {
        return new CustomStore({
          loadMode: 'raw',
          key: 'id',
          load() {
            return data
          }
        })
    }

    const mapDictionary = ({ dictionary, t }) => {
        return dictionary.map(v => ({ id: v.key, name: t(v.value) }))
    }

    const mapUsers = (users) => {
        return users.map(v => ({ id: v.id, login: v.login }))
    }

    const addConfig = (e) =>
    {
        setReportData(null)
        setConfigId(null)
        setVisible(true)
    }

    const editConfig = (e) =>
    {
        setConfigId(selectedItem.Id?._value ?? selectedItem?.Id)
        setVisible(true)
    }

    const generate = async () =>
    {
        reportData.organizationsNIPs.forEach(async orgNIP => {
            await XfXApi.ExportApi.apiTenantIdOrganizationIdExportPost(
                XfXApi.GetTenantId(), 
                orgNIP,
                {
                    columns: JSON.parse(reportData.columns),
                    filter: reportData.odataFilter,
                    orderby: null,
                    outputType: reportData.outputFileType,
                    source: reportData.dataSource,
                    singleQtyText: t("single-qty-text"),
                    reportId: reportData.id
                })
        })
    }

    const deletionPopup = DeletionPopup({
        onConfirm: () =>{
          XfXApi.ReportApi
            .apiTenantIdReportDelete(XfXApi.GetTenantId(), selectedItem.Id?._value ?? selectedItem?.Id)
            .then(x => setReloadKey(reloadKey + 1)) 
              dataGridRef?.current?.instance?.clearSelection()
              dataGridRef?.current?.instance?.refresh()
        },
        content: deletionPopupContent, 
        title: t("#_DeletionPopup_delete"),
        isVisible: deletionPopupVisible, 
        setIsVisible: setDeletionPopupVisible, 
        t: t,
        setBlockAutoRefresh: setBlockAutoRefresh
    })
    
    const deleteConfiguration = () => {
        if (selectedItem === null) 
            return

            
        setDeletionPopupContent(t("#_DeletionPopup"));            
        deletionPopup.show()
    }

    const toolbarButtons = [
        { key: 'reportAdd', icon: 'plus', text: t("#_reportsconfiguration_1"), onClick: (e) => addConfig(e) },
        { key: 'reportEdit', icon: 'edit', text: t("#_reportsconfiguration_2"), onClick: (e) => editConfig(e), disabled: selectedItem === null },
        { key: 'reportGenerate', icon: 'export', text: t("#_reportsconfiguration_3"), onClick: async () => { await generate() }, disabled: selectedItem === null },
        { key: 'reportDelete', icon: 'trash', text: t("#_reportsconfiguration_4"), onClick: deleteConfiguration, disabled: selectedItem === null },
    ]

    return (
        <>
            <div id="dataGrid-reports-configurations">
                {deletionPopup.popup}
                {visible && <ReportBuilderEditor 
                    setVisible={setVisible}
                    reportConfigurationId={configId}      
                    userDataSource={users}
                    userColums={userColums}
                    reportTypes={reportTypes}
                    reportTypesDataStore={reportTypesDataStore}
                    reportPeriodsDataStore={reportPeriodsDataStore}
                    allOrganizationsCustomStore={allOrganizationsCustomStore}
                    reportExportParametersOutputFileTypes={reportExportParametersOutputFileTypes}
                    reportExportParametersOutputFileTypesDataStore={reportExportParametersOutputFileTypesDataStore}
                    allReportExportParametersDataSources={allReportExportParametersDataSources}
                    allReportExportParametersDataSourcesDataStore={allReportExportParametersDataSourcesDataStore}
                    availableReportExportParametersDataSources={availableReportExportParametersDataSources}
                    availableReportExportParametersDataSourcesDataStore={availableReportExportParametersDataSourcesDataStore}
                    sendInvColumns={sendInvColumns}
                    dispatchFailedInvColumns={dispatchFailedInvColumns}
                    repoDispatchColumns={repoDispatchColumns}
                    receivedInvColumns={receivedInvColumns}
                    receivedFailedInvColumns={receivedFailedInvColumns}
                    repositoryReceivedInvColumns={repositoryReceivedInvColumns}
                    onSaved={() => 
                    {
                        dataGridRef?.current?.instance?.clearSelection()
                        dataGridRef?.current?.instance?.refresh()
                    }}
                    visible={visible}
                    reportData={reportData}
                    onHiding={() => {dataGridRef?.current?.instance?.clearSelection()}}
                    setBlockAutoRefresh={setBlockAutoRefresh}
                />}
                <BreadCrumbs node={getNode({ componentName: 'ReportBuilder', t })}></BreadCrumbs>
                <GenericToolbar className="xfx-toolbar-bigbuttons" buttons={toolbarButtons}></GenericToolbar>
                <div id="dataGrid-reports" className="dataGrid">
                    {GenericOdataGrid({ 
                        ref: dataGridRef,
                        data: data, 
                        endpoint: OdataEndpoints.Reports(), 
                        setSelectedItem: setSelectedItem, 
                        defaultFilter: dataGridFilter,
                        defaultSort: defaultSort, 
                        updateDataGridFilter: updateDataGridFilter,
                        autoReload: true,
                        gridKey: reloadKey,
                        odataCustomFilterFieldName: "Organizations",
                        dataSourceOptions: dataSourceOptions,
                        setDataSourceOptions,
                        blockAutoRefresh: blockAutoRefresh,
                        setBlockAutoRefresh: setBlockAutoRefresh
                    })}
                </div>
            </div> 
        </>
    )
}


export default ReportBuilder 