import { Privileges } from 'contexts/auth'

const AccessPrivileges = {
    LicenseData:  ["system_management", "license_management"],
    Organizations: ["system_management", "organizations_management"],
    NotificationsConfiguration: ["system_management"],
    SecuritySettings: ["system_management"],
    SecurityLogSettings: ["system_management"],
    Users: ["system_management", "user_management"],
    Roles: ["system_management", "roles_management"],
    Menu: ["system_management"],
    AccountingSystems: ["system_management", "data_source_management"],
    ModulesGrid: ["system_management", "modules_management"],
    Pipelines: ["system_management", "pipelines_management"],
    PipelinesFullAccess: ["system_management"],
    ExitPaths: ["system_management"],
    KsefRoles: ["ksef_management"],
    KsefTokens: ["ksef_management"],
    KsefSessions: ["ksef_management"],
    KsefReceptionCheckups: ["ksef_management"],
    OrganizationSyncSettings: ["system_management", "organizations_management"],
    DocumentTemplates: ["system_management"],
    SelfInvoicingGranted: ["self_invocing_permissions", "system_management"],
    NumberingSeries: ["system_management"],
    ReportBuilder: ["report_builder"],
    ConfigS3: ["system_management"]
}

export default AccessPrivileges