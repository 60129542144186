import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import pl from './pl.json'
import en from './en.json'
import plMessages from "./dx-pl.json";
import { locale, loadMessages } from "devextreme/localization";
import { SetLanguageCodeForApi } from "api/XfXApi"

export const Languages = [
  {
    PL: 'pl',
    EN: 'en-GB'
  }
]


const getBrowserLocales = () => {
  const browserLocales = navigator.languages === undefined ? [navigator.language] : navigator.languages

  if (!browserLocales) {
    return undefined
  }

  return browserLocales.map(locale => {
    const trimmedLocale = locale.trim()
    return trimmedLocale.split(/-|_/)[0]
  })
}

export const getFirstAvailableLanguage = () => {
  const browserLocales = getBrowserLocales()
  const distinctBrowserLocales = [...new Set(browserLocales)]
  const availableLanguages = Object.values(Languages[0])
  const al = distinctBrowserLocales?.find(x => availableLanguages.includes(x))

  return al ? al : Languages.PL
}

export const InitLocalization = async () => {
  loadMessages(plMessages)
  const availableLang = getFirstAvailableLanguage()
  BasicLanguageSetup(availableLang)
}

export const BasicLanguageSetup = (code) => {
  i18n.changeLanguage(code)
  SetLanguageCodeForApi(code)
  locale(code)
}

i18n
  .use(initReactI18next)
  .init({
    fallbackLng: 'pl',
    debug: true,

    interpolation: {
      escapeValue: false,
    },
    resources: {
      pl: {
        translation: pl
      },

      en: {
        translation: en
      }
    }
  });


export default i18n;