import { useState, useEffect, useRef } from 'react';
import BreadCrumbs from 'components/breadCrumbs/BreadCrumbs';
import OrganizationContextOdataGrid from 'pages/Invoices/OrganizationContextOdataGrid';
import { useUserSettingsContext } from 'contexts/UserSettingsProvider.jsx';
import { useTranslation } from 'react-i18next';
import { OdataEndpoints } from 'api/XfXApi'
import { XfXApi } from 'api/XfXApi';
import utcToLocal from 'utils/utcToLocal';
import { getNode } from 'components/breadCrumbs/getNode';
import { EventLogsPopup, EventLogsTypes } from 'pages/Invoices/EventLogsPopup';
import { DownloadDeletePopup } from './DownloadDeletePopup';

import GenericToolbar from 'pages/settings/Toolbars/GenericToolbar';

const getToolbarButtons = ({ t, selectedItem, organizationId, reload, downloadDeletePopup, setEventLogsVisible }) => [
    {
        icon: 'inboxBlack', text: t("job-button-download"), disabled: selectedItem === null || !selectedItem.ResultFileId,
        onClick: () => downloadFile(selectedItem, t, organizationId)
    },
    {
        icon: 'trashBlack', text: t("job-button-delete"), disabled: selectedItem === null,
        onClick: () => {
            downloadDeletePopup.show({
                selectedItem, organizationId, reload
            })
        },
    },
    {
        id: "logs", icon: 'textdocument', text: t("button-logs"), disabled: selectedItem === null,
        onClick: async () => setEventLogsVisible(true)
    },
]

const downloadFile = (x, t, organizationId) => {
    if (!x.ResultFileId) return

    XfXApi.JobsApi.apiTenantIdOrganizationIdJobsResultGet(XfXApi.GetTenantId(), organizationId, x.Id.toString(), {

        method: "GET",
        responseType: 'blob', //important for binary file
    })
        .then((response) => {
            if (response.status !== 200) return
            const downloadUrl = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = downloadUrl;
            link.setAttribute('download', x.ResultFileName);

            document.body.appendChild(link);
            link.click();
            link.remove();
        })
        .catch(x => {
            console.log(x)
        })

}

const sourceToStr = (source, t) => t("job-source-" + source)

const mapLookupDataSource = ( t, dataSource, prefix ) => {
    return dataSource?.map(({ key, value }) => ({ name: value, description: t(`${prefix}${value.toLowerCase()}`)}))
  }
  
  const lookupDataSource = ( t, dataSource, prefix ) => {
    return {
      store: {
          type: 'array',
          data: mapLookupDataSource(t, dataSource, prefix),
          key: 'name'
      },
    }
  }

const data = ({ t, organizationId, processingStatusDataSource, processingStatusPrefix }) => [
    { dataField: "Id", caption: "Id", width: "auto", visible: false },
    { dataField: "CreationDateUtc", dataType: "datetime", caption: t("job-creationdate"), minWidth: 130, width: "auto", visible: true, cellRender: x => utcToLocal(x.value) },
    { dataField: "EndDateUtc", dataType: "datetime", caption: t("job-enddate"), width: "auto", minWidth: 130, visible: true, cellRender: x => utcToLocal(x.value) },
    {
        dataField: "JobType", dataType: "string", caption: t("job-type"), width: "auto", minWidth: 130, visible: true,
        cellRender: x => t("job-type-" + x.value)
    },
    { dataField: "ResultFileType", dataType: "string", caption: t("job-content"), width: "auto", minWidth: 130, visible: true },
    { dataField: "ResultFileId", caption: t("job-result"), visible: false },
    { dataField: "ResultFileName", visible: false },
    { dataField: "DataSource", dataType: "string", caption: t("job-data-source"), minWidth: 200, visible: true, cellRender: x => sourceToStr(x.value, t) },
    { 
        dataField: "Status", dataType: "string", caption: t("job-status"), width: "*", minWidth: 200, visible: true, allowSorting: false,
        lookupValueExpr: "name", lookupDisplayExpr: "description", lookupDataSource: lookupDataSource(t, processingStatusDataSource, processingStatusPrefix), 
        editorOptions: {
          dropDownOptions: {
            minWidth: '250px'
          }
        }
    },
]


const endpoint = OdataEndpoints.Jobs

const DownloadPage = () => {
    const processingStatusPrefix = 'job-status-'
    const defaultSort = { selector: "CreationDateUtc", desc: true };

    const [processingStatusDataSource, setProcessingStatusDataSource] = useState([])
    const [selectedItem, setSelectedItem] = useState(null)
    const { t } = useTranslation()
    const [dataSourceOptions, setDataSourceOptions] = useState()
    const [columns, setColumns] = useState([])
    const [toolbarButtons, setToolbarButtons] = useState()
    const [eventLogsVisible, setEventLogsVisible] = useState(false)
    const [blockAutoRefresh, setBlockAutoRefresh] = useState(false)

    const dataGridRef = useRef(null)

    const { getOrganizationContextSettings } = useUserSettingsContext()
    const organizationId = getOrganizationContextSettings()?.NIP

    const downloadDeletePopup = DownloadDeletePopup(setBlockAutoRefresh)

    useEffect(() => {
        setToolbarButtons(getToolbarButtons({
            t, selectedItem, organizationId, reload: () => {
                dataGridRef.current.instance.refresh()
            }, downloadDeletePopup, setEventLogsVisible
        }))
    }, [t, selectedItem, organizationId, dataSourceOptions])


    useEffect(() => {
        async function getDictionariesAndSetColumns() {
            const processingstatusesPromise = XfXApi.DictionaryInvoice.apiTenantIdDictionaryJobprocessingstatusesGet(XfXApi.GetTenantId())
            const processingStatusData = (await processingstatusesPromise).data
  
            setProcessingStatusDataSource(processingStatusData)

            const dataToShow = data({ t, organizationId, processingStatusDataSource: processingStatusData, processingStatusPrefix })

            setColumns(dataToShow)
        }
        
        getDictionariesAndSetColumns()
    }, [organizationId, t, setColumns])

    return (
        <>
            {downloadDeletePopup.popup}
            <BreadCrumbs node={getNode({ componentName: 'Download', t })}></BreadCrumbs>
            <GenericToolbar className="xfx-toolbar-bigbuttons" buttons={toolbarButtons}></GenericToolbar>
            {eventLogsVisible && <EventLogsPopup type={EventLogsTypes.Jobs}
                                                 guid={selectedItem?.Id} 
                                                 setEventLogsVisible={setEventLogsVisible} 
                                                 eventLogsVisible={eventLogsVisible}
                                                 setBlockAutoRefresh={setBlockAutoRefresh}>
                                 </EventLogsPopup>}
            <div id="dataGrid-downloads" className="dataGrid">
                {OrganizationContextOdataGrid({
                    id: 'Download',
                    ref: dataGridRef,
                    data: columns,
                    endpoint: endpoint,
                    setSelectedItem: setSelectedItem,
                    defaultSort: defaultSort,
                    dataSourceOptions: dataSourceOptions,
                    setDataSourceOptions: setDataSourceOptions,
                    blockAutoRefresh: blockAutoRefresh,
                    setBlockAutoRefresh: setBlockAutoRefresh
                })}
            </div>
        </>
    );
}

export default DownloadPage