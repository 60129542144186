import { useState } from "react";
import GenericPopup from "components/popup/GenericPopup";
import SalesProcessingFromTokensImporter from 'pages/Invoices/Sale/SalesProcessingFromTokensImporter';
import { commonPopupToolbarItems } from 'components/popup/PopupTools';
import { postInvoicesFromTokensCSV } from 'pages/Invoices/Sale/actions/postInvoices';
import { useTranslation } from 'react-i18next';
import CustomStore from 'devextreme/data/custom_store';

const newPostInvoicesFromTokensCsvDto =
{
    invoices: [],
    adjustTokens: true,
    tags: [],
    forceXsdValidation: null,
    anonymize: false,
    requiresBatchDispatch: null,
    vatDate: null,
    ansiCode: null,
    importOnlyValid: true
}

const setnewPostInvoicesFromTokensCsvDtoDefaults = () => {
    newPostInvoicesFromTokensCsvDto.invoices = []
    newPostInvoicesFromTokensCsvDto.adjustTokens = true
    newPostInvoicesFromTokensCsvDto.tags = []
    newPostInvoicesFromTokensCsvDto.forceXsdValidation = null
    newPostInvoicesFromTokensCsvDto.anonymize = false
    newPostInvoicesFromTokensCsvDto.requiresBatchDispatch = null
    newPostInvoicesFromTokensCsvDto.vatDate = null
    newPostInvoicesFromTokensCsvDto.ansiCode = null
    newPostInvoicesFromTokensCsvDto.importOnlyValid = true
}

const ImporterFromTokensPopup = ({
    organizationId,
    dispatchType,
    setSelectedFilesItemKeys,
    selectedFilesItemKeys,
    afterSave,
    setBlockAutoRefresh,
    ansiCodesDataSource
}) => {

    const [postInvoicesFromTokensDto, setPostInvoicesFromTokensDto] = useState(newPostInvoicesFromTokensCsvDto)
    const [importerFromTokensVisible, setImporterFromTokensVisible] = useState(false)
    const [loading, setLoading] = useState(false)
    const [fileStore, setFileStore] = useState([])
    const { t } = useTranslation()
    const ansiCodePrefix = '#_ANSICode_'

    function makeAsyncDataSource(data) {
        return new CustomStore({
          loadMode: 'raw',
          key: 'key',
          load() {
            return data.map(x => {
                return {
                    ...x, description: t(`${ansiCodePrefix}${x.value}`)
                }
            })
          },
        });
      }

    const saveButtonOptions = {
        text: t("#_oneinvoicegrid_5"),
        onClick: async () => {
            setLoading(true)
            await postInvoicesFromTokensCSV({
                organizationId: organizationId, 
                postInvoiceDto: postInvoicesFromTokensDto, 
                dispatchType: dispatchType, 
                t: t
            })
            setLoading(false)
            close()
            setnewPostInvoicesFromTokensCsvDtoDefaults()
            setPostInvoicesFromTokensDto(newPostInvoicesFromTokensCsvDto)
            setSelectedFilesItemKeys([])
            setFileStore([])
            afterSave()
        },
        disabled: loading
    }

    const cancelButtonOptions = {
        text: t("#_oneinvoicegrid_8"),
        onClick: () => {
            close()
            setnewPostInvoicesFromTokensCsvDtoDefaults()
            setPostInvoicesFromTokensDto(newPostInvoicesFromTokensCsvDto)
            setSelectedFilesItemKeys([])
            setFileStore([])
            setLoading(false)
        }
    }

    const importPopupToolbarItems = commonPopupToolbarItems({
        t: t,
        saveButtonOptions: saveButtonOptions,
        cancelButtonOptions: cancelButtonOptions,
        loading: loading
    })

    const { popup, show, close } = GenericPopup({
        onHiding: () => close(),
        content:
            <div>
                <SalesProcessingFromTokensImporter
                    postInvoicesFromTokensDto={postInvoicesFromTokensDto}
                    setPostInvoicesFromTokensDto={setPostInvoicesFromTokensDto}
                    selectedFilesItemKeys={selectedFilesItemKeys}
                    setSelectedFilesItemKeys={setSelectedFilesItemKeys}
                    fileStore={fileStore}
                    setFileStore={setFileStore}
                    ansiCodesDataSource={makeAsyncDataSource(ansiCodesDataSource)}
                ></SalesProcessingFromTokensImporter>
            </div>,
        toolbarItems: importPopupToolbarItems,
        title:  t("button-import-from-csv"),
        width: "80%",
        height: "85%",
        isVisible: importerFromTokensVisible,
        setIsVisible: setImporterFromTokensVisible,
        setBlockAutoRefresh: setBlockAutoRefresh
    })

    return { popup: popup, show, close }
}

export default ImporterFromTokensPopup;