export const OrganizationsColumnRenderer = (x) => {
    return <><ul>{x.value.map((o) => <li>{o}</li>)}</ul></>
}

export const RecipientsRenderer = (x) => {
    return (
    <>
    <div>{
        x.value.map((r,idx,arr)=> (
            <div>
                <div>
                    {r.email}
                </div>
                {r.name && <div>{r.name}</div>}
                {(r.name && idx < arr.length - 1) && <hr/>}
            </div>
            ))
            }
    </div>
    </>)
}