import { FormField } from './model';
import { Fa1InvoiceItem } from './Fa1InvoiceItem';
import { proceduraPossibleValues, gtuPossibleValues, p12PossibleValues } from './possibleValues';
import { FieldsVisibility, FieldsVisibilityBeforeCor } from './model';

const idColWidth = 40;
const dateColWidth = 230;
const amountColWidth = 190;
const nazwaColWidth = 200;
const iloscColWidth = 120;
const miaraColWidth = 100;
const stawkaOssColWidth = 120;
const gtuColWidth = 150;
const proceduraColWidth = 230;
const taxRateColWidth = 340;
const akcyzaColWidth = 200;
const zal15ColWidth = 200;
const standardTextColWidth = 170;

export const columns = (showP_6A: boolean, showNetto: boolean, showOss: boolean, showCurrency: boolean, optionalFieldExistence: FieldsVisibility) => [
    {
        dataField: "ID", caption: "L.p.", dataType: "number", cellTemplate: "idColumn", showInColumnChooser: false, allowEditing: false, width: idColWidth, 
    },
    {
        dataField: "P_6A",  caption: "Data dostawy / wykonania usługi", visible: showP_6A, showInColumnChooser: false, dataType: 'date', width: dateColWidth
    },
    {
        dataField: "nazwaTowaruLubUslugi", caption: "Nazwa towaru / usługi", showInColumnChooser: false, width: nazwaColWidth
    },
    {
        dataField: "ilosc", caption: "Ilość", showInColumnChooser: false, width: iloscColWidth
    },
    {
        dataField: "miara", caption: "Miara", showInColumnChooser: false, width: miaraColWidth
    },
    {
        dataField: "cenaJednostkowaNetto", caption: "Cena jednostkowa netto", visible: showNetto, showInColumnChooser: false, width: amountColWidth, format: { type: "fixedPoint", precision: 2 }
    },
    {
        dataField: "cenaJednostkowaBrutto", caption: "Cena jednostk. brutto", visible: !showNetto, showInColumnChooser: false, width: amountColWidth, format: { type: "fixedPoint", precision: 2 }
    },
    {
        dataField: "wartoscSprzedazyNetto", caption: "Wartość sprzedaży netto", visible: showNetto, allowEditing: false, showInColumnChooser: false, width: amountColWidth, format: { type: "fixedPoint", precision: 2 }
    },
    {
        dataField: "wartoscSprzedazyBrutto", caption: "Wartość sprzedaży brutto", visible: !showNetto, allowEditing: false, showInColumnChooser: false, width: amountColWidth, format: { type: "fixedPoint", precision: 2 }
    },
    {
        dataField: "rabatOpust", caption: "Rabat / Opust", visible: optionalFieldExistence.rabatOpust, showInColumnChooser: true, width: amountColWidth, format: { type: "fixedPoint", precision: 2 }
    },
    {
        dataField: "kwotaPodatku", caption: "Kwota podatku", showInColumnChooser: true, visible: optionalFieldExistence.kwotaPodatku, allowEditing: false, width: amountColWidth, format: { type: "fixedPoint", precision: 2 }
    },
    {
        dataField: "stawkaPodatku", caption: "Stawka podatku", showInColumnChooser: false, visible: !showOss, width: taxRateColWidth, lookup:{ dataSource: p12PossibleValues, displayExpr: "PrintableValue", valueExpr: "XmlValue"}
    },
    {
        dataField: "stawkaPodatkuXII", dataType: "number", caption: "Stawka podatku OSS", visible: showOss, editorOptions: { max: 100, min: 0 }, showInColumnChooser: false, width: stawkaOssColWidth, format: { type: "fixedPoint", precision: 6 }
    },
    {
        dataField: "indeks", dataType: "string", visible: optionalFieldExistence.indeks, caption: "Indeks", showInColumnChooser: true, editorOptions: { maxLength: 50 }, width: standardTextColWidth
    },
    {
        dataField: "towarUsługaZal15", visible: optionalFieldExistence.towarUsługaZal15, dataType: "boolean", showInColumnChooser: !showOss, caption: "Towar lub usługa z zał. 15", width: zal15ColWidth
    },
    {
        dataField: "GTU", showInColumnChooser: true, visible: optionalFieldExistence.GTU, caption: "GTU", width: gtuColWidth, lookup: { dataSource: gtuPossibleValues, displayExpr: "PrintableValue", valueExpr: "XmlValue" }
    },
    {
        dataField: "PKWiU", caption: "PKWiU", showInColumnChooser: true, visible: optionalFieldExistence.PKWiU, dataType: "string", editorOptions: { maxLength: 50 }, width: standardTextColWidth,
    },
    {
        dataField: "GTIN", caption: "GTIN", showInColumnChooser: true, visible: optionalFieldExistence.GTIN, dataType: "string", editorOptions: { maxLength: 20 }, width: standardTextColWidth,
    },
    {
        dataField: "CN", caption: "CN", showInColumnChooser: true, visible: optionalFieldExistence.CN, dataType: "string", editorOptions: { maxLength: 50 },  width: standardTextColWidth,
    },
    {
        dataField: "PKOB", caption: "PKOB", showInColumnChooser: true, visible: optionalFieldExistence.PKOB, dataType: "string", editorOptions: { maxLength: 50 }, width: standardTextColWidth,        
    },
    {
        dataField: "procedura", showInColumnChooser: true, caption: "Procedura", visible: optionalFieldExistence.procedura, width: proceduraColWidth, lookup: { dataSource: proceduraPossibleValues, displayExpr: "PrintableValue", valueExpr: "XmlValue" }
    },
    {
        dataField: "kwotaPodatkuAkcyzowego", showInColumnChooser: true, visible: optionalFieldExistence.kwotaPodatkuAkcyzowego, caption: "Kwota podatku akcyzowego", width: akcyzaColWidth, format: { type: "fixedPoint", precision: 2 }
    },
    {
        visible: showCurrency, showInColumnChooser: false, dataField: "kursWaluty", caption: "Kurs Waluty", width: amountColWidth, format: { type: "fixedPoint", precision: 6 }
    }
  ]

export const columnsBefore = (initShowNetto: boolean | null, optionalFieldExistenceBeforeCor: FieldsVisibilityBeforeCor, correctedInvIsInKsefix: boolean, showCurrency: boolean, showNetto: boolean, showOss: boolean) => [
    {
        dataField: "ID", caption: "L.p.", dataType: "number", cellTemplate: "idColumn", showInColumnChooser: false, allowEditing: false, width: idColWidth, 
    },
    {
        dataField: "P_6A",  caption: "Data dostawy / wykonania usługi", visible: (correctedInvIsInKsefix ? optionalFieldExistenceBeforeCor.P_6A : false), showInColumnChooser: (correctedInvIsInKsefix ? false : true), dataType: 'date', width: dateColWidth
    },
    {
        dataField: "nazwaTowaruLubUslugi", caption: "Nazwa towaru / usługi", showInColumnChooser: false, width: nazwaColWidth
    },
    {
        dataField: "ilosc", caption: "Ilość", showInColumnChooser: false, width: iloscColWidth
    },
    {
        dataField: "miara", caption: "Miara", showInColumnChooser: false, width: miaraColWidth
    },
    {
        dataField: "cenaJednostkowaNetto", caption: "Cena jednostkowa netto", visible: (correctedInvIsInKsefix ? initShowNetto === true : showNetto), showInColumnChooser: false, width: amountColWidth, format: { type: "fixedPoint", precision: 2 }
    },
    {
        dataField: "cenaJednostkowaBrutto", caption: "Cena jednostk. brutto", visible: (correctedInvIsInKsefix ? initShowNetto !== true : !showNetto), showInColumnChooser: false, width: amountColWidth, format: { type: "fixedPoint", precision: 2 }
    },
    {
        dataField: "wartoscSprzedazyNetto", caption: "Wartość sprzedaży netto", visible: (correctedInvIsInKsefix ? initShowNetto === true : showNetto), allowEditing: false, showInColumnChooser: false, width: amountColWidth, format: { type: "fixedPoint", precision: 2 }
    },
    {
        dataField: "wartoscSprzedazyBrutto", caption: "Wartość sprzedaży brutto", visible: (correctedInvIsInKsefix ? initShowNetto !== true : !showNetto), allowEditing: false, showInColumnChooser: false, width: amountColWidth, format: { type: "fixedPoint", precision: 2 }
    },
    {
        dataField: "rabatOpust", caption: "Rabat / Opust", visible: optionalFieldExistenceBeforeCor.rabatOpust, showInColumnChooser: true, width: amountColWidth, format: { type: "fixedPoint", precision: 2 }
    },
    {
        dataField: "kwotaPodatku", caption: "Kwota podatku", showInColumnChooser: true, visible: optionalFieldExistenceBeforeCor.kwotaPodatku, allowEditing: false, width: amountColWidth, format: { type: "fixedPoint", precision: 2 }
    },
    {
        dataField: "stawkaPodatku", caption: "Stawka podatku", showInColumnChooser: false, visible: (correctedInvIsInKsefix ? optionalFieldExistenceBeforeCor.stawkaPodatku : !showOss), width: taxRateColWidth, lookup:{ dataSource: p12PossibleValues, displayExpr: "PrintableValue", valueExpr: "XmlValue"}
    },
    {
        dataField: "stawkaPodatkuXII", dataType: "number", caption: "Stawka podatku OSS", visible: (correctedInvIsInKsefix ? optionalFieldExistenceBeforeCor.stawkaPodatkuXII : showOss), editorOptions: { max: 100, min: 0 }, showInColumnChooser: false, width: stawkaOssColWidth, format: { type: "fixedPoint", precision: 6 }
    },
    {
        dataField: "indeks", dataType: "string", visible: optionalFieldExistenceBeforeCor.indeks, caption: "Indeks", showInColumnChooser: true, editorOptions: { maxLength: 50 }, width: standardTextColWidth
    },
    {
        dataField: "towarUsługaZal15", visible: optionalFieldExistenceBeforeCor.towarUsługaZal15, dataType: "boolean", caption: "Towar lub usługa z zał. 15", width: zal15ColWidth, showInColumnChooser: !correctedInvIsInKsefix && !showOss
    },
    {
        dataField: "GTU", showInColumnChooser: true, visible: optionalFieldExistenceBeforeCor.GTU, caption: "GTU", width: gtuColWidth, lookup: { dataSource: gtuPossibleValues, displayExpr: "PrintableValue", valueExpr: "XmlValue" }
    },
    {
        dataField: "PKWiU", caption: "PKWiU", showInColumnChooser: true, visible: optionalFieldExistenceBeforeCor.PKWiU, dataType: "string", editorOptions: { maxLength: 50 }, width: standardTextColWidth,
    },
    {
        dataField: "GTIN", caption: "GTIN", showInColumnChooser: true, visible: optionalFieldExistenceBeforeCor.GTIN, dataType: "string", editorOptions: { maxLength: 20 }, width: standardTextColWidth,
    },
    {
        dataField: "CN", caption: "CN", showInColumnChooser: true, visible: optionalFieldExistenceBeforeCor.CN, dataType: "string", editorOptions: { maxLength: 50 },  width: standardTextColWidth,
    },
    {
        dataField: "PKOB", caption: "PKOB", showInColumnChooser: true, visible: optionalFieldExistenceBeforeCor.PKOB, dataType: "string", editorOptions: { maxLength: 50 }, width: standardTextColWidth,        
    },
    {
        dataField: "procedura", showInColumnChooser: true, caption: "Procedura", visible: optionalFieldExistenceBeforeCor.procedura, width: proceduraColWidth, lookup: { dataSource: proceduraPossibleValues, displayExpr: "PrintableValue", valueExpr: "XmlValue" }
    },
    {
        dataField: "kwotaPodatkuAkcyzowego", showInColumnChooser: true, visible: optionalFieldExistenceBeforeCor.kwotaPodatkuAkcyzowego, caption: "Kwota podatku akcyzowego", width: akcyzaColWidth, format: { type: "fixedPoint", precision: 2 }
    },
    {
        visible: (correctedInvIsInKsefix ? optionalFieldExistenceBeforeCor.kursWaluty : showCurrency), showInColumnChooser: false, dataField: "kursWaluty", caption: "Kurs Waluty", width: amountColWidth, format: { type: "fixedPoint", precision: 6 }
    }
  ]
  