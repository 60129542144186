
import { XfXApi } from "../../../api/XfXApi"
import { syncSettingInit, addPopupTitle, editPopupTitle } from "./const"
import notify from "devextreme/ui/notify"
import CustomStore from 'devextreme/data/custom_store';

function makeAsyncDataSource(data) {
  return new CustomStore({
    loadMode: 'raw',
    key: 'id',
    load() {
      return data
    },
  });
}

export const fetchOrganizationSyncSettings = async ({ setOrganizationSyncSettings }) => {
  try {
    const { data } = await XfXApi.OrganizationSyncSetting.apiTenantIdOrganizationSyncSettingOrganizationSyncSettingsGet(XfXApi.GetTenantId())
    setOrganizationSyncSettings(data)
  } catch (error) {

  }
}

export const fetchOrganizations = async ({ setOrganizations }) => {
  try {
    const { data } = await XfXApi.Organization.apiTenantIdOrganizationUserOrganizationsGet(XfXApi.GetTenantId())
    setOrganizations(makeAsyncDataSource(data))
  } catch (error) {

  }
}

export const handleSelectionChanged = ({ s, setSelectedOrganizationSyncSetting }) => {
  setSelectedOrganizationSyncSetting(s.selectedRowsData.length > 0 ? s.selectedRowsData[0] : null)
}

const setOrganizationSyncSettingDefaults = () => {
  syncSettingInit.useAdditionalEncryption = false
  syncSettingInit.asyncModeEnabled = false
  syncSettingInit.organizationId = null
  syncSettingInit.lastSyncDataAsSubject1Utc = null
  syncSettingInit.lastSyncDataAsSubject2Utc = null
  syncSettingInit.lastSyncDataAsSubject3Utc = null
  syncSettingInit.syncRequested = false
  syncSettingInit.receptionSyncOnAsSubject1 = false
  syncSettingInit.receptionSyncOnAsSubject2 = false
  syncSettingInit.receptionSyncOnAsSubject3 = false
  syncSettingInit.initialReceptionCheckupDate = new Date(Date.UTC(2022, 0, 1))
  syncSettingInit.largeWalkingWindowEnabled = false
  syncSettingInit.shippingSynchronizationEnabled = false
}

export const addOrganizationSyncSetting = ({ setOrganizationSyncSetting, setVisible,
  setPopupTitle, setOrganizationSyncSettingEdit }) => {
  setOrganizationSyncSettingDefaults()
  setOrganizationSyncSetting(syncSettingInit)
  setVisible(true)

  setPopupTitle(addPopupTitle)
  setOrganizationSyncSettingEdit(false)
}

export const editOrganizationSyncSetting = async ({
  dataGridOrganizationSyncSettings, setOrganizationSyncSetting, setVisible,
  setPopupTitle, setOrganizationSyncSettingEdit }) => {
  const selected = dataGridOrganizationSyncSettings.current.instance.getSelectedRowsData()
  if (selected.length === 0)
    return

  const currentOrganizationSyncSetting = { ...selected[0] }

  setOrganizationSyncSetting(currentOrganizationSyncSetting)

  setVisible(true)
  setPopupTitle(editPopupTitle)
  setOrganizationSyncSettingEdit(true)
}

export const deleteOrganizationSyncSetting = async (dataGridOrganizationsSyncSettings, setOrganizationSyncSettings, t) => {
  const selected = dataGridOrganizationsSyncSettings.current.instance.getSelectedRowsData()
  if (selected.length === 0)
    return

  try {
    const response = await XfXApi.OrganizationSyncSetting.apiTenantIdOrganizationSyncSettingIdDelete(selected[0].id, XfXApi.GetTenantId())

    if (response.status === 200) {
      notify({
        message: t("#_organizationsyncsettings_actions_1"),
        position: {
          my: 'center bottom',
          at: 'center bottom',
        },
      }, 'success', 5000);
    }
  } catch (error) {
    if (error.response.status >= 400) {
      notify({
        message: `${error?.response?.data?.message}`,
        position: {
          my: 'center bottom',
          at: 'center bottom',
        },
      }, 'error', 10000);
    }
  }
  finally {
    await fetchOrganizationSyncSettings({ setOrganizationSyncSettings })
  }
}

export const saveAction = async ({ organizationSyncSettingEdit, organizationSyncSetting, popup, setOrganizationSyncSettings, dataGridOrganizationSyncSettings, t, setErrors }) => {
  try {
    const orgId = organizationSyncSetting.organizationId ? organizationSyncSetting.organizationId[0] : null

    const response = organizationSyncSettingEdit ?
      await XfXApi.OrganizationSyncSetting.apiTenantIdOrganizationSyncSettingPut(XfXApi.GetTenantId(), {...organizationSyncSetting, organizationId: orgId }) :
      await XfXApi.OrganizationSyncSetting.apiTenantIdOrganizationSyncSettingPost(XfXApi.GetTenantId(), {...organizationSyncSetting, organizationId: orgId })

    if (response.status === 200) {
      notify({
        message: `${t("#_organizationsyncsettings_actions_2")} ${organizationSyncSettingEdit ? t("#_organizationsyncsettings_actions_3") : t("#_organizationsyncsettings_actions_4")}`,
        position: {
          my: 'center bottom',
          at: 'center bottom',
        },
      }, 'success', 5000);
      popup.close()
    }
  } catch (error) {
    if (error.response.status >= 400) {
      if (error.response.status !== 422)
      {
        notify({
          message: `${error?.response?.data?.message}`,
          position: {
            my: 'center bottom',
            at: 'center bottom',
          },
        }, 'error', 10000);
      }
      else
      {
        setErrors(error.response.data)

        const errorsWithoutFieldname = error.response.data.filter(x => x.fieldName === '').map(x => x.errors) 

        if (errorsWithoutFieldname?.length > 0)
        {
          notify({
            message: `${errorsWithoutFieldname[0]}`,
            position: {
              my: 'center bottom',
              at: 'center bottom',
            },
          }, 'error', 10000);
        }
      }
    }
  }
  finally {
    await fetchOrganizationSyncSettings({ setOrganizationSyncSettings })
    dataGridOrganizationSyncSettings.current.instance.clearSelection()
  }
}