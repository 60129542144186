type KeyValueObject = { [key: string]: any };

export const filterKeys = (obj: KeyValueObject, keys: string[]): KeyValueObject => {
  const filteredObj: KeyValueObject = {};
  const lowerCaseKeys = keys.map(key => key.toLowerCase());

  Object.keys(obj).forEach(originalKey => {
    const keyLowerCase = originalKey.toLowerCase();
    if (lowerCaseKeys.includes(keyLowerCase)) {
      filteredObj[originalKey] = obj[originalKey];
    }
  });

  return filteredObj;
}


function capitalizeFirstLetter(str: string): string {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export const transformKeysToStartWithUpperCase = (obj: Record<string, any>): Record<string, any> => {
  return Object.keys(obj).reduce((newObj: Record<string, any>, key: string) => {
    const capitalizedKey = capitalizeFirstLetter(key);
    newObj[capitalizedKey] = obj[key];
    return newObj;
  }, {});
}

export const escapeRegExp = (string: string) => {
  return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
}

export const findMaxNumberInPattern = (prefix: string, strings: string[]): number => {
  let maxNumber = -1;
  const escapedPrefix = escapeRegExp(prefix);
  const pattern = new RegExp(`^${escapedPrefix}\\[(\\d+)\\]`);

  strings.forEach(str => {
    const match = str.match(pattern);
    if (match) {
      const number = parseInt(match[1]);
      if (number > maxNumber) {
        maxNumber = number;
      }
    }
  });

  return maxNumber;
}

export const cleanObject = (obj: any, condition: (key: string, value: any) => boolean): any => {
  const ret = { ...obj };
  Object.keys(ret).forEach(key => {
    const value = ret[key];
    if (!condition(key, value)) {
      delete ret[key];
    }
  });
  return ret;
}