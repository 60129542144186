import { GetOdataDataSource } from 'utils/dataSourceHelper'
import { InvoicesEndpoints, XfXApi } from '../../../api/XfXApi'
import  utcToLocal from 'utils/utcToLocal';
import { utcToLocalDateOnly } from 'utils/utcToLocal';
import { batchPackageGridType, buttonTypes } from './const';

const batchProcessingStatusPrefix = 'batch-processing-status-'

const toLabel = ({ value, t, dataSource, prefix, subValue }) => {
  return dataSource?.map(x => x.value)?.includes(value) ? t(`${prefix}${value.toLowerCase()}`) : (subValue ? subValue : value)
}

const mapLookupDataSource = ({ t, dataSource, prefix }) => {
  return dataSource?.map(({ key, value }) => ({ name: value, description: t(`${prefix}${value.toLowerCase()}`)}))
}

const lookupDataSource = ({ t, dataSource, prefix }) => {
  return {
    store: {
        type: 'array',
        data: mapLookupDataSource({ t, dataSource, prefix }),
        key: 'name'
    },
  }
}

const disabledByPrivilege = (endpoint, hasAnyPrivilege, organizationId) => {
  let hasPrivilege = false
  switch (endpoint) {
    case InvoicesEndpoints.DispatchSaleProcessing(organizationId):
    case InvoicesEndpoints.DispatchSaleProcessingOffline(organizationId):
      hasPrivilege = hasAnyPrivilege(['dispatch_sales_processing'], organizationId)
      break;
    case InvoicesEndpoints.DispatchSaleFailed(organizationId):
      hasPrivilege = hasAnyPrivilege(['dispatch_sales_failed'], organizationId)
      break;
    case InvoicesEndpoints.RepositorySales(organizationId):
      hasPrivilege = hasAnyPrivilege(['sales'], organizationId)
      break;
    case InvoicesEndpoints.DispatchSelfInvoicingProcessing(organizationId):
    case InvoicesEndpoints.DispatchSelfInvoicingProcessingOffline(organizationId):
      hasPrivilege = hasAnyPrivilege(['dispatch_selfinvoicing_processing'], organizationId)
      break;
    case InvoicesEndpoints.DispatchSelfInvoicingFailed(organizationId):
      hasPrivilege = hasAnyPrivilege(['dispatch_selfinvoicing_failed'], organizationId)
      break;
    case InvoicesEndpoints.RepositoryPurchase(organizationId):
      hasPrivilege = hasAnyPrivilege(['purchaser'], organizationId)
      break;
    case InvoicesEndpoints.DispatchOtherProcessing(organizationId):
      hasPrivilege = hasAnyPrivilege(['dispatch_other_processing'], organizationId)
      break;
    case InvoicesEndpoints.DispatchOtherFailed(organizationId):
      hasPrivilege = hasAnyPrivilege(['dispatch_other_failed'], organizationId)
      break;
    case InvoicesEndpoints.RepositoryOther(organizationId):
      hasPrivilege = hasAnyPrivilege(['other'], organizationId)
      break;
    default:
      break;
  }

  return hasPrivilege
}

export const getDisabled = async (Id, hasAnyPrivilege, organizationId, gridType, buttonType) => {
    let disabled = true

    if (Id === undefined || Id === null)
      return disabled

    const endpoint = gridType === batchPackageGridType.DispatchType && buttonType === buttonTypes[0] ? InvoicesEndpoints.DispatchSaleProcessing(organizationId) :
                     gridType === batchPackageGridType.DispatchType && buttonType === buttonTypes[1] ? InvoicesEndpoints.DispatchSaleFailed(organizationId) :
                     gridType === batchPackageGridType.DispatchType && buttonType === buttonTypes[2] ? InvoicesEndpoints.RepositorySales(organizationId) :
                     gridType === batchPackageGridType.DispatchType && buttonType === buttonTypes[3] ? InvoicesEndpoints.DispatchSaleProcessingOffline(organizationId) :
                     gridType === batchPackageGridType.SelfInvoicingType && buttonType === buttonTypes[0] ? InvoicesEndpoints.DispatchSelfInvoicingProcessing(organizationId) :
                     gridType === batchPackageGridType.SelfInvoicingType && buttonType === buttonTypes[1] ? InvoicesEndpoints.DispatchSelfInvoicingFailed(organizationId) :
                     gridType === batchPackageGridType.SelfInvoicingType && buttonType === buttonTypes[2] ? InvoicesEndpoints.RepositoryPurchase(organizationId) :
                     gridType === batchPackageGridType.SelfInvoicingType && buttonType === buttonTypes[3] ? InvoicesEndpoints.DispatchSelfInvoicingProcessingOffline(organizationId) :
                     gridType === batchPackageGridType.OtherType && buttonType === buttonTypes[0] ? InvoicesEndpoints.DispatchOtherProcessing(organizationId) :
                     gridType === batchPackageGridType.OtherType && buttonType === buttonTypes[1] ? InvoicesEndpoints.DispatchOtherFailed(organizationId) :
                     InvoicesEndpoints.RepositoryOther(organizationId)

    if(!disabledByPrivilege(endpoint, hasAnyPrivilege, organizationId))
      return disabled

    const dataSourceOptions = GetOdataDataSource(endpoint, null,
      ['Id', 'BatchId'], ['BatchId', '=', Id], null);

    await dataSourceOptions.load()
        .then((x) => {
          disabled = x?.length === 0
        }).catch(e => {
          disabled = true
        });
    return disabled
}

export const deleteBatch = async ({ organizationId, id, reloadGrid, setSelectedItem }) => {

    await XfXApi.BatchApi.apiTenantIdOrganizationIdBatchDelete(id, XfXApi.GetTenantId(), organizationId)
    .then(x => {})
    .catch(e => {})
    .finally(f => {
      setSelectedItem(null)
      reloadGrid()
    })
}

export const columns = ({ t, batchProcessingStatusDataSource }) => [
  { dataField: "CanBeDeleted", dataType: "boolean", visible: false, showInColumnChooser: false },
  { dataField: "CanBeSigned", dataType: "boolean", visible: false, showInColumnChooser: false },
  { dataField: "ExternalTrackingId", dataType: "string", caption: t("#_batchtools_14"), minWidth: 700 },
  { dataField: "OrganizationNip", dataType: "string", caption: t("#_batchtools_10") },
  { dataField: "CreationDateUtc", dataType: "datetime", caption: t("#_batchtools_11"), cellRender: x => utcToLocal(x.values[x.columnIndex]) },
  { dataField: "NumberOfInvoices", dataType: "number", caption: t("#_batchtools_17") },
  { dataField: "ScheduledDateUtc", dataType: "datetime", caption: t("#_batchtools_16"), cellRender: x => utcToLocalDateOnly(x.values[x.columnIndex]), visible: false, showInColumnChooser: true },
  { 
    dataField: "Status", dataType: "string", caption: t("#_batchtools_12"), calculateDisplayValue: x => toLabel({ value: x.Status, t, dataSource: batchProcessingStatusDataSource, prefix: batchProcessingStatusPrefix }),
    lookupValueExpr: "name", lookupDisplayExpr: "description", lookupDataSource: lookupDataSource({ t, dataSource: batchProcessingStatusDataSource, prefix: batchProcessingStatusPrefix }), 
    editorOptions: {
      dropDownOptions: {
        minWidth: '350px'
      }
    }
  },
  { dataField: "UpoNumber", dataType: "string", caption: t("#_batchtools_15"), minWidth: 300  },
  { dataField: "DispatchType", dataType: "string", caption: t("#_batchtools_13"), visible: false, showInColumnChooser: false },
]
