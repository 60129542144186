import { useRef } from "react"
import RadioGroup from 'devextreme-react/radio-group'
import { Form, GroupItem, SimpleItem, Label } from 'devextreme-react/form';
import OrganizationsDropDown from  'components/dropdown/OrganizationsDropDown.tsx'
import FileUploader from 'devextreme-react/file-uploader';
import SelectBox from 'devextreme-react/select-box';

const labelMode = 'outside'
const labelLocation = 'top'
const readOnly = false
const showColonAfterLabel = false
const minColWidth = 300
const fileExtensions = ['.docx', '.xsl'];
const fileTypes = ".docx,.xsl"

const DocumentTemplateEditor = ({
    state,
    setState,
    vgForm,
    allOrganizationsCustomStore,
    setTemplateFile,
    templateFile,
    invKindDataSource,
    t,
    formKey,
    getFirstError, 
    noErrors, 
    clearError,
    templateFileTypesDataSource
}) => {
    const fileUploaderRef = useRef()
    const isDispatchOptions = [
        { value: true, text: t("#_documenttemplateseditor_1") },
        { value: false, text: t("#_documenttemplateseditor_2") } 
    ];

    const formFieldDataChanged = (e) => {
        if (e.dataField) {
          clearError(e.dataField);
        }
    }

    return <> <Form
        id="form"
        ref={vgForm}
        labelMode={labelMode}
        readOnly={readOnly}
        showColonAfterLabel={showColonAfterLabel}
        labelLocation={labelLocation}
        minColWidth={minColWidth}
        formData={state}
        onFieldDataChanged={formFieldDataChanged}
        validationGroup={"DocumentTemplateValidationGroup"}
        key={formKey}
        className={"xfx-form"}>
        <GroupItem>
            <SimpleItem cssClass="xfx-required" dataField="isDispatch">
                <Label text={t("#_documenttemplateseditor_3")} />
                <RadioGroup 
                    value={state.isDispatch}
                    onValueChange={e => {
                        setState({ ...state, isDispatch: e })
                        clearError('isDispatch')
                    }}
                    displayExpr="text"
                    valueExpr="value"
                    dataSource={isDispatchOptions}
                    validationErrors={getFirstError("isDispatch")}
                    isValid={noErrors("isDispatch")}
                />
            </SimpleItem>

            <SimpleItem dataField="name" cssClass="xfx-required" editorType="dxTextBox" value={state.name}
                editorOptions={{
                    validationErrors: getFirstError("name"),
                    isValid: noErrors("name")
                }}
            >
                <Label text={t("#_documenttemplateseditor_5")} />
            </SimpleItem>

            <SimpleItem cssClass="xfx-required">
                <Label text={t("#_documenttemplateseditor_7")} />
                <OrganizationsDropDown
                    value={state.organizationIds}
                    t={t}
                    setValue={v => { setState({ ...state, organizationIds: v });}}
                    allOrganizations={allOrganizationsCustomStore}
                    useFluentValidation={true}
                    validationErrors={getFirstError("organizationIds")}
                    isValid={noErrors("organizationIds")}
                    clearError={clearError}
                    clearErrorField={'organizationIds'}
                ></OrganizationsDropDown>
            </SimpleItem>

            <SimpleItem cssClass="xfx-required" dataField="invKindDataSource">
                <Label text={t("#_documenttemplateseditor_9")} />
                <SelectBox 
                    placeholder={t("#_documenttemplateseditor_10")}
                    showClearButton={false}
                    items={invKindDataSource}
                    value={state.invoiceKind}
                    valueExpr="key"
                    displayExpr="value"
                    label={t("#_documenttemplateseditor_11")}
                    onValueChanged={v => {
                        setState({ ...state, invoiceKind: v.value }) 
                        clearError('invoiceKind')
                    }}
                    validationErrors={getFirstError("invoiceKind")}
                    isValid={noErrors("invoiceKind")}
                />
            </SimpleItem>
            <SimpleItem cssClass="xfx-required">
                <Label text={t("#_documenttemplateseditor_22")} />
                <SelectBox 
                    placeholder={t("#_documenttemplateseditor_21")}
                    showClearButton={false}
                    items={templateFileTypesDataSource}
                    value={state.templateFileType}
                    valueExpr="key"
                    displayExpr="value"
                    label={t("#_documenttemplateseditor_22")}
                    onValueChanged={v => {
                        setState({ ...state, templateFileType: v.value }) 
                        clearError('templateFileType')
                    }}
                    validationErrors={getFirstError("templateFileType")}
                    isValid={noErrors("templateFileType")}
                />
            </SimpleItem>
            <SimpleItem cssClass="xfx-required">
                <Label text={t("#_documenttemplateseditor_13")} />
                <FileUploader
                    ref={fileUploaderRef}
                    accept={fileTypes}
                    allowCanceling={false}
                    selectButtonText={t("#_documenttemplateseditor_14")}
                    labelText={t("#_documenttemplateseditor_15")}
                    multiple={false}
                    value={templateFile}
                    showFileList={true}
                    uploadMode="instantly"
                    uploadFile={async (file) => {
                        setTemplateFile([file])
                        clearError('file')
                    }}
                    allowedFileExtensions={fileExtensions}
                    invalidFileExtensionMessage={t("#_documenttemplateseditor_16")}
                    uploadedMessage={t("#_documenttemplateseditor_17")}
                    readyToUploadMessage={t("#_documenttemplateseditor_18")}
                    uploadFailedMessage={t("#_documenttemplateseditor_19")}
                    name={'file'}
                    validationErrors={getFirstError("file")}
                    isValid={noErrors("file")}
                />
            </SimpleItem>
        </GroupItem>
    </Form></>
}

export default DocumentTemplateEditor