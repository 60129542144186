import React, { useState, useEffect } from 'react';
import 'devextreme-react/autocomplete';
import { DropDownBox } from 'devextreme-react/drop-down-box';
import CustomStore from 'devextreme/data/custom_store';
import TreeView from 'devextreme-react/tree-view';
import { useTranslation } from 'react-i18next';

const makeAsyncDataSource = ({ data }) => {
  return new CustomStore({
    loadMode: 'raw',
    key: 'id',
    load() {
      return data
    },
  });
}

const ColumnsDropDown = ({ value, setValue, columns, treeViewRef }) => {
  const { t } = useTranslation()
  const [treeBoxValue, setTreeBoxValue] = useState()

  useEffect(() => {
    setTreeBoxValue(value)
    if (value !== undefined) {
    }
  }, [value])

  const [allColumns, setAllColumns] = useState(makeAsyncDataSource({
    data: columns
  }))

  const syncTreeViewSelection = (e) => {
    const treeView = (e.component.selectItem && e.component)
      || (treeViewRef && treeViewRef.instance);

    if (treeView) {
      if (e.value === null) {
        treeView.unselectAll();
      } else {
        const values = e.value || treeBoxValue;
        values && values.forEach((value) => {
          treeView.selectItem(value);
        });
      }
    }

    if (e.value !== undefined) {
      setTreeBoxValue(e.value)
    }
  }

  const treeViewItemSelectionChanged = (e) => {
    const values = e.component.getSelectedNodeKeys()
    setTreeBoxValue(values)
    setValue(values)
  };

  const treeViewRender = () => {
    return (
      <TreeView dataSource={allColumns}
        ref={treeViewRef}
        dataStructure="plain"
        keyExpr="id"
        parentIdExpr="categoryId"
        selectionMode="multiple"
        showCheckBoxesMode="normal"
        selectNodesRecursive={false}
        displayExpr="name"
        selectByClick={true}
        onContentReady={syncTreeViewSelection}
        onItemSelectionChanged={treeViewItemSelectionChanged}
      />
    );
  }

  return <DropDownBox
    value={treeBoxValue}

    valueExpr="id"
    displayExpr="name"
    placeholder={t("#_columnsdropdown_5")}
    showClearButton={false}
    dataSource={allColumns}
    onValueChanged={syncTreeViewSelection}
    contentRender={treeViewRender}
  />
}

export default ColumnsDropDown
