import { Form as FormModel, FormField, FieldType, FieldGroup, Section } from "../model"
import { isValidNIP } from "../validators";

export const GetFormErrors = (model: FormModel, formValues: Record<string, any>): Record<string, string | null> => {
  const fields: FormField[] = [];
  model.Sections.forEach(section => {
    section.Groups.forEach(group => {
      group.Fields.forEach(field => {
        fields.push(field);
      });
    });
  });
  const errors: Record<string, string | null> = {}
  fields.forEach(element => {
    const val = formValues[element.Identifier]

    if (element.MfType === "TNrNIP" && val !== undefined) {


      const pattern = /^\d{10}$/
      if (!pattern.test(val as string)) {
        errors[element.Address] = "Niepoprawny format NIP"
        return
      }

      if (!isValidNIP(val as string)) {

        errors[element.Address] = "Nieprawidłowy NIP - błędna suma kontrolna"
        return
      }
    }


    if (element.Type === FieldType.Formula || !element.IsRequired) {
      errors[element.Address] = null
    } else {
      errors[element.Address] = (val === undefined || val === "") ? "Pole wymagane" : null;
    }

  });

  return errors
}