import { Privileges } from 'privileges';
import { Fa1InvoiceItem } from './Fa1InvoiceItem'

export type Condition = {
    Expression: string;
};

export enum FieldType {
    CheckBox,
    ComboBox,
    TextBox,
    IntBox,
    DecimalBox,
    DateBox,
    DateTimeBox,
    Sequence,
    Formula,
    InvNumberCustom,
    SystemInfoComboBox,
    OrganizationNip
}

export type PossibleValue = {
    XmlValue: string | null;
    PrintableValue: string;
};


export type FormField = {
    Identifier: string;
    Name: string;
    Type: FieldType;
    Conditions: Condition[];
    Invisible: boolean | null;
    Row: number;
    IsRequired: boolean;
    PossibleValues?: PossibleValue[];
    PossibleValuesId?: string;
    Address: string;
    MfType: string | null;
    DependentFields: FormField[] | null;
    MinOccurs: number | null;
    MaxOccurs: number | null;
    Formula: string | null;
    Value: any;
    IsReadOnly: boolean;
    Description?: string | null;
    DescriptionType?: DescriptionType | null;
    LockInfo?: string;
};

export type FieldGroup = {
    Name: string;
    Fields: FormField[];
    Id: string;
};

export type Section = {
    Groups: FieldGroup[];
};

export type Form = {
    Name: string;
    Sections: Section[];
    Version: string;
};

export enum EditorType {
    Vat = 0,
    Unknown = 1,
    Correction = 2,
    CorrectionSelfInvoice = 3,
}

export enum EditorTokensSource {
    NewInvoice,  // For new Invoice
    Invoice, // From Invoice with xml
    Draft   // From Draft with tokens
}

export interface RowsSummary {
    netto: number;
    vat: number;
    brutto: number;
    vatInPln: number;
}

// export interface Fa1InvoiceItem {

// }

export interface CorrectionRowsEditorProps {
    invoiceItems: Fa1InvoiceItem[];
    setInvoiceItems: (value: Fa1InvoiceItem[]) => void;
    FieldGroup: FieldGroup,
    fieldsVisiblityMap: { [key: string]: { isVisible: boolean, field: FormField }; }
    showP_6A: boolean;
    showCurrency: boolean;
    editMode: boolean;
    setShowOssForBeforeCor: (value: boolean) => void;
    setShowOssForAfterCor: (value: boolean) => void;
    showOssForBeforeCor: boolean;
    showOssForAfterCor: boolean;
    showNetto: boolean;
    initShowNetto: boolean | null;
    correctedInvIsInKsefix: boolean;
    noRowsMode: boolean;
    setNoRowsMode: (value: boolean) => void;
    clearRowsTemp: boolean;
    isReadOnly: boolean;
}


export interface FieldsVisibility {
    rabatOpust: boolean;
    indeks: boolean;
    towarUsługaZal15: boolean;
    GTU: boolean;
    PKWiU: boolean;
    GTIN: boolean;
    CN: boolean;
    PKOB: boolean;
    procedura: boolean;
    kwotaPodatkuAkcyzowego: boolean;
    kwotaPodatku: boolean;
}

export interface FieldsVisibilityBeforeCor extends FieldsVisibility {
    P_6A: boolean;
    cenaJednostkowaNetto: boolean;
    cenaJednostkowaBrutto: boolean;
    wartoscSprzedazyNetto: boolean;
    wartoscSprzedazyBrutto: boolean;
    stawkaPodatku: boolean;
    stawkaPodatkuXII: boolean;
    kursWaluty: boolean;
}

export enum DescriptionType {
    ShowAlways,
    ShowWhenCorrected,
    OnlyForKorType
}

export type UserInfo = {
    isAdmin: boolean;
    privileges: Privileges[];
}


export enum InvoiceDateType {
    Common = "Common",
    SameAsIssueDate = "SameAsIssueDate",
    Various = "Various",
    Period = "Period",
}

export interface IOrganization { nip: string, fullName: string }