import { XfXApi } from "api/XfXApi";
import notify from 'devextreme/ui/notify';
import { DraftStatus } from "api/openapi-invoice";

interface CreateOrUpdateDraftProps {
    draftId: string | null;
    organizationId: string;
    tokens: { [key: string]: any };
    isReady: boolean;
    keysForXml: string[];
}

interface CreateOrUpdateDraftReturn {
    isSuccessful: boolean;
    draftId: string | null;
    status: DraftStatus | null;
    draftOrganizationId: string | null; // draft context can be changed beacuse of "automigration"
}

export const createOrUpdateDraft = async ({ draftId, organizationId, tokens, keysForXml, isReady }: CreateOrUpdateDraftProps): Promise<CreateOrUpdateDraftReturn> => {
    try {
        if (draftId === null) {
            const response = await XfXApi.DraftApi.apiTenantIdOrganizationIdInvoicedraftPost(XfXApi.GetTenantId(), organizationId, {
                tokens: tokens,
                expectedStatus: isReady ? DraftStatus.Ready : DraftStatus.InProgress,
                keysForXml
            });
            return {
                isSuccessful: true,
                draftId: response.data.id ?? null,
                status: response.data.status ?? null,
                draftOrganizationId: response.data.ksefContext ?? null
            }
        } else {
            const response = await XfXApi.DraftApi.apiTenantIdOrganizationIdInvoicedraftIdPut(draftId, XfXApi.GetTenantId(), organizationId, {
                tokens: tokens,
                expectedStatus: isReady ? DraftStatus.Ready : DraftStatus.InProgress,
                keysForXml,
            });
            return {
                isSuccessful: true,
                draftId: draftId,
                status: response.data.status ?? null,
                draftOrganizationId: response.data.ksefContext ?? null
            }
        }
    } catch (error) {
        console.error("Error in createOrUpdateDraft:", error);
        console.error("error.response.status");
        // @ts-ignore
        if (error.response.status === 400) {
            notify({
                //@ts-ignore
                message: error?.response?.data?.XfxDetails?.join(', '),
                position: {
                    my: 'center bottom',
                    at: 'center bottom',
                },
            }, 'error', 10000);
        }
        return {
            isSuccessful: false,
            draftId: null,
            status: null,
            draftOrganizationId: null
        }
    }
}
