import React, { useState, useRef, useEffect } from 'react';
import BreadCrumbs from 'components/breadCrumbs/BreadCrumbs';
import { getNode } from 'components/breadCrumbs/getNode';
import { useTranslation } from 'react-i18next';
import GenericToolbar from '../Toolbars/GenericToolbar';
import { OdataEndpoints, KsefixEndpoints } from 'api/XfXApi';
import notify from 'devextreme/ui/notify';
import GenericOdataGrid from 'pages/Invoices/GenericOdataGrid';
import useDataGridFilter from 'utils/useDataGridFilter';
import useFormErrors from 'utils/useFormErrors';
import { columns } from './const';
import axios, { AxiosResponse } from 'axios';
import ImportPopup from './ImporterPopup';
import ExporterPopup from './Exporter/ExporterPopup';

const ConfigImport = () => {
    const initialFilter: any[] = [];
    const [blockAutoRefresh, setBlockAutoRefresh] = useState(false);
    const [data, setData] = useState<any[]>([]);
    const emptyState = { name: null };
    const { dataGridFilter, updateDataGridFilter } = useDataGridFilter({ initialFilter, queryArguments: undefined });
    const [state, setState] = useState(emptyState);
    const [file, setFile] = useState<any[]>([]);
    const [selectedItem, setSelectedItem] = useState<any>(null);
    const [reloadKey, setReloadKey] = useState(0);
    const [dataSourceOptions, setDataSourceOptions] = useState();
    const dataGridRef = useRef(null);

    const { setErrors, getFirstError, noErrors, clearError, clearAllErrors } = useFormErrors();
    const { t } = useTranslation();
    const defaultSort = { selector: "CreationDateUtc", desc: true };

    useEffect(() => {
        setData(columns({ t }));
    }, [t, setData]);





    const downloadDataSource = async () => {
        try {
            if (selectedItem != null) {
                const url = KsefixEndpoints.GetConfigFile(selectedItem.Id);
                const now = new Date();
                const year = now.getFullYear();
                const month = String(now.getMonth() + 1).padStart(2, '0');
                const day = String(now.getDate()).padStart(2, '0');
                const hours = String(now.getHours()).padStart(2, '0');
                const minutes = String(now.getMinutes()).padStart(2, '0');
                const seconds = String(now.getSeconds()).padStart(2, '0');
                const formattedDate = `${year}-${month}-${day}_${hours}${minutes}${seconds}`;

                const fileName = `${selectedItem.Name}_${formattedDate}.json`;

                const resp = await axios.request<Blob>({
                    url: url,
                    method: "GET",
                    responseType: 'blob',
                });
                saveFile(resp, fileName)
            }
        } catch (error: any) {
            console.error(error);
        }
    };

    const saveFile = (response: AxiosResponse<Blob>, fileName: string): void => {
        if (response.status !== 200) return;
        const downloadUrl = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = downloadUrl;
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
        link.remove();
    };

    const downloadReportContent = async () => {
        try {
            if (selectedItem != null) {
                const url = KsefixEndpoints.GetReportFile(selectedItem.Id);
                const now = new Date();
                const year = now.getFullYear();
                const month = String(now.getMonth() + 1).padStart(2, '0');
                const day = String(now.getDate()).padStart(2, '0');
                const hours = String(now.getHours()).padStart(2, '0');
                const minutes = String(now.getMinutes()).padStart(2, '0');
                const seconds = String(now.getSeconds()).padStart(2, '0');
                const formattedDate = `${year}-${month}-${day}_${hours}${minutes}${seconds}`;

                const fileName = `ImportLog_${selectedItem.Name}_${formattedDate}.csv`;

                const resp = await axios.request<Blob>({
                    url: url,
                    method: "GET",
                    responseType: 'blob',
                });
                saveFile(resp, fileName);
            }
        } catch (error: any) {
            console.error(error);
        }
    };

    const importPopup = ImportPopup({
        state: state,
        setState: setState,
        file: file,
        setFile: setFile,
        reloadKey: reloadKey,
        setReloadKey: setReloadKey,
        blockAutoRefresh: blockAutoRefresh,
        setBlockAutoRefresh: setBlockAutoRefresh,
    });

    const openImportPopup = () => {
        setState({ ...emptyState });
        setFile([]);
        importPopup.show();
    };

    const exporterPopup = ExporterPopup({
        state: state,
        setState: setState,
        file: file,
        setFile: setFile,
        reloadKey: reloadKey,
        setReloadKey: setReloadKey,
        blockAutoRefresh: blockAutoRefresh,
        setBlockAutoRefresh: setBlockAutoRefresh,
    });

    const toolbarButtons = [
        { icon: 'add', text: t("button-config-import"), onClick: openImportPopup },
        { icon: 'export', text: t("button-config-export"), onClick: () => exporterPopup.show() },
        { icon: 'download', text: t("download-data-source-button"), onClick: downloadDataSource, disabled: selectedItem === null },
        { icon: 'doc', text: t("download-report-button"), onClick: downloadReportContent, disabled: selectedItem === null || selectedItem.Status !== 'Completed' },
    ];



    return (
        <>
            {importPopup.popup}
            {exporterPopup.popup}
            <BreadCrumbs node={getNode({ componentName: 'ConfigImport', t })} breadCrumbs={undefined}></BreadCrumbs>
            <GenericToolbar className="xfx-toolbar-bigbuttons" header={t("licenses")} buttons={toolbarButtons} customHeaderRenderer={undefined} toolbarId={'configImportToolbar'}></GenericToolbar>
            <div id="dataGrid-documentTemplates" className="dataGrid">
                {/* @ts-ignore */}
                {GenericOdataGrid({
                    ref: dataGridRef,
                    data: data,
                    endpoint: OdataEndpoints.ConfigImport(),
                    setSelectedItem: setSelectedItem,
                    defaultFilter: dataGridFilter,
                    defaultSort: defaultSort,
                    updateDataGridFilter: updateDataGridFilter,
                    gridKey: reloadKey,
                    dataSourceOptions: dataSourceOptions,
                    setDataSourceOptions: setDataSourceOptions,
                    blockAutoRefresh: blockAutoRefresh,
                    setBlockAutoRefresh: setBlockAutoRefresh
                })}
            </div>
        </>
    );
};

export default ConfigImport;
