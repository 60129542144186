import { useState, useEffect, useRef } from 'react';
import DataGrid, {
    Scrolling, Paging, Column, HeaderFilter, FilterRow, Selection, Search
} from 'devextreme-react/data-grid';
import GenericToolbar from '../Toolbars/GenericToolbar'
import PipelineEditor from './PipelineEditor'
import { XfXApi } from 'api/XfXApi';
import useToast from '../../../utils/useToast'
import { useRefreshButton } from 'utils/refresh'
import BreadCrumbs from 'components/breadCrumbs/BreadCrumbs';
import { getNode } from 'components/breadCrumbs/getNode';
import { useTranslation } from 'react-i18next';
import { commonPopupToolbarItems } from 'components/popup/PopupTools';
import GenericPopup from "components/popup/GenericPopup"
import DeletionPopup from 'components/popup/DeletionPopup';
import useFormErrors from 'utils/useFormErrors';
import CustomStore from 'devextreme/data/custom_store';
import { OdataEndpoints } from 'api/XfXApi';
import { GetOdataDataSource } from 'utils/dataSourceHelper'

const NULL_SYMBOL = '*'

const starForNull = (v, fieldName) => {
    return v[fieldName] ? v[fieldName] : NULL_SYMBOL
}

const starForNullOrganizationName = (v) => {
    return v['organizationName'] ? v['organizationName'] : (v['nip'] ? null : NULL_SYMBOL)
}

function makeAsyncDataSource(data) {
    return new CustomStore({
      loadMode: 'raw',
      key: 'nip',
      load() {
        return data
      },
    })
}


const calculatePipelineTypeValue = (v, t) => {
    switch (v.type) {
        case 0:
            return t("pipelines-type-undefined")
        case 1:
            return t("pipelines-type-sales")
        case 2:
            return t("pipelines-type-self-invoicing")
        case 3:
            return t("pipelines-type-other")
        case 4:
            return t("pipelines-type-purchases-entity2")
        case 5:
            return t("pipelines-type-purchases-entity3")
        case 6:
            return t("pipelines-type-sales-self-invoicing")
        default:
            return null
    }
}

const columns = ({ t }) => [
    <Column key="name" dataField="name" caption={t("#_pipelines_5")} />,
    <Column key="nip" dataField="nip" caption={t("#_pipelines_8")} calculateCellValue={v => starForNull(v, "nip")} />,
    <Column key="organizationName" dataField="organizationName" caption={t("#_pipelines_33")} calculateCellValue={v => starForNullOrganizationName(v)}/>,
    <Column key="fkSystem" dataField="fkSystem" caption={t("#_pipelines_12")} calculateCellValue={v => starForNull(v, "fkSystem")} />,
    <Column key="IsDispatch" dataField="IsDispatch" caption={t("#_pipelines_14")} calculateCellValue={v => v.isDispatch ? t("#_pipelines_15") : t("#_pipelines_16")} />,
    <Column key="type" dataField="type" caption={t("#_pipelines_17")} calculateCellValue={v => calculatePipelineTypeValue(v, t)} />,
]

const PipelinesGrid = () => {
    const { t } = useTranslation()
    const [allPipelines, setAllPipelines] = useState([])
    const [editVisible, setEditVisible] = useState(false)
    const [selectedItem, setSelectedItem] = useState(null)
    const [availableOrganizations, setAvailableOrganizations] = useState()
    const [availableSystems, setAvailableSystems] = useState()
    const [availableModules, setAvailableModules] = useState()
    const [availableUsers, setAvailableUsers] = useState()
    const [editPipeline, setEditPipeline] = useState()
    const [popupTitle, setPopupTitle] = useState()
    const [isNew, setIsNew] = useState(false)
    const [dispatchPipelineTypes, setDispatchPipelineTypes] = useState([])
    const [receptionPipelineTypes, setReceptionPipelineTypes] = useState([])
    const [deletionPopupVisible, setDeletionPopupVisible] = useState(false)
    const [deletionPopupContent, setDeletionPopupContent] = useState("")
    const [blockAutoRefresh, setBlockAutoRefresh] = useState(false)

    useRefreshButton(() => refresh())

    const { getFirstError, noErrors, setErrors, clearError } = useFormErrors();

    const updateStep = (step) => {
        return {
            ...step,
            valuesMap: deserializeStepSettings(step.settings)
        }
    }

    const updatePipeline = (p) => {
        const updated = { ...p, steps: p.steps !== null ? p.steps.map(updateStep) : [] }
        return updated
    }

    const refresh = () => {
        XfXApi.Pipeline.apiTenantIdPipelineGet(XfXApi.GetTenantId())
            .then(x => {
                const pipelines = x.data.map(updatePipeline);
                setAllPipelines(pipelines)
            })
    }

    const getAvailableOrganizations = () => {
        XfXApi.Organization.apiTenantIdOrganizationOrganizationsGet(XfXApi.GetTenantId())
            .then((resp) => {
                setAvailableOrganizations(makeAsyncDataSource([{ nip: NULL_SYMBOL, fullName: NULL_SYMBOL }, ...resp.data.map(x => ({ nip: x.nip, fullName: x.fullName }))]))
            })
    }

    const getAvailableSystems = async () => {
        const dataSource = GetOdataDataSource(OdataEndpoints.AccountingSystems(), null, ['Id', 'OrganizationIds', 'Name', 'Organizations'],
            null, null, null, null, null, false)
        
        await dataSource.load()
            .then((x) => {
                setAvailableSystems([NULL_SYMBOL, ...x.map(x => x.Name)])
            }).catch(e => {
                console.log(e)
            })
    }

    const getAvailableModules = () => {
        XfXApi.ModuleApi.apiTenantIdModuleGet(XfXApi.GetTenantId())
            .then((resp) => {
                setAvailableModules(resp.data.filter(x => x.isAvailable))
            })
    }

    const getAvailableUsers = () => {
        XfXApi.User.apiTenantIdUserUsersGet(XfXApi.GetTenantId())
            .then((resp) => {
                setAvailableUsers(resp.data.stateObject)
            })
    }

    const getDispatchPipelineTypes = () => {
        XfXApi.Pipeline.apiTenantIdPipelineDispatchTypesGet(XfXApi.GetTenantId())
            .then(x => {
                setDispatchPipelineTypes(x.data)
            })
    }

    const getReceptionPipelineTypes = () => {
        XfXApi.Pipeline.apiTenantIdPipelineReceptionTypesGet(XfXApi.GetTenantId())
            .then(x => {
                setReceptionPipelineTypes(x.data)
            })
    }

    useEffect(() => {
        refresh()
        getAvailableOrganizations()
        getAvailableSystems()
        getAvailableModules()
        getAvailableUsers()
        getDispatchPipelineTypes()
        getReceptionPipelineTypes()
    }, [t])

    const dataGridRef = useRef()

    const { toast, showToastError } = useToast()
 
    const serializeStepSettings = settings => {
        return JSON.stringify(Object.fromEntries(Object.entries(settings).map(x => [x[0], JSON.stringify(x[1])])))
    }

    const deserializeStepSettings = serializedSettings => {
        try {
            const settingsWithStringValues = JSON.parse(serializedSettings)
            return Object.fromEntries(Object.entries(settingsWithStringValues).map(x => [x[0], JSON.parse(x[1])]))
        } catch (error) {
            return null
        }
    }

    const savePipeline = async () => {
        const toSave = {
            ...editPipeline, steps: editPipeline.steps.map(x => {
                return {
                    ...x,
                    id: x.id,
                    settings: serializeStepSettings(x.valuesMap)
                }
            })
        };

        var saved = true;

        try{
            if(toSave.id === null)
                await XfXApi.Pipeline.apiTenantIdPipelinePost(XfXApi.GetTenantId(), toSave);
            else
                await XfXApi.Pipeline.apiTenantIdPipelinePut(XfXApi.GetTenantId(), toSave);
        }
        catch(x)
        {
                console.log(x)
                var toastMessage = x?.response?.data?.length > 0 && x?.response?.data[0]?.errors?.length > 0 ? x.response.data[0].errors[0] : t("#_pipelines_18")
                showToastError(toastMessage)
                saved = false;
                if (x?.response?.data) {
                    setErrors(x.response.data);
                }
        }

        if(saved)
        {
            refresh()
            popup.close()
        }
    }

    const deletionPopup = DeletionPopup({
        onConfirm: () =>{
            XfXApi.Pipeline.apiTenantIdPipelineIdDelete(selectedItem.id, XfXApi.GetTenantId())
            .then(refresh)
        },
        content: deletionPopupContent, 
        title: t("#_DeletionPopup_delete"),
        isVisible: deletionPopupVisible, 
        setIsVisible: setDeletionPopupVisible, 
        t: t,
        setBlockAutoRefresh: setBlockAutoRefresh
      })

    const deleteSelected = () => {
        if (selectedItem === null) return
        setDeletionPopupContent(t("#_DeletionPopup"));        
        deletionPopup.show();
    }

    const edit = () => {
        const selected = dataGridRef.current.instance.getSelectedRowsData()
        if (selected.length === 0) return

        setEditPipeline(selected[0])
        setEditPipeline({ ...selected[0], 
            nip: !selected[0]?.nip && !selected[0]?.organizationName ? null : selected[0]?.nip,
            fkSystem: selected[0]?.fkSystem ? selected[0].fkSystem : null
        })
        setPopupTitle(t("#_pipelines_21"))
        setIsNew(false)
        setEditVisible(true)
        clearError("id")
    }

    const addNew = () => {

        setEditPipeline({
            id: null,
            name: t("#_pipelines_22"),
            steps: [],
            nip: null,
            fkSystem: null,
            isDispatch: true
        })
        setPopupTitle(t("#_pipelines_23"))
        setIsNew(true)
        setEditVisible(true)
        clearError("id")
    }

    const toolbarButtons = [
        { icon: 'plus', text: t("#_pipelines_25"), onClick: addNew },
        { icon: 'edit', text: t("#_pipelines_27"), onClick: edit, disabled: selectedItem === null },
        { icon: 'trash', text: t("#_pipelines_29"), onClick: () => deleteSelected(), disabled: selectedItem === null }
    ]

    const popupToolbarItems = commonPopupToolbarItems({ onSave: () => savePipeline(), onCancel: () => popup.close(), t: t })
    const popup = GenericPopup({ 
        onHiding: () => popup.close(),
        content:
        <div>
          <PipelineEditor
            pipeline={editPipeline}
            setPipeline={setEditPipeline}
            availableOrganizations={availableOrganizations}
            availableSystems={availableSystems}
            availableModules={availableModules}
            availableUsers={availableUsers}
            isNew={isNew}
            dispatchPipelineTypes={dispatchPipelineTypes}
            receptionPipelineTypes={receptionPipelineTypes}
            getFirstError={getFirstError}
            noErrors={noErrors}
            clearError={clearError}
            nullSymbol={NULL_SYMBOL}
        ></PipelineEditor>
        </div>,
        toolbarItems: popupToolbarItems,
        title: popupTitle,
        width: "800px",
        height: "700px",
        isVisible: editVisible,
        setIsVisible: setEditVisible,
        setBlockAutoRefresh: setBlockAutoRefresh
      })

      useEffect(() => {
        if (editVisible)
          popup.show()
        else
          popup.close()
      }, [editVisible])

    return (
        <>
            {toast}
            {popup.popup}
            {deletionPopup.popup}
            <BreadCrumbs node={getNode({ componentName: 'PipelinesGrid', t })}></BreadCrumbs>
            <GenericToolbar className="xfx-toolbar-bigbuttons" header={t("#_pipelines_32")} buttons={toolbarButtons}></GenericToolbar>
            <div id="dataGrid" className="dataGrid">
                <DataGrid
                    ref={dataGridRef}
                    dataSource={allPipelines}
                    keyExpr="id"
                    showBorders={true}
                    rowAlternationEnabled={true}
                    showColumnLines={true}
                    onSelectionChanged={s => {
                        setSelectedItem(s.selectedRowsData.length > 0 ? s.selectedRowsData[0] : null)
                    }}
                >
                    <Selection mode="single" />
                    <Scrolling mode="virtual" preloadEnabled={true}/>
                    <Paging defaultPageSize={100} />
                    <HeaderFilter> 
                        <Search
                        enabled={true}
                        >
                        </Search>
                    </HeaderFilter>
                    <FilterRow visible={true} applyFilter={true} />
                    <HeaderFilter visible={true} />
                    {columns({ t })}
                </DataGrid>
            </div>
        </>
    );
}


export default PipelinesGrid 
