import { addOrganizationSyncSetting, editOrganizationSyncSetting } from "./actions"

export const toolbarButtons= ({ deleteOrganizationSyncSettings,
    selectedOrganizationSyncSetting, dataGridOrganizationSyncSettings, setOrganizationSyncSettings, setOrganizationSyncSetting,
    setVisible, setPopupTitle, setOrganizationSyncSettingEdit, t }) => {
    return [
        { icon: 'plus', 
          text: t("#_organizationsyncsettings_toolbarbuttons_1"), 
          onClick: async() => await addOrganizationSyncSetting({ setOrganizationSyncSetting, setVisible, 
            setPopupTitle, setOrganizationSyncSettingEdit }) },
        { icon: 'edit', 
          text: t("#_organizationsyncsettings_toolbarbuttons_2"), 
          onClick: async() => await editOrganizationSyncSetting({ dataGridOrganizationSyncSettings, setOrganizationSyncSetting, setVisible, 
            setPopupTitle, setOrganizationSyncSettingEdit
         }), disabled: selectedOrganizationSyncSetting === null },
         { icon: 'trash', 
            text: t("#_organizationsyncsettings_toolbarbuttons_3"), 
            onClick: async() => deleteOrganizationSyncSettings(), disabled: selectedOrganizationSyncSetting === null }
    ]
}