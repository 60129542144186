const RoleTypes = {
    Owner: "owner",
    Introspection: "introspection",
    Invoice_read: "invoice_read",
    Invoice_write: "invoice_write",
    Payment_confirmation_write: "payment_confirmation_write",
    Credentials_read: "credentials_read",
    Credentials_manage: "credentials_manage",
    Self_invoicing: "self_invoicing",
    Tax_representative: "tax_representative",
    Enforcement_operations: "enforcement_operations",
    Court_bailiff: "court_bailiff",
    Enforcement_authority: "enforcement_authority",
}

export default RoleTypes