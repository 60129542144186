import { useState } from 'react';
import { Form, GroupItem } from 'devextreme-react/form';
import { TextBox } from "devextreme-react/text-box";
import List, { ItemDragging } from 'devextreme-react/list';
import { Button } from 'devextreme-react/button';
import ModuleProperties from './ModuleProperties'
import SelectBox from 'devextreme-react/select-box';
import DropDownButton from 'devextreme-react/drop-down-button';
import { CheckBox } from 'devextreme-react';
import { useTranslation } from 'react-i18next';
import OrganizationsDropDown from  'components/dropdown/OrganizationsDropDown.tsx'

const PipelineEditor = ({ pipeline, setPipeline, availableOrganizations, availableSystems, availableModules, availableUsers, 
    isNew, dispatchPipelineTypes, receptionPipelineTypes, getFirstError, noErrors, clearError, nullSymbol }) => {
    const { t } = useTranslation()
    const types = [t("#_pipelineeditor_1"), t("#_pipelineeditor_2")]
    const [selectedModule, setSelectedModule] = useState(null)
    const [moduleNames, setModuleNames] = useState(availableModules.reduce((a, v) => ({ ...a, [v.id]: v.name }), {}))
    const [pipelineType, setPipelineType] = useState(pipeline.isDispatch ? types[0] : types[1])
    const [settingsVisible, setSettingsVisible] = useState(false)

    const [availablePipelineTypes, setAvailablePipelineTypes] = useState(pipeline.isDispatch ? dispatchPipelineTypes : receptionPipelineTypes)

    const isDispatch = (v) => {
        return v === types[0]
    }

    const addStep = (m) => {
        const module = availableModules.filter(x => x.name === m.itemData)[0]
        let defaultValues = module.properties.reduce((a, v) => ({ ...a, 
            [v.id]: v.values ? v.values[v.defaultValueIndex].id : null 
        }), {})
        
        defaultValues = handleDefaultValues(defaultValues)

        const updated = {
            ...pipeline, steps: [...pipeline.steps ?? [], {
                id: crypto.randomUUID(),
                moduleId: module.id,
                valuesMap: defaultValues,
                version: 1,
                isActive: true
            }]
        }
        setPipeline(updated)
    }
    
    const handleDefaultValues = (defaultValues) => {
        if (defaultValues['accept-type'] === 'users' && defaultValues['required-acceptations-count'])
        {
            const propNameToRemove = 'required-acceptations-count';
            const { [propNameToRemove]: removedProperty, ...rest } = defaultValues
            defaultValues = rest
        }
        else if (defaultValues['accept-type'] === 'count' && defaultValues['user-selection'])
        {
            const propNameToRemove = 'user-selection';
            const { [propNameToRemove]: removedProperty, ...rest } = defaultValues
            defaultValues = { ...rest, 'required-acceptations-count': 1 }
        }

        return defaultValues
    }

    const updateModuleIsActive = (isActive, id) => {
        const updated = {
            ...pipeline, steps: pipeline.steps === null ? null : pipeline.steps.map(s => {
                if (s.id === id) return { ...s, isActive: isActive }
                else return s
            })
        }
        setPipeline(updated)
    }

    const deleteStep = (step) => {
        setPipeline({ ...pipeline, steps: pipeline.steps.filter(x => x !== step) })
        if (selectedModule === step) {
            setSelectedModule(null)
        }
    }

    const ModuleInfo = (x) => {
        return <div className='moduleListItem'>
            <div>{moduleNames[x.moduleId]}</div>

            <div>
                <CheckBox defaultValue={x.isActive} onValueChanged={e => {
                    updateModuleIsActive(e.value, x.id)
                }}></CheckBox>

                <Button icon="toolbox" onClick={() => {
                    setSelectedModule(x)
                    setSettingsVisible(true)
                }} />
                <Button icon="trash" onClick={() => deleteStep(x)} />
            </div>
        </div>
    }

    const updateModuleValuesMap = (valuesMap, stepId) => {
        const update = {
            ...pipeline, steps: pipeline.steps === null ? null : pipeline.steps.map(s => {
                if (s.id === stepId) return { ...s, valuesMap: valuesMap }
                else return s
            })
        }
        setPipeline(update)
    }

    const wrapNull = (v) => v === null ? nullSymbol : v
    const unwrapNull = (v) => v === nullSymbol ? null : v

    const modulesDropdownOptions = {
        minWidth: '350px'
    }

    return <>
            <Form id="form" visible={true}>
                <GroupItem>
                    {isNew && <div className="dx-field">
                        <div className="dx-field-value">

                            <SelectBox
                                placeholder={t("#_pipelineeditor_6")}
                                showClearButton={false}
                                items={types}
                                value={pipelineType}
                                label={t("#_pipelineeditor_7")}           
                                onValueChanged={v => {
                                    setPipelineType(v.value)
                                    setPipeline(p=>({ ...p, isDispatch: isDispatch(v.value), type: null}))
                                    setAvailablePipelineTypes(v.value === types[0] ? dispatchPipelineTypes : receptionPipelineTypes)
                                }}
                            />
                        </div>
                    </div>}
                    {isNew && <div className="dx-field">
                        <div className="dx-field-value">
                            <SelectBox
                                placeholder={t("pipelines-select-kind")}
                                showClearButton={false}
                                items={availablePipelineTypes}
                                label={t("#_pipelineeditor_9")}
                                key="id"
                                displayExpr="name"
                                valueExpr="id"
                                value={pipeline.type}
                                onValueChanged={v => {
                                    setPipeline(p=>({ ...p, type: v.value }))                                    
                                    clearError("id");
                                }}                               
                                validationErrors={getFirstError("id")}
                                isValid={noErrors("id")}
                            />
                        </div>
                    </div>}
                    <div className="dx-field">
                        <div className="dx-field-value">
                            <TextBox
                                label={t("#_pipelineeditor_10")}
                                value={pipeline.name}
                                onValueChanged={v => setPipeline(p=>({ ...p, name: unwrapNull(v.value) }))}>
                            </TextBox>
                        </div>
                    </div>


                    <div className="dx-field">
                        <div className="dx-field-value">
                            <OrganizationsDropDown
                                value={wrapNull(pipeline.nip)}
                                setValue={v => setPipeline({ ...pipeline, nip: unwrapNull(v[0])})}
                                allOrganizations={availableOrganizations}
                                t={t}
                                placeholder={t("#_pipelines_34")}
                                label={t("#_pipelines_33")}
                                mode={'single'}
                                dropDownValueExpr={'nip'}
                                validationErrors={getFirstError('id')}
                                isValid={noErrors('id')}
                                useFluentValidation={true}
                            />
                        </div>
                    </div>

                    <div className="dx-field">
                        <div className="dx-field-value">
                            <SelectBox
                                items={availableSystems}
                                placeholder={t("#_pipelineeditor_13")}
                                showClearButton={false}
                                value={wrapNull(pipeline.fkSystem)}
                                label={t("#_pipelineeditor_14")}                                                               
                                validationErrors={getFirstError("id")}
                                isValid={noErrors("id")}
                                onValueChanged={v => {
                                    setPipeline({ ...pipeline, fkSystem: unwrapNull(v.value) })                                                           
                                    clearError("id")
                                }}
                            />
                        </div>
                    </div>
                </GroupItem>
            </Form>
            <div>Moduły:</div>
            <div className="dx-field">
                <DropDownButton
                    splitButton={false} x
                    useSelectMode={false}
                    width="150px"
                    text={t("#_pipelineeditor_16")}
                    noDataText={t("#_pipelineeditor_17")}
                    items={availableModules.filter(x => pipeline.isDispatch ? x.isDispatch : x.isReception).map(x => x.name)}
                    onItemClick={addStep}
                    dropDownOptions={modulesDropdownOptions}
                />
            </div>

            <List
                dataSource={pipeline.steps}
                selectionMode='none'
                repaintChangesOnly={true}
                itemRender={ModuleInfo}>
                <ItemDragging allowReordering={true}
                    onReorder={(e) => {
                        const moved = pipeline.steps[e.fromIndex]
                        var reorderedSteps = [...pipeline.steps]
                        reorderedSteps.splice(e.fromIndex, 1)
                        reorderedSteps.splice(e.toIndex, 0, moved)
                        const updated = {
                            ...pipeline,
                            steps: reorderedSteps
                        }
                        setPipeline(updated)
                    }}
                >
                </ItemDragging>
            </List>
        {selectedModule !== null && <>
        <ModuleProperties key={selectedModule.id} module={selectedModule} moduleDefinitions={availableModules}
                        updateModuleValuesMap={v => updateModuleValuesMap(v, selectedModule.id)}
                        setSettingsVisible={setSettingsVisible} settingsVisible={settingsVisible} selectedModule={selectedModule}
                         moduleNames={moduleNames} availableUsers={availableUsers}></ModuleProperties>
        </>}
    </>
}

export default PipelineEditor;
