import { useState } from 'react';
import { Toast } from 'devextreme-react/toast';
import Notify from 'devextreme/ui/notify';

const types = ['error', 'info', 'success', 'warning'];

export const showInfo = (msg) => {
    Notify({
        message: msg,
        type: "success",
        displayTime: 5000,
        position: {
            my: 'center bottom',
            at: 'center bottom',
        },
    });
}
