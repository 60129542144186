import {  DateTimeFormat } from 'utils/cellRenderers'
import { renderModificationDateCell,renderCreationDateCell } from 'utils/cellRenderers'

export const columns = ({ t, dataSource, reportTypeDataSource }) => 
[ 
    { dataField: "Name", dataType: "string", caption: t("#_reports_const_1"), visible: true },
    { dataField: "Organizations", dataType: "object", caption: t("#_reports_const_2"), 
        cellRender: x => orgatizationsRender(x), allowSorting: false, allowFiltering: true, visible: true },
    {
        dataField: "DataSource", dataType: "string", caption: t("#_reports_const_3"),
        visible: true, lookupValueExpr: "name", lookupDisplayExpr: "description", lookupDataSource: lookupDataSource(t, dataSource), 
        editorOptions: {
          dropDownOptions: {
            minWidth: '600px'
          }
        } 
    },
    {
      dataField: "ReportType", dataType: "string", caption: t("#_reports_const_7"), width: "160px",
      visible: true, lookupValueExpr: "name", lookupDisplayExpr: "description", lookupDataSource: lookupDataSource(t, reportTypeDataSource), 
      editorOptions: {
        dropDownOptions: {
          minWidth: '200px'
        }
      } 
    },
    { dataField: "CreationDateUtc", dataType: "datetime", caption: t("#_reports_const_4"), format: DateTimeFormat, width: "210px", cellRender:renderCreationDateCell,visible: true },
    { dataField: "ModificationDateUtc", dataType: "datetime", caption: t("#_reports_const_5"), format: DateTimeFormat, width: "210px", cellRender:renderModificationDateCell,visible: true },
]

export const colData = ( t ) => 
[ 
    { dataField: "caption", dataType: "string", caption: t("#_reports_const_6"), visible: true },
]

const orgatizationsRender = (orgList) => {
    return (
    <ul>
        {orgList?.value?.map(e => <li>{e}</li>)}
    </ul>)
}


const mapLookupDataSource = ( t, dataSource ) => {
    return dataSource?.map(({ key, value }) => ({ name: value, description: t(`${value}`)}))
  }
  
  const lookupDataSource = ( t, dataSource ) => {
    return {
      store: {
          type: 'array',
          data: mapLookupDataSource(t, dataSource),
          key: 'name'
      },
    }
  }