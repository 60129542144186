import axios from "axios";
import { InvoicesEndpoints, XfXApi } from 'api/XfXApi'
import { GetPrintFromXmlDto } from "api/openapi-invoice";

const getObjectUrl = async (data: any, url: string): Promise<string> => {
    const response = await axios
        .request({
            url: url,
            method: "POST",
            data: data,
            responseType: 'blob'
        });

    const type = response.headers["content-type"]
    const file = new Blob([response.data], { type: type });

    return URL.createObjectURL(file);
}

const saveToFile = (data: any, fileName: string) => {
    const downloadUrl = window.URL.createObjectURL(new Blob([data]));
    const link = document.createElement('a');
    link.href = downloadUrl;
    link.setAttribute('download', fileName);

    document.body.appendChild(link);
    link.click();
    link.remove();
}

const generateHtml = async (asFile: boolean, xml: string, organizationId: string): Promise<any> => {
    const model: GetPrintFromXmlDto = {
        asFile: asFile,
        xmlString: xml
    }

    if (asFile) {
        return await getObjectUrl(model, InvoicesEndpoints.HtmlFromXml(organizationId));
    }

    return await XfXApi.EditorApi.apiTenantIdOrganizationIdEditorHtmlFromXmlPost(XfXApi.GetTenantId(), organizationId, model);
}

const generatePdf = async (xml: string, organizationId: string): Promise<any> => {
    const model: GetPrintFromXmlDto = {
        xmlString: xml
    }

    return await axios
        .request({
            url: InvoicesEndpoints.PdfFromXml(organizationId),
            method: "POST",
            data: model,
            responseType: 'blob'
        });
}

export const saveXml = async (xml: string, download: boolean) => {
    //const xml = await generateXml(true, updatedValues2);

    if (typeof xml === 'string') {
        // if (ExtendedEditor) setWindowText(xml)

        if (download) {
            saveToFile(xml, "fv.xml");
        }
    }
}

export const getHtml = async (xmlString: string, download: boolean, organizationId: string, show: any) => {
    //const xmlString = await generateXml(false, updatedValues2);

    if (download) {
        const htmlResponse = await generateHtml(false, xmlString, organizationId);
        saveToFile(htmlResponse.data, "fv.html");
    }
    else {
        const htmlResponse = await generateHtml(true, xmlString, organizationId);

        show(htmlResponse)
    }
}

export const getPdf = async (xmlString: string, organizationId: string) => {
    //const xmlString = await generateXml(false, updatedValues2);
    const pdfResponse = await generatePdf(xmlString, organizationId);
    saveToFile(pdfResponse.data, "fv.pdf")
}