import notify from "devextreme/ui/notify"
import { XfXApi } from "../../../api/XfXApi"
import DataGrid, { Paging, Selection, Scrolling, FilterRow } from 'devextreme-react/data-grid';
import { gridColumns, userInit, editPopupTitle, addPopupTitle } from "./const";
import CustomStore from "devextreme/data/custom_store";
import DropDownBox from "devextreme-react/drop-down-box";
import { useTranslation } from 'react-i18next';

export const submit = async ({ vgFirstForm, vgSecondForm, userEdit, user, dataGridRef, setReloadKey, setVisible, t }) => {
  const validationFirst = vgFirstForm.current.instance.validate()
  const validationSecond = vgSecondForm.current.instance.validate()

  if (!validationSecond.isValid || !validationFirst.isValid)
    return
  else
    saveAction({ userEdit, user, setVisible, dataGridRef, setReloadKey, t })
}

export const saveReport = async () => {
  await XfXApi.User.apiTenantIdUserApiReportGet(XfXApi.GetTenantId(), {

      method: "GET",
      responseType: 'blob', 
  })
  .then((response) => {
    if (response.status !== 200) return
    const downloadUrl = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = downloadUrl;

    const date = new Date();
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');
    const seconds = date.getSeconds().toString().padStart(2, '0');
    const formattedDate = `${year}-${month}-${day}_${hours}${minutes}${seconds}`;

    const reportName = `users_API_${formattedDate}.csv`

    link.setAttribute('download', reportName);

    document.body.appendChild(link);
    link.click();
    link.remove();
  })
  .catch(x => {
    console.log(x)
  })

}

export const saveAction = async ({ userEdit, user, setVisible, dataGridRef, setReloadKey, t }) => {
  try {
    const response = userEdit ?
      await XfXApi.User.apiTenantIdUserPut(XfXApi.GetTenantId(), user) :
      await XfXApi.User.apiTenantIdUserPost(XfXApi.GetTenantId(), user)

    if (response.status === 200) {
      notify({
        message: `${userEdit ? t("#_users_53") : t("#_users_54")}`,
        position: {
          my: 'center bottom',
          at: 'center bottom',
        },
      }, 'success', 5000);
    }
  } catch (error) {
    if (error.response.status === 400) {
      notify({
        message: `${userEdit ? t("#_users_55") : t("#_users_56")} \r\n \r\n` + error.response.data.errors.join('\r\n'),
        position: {
          my: 'center bottom',
          at: 'center bottom',
        },
      }, 'error', 10000);
    }
    if (error.response.status === 401)//tu w teorii nie powinniśmy wejść bo już wcześniej powinniśmy sterować tym co widoczne
    {
      notify({
        message: `${userEdit ? t("#_users_57") : t("#_users_58")}`,
        position: {
          my: 'center bottom',
          at: 'center bottom',
        },
      }, 'error', 5000);
    }
    else
      console.error(error)
  }
  finally {
    setVisible(false)
    setReloadKey(oldVal => oldVal + 1)
    dataGridRef?.current?.instance?.clearSelection()
  }
}

export const fetchPriviliges = async ({ setId2Privilege }) => {
  XfXApi.Role.apiTenantIdRolePrivilegesGet(XfXApi.GetTenantId())
    .then(x => {
      let map = {}
      x.data.stateObject.administration.forEach(x => map[x.id] = x)
      x.data.stateObject.reception.forEach(x => map[x.id] = x)
      x.data.stateObject.dispatch.forEach(x => map[x.id] = x)
      setId2Privilege(map)
    })
}

export const fetchRoles = async ({ setRoles }) => {
  try {
    const { data: { stateObject } } = await XfXApi.Role.apiTenantIdRoleRolesGet(XfXApi.GetTenantId())
    setRoles(makeAsyncDataSource({ data: stateObject }))
  } catch (error) {
    console.error(error)
  }
}

export const copyToken = ({ dataGridRef, t }) => {
  const selected = dataGridRef?.current?.instance?.getSelectedRowsData()

  if (selected.length === 0) 
    return

  var token = selected[0].Token

  navigator.clipboard
    .writeText(token)
    .then(() => {
      notify({
        message: t("#_actions_28"),
        position: {
          my: 'center bottom',
          at: 'center bottom',
        },
      }, 'success', 500);
    })
    .catch(() => {
      console.log(t("#_actions_29"))
    });
}

export const formFieldDataChanged = ({ e, setUser, setIsAdmin }) => {
  setUser(e.component.option("formData"))
  switch (e.dataField) {
    case 'Admin':
      setIsAdmin(e.value)
      break;
    default:
      break;
  }
}

export const dataGridOnSelectionChanged = ({ e, setRolesValue, user }) => {
  setRolesValue((e.selectedRowKeys.length && e.selectedRowKeys) || [])
  user.RoleIds = (e.selectedRowKeys.length && e.selectedRowKeys) || []
}

export const makeAsyncDataSource = ({ data }) => {
  return new CustomStore({
    loadMode: 'raw',
    key: 'id',
    load() {
      return data
    },
  });
}

export const RolesDropDown = ({ roles, rolesValue, setRolesValue, user }) => {
  const { t } = useTranslation()
  const rolesRender = (rolesValue, setRolesValue) => {
    return (
      <DataGrid
        dataSource={roles}
        columns={gridColumns}
        hoverStateEnabled={true}
        selectedRowKeys={rolesValue}
        onSelectionChanged={e => {
          dataGridOnSelectionChanged({ e: e, setRolesValue, user: user })
        }}
        height="100%">
        <Selection
          allowSelectAll={false}
          deferred={false}
          mode='multiple'
          selectAllMode='allPages'
          showCheckBoxesMode='always'
        />
        <Scrolling mode="virtual" preloadEnabled={true} />
        <Paging enabled={true} pageSize={100} />
        <FilterRow visible={true} />
      </DataGrid>
    );
  }
  return <DropDownBox
    valueExpr="id"
    value={rolesValue}
    deferRendering={false}
    displayExpr="name"
    placeholder={t("#_actions_180")}
    showClearButton={false}
    dataSource={roles}
    contentRender={() => rolesRender(rolesValue, setRolesValue)}
    label={t("#_actions_190")}>
  </DropDownBox>
}

export const setUserInitDefaults = ({ setIsAdmin }) => {
  userInit.Id = null
  userInit.Login = null
  userInit.Name = null
  userInit.FirstName = null
  userInit.LastName = null
  userInit.Email = null
  userInit.Admin = false
  userInit.Type = 0
  userInit.UserRoles = []
  userInit.RoleIds = []
  userInit.Secret = null

  setIsAdmin(false)
}

export const editUser = ({ dataGridRef, setIsUserNatural, setIsAdmin, setUser, setVisible, setRolesValue, setPopupTitle, setUserEdit }) => {
  const selected = dataGridRef?.current?.instance?.getSelectedRowsData()
  
  if (selected.length === 0) 
    return

  const currentUser = {
    Id: selected[0].Id,
    Login: selected[0].Login,
    Name: selected[0].Name,
    FirstName: selected[0].FirstName,
    LastName: selected[0].LastName,
    Email: selected[0].Email,
    Type: selected[0].TypeValue,
    Admin: selected[0].Admin,
    UserRoles: [],
    RoleIds: selected[0].RoleIds,

    Disabled: selected[0].Disabled
  }

  if (currentUser.Type === 0)
    setIsUserNatural(true)
  else
    setIsUserNatural(false)

  setIsAdmin(selected[0].Admin)
  setUser(currentUser)
  setVisible(true)
  setRolesValue(selected[0].RoleIds)

  setPopupTitle(editPopupTitle)
  setUserEdit(true)
}

export const addUser = ({ dataGridRef, setIsUserNatural, setUser, setIsAdmin, setVisible, setRolesValue, setPopupTitle, setUserEdit }) => {
  dataGridRef?.current?.instance?.clearSelection()

  setIsUserNatural(true)
  setUserInitDefaults({ setIsAdmin: setIsAdmin })
  setUser(userInit)
  setVisible(true)
  setRolesValue(userInit.RoleIds)

  setPopupTitle(addPopupTitle)
  setUserEdit(false)
}

export const setUserDefaults = ({ e, user }) => {
  if (e === 1) {
    user.FirstName = null
    user.LastName = null
    user.Email = null
  }
  else
    user.Name = null
}

export const onUserTypeChanged = ({ e, setIsUserNatural, user }) => {
  if (e.value === 0) {
    setIsUserNatural(true)
  }
  else {
    setIsUserNatural(false)
  }

  setUserDefaults({ e: e.value, user: user })
}

export const completedValue = ({ rowData }) => {
  return rowData.Admin === true
}

export const onIsAdminChanged = ({ e, setIsAdmin, vgFirstForm }) => {
  setIsAdmin(e.value)
  if (!e.value)
    vgFirstForm?.current?.instance?.validate()
}