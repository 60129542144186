import {
 DateTimeFormat,
 utcToCetText
} from 'utils/cellRenderers'

export const columns = ({ t }) => 
[ 
    { dataField: "GrantedByNip", dataType: "string", caption: t("#_SelfInvoicingGranted_GrantedByNipCol"), width: "25%", visible: true },
    {
      dataField: "Active", dataType: "boolean", caption: t("#_SelfInvoicingGranted_ActiveCol"), 
      width: "25%",  visible: true, lookupValueExpr: "name", lookupDisplayExpr: "description", lookupDataSource: activeDataSource(t),
      editorOptions: {
        dropDownOptions: {
          minWidth: '190px'
        }
      }
    },
    { dataField: "LastCheckUpDateUtc", dataType: "datetime", caption: t("#_SelfInvoicingGranted_LastCheckUpDateUtcCol"), cellRender: renderLastCheckUpDateCell, format: DateTimeFormat, visible: true }, 
    { dataField: "CreationDateUtc", dataType: "datetime", visible: false },
    { dataField: "Id", visible: false },
]

const activeDataSource = (t) => {
  return {
    store: {
      type: 'array',
      data: [
        { name: true, description: t("#_SelfInvoicingGranted_Active") },
        { name: false, description: t("#_SelfInvoicingGranted_Inactive") },
      ],
      key: 'name'
    },
  }
}

export const renderLastCheckUpDateCell = x => {
  return <div>
    <span>{x.data?.LastCheckUpDateUtc !== null ? utcToCetText(x.data.LastCheckUpDateUtc) : ''}</span>
  </div>
}