import React, { useState, useRef } from 'react';
import { DataGrid, Column, Paging, HeaderFilter, FilterRow, Selection, Search } from 'devextreme-react/data-grid';
import ArrayStore from 'devextreme/data/array_store';
import DataSource from 'devextreme/data/data_source';
import notify from 'devextreme/ui/notify';
import { XfXApi } from 'api/XfXApi';
import GenericToolbar from '../Toolbars/GenericToolbar';
import FileUploader from 'devextreme-react/file-uploader';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import ContractorManagePopup from "./ContractorManagePopup";
import { renderDateCell } from 'utils/cellRenderers'
import DeletionPopup from 'components/popup/DeletionPopup';

const fileExtensions = ['.json', '.xlsx', '.csv'];
const fileTypes = ".json,.xlsx,.csv"

const Contractors = ({ selectedItem, contractorsData, fetchContractorsData, setContractorsData, setBlockAutoRefresh }) => {
  const { t } = useTranslation()
  const editPopupTitle = t("#_contractors_3")
  const addPopupTitle = t("#_contractors_4")
  const organizationData = selectedItem
  const [visible, setVisible] = useState(false)
  const [popupTitle, setPopupTitle] = useState(editPopupTitle)
  const formRef = useRef(null);

  const [contractorId, setContractorId] = useState(null)
  const dataGridRef = useRef(null);

  const dataSource = getContractors(selectedItem?.Id)
  const [deletionPopupVisible, setDeletionPopupVisible] = useState(false)
  const [deletionPopupContent, setDeletionPopupContent] = useState("")

  const deletionPopup = DeletionPopup({
    onConfirm: async () => {
      const selected = dataGridRef.current.instance.getSelectedRowsData()
      
      if (selected.length === 0) 
        return

      await XfXApi.Contractor.apiTenantIdContractorDelete(selected[0].id, XfXApi.GetTenantId())
      await fetchContractorsData({ setContractorsData })
    },

    content: deletionPopupContent,
    title: t("#_DeletionPopup_delete"),
    isVisible: deletionPopupVisible,
    setIsVisible: setDeletionPopupVisible,
    t: t,
    setBlockAutoRefresh: setBlockAutoRefresh
  })

  const deleteContractor = () => {
    setDeletionPopupContent(t("#_DeletionPopup"));
    deletionPopup.show();
  }

  function getContractors(key) {
    return new DataSource({
      store: new ArrayStore({
        data: contractorsData,
        key: 'id',
      }),
      filter: ['organizationId', '=', key],
    });
  }

  function addContractor(e) {
    setContractorId(null)
    setVisible(true)

    setPopupTitle(addPopupTitle)
  }

  function editContractor(e) {
    const selected = dataGridRef.current.instance.getSelectedRowsData()
    if (selected.length === 0) return

    setContractorId(selected[0].id)
    setVisible(true)

    setPopupTitle(editPopupTitle)
  }

  const toolbarButtons = [
    { icon: 'plus', text: t("#_contractors_10"), onClick: addContractor },
    { icon: 'edit', text: t("#_contractors_120"), onClick: editContractor, disabled: contractorId === null },
    { icon: 'trash', text: t("#_contractors_140"), onClick: deleteContractor, disabled: contractorId === null }
  ]

  const uploadFile = async (file) => {
    //To podejscie niestety nie przekazuje prawidlowo pliku (przerzuca bloba i ma pustą zawartość {})
    //await XfXApi.Contractor.apiContractorContractorsPost(organizationData.id, file)

    try {
      var fileData = new FormData();
      fileData.append("file", file, file.name);
      const response = await axios.post((window.XFX_WEBAPI ?? "") + `/api/${XfXApi.GetTenantId()}/Contractor/Contractors?organizationId=${organizationData.Id}`, fileData, {
        headers: {
          'Content-Type': `multipart/form-data`
        }
      })

      if (response.status === 200) {
        notify({
          message: t("#_contractors_160"),
          position: {
            my: 'center bottom',
            at: 'center bottom',
          },
        }, 'success', 5000);
      }
    } catch (error) {
      if (error.response.status === 400) {
        notify({
          message: `${t("#_contractors_8")} \r\n \r\n` + error.response.data.join('\r\n'),
          position: {
            my: 'center bottom',
            at: 'center bottom',
          },
        }, 'error', 10000);
      }
    } finally {
      await fetchContractorsData({ setContractorsData })
    }
  };

  return (
    <React.Fragment>
      {visible && 
        <ContractorManagePopup
          setVisible={setVisible}
          popupTitle={popupTitle}
          formRef={formRef}
          organizationId={organizationData?.Id}
          fetchcontractors={fetchContractorsData}
          editMode={contractorId ? true : false}
          contractorId={contractorId}
          setContractorsData={setContractorsData}
          dataGridContractors={dataGridRef}
          visible={visible}
          setBlockAutoRefresh={setBlockAutoRefresh}>
        </ContractorManagePopup>
      }
      {deletionPopup.popup}

      <GenericToolbar className="xfx-toolbar-bigbuttons" header={`Kontrahenci ${organizationData?.FullName ?? ''}`} buttons={toolbarButtons} showIcon={false} showSimpleHeader={true} />
      <div className="dataGrid">
        <DataGrid
          ref={dataGridRef}
          dataSource={dataSource}
          columnAutoWidth={true}
          allowColumnReordering={true}
          showColumnLines={true}
          showBorders={true}
          alignItems={"center"}
          rowAlternationEnabled={true}
          onSelectionChanged={s => {
            setContractorId(s.selectedRowsData.length > 0 ? s.selectedRowsData[0].id : null)
          }}
        >
          <HeaderFilter>
            <Search
              enabled={true}
            >
            </Search>
          </HeaderFilter>
          <FilterRow visible={true} applyFilter={true} />

          <Paging defaultPageSize={5} />
          <Selection mode="single" />

          <Column dataField="countryPrefix" caption={t("#_contractors_200")} width={"5%"} />
          <Column dataField="nip" width={"10%"} />
          <Column dataField="name" caption={t("#_contractors_201")} width={"35%"} />
          <Column dataField="creationDateUtc" dataType="datetime" format="dd.MM.yyyy HH:mm:ss" caption={t("#_contractors_202")} width={"10%"} cellRender={x => renderDateCell(x.data?.creationDateUtc)} />
          <Column dataField="modificationDateUtc" dataType="datetime" format="dd.MM.yyyy HH:mm:ss" caption={t("#_contractors_203")} width={"10%"} cellRender={x => renderDateCell(x.data?.modificationDateUtc)} />
          <Column dataField="role" dataType="string" caption={t("#_contractors_207")} width={"10%"} />
          <Column
            width={"8%"}
            caption={t("#_contractors_205")}
            dataType="boolean"
            dataField="selfInvoicingContractSigned"
          />
        </DataGrid>
      </div>
      <div className="file-uploader-block">
        <FileUploader
          accept={fileTypes}
          selectButtonText={t("#_contractors_29")}
          labelText={t("#_contractors_30")}
          multiple={false}
          uploadMode="instantly"
          uploadFile={uploadFile}
          allowedFileExtensions={fileExtensions}
          invalidFileExtensionMessage={t("#_contractors_32")}
          uploadedMessage={t("#_contractors_33")}
          readyToUploadMessage={t("#_contractors_34")}
          uploadFailedMessage={t("#_contractors_35")}
          name={'file'}
        />
      </div>
    </React.Fragment>
  );
}

export default Contractors;
