import { useState } from "react"
import { useSearchParams } from "react-router-dom";

const querySegmentChanged = (array, key, newValue) => {
    if (array === null) return true
    const filtered = array.filter(x => x[0] === key)
    const seg = filtered.length > 0 ? filtered[0] : null
    const oldValue = seg === null ? null : seg[2]
    return newValue !== oldValue
}

const useDataGridFilter = ({ initialFilter, queryArguments }) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const [dataGridFilter, setDataGridFilter] = useState(null)
    const [activeQueryArguments, setActiveQueryArguments] = useState([])

    let newFilterState = [...(dataGridFilter ?? initialFilter)]

    if (queryArguments !== undefined) {
        let requiresUpdate = false
        let currenQueryArguments = []
        queryArguments.forEach(argumentName => {
            const value = searchParams.get(argumentName)

            if (querySegmentChanged(dataGridFilter, argumentName, value)) {
                requiresUpdate = true
            }
            newFilterState = newFilterState.filter(x => x[0] !== argumentName)
            if (value !== null) {
                newFilterState.push([argumentName, '=', value])
                currenQueryArguments.push(argumentName)
            }
        })
        if (requiresUpdate) {
            setDataGridFilter(newFilterState)
            setActiveQueryArguments(currenQueryArguments)
        }
    }


    if (dataGridFilter === null) {
        setDataGridFilter(newFilterState)
    }

    const updateDataGridFilter = (fieldName, value) => {
        const update = [fieldName, '=', value]
        const newFilterState = [...dataGridFilter.filter(x => x[0] !== fieldName), update]
        setDataGridFilter(
            newFilterState
        )
    }

    const deleteActiveQueryArgument = (argumentName) => {
        const param = searchParams.get(argumentName)
        if (param) {
            searchParams.delete(argumentName)
            setSearchParams(searchParams);
        }
    }

    return { dataGridFilter, updateDataGridFilter, activeQueryArguments, deleteActiveQueryArgument }
}

export default useDataGridFilter