import { useEffect, useState, useRef, useCallback } from 'react';
import DataGrid, {
  Column, Scrolling, Paging, HeaderFilter, FilterRow, Selection, ColumnFixing, ColumnChooser, Lookup,
  FilterPanel,
  FilterBuilderPopup,
  ColumnChooserSearch,
  Search,
  MasterDetail
} from 'devextreme-react/data-grid';
import "./grid.css"
import useDataSourceAutoReload from '../../utils/useDataSourceReload'
import { useRefreshButton } from 'utils/refresh';
import { Button } from 'devextreme-react/button';
import { useOrganizationDropDownHandler } from 'utils/organizationDropDownHandler';
import { useTranslation } from 'react-i18next';
import { useUserSettingsContext } from '../../contexts/UserSettingsProvider';
import { useAuth } from '../../contexts/auth';
import { FilterUsageTypes } from './GenericOdataGrid';

export const actionColumnWidth = 50
const reloadIntervalMs = 30000

const headerPixels = 40
const breadCrumbsPixels = 40
const toolbarButtonsPixels = 100
const restOfInnerHeight = headerPixels + breadCrumbsPixels + toolbarButtonsPixels

export const convertToColumns = (data) => data.map(x => <Column
  key={x.key ?? x.dataField}
  dataField={x.dataField}
  caption={x.caption}
  width={x.width}
  format={x.format}
  calculateCellValue={x.calculateCellValue}
  calculateDisplayValue={x.calculateDisplayValue}
  minWidth={x.minWidth}
  visible={x.visible}
  dataType={x.dataType}
  cellRender={x.cellRender}
  alignment={x.alignment}
  sortIndex={x.sortIndex}
  sortOrder={x.sortOrder}
  allowSorting={x.allowSorting ?? true}
  showInColumnChooser={x.showInColumnChooser ?? true}
  allowFiltering={x.allowFiltering ?? true}
  headerId={x.dataField}
  fixed={x.fixed}
  visibleIndex={x.visibleIndex}
  fixedPosition={x.fixedPosition}
  editorOptions={x.editorOptions}>
  {x.lookupDataSource && <Lookup
    dataSource={x.lookupDataSource}
    valueExpr={x.lookupValueExpr}
    displayExpr={x.lookupDisplayExpr} />
  }
</Column>)

const getGridSize = () => {
  return window.innerHeight >= restOfInnerHeight ? window.innerHeight - restOfInnerHeight : window.innerHeight
}

export const GenericGrid = ({ id, ref, columns, dataSource,
  setSelectedItem,
  setSelectedItems,
  defaultFilter, filterButtons,
  updateDataGridFilter,
  multiSelectionMode, gridKey, setDefaultSort,
  visible,
  useFilterBuilder,
  filterUsageType = FilterUsageTypes.ODataSourceOptions,
  masterDetail,
  blockAutoRefresh,
  setBlockAutoRefresh }) => {
  const { t } = useTranslation()
  const deferredSelectionMode = false
  const [selection, setSelection] = useState()
  const [dataGridId, setDataGridId] = useState(id)
  const columnChooserRef = useRef(null)
  const { updateUserSettingsInDb, postUserSettingsIntoDb } = useUserSettingsContext()
  const { userSettings, userId } = useAuth()
  const [texts, setTexts] = useState({
    createFilter: "",
    clearFilter: "",
    filterEnabledHint: ""
  })

  useEffect(() => {
    let s;
    if (multiSelectionMode === true) {
      s = <Selection mode="multiple"
        //  deferred={true}
        showCheckBoxesMode={false}
      />
    } else {
      s = <Selection mode="single" />
    }
    setSelection(s)
  }, [multiSelectionMode])

  const onDataReload = (items) => {
    const selectedItems = ref?.current?.instance.getSelectedRowsData();
    if (items && selectedItems && setSelectedItem) {
      const selectedIds = selectedItems.map(x => x?.Id?._value);
      const itemsToSet = items.filter(x => selectedIds.includes(x?.Id?._value));
      setSelectedItem(itemsToSet.length === 1 ? itemsToSet[0] : null);
    }
  };

  useRefreshButton(async () => {
    if (dataSource?.reload)
      await dataSource?.reload()
    if (dataSource?.items)
      onDataReload(dataSource?.items())
  })

  useOrganizationDropDownHandler((e) => {
    dataSource?.reload()
  })

  const [gridHeight, setGridHeight] = useState(getGridSize())

  const onWindowResized = () => {
    try {
      setGridHeight(window.innerHeight >= restOfInnerHeight ? window.innerHeight - restOfInnerHeight : window.innerHeight)
    } catch (error) {

    }
  }

  const onOptionChanged = (e) => {
    if (e?.fullName?.endsWith('visibleIndex'))
      handleIndexChangedColumn(e)

    if (e?.fullName?.endsWith('fixedPosition') || (e?.fullName?.endsWith('fixed') && !e.value))
      handleFixUnfixColumn(e)

    if (e?.fullName?.endsWith('sortOrder'))
      handleSortUnsortColumn(e)
  }

  const handleIndexChangedColumn = async (e) => {
    const visibleColumns = ref?.current?.instance?.getVisibleColumns()?.filter(x => x.visible)
    let cvi = { ...userSettings.settings.VisibleIndex }

    cvi[dataGridId] = Object.fromEntries(new Map(visibleColumns.map(x => [x.headerId, x.visibleIndex])))

    if (userSettings !== null) {
      const settings = { ...userSettings.settings, VisibleIndex: cvi }
      await updateUserSettingsInDb(userSettings?.id, { userId: userId, settings: settings })
    }
    else {
      const settings = { VisibleIndex: cvi }
      await postUserSettingsIntoDb({ userId: userId, settings: settings })
    }
  }

  const saveColumnsChose = useCallback(async (e) => {
    const allColumns = ref?.current?.instance?._controllers?.columns?._columns;
    const cv = { ...userSettings.settings.ColumnsVisible };
    cv[dataGridId] = Object.values(Object.fromEntries(new Map([[dataGridId, allColumns.filter(x => x.visible).map(x => x.headerId)]])))[0];

    const caic = { ...userSettings.settings.ColumnsAvailableInChooser };
    caic[dataGridId] = Object.values(Object.fromEntries(new Map([[dataGridId, allColumns.filter(x => x.showInColumnChooser).map(x => x.headerId)]])))[0];

    if (userSettings !== null) {
      const settings = { ...userSettings.settings, ColumnsVisible: cv, ColumnsAvailableInChooser: caic };
      await updateUserSettingsInDb(userSettings?.id, { userId: userId, settings: settings });
    }
    else {
      const settings = { ColumnsVisible: cv, ColumnsAvailableInChooser: caic };
      await postUserSettingsIntoDb({ userId: userId, settings: settings });
    }
  }, [dataGridId, postUserSettingsIntoDb, ref, updateUserSettingsInDb, userId, userSettings])

  const [columnChooserPopup, setColumnChooserPopup] = useState(null)

  const dataGridOnContentReady = useCallback((e) => {
    const columnChooserView = e.component.getView("columnChooserView")
    if (!columnChooserView._popupContainer) {
      columnChooserView._initializePopupContainer()
      columnChooserView.render()
      setColumnChooserPopup(columnChooserView._popupContainer)
    }
  }, [])

  useEffect(() => {
    if (columnChooserPopup !== null) columnChooserPopup.on("hiding", () => saveColumnsChose())
    return () => {
      if (columnChooserPopup !== null) columnChooserPopup.off("hiding")
    }
  }, [saveColumnsChose, columnChooserPopup])

  const handleFixUnfixColumn = async (e) => {
    const columnsFixed = ref?.current?.instance?.getVisibleColumns()?.filter(x => x.fixed)
    let cf = { ...userSettings.settings.ColumnsFixed }

    if (columnsFixed.length === 0) {
      if (Object.keys(cf).length === 1)
        cf = null
      else
        cf = Object.fromEntries(Object.entries(cf).filter(([key]) => !key.includes(dataGridId)))
    }
    else
      cf[dataGridId] = Object.fromEntries(new Map(columnsFixed.map(x => [x.headerId, x.fixedPosition])))

    if (userSettings !== null) {
      const settings = { ...userSettings.settings, ColumnsFixed: cf }
      await updateUserSettingsInDb(userSettings?.id, { userId: userId, settings: settings })
    }
    else {
      const settings = { ColumnsFixed: cf }
      await postUserSettingsIntoDb({ userId: userId, settings: settings })
    }
  }

  const handleSortUnsortColumn = async (e) => {
    const columnsToSort = ref?.current?.instance?.getVisibleColumns()?.filter(x => x.sortOrder !== undefined)
    let cts = { ...userSettings.settings.ColumnsToSort }
    let es = { ...userSettings.settings.EmptySorting }

    if (columnsToSort.length === 0) {
      es[dataGridId] = true
      setDefaultSort(null)

      if (Object.keys(cts).length === 1)
        cts = null
      else
        cts = Object.fromEntries(Object.entries(cts).filter(([key]) => !key.includes(dataGridId)))
    }
    else {
      es[dataGridId] = false
      cts[dataGridId] = Object.fromEntries(new Map(columnsToSort.map(x => [x.headerId, x.sortOrder])))
    }

    if (userSettings !== null) {
      const settings = { ...userSettings.settings, ColumnsToSort: cts, EmptySorting: es }
      await updateUserSettingsInDb(userSettings?.id, { userId: userId, settings: settings })
    }
    else {
      const settings = { ColumnsToSort: cts, EmptySorting: es }
      await postUserSettingsIntoDb({ userId: userId, settings: settings })
    }
  }

  useEffect(() => {
    window.addEventListener('resize', onWindowResized)

    setTexts({
      createFilter: t("data-grid-filter-builder-create"),
      clearFilter: t("data-grid-filter-builder-clear"),
      filterEnabledHint: t("data-grid-filter-builder-enable")
    })

    return () => {
      window.removeEventListener('resize', onWindowResized)
    }
  }, [])

  useEffect(() => {
    dataSource?.reload().then(x => {
      if (dataSource?.items)
        onDataReload(dataSource?.items())
    })
  }, [gridKey])

  useDataSourceAutoReload({ dataSource: dataSource, onDataReload: onDataReload, blockAutoRefresh: blockAutoRefresh !== undefined ? blockAutoRefresh : false })

  let key = 1
  const button = filterButtons !== undefined ? filterButtons.map(x => <Button key={"filter-key-" + key++} width={120}
    text={x.text}
    type="danger"
    onClick={x.onClick}
  ></Button>

  ) : <></>

  return <>
    {button}
    <DataGrid
      ref={ref}
      visible={visible ?? false}
      columnResizingMode="widget"
      allowColumnReordering={true}
      dataSource={dataSource}
      showBorders={true}
      height={gridHeight}
      rowAlternationEnabled={true}
      showColumnLines={true}
      syncLookupFilterValues={false}
      defaultFilterValue={filterUsageType === FilterUsageTypes.DataGridDefaultFilterValueProp && defaultFilter ? defaultFilter : []}
      onContentReady={dataGridOnContentReady}
      onSelectionChanged={s => {
        if (deferredSelectionMode) {
          if (setSelectedItems) {
            s.component.getSelectedRowsData()
              .then(x => {
                setSelectedItems(x)
                if (setSelectedItem) setSelectedItem(x.length === 1 ? x[0] : null)
              })
          }
        } else {
          if (setSelectedItem) setSelectedItem(s.selectedRowsData.length === 1 ? s.selectedRowsData[0] : null)
          if (setSelectedItems) setSelectedItems(s.selectedRowsData)
        }
      }}
      onOptionChanged={async (e) => {
        await onOptionChanged(e)
      }}
      onRowCollapsing={s => {
        ref.current.instance.deselectRows([s.key])
        setSelectedItem(null)
      }}
      onRowExpanding={s => {
        ref.current.instance.collapseAll(-1)
        ref.current.instance.selectRows([s.key], false)
        setSelectedItem(ref.current.instance.getSelectedRowsData().length === 1 ? ref.current.instance.getSelectedRowsData()[0] : null)
      }}
    >
      <Scrolling mode="virtual" preloadEnabled={true} />
      <Paging defaultPageSize={100} />
      <FilterPanel visible={useFilterBuilder} texts={texts} />
      {useFilterBuilder &&
        <FilterBuilderPopup
          title={t("data-grid-filter-builder-title")}
          onHiding={() => {
            if (setBlockAutoRefresh)
              setBlockAutoRefresh(false)
          }}
          onShowing={() => {
            if (setBlockAutoRefresh)
              setBlockAutoRefresh(true)
          }}
          position={{ of: window, at: 'top', my: 'top', offset: { y: 10 } }}
        />
      }
      <HeaderFilter>
        <Search
          enabled={true}
        >
        </Search>
      </HeaderFilter>
      <FilterRow visible={true} applyFilter={true} />
      <ColumnFixing enabled={true} />
      <ColumnChooser
        ref={columnChooserRef}
        enabled={true}
        mode="select"
        title={t("#_genericgrid_1")}
        visible={false}
        height={600}
        width={400}
      >
        <ColumnChooserSearch
          enabled={true}
        />
      </ColumnChooser>
      {selection}
      {columns}
      {masterDetail && masterDetail()}
    </DataGrid>
  </>
}

export default GenericGrid
