import { Form, SimpleItem, Label, GroupItem } from 'devextreme-react/form';
import { saveAction } from "./actions";
import { useState } from "react";
import { Tooltip } from 'devextreme-react/tooltip';
import { Template } from 'devextreme-react/core/template';
import { useTranslation } from 'react-i18next';
import GenericPopup from "components/popup/GenericPopup";
import { commonPopupToolbarItems } from 'components/popup/PopupTools';
import useFormErrors from 'utils/useFormErrors';
import OrganizationsDropDown from  'components/dropdown/OrganizationsDropDown.tsx'

const labelMode = 'static'
const labelLocation = 'left'
const showColonAfterLabel = false
const minColWidth = 300
const colCount = 3
const colSpan = 3

const validationBasicOrganizationSyncSettingFormGroup = "validationBasicOrganizationSyncSettingFormGroup";

const AddEditPopup = ({ 
    visible, setVisible, popupTitle, vgBasicOrganizationSyncSettingForm,
    organizationSyncSettingEdit, organizationSyncSetting, setOrganizationSyncSetting, setOrganizationSyncSettings,
    dataGridOrganizationSyncSettings, organizations, setBlockAutoRefresh }) => {

    const { t } = useTranslation()
    const { getFirstError, noErrors, setErrors, clearError } = useFormErrors()

    const [minDateValue, setMinDateValue] = useState(new Date(Date.UTC(2022, 0, 1)))
    const [maxDateValue, setMaxDateValue] = useState(new Date(Date.UTC(new Date().getUTCFullYear(), new Date().getUTCMonth(), new Date().getUTCDate())))

    const saveButtonOptions = {
        text: t("#_organizationsyncsettings_addeditpopup_1"),
        onClick: async() => await submit({ organizationSyncSettingEdit: organizationSyncSettingEdit, organizationSyncSetting: organizationSyncSetting, popup: popup, 
            setOrganizationSyncSettings, dataGridOrganizationSyncSettings: dataGridOrganizationSyncSettings, t })
    }
  
    const cancelButtonOptions = {
        text: t("#_organizationsyncsettings_addeditpopup_2"),
        onClick: () => { 
          popup.close()
        }
    }

    const submit = async({ organizationSyncSettingEdit, organizationSyncSetting, popup, setOrganizationSyncSettings, dataGridOrganizationSyncSettings, t }) => {
        const validationResult = vgBasicOrganizationSyncSettingForm?.current?.instance?.validate()
        if (!validationResult?.isValid)
          return
        else
          saveAction({ organizationSyncSettingEdit: organizationSyncSettingEdit, organizationSyncSetting: organizationSyncSetting, 
            popup, setOrganizationSyncSettings, dataGridOrganizationSyncSettings: dataGridOrganizationSyncSettings, t, setErrors })
    }

    const formFieldDataChanged = (e) => {
        setOrganizationSyncSetting(e.component.option("formData"))
        if (e.dataField) {
          clearError(e.dataField)
        }
    }

    const customTooltipEncryption = () => {
      return (
        <>
         <div id="template-content">
            <i id="helpedInfoEncryption" className="dx-icon dx-icon-info"></i>
          </div>
          <Tooltip
            target="#helpedInfoEncryption"
            showEvent="mouseenter"
            hideEvent="mouseleave"
          >
            <div id="tooltip-content">
              {t("#_organizationsyncsettings_addeditpopup_3")} <br />
              {t("#_organizationsyncsettings_addeditpopup_4")} <br />
              {t("#_organizationsyncsettings_addeditpopup_5")}
            </div>
          </Tooltip> 
        </>
      )
    }

    const customTooltipLargeWalkingWindowEnabled = () => {
      return (
        <>
         <div id="template-content">
            <i id="helpedInfoLargeWalkingWindowEnabled" className="dx-icon dx-icon-info"></i>
          </div>
          <Tooltip
            target="#helpedInfoLargeWalkingWindowEnabled"
            showEvent="mouseenter"
            hideEvent="mouseleave"
          >
            <div id="tooltip-content">
            {t("#_organizationsyncsettings_addeditpopup_6")}
            </div>
          </Tooltip> 
        </>
      )
    }

    const dateBoxEditorOptions = { 
      type: 'date', pickerType: 'calendar', 
      readOnly: false, min: minDateValue, max: maxDateValue,
      showDropDownButton: true, dropDownButtonTemplate: 'renderEventIcon',
      invalidDateMessage: t("#_organizationsyncsettings_addeditpopup_7"),
      dateOutOfRangeMessage: t("#_organizationsyncsettings_addeditpopup_8"),
      validationErrors: getFirstError("initialReceptionCheckupDate"),
      isValid: noErrors("initialReceptionCheckupDate"),
      acceptCustomValue: false
    }

    const renderEventIcon = () => {
      return (
        <>
          <div className='dx-icon dx-icon-event'></div>
        </>
      );
    }

    const popupToolbarItems = commonPopupToolbarItems({ 
      t: t, 
      saveButtonOptions: saveButtonOptions,
      cancelButtonOptions: cancelButtonOptions
    })

    const popup = GenericPopup({ 
      onHiding:() => popup.close(),
      content:
      <div>
        <Form
          id="form"
          ref={vgBasicOrganizationSyncSettingForm}
          validationGroup={validationBasicOrganizationSyncSettingFormGroup}
          labelMode={labelMode}
          showColonAfterLabel={showColonAfterLabel}
          labelLocation={labelLocation}
          minColWidth={minColWidth}
          colCount={colCount}
          onFieldDataChanged={formFieldDataChanged}
          formData={organizationSyncSetting}>
          <GroupItem colCount={colCount} colSpan={colSpan}>
            <SimpleItem 
              cssClass="xfx-required">
              <OrganizationsDropDown
                value={organizationSyncSetting.organizationId}
                setValue={v => setOrganizationSyncSetting(oldVal => ({...oldVal, organizationId: v }))}
                allOrganizations={organizations}
                t={t}
                useFluentValidation={true}
                validationErrors={getFirstError("organizationId")}
                isValid={noErrors("organizationId")}
                placeholder={t("#_organizationsyncsettings_addeditpopup_19")}
                label={t("#_organizationsyncsettings_addeditpopup_9")}
                mode={'single'}
                clearError={clearError}
                clearErrorField={'organizationId'}
              />
            </SimpleItem>
            <SimpleItem 
              cssClass="xfx-required" 
              dataField="initialReceptionCheckupDate" 
              editorType="dxDateBox" 
              value={organizationSyncSetting.initialReceptionCheckupDate}
              editorOptions={dateBoxEditorOptions}>
              <Label text={t("#_organizationsyncsettings_addeditpopup_11")} />
              <Template name='renderEventIcon' render={renderEventIcon} />
            </SimpleItem>
          </GroupItem>
          <GroupItem colCount={colCount} colSpan={colSpan}>
            <SimpleItem dataField="receptionSyncOnAsSubject1" editorType="dxSwitch" value={organizationSyncSetting.receptionSyncOnAsSubject1}>
                  <Label text={t("#_organizationsyncsettings_addeditpopup_12")}  />
            </SimpleItem>
            <SimpleItem dataField="receptionSyncOnAsSubject2" editorType="dxSwitch" value={organizationSyncSetting.receptionSyncOnAsSubject2}>
                  <Label text={t("#_organizationsyncsettings_addeditpopup_13")}  />
            </SimpleItem>
            <SimpleItem dataField="receptionSyncOnAsSubject3" editorType="dxSwitch" value={organizationSyncSetting.receptionSyncOnAsSubject3}>
                  <Label text={t("#_organizationsyncsettings_addeditpopup_14")}  />
            </SimpleItem>
          </GroupItem>
          <GroupItem colCount={colCount} colSpan={colSpan}>
              <SimpleItem 
                dataField="asyncModeEnabled" 
                editorType="dxSwitch" 
                value={organizationSyncSetting.asyncModeEnabled}
                editorOptions={{
                  value: organizationSyncSetting.asyncModeEnabled
                }}>
                <Label text="Tryb asynchroniczny" />
              </SimpleItem>
              <SimpleItem 
                dataField="shippingSynchronizationEnabled" 
                editorType="dxSwitch" 
                value={organizationSyncSetting.shippingSynchronizationEnabled}
                editorOptions={{
                  value: organizationSyncSetting.shippingSynchronizationEnabled
                }}>
                <Label text={t("#_organizationsyncsettings_addeditpopup_18")} />
              </SimpleItem>
              <SimpleItem 
                  dataField="useWriteSession" 
                  editorType="dxSwitch" 
                  value={organizationSyncSetting.useWriteSession}>
                  <Label text={t("#_organizationsyncsettings_addeditpopup_17")} />
              </SimpleItem>
            </GroupItem>
            <GroupItem colCount={colCount} colSpan={colSpan}>
            <SimpleItem 
                dataField="useAdditionalEncryption" 
                editorType="dxSwitch" 
                value={organizationSyncSetting.encryptConnectionToKsef}>
                <Label text={t("#_organizationsyncsettings_addeditpopup_15")} />
              </SimpleItem>
              <SimpleItem 
                editorType="dxLabel" 
                render={customTooltipEncryption}>
              </SimpleItem>
            </GroupItem>
            <GroupItem colCount={colCount} colSpan={colSpan}>
              <SimpleItem 
                dataField="largeWalkingWindowEnabled" 
                editorType="dxSwitch" 
                value={organizationSyncSetting.largeWalkingWindowEnabled}>
                <Label text={t("#_organizationsyncsettings_addeditpopup_16")} />
              </SimpleItem>
              <SimpleItem 
                editorType="dxLabel" 
                render={customTooltipLargeWalkingWindowEnabled}>
              </SimpleItem>
            </GroupItem>
        </Form>
      </div>,
      toolbarItems: popupToolbarItems,
      title: popupTitle,
      width: "1250px",
      height: "520px",
      isVisible: visible,
      setIsVisible: setVisible,
      setBlockAutoRefresh: setBlockAutoRefresh
    })

    return (
        <>
          {popup.popup}
        </>
    )
}

export default AddEditPopup