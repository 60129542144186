import BreadCrumbs from 'components/breadCrumbs/BreadCrumbs';
import { getNode } from 'components/breadCrumbs/getNode';
import { useTranslation } from 'react-i18next';
import { OdataEndpoints } from 'api/XfXApi';
import notify from 'devextreme/ui/notify';
import GenericPopup from "components/popup/GenericPopup"
import { useState, useRef, useEffect } from 'react';
import { commonPopupToolbarItems } from 'components/popup/PopupTools';
import GenericOdataGrid from 'pages/Invoices/GenericOdataGrid';
import useDataGridFilter from 'utils/useDataGridFilter'
import useFormErrors from 'utils/useFormErrors';
import { columns } from './const';
import GenericToolbar from 'pages/settings/Toolbars/GenericToolbar';
import FailureAddEdit from './FailureAddEdit';
import { XfXApi } from 'api/XfXApi';
import CustomStore from 'devextreme/data/custom_store';
import { utcToLocalDate } from 'utils/utcToLocal';
import { useAuth } from 'contexts/auth'
import { Features } from "features.ts";
import { EventLogsPopup, EventLogsTypes } from 'pages/Invoices/EventLogsPopup';

const emptyFailure = {
    id: null,
    mode: 0,
    kind: 0,
    type: null,
    dateFromUtc: null,
    dateToUtc: null,
    annotation: null
}

const FailureRegistry = () => {
    const initialFilter = []
    const [failure, setFailure] = useState(emptyFailure)
    const [blockAutoRefresh, setBlockAutoRefresh] = useState(false)

    const [isVisible, setIsVisible] = useState(false)
    const [data, setData] = useState([])
    const formRef = useRef(null);
    const { dataGridFilter, updateDataGridFilter } = useDataGridFilter({ initialFilter, queryArguments: undefined })
    const [selectedItem, setSelectedItem] = useState(null)
    const [reloadKey, setReloadKey] = useState(0)
    const [dataSourceOptions, setDataSourceOptions] = useState()
    const dataGridRef = useRef(null);

    const { hasFeature } = useAuth()

    const { setErrors, getFirstError, noErrors, clearError, clearAllErrors } = useFormErrors();
    const { t } = useTranslation()
    const defaultSort = { selector: "DateFromUtc", desc: true };

    const [failureKindsDataSource, setFailureKindsDataSource] = useState([])
    const [failureModesDataSource, setFailureModesDataSource] = useState([])
    const [failureTypesDataSource, setFailureTypesDataSource] = useState([])
    const [availableFailureTypesDataSource, setAvailableFailureTypesDataSource] = useState([])
    const [eventLogsVisible, setEventLogsVisible] = useState(false)

    function makeAsyncDataSource(data) {
        return new CustomStore({
          loadMode: 'raw',
          key: 'key',
          load() {
            return data
          },
        })
    }

    useEffect(() => {
        const loadData = async () => {
            const failureKindsResponse = await XfXApi.Dictionary.apiTenantIdDictionaryFailureKindsGet(XfXApi.GetTenantId())
            const failureModesResponse = await XfXApi.Dictionary.apiTenantIdDictionaryFailureModesGet(XfXApi.GetTenantId())
            const failureTypesResponse = await XfXApi.Dictionary.apiTenantIdDictionaryFailureTypesGet(XfXApi.GetTenantId())

            const failureTypesDS = makeAsyncDataSource(failureTypesResponse.data.map(x => { return {...x, description: t(`#_FailureType_${x.value}`)}}))

            setFailureKindsDataSource(makeAsyncDataSource(failureKindsResponse.data.map(x => { return {...x, description: t(`#_FailureKind_${x.value}`)}})))
            setFailureModesDataSource(makeAsyncDataSource(failureModesResponse.data.map(x => { return {...x, description: t(`#_FailureMode_${x.value}`)}})))
            setFailureTypesDataSource(failureTypesDS)
            setAvailableFailureTypesDataSource(failureTypesDS)

            setData(columns({ t }))
        }

        loadData()
    }, [t, setData])

    const addEditModal = FailureAddEdit({
        failure: failure,
        formRef: formRef,
        t: t,
        getFirstError: getFirstError,
        noErrors: noErrors,
        clearError: clearError,
        failureKindsDataSource: failureKindsDataSource,
        failureModesDataSource: failureModesDataSource,
        failureTypesDataSource: failureTypesDataSource,
        edit: failure?.id ? true : false,
        setFailure: setFailure,
        makeAsyncDataSource: makeAsyncDataSource,
        availableFailureTypesDataSource: availableFailureTypesDataSource,
        setAvailableFailureTypesDataSource: setAvailableFailureTypesDataSource
    })

    const AddAction = async () => {
        try {
            var response = await XfXApi.FailureApi.apiTenantIdFailurePost(XfXApi.GetTenantId(), failure)

            if (response.status === 200) {
                notify({
                    message: t("#_FailureAdd_SuccessMessage"),
                    position: {
                        my: 'center bottom',
                        at: 'center bottom',
                    },
                }, 'success', 5000);
                return true;
            }
        } catch (error) {
            if (error.response.status === 400) {
                notify({
                    message: t("#_FailureAdd_ErrorMessage"),
                    position: {
                        my: 'center bottom',
                        at: 'center bottom',
                    },
                }, 'error', 10000);
            }
            else if (error.response.status === 422) {
                setErrors(error.response.data)
            }
        }
    }

    const EditAction = async () => {
        try {
            var response = await XfXApi.FailureApi.apiTenantIdFailurePut(XfXApi.GetTenantId(), 
                { 
                    Id: failure.id,
                    DateToUtc: failure.dateToUtc,
                    Annotation: failure.annotation
                })

            if (response.status === 200) {
                notify({
                    message: t("#_FailureEdit_SuccessMessage"),
                    position: {
                        my: 'center bottom',
                        at: 'center bottom',
                    },
                }, 'success', 5000);
                return true;
            }
        } catch (error) {
            if (error.response.status === 400) {
                notify({
                    message: t("#_FailureEdit_ErrorMessage"),
                    position: {
                        my: 'center bottom',
                        at: 'center bottom',
                    },
                }, 'error', 10000);
            }
            else if (error.response.status === 422) {
                setErrors(error.response.data)
            }
        }
    }

    const popupToolbarItems = commonPopupToolbarItems({
        onSave: async () => {
            const sent = failure?.id ? await EditAction() : await AddAction()
            if (sent) {
                popup.close()
                clearAllErrors()
                setFailure({ ...emptyFailure })
                setReloadKey(reloadKey + 1);
            }
        },
        onCancel: async () => {
            popup.close()
            clearAllErrors()
        },
        t: t
    })

    const popup = GenericPopup({
        onHiding: async () => {
            popup.close()
            clearAllErrors()
        },
        content:
            <div>
                {addEditModal}
            </div>,
        toolbarItems: popupToolbarItems,
        title: failure?.id ? t("#_FailureRegistry_EditTitle") : t("#_FailureRegistry_AddTitle"),
        width: "512",
        height: "80%",
        isVisible: isVisible,
        setIsVisible: setIsVisible,
        setBlockAutoRefresh: setBlockAutoRefresh
    })

    const openPopup = async () => {
        setFailure({ ...emptyFailure })
        setAvailableFailureTypesDataSource(makeAsyncDataSource(await failureTypesDataSource.load()))
        popup.show()
    }

    const getEnumKeyFromDataSource = (enumValue, propertyName) => {
        let result

        const dataSource = 
            propertyName === 'mode' ? failureModesDataSource : 
            propertyName === 'kind' ? failureKindsDataSource : failureTypesDataSource

        dataSource.load().then(x => {
            result = x.find(y => y.value === enumValue)?.key
        })

        return result
    }

    const openPopupEdit = () => {
        const mode = getEnumKeyFromDataSource(selectedItem.Mode, 'mode')
        const kind = getEnumKeyFromDataSource(selectedItem.Kind, 'kind')
        const type = getEnumKeyFromDataSource(selectedItem.Type, 'type')

        if (mode === undefined || kind === undefined || type === undefined)
        {
            notify({
                message: t("#_FailureEdit_MappingError"),
                position: {
                    my: 'center bottom',
                    at: 'center bottom',
                },
            }, 'error', 5000);

            return
        }

        setFailure({
            id: selectedItem.Id,
            mode: mode,
            kind: kind,
            type: type,
            dateFromUtc: utcToLocalDate(selectedItem.DateFromUtc),
            dateToUtc: utcToLocalDate(selectedItem.DateToUtc),
            annotation: selectedItem.Annotation
        })

        popup.show()
    }

    const toolbarButtons = [
        { icon: 'add', text: t("button-add-failure"), onClick: openPopup },
        { icon: 'edit', text: t("button-edit-failure"), onClick: openPopupEdit, disabled: selectedItem === null },
        { icon: 'textdocument', text: t("button-logs"), disabled: selectedItem === null, onClick: async () => setEventLogsVisible(true) },
    ]

    return (
        <>
            {popup?.popup}
            <BreadCrumbs node={getNode({ componentName: 'FailureRegistry', t })}></BreadCrumbs>
            {hasFeature(Features.ConfigFailure) && <div>
                <GenericToolbar className="xfx-toolbar-bigbuttons" buttons={toolbarButtons} toolbarId={'failureRegistryToolbar'}></GenericToolbar>
                {eventLogsVisible && <EventLogsPopup type={EventLogsTypes.Failure}
                                                 guid={selectedItem?.Id} 
                                                 setEventLogsVisible={setEventLogsVisible} 
                                                 eventLogsVisible={eventLogsVisible}
                                                 setBlockAutoRefresh={setBlockAutoRefresh}>
                                 </EventLogsPopup>}
                <div id="dataGrid-failureRegistry" className="dataGrid">
                    {
                        GenericOdataGrid({
                            ref: dataGridRef,
                            data: data,
                            endpoint: OdataEndpoints.Failure(),
                            setSelectedItem: setSelectedItem,
                            defaultFilter: dataGridFilter,
                            defaultSort: defaultSort,
                            updateDataGridFilter: updateDataGridFilter,
                            gridKey: reloadKey,
                            dataSourceOptions: dataSourceOptions,
                            setDataSourceOptions: setDataSourceOptions,
                            blockAutoRefresh: blockAutoRefresh,
                            setBlockAutoRefresh: setBlockAutoRefresh
                        })
                    }
                </div>
            </div>}
        </>
    );
}

export default FailureRegistry 