import GenericPopup from "./GenericPopup";


const SaveCancelPopup = ({ onSave, onCancel, title, content, saveLabel, width, height, t, cancelLabel, useSaveIcon, isVisible, setIsVisible, setBlockAutoRefresh }) => {
    const buttons = [
        {
            icon: useSaveIcon === undefined || useSaveIcon === true ? 'save' : undefined,
            text: saveLabel === undefined ? t("#_savecancelpopup_1") : saveLabel,
            action: onSave === undefined ? undefined : (popupHandler) => {
                onSave()
                popupHandler.close()
            },
            useSubmitBehavior: true,
            visible: onSave !== undefined,
            location: "before"
        },
        {
            text: cancelLabel === undefined ? t("#_savecancelpopup_2") : cancelLabel,
            action: (popupHandler) => {
                if (onCancel !== undefined) {
                    onCancel()
                }
                popupHandler.close()
            },
            location: "after"
        }
    ]
    const onHiding = () => popup.close()
    const popup = GenericPopup({ buttons, title, content, width, height, onHiding: onHiding, isVisible: isVisible, setIsVisible: setIsVisible, setBlockAutoRefresh: setBlockAutoRefresh })
    return popup;
}

export default SaveCancelPopup
