import React, { useEffect, useMemo, useState, useRef } from 'react';
import DataGrid, {
    Column, Editing, RowDragging, Lookup, Format, Texts,
    ColumnChooser, ColumnChooserSearch, ColumnChooserSelection, Position, Scrolling
} from 'devextreme-react/data-grid';
import { Fa1InvoiceItem } from "./Fa1InvoiceItem";
import { FieldGroup, FormField, RowsSummary, FieldsVisibility } from './model';
import { summaryContainer, lpColumnRender } from './RowsEditorCommon';
import { Template } from 'devextreme-react/core/template';
import { CheckBox } from 'devextreme-react';
import { Button } from 'devextreme-react/button';
import { columns } from './rowDataGridColumns';

interface RowsEditorProps {
    invoiceItems: Fa1InvoiceItem[];
    setInvoiceItems: (value: Fa1InvoiceItem[]) => void;
    FieldGroup: FieldGroup,
    fieldsVisiblityMap: { [key: string]: { isVisible: boolean, field: FormField }; }
    showP_6A: boolean;
    showCurrency: boolean;
    showOss: boolean;
    showNetto: boolean;
    setShowOss: (value: boolean) => void;
    clearRowsTemp: boolean;
}

const RowsEditor: React.FC<RowsEditorProps> = ({ invoiceItems, setInvoiceItems, FieldGroup, fieldsVisiblityMap, showP_6A, showCurrency, setShowOss, showOss, showNetto, clearRowsTemp }) => {

    // INIT
    const firstRender = useRef(true); // to stop some useEffects on first render
    const dataGridRef = useRef(null)
    
    const [summary, setSummary] = useState<RowsSummary>({ netto: 0, vat: 0, brutto: 0, vatInPln: 0 });
    const [columnVisibility, setColumnVisibility] = useState<FieldsVisibility>({
        rabatOpust: false,
        indeks: false,
        towarUsługaZal15: false,
        GTU: false,
        PKWiU: false,
        GTIN: false,
        CN: false,
        PKOB: false,
        procedura: false,
        kwotaPodatkuAkcyzowego: false,
        kwotaPodatku: false
    });
    // INIT

    // helper functions
    const updateIds = (rows: Fa1InvoiceItem[]) => { 
        for (let index = 0; index < rows.length; index++) {
            const id = index.toString();
            rows[index].ID = id;
            rows[index].lp = Number(id) + 1;
        }
        return rows
    }

    const createEmptyRow = (resetIdTemp: boolean): Fa1InvoiceItem => {
        let id = 0;
        
        if (!resetIdTemp) {
            id = invoiceItems.length;
        }
        const newProduct = new Fa1InvoiceItem(
            {
                ID: id.toString(),
                lp: id + 1,
                nazwaTowaruLubUslugi: '',
                ilosc: 0,
                miara: '', 
                cenaJednostkowaNetto: 0.00,
                cenaJednostkowaBrutto: 0.00,
                rabatOpust: 0.00,
                wartoscSprzedazyNetto: 0.00,
                wartoscSprzedazyBrutto: 0.00,
                kwotaPodatku: 0.00,
                stawkaPodatku: "",
                stawkaPodatkuXII: '',
                indeks: '',
                towarUsługaZal15: false,
                procedura: '',
                GTU: '',
                PKWiU: '',
                GTIN: '',
                CN: '',
                kwotaPodatkuAkcyzowego: 0,
                PKOB: '',
                kursWaluty: 0,
                P_6A: null,
                stanPrzed: false
            })
    
            // @ts-ignore
            const kwotaPodatkuInfo = dataGridRef?.current?.instance?.columnOption('kwotaPodatku');
            const updateKwotaPodatku = kwotaPodatkuInfo?.visible;
            newProduct.Calculate(showNetto, updateKwotaPodatku ?? false);

            return newProduct;
    }

    const handleColumnHidden = (
        columnNumberMatch: RegExpMatchArray, columnsArr: any, rows: Fa1InvoiceItem[], setRows: (items: Fa1InvoiceItem[]) => void,
        setColumnVisibility: (param: any) => void
    ) => {
        const columnNumber = parseInt(columnNumberMatch[1], 10);
        const column =  columnsArr[columnNumber];
        setColumnVisibility((old: any) => ({
            ...old,
            [column.dataField]: false
        }));

        const updatedItems: Fa1InvoiceItem[] = rows.map(item =>
            Object.setPrototypeOf(({
                ...item,
                [column.dataField]: (item as any)[column.dataField] === false || (item as any)[column.dataField] === true ? false : null 
            }), Fa1InvoiceItem.prototype)
        );
        if (column.dataField === "kwotaPodatku") {
            updatedItems.forEach(x => x.Calculate(showNetto, false))
        }
        setRows(updatedItems) 
    }

    const handleColumnShown = (
        columnNumberMatch: RegExpMatchArray, columnsArr: any, rows: Fa1InvoiceItem[], setRows: (items: Fa1InvoiceItem[]) => void,
        setColumnVisibility: (param: any) => void
    ) => {
        const columnNumber = parseInt(columnNumberMatch[1], 10);
        const column =  columnsArr[columnNumber];
        setColumnVisibility((old: any) => ({
            ...old,
            [column.dataField]: true
        }));

        if (column.dataField === "kwotaPodatku") {
            const updatedItems: Fa1InvoiceItem[] = rows.map(item => 
                Object.setPrototypeOf(({ ...item }), Fa1InvoiceItem.prototype)
            );
            updatedItems.forEach(x => x.Calculate(showNetto, true))
            setRows(updatedItems) 
        }
    }
    // helper functions

    // next useEffects
    useEffect(() => {
        if (firstRender.current) 
        return;
    
        if (showOss === true)
        {
            const updatedItems: Fa1InvoiceItem[] = invoiceItems.map(item => 
                Object.setPrototypeOf(({
                    ...item, 
                    stawkaPodatku: null
                }), Fa1InvoiceItem.prototype)
            );
            setInvoiceItems(updatedItems)
        }
        else
        {
            const updatedItems: Fa1InvoiceItem[] = invoiceItems.map(item => 
                Object.setPrototypeOf(({
                    ...item, 
                    stawkaPodatkuXII: null,
                    towarUsługaZal15: null 
                }), Fa1InvoiceItem.prototype)
            );
            // sprawdzić czy vat w pln się przelicza
            setInvoiceItems(updatedItems)
    }
    }, [showOss]); 

    useEffect(() => {
        if (firstRender.current) 
            return;
        
        if (showCurrency === false)
        {
            const updatedItems: Fa1InvoiceItem[] = invoiceItems.map(item => 
                Object.setPrototypeOf(({
                    ...item, 
                    kursWaluty: null 
            }), Fa1InvoiceItem.prototype));

            setInvoiceItems(updatedItems)
        }
    }, [showCurrency]);

    useEffect(() => {
        if (firstRender.current) 
            return;
        
        if (showP_6A === false)
        {
            const updatedItems: Fa1InvoiceItem[] = invoiceItems.map(item => 
                Object.setPrototypeOf(({
                    ...item, 
                    P_6A: null 
                }), Fa1InvoiceItem.prototype)
            );
            setInvoiceItems(updatedItems)
        }
    }, [showP_6A]);

    useEffect(() => {
        let netto = 0;
        let vat = 0;
        let vatInPln = 0;
        let brutto = 0;

        invoiceItems.forEach((item) => {
            netto += item.wartoscSprzedazyNetto;
            vat += item._vat;
            vatInPln += item._vatInPln;
            brutto += item.wartoscSprzedazyBrutto;
        });

        setSummary({ netto, vat, vatInPln, brutto });
    }, [invoiceItems]);
    // next useEffects

    const initActions = () => {
        const invoiceItemsArr: Fa1InvoiceItem[] = [] as Fa1InvoiceItem[];

        if (invoiceItems?.length === 0 || clearRowsTemp) {
            const initRow = createEmptyRow(true)
            invoiceItemsArr.push(initRow);

            setInvoiceItems(invoiceItemsArr)
        }

        const optFieldsExistance: FieldsVisibility = {
            rabatOpust: invoiceItems.some(x => x.rabatOpust),
            indeks: invoiceItems.some(x => x.indeks),
            towarUsługaZal15: invoiceItems.some(x => x.towarUsługaZal15),
            GTU: invoiceItems.some(x => x.GTU),
            PKWiU: invoiceItems.some(x => x.PKWiU),
            GTIN: invoiceItems.some(x => x.GTIN),
            CN: invoiceItems.some(x => x.CN),
            PKOB: invoiceItems.some(x => x.PKOB),
            procedura: invoiceItems.some(x => x.procedura),
            kwotaPodatkuAkcyzowego: invoiceItems.some(x => x.kwotaPodatkuAkcyzowego),
            kwotaPodatku: invoiceItems.some(x => x.kwotaPodatku && x.kwotaPodatku !== 0),
        }
        setColumnVisibility(optFieldsExistance);
    }

    useEffect(() => {
        if (clearRowsTemp) {
            initActions()
        }
    }, [clearRowsTemp]);

    // INIT useEffect
    useEffect(() => {
        initActions()

        if (firstRender.current) 
        {
            firstRender.current = false;
        }

        // for development in strict mode
        return () => { firstRender.current = true };

    }, []);
    // INIT useEffect

    // event handlers
    const onColumnChoserClick = () => {
        // @ts-ignore
        dataGridRef?.current?.instance?.showColumnChooser();
    }

    const addRow = () => {
        const row = createEmptyRow(false)

        // @ts-ignore
        setInvoiceItems(old => [...old, row])
    };

    const onOptChangedInRows = (e: any) => {
        if (e.name === "columns" && e.fullName.endsWith("visible") && e.value === false) {
            const columnNumberMatch = e.fullName.match(/\[(\d+)\]/);
            if (columnNumberMatch) {
                handleColumnHidden(columnNumberMatch, columnsArr, invoiceItems, setInvoiceItems, setColumnVisibility);
                // dataGridRef?.current?.instance?.showColumnChooser();
            }
        }
        if (e.name === "columns" && e.fullName.endsWith("visible") && e.value === true) {
            const columnNumberMatch = e.fullName.match(/\[(\d+)\]/);
            if (columnNumberMatch) { 
                handleColumnShown(columnNumberMatch, columnsArr, invoiceItems, setInvoiceItems, setColumnVisibility);
                // dataGridRef?.current?.instance?.showColumnChooser();
            }
        }
    }

    const handleRowUpdated = (e: any) => {
        const rowsWithUpdated = [...invoiceItems];
        const updatedRowIndex = rowsWithUpdated.findIndex(item => item.ID === e.key);
        if (updatedRowIndex > -1) {
            // @ts-ignore
            const kwotaPodatkuInfo = dataGridRef?.current?.instance?.columnOption('kwotaPodatku');
            const updateKwotaPodatku = kwotaPodatkuInfo?.visible;
            e.data.Calculate(showNetto, updateKwotaPodatku ?? false);
            rowsWithUpdated[updatedRowIndex] = e.data;
            setInvoiceItems(rowsWithUpdated)
        }
    };

    const removeRowByKey = (key: string) => {
        const invoiceItemsClone = [...invoiceItems];
        const indexAfter = invoiceItemsClone.findIndex(item => item.ID === key);
        if (indexAfter > -1) {
            invoiceItemsClone.splice(indexAfter, 1);
            updateIds(invoiceItemsClone)
            setInvoiceItems(invoiceItemsClone);
        }
    }
    // event handlers

    // memo variables
    const columnsArr: any = useMemo(() => 
        columns(showP_6A, showNetto, showOss, showCurrency, columnVisibility), 
        [showCurrency, showNetto, showOss, showP_6A]
    );
    // memo variables

    return (
        <>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <CheckBox
                    key={"oss-new-rows"}
                    text={'OSS'}
                    defaultValue={showOss}
                    onValueChanged={(e) => setShowOss(e.value)}
                />
                <Button
                    text="Dodatkowe kolumny"
                    stylingMode="outlined"
                    icon="insertcolumnleft"
                    onClick={onColumnChoserClick}
                />
            </div>

            <DataGrid
                id="gridContainerNewRows"
                dataSource={invoiceItems}
                keyExpr="ID"
                showBorders={true}
                width="100%"
                ref={dataGridRef}
                columnResizingMode="widget"
                showColumnLines={true}
                rowDragging={{
                    allowReordering: false, 
                }}
                columns={columnsArr}
                onOptionChanged={onOptChangedInRows}
                sorting={{ mode: 'none' }}
                onRowUpdated={(e) => handleRowUpdated(e)}
                onRowRemoving={(e) => {
                    removeRowByKey(e.key);
                }}
                noDataText='Brak pozycji'
            >
                <Template name="idColumn" render={lpColumnRender} />
                <Scrolling mode="virtual" preloadEnabled={true}/>
                <Editing
                    mode="cell"
                    allowAdding={true}
                    allowUpdating={true}
                    allowDeleting={true}>
                    <Texts confirmDeleteMessage='Czy ususnąć pozycję?' />
                </Editing>
                <ColumnChooser
                    enabled={true}
                    title={"Wybór widocznych kolumn"}
                    height={500}
                    width={400}
                    mode={'select'}
                >
                    <Position
                        my={{x: "center", y: "center"}}
                        at={{x: "center", y: "center"}}
                    />

                    <ColumnChooserSearch
                        enabled={true}
                        editorOptions={true} />

                    <ColumnChooserSelection
                        selectByClick={true}
                        recursive={true} />
                </ColumnChooser>
            </DataGrid >

            {summaryContainer(summary, showCurrency)}


            <button onClick={addRow}>Dodaj wiersz</button>
        </>
    );
}

export default RowsEditor;

