import { useState, useEffect } from 'react'
import SaveCancelPopup from 'components/popup/SaveCancelPopup'
import { XfXApi } from 'api/XfXApi'
import { parseArrayFromString } from '../Sale/SalesAcceptance/actions/parseArrayFromString'
import { useTranslation } from 'react-i18next';

export const AcceptanceUsersPopup = ({ usersManagement, setBlockAutoRefresh }) => {
    const { t } = useTranslation()
    const [users, setUsers] = useState()
    const [isVisible, setIsVisible] = useState(false)

    const getUsers = () => {
        usersManagement ? XfXApi.User.apiTenantIdUserUsersGet(XfXApi.GetTenantId()).then((resp) => { setUsers(resp.data.stateObject) }) :
            setUsers([])

    }

    useEffect(getUsers, [usersManagement])

    const [acceptRequirements, setAcceptRequirements] = useState()
    const popup = SaveCancelPopup({
        title: t("#_acceptanceuserspopup_1"),
        width: 500,
        height: 300,
        content: <div>
            <ul>
                {acceptRequirements}
            </ul>
        </div>,
        t,
        isVisible: isVisible,
        setIsVisible: setIsVisible,
        setBlockAutoRefresh: setBlockAutoRefresh
    })

    const show = (requiresManualAcceptCount, userIdsStr) => {
        var requirements = []
        if (requiresManualAcceptCount !== null && requiresManualAcceptCount != undefined) {
            requirements.push(`${t("acceptanceuserspopup-required-count")}: ${requiresManualAcceptCount}`)
        }
        if (userIdsStr !== '' && userIdsStr !== undefined) {
            const ids = parseArrayFromString(userIdsStr)
            const usersList = ids.map(id => {
                const user = users.find(user => user.id === id)
                return {
                    id: id,
                    name: user !== undefined ? user.firstName + " " + user.lastName + " (" + user.email + ")" : "?"
                }
            }).map(x => <li key={x.id}>{x.name}</li>)

            requirements = usersList
        }
        setAcceptRequirements(requirements)

        popup.show()
    }
    return { ...popup, show }
}
