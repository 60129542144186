import { ToolbarItem } from 'devextreme-react/popup';
import { useTranslation } from 'react-i18next';
import GenericPopup from "components/popup/GenericPopup";

export const UserDetailsPopup = ({ user, setUserDetailsVisible, userDetailsVisible }) => {
    const { t } = useTranslation()
    const fullNameVisible = ((user?.firstName !== null && user?.firstName !== '') || (user?.lastName !== null && user?.lastName !== ''))
    const typeVisible = user?.type !== undefined

    const popup = GenericPopup({ 
        onHiding: () => setUserDetailsVisible(false),
        content:
        <div>
          <div>
            <p>
                Użytkownik: <span>{user?.login}</span>
            </p>
            {fullNameVisible &&
                <p>
                    Pełna nazwa:&nbsp;
                    <span>{user?.firstName ?? ""}</span>&nbsp;
                    <span>{user?.lastName ?? ""}</span>
                </p>
            }
            {typeVisible &&
                <p>
                    Typ: <span>{user?.type === 0 ? t("#_eventlogstools_2") : "API"}</span>
                </p>
                }
          </div>
        </div>,
        title: t("#_eventlogstools_1"),
        width: "400px",
        height: "320px",
        toolbarItems: [<ToolbarItem
            widget="dxButton"
            toolbar="bottom"
            location="after"
            options={{
                text: t("#_eventlogstools_3"),
                onClick: () => {
                  setUserDetailsVisible(false)
                }
            }} /> ],
        isVisible: userDetailsVisible,
        setIsVisible: setUserDetailsVisible
      })

    return (
      <>
        {popup.popup}
        </>
    );
  }

  export const BusinessErrorDetailsPopup = ({ businessError, setBusinessErrorDetailsVisible, businessErrorDetailsVisible }) => {
    const { t } = useTranslation()
    const dateTimeToReturn = (businessError?.timeStampUtc !== null && businessError?.timeStampUtc !== undefined) ? 
    `${new Date(businessError?.timeStampUtc).toLocaleDateString()} ${new Date(businessError?.timeStampUtc).toLocaleTimeString()}` : ''

    var details = businessError?.detailedMessage;
    if(details === undefined)
    {
        if(businessError?.data !== undefined)
        {
            var moduleResults = businessError?.data["module-result"];
            if(moduleResults !== undefined && moduleResults !== null)
                details = JSON.parse(businessError.data["module-result"])?.Errors?.join('\r\n')
        }
        if(details === undefined || details=== null)
            details =''
    }

    const popup = GenericPopup({ 
        onHiding: () => setBusinessErrorDetailsVisible(false),
        content:
        <div>
          <div>
            <p>
                {t("#_eventlogstools_112")} <span>{dateTimeToReturn}</span>
            </p>
            <p>
                {t("#_eventlogstools_113")} <span>{businessError?.code}</span>
            </p>
            <p>
                {t("#_eventlogstools_114")} <span>{businessError?.message}</span>
            </p>
            <p>
                {t("#_eventlogstools_115")} <span>{details}</span>
            </p>
          </div>
                  <ToolbarItem
                      widget="dxButton"
                      toolbar="bottom"
                      location="after"
                      options={{
                          text: t("#_eventlogstools_5"),
                          onClick: () => {
                            setBusinessErrorDetailsVisible(false)
                          }
                      }} /> 
        </div>,
        title: t("#_eventlogstools_4"),
        width: "1000px",
        height: "500px",
        toolbarItems: [<ToolbarItem
            widget="dxButton"
            toolbar="bottom"
            location="after"
            options={{
                text: t("#_eventlogstools_5"),
                onClick: () => {
                  setBusinessErrorDetailsVisible(false)
                }
            }} />  ],
        isVisible: businessErrorDetailsVisible,
        setIsVisible: setBusinessErrorDetailsVisible
      })

      return (
        <>
          {popup.popup}
          </>
      );
  }
  

export const bpmnProcessKeyToLabel = (s, t, prefix) => {
    return s.bpmnProcessKey ? t(`${prefix}${s.bpmnProcessKey}`) : "" //This condition handles case when bpmnProcessKey is undefined (undefined !== known enum value Unspecified)
  }

  export const timestampUtcToLabel = s => { 
    return new Date(`${s.timestampUtc}Z`)//Will it always work?
  }

  export const businessErrorToLabel = s => { 
    return s?.businessError !== null && s?.businessError !== undefined ? s?.businessError?.message : null
  }

  export const moduleResultToLabel = (s, t) => {
    if (s.moduleResult === true) return t("#_eventlogstools_12")
    if (s.moduleResult === false) return t("#_eventlogstools_13")
    if (s.moduleResult === null) return ""
  
    return s.moduleResult
  }

  export const businessEventToLabel = (s, t, prefix) => {
    return t(`${prefix}${s.businessEvent}`)
  }
