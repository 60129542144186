import { findValue } from "../FormFieldComponent";
import { FieldType, FormField } from "../model";
import { Parser } from 'hot-formula-parser'

interface CalculateFieldsVisibilityArgs {
  fieldsVisiblityMap: { [key: string]: { isVisible: boolean, field: FormField }; };
  invoiceValues: { [key: string]: any; };
}

interface CalculateFieldsVisibilityReturn {
  anythingChanged: boolean;
  update: { [key: string]: { isVisible: boolean, field: FormField }; };

}

export const CalculateFieldsVisibility = ({ fieldsVisiblityMap, invoiceValues }: CalculateFieldsVisibilityArgs): CalculateFieldsVisibilityReturn | undefined => {
  const update = { ...fieldsVisiblityMap }

  const cells: { [key: string]: any; } = {}
  for (const key in fieldsVisiblityMap) {


    if (fieldsVisiblityMap.hasOwnProperty(key)) {
      const { field } = fieldsVisiblityMap[key]

      if (invoiceValues === undefined) {
        return
      }
      const currentValue = findValue(invoiceValues, field.Identifier)

      if (currentValue.value !== undefined && field.PossibleValues) {
        const printableValue = field.PossibleValues.find(x => x.XmlValue === currentValue.value)?.PrintableValue
        if (printableValue !== undefined) {
          cells[field.Address] = printableValue
        } else {
          console.error("printableValue not found for " + currentValue.value + " in " + field.Address);

        }
      } else if (currentValue.value === undefined && field.Type === FieldType.CheckBox) {
        cells[field.Address] = "NIE"
      } else {
        cells[field.Address] = invoiceValues[field.Address] ?? "" // empty string is needed for parser to work beacuse in excel empty cell is ""
      }
    }
  }

  const parser = new Parser()
  parser.on('callCellValue', (cellCoord, done) => {
    const ret = cells[cellCoord.label]

    if (ret === undefined) {
      //console.log("required " + ret + " for " + cellCoord.label)
    }
    done(ret)
  });

  let anythingChanged = false
  for (const key in fieldsVisiblityMap) {

    if (fieldsVisiblityMap.hasOwnProperty(key)) {
      const { field, isVisible } = fieldsVisiblityMap[key]
      const formulas = field.Conditions
      if (formulas.length === 0) {

        const newState = true
        anythingChanged = anythingChanged || newState !== isVisible
        update[key] = {
          isVisible: true,
          field: field
        }

      } else {

        let isFieldHidden = false

        for (let i = 0; i < formulas.length; i++) {
          const formula = formulas[i];
          const formulaToParse = formula.Expression.substring(1);
          const { result, error } = parser.parse(formulaToParse);

          if (error === null) {
            if (result === true) {
              isFieldHidden = true
              break// One condition met we don't need to check the others.
            }
          } else {
            console.error(`Fail to parse formula ${formulaToParse} for ${field.Address}(${field.Identifier})`);
          }
        }

        const newVisibility = !isFieldHidden

        if (newVisibility !== isVisible) {
          anythingChanged = true;
          update[key] = {
            isVisible: newVisibility,
            field: field
          }
          console.log(`Change field ${field.Address} (${field.Identifier}) visibility to ${isFieldHidden}`)
        }
      }
    }
  }

  return { anythingChanged, update }
}