import React, { useEffect, useState, useRef } from "react"
import 'devextreme-react/autocomplete';
import { MasterDetail } from 'devextreme-react/data-grid';
import GenericToolbar from '../Toolbars/GenericToolbar'
import BreadCrumbs from 'components/breadCrumbs/BreadCrumbs';
import { getNode } from 'components/breadCrumbs/getNode';
import { fetchContractors, renderDetail} from "./actions";
import AddEditPopup from "./AddEditPopup";
import { columns } from "./const";
import { useTranslation } from 'react-i18next';
import DeletionPopup from 'components/popup/DeletionPopup';
import { XfXApi } from 'api/XfXApi'; 
import { toolbarButtons } from "./toolbarButtons";
import GenericOdataGrid from 'pages/Invoices/GenericOdataGrid';
import { OdataEndpoints } from 'api/XfXApi';
import useDataGridFilter from 'utils/useDataGridFilter'
import notify from "devextreme/ui/notify";

const Organizations = () => {
  const { t } = useTranslation()

  const [organizationId, setOrganizationId] = useState(null)
  const [organizationNip, setOrganizationNip] = useState(null)
  const [organizationHasAnyInvoices, setOrganizationHasAnyInvoices] = useState(null)
  const [popupTitle, setPopupTitle] = useState(t("#_const_28"))
  const [visible, setVisible] = useState(false)

  const [selectedOrganization, setSelectedOrganization] = useState(null)

  const dataGridOrganizations = useRef(null)

  const [contractorsData, setContractorsData] = useState([])
  const [deletionPopupVisible, setDeletionPopupVisible] = useState(false)
  const [deletionPopupContent, setDeletionPopupContent] = useState("") 

  const [data, setData] = useState([])

  const initialFilter = []
  const { dataGridFilter, updateDataGridFilter } = useDataGridFilter({ initialFilter })
  const defaultSort = { selector: "CreationDateUtc", desc: true }

  const [reloadKey, setReloadKey] = useState(0)
  const [dataSourceOptions, setDataSourceOptions] = useState()

  const [blockAutoRefresh, setBlockAutoRefresh] = useState(false)

  const deletionPopup = DeletionPopup({
    onConfirm: async () => {
      const selected = dataGridOrganizations.current.instance.getSelectedRowsData()

      if (selected.length === 0) 
        return

      dataGridOrganizations.current.instance.collapseAll(-1)

      try {
        const response = await XfXApi.Organization.apiTenantIdOrganizationDelete(XfXApi.GetTenantId(), selectedOrganization.Id?._value ?? selectedOrganization?.Id)

        if (response.status === 200) {
          notify({
            message: t("#_actions_20"),
            position: {
              my: 'center bottom',
              at: 'center bottom',
            },
          }, 'success', 5000);
        }
      } catch (error) {
        if (error.response.status === 400) {
          notify({
            message: `${t("#_organizations_2")} \r\n \r\n` + error.response.data.errors.join('\r\n'),
            position: {
              my: 'center bottom',
              at: 'center bottom',
            },
          }, 'error', 10000);
        }
      }
      finally {
        setReloadKey(oldVal => oldVal + 1)
      }
    },
    content: deletionPopupContent,
    title: t("#_DeletionPopup_delete"),
    isVisible: deletionPopupVisible,
    setIsVisible: setDeletionPopupVisible,
    t: t,
    setBlockAutoRefresh: setBlockAutoRefresh
  })

  const deleteOrganization = () => {
    setDeletionPopupContent(t("#_DeletionPopup"));
    deletionPopup.show()
  }

  useEffect(() => {
    fetchContractors({ setContractorsData })
  }, [t])

  useEffect(()=>{
    setData(columns({t}))
  }, [t, setData])

  const masterDetail = () => {
    return <MasterDetail
            enabled={true}
            render={() => renderDetail({ selectedItem: selectedOrganization, contractorsData: contractorsData, setContractorsData, setBlockAutoRefresh })} 
    />
  }

  return (
    <div id="dataGrid-organizations">
      {visible && 
        <AddEditPopup
          setVisible={setVisible}
          popupTitle={popupTitle}
          organizationEdit={organizationId ? true : false}
          organizationId={organizationId}
          organizationNip={organizationNip}
          dataGridOrganizations={dataGridOrganizations}
          visible={visible}
          setReloadKey={setReloadKey}
          setBlockAutoRefresh={setBlockAutoRefresh}
          organizationHasAnyInvoices={selectedOrganization?.HasAnyInvoices}>
        </AddEditPopup>
      }
      {deletionPopup.popup}
      <BreadCrumbs node={getNode({ componentName: t("#_organizations_1"), t })}></BreadCrumbs>
      <GenericToolbar
        className="xfx-toolbar-bigbuttons"
        buttons={toolbarButtons({
          deleteOrganization: () => deleteOrganization(), selectedOrganization: selectedOrganization, dataGridOrganizations: dataGridOrganizations,
          setOrganizationId, setOrganizationNip, setVisible, setPopupTitle, t
        })}>
      </GenericToolbar>
      <div id="dataGrid-organizations" className="dataGrid">
          {GenericOdataGrid({ 
            ref: dataGridOrganizations,
            data: data, 
            endpoint: OdataEndpoints.Organizations(), 
            setSelectedItem: setSelectedOrganization, 
            defaultFilter: dataGridFilter,
            defaultSort: defaultSort, 
            updateDataGridFilter: updateDataGridFilter,
            autoReload: true,
            gridKey: reloadKey,
            dataSourceOptions: dataSourceOptions,
            setDataSourceOptions,
            masterDetail: masterDetail,
            blockAutoRefresh: blockAutoRefresh,
            setBlockAutoRefresh: setBlockAutoRefresh
          })}
      </div>
    </div>
  )
}

export default Organizations
