import { RefObject } from 'react';
import { XfXApi } from "api/XfXApi";
import { DataSource } from 'api/openapi-invoice';
import { ExportType } from 'components/export-popup/ExportPopup';

interface IExportRepositoryProps {
    organizationId: string;
    dataGridRef: RefObject<any>;
    lastUsedQueryParms: Record<string, any>;
    selectedFormat: ExportType;
    gridId: string;
    t: (key: string) => string;
}

export const ExportRepository = ({
    organizationId,
    dataGridRef,
    lastUsedQueryParms,
    selectedFormat,
    gridId,
    t
}: IExportRepositoryProps) => {
    const dataGrid = dataGridRef.current.instance;
    const visibleColumns = dataGrid.getVisibleColumns();
    const orderedVisibleColumnNames = visibleColumns.map((x: any) => ({ name: x.name, caption: x.caption }));

    const filter = lastUsedQueryParms['$filter'];
    const orderby = lastUsedQueryParms['$orderby'];

    let source: DataSource;
    switch (gridId) {
        case 'RepositorySales':
            source = DataSource.SaleRepo;
            break;
        case 'RepositoryPurchase':
            source = DataSource.PurchaseRepo;
            break;
        case 'RepositoryOther':
            source = DataSource.OtherRepo;
            break;
        default:
            source = gridId as DataSource;
            break;
    }

    XfXApi.ExportApi.apiTenantIdOrganizationIdExportPost(XfXApi.GetTenantId(), organizationId, {
        columns: orderedVisibleColumnNames,
        filter: filter,
        orderBy: orderby,
        outputType: selectedFormat,
        source: source,
        singleQtyText: t("single-qty-text")
    });
}



interface IExportRepoActionProps {
    organizationId: string;
    dataGridRef: RefObject<any>;
    lastUsedQueryParms: Record<string, any>;
    gridId: string;
    selectedFormat: ExportType;
    t: (key: string) => string;
}

export const GetExportRepoAction = ({ organizationId,
    dataGridRef,
    lastUsedQueryParms,
    gridId,
}: IExportRepoActionProps) => {
    return (selectedFormat: ExportType, t: (key: string) => string) =>
        ExportRepository({
            organizationId,
            dataGridRef,
            lastUsedQueryParms,
            selectedFormat,
            gridId,
            t
        });
}