import { Routes, Route, Navigate } from 'react-router-dom';
import appInfo from './app-info';
import routes from './app-routes';
import { SideNavOuterToolbar as SideNavBarLayout } from './layouts';
import useKsefNotifications from 'utils/useKsefNotifications'
import PrivsOrganizationContextChecker from './PrivsOrganizationContextChecker';


const Content = () => {

  useKsefNotifications()

  return (
      <SideNavBarLayout title={appInfo.title}>
        <PrivsOrganizationContextChecker />
        <Routes>
          {routes.map(({ path, component, element }) => (
            <Route
              exact
              key={path}
              path={path}
              element={element}
            />
          ))}
          <Route
            path="*"
            element={<Navigate to="/home" replace />}
          />
        </Routes>
        {/* <Footer>
      </Footer> */}
      </SideNavBarLayout>
  );
}

export default Content