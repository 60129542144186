import { XfXApi } from 'api/XfXApi';

export const getUpoForInvoicePdf = async (organizationId, invoiceId, ksefNumber) => {
    const invId = invoiceId?._value ? invoiceId?._value : invoiceId

    XfXApi.InvoiceApi.apiTenantIdOrganizationIdInvoicesUpoPdfGet(XfXApi.GetTenantId(), organizationId, invId, {
        method: "GET",
        responseType: 'blob', //important for binary file
    })
        .then(response => {

            if (response.status !== 200) return
            const downloadUrl = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = downloadUrl;

            link.setAttribute('download', ksefNumber + "_upo.pdf");
            document.body.appendChild(link);
            link.click();
            link.remove();
        })
}