import { useEffect, useState, useRef } from "react"
import { XfXApi } from 'api/XfXApi';
import GenericToolbar from '../../settings/Toolbars/GenericToolbar';
import DataGrid, {
    Scrolling, Paging, Column, HeaderFilter, FilterRow, Selection, Search
} from 'devextreme-react/data-grid';
import useDataSourceAutoReload from '../../../utils/useDataSourceReload'
import useToast from '../../../utils/useToast'
import KsefLoginPopup from '../KsefLoginPopup'
import SelectKsefRoleEditorPopup from './SelectKsefRoleEditorPopup'
import RoleNames from './RoleNames'
import { OdataEndpoints } from 'api/XfXApi';
import { GetOdataDataSource } from 'utils/dataSourceHelper'
import { useRefreshButton as reload } from "utils/refresh";
import BreadCrumbs from 'components/breadCrumbs/BreadCrumbs';
import { getNode } from 'components/breadCrumbs/getNode';
import { useOrganizationDropDownHandler } from 'utils/organizationDropDownHandler';
import { useTranslation } from 'react-i18next';
import { useUserSettingsContext } from "../../../contexts/UserSettingsProvider";
import DeletionPopup from 'components/popup/DeletionPopup';
import { renderDateCell } from 'utils/cellRenderers'
import notify from "devextreme/ui/notify"

const statusToString = (s, t) => {
    if (s.Status === "ToAdd") return t("#_ksefroles_3")
    if (s.Status === "ToRemove") return t("#_ksefroles_4")
    if (s.Status === "Active") return t("#_ksefroles_5")
    if (s.Status === "ToUpdate") return t("#_ksefroles_7")

    return s.Status
}

const rolesToString = (row, t) => row.Roles.map((x) => roleToString(x, t)).join(', ')

const roleToString = (r, t) => {
    if (r.toLowerCase() === "owner") return RoleNames({ t }).Owner;
    if (r.toLowerCase() === "introspection") return RoleNames({ t }).Introspection;
    if (r.toLowerCase() === "invoice_read") return RoleNames({ t }).Invoice_read;
    if (r.toLowerCase() === "invoice_write") return RoleNames({ t }).Invoice_write;
    if (r.toLowerCase() === "payment_confirmation_write") return RoleNames({ t }).Payment_confirmation_write;
    if (r.toLowerCase() === "credentials_read") return RoleNames({ t }).Credentials_read;
    if (r.toLowerCase() === "credentials_manage") return RoleNames({ t }).Credentials_manage;
    if (r.toLowerCase() === "self_invoicing") return RoleNames({ t }).Self_invoicing;
    if (r.toLowerCase() === "tax_representative") return RoleNames({ t }).Tax_representative;
    if (r.toLowerCase() === "enforcement_operations") return RoleNames({ t }).Enforcement_operations;
    if (r.toLowerCase() === "court_bailiff") return RoleNames({ t }).Court_bailiff;
    if (r.toLowerCase() === "enforcement_authority") return RoleNames({ t }).Enforcement_authority;
    return "-"
}

const KsefRoles = () => {
    const { t } = useTranslation()
    const header = t("#_ksefroles_1")
    const { getOrganizationContextSettings } = useUserSettingsContext()
    const [organizationId, setOrganizationId] = useState(getOrganizationContextSettings()?.NIP)
    const [selectedItem, setSelectedItem] = useState(null)
    const [editVisible, setEditVisible] = useState(false)
    const [ksefLoginVisible, setKsefLoginVisible] = useState(false)
    const [autosignAuthChallenge, setAutosignAuthChallenge] = useState(false)
    const [deletionPopupVisible, setDeletionPopupVisible] = useState(false)
    const [deletionPopupContent, setDeletionPopupContent] = useState("")
    const [roleToEdit, setRoleToEdit] = useState(null)
    const [refreshKey, setRefreshKey] = useState(0)
    const [syncInProgress, setSyncInProgress] = useState(false)
    const { toast, showToastError } = useToast()
    const [blockAutoRefresh, setBlockAutoRefresh] = useState(false)

    const defaultSort = { selector: "CreationDateUtc", desc: true };
    const dataSource = (orgId) => GetOdataDataSource(OdataEndpoints.KsefRole(orgId), defaultSort,
        ['Id', 'Nip', 'UserName', 'IsIndividualRole', 'CreationDateUtc', 'Status', 'Roles', 'LastSynchronizationDateUtc'], null, null, 'Int32', null)
    const [ds, setDs] = useState(dataSource(organizationId))

    const dataGridRef = useRef(null)

    const refresh = () => {
        ds.reload()
        setRefreshKey(refreshKey + 1)
    }
    const onClose = () => {
        refresh()
        setBlockAutoRefresh(false)
    }

    reload(refresh)

    useOrganizationDropDownHandler(async (e) => {
        const organizationContextFromDetails = e?.detail?.OrganizationContext
        setOrganizationId(organizationContextFromDetails?.NIP)
    })

    useEffect(() => {
        setDs(dataSource(organizationId))
        XfXApi.KsefAuthApi.apiTenantIdKsefAuthAutosignGet(XfXApi.GetTenantId()).then((x) => { setAutosignAuthChallenge(x.data) })
    }, [organizationId])


    const showEditorForNew = () => {
        setRoleToEdit(null)
        setEditVisible(true)
        setBlockAutoRefresh(true)
    }

    const showEditorForEdit = () => {
        setRoleToEdit(selectedItem)
        setEditVisible(true)
        setBlockAutoRefresh(true)
    }
    const deletionPopup = DeletionPopup({
        onConfirm: async () => {
            try {
                await XfXApi.KsefRoleApi.apiTenantIdOrganizationIdKsefRoleIdDelete(selectedItem.Id, XfXApi.GetTenantId(), organizationId)
                setSelectedItem(null)
            }
            catch (ex) {
                showToastError(t("#_ksefroles_20"))
            } finally {
                refresh()
            }
        },
        content: deletionPopupContent,
        title: t("#_DeletionPopup_delete"),
        isVisible: deletionPopupVisible,
        setIsVisible: setDeletionPopupVisible,
        t: t,
        setBlockAutoRefresh: setBlockAutoRefresh
    })

    const deleteSelected = () => {
        setDeletionPopupContent(t("#_DeletionPopup"));
        deletionPopup.show();
    }

    useDataSourceAutoReload({ dataSource: dataSource, onDataReload: refresh, blockAutoRefresh: blockAutoRefresh })

    const syncWithKsef = async () => {
        try {
            const tokenCheck = await XfXApi.KsefCredentialsApi.apiTenantIdOrganizationIdKsefCredentialsPrivilegesByTokenGet(XfXApi.GetTenantId(), organizationId)
            if (tokenCheck.data.tokenActive && tokenCheck.data.credentialsManageAvalailable) {
                notify({
                    message: t("#_ksefroles_40"),
                    position: {
                        my: 'center bottom',
                        at: 'center bottom',
                    },
                }, 'success', 5000);
                await requestRolesSync()
            }
            else {
                setKsefLoginVisible(true)
            }
        } finally {
            refresh()
        }
    }

    const toolbarButtons = [
        { icon: 'add', text: t("#_ksefroles_22"), disabled: organizationId === null || organizationId === undefined, onClick: showEditorForNew },
        { icon: 'edit', text: t("#_ksefroles_240"), disabled: selectedItem == null || selectedItem.Status === 'ToRemove' || selectedItem.Roles.some(r => r.toLowerCase() === "owner"), onClick: showEditorForEdit },
        { icon: 'trash', text: t("#_ksefroles_26"), disabled: selectedItem == null || selectedItem.Status === 'ToRemove' || selectedItem.Roles.some(r => r.toLowerCase() === "owner"), onClick: deleteSelected },
        { icon: 'refresh', text: t("#_ksefroles_sync"), disabled: organizationId == null || syncInProgress, onClick: syncWithKsef }
    ]

    const columns = [
        <Column dataField="Nip" width="auto" caption={t("#_ksefroles_25")} visible={false} dataType='string' />,
        <Column dataField="UserName" width="auto" caption="Kontekst użytkownika (NIP/PESEL)" dataType='string' />,
        <Column dataField="CreationDateUtc" width="auto" caption={t("#_ksefroles_28")} dataType='datetime' cellRender={x => renderDateCell(x.data?.CreationDateUtc)} />,
        <Column dataField="LastSynchronizationDateUtc" width="auto" caption={t("#_ksefroles_38")} dataType='datetime' cellRender={x => renderDateCell(x.data?.LastSynchronizationDateUtc)} />,
        <Column dataField="Roles" caption={t("#_ksefroles_29")} calculateCellValue={x => rolesToString(x, t)} allowSorting={false} dataType='string' />,
        <Column dataField="Status" width="auto" caption={t("#_ksefroles_31")} calculateCellValue={x => statusToString(x, t)} dataType='string' />,
    ]

    const requestRolesSync = async () => {
        if (organizationId === null || organizationId === undefined) return

        try {
            setSyncInProgress(true)
            const response = await XfXApi.KsefRoleApi.apiTenantIdOrganizationIdKsefRoleSyncGet(XfXApi.GetTenantId(), organizationId)

            if (response.status === 200) {
                notify({
                    message: t("#_actions_14"),
                    position: {
                        my: 'center bottom',
                        at: 'center bottom',
                    },
                }, 'success', 5000);
            }
        }
        catch (error) {
            if (error.response.status === 400) {
                notify({
                    message: `${t("#_ksefroles_36")} \r\n \r\n` + error.response.data.join('\r\n'),
                    position: {
                        my: 'center bottom',
                        at: 'center bottom',
                    },
                }, 'error', 10000);
            }
        }finally{
            setSyncInProgress(false)
        }
    }

    return (<>
        {toast}
        {deletionPopup.popup}
        {organizationId !== null && organizationId !== undefined && 
            <KsefLoginPopup 
                organizationNip={organizationId} 
                isVisible={ksefLoginVisible} 
                setIsVisible={setKsefLoginVisible}
                showToastError={showToastError} 
                onSuccessfulLogin={requestRolesSync} 
                autosignAuthChallenge={autosignAuthChallenge}
                setBlockAutoRefresh={setBlockAutoRefresh}>
            </KsefLoginPopup>
        }
        {editVisible && 
            <SelectKsefRoleEditorPopup 
                role={roleToEdit} 
                organization={organizationId} 
                isVisible={editVisible} 
                setIsVisible={setEditVisible} 
                onClose={onClose}
                setBlockAutoRefresh={setBlockAutoRefresh}>
            </SelectKsefRoleEditorPopup>
        }
        <BreadCrumbs node={getNode({ componentName: 'KsefRoles', t })}></BreadCrumbs>
        <GenericToolbar className="xfx-toolbar-bigbuttons" header={header} buttons={toolbarButtons} showIcon={true}></GenericToolbar>
        {organizationId !== null && organizationId !== undefined && 
            <div className="dataGrid">
                <DataGrid
                    ref={dataGridRef}
                    dataSource={ds}
                    showBorders={true}
                    height={800}
                    rowAlternationEnabled={true}
                    showColumnLines={true}
                    onSelectionChanged={s => {
                        setSelectedItem(s.selectedRowsData.length > 0 ? s.selectedRowsData[0] : null)
                    }}
                    loadPanel={
                        { enabled: false }
                    }
                >
                    <Selection mode="single" />
                    <Scrolling mode="virtual" preloadEnabled={true} />
                    <Paging defaultPageSize={100} />
                    <HeaderFilter>
                        <Search
                            enabled={true}
                        >
                        </Search>
                    </HeaderFilter>
                    <FilterRow visible={true} applyFilter={true} />

                    {columns}
                </DataGrid>
            </div>
        }
    </>)
}


export default KsefRoles;
