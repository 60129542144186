import { useEffect, useState } from "react";
import 'devextreme-react/autocomplete';
import BreadCrumbs from 'components/breadCrumbs/BreadCrumbs';
import { getNode } from 'components/breadCrumbs/getNode';
import Toolbar, { Item } from 'devextreme-react/toolbar';
import { Form, SimpleItem, Label, GroupItem } from 'devextreme-react/form';
import { useTranslation } from 'react-i18next';
import { XfXApi } from 'api/XfXApi';
import { autoLogoutOptions } from './const.jsx';
import notify from "devextreme/ui/notify";
import SecurityLogSettingsEditor from 'pages/settings/SecurityLog/SecurityLogSettingsEditor';
import { useAuth } from 'contexts/auth'
import { Features } from "features.ts";

const SecurityConfigurator = () => {
  const { t } = useTranslation();
  const [form, setForm] = useState({
    autoLogout: 0
  });

  const { hasFeature } = useAuth()

  useEffect(() => {
    XfXApi.SettingsApi.apiTenantIdSettingsSecurityGet(XfXApi.GetTenantId())
      .then(cont => { if (cont.data) { setForm(cont.data) } })
      .catch(() => { });
  }, []);

  const onSaved = async ()=>
  {
    try {
      const response = await XfXApi.SettingsApi.apiTenantIdSettingsSecurityPost(XfXApi.GetTenantId(), form)
      if (response.status === 200) {
        notify({
          message: t("#_securitysavesuccess"),
          position: {
            my: 'center bottom',
            at: 'center bottom',
          },
        }, 'success', 5000);
      }
    } catch (error) {
      if (error.response.status === 400) {
        notify({
          message: error.response.data.messages[0],
          position: {
            my: 'center bottom',
            at: 'center bottom',
          },
        }, 'error', 10000);
      }
    }
  }

  const SecurityForm = () => (
    <>
      <p>{t('securityConfig_description')}</p>
      <Form
        id="form"
        labelMode="static"
        minColWidth={300}
        colCount={3}
        formData={form}>
        <GroupItem colCount={3} colSpan={3}>
          <SimpleItem dataField="autoLogout" editorType="dxSelectBox" value={form.autoLogout}
            editorOptions={{
              key: "key",
              valueExpr: "key",
              displayExpr: "value",
              dataSource: autoLogoutOptions({ t }),
              value: form.autoLogout
            }}>
            <Label text={t("#_securitycode_1")} />
          </SimpleItem>
        </GroupItem>
      </Form>
      <Toolbar className='basicToolbar'>
        <Item
          widget="dxButton"
          toolbar="bottom"
          location="before"
          options={{
            text: t('#_savecancelpopup_1'),
            onClick: onSaved
          }}
        />
      </Toolbar>
    </>
  );

  return (
    <>
      <BreadCrumbs node={getNode({ componentName: 'SecurityConfigurator', t })}></BreadCrumbs>
      <SecurityForm />
      {hasFeature(Features.SecurityLog) && <SecurityLogSettingsEditor />}
    </>
  );
};

export default SecurityConfigurator;
