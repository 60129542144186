import React from 'react';
import { SecurityEventLogDetails, SecurityEventLogParameter } from './Types';
import { getChanges } from './getChanges';
import { CSSProperties } from 'react';
import { useTranslation } from 'react-i18next';

interface ChangeLogProps {
  details: SecurityEventLogDetails | undefined;
}

const ChangeLog: React.FC<ChangeLogProps> = ({ details }) => {
  const { t } = useTranslation();

  if (!details) return <></>;

  const listOfChanges = details.Changes && details.Changes.length > 0 ? getChanges(details.Changes) : [];
  const parameters = details.Parameters || [];

  const changeLogStyle: CSSProperties = {
    whiteSpace: 'pre-wrap',
    margin: '10px 0'
  };

  const parameterStyle: CSSProperties = {
    margin: '5px 0',
    padding: '5px',
  };

  return (
    <div>
      {listOfChanges.length > 0 && (
        <div>
          <p>{t("changelog-changes")}:</p>
          {listOfChanges.map((change, index) => (
            <div key={index} className="change" style={changeLogStyle}>
              {change}
            </div>
          ))}
        </div>
      )}

      {parameters.length > 0 && <div>
        <p>{t("changelog-parameters")}:</p>
        {parameters.map((param: SecurityEventLogParameter, index) => (
          <div key={index} style={parameterStyle}>
            <strong>{param.Key}:</strong> {param.Value?.toString()}
          </div>
        ))}
      </div>
      }

      {listOfChanges.length === 0 && parameters.length === 0 && <div className="change" style={changeLogStyle}>{JSON.stringify(details, null, 4)}</div>}
    </div>
  );
};

export default ChangeLog;
