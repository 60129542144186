import { GetOdataDataSource } from 'utils/dataSourceHelper'

const hasPrivilege = (endpoint, hasAnyPrivilege) => {
    let hasPrivilege = false
    //TO DO when privileges for repositories will be known and defined in accessprevileges
    // switch (endpoint) {
    //   case OdataEndpoints.SalesProcessesProcessing:
    //     hasPrivilege = hasAnyPrivilege(AccessPrivileges.SalesProcessingGrid)
    //     break;
    //   case OdataEndpoints.SalesProcessesFailed:
    //     hasPrivilege = hasAnyPrivilege(AccessPrivileges.SalesFailedGrid)
    //     break;
    //   case OdataEndpoints.SalesProcessesRepository:
    //     hasPrivilege = hasAnyPrivilege(AccessPrivileges.SalesRepositoryGrid)
    //     break;
    //   default:
    //     break;
    // }
  
    return true//hasPrivilege
}

export const getDisabled = async (Id, endpoint, hasAnyPrivilege, filterParameter) => {
    let disabled = true

    if (Id === undefined || Id === null)
      return disabled
    
    if(!hasPrivilege(endpoint, hasAnyPrivilege))
      return disabled

    const dataSourceOptions = GetOdataDataSource(endpoint, null,
      ['Id', filterParameter], [filterParameter, '=', Id._value], null);

    await dataSourceOptions.load()
        .then((x) => {
          disabled = x?.length === 0
        }).catch(e => {
          disabled = true
        });
    return disabled
}