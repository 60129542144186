import React, { useState, useEffect, useRef } from 'react';
import GenericToolbar from '../Toolbars/GenericToolbar';
import { getNode } from 'components/breadCrumbs/getNode';
import BreadCrumbs from 'components/breadCrumbs/BreadCrumbs';
import { columns } from './const';
import useDataGridFilter from 'utils/useDataGridFilter'
import { OdataEndpoints } from 'api/XfXApi';
import GenericOdataGrid from 'pages/Invoices/GenericOdataGrid';
import { useTranslation } from 'react-i18next';
import { useUserSettingsContext } from "contexts/UserSettingsProvider";
import { EventLogsPopup, EventLogsTypes } from '../../Invoices/EventLogsPopup';
import ExportPopup from 'components/export-popup/ExportPopup';
import { exportAction } from './exportAction';

const SelfInvoicingGranted = () => {
  const { t } = useTranslation()
  const [data, setData] = useState([])
  const [selectedItem, setSelectedItem] = useState(null)
  const initialFilter = []
  const { dataGridFilter, updateDataGridFilter } = useDataGridFilter({ initialFilter })
  const defaultSort = { selector: "CreationDateUtc", desc: true };
  const [reloadKey, setReloadKey] = useState(0)
  const [dataSourceOptions, setDataSourceOptions] = useState()
  const dataGridRef = useRef(null);
  const { getOrganizationContextSettings } = useUserSettingsContext()
  const [eventLogsVisible, setEventLogsVisible] = useState(false)
  const [isExportPopupVisible, setIsExportPopupVisible] = useState(false)
  const [blockAutoRefresh, setBlockAutoRefresh] = useState(false)
  
  const organizationId = getOrganizationContextSettings()?.NIP

  useEffect(() => {
    setData(columns({ t }));
  }, [setData, t])

  const exportPopup = ExportPopup({ 
    t, exportAction: (type, t) => { exportAction(organizationId, type, t("#_job-create-report-finalized")) }, 
    isVisible: isExportPopupVisible, setIsVisible: setIsExportPopupVisible, setBlockAutoRefresh: setBlockAutoRefresh
  })

  const toolbarButtons = [
    { icon: 'textdocument', text: t("#_toolbarbuttonsnavigaterepositiories_9"), disabled: selectedItem === null, onClick: () => setEventLogsVisible(true) },
    { icon: 'export', text: t("button-export-rows"), onClick: () => exportPopup.show() }
  ]

  return (
    <>
      {exportPopup.popup}
      {eventLogsVisible && 
        <EventLogsPopup 
          type={EventLogsTypes.Sessions} 
          guid={selectedItem?.Id} 
          setEventLogsVisible={setEventLogsVisible} 
          eventLogsVisible={eventLogsVisible}
          setBlockAutoRefresh={setBlockAutoRefresh}>
        </EventLogsPopup>
      }
      <BreadCrumbs node={getNode({ componentName: 'SelfInvoicingGranted', t })} />
      <GenericToolbar className="xfx-toolbar-bigbuttons" buttons={toolbarButtons} />
      <div id="dataGrid-selfInvoicingGranted" className="dataGrid">
        {GenericOdataGrid({
          ref: dataGridRef,
          data: data,
          endpoint: OdataEndpoints.SelfInvoicingGrantedOdata(organizationId),
          setSelectedItem: setSelectedItem,
          defaultFilter: dataGridFilter,
          defaultSort: defaultSort,
          updateDataGridFilter: updateDataGridFilter,
          gridKey: reloadKey,
          dataSourceOptions: dataSourceOptions,
          setDataSourceOptions,
          blockAutoRefresh: blockAutoRefresh,
          setBlockAutoRefresh: setBlockAutoRefresh
        })}
      </div>
    </>
  );
}

export default SelfInvoicingGranted;
