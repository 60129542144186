import 'devextreme-react/autocomplete';
import DataGrid, {
    Paging,
    FilterRow,
    Scrolling,
    Selection,
} from 'devextreme-react/data-grid';
import DropDownBox from 'devextreme-react/drop-down-box';

interface OrganizationsDropDownProps {
    value: any;
    setValue: (value: any) => void;
    allOrganizations: any[];
    disabled: boolean;
    t: (key: string) => string;
    useFluentValidation?: boolean;
    validationErrors?: any;
    isValid?: boolean;
    placeholder?: string;
    label?: string;
    mode?: 'multiple' | 'single';
    dropDownValueExpr?: string;
    clearError?: (field: string) => void;
    clearErrorField?: string;
    displayExpr?: string;
}

const organizationsColumns = ({ t }: { t: (key: string) => string }) => [
    { dataField: 'fullName', caption: t("#_organizationsdropdown_1") },
    { dataField: 'nip', caption: t("#_organizationsdropdown_2") },
];

export interface IOrganization { nip: string, fullName: string }

const OrganizationsDropDown: React.FC<OrganizationsDropDownProps> = ({
    value,
    setValue,
    allOrganizations,
    disabled,
    t,
    useFluentValidation,
    validationErrors,
    isValid,
    placeholder,
    label,
    mode,
    dropDownValueExpr,
    clearError,
    clearErrorField,
    displayExpr
}) => {
    const organizationsRender = (value: any, setValue: (value: any) => void) => {
        return (
            <DataGrid
                className='dataGrid'
                dataSource={allOrganizations}
                columns={organizationsColumns({ t })}
                hoverStateEnabled={true}
                selectedRowKeys={value}
                onSelectionChanged={e => {
                    setValue((e.selectedRowKeys.length && e.selectedRowKeys) || []);
                    if (clearError && clearErrorField) clearError(clearErrorField);
                }}
                height='100%'
            >
                <Selection
                    allowSelectAll={false}
                    deferred={false}
                    mode={mode ? mode : 'multiple'}
                    selectAllMode='allPages'
                    showCheckBoxesMode='always'
                />
                <Scrolling mode='virtual' preloadEnabled={true} />
                <Paging enabled={true} pageSize={100} />
                <FilterRow visible={true} />
            </DataGrid>
        );
    };

    return (
        <DropDownBox
            disabled={disabled}
            value={value}
            valueExpr={dropDownValueExpr ? dropDownValueExpr : 'id'}
            deferRendering={false}
            displayExpr={displayExpr? displayExpr: 'fullName'}
            placeholder={placeholder ? placeholder : t('#_organizationsdropdown_5')}
            showClearButton={false}
            dataSource={allOrganizations}
            onOptionChanged={e => {
                if (e.name === 'value') {
                    setValue(e.value || []);
                }
            }}
            contentRender={() => organizationsRender(value, setValue)}
            label={label ? label : t('#_organizationsdropdown_6')}
            {...(useFluentValidation && { validationErrors: validationErrors })}
            {...(useFluentValidation && { isValid: isValid })}
        />
    );
};


export default OrganizationsDropDown;
