import { XfXApi } from "../../../api/XfXApi"
import notify from "devextreme/ui/notify"
import Contractors from "../Contractors/Contractors"

export const fetchContractors = async ({ setContractorsData }) => {
  try {
    const { data } = await XfXApi.Contractor.apiTenantIdContractorContractorsGet(XfXApi.GetTenantId())
    setContractorsData(data)
  } catch (error) {
    console.error(error)
  }
}

export const copyToken = ({ dataGridOrganizations, t }) => {
  const selected = dataGridOrganizations?.current?.instance?.getSelectedRowsData()
  
  if (selected.length === 0) 
    return

  var toCopy = `api/${XfXApi.GetTenantId()}/${selected[0].NIP}/`

  navigator.clipboard
    .writeText(toCopy)
    .then(() => {
      notify({
        message: t("#_organization_actions_10"),
        position: {
          my: 'center bottom',
          at: 'center bottom',
        },
      }, 'success', 500);
    })
    .catch(() => {
      console.log(t("#_organization_actions_11"))
    });
}

export const addOrganization = ({ dataGridOrganizations, setOrganizationId, setOrganizationNip, setVisible, setPopupTitle, t }) => {
  dataGridOrganizations.current.instance.collapseAll(-1)

  setOrganizationId(null)
  setOrganizationNip(null)
  setVisible(true)

  setPopupTitle(t("#_const_29"))
}

export const editOrganization = async ({
  dataGridOrganizations, setOrganizationId, setOrganizationNip, setVisible, setPopupTitle, 
  t }) => {
  dataGridOrganizations.current.instance.collapseAll(-1)

  const selected = dataGridOrganizations.current.instance.getSelectedRowsData()

  if (selected.length === 0) 
    return

  const currentOrganization = { ...selected[0] }

  setOrganizationId(currentOrganization?.Id?._value ?? currentOrganization?.Id)
  setOrganizationNip(currentOrganization?.NIP)
  setVisible(true)

  setPopupTitle(t("#_const_28"))
}

export const renderDetail = ({ selectedItem, contractorsData, setContractorsData, setBlockAutoRefresh }) => {
  return <Contractors 
    selectedItem={selectedItem} 
    contractorsData={contractorsData} 
    fetchContractorsData={fetchContractors} 
    setContractorsData={setContractorsData} 
    setBlockAutoRefresh={setBlockAutoRefresh}
  />
}