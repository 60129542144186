import { useState } from "react"
import GenericPopup from "components/popup/GenericPopup"
import { Form, GroupItem, SimpleItem, Label } from 'devextreme-react/form';
import { XfXApi } from "api/XfXApi";
import notify from 'devextreme/ui/notify';
import { useTranslation } from 'react-i18next';
import { commonPopupToolbarItems } from 'components/popup/PopupTools';

export const TagsEditor = (reloadGrid, setBlockAutoRefresh) => {
    const { t } = useTranslation()
    const [title, setTitle] = useState()
    const [invoice, setInvoice] = useState({ Tag1: "test" })
    const [organizationId, setOrganizationId] = useState()
    const [isVisible, setIsVisible] = useState(false)

    const tagsForm = <Form
        className={"xfx-form"}
        formData={invoice}>
        <GroupItem colCount={2}
            formData={invoice}>
            <SimpleItem dataField="Tag1" editorType="dxTextBox" value={invoice.Tag1}>
                <Label text="TAG 1" />
            </SimpleItem>
            <SimpleItem dataField="Tag2" editorType="dxTextBox" value={invoice.Tag2}>
                <Label text="TAG 2" />
            </SimpleItem>
            <SimpleItem dataField="Tag3" editorType="dxTextBox" value={invoice.Tag3}>
                <Label text="TAG 3" />
            </SimpleItem>
            <SimpleItem dataField="Tag4" editorType="dxTextBox" value={invoice.Tag4}>
                <Label text="TAG 4" />
            </SimpleItem>
            <SimpleItem dataField="Tag5" editorType="dxTextBox" value={invoice.Tag5}>
                <Label text="TAG 5" />
            </SimpleItem>
            <SimpleItem dataField="Tag6" editorType="dxTextBox" value={invoice.Tag6}>
                <Label text="TAG 6" />
            </SimpleItem>
            <SimpleItem dataField="Tag7" editorType="dxTextBox" value={invoice.Tag7}>
                <Label text="TAG 7" />
            </SimpleItem>
            <SimpleItem dataField="Tag8" editorType="dxTextBox" value={invoice.Tag8}>
                <Label text="TAG 8" />
            </SimpleItem>
            <SimpleItem dataField="Tag9" editorType="dxTextBox" value={invoice.Tag9}>
                <Label text="TAG 9" />
            </SimpleItem>
            <SimpleItem dataField="Tag10" editorType="dxTextBox" value={invoice.Tag10}>
                <Label text="TAG 10" />
            </SimpleItem>
        </GroupItem>

    </Form>

    const popupToolbarItems = commonPopupToolbarItems({ 
     onSave: () => {
        const request = {
            invoiceId: invoice.Id,
            isDispatch: invoice.IsDispatch,
            tag1: invoice.Tag1,
            tag2: invoice.Tag2,
            tag3: invoice.Tag3,
            tag4: invoice.Tag4,
            tag5: invoice.Tag5,
            tag6: invoice.Tag6,
            tag7: invoice.Tag7,
            tag8: invoice.Tag8,
            tag9: invoice.Tag9,
            tag10: invoice.Tag10
        }

        XfXApi.TagsApi.apiTenantIdOrganizationIdTagsPost(XfXApi.GetTenantId(), organizationId, request)
            .then(() => reloadGrid())
            .catch((x)=>{
                console.log(x)
                notify(t("#_tagseditor_3"))
            })
        popup.close()
     }, 
     onCancel: () => {
        popup.close()
     }, t: t 
    })

    const popup = GenericPopup({
        onHiding: () => popup.close(),
        toolbarItems: popupToolbarItems,
        title: title,
        width: "772px",
        height: "757px",
        content: tagsForm,
        isVisible: isVisible,
        setIsVisible: setIsVisible,
        setBlockAutoRefresh: setBlockAutoRefresh
    })

    const show = ({ organizationId, invoice, reloadGrid }) => {
        setOrganizationId(organizationId)
        setInvoice(invoice)
        setTitle(t("#_tagseditor_4") + invoice.Number)
        popup.show()
    }

    return { popup: popup.popup, show }
}
