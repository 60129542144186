import { useState, useEffect, useRef } from 'react';
import DataGrid, {
    Scrolling, Paging, Column, HeaderFilter, FilterRow, Selection, Search
} from 'devextreme-react/data-grid';
import GenericToolbar from '../Toolbars/GenericToolbar'
import ExitPathEditor from './ExitPathEditor';
import { XfXApi } from '../../../api/XfXApi';
import useToast from '../../../utils/useToast'
import BreadCrumbs from 'components/breadCrumbs/BreadCrumbs';
import { getNode } from 'components/breadCrumbs/getNode';
import { useTranslation } from 'react-i18next';
import DeletionPopup from 'components/popup/DeletionPopup';
import { useRefreshButton } from 'utils/refresh'
import CustomStore from 'devextreme/data/custom_store';
import { OdataEndpoints } from 'api/XfXApi';
import { GetOdataDataSource } from 'utils/dataSourceHelper'

const NULL_SYMBOL = '*'
const exitPathEventPrefix = '#_ExitPath_'

const starForNull = (v, fieldName) => {
    return v[fieldName] ? v[fieldName] : NULL_SYMBOL
}

const starForNullOrganizationName = (v) => {
    return v['organizationName'] ? v['organizationName'] : (v['nip'] ? null : NULL_SYMBOL)
}

const columns = ({ t }) => [
    <Column key="name" dataField="name" caption={t("#_exitpaths_1")} />,
    <Column key="nip" dataField="nip" caption={t("#_exitpaths_3")} calculateCellValue={v => starForNull(v, "nip")} />,
    <Column key="organizationName" dataField="organizationName" caption={t("#_exitpaths_28")} calculateCellValue={v => starForNullOrganizationName(v)}/>,
    <Column key="fkSystem" dataField="fkSystem" caption={t("#_exitpaths_7")} calculateCellValue={v => starForNull(v, "fkSystem")} />,
]

const ExitPaths = () => {
    const { t } = useTranslation()
    const [allExitPaths, setAllExitPaths] = useState([])
    const [editVisible, setEditVisible] = useState(false)
    const [selectedItem, setSelectedItem] = useState(null)
    const [availableOrganizations, setAvailableOrganizations] = useState()
    const [availableSystems, setAvailableSystems] = useState()
    const [editExitPath, setEditExitPath] = useState()
    const [popupTitle, setPopupTitle] = useState()
    const [canSave, setCanSave] = useState(false)
    const [saveError, setSaveError] = useState("")
    const [authorizationType, setAuthorizationType] = useState(-1)
    const [deletionPopupVisible, setDeletionPopupVisible] = useState(false)
    const [deletionPopupContent, setDeletionPopupContent] = useState("")
    const [exitPathsEvents, setExitPathsEvents] = useState([])

    function makeAsyncDataSource(data) {
        return new CustomStore({
          loadMode: 'raw',
          key: 'nip',
          load() {
            return data
          },
        })
    }

    function makeAsyncEventsDataSource(data) {
        return new CustomStore({
          loadMode: 'raw',
          key: 'Id',
          load() {
            return data
          },
        })
    }

    const refresh = () => {
        XfXApi.ExitPath.apiTenantIdExitPathGet(XfXApi.GetTenantId())
            .then(x => {
                setAllExitPaths(x.data)
            })
    }

    useRefreshButton(() => refresh())

    const getAvailableOrganizations = () => {
        XfXApi.Organization.apiTenantIdOrganizationOrganizationsGet(XfXApi.GetTenantId())
            .then((resp) => {
                setAvailableOrganizations(makeAsyncDataSource([{ nip: NULL_SYMBOL, fullName: NULL_SYMBOL }, ...resp.data.map(x => ({ nip: x.nip, fullName: x.fullName }))]))
            })
    }

    const getAvailableSystems = async () => {
        const dataSource = GetOdataDataSource(OdataEndpoints.AccountingSystems(), null, ['Id', 'OrganizationIds', 'Name', 'Organizations'],
            null, null, null, null, null, false)
        
        await dataSource.load()
            .then((x) => {
                setAvailableSystems([NULL_SYMBOL, ...x.map(x => x.Name)])
            }).catch(e => {
                console.log(e)
            })
    }

    const getInvoiceEvents = (t) => {
        XfXApi.DictionaryInvoice.apiTenantIdDictionaryInvoiceNotificationEventsGet(XfXApi.GetTenantId())
            .then((resp) => {
                setExitPathsEvents(makeAsyncEventsDataSource(
                    [...resp.data.map(x => ({ Id: x.key, Event: x.value, Description: t(`${exitPathEventPrefix}${x.value}`) }))]
                ))
            })
    }

    useEffect(() => {
        refresh()
        getAvailableOrganizations()
        getAvailableSystems()
        getInvoiceEvents(t)
    }, [t])  

    useEffect(() => {
        if (allExitPaths === undefined) 
            return
        
        const canSave = editExitPath != null 
            && Object.keys(editExitPath.endpoints).length !== 0
            && Object.keys(editExitPath.events).length !== 0
        
        setCanSave(canSave)
        setSaveError(canSave ? "" : t("#_exitpaths_message"))
    }, [editExitPath, allExitPaths])

    const dataGridRef = useRef()

    const { toast, showToastError } = useToast()

    const deletionPopup = DeletionPopup({
        onConfirm: () => {
            XfXApi.ExitPath.apiTenantIdExitPathIdDelete(selectedItem.id, XfXApi.GetTenantId())
                .then(refresh)
        },
        content: deletionPopupContent,
        title: t("#_DeletionPopup_delete"),
        isVisible: deletionPopupVisible,
        setIsVisible: setDeletionPopupVisible,
        t: t
    })

    const deleteSelected = () => {
        if (selectedItem === null) return
        setDeletionPopupContent(t("#_DeletionPopup"));
        deletionPopup.show();
    }

    const edit = () => {
        const selected = dataGridRef.current.instance.getSelectedRowsData()
        if (selected.length === 0) return

        setEditExitPath({ ...selected[0], 
            nip: !selected[0]?.nip && !selected[0]?.organizationName ? null : selected[0]?.nip,
            fkSystem: selected[0]?.fkSystem ? selected[0].fkSystem : null
        })

        setPopupTitle(t("#_exitpaths_13"))
        setEditVisible(true)
    }

    const addNew = () => {

        setEditExitPath({
            id: null,
            name: t("#_exitpaths_14"),
            nip: null,
            fkSystem: null,
            endpoints: {},
            headers: [],
            detailLogsEnabled: false,
            logAuthData: false,
            events:[]
        })
        setPopupTitle(t("#_exitpaths_15"))
        setEditVisible(true)
    }

    const toolbarButtons = [
        { icon: 'plus', text: t("#_exitpaths_17"), onClick: addNew },
        { icon: 'edit', text: t("#_exitpaths_19"), onClick: edit, disabled: selectedItem === null },
        { icon: 'trash', text: t("#_exitpaths_21"), onClick: () => deleteSelected(), disabled: selectedItem === null }
    ]

    return (
        <>
            {toast}
            {editVisible && <ExitPathEditor
                exitPath={editExitPath}
                setExitPath={setEditExitPath}
                availableOrganizations={availableOrganizations}
                availableSystems={availableSystems}
                saveError={saveError}
                authorizationType={authorizationType}
                setAuthorizationType={setAuthorizationType}
                popupTitle={popupTitle}
                setEditVisible={setEditVisible}
                canSave={canSave}
                refresh={refresh}
                showToastError={showToastError}
                nullSymbol={NULL_SYMBOL}
                exitPathsEvents={exitPathsEvents}
            ></ExitPathEditor>}
            {deletionPopup.popup}
            <BreadCrumbs node={getNode({ componentName: 'ExitPaths', t })}></BreadCrumbs>
            <GenericToolbar className="xfx-toolbar-bigbuttons" header={t("#_exitpaths_23")} buttons={toolbarButtons}></GenericToolbar>
            <div id="dataGrid" className="dataGrid">
                <DataGrid
                    ref={dataGridRef}
                    dataSource={allExitPaths}
                    keyExpr="id"
                    showBorders={true}
                    rowAlternationEnabled={true}
                    showColumnLines={true}
                    onSelectionChanged={s => {
                        setSelectedItem(s.selectedRowsData.length > 0 ? s.selectedRowsData[0] : null)
                    }}
                >
                    <Selection mode="single" />
                    <Scrolling mode="virtual" preloadEnabled={true} />
                    <Paging defaultPageSize={100} />
                    <HeaderFilter>
                        <Search
                            enabled={true}
                        >
                        </Search>
                    </HeaderFilter>
                    <FilterRow visible={true} applyFilter={true} />
                    <HeaderFilter visible={true} />
                    {columns({ t })}
                </DataGrid>
            </div>
        </>
    );
}


export default ExitPaths 
