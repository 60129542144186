import { ToolbarItem } from 'devextreme-react/popup';

export const commonPopupToolbarItems = ({ onSave, onCancel, saveLabel, t, cancelLabel, 
    includeDraft, onSaveDraft, saveButtonOptions, cancelButtonOptions, saveDraftLabel, 
    saveDraftButtonOptions, loading }) => {

    const buildSaveButtonOptions = () =>
    {
        return {
            text: saveLabel ?? t("#_savecancelpopup_1"),
            onClick: async () => {
                await onSave()
            }
        }
    }

    const buildCancelButtonOptions = () =>
    {
        return {
            text: cancelLabel ?? t("#_savecancelpopup_2"),
            onClick: async () => {
                await onCancel()
            }
        }
    }

    const buildSaveDraftOptions = () =>
    {
        return {
            text: saveDraftLabel ?? t("#_savecancelpopup_3"),
            onClick: async () => {
                if (onSaveDraft)
                    await onSaveDraft()
            }
        }
    }

    const toolbarArray = [
        <ToolbarItem
            key="popup-toolbarItem-save"
            cssClass="popup-toolbarItem-save"
            widget="dxButton"
            toolbar="bottom"
            location="before"
            options= {saveButtonOptions ? saveButtonOptions : buildSaveButtonOptions()}
        />,
        <ToolbarItem
            key="popup-toolbarItem-cancel"
            cssClass="popup-toolbarItem-cancel"
            widget="dxButton"
            toolbar="bottom"
            location="before"
            options= {cancelButtonOptions ? cancelButtonOptions : buildCancelButtonOptions()}
        />
    ]

    const draftItem =
                    <ToolbarItem
                        key="popup-toolbarItem-saveDraft"
                        cssClass="popup-toolbarItem-saveDraft"
                        widget="dxButton"
                        toolbar="bottom"
                        location="before"
                        options={saveDraftButtonOptions ? saveDraftButtonOptions : buildSaveDraftOptions()}
                    />  
    
    const loadIndicatorItem =
                    <ToolbarItem
                        key="popup-toolbarItem-load"
                        cssClass="popup-toolbarItem-load"
                        widget="dxLoadIndicator"
                        toolbar="bottom"
                        location="before"
                    />

    if (includeDraft)
        toolbarArray.splice(1, 0, draftItem)

    if (loading)
        toolbarArray.splice(0, 0, loadIndicatorItem)

    return toolbarArray
}
