import i18n from 'localization/i18n';
const t = i18n.t

const gridColums = [
    { id: 0, name: t("#_columnsforrestrctions_1") }, //Subject1VatNumber:
    { id: 1, name: t("#_columnsforrestrctions_2") }, //Subject1Name
    { id: 2, name: t("#_columnsforrestrctions_3") }, //Subject2VatNumber
    { id: 3, name: t("#_columnsforrestrctions_4") }, //Subject2Name
    { id: 4, name: t("#_columnsforrestrctions_5") }, //Subject3VatNumber
    { id: 5, name: t("#_columnsforrestrctions_6") }, //Subject3Name
    { id: 6, name: t("#_columnsforrestrctions_7") }, //TotalAmount
  ]
  
  export const SaleColumnsForRestrictions = () => {
    const deepCopy = JSON.parse(JSON.stringify(gridColums))
    return deepCopy.filter(x => x.id !== 0 && x.id !== 1)
  }
  
  export const PurchaseColumnsForRestrictions = () => {
    const deepCopy = JSON.parse(JSON.stringify(gridColums))
    return deepCopy
  }
  
  export const OtherColumnsForRestrictions = () => {
    const deepCopy = JSON.parse(JSON.stringify(gridColums))
    return deepCopy.filter(x => x.id !== 4 && x.id !== 5)
  }
