import axios from "axios";
import { AxiosResponse } from "axios";
import { InvoicesEndpoints } from 'api/XfXApi';
import notify from 'devextreme/ui/notify';

interface GetXmlParams {
  organizationId: string;
  invoiceId: string;
  sales?: boolean;
  pretty?: boolean;
  t: (key: string) => string;
  ksefNumber?: string;
  subject2VatNumber?: string;
}

interface GetDraftXmlParams {
  organizationId: string;
  draftId: string;
  t: (key: string) => string;
}

export const getAxiosRequestForBinaryFile = (url: string) => (): Promise<AxiosResponse<Blob>> => axios.request<Blob>({
  url: url,
  method: "GET",
  responseType: 'blob',
});

const saveFile = (response: AxiosResponse<Blob>, fileName: string): void => {
  if (response.status !== 200) return;
  const downloadUrl = window.URL.createObjectURL(new Blob([response.data]));
  const link = document.createElement('a');
  link.href = downloadUrl;
  link.setAttribute('download', fileName);

  document.body.appendChild(link);
  link.click();
  link.remove();
}

export const showError = (msg: string) => {
  notify({
    message: msg,
    position: {
      my: 'center bottom',
      at: 'center bottom',
    },
  }, 'error', 3000);
}

export async function GetXml({ organizationId, invoiceId, sales = true, pretty = true, t, ksefNumber, subject2VatNumber }: GetXmlParams): Promise<void> {
  const url = (sales ? InvoicesEndpoints.DispatchXml(organizationId) : InvoicesEndpoints.ReceptionXml(organizationId)) + "?invoiceId=" + invoiceId + `&pretty=${pretty}`;
  const fileName = (ksefNumber ? ksefNumber : (subject2VatNumber ? `${subject2VatNumber}_${invoiceId}` : invoiceId)) + "_fv.xml";

  getAxiosRequestForBinaryFile(url)()
    .then(response => saveFile(response, fileName))
    .catch(() => showError(t("#_invoicegridactions_2")));
}

export async function GetDraftXml({ organizationId, draftId, t }: GetDraftXmlParams): Promise<void> {
  const url = InvoicesEndpoints.DraftsXml(organizationId) + "/" + draftId;
  const fileNamePrefix = draftId + "_fv_robocza.xml";

  getAxiosRequestForBinaryFile(url)()
    .then(response => saveFile(response, fileNamePrefix))
    .catch(() => showError(t("#_invoicegridactions_2")));
}

interface OpenInvoiceXmlParams {
  organizationId: string;
  invoiceId: string;
  sales?: boolean;
  pretty?: boolean;
  t: (key: string) => string;
}

interface OpenDraftXmlParams {
  organizationId: string;
  draftId: string;
  t: (key: string) => string;
}

interface OpenUpoXmlParams {
  organizationId: string;
  invoiceId: string;
  pretty?: boolean;
  t: (key: string) => string;
}

export async function OpenInvoiceXml({ organizationId, invoiceId, sales = true, pretty = true, t }: OpenInvoiceXmlParams): Promise<void> {
  const url = (sales ? InvoicesEndpoints.DispatchXml(organizationId) : InvoicesEndpoints.ReceptionXml(organizationId))
    + "?invoiceId=" + invoiceId + `&pretty=${pretty}`;

  await GetXmlAndShowWindow(getAxiosRequestForBinaryFile(url), t);
}

export async function OpenDraftXml({ organizationId, draftId, t }: OpenDraftXmlParams): Promise<void> {
  const url = InvoicesEndpoints.DraftsXml(organizationId) + "/" + draftId;

  await GetXmlAndShowWindow(getAxiosRequestForBinaryFile(url), t);
}

export async function OpenUpoXml({ organizationId, invoiceId, pretty = true, t }: OpenUpoXmlParams): Promise<void> {
  const url = InvoicesEndpoints.Upo(organizationId) + "?invoiceId=" + invoiceId + `&pretty=${pretty}`;
  await GetXmlAndShowWindow(getAxiosRequestForBinaryFile(url), t);
}


const showFileInNewWindow = (response: AxiosResponse<Blob, any>): void => {
  if (response.status === 200) {
    const file = new Blob([response.data], { type: "application/xml" });
    const fileURL = URL.createObjectURL(file);
    const xmlWindow = window.open();
    if (xmlWindow) {
      xmlWindow.location.href = fileURL;
    }
  }
}

const GetXmlAndShowWindow = (fetch: () => Promise<AxiosResponse<Blob, any>>, t: (key: string) => string): void => {
  fetch()
    .then(showFileInNewWindow)
    .catch(() => showError(t("#_invoicegridactions_3")));
}