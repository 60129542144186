import { copySessionId } from "../../Invoices/InvoiceGridActions";

export const source = {
    Sessions: 0,
    ReceptionCheckups: 1
}

export const toolbarButtonsNavigateRepositories = ({ selectedItem, salesArchiveDisabled, purchaseArchiveDisabled, otherArchiveDisabled, setEventLogsVisible, navigate, parameterName, gridSource, t }) => {
    return [
      {
          icon: 'folderPlusIcon', text: t("#_toolbarbuttonsnavigaterepositiories_2"), disabled: selectedItem === null || salesArchiveDisabled, onClick: () => {
              navigate({
                  pathname: '/repository-sales',
                  search: `?${parameterName}=` + selectedItem.Id,
              });
          }
  
      },
      {
          icon: 'folderPlusIcon', text: t("#_toolbarbuttonsnavigaterepositiories_4"), disabled: selectedItem === null || purchaseArchiveDisabled, onClick: () => {
              navigate({
                  pathname: '/repository-purchase',
                  search: `?${parameterName}=` + selectedItem.Id,
              });
          }
      },
      {
          icon: 'folderPlusIcon', text: t("#_toolbarbuttonsnavigaterepositiories_6"), disabled: selectedItem === null || otherArchiveDisabled, onClick: () => {
              navigate({
                  pathname: '/repository-other',
                  search: `?${parameterName}=` + selectedItem.Id,
              });
          }
      },
      { icon: 'copy', text: t("#_toolbarbuttonsnavigaterepositiories_8"), disabled: selectedItem || gridSource === source.ReceptionCheckups 
                                                                                                    ? selectedItem?.SessionId?._value 
                                                                                                    : selectedItem?.Id?._value, 
        onClick: async () => copySessionId(gridSource === source.ReceptionCheckups ? selectedItem?.SessionId?._value : selectedItem?.Id?._value) },
      { icon: 'textdocument', text: t("#_toolbarbuttonsnavigaterepositiories_9"), disabled: selectedItem === null || selectedItem === undefined, onClick: () => setEventLogsVisible(true) }
    ]
  }
