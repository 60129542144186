import { useState } from 'react'
import SaveCancelPopup from 'components/popup/SaveCancelPopup';
import { XfXApi } from "api/XfXApi"
import { useTranslation } from 'react-i18next'

const deleteJob = (x, organizationId, reload) => {
    XfXApi.JobsApi.apiTenantIdOrganizationIdJobsDelete(organizationId, XfXApi.GetTenantId(), x.Id.toString())
        .then(r => {
            reload()
        })
}

export const DownloadDeletePopup = (setBlockAutoRefresh) => {
    const { t } = useTranslation()
    const [state, setState] = useState()
    const [isVisible, setIsVisible] = useState(false)

    const { popup, show } = SaveCancelPopup({
        title: t("#_DeletionPopup_delete"),
        saveLabel: t("#_DeletionPopup_1"),
        onSave: async () => {
            deleteJob(state.selectedItem, state.organizationId, state.reload)
        },
        onCancel: () => { },
        width: 399,
        height: 355,
        content:
            <>
                <div className='popup_content'>{t("#_DeletionPopup")}</div>
            </>,
        t,
        cancelLabel: t("#_DeletionPopup_2"),
        useSaveIcon: false,
        isVisible: isVisible,
        setIsVisible: setIsVisible,
        setBlockAutoRefresh: setBlockAutoRefresh
    })

    return {
        popup, show: ({ selectedItem, organizationId, reload }) => {
            setState({
                selectedItem,
                organizationId,
                reload
            })
            show()
        }
    }
}
