import { useState } from 'react'
import { SingleCard } from './layouts';
import { LoginForm } from './components';
import AADForm from './components/login-form/aad-form';
import { useAuth } from './contexts/auth';
import { useTranslation } from 'react-i18next';
import TenantSelector from 'TenantSelector'
import NoPermissions from 'NoPermissions'



const UnauthenticatedContent = function ({ selectTenantStep, availableTenats, selectCallback }) {
  const { t } = useTranslation()
  const { maintenanceMode, userCantLogin, notifyLoginFailed } = useAuth();

  if(userCantLogin)
    return <NoPermissions/>

  if (selectTenantStep) {    
    if(availableTenats.length === 0)
    {
      notifyLoginFailed();
      return <NoPermissions/>
    }

      return (
        <TenantSelector availableTenats={availableTenats} selectCallback={selectCallback}></TenantSelector>
      )
  }
  else {
    const loginComponent = maintenanceMode ? <LoginForm /> : <AADForm></AADForm>
    return (
      <SingleCard title={t("#_unauthenticatedcontent_1")}>
        {loginComponent}
      </SingleCard>
    );
  }
}

export default UnauthenticatedContent
