import { Section } from "./model";

export const showCssClass = (className: string) => {
    const toShow = document.querySelectorAll("." + className);
    toShow.forEach((el) => {
        (el as HTMLElement).style.display = ""
    })
}

export const hideCssClass = (className: string) => {
    const toHide = document.querySelectorAll("." + className);
    toHide.forEach((el) => {
        (el as HTMLElement).style.display = "none"
    })
}

export const showTab = (s: Section, sectionIndex: number, tabIndex: number, sectionExpanded: boolean) => {

    if (sectionExpanded) {
        showCssClass("invoice-editor-section-" + sectionIndex + "-" + tabIndex)
    }

    for (let n = 0; n < s.Groups.length; n++) {
        if (n === tabIndex && sectionExpanded) {
            continue
        }

        hideCssClass("invoice-editor-section-" + sectionIndex + "-" + n)
    }
}