import moment from 'moment';

const utcToLocal = date => {
    if (!date) return "-"
    const m = moment(date).utcOffset(0, true) // treats date as utc
    return m.local().format("DD.MM.yyyy HH:mm")
}

export const utcToLocalDateOnly = date => {
    if (!date) return null
    const m = moment(date).utcOffset(0, true) // treats date as utc
    return m.local().format("DD.MM.yyyy")
}

export const utcToLocalWithSeconds = date => {
    if (!date) return "-"
    const m = moment(date).utcOffset(0, true) // treats date as utc
    return m.local().format("DD.MM.yyyy HH:mm:ss")
}

export const utcToLocalDate = date => {
    if (!date) 
        return null
    
    const m = moment(date).utcOffset(0, true) // treats date as utc
    return m.local()
}

export default utcToLocal