import GenericOdataGrid from "../../Invoices/GenericOdataGrid";
import { useState, useEffect, useRef } from "react"
import GenericToolbar from '../Toolbars/GenericToolbar';
import { EventLogsPopup, EventLogsTypes } from '../../Invoices/EventLogsPopup';
import { OdataEndpoints, InvoicesEndpoints } from '../../../api/XfXApi';
import { useNavigate } from 'react-router';
import BreadCrumbs from 'components/breadCrumbs/BreadCrumbs';
import { getNode } from 'components/breadCrumbs/getNode';
import useDataGridFilter from 'utils/useDataGridFilter'
import { useAuth } from 'contexts/auth'
import { toolbarButtonsNavigateRepositories, source } from '../Tools/toolbarButtonsNavigateRepositiories';
import { columns, filterParameter, queryParameter } from "./const";
import { getDisabled } from '../Tools/Tools';
import { useTranslation } from 'react-i18next';
import { useUserSettingsContext } from "../../../contexts/UserSettingsProvider";

const ReceptionCheckupsGrid = () => {
  const { t } = useTranslation()
  const navigate = useNavigate();
  const { hasAnyPrivilege } = useAuth()
  const dataGridRef = useRef(null)
  const initialFilter = []
  const { dataGridFilter, updateDataGridFilter } = useDataGridFilter({ initialFilter })
  const [selectedItem, setSelectedItem] = useState(null)
  const [eventLogsVisible, setEventLogsVisible] = useState(false)
  const [data, setData] = useState([])
  
  useEffect(() => {
    setData(columns({ t }))
  }, [t, setData])

  const { getOrganizationContextSettings } = useUserSettingsContext()
  const organizationId = getOrganizationContextSettings()?.NIP

  const [salesArchiveDisabled, setSalesArchiveDisabled] = useState(true)
  const [purchaseArchiveDisabled, setPurchaseArchiveDisabled] = useState(true)
  const [otherArchiveDisabled, setOtherArchiveDisabled] = useState(true)
  const [dataSourceOptions, setDataSourceOptions] = useState()

  const [blockAutoRefresh, setBlockAutoRefresh] = useState(false)

  useEffect(() => {
    const rs = async () => { return await getDisabled(selectedItem?.Id, InvoicesEndpoints.RepositorySales(organizationId), hasAnyPrivilege, queryParameter) }
    const rp = async () => { return await getDisabled(selectedItem?.Id, InvoicesEndpoints.RepositoryPurchase(organizationId), hasAnyPrivilege, queryParameter) }
    const ro = async () => { return await getDisabled(selectedItem?.Id, InvoicesEndpoints.RepositoryOther(organizationId), hasAnyPrivilege, queryParameter) }

    rs().then((x) => { setSalesArchiveDisabled(x) })
    rp().then((x) => { setPurchaseArchiveDisabled(x) })
    ro().then((x) => { setOtherArchiveDisabled(x) })

  }, [hasAnyPrivilege, selectedItem, organizationId])

  return (
    <>
      {eventLogsVisible && 
        <EventLogsPopup 
          type={EventLogsTypes.ReceptionCheckups} 
          guid={selectedItem?.Id} 
          setEventLogsVisible={setEventLogsVisible} 
          eventLogsVisible={eventLogsVisible}
          setBlockAutoRefresh={setBlockAutoRefresh}>
        </EventLogsPopup>
      }
      <BreadCrumbs node={getNode({ componentName: 'ReceptionCheckupsGrid', t })}></BreadCrumbs>
      <GenericToolbar className="xfx-toolbar-bigbuttons"
        buttons={toolbarButtonsNavigateRepositories({
          selectedItem: selectedItem, salesArchiveDisabled: salesArchiveDisabled, purchaseArchiveDisabled: purchaseArchiveDisabled,
          otherArchiveDisabled: otherArchiveDisabled, setEventLogsVisible, navigate, parameterName: filterParameter, gridSource: source.ReceptionCheckups, t
        })}></GenericToolbar>
      <div id="dataGrid-receptionCheckups" className="dataGrid">
        {GenericOdataGrid({
          data, endpoint: OdataEndpoints.ReceptionCheckups(organizationId), setSelectedItem: setSelectedItem,
          defaultFilter: dataGridFilter, updateDataGridFilter: updateDataGridFilter,
          ref: dataGridRef, id: "ReceptionCheckups", dataSourceOptions: dataSourceOptions, setDataSourceOptions,
          blockAutoRefresh: blockAutoRefresh, setBlockAutoRefresh: setBlockAutoRefresh
        })}
      </div>
    </>
  );
}

export default ReceptionCheckupsGrid