import React, { useState, useRef, useCallback } from 'react';
// import { Button } from 'devextreme-react/button';
import { useAuth } from '../../contexts/auth';
import 'layouts/single-card/single-card.scss';
import LoadIndicator from 'devextreme-react/load-indicator';
import { useMsal } from "@azure/msal-react";
import { useIsAuthenticated } from "@azure/msal-react";
import { InteractionStatus } from "@azure/msal-browser";
import { useTranslation } from 'react-i18next';
import './aad-form.scss';


const AADForm = () => {
  const { inProgress } = useMsal();
  const { aadSignIn, user, userCantLogin } = useAuth();
  const isAuthenticated = useIsAuthenticated();
  const { t } = useTranslation()

  return (
    <>
      { (!isAuthenticated && inProgress !== InteractionStatus.None) || (isAuthenticated && !user && !userCantLogin)
        ? <LoadIndicator className={"login-element"} width={'50px'} height={'50px'} visible={true} />
        : <button className={'login-button login-element'} onClick={aadSignIn}>{t("#_aad_login_1")}</button>
      }
    </>
  );
}

export default AADForm;