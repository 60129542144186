import { useEffect, useState, useRef } from 'react'
import { XfXApi } from 'api/XfXApi';
import DataGrid, { Paging, FilterRow, Scrolling, Selection } from 'devextreme-react/data-grid';
import { convertToColumns } from 'pages/Invoices/GenericGrid'
import CustomStore from 'devextreme/data/custom_store';
import DropDownBox from 'devextreme-react/drop-down-box';
import { riseOrganizationChangedEvent } from 'utils/organizationDropDownHandler';
import { useTranslation } from 'react-i18next';
import { useUserSettingsContext } from '../contexts/UserSettingsProvider';
import { useAuth } from '../contexts/auth';
import { renderModificationDateCell, renderCreationDateCell } from 'utils/cellRenderers'

const data = ({t}) => [
    { dataField: "id", visible: false },
    { dataField: "fullName", caption: t("#_organizationfilter_1"), dataType: "string" },
    { dataField: "nip", caption: t("#_organizationfilter_3"), dataType: "string" },
    { dataField: "Type", dataType: "number", visible: false },
    { dataField: "Street", dataType: "string", visible: false },
    { dataField: "StreetNo", dataType: "string", visible: false },
    { dataField: "FlatNo", dataType: "string", visible: false },
    { dataField: "City", dataType: "string", visible: false },
    { dataField: "ZipCode", dataType: "string", visible: false },
    { dataField: "KRS", dataType: "string", visible: false },
    { dataField: "REGON", dataType: "number", visible: false },
    { dataField: "LastSyncDateUtc", dataType: "datetime", visible: false },
    { dataField: "SyncRequested", dataType: "boolean", visible: false },
    { dataField: "CreationDateUtc", dataType: "datetime", cellRender: renderCreationDateCell, visible: false },
    { dataField: "CreatedById", dataType: "string", visible: false },
    { dataField: "ModificationDateUtc", dataType: "datetime", cellRender: renderModificationDateCell,visible: false },
    { dataField: "ModificatedById", dataType: "string", visible: false },
    { dataField: "ReceptionSyncOnAsSubject1", dataType: "boolean", visible: false },
    { dataField: "ReceptionSyncOnAsSubject2", dataType: "boolean", visible: false },
    { dataField: "ReceptionSyncOnAsSubject3", dataType: "boolean", visible: false },
    { dataField: "UseAdditionalEncryption", dataType: "boolean", visible: false },
  ]

const OrganizationFilter = () => {
    const { t } = useTranslation()
    const [organizations, setOrganizations] = useState()
    const [organizationsValue, setOrganizationsValue] = useState([])
    const dropDownBoxRef = useRef(null)
    const dataGridRef = useRef(null)
    const { userId, userSettings } = useAuth()
    const { updateUserSettingsInDb, postUserSettingsIntoDb, getOrganizationContextSettings } = useUserSettingsContext()

    useEffect(() => {
      const initialSelection = getOrganizationContextSettings()
      setOrganizationsValue(initialSelection?.Id ? [initialSelection?.Id] : [])

        XfXApi.Organization.apiTenantIdOrganizationUserOrganizationsGet(XfXApi.GetTenantId())
            .then(x => {
                setOrganizations(makeAsyncDataSource(x.data))
            })
    }, [])

    async function organizationsOnSelectionChanged(e) {
        const selectedRowData = dataGridRef.current.instance.getSelectedRowsData()[0]
        setOrganizationsValue((e.selectedRowKeys.length && e.selectedRowKeys) || [])
        dropDownBoxRef.current.instance.close()
        riseOrganizationChangedEvent({
          OrganizationContext: {
            Id: selectedRowData?.id,
            NIP: selectedRowData?.nip
          } 
        })

        if (userSettings !== null)
        {
          const settings = { ...userSettings.settings, OrganizationContext: { Id: selectedRowData?.id, NIP: selectedRowData?.nip }} 
          await updateUserSettingsInDb(userSettings?.id, { userId: userId, settings: settings })
        }
        else
        {
          const settings = { OrganizationContext: { Id: selectedRowData?.id, NIP: selectedRowData?.nip }}
          await postUserSettingsIntoDb({ userId: userId, settings: settings })
        }
      }

    function makeAsyncDataSource(data) {
        return new CustomStore({
          loadMode: 'raw',
          key: 'id',
          load() {
            return data
          },
        })
      }

    function organizationsRender() {
        return (
            <DataGrid
              className='dataGrid'
              dataSource={organizations}
              hoverStateEnabled={true}
              selectedRowKeys={organizationsValue}
              onSelectionChanged={organizationsOnSelectionChanged}
              ref={dataGridRef}>
              <Selection mode="single" />
              <Scrolling mode="virtual" rowRenderingMode={"virtual"}/>
              <Paging enabled={true} pageSize={5} />
              <FilterRow visible={true} />
              {organizationsColumns}
            </DataGrid>
        )
      }

    function syncOrganizationsSelection(e) {
        setOrganizationsValue(e.value || [])
    }

    const organizationsColumns = convertToColumns(data({t}))

    return <div className='organization-selectBox'>
        <DropDownBox
              value={organizationsValue}
              valueExpr="id"
              deferRendering={false}
              displayExpr="fullName"
              placeholder={t("#_organizationfilter_17")}
              dataSource={organizations}
              onValueChanged={syncOrganizationsSelection}
              contentRender={organizationsRender}
              ref={dropDownBoxRef}
        />
    </div>
}

export default OrganizationFilter
