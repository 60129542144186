export const getNode = ({ componentName, t }) => {
  let node = null
  switch (componentName) {
      case 'HomePage':
        node = { text: t('Strona startowa'), parent: null}
        break;
      case 'LicenseData':
        node = { text: t('menu/settings/general/licenses'), parent: { text: t('menu/settings/general'), parent: { text: t('menu/settings'), parent: null }}}
        break;
      case 'Organizations':
        node = { text: t('menu/settings/general/organizations'), parent: { text: t('menu/settings/general'), parent: { text: t('menu/settings'), parent: null }}}
        break;
      case 'Notifications':
          node = { text: t('menu/settings/general/notifications'), parent: { text: t('menu/settings/general'), parent: { text: t('menu/settings'), parent: null }}}
          break;
      case 'SecurityConfigurator':
        node = { text: t('menu/settings/general/security'), parent: { text: t('menu/settings/general'), parent: { text: t('menu/settings'), parent: null }}}
        break;
      case 'SecurityLog':
        node = { text: t('menu/settings/general/security-log'), parent: { text: t('menu/settings/general'), parent: { text: t('menu/settings'), parent: null }}}
        break;      
      case 'Users':
        node = { text: t('menu/settings/general/users'), parent: { text: t('menu/settings/general'), parent: { text: t('menu/settings'), parent: null }}}
        break;
      case 'Roles':
        node = { text: t('menu/settings/general/roles'), parent: { text: t('menu/settings/general'), parent: { text: t('menu/settings'), parent: null }}}
        break;
      case 'DocumentTemplates':
        node = { text: t('menu/settings/general/documenttemplates'), parent: { text: t('menu/settings/general'), parent: { text: t('menu/settings'), parent: null }}}
          break;
      case 'SelfInvoicingGranted':
        node = { text: t('menu/settings/general/selfinvoicinggranted'), parent: { text: t('menu/settings/general'), parent: { text: t('menu/settings'), parent: null }}}
          break;
      case 'NumberingSeries':
          node = { text: t('menu/settings/general/numberingseries'), parent: { text: t('menu/settings/general'), parent: { text: t('menu/settings'), parent: null } } }
          break;
      case 'ConfigS3':
          node = { text: t('menu/settings/general/config-s3'), parent: { text: t('menu/settings/general'), parent: { text: t('menu/settings'), parent: null }}}
          break;
      case 'MenuEditor':
          node = { text: t('menu/settings/general/menu'), parent: { text: t('menu/settings/general'), parent: { text: t('menu/settings'), parent: null }}}
          break;
      case 'FailureRegistry':
          node = { text: t('menu/settings/general/failureregistry'), parent: { text: t('menu/settings/general'), parent: { text: t('menu/settings'), parent: null }}}
          break;
      case 'FailuresLog':
            node = { text: t('menu/settings/general/failurelog'), parent: { text: t('menu/settings/general'), parent: { text: t('menu/settings'), parent: null }}}
            break;             
      case 'ConfigImport':
          node = { text: t('menu/settings/general/config-import'), parent: { text: t('menu/settings/general'), parent: { text: t('menu/settings'), parent: null }}}
          break;
      case 'BatchSettings':
          node = { text: t('menu/settings/general/batchsettings'), parent: { text: t('menu/settings/general'), parent: { text: t('menu/settings'), parent: null }}}
          break;
      case 'AccountingSystems':
        node = { text: t('menu/settings/data/fksystem'), parent: { text: t('menu/settings/data'), parent: { text: t('menu/settings'), parent: null }}}
        break;
      case 'ModulesGrid':
        node = { text: t('menu/settings/data/catalog'), parent: { text: t('menu/settings/data'), parent: { text: t('menu/settings'), parent: null }}}
        break;
      case 'PipelinesGrid':
        node = { text: t('menu/settings/data/scenario'), parent: { text: t('menu/settings/data'), parent: { text: t('menu/settings'), parent: null }}}
        break;
      case 'ExitPaths':
        node = { text: t('menu/settings/data/exit'), parent: { text: t('menu/settings/data'), parent: { text: t('menu/settings'), parent: null }}}
        break;
      case 'ReportBuilder':
        node = { text: t('menu/settings/data/report-builder'), parent: { text: t('menu/settings/data'), parent: { text: t('menu/settings'), parent: null }}}
        break;

      case 'KsefRoles':
        node = { text: t('menu/settings/general/roles'), parent: { text: t('menu/settings/ksef'), parent: { text: t('menu/settings'), parent: null }}}
        break;
      case 'KsefTokens':
        node = { text: t('menu/settings/ksef/tokens'), parent: { text: t('menu/settings/ksef'), parent: { text: t('menu/settings'), parent: null }}}
        break;
      case 'ReceptionCheckupsGrid':
        node = { text: t('menu/settings/ksef/receptioncheckups'), parent: { text: t('menu/settings/ksef'), parent: { text: t('menu/settings'), parent: null }}}
        break;
      case 'KsefSessions':
        node = { text: t('menu/settings/ksef/sesions'), parent: { text: t('menu/settings/ksef'), parent: { text: t('menu/settings'), parent: null }}}
          break;
      case 'OrganizationSyncSettings':
          node = { text: t('menu/settings/ksef/sync'), parent: { text: t('menu/settings/ksef'), parent: { text: t('menu/settings'), parent: null }}}
          break;
      case 'Download':
        node = { text: t('menu/download'), parent: null}
        break;            
      default:
        node = { text: componentName, parent: null}
        break;
    }

  return node
}