import { useState, useEffect } from 'react';
import { showInfo } from 'utils/useNotification'
import { HubConnectionBuilder } from '@microsoft/signalr';
import { GetAuthToken, GetAuthType, NotificationHubUrl, GetTenantId, XfXApi } from 'api/XfXApi';
import { useTranslation } from 'react-i18next';

const useKsefNotifications = () => {
    const { t } = useTranslation()
    const [connection, setConnection] = useState(null);

    useEffect(() => {
        document.addEventListener("XfxNotification", (e) => {
            showInfo(e.detail.message.message)
            console.log("XfxNotification")
        })
    }, [])

    useEffect(() => {
        const authType = GetAuthType()
        const authToken = GetAuthToken()
        const tokenForSignalR = authType === 'Basic' ? `Basic ${authToken}` : authToken
        const newConnection = new HubConnectionBuilder()
            .withUrl(NotificationHubUrl(XfXApi.GetTenantId()),
                {
                    accessTokenFactory: () => tokenForSignalR
                })
            .withAutomaticReconnect()
            .build();

        setConnection(newConnection);
    }, []);

    useEffect(() => {
        if (connection) {
            connection.start()
                .then(result => {
                    connection.on('ReceiveMessage', message => {
                        const updated = {
                            ...message, message: message.message ?? message
                        }
                        document.dispatchEvent(new CustomEvent("XfxNotification", { 
                            detail: { 
                                message: updated 
                            } }))
                    });
                })
                .catch(e => console.log('Connection failed: ', e));
        }
    }, [connection]);
}

export default useKsefNotifications