import { InvoiceDraftDto } from 'api/openapi-invoice';
import { XfXApi } from 'api/XfXApi';
import { AxiosResponse } from 'axios';

export const downloadXmlForDraftDto = async (download: boolean, organizationId: string, draft: InvoiceDraftDto) => {
  const response = await XfXApi.DraftApi.apiTenantIdOrganizationIdInvoicedraftXmlFromTokensPost(XfXApi.GetTenantId(),
    organizationId, draft)
  return processResponse(download, response)
}

const processResponse = (download: boolean, response: AxiosResponse<void, any>) => {
  if (typeof response.data === 'string') {
    if (download) {
      const downloadUrl = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = downloadUrl;
      link.setAttribute('download', "fv.xml");

      document.body.appendChild(link);
      link.click();
      link.remove();
    }
    return response.data
  }
  return ""
}