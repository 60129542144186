import { XfXApi } from 'api/XfXApi';
import { showInfo } from 'utils/useNotification'

export const exportAction = (organizationId: string, fileType: string, onSuccessMessage: string) => {
    XfXApi.SelfInvoicingGranted.apiTenantIdOrganizationIdSelfInvoicingGrantedCreateReportPost(organizationId, XfXApi.GetTenantId(), fileType,)
        .then(({ status }) => {
            if (status === 200) {
                showInfo(onSuccessMessage);
            }
        })
}