import React, { useState, useEffect } from "react"
import { Popup, ToolbarItem } from 'devextreme-react/popup';
import { Form, SimpleItem, Label, GroupItem } from 'devextreme-react/form';
import { Template } from 'devextreme-react/core/template';
import { XfXApi } from 'api/XfXApi';
import notify from 'devextreme/ui/notify';
import { useTranslation } from 'react-i18next';
import { useUserSettingsContext } from "../../../contexts/UserSettingsProvider";
import GenericPopup from "components/popup/GenericPopup";
import { commonPopupToolbarItems } from 'components/popup/PopupTools';

const ReceptionSyncPopup = ({ isVisible, setIsVisible, setBlockAutoRefresh }) => {
    const { t } = useTranslation()
    const formDataInit = { 
        isCustomRangeSync: false,
        dateFrom: new Date(), 
        dateTo: new Date()
    }
    const { getOrganizationContextSettings } = useUserSettingsContext()

    const organizationId = getOrganizationContextSettings()?.NIP
    const organizationGuid = getOrganizationContextSettings()?.Id

    const [datesVisible, setDatesVisible] = useState(false) 
    const [formData, setFormData] = useState(formDataInit)

    const isCustomRangeSyncOptions = [
        { value: false, text: t("#_receptionsyncpopup_1") }, 
        { value: true, text: t("#_receptionsyncpopup_2") }
    ];

    const dateBoxEditorOptions = { 
        type: 'date', pickerType: 'calendar', 
        readOnly: false, visible: datesVisible,
        showDropDownButton: true, dropDownButtonTemplate: 'renderEventIcon'
      }

    const customRangeEditorOptions = { 
        displayExpr: "text",
        valueExpr: "value", 
        dataSource: isCustomRangeSyncOptions, 
        onValueChanged: x => setDatesVisible(x.value) 
    }

    const renderEventIcon = () => {
        return (
          <>
            <div className='dx-icon dx-icon-event'></div>
          </>
        );
      }

    const doStandardSync = (organizationGuid) => 
    {
        XfXApi.OrganizationSyncSetting.apiTenantIdOrganizationSyncSettingSyncRequestedPut(XfXApi.GetTenantId(), organizationGuid)
        .then((x) => {
            notify({
                message: t("#_receptionsyncpopup_10"),
                position: {
                my: 'center bottom',
                at: 'center bottom',
                },
            }, 'success', 1000);
        })
        .catch((x) =>{
            notify({
                message: `${x?.response?.data?.errors?.join('\r\n') ?? t("#_receptionsyncpopup_3")}`,
                position: {
                my: 'center bottom',
                at: 'center bottom',
                },
            }, 'error', 5000);
        })
    }

    const doCustomSync = (organizationId, dateFrom, dateTo) => 
    {
        const dto = {
            vatNumber: organizationId,
            dateFrom: dateFrom,
            dateTo: dateTo
        }
        XfXApi.Organization.apiTenantIdOrganizationSyncCustomPut(XfXApi.GetTenantId(), dto)
        .then((x) => {
            notify({
                message: t("#_receptionsyncpopup_10"),
                position: {
                my: 'center bottom',
                at: 'center bottom',
                },
            }, 'success', 1000);
        })
        .catch((x) =>{
            notify({
                message: `${x?.response?.data?.errors?.join('\r\n') ?? t("#_receptionsyncpopup_4")}`,
                position: {
                my: 'center bottom',
                at: 'center bottom',
                },
            }, 'error', 5000);
        })
    }

    const popupToolbarItems = commonPopupToolbarItems({ 
        saveLabel: t("#_receptionsyncpopup_8"),
        cancelLabelt: t("#_receptionsyncpopup_9"),
        onSave: async () => {
            if (formData.isCustomRangeSync)
                doCustomSync(organizationId, formData.dateFrom, formData.dateTo)
            else
                doStandardSync(organizationGuid)

            popup.close()
        }, 
        onCancel: () => {
            popup.close()
        }, t: t 
      })

    const popup = GenericPopup({ 
        onHiding: () => {
            popup.close()
        },
        content:
        <div>
          <Form
            id="sync-form"
            showColonAfterLabel={false}
            labelLocation='left'
            labelMode='static'
            formData={formData}>
                <GroupItem colCount={1} colSpan={1}>
                    <SimpleItem dataField="isCustomRangeSync" editorType="dxRadioGroup" editorOptions={customRangeEditorOptions}>
                    <Label visible={false} />
                        </SimpleItem>
                </GroupItem>
                <GroupItem colCount={2} colSpan={2}>
                    <SimpleItem dataField="dateFrom" editorType="dxDateBox" editorOptions={dateBoxEditorOptions}>
                        <Label text={t("#_receptionsyncpopup_6")} />
                        <Template name='renderEventIcon' render={renderEventIcon} />
                    </SimpleItem>
                    <SimpleItem dataField="dateTo" editorType="dxDateBox" editorOptions={dateBoxEditorOptions}>
                        <Label text={t("#_receptionsyncpopup_7")} />
                        <Template name='renderEventIcon' render={renderEventIcon} />
                    </SimpleItem>
                </GroupItem>
            </Form>
        </div>,
        toolbarItems: popupToolbarItems,
        title: t("#_receptionsyncpopup_5"),
        width: "900px",
        height: "400px",
        isVisible: isVisible,
        setIsVisible: setIsVisible,
        setBlockAutoRefresh: setBlockAutoRefresh
    })

    return (
        <>
            {popup.popup}
        </>
    )
}

export default ReceptionSyncPopup
