import { TOKENS } from "../customTokens";
import { EditorType } from "../model";

export const applyCustomEditorRules = (tokensDictionary: { [x: string]: any; }): { [x: string]: any; } => {

    const invoiceType = tokensDictionary["Fa.RodzajFaktury"];
    const editorType = tokensDictionary[TOKENS._EditorType]
    const keys = Object.keys(tokensDictionary);
    let ret = { ...tokensDictionary }
    ConnectSubject2WithCorrection(editorType, invoiceType, keys, ret);
    return ret;
}

// For the correction created without the original invoice in KSeF, 
// we need to link the "Podmiot2" section with the corresponding one in "Podmiot2K" using the unique "IDNabywcy".
const ConnectSubject2WithCorrection = (editorType: any, invoiceType: any, keys: string[], ret: { [x: string]: any; }) => {
    if (editorType === EditorType.Vat && invoiceType === "KOR") {
        console.log("Editor Type is NEW");
        if (keys.some(k => k.startsWith("Fa.Podmiot2K[0]"))) {
            ret["Podmiot2.IDNabywcy"] = ret["Fa.Podmiot2K[0].IDNabywcy"] = "Podmiot2";
        }
    }
}