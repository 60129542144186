import { useState, useRef, useCallback } from 'react';
import Form, {
  Item,
  Label,
  ButtonItem,
  ButtonOptions,
  RequiredRule
} from 'devextreme-react/form';
import LoadIndicator from 'devextreme-react/load-indicator';
import notify from 'devextreme/ui/notify';
import { useAuth } from '../../contexts/auth';
import 'layouts/single-card/single-card.scss';
import { useTranslation } from 'react-i18next';
import './login-form.scss';

const LoginForm = function () {
  const { signIn } = useAuth();
  const [loading, setLoading] = useState(false);
  const formData = useRef({});
  const { t } = useTranslation()

  const loginEditorOptions = { stylingMode: 'filled', placeholder: 'Login', mode: 'form-text' };
  const passwordEditorOptions = { stylingMode: 'filled', placeholder: t("#_login-form_5"), mode: 'password' };

  const onSubmit = useCallback(async (e) => {
    e.preventDefault();
    const { login, password } = formData.current;
    setLoading(true);

    await signIn(login, password).catch(() => {
      setLoading(false);
      notify(t("#_login-form_1"), 'error', 2000);
    })

  }, [signIn]);

  return (
    <form className={'login-form'} onSubmit={onSubmit}>
      <Form formData={formData.current} disabled={loading}>
        <Item
          dataField={'login'}
          editorType={'dxTextBox'}
          editorOptions={loginEditorOptions}
        >
          <RequiredRule message={t("#_login-form_2")} />
          <Label visible={false} />
        </Item>
        <Item
          dataField={'password'}
          editorType={'dxTextBox'}
          editorOptions={passwordEditorOptions}
        >
          <RequiredRule message={t("#_login-form_3")} />
          <Label visible={false} />
        </Item>
        {/* <Item
          dataField={'rememberMe'}
          editorType={'dxCheckBox'}
          editorOptions={rememberMeEditorOptions}
        >
          <Label visible={false} />
        </Item> */}
        <ButtonItem>
          <ButtonOptions
            width={'100%'}
            type={'default'}
            useSubmitBehavior={true}
          >
            <span className="dx-button-text">
              {
                loading
                  ? <LoadIndicator width={'24px'} height={'24px'} visible={true} />
                  : t("#_login-form_4")
              }
            </span>
          </ButtonOptions>
        </ButtonItem>
      </Form>
    </form>
  );
}

export default LoginForm
