type KeyValuePair = {
    key: string | undefined,
    value: any
}

export const findValue = (dictionary: { [key: string]: any }, targetKey: string): KeyValuePair => {
    const normalizedTargetKey = targetKey.toLowerCase();

    for (const key in dictionary) {
        const normalizedKey = key.toLowerCase();

        if (normalizedKey === normalizedTargetKey) {
            return { key: key, value: dictionary[key] };
        }
    }

    return { key: undefined, value: undefined };
}
