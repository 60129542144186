import 'devextreme-react/autocomplete';
import DataGrid, {
  Paging,
  FilterRow,
  Scrolling,
  Selection
} from 'devextreme-react/data-grid';
import DropDownBox from 'devextreme-react/drop-down-box';

const privilegesColumns = t => [{ dataField: 'name', caption: t("#_privilegesdropdown_1") }, { dataField: 'description', caption: t("#_privilegesdropdown_3") }];


const PrivilegesDropDown = ({ value, setValue, allPrivileges, t }) => {
  const privilegesRender = (value, setValue) => {

    return (
      <DataGrid
        dataSource={allPrivileges}
        columns={privilegesColumns(t)}
        hoverStateEnabled={true}
        selectedRowKeys={value}
        onSelectionChanged={e => {
          setValue((e.selectedRowKeys.length && e.selectedRowKeys) || [])
        }}
        height="100%">
        <Selection
          allowSelectAll={false}
          deferred={false}
          mode='multiple' 
          selectAllMode='allPages'
          showCheckBoxesMode='always'
        />
        <Scrolling mode="virtual" preloadEnabled={true}/>
        <Paging enabled={true} pageSize={100} />
        <FilterRow visible={true} />
      </DataGrid>
    );
  }
  return <DropDownBox
    value={value}
    valueExpr="id"
    deferRendering={false}
    displayExpr="name"
    placeholder={t("#_privilegesdropdown_5")}
    showClearButton={false}
    dataSource={allPrivileges}
    contentRender={() => privilegesRender(value, setValue)}
    label={t("#_privilegesdropdown_6")}>
    {/* <Validator validationGroup={validationRolesFormGroup}>
      <RequiredRule message={t("#_privilegesdropdown_7")} />
    </Validator> */}
  </DropDownBox>
}

export default PrivilegesDropDown
