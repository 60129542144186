import { Form, GroupItem, SimpleItem, Label } from 'devextreme-react/form';
import { Template } from 'devextreme-react/core/template';
import { useEffect, useState } from 'react';

const labelMode = 'outside'
const labelLocation = 'top'
const showColonAfterLabel = false
const minColWidth = 300

const FailureAddEdit = ({
    failure,
    formRef,
    t,
    getFirstError, 
    noErrors, 
    clearError,
    failureKindsDataSource,
    failureModesDataSource,
    failureTypesDataSource,
    edit,
    setFailure,
    makeAsyncDataSource,
    availableFailureTypesDataSource,
    setAvailableFailureTypesDataSource
}) => {
    const getEditorOptions = (dataField, dataSource, readOnly) => {
      const options = {
        displayExpr: "description",
        valueExpr: "key",
        dataSource: dataSource,
        validationErrors: getFirstError(`${dataField}`),
        isValid: noErrors(`${dataField}`),
        readOnly: readOnly ? readOnly : false,
        validationMessageMode: 'always'
      }

      if (dataField === 'kind')
        return {...options, 
          onValueChanged: async v => {
            const allFailureTypes = (await failureTypesDataSource.load())

            if (v.value === 0)
            {
              const ds = makeAsyncDataSource(allFailureTypes)
              setAvailableFailureTypesDataSource(ds)
              setTypeEditorOptions(getEditorOptions('type', ds, readOnly))
            }
            else if (v.value === 1)
            {
              const ds = makeAsyncDataSource(allFailureTypes.filter(x => x.value !== 'Internal'))
              setAvailableFailureTypesDataSource(ds)
              setTypeEditorOptions(getEditorOptions('type', ds, readOnly))
            }

            setFailure({ ...failure, type: null })
          }}
      else
        return options
    }

    const [typeEditorOptions, setTypeEditorOptions] = useState(getEditorOptions('type', failureTypesDataSource, edit))

    useEffect(() => {
      setTypeEditorOptions(getEditorOptions('type', availableFailureTypesDataSource, edit))
    }, [availableFailureTypesDataSource, edit])

    const formFieldDataChanged = (e) => {
        if (e.dataField) {
          clearError(e.dataField)
        }
    }

    const renderEventIcon = () => {
      return (
        <>
          <div className='dx-icon dx-icon-event'></div>
        </>
      )
    }

    const getDateBoxEditorOptions = (dataField, max, readOnly) => {
      return {
        type: 'datetime', 
        pickerType: 'calendar', 
        readOnly: readOnly ? readOnly : false, 
        visible: true,
        showDropDownButton: true, 
        dropDownButtonTemplate: 'renderEventIcon',
        invalidDateMessage: t("invalidDateMessage"),
        acceptCustomValue: false,
        openOnFieldClick: true,
        showClearButton: true,
        max: max ? max : null,
        validationErrors: getFirstError(`${dataField}`),
        isValid: noErrors(`${dataField}`),
        dateSerializationFormat: 'yyyy-MM-ddTHH:mm:ssZ',
        validationMessageMode: 'always'
      }
    }

    return <>
        <Form
            id="form"
            ref={formRef}
            labelMode={labelMode}
            readOnly={false}
            showColonAfterLabel={showColonAfterLabel}
            labelLocation={labelLocation}
            minColWidth={minColWidth}
            formData={failure}
            onFieldDataChanged={formFieldDataChanged}
            className={"xfx-form"}>
            <GroupItem>
                <SimpleItem 
                    cssClass="xfx-required"
                    dataField="mode" 
                    editorType="dxSelectBox" 
                    value={failure?.mode}
                    editorOptions={getEditorOptions('mode', failureModesDataSource, true)}>
                    <Label text={t("#_Failure_ModeColumn")}/>
                </SimpleItem>
            </GroupItem>
            <GroupItem colCount={2} colSpan={1}>
                <SimpleItem
                    dataField="kind" 
                    cssClass="xfx-required" 
                    editorType="dxRadioGroup" 
                    value={failure?.kind}
                    editorOptions={getEditorOptions('kind', failureKindsDataSource, edit)}>
                    <Label text={t("#_Failure_KindColumn")} />
                </SimpleItem>
                <SimpleItem
                    dataField="type" 
                    cssClass="xfx-required" 
                    editorType="dxRadioGroup" 
                    value={failure?.type} 
                    editorOptions={typeEditorOptions}>
                    <Label text={t("#_Failure_TypeColumn")} />
                </SimpleItem>
            </GroupItem>
            <GroupItem colCount={2} colSpan={1}>
                <SimpleItem
                    dataField="dateFromUtc" 
                    cssClass="xfx-required" 
                    editorType="dxDateBox" 
                    value={failure?.dateFromUtc} 
                    editorOptions={getDateBoxEditorOptions('dateFromUtc', null, edit)}>
                    <Template name='renderEventIcon' render={renderEventIcon} />
                    <Label text={t("#_Failure_DateFromUtcColumn")} mode="floating"/>
                </SimpleItem>
                <SimpleItem
                    dataField="dateToUtc" 
                    editorType="dxDateBox" 
                    value={failure?.dateToUtc} 
                    editorOptions={getDateBoxEditorOptions('dateToUtc', new Date())}>
                    <Template name='renderEventIcon' render={renderEventIcon} />
                    <Label text={t("#_Failure_DateToUtcColumn")} mode="floating"/>
                </SimpleItem>
            </GroupItem>
            <GroupItem>
              <SimpleItem
                  dataField="annotation"  
                  editorType="dxTextArea" 
                  value={failure?.annotation} 
                  editorOptions={{
                    autoResizeEnabled: true
                  }}>
                  <Label text={t("#_Failure_AnnotationColumn")} />
              </SimpleItem>
            </GroupItem>
        </Form>
    </>
}

export default FailureAddEdit 