import { renderCreationDateCell } from 'utils/cellRenderers'

export const columns = ({ t, resetIdPeriodDataSource: resetIdPeriodDataSource, invKindDataSource }) => 
[ 
    { dataField: "Name", dataType: "string", caption: t("#_NumberingSeries_const_1"), width: "20%", visible: true },
    { dataField: "Mask", dataType: "string", caption: t("#_NumberingSeries_const_5"), width: "30%", visible: true },
    { 
      dataField: "InvoiceKind", dataType: "string", caption: t("#_NumberingSeries_const_2"), width: "10%", visible: true,
      lookupDataSource: invKindDataSource?.map(x => x.value),
      editorOptions: {
        dropDownOptions: {
          minWidth: '130px'
        }
      } 
    },
    { 
        dataField: "ResetIdPeriod", caption: t("#_NumberingSeries_const_6"), width: "15%", visible: true,
        lookupDataSource: resetIdPeriodDataSource, lookupDisplayExpr: "value", lookupValueExpr: "key",
        cellRender: (x) => resetIdPeriodCellRender(x, resetIdPeriodDataSource), 
        editorOptions: {
          dropDownOptions: {
            minWidth: '130px'
          }
        } 
      },
    { dataField: "Organizations", dataType: "object", caption: t("#_NumberingSeries_const_3"), width: "30%", 
      cellRender: x => orgatizationsRender(x), allowSorting: false, allowFiltering: true, visible: true },
    { dataField: "CreationDateUtc", dataType: "datetime", caption: t("#_NumberingSeries_const_4"), width: "15%", cellRender: renderCreationDateCell, visible: true },
    { dataField: "OrganizationIds", visible: false },
    { dataField: "Id", visible: false },
]

const orgatizationsRender = (orgList) => {
    return (
    <ul>
        {orgList?.value?.map(e => <li>{e}</li>)}
    </ul>)
}

const resetIdPeriodCellRender = (field, resetIdPeriodDataSource) => {
    const value = resetIdPeriodDataSource.find(x => x.key == field.value)?.value;
    return (<>
        {value}
    </>)
}
