import { SecurityEventLogChange, SecurityEventLogChangeType } from "./Types";


const objToStr  = (jsonData : any) =>  JSON.stringify(jsonData, null, 2)
  
export function getChanges(changes: SecurityEventLogChange[]): string[] {
    const changeList: string[] = [];

    changes.forEach(change => {
        switch (change.Type) {
            case 'Create':
                if(change.NewValue) changeList.push(`Utworzono: ${objToStr(JSON.parse(change.NewValue))}`);
                break;
            case 'Delete':
                if(change.OldValue) changeList.push(`Usunięto: ${objToStr(change.OldValue)}`);
                break;
            case 'Update':
                if (change.OldValue && change.NewValue) {
                    try {
                        const oldObj = JSON.parse(change.OldValue);
                        const newObj = JSON.parse(change.NewValue);
                        Object.keys(newObj).forEach(key => {
                            if (oldObj[key] !== newObj[key] 
                                && Array.isArray(newObj[key]) === false && typeof newObj[key] !== 'object'
                                && Array.isArray(oldObj[key]) === false && typeof oldObj[key] !== 'object' ) {
                                changeList.push(`Zaktualizowano obiekt: ${change.Name} \r\n\tPole: ${key} \r\n\t\tStara wartość: ${oldObj[key]}\r\n\t\tNowa wartość: ${newObj[key]}`);
                            }
                        });
                    } catch (error) {
                        console.error("Error parsing JSON:", error);
                    }
                } else {
                    changeList.push(`Zaktualizowano obiekt: ${change.Name}, Brak zmian lub niepoprawne dane.`);
                }
                break;
            default:
                changeList.push(`Unknown change type for Property: ${change.Name}`);
        }
    });

    return changeList;
}
