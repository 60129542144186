import 'devextreme-react/autocomplete';
import DataGrid, {
    Paging,
    FilterRow,
    Scrolling,
    Selection,
} from 'devextreme-react/data-grid';
import DropDownBox from 'devextreme-react/drop-down-box';

const AnsiCodesColumns = ({ t }) => [
    { dataField: 'description', caption: t("#_ansiCodesdropdown_4") },
    { dataField: 'key', caption: t("#_ansiCodesdropdown_3") }, 
    { dataField: 'value', caption: t("#_ansiCodesdropdown_4"), visible: false } 
]

const ansiCodesDownOptions = {
    minWidth: '400px'
}

const AnsiCodesDropDown = ({ value, setValue, ansiCodesDataSource, t }) => {
    const ansiCodesGridRender = (value, setValue) => {
        return (
                <DataGrid
                    className='dataGrid'
                    dataSource={ansiCodesDataSource}
                    columns={AnsiCodesColumns({ t })}
                    hoverStateEnabled={true}
                    selectedRowKeys={value}
                    onSelectionChanged={e => {
                        setValue((e.selectedRowKeys.length && e.selectedRowKeys) || [])
                    }}
                    height='100%'>
                    <Selection mode="single" />
                    <Scrolling mode='virtual' preloadEnabled={true}/>
                    <Paging enabled={true} pageSize={100} />
                    <FilterRow visible={true} />
                </DataGrid>
        )
    }

    return <DropDownBox
        width={'400px'}
        label={t("#_ansiCodesdropdown_2")}
        value={value}
        valueExpr='key'
        deferRendering={false}
        displayExpr='description'
        placeholder={t('#_ansiCodesdropdown_1')}
        showClearButton={true}
        dataSource={ansiCodesDataSource}
        onSelectionChanged={e => {
            setValue(e.value || [])
        }}
        onValueChanged={e => {
            setValue(e.value || [])
        }}
        contentRender={() => ansiCodesGridRender(value, setValue)}
        dropDownOptions={ansiCodesDownOptions}
    />
}

export default AnsiCodesDropDown