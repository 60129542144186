import { submit } from "./actions"
import i18n from 'localization/i18n';

const t = i18n.t

export const userInit = {
  Id: null,
  Login: null,
  Name: null,
  FirstName: null,
  LastName: null,
  Email: null,
  Type: 0,
  Admin: false,
  UserRoles: [],
  RoleIds: [],
  Disabled: false,
  Secret: null
}

export const labelMode = 'static'
export const labelLocation = 'left'
export const showColonAfterLabel = false
export const minColWidth = 300
export const colCount = 1

export const gridColumns = [{ dataField: 'name', caption: t("#_const_56") }, { dataField: 'description', caption: t("#_const_58") }]

export const editPopupTitle = t("#_const_59")
export const addPopupTitle = t("#_const_60")

export const validationFirstFormGroup = "validationFirstFormGroup"
export const validationSecondFormGroup = "validationSecondFormGroup"

export const closeButtonOptions = ({ setVisible }) => {
    return {
      text: t("#_const_61"),
      onClick: () => setVisible(false)
    }
}

export const saveButtonOptions = ({ vgFirstForm, vgSecondForm, userEdit, user, setVisible, dataGridRef, setReloadKey, t }) => {
    return {
        text: t("#_const_62"),
        onClick: () => submit({ vgFirstForm, vgSecondForm, userEdit, user, setVisible, dataGridRef, setReloadKey, t }),
        useSubmitBehavior: true
    }
}

export const columns = ({ t }) => 
[ 
    { key: "Id", dataField:"Id", dataType: "number", caption: "ID", width: "30%", visible: false },
    { key: "Login", dataField:"Login", dataType: "string", caption: "Login", visible: false },
    { key: "FirstName", dataField: "FirstName", dataType: "string", caption: t("#_users_34"), width:"10%" },
    { key: "LastName", dataField: "LastName", dataType: "string", caption: t("#_users_37"), width:"10%" },
    { key: "Name", dataField: "Name", dataType: "string", caption: t("#_users_38"), width:"10%" },
    { key: "Email", dataField: "Email", dataType: "string", caption: t("#_users_39"), width:"10%" },
    { key: "Roles", dataField: "Roles", dataType: "string", caption: t("#_users_42") },
    { key: "Admin", dataField: "Admin", dataType: "boolean", caption: t("#_users_44"), width:"120px" },
    { key: "Token", dataField: "Token", dataType: "string", caption: t("#_users_45"), width:"270px" },
    { key: "Disabled", dataField: "Disabled", dataType: "boolean", caption: t("#_users_50"), width:"120px" },
    { key: "RoleIds", dataField:"RoleIds", visible: false },
    { key: "Type", dataField:"Type", visible: false },
    { key: "TypeValue", dataField:"TypeValue", visible: false },
]
