import { loginRequest } from "../authConfig";
import { InteractionRequiredAuthError, CacheLookupPolicy } from "@azure/msal-browser";

export const AadGetTokenData = (instance, account) => {
    let getTokenData = null

    getTokenData = async () => {
        let tokenData = ''
        const request = {
            ...loginRequest,
            account: account
        };
        await instance.acquireTokenSilent(request)
            .then(x => {
                tokenData = x
            })
            .catch(x => {
                if (x instanceof InteractionRequiredAuthError) {
                    instance.acquireTokenRedirect(request);
                }
            })

        return tokenData
    }

    return getTokenData;
};

export const AADGetTokenDataForce = async (instance, account) => {
    let tokenData = ''
    const request = {
        ...loginRequest,
        account: account,
        forceRefresh: true,
        cacheLookupPolicy: CacheLookupPolicy.RefreshTokenAndNetwork
    };
    await instance.acquireTokenSilent(request)
        .then(x => {
            tokenData = x
        })
        .catch(x => {
            if (x instanceof InteractionRequiredAuthError) {
                instance.acquireTokenRedirect(request);
            }
        })

    return tokenData;
};

export const AadLogout = async (instance, accounts) => {
    await instance.logoutRedirect({
        account: accounts[0]
    });
}