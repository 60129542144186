import { Form, SimpleItem, Label, GroupItem } from 'devextreme-react/form';
import { useTranslation } from 'react-i18next';
import useFormErrors from 'utils/useFormErrors';
import { useEffect, useState, useRef } from "react"
import { XfXApi } from "../../../api/XfXApi"
import ScrollView from 'devextreme-react/scroll-view';
import GenericPopup from "components/popup/GenericPopup"
import { Button } from 'devextreme-react/button';
import notify from "devextreme/ui/notify"
import {
  DataGrid, Column, Editing, Texts, Scrolling
} from 'devextreme-react/data-grid';
import { commonPopupToolbarItems } from 'components/popup/PopupTools';
import { Switch } from 'devextreme-react';
import { useAuth } from 'contexts/auth';

const colCount = 3
const colSpan = 3

const AddEditPopup = ({ 
    setVisible, popupTitle,
    organizationEdit, organizationId, organizationNip,
    dataGridOrganizations, visible, setReloadKey, setBlockAutoRefresh,
    organizationHasAnyInvoices
}) => {

  const bankAccountsGridRef = useRef(null);
  const { getFirstError, noErrors, setErrors, clearError } = useFormErrors();
  const [types, setTypes] = useState([])
  const [euCountryPrefixes, setEuCountryPrefixes] = useState([])
  const [euCountryPrefixesAcceptable, setEuCountryPrefixesAcceptable] = useState([])
  const [countryCodes, setCountryCodes] = useState([])
  const [nipEditDisabled, setNipEditDisabled] = useState(false)
  const { t } = useTranslation()
  const { hasPrivilege } = useAuth();
  const [organization, setOrganization] = useState({
    nip: "",
    fullName: "",
    type: 0,
    krs: "",
    regon: "",
    countryPrefix: 0,
    eoriNo: "",
    invoiceFooter: "",
    bdo: "",
    mapping: null,
    address: {
      id: null,
      countryCode: null,
      gln: "",
      adresL1: "",
      adresL2: ""
    },
    correspondenceAddress: {
      id: null,
      countryCode: null,
      gln: "",
      adresL1: "",
      adresL2: ""
    },
    organizationBankAccounts: [],
    isActive: true
  })

  const saveButtonOptions = {
    text: t("#_addeditpopup_3"),
    onClick: async () => {
      let bankAccountsGrid = bankAccountsGridRef.current.instance;
      await bankAccountsGrid.saveEditData();
      await submit({ organizationEdit: organizationEdit, organization: organization, dataGridOrganizations: dataGridOrganizations, t, setReloadKey })
    }
  };

  const cancelButtonOptions = {
    text: t("#_addeditpopup_4"),
    onClick: () => {
      popup.close()
    }
  }

  const submit = async ({ organizationEdit, organization, dataGridOrganizations, t, setReloadKey }) => {
    try {
      const response = organizationId ?
        await XfXApi.Organization.apiTenantIdOrganizationIdPut(organizationId, XfXApi.GetTenantId(), organization) :
        await XfXApi.Organization.apiTenantIdOrganizationPost(XfXApi.GetTenantId(), organization)

      if (response.status === 200) {
        notify({
          message: response?.data?.messages ? response.data.messages.join('\r\n') : `${t("#_organization_form_1")} ${organizationEdit ? t("#_actions_16") : t("#_actions_17")}`,
          position: {
            my: 'center bottom',
            at: 'center bottom',
          },
        }, 'success', 5000);
        popup.close()
        setReloadKey(oldVal => oldVal + 1)
        dataGridOrganizations.current.instance.clearSelection()
      }
    } catch (error) {
      if (error.response.status === 400) {
        notify({
          message: `${t("#_organization_form_2")} ${organizationEdit ? t("#_actions_18") : t("#_actions_19")} ${t("#_organization_form_3")}. ${t("#_organization_form_4")}: \r\n \r\n` + error.response.data.errors.join('\r\n'),
          position: {
            my: 'center bottom',
            at: 'center bottom',
          },
        }, 'error', 10000);
      }
      else if (error.response.status === 422) {
        setErrors(error.response.data);
      }
    }
  }

  const formFieldDataChanged = (e) => {
    if (e.dataField) {
      clearError(e.dataField);
    }
  }

  useEffect(() => {
    const loadData = async () => {
      const typesRes = await XfXApi.Organization.apiTenantIdOrganizationOrganizationTypesGet(XfXApi.GetTenantId());
      const countryCodesRes = await XfXApi.Dictionary.apiTenantIdDictionaryCountryCodesGet(XfXApi.GetTenantId());
      const euPrefixesRes = await XfXApi.Dictionary.apiTenantIdDictionaryEuCountryPrefixesGet(XfXApi.GetTenantId());

      setEuCountryPrefixes(euPrefixesRes.data);
      setEuCountryPrefixesAcceptable(euPrefixesRes.data.filter(x => x.value === '' || x.value === 'PL'))
      setTypes(typesRes.data);
      setCountryCodes(countryCodesRes.data);
    }

    loadData();
    popup.show();
  }, [])

  useEffect(() => {

    setOrganization(oldVal => ({
      ...oldVal,
      countryPrefix: null,
      address: {
        ...oldVal.address,
        countryCode: null,
      },
      correspondenceAddress: {
        ...oldVal.correspondenceAddress,
        countryCode: null,
      }
    }))

    if (organizationId) {
      XfXApi.Organization.apiTenantIdOrganizationGet(XfXApi.GetTenantId(), organizationId).then(org => {
        org.data.countryPrefixStr !== 'PL' && org.data.countryPrefixStr !== '' 
          ? setOrganization({ ...org.data, countryPrefix: null }) 
          : setOrganization(org.data)
      })

      if (organizationHasAnyInvoices === false)
        setNipEditDisabled(false)
      else
        setNipEditDisabled(true)
    }
  }, [organizationHasAnyInvoices, organizationId, organizationNip])

  const addEmptyBankAccount = () => bankAccountsGridRef.current.instance.addRow();

  const popupToolbarItems = commonPopupToolbarItems({
    t: t,
    saveButtonOptions: saveButtonOptions,
    cancelButtonOptions: cancelButtonOptions
  })

  const popup = GenericPopup({
    onHiding: () => popup.close(),
    content:
      <div>
        <ScrollView>
          <Form
            id="form"
            labelMode="static"
            showColonAfterLabel={false}
            labelLocation="left"
            minColWidth={300}
            colCount={colCount}
            onFieldDataChanged={formFieldDataChanged}
            formData={organization}>
            <GroupItem caption={t("#_organization_form_5")} colCount={colCount} colSpan={colSpan}>
              <SimpleItem dataField="countryPrefix" editorType="dxSelectBox" value={organization.countryPrefix}
                editorOptions={{
                  key: "key",
                  valueExpr: "key",
                  displayExpr: "value",
                  dataSource: euCountryPrefixesAcceptable,
                  value: organization.countryPrefix,
                  validationErrors: getFirstError("countryPrefix"),
                  isValid: noErrors("countryPrefix"),
                }}>
                <Label text={t("#_organization_form_6")} />
              </SimpleItem>
              <SimpleItem dataField="eoriNo" editorType="dxTextBox" value={organization.isActive}>
                <Label text={t("#_organization_form_7")} />
              </SimpleItem>
              <SimpleItem>
                <div className="dx-field" style={{ width: "auto" }}>
                  <div className="dx-field-label">{t("organization_form_is_active")}</div>
                  <div className="dx-field-value" style={{ paddingRight: 50 }}>
                    <Switch
                      value={organization.isActive}
                      onValueChanged={(e) => setOrganization(oldVal => ({ ...oldVal, isActive: e.value }))}
                      disabled={!hasPrivilege('organization_state')}
                    />
                  </div>
                </div>
              </SimpleItem>
            </GroupItem>
            <GroupItem caption={t("#_organization_form_8")} colCount={colCount} colSpan={colSpan}>
              <SimpleItem dataField="nip" editorType="dxTextBox" value={organization.nip}
                editorOptions={{
                  validationErrors: getFirstError("nip"),
                  isValid: noErrors("nip"),
                  disabled: nipEditDisabled
                }}
                cssClass="xfx-required"
              >
                <Label text={t("#_organization_form_9")} />
              </SimpleItem>
              <SimpleItem dataField="fullName" editorType="dxTextBox" value={organization.fullName}
                editorOptions={{
                  validationErrors: getFirstError("fullName"),
                  isValid: noErrors("fullName")
                }}
                cssClass="xfx-required"
              >
                <Label text={t("#_organization_form_10")} />
              </SimpleItem>
              <SimpleItem dataField="type" editorType="dxSelectBox" value={organization.type} cssClass="xfx-required"
                editorOptions={{
                  key: "id",
                  dataSource: types,
                  displayExpr: "description",
                  valueExpr: "id",
                  value: organization.type,
                  validationErrors: getFirstError("type"),
                  isValid: noErrors("type")
                }}>
                <Label text={t("#_addeditpopup_24")} />
              </SimpleItem>
              <SimpleItem dataField="mapping" editorType="dxTextBox" value={organization.mapping}>
                <Label text={t("#_organization_form_35")} />
              </SimpleItem>
            </GroupItem>

            <GroupItem caption={t("#_organization_form_12")} colCount={2} colSpan={3}>
              <SimpleItem dataField="address.countryCode" editorType="dxSelectBox" value={organization.address.countryCode}
                editorOptions={{
                  key: "key",
                  valueExpr: "key",
                  displayExpr: "value",
                  dataSource: countryCodes,
                  value: organization.address.countryCode
                }}>
                <Label text={t("#_organization_form_13")} />
              </SimpleItem>
              <SimpleItem dataField="address.gln" editorType="dxTextBox" value={organization.address.gln}>
                <Label text={t("#_organization_form_23")} />
              </SimpleItem>
              <SimpleItem dataField="address.adresL1"
                editorType="dxTextArea"
                editorOptions={{ height: 150, maxLength: 512 }}
                value={organization.address.adresL1}>
                <Label text={t("#_organization_form_101")} />
              </SimpleItem>
              <SimpleItem dataField="address.adresL2"
                editorType="dxTextArea"
                editorOptions={{ height: 150, maxLength: 512 }}
                value={organization.address.adresL2}>
                <Label text={t("#_organization_form_102")} />
              </SimpleItem>
            </GroupItem>

            <GroupItem caption={t("#_organization_form_24")} colCount={2} colSpan={3}>
              <SimpleItem dataField="correspondenceAddress.countryCode" editorType="dxSelectBox" value={organization.correspondenceAddress.countryCode}
                editorOptions={{
                  key: "key",
                  valueExpr: "key",
                  displayExpr: "value",
                  dataSource: countryCodes,
                  value: organization.correspondenceAddress.countryCode
                }}>
                <Label text={t("#_organization_form_13")} />
              </SimpleItem>
              <SimpleItem dataField="correspondenceAddress.gln" editorType="dxTextBox" value={organization.correspondenceAddress.gln}>
                <Label text={t("#_organization_form_23")} />
              </SimpleItem>
              <SimpleItem dataField="correspondenceAddress.adresL1"
                editorType="dxTextArea"
                editorOptions={{ height: 150, maxLength: 512 }}
                value={organization.correspondenceAddress.adresL1}>
                <Label text={t("#_organization_form_103")} />
              </SimpleItem>
              <SimpleItem dataField="correspondenceAddress.adresL2"
                editorType="dxTextArea"
                editorOptions={{ height: 150, maxLength: 512 }}
                value={organization.correspondenceAddress.adresL2}>
                <Label text={t("#_organization_form_104")} />
              </SimpleItem>
            </GroupItem>

            <GroupItem caption={t("#_organization_form_25")} colSpan={colSpan}>
              <Button icon="plus" stylingMode="outlined" style={{ marginBottom: '10px' }} onClick={() => addEmptyBankAccount()} />
              <DataGrid
                ref={bankAccountsGridRef}
                showBorders={true}
                dataSource={organization.organizationBankAccounts}
                repaintChangesOnly={true}
              >
                <Editing
                  refreshMode={"reshape"}
                  mode="cell"
                  allowAdding={true}
                  allowDeleting={true}
                  allowUpdating={true}
                >
                  <Texts
                    confirmDeleteTitle={t("#_DeletionPopup_1")}
                    confirmDeleteMessage={t("#_DeletionPopup")}
                  />
                </Editing>
                <Scrolling
                  mode="virtual"
                />
                <Column dataField="rbplNo" caption={t("#_organization_form_28")}>
                </Column>
                <Column dataField="bankName" caption={t("#_organization_form_29")}>
                </Column>
                <Column dataField="description" caption={t("#_organization_form_30")}>
                </Column>
              </DataGrid>
            </GroupItem>

            <GroupItem caption={t("#_organization_form_31")} colCount={colCount} colSpan={colSpan}>
              <SimpleItem dataField="invoiceFooter" editorType="dxTextBox" value={organization.invoiceFooter}>
                <Label text={t("#_organization_form_31")} />
              </SimpleItem>
              <SimpleItem dataField="krs" editorType="dxTextBox" value={organization.krs}>
                <Label text={t("#_organization_form_32")} />
              </SimpleItem>
              <SimpleItem dataField="regon" editorType="dxTextBox" value={organization.regon}>
                <Label text={t("#_organization_form_33")} />
              </SimpleItem>
              <SimpleItem dataField="bdo" editorType="dxTextBox" value={organization.bdo}>
                <Label text={t("#_organization_form_34")} />
              </SimpleItem>
            </GroupItem>
          </Form>
        </ScrollView>
      </div>,
    toolbarItems: popupToolbarItems,
    title: popupTitle,
    width: "1250px",
    height: "750px",
    isVisible: visible,
    setIsVisible: setVisible,
    setBlockAutoRefresh: setBlockAutoRefresh
  })

  return (
    <>
      {popup.popup}
    </>
  )
}

export default AddEditPopup
