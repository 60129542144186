import {
  renderSaleDateCell,
  renderReceivedDateCell,
  CurrencyFormat,
  renderAcceptanceStatusCell,
  DateFormat, DateTimeFormat,
  DateFormat2,
  renderTrueFalseCell,
  renderBusinessErrorDetail,
  renderSubject3role,
  renderDraftStatusCell,
  renderCreationDateCell,
  renderModificationDateCell
} from 'utils/cellRenderers'

const subject3Prefix = 'subject3-'
const processingStatusPrefix = 'processing-status-'
const processingModePrefix = 'processing-mode-'

const tagsColumns = ({ t }) => [
  { dataField: "Tag1", dataType: "string", caption: t("column-tag1"), width: "auto" },
  { dataField: "Tag2", dataType: "string", caption: t("column-tag2"), width: "auto" },
  { dataField: "Tag3", dataType: "string", caption: t("column-tag3"), width: "auto" },
  { dataField: "Tag4", dataType: "string", caption: t("column-tag4"), width: "auto" },
  { dataField: "Tag5", dataType: "string", caption: t("column-tag5"), width: "auto" },
  { dataField: "Tag6", dataType: "string", caption: t("column-tag6"), width: "auto" },
  { dataField: "Tag7", dataType: "string", caption: t("column-tag7"), width: "auto" },
  { dataField: "Tag8", dataType: "string", caption: t("column-tag8"), width: "auto" },
  { dataField: "Tag9", dataType: "string", caption: t("column-tag9"), width: "auto" },
  { dataField: "Tag10", dataType: "string", caption: t("column-tag10"), width: "auto" },
]
const mapLookupDataSource = (t, dataSource, prefix) => {
  return dataSource?.map(({ key, value }) => ({ name: value, description: t(`${prefix}${value.toLowerCase()}`) }))
}

const lookupDataSource = (t, dataSource, prefix) => {
  return {
    store: {
      type: 'array',
      data: mapLookupDataSource(t, dataSource, prefix),
      key: 'name'
    },
  }
}

const trueFalseDataSource = (t) => {
  return {
    store: {
      type: 'array',
      data: [
        { name: true, description: t("#_cellrenderers_5") },
        { name: false, description: t("#_cellrenderers_6") }
      ],
      key: 'name'
    },
  }
}

const isDispatchLookupDataSource = (t) => {
  return {
    store: {
      type: 'array',
      data: [
        { name: true, description: t("#_cellrenderers_3") },
        { name: false, description: t("#_cellrenderers_4") },
      ],
      key: 'name'
    },
  }
}

const manualAcceptLabelsDataStore = (t) => {
  return {
    store: {
      type: 'array',
      data: [
        { name: 'NotRequired', description: t("manual-accept-not-required") },
        { name: 'AwaitingAccept', description: t("manual-accept-awaiting") },
        { name: 'Accepted', description: t("manual-accept-confirmed") },
        { name: 'Rejected', description: t("manual-accept-rejected") },
      ],
      key: 'name'
    },
  }
}
const schemaVersionColumn = ({ t }) => [{ dataField: "SchemaVersion", dataType: "string", caption: t("column-schema-version"), width: "auto" }]

export const sendInvoiceColumns = ({ t, invKindDataSource, subject3RoleDataSource, currencyCodesDataSource, processingStatusDataSource, saleDateTagsDataSource, processingModeDataSource }) => [
  { dataField: "Subject1VatNumber", dataType: "string", caption: t("column-subject1vatnumber"), width: "auto", showInColumnChooser: true, visible: true },
  { dataField: "Subject1Name", dataType: "string", caption: t("column-subject1name"), width: "auto", visible: true },
  { dataField: "Subject2VatNumber", dataType: "string", caption: t("column-subject2vatnumber"), width: "auto", visible: true },
  { dataField: "Subject2Name", dataType: "string", caption: t("column-subject2name"), width: "auto", visible: true },
  { dataField: "Subject3VatNumber", dataType: "string", caption: t("column-subject3vatnumber"), width: "auto", visible: true },
  { dataField: "Subject3Name", dataType: "string", caption: t("column-subject3name"), width: "auto", visible: true },
  {
    dataField: "IsDispatch", dataType: "boolean", caption: t("column-isdispatch"),
    width: "130px", visible: true, lookupValueExpr: "name", lookupDisplayExpr: "description", lookupDataSource: isDispatchLookupDataSource(t),
    editorOptions: {
      dropDownOptions: {
        minWidth: '150px'
      }
    }
  },
  {
    dataField: "ProcessingMode", dataType: "string", caption: t("column-processing-mode"), width: "auto", visible: true,
    lookupValueExpr: "name", lookupDisplayExpr: "description", lookupDataSource: lookupDataSource(t, processingModeDataSource, processingModePrefix),
    editorOptions: {
      dropDownOptions: {
        minWidth: '250px'
      }
    }
  },
  { dataField: "DateReceivedUtc", dataType: "date", caption: t("column-datereceivedutc"), width: "auto", cellRender: renderReceivedDateCell, format: DateTimeFormat, visible: true },
  { dataField: "Number", dataType: "string", caption: t("column-number"), width: "auto", visible: true },
  { dataField: "IssueDate", dataType: "date", caption: t("column-issuedate"), width: "auto", format: DateFormat, visible: true },
  { dataField: "SaleDate", dataType: "date", caption: t("column-saledate"), width: "auto", cellRender: renderSaleDateCell, visible: true },
  {
    dataField: "SaleDateTag", dataType: "string", caption: t("column-other-features"), width: "auto", visible: true,
    lookupDataSource: saleDateTagsDataSource?.map(x => x.value),
    editorOptions: {
      dropDownOptions: {
        minWidth: '120px'
      }
    }
  },
  { dataField: "TotalAmount", dataType: "number", caption: t("column-totalamount"), width: "auto", format: CurrencyFormat, visible: true },
  {
    dataField: "CurrencyCode", dataType: "string", caption: t("column-currencycode"), width: "100px", visible: true,
    lookupDataSource: currencyCodesDataSource?.map(x => x.value),
    editorOptions: {
      dropDownOptions: {
        minWidth: '130px'
      }
    }
  },
  {
    dataField: "Kind", dataType: "string", caption: t("column-kind"), width: "100px", visible: true,
    lookupDataSource: invKindDataSource?.map(x => x.value),
    editorOptions: {
      dropDownOptions: {
        minWidth: '130px'
      }
    }
  },
  { dataField: "KsefDate", dataType: "date", caption: t("column-ksefdate"), width: "auto", format: DateFormat, visible: true },
  { dataField: "KsefNumber", dataType: "string", caption: t("column-ksefnumber"), width: "auto", visible: true },
  {
    dataField: "Status", dataType: "string", caption: t("column-status"), width: "auto", visible: true,
    lookupValueExpr: "name", lookupDisplayExpr: "description", lookupDataSource: lookupDataSource(t, processingStatusDataSource, processingStatusPrefix),
    editorOptions: {
      dropDownOptions: {
        minWidth: '250px'
      }
    }
  },
  { dataField: "FKSystem", dataType: "string", caption: t("column-fksystem"), width: "auto", visible: true },
  {
    dataField: "Subject3Role", dataType: "string", caption: t("column-subject3role"), width: "auto", visible: true,
    lookupValueExpr: "name", lookupDisplayExpr: "description", lookupDataSource: lookupDataSource(t, subject3RoleDataSource, subject3Prefix),
    cellRender: x => renderSubject3role(x, t, subject3RoleDataSource, subject3Prefix),
    editorOptions: {
      dropDownOptions: {
        minWidth: '250px'
      }
    }
  },

  { dataField: "ManualAcceptStatus", dataType: "string", caption: t("column-requiresmanualaccept"), width: "auto", visible: true, lookupValueExpr: "name", lookupDisplayExpr: "description", lookupDataSource: manualAcceptLabelsDataStore(t), },
  {
    dataField: "SelfInvoicing", dataType: "boolean", caption: t("column-selfinvoicing"), width: "auto", visible: true, showInColumnChooser: true,
    lookupDataSource: trueFalseDataSource(t), cellRender: x => renderTrueFalseCell(x.data?.SelfInvoicing, t),
    lookupValueExpr: "name", lookupDisplayExpr: "description"
  },
  { dataField: "SessionId", dataType: "string", caption: t("column-sessionid"), width: "auto", visible: false, showInColumnChooser: false, allowFiltering: false },
  { dataField: "SyncProcessId", dataType: "string", caption: t("column-syncprocessid"), width: "auto", visible: false, showInColumnChooser: false, allowFiltering: false },
  { dataField: "BatchId", dataType: "string", caption: t("column-batchid"), width: "auto", visible: false, showInColumnChooser: false, allowFiltering: false },
  { dataField: "KsefContext", dataType: "string", caption: t("column-ksefcontext"), width: "auto", visible: false, showInColumnChooser: false, allowFiltering: false },
  { dataField: "Subject3RoleDescription", dataType: "string", caption: t("column-subject3roledescription"), width: "auto", visible: false },
  {
    dataField: "Subject3RoleOther", dataType: "boolean", caption: t("column-subject3RoleOther"), width: "auto", visible: false, showInColumnChooser: true,
    lookupDataSource: trueFalseDataSource(t), cellRender: x => renderTrueFalseCell(x.data?.Subject3RoleOther, t),
    lookupValueExpr: "name", lookupDisplayExpr: "description",
    editorOptions: {
      dropDownOptions: {
        minWidth: '200px'
      }
    }
  },
  {
    dataField: "ReceivedByExternalSystem", dataType: "boolean", caption: t("Column-receivedbyexternalsystem"), width: "auto", visible: true,
    lookupDataSource: trueFalseDataSource(t), cellRender: x => renderTrueFalseCell(x.data?.ReceivedByExternalSystem, t),
    lookupValueExpr: "name", lookupDisplayExpr: "description",
    editorOptions: {
      dropDownOptions: {
        minWidth: '250px'
      }
    }
  },
  { dataField: "Id", dataType: "object", caption: t("Column-ksefixid"), width: "auto", visible: true },
  { dataField: "ExternalTrackingId", dataType: "string", caption: t("column-externaltrackingid"), width: "auto", visible: true },
  { dataField: "VatDate", dataType: "date", caption: t("column-vatdate"), width: "auto", format: DateFormat2, visible: true },
  {
    dataField: "ManualUpload", dataType: "boolean", caption: t("column-manualUpload"), width: "auto", visible: true, showInColumnChooser: true,
    lookupDataSource: trueFalseDataSource(t), cellRender: x => renderTrueFalseCell(x.data?.ManualUpload, t),
    lookupValueExpr: "name", lookupDisplayExpr: "description"
  },
  ...tagsColumns({ t }),
  ...schemaVersionColumn({ t })
]

export const dispatchFailedInvoiceColumns = ({ t, invKindDataSource, subject3RoleDataSource, currencyCodesDataSource, processingStatusDataSource, saleDateTagsDataSource, processingModeDataSource }) => [
  ...sendInvoiceColumns({ t, invKindDataSource, subject3RoleDataSource, currencyCodesDataSource, processingStatusDataSource, saleDateTagsDataSource, processingModeDataSource }),
  { dataField: "BusinessError", dataType: "object", caption: t("detailed-message"), width: "auto", visible: true, showInColumnChooser: true, cellRender: renderBusinessErrorDetail },
]


const getMigrationNip = (x, organizationId) => {
  if (!x.data || x.data?.MigrationContext === null)
    return null;
  if (x.data.KsefContext === organizationId) {
    return x.data.MigrationContext
  }
  return x.data.KsefContext
}

export const repositoryDispatchColumns = ({ t, invKindDataSource, subject3RoleDataSource, currencyCodesDataSource, processingStatusDataSource, saleDateTagsDataSource, organizationId, organizations, processingModeDataSource, getOrganizationFullNameByNip }) => [
  ...sendInvoiceColumns({ t, invKindDataSource, subject3RoleDataSource, currencyCodesDataSource, processingStatusDataSource, saleDateTagsDataSource, processingModeDataSource }),
  { dataField: "UpoNumber", dataType: "string", caption: t("upo-number"), width: "auto", visible: true },
  ...migrationColumns({ t, organizationId, getOrganizationFullNameByNip })
]
export const receivedInvoiceColumns = ({ t, invKindDataSource, subject3RoleDataSource, currencyCodesDataSource, processingStatusDataSource, saleDateTagsDataSource }) => [
  { dataField: "Subject1VatNumber", dataType: "string", caption: t("column-subject1vatnumber"), width: "auto", visible: true },
  { dataField: "Subject1Name", dataType: "string", caption: t("column-subject1name"), width: "auto", visible: true },
  { dataField: "Subject2VatNumber", dataType: "string", caption: t("column-subject2vatnumber"), width: "auto", visible: true },
  { dataField: "Subject2Name", dataType: "string", caption: t("column-subject2name"), width: "auto", visible: true },
  { dataField: "Subject3VatNumber", dataType: "string", caption: t("column-subject3vatnumber"), width: "auto", visible: true },
  { dataField: "Subject3Name", dataType: "string", caption: t("column-subject3name"), width: "auto", visible: true },
  {
    dataField: "IsDispatch", dataType: "boolean", caption: t("column-isdispatch"),
    width: "130px", visible: true, lookupValueExpr: "name", lookupDisplayExpr: "description", lookupDataSource: isDispatchLookupDataSource(t),
    editorOptions: {
      dropDownOptions: {
        minWidth: '150px'
      }
    }
  },
  { dataField: "DateReceivedUtc", dataType: "date", caption: t("column-datereceivedutc"), width: "auto", cellRender: renderReceivedDateCell, format: DateTimeFormat, visible: true },
  { dataField: "Number", dataType: "string", caption: t("column-number"), width: "auto", visible: true },
  { dataField: "IssueDate", dataType: "date", caption: t("column-issuedate"), width: "auto", format: DateFormat, visible: true },
  { dataField: "SaleDate", dataType: "date", caption: t("column-saledate"), width: "auto", cellRender: renderSaleDateCell, visible: true },
  {
    dataField: "SaleDateTag", dataType: "string", caption: t("column-other-features"), width: "auto", visible: true,
    lookupDataSource: saleDateTagsDataSource?.map(x => x.value),
    editorOptions: {
      dropDownOptions: {
        minWidth: '120px'
      }
    }
  },
  { dataField: "TotalAmount", dataType: "number", caption: t("column-totalamount"), width: "auto", format: CurrencyFormat, visible: true },
  {
    dataField: "CurrencyCode", dataType: "string", caption: t("column-currencycode"), width: "100px", visible: true,
    lookupDataSource: currencyCodesDataSource?.map(x => x.value),
    editorOptions: {
      dropDownOptions: {
        minWidth: '130px'
      }
    }
  },
  {
    dataField: "Kind", dataType: "string", caption: t("column-kind"), width: "100px", visible: true,
    lookupDataSource: invKindDataSource?.map(x => x.value),
    editorOptions: {
      dropDownOptions: {
        minWidth: '130px'
      }
    }
  },
  { dataField: "KsefDate", dataType: "date", caption: t("column-ksefdate"), width: "auto", format: DateFormat, visible: true },
  { dataField: "KsefNumber", dataType: "string", caption: t("column-ksefnumber"), width: "auto", visible: true },
  {
    dataField: "Status", dataType: "string", caption: t("column-status"), width: "auto", visible: true,
    lookupValueExpr: "name", lookupDisplayExpr: "description", lookupDataSource: lookupDataSource(t, processingStatusDataSource, processingStatusPrefix),
    editorOptions: {
      dropDownOptions: {
        minWidth: '250px'
      }
    }
  },
  { dataField: "FKSystem", dataType: "string", caption: t("column-fksystem"), width: "auto", visible: true },
  {
    dataField: "Subject3Role", dataType: "string", caption: t("column-subject3role"), width: "auto", visible: true,
    lookupValueExpr: "name", lookupDisplayExpr: "description", lookupDataSource: lookupDataSource(t, subject3RoleDataSource, subject3Prefix),
    cellRender: x => renderSubject3role(x, t, subject3RoleDataSource, subject3Prefix),
    editorOptions: {
      dropDownOptions: {
        minWidth: '250px'
      }
    }
  },

  { dataField: "ManualAcceptStatus", dataType: "string", caption: t("column-requiresmanualaccept"), width: "auto", visible: true, lookupValueExpr: "name", lookupDisplayExpr: "description", lookupDataSource: manualAcceptLabelsDataStore(t), },
  { dataField: "SessionId", dataType: "string", caption: t("column-sessionid"), width: "auto", visible: false, showInColumnChooser: false, allowFiltering: false },
  { dataField: "BatchId", dataType: "string", caption: t("column-batchid"), width: "auto", visible: false, showInColumnChooser: false, allowFiltering: false },
  { dataField: "KsefContext", dataType: "string", caption: t("column-ksefcontext"), width: "auto", visible: false, showInColumnChooser: false, allowFiltering: false },
  {
    dataField: "SelfInvoicing", dataType: "boolean", caption: t("column-selfinvoicing"), width: "auto", visible: true, showInColumnChooser: true,
    lookupDataSource: trueFalseDataSource(t), cellRender: x => renderTrueFalseCell(x.data?.SelfInvoicing, t),
    lookupValueExpr: "name", lookupDisplayExpr: "description"
  },
  { dataField: "SyncProcessId", dataType: "string", caption: t("column-syncprocessid"), width: "auto", visible: false, showInColumnChooser: false, allowFiltering: false },
  { dataField: "Subject3RoleDescription", dataType: "string", caption: t("column-subject3roledescription"), width: "auto", visible: false },
  {
    dataField: "Subject3RoleOther", dataType: "boolean", caption: t("column-subject3RoleOther"), width: "auto", visible: false, showInColumnChooser: true,
    lookupDataSource: trueFalseDataSource(t), cellRender: x => renderTrueFalseCell(x.data?.Subject3RoleOther, t),
    lookupValueExpr: "name", lookupDisplayExpr: "description",
    editorOptions: {
      dropDownOptions: {
        minWidth: '200px'
      }
    }
  },
  {
    dataField: "ReceivedByExternalSystem", dataType: "boolean", caption: t("Column-receivedbyexternalsystem"), width: "auto", visible: true,
    lookupDataSource: trueFalseDataSource(t), cellRender: x => renderTrueFalseCell(x.data?.ReceivedByExternalSystem, t),
    lookupValueExpr: "name", lookupDisplayExpr: "description"
  },
  { dataField: "Id", dataType: "object", caption: t("Column-ksefixid"), width: "auto", visible: true },
  { dataField: "ExternalTrackingId", dataType: "string", caption: t("column-externaltrackingid"), width: "auto", visible: false, showInColumnChooser: true, allowFiltering: true },
  { dataField: "VatDate", dataType: "date", caption: t("column-vatdate"), width: "auto", format: DateFormat2, visible: true },
  {
    dataField: "ManualUpload", dataType: "boolean", caption: t("column-manualUpload"), width: "auto", visible: true, showInColumnChooser: true,
    lookupDataSource: trueFalseDataSource(t), cellRender: x => renderTrueFalseCell(x.data?.ManualUpload, t),
    lookupValueExpr: "name", lookupDisplayExpr: "description"
  },
  ...tagsColumns({ t }),
  ...schemaVersionColumn({ t })
]

export const receivedFailedInvoiceColumns = ({ t, invKindDataSource, subject3RoleDataSource, currencyCodesDataSource, processingStatusDataSource, saleDateTagsDataSource }) => [
  ...receivedInvoiceColumns({ t, invKindDataSource, subject3RoleDataSource, currencyCodesDataSource, processingStatusDataSource, saleDateTagsDataSource }),
  { dataField: "BusinessError", dataType: "object", caption: t("detailed-message"), width: "auto", visible: true, showInColumnChooser: true, cellRender: renderBusinessErrorDetail },
]

export const repositoryReceivedInvoiceColumns = ({ t, invKindDataSource, subject3RoleDataSource, currencyCodesDataSource, processingStatusDataSource, organizationId, organizations, processingModeDataSource, getOrganizationFullNameByNip }) => [
  ...receivedInvoiceColumns({ t, invKindDataSource, subject3RoleDataSource, currencyCodesDataSource, processingStatusDataSource, processingModeDataSource }),
  {
    dataField: "ProcessingMode", dataType: "string", caption: t("column-processing-mode"), width: "auto", visible: true,
    lookupValueExpr: "name", lookupDisplayExpr: "description", lookupDataSource: lookupDataSource(t, processingModeDataSource, processingModePrefix),
    editorOptions: {
      dropDownOptions: {
        minWidth: '250px'
      }
    }
  },
  { dataField: "UpoNumber", dataType: "string", caption: t("upo-number"), width: "auto", visible: true },
  ...migrationColumns({ t, organizationId, getOrganizationFullNameByNip })
]

export const sendInvoiceAcceptanceColumns = ({ organizationId, currentOrganizationDisabled, reloadGrid, userId, t, invKindDataSource, subject3RoleDataSource, currencyCodesDataSource, processingStatusDataSource, saleDateTagsDataSource, processingModeDataSource }) => [
  ...sendInvoiceColumns({ t, invKindDataSource, subject3RoleDataSource, currencyCodesDataSource, processingStatusDataSource, saleDateTagsDataSource, processingModeDataSource }).filter(x => x.dataField !== "ManualAcceptStatus"),
  { dataField: "RequiresManualAcceptCount", dataType: "number", caption: "RequiresManualAcceptCount", width: "auto", visible: false, showInColumnChooser: false, allowFiltering: false },
  { dataField: "RequiresManualAcceptUsersStr", dataType: "string", caption: "RequiresManualAcceptUsers", width: "auto", visible: false, showInColumnChooser: false, allowFiltering: false },
  { dataField: "RequiresManualAcceptUsersConfirmedStr", dataType: "string", caption: "RequiresManualAcceptUsers", width: "auto", visible: false, showInColumnChooser: false, allowFiltering: false },
  //  { dataField: "ManualAcceptStatus", dataType: "boolean", caption: t("column-requiresmanualaccept"), width: "auto", visible: false, showInColumnChooser: false, allowFiltering: false},
  {
    dataField: "AcceptanceStatusColumnForIdPurposes", caption: t("column-acceptance-status"), width: "auto", cellRender: renderAcceptanceStatusCell({ organizationId, userId, currentOrganizationDisabled, reloadGrid, t }), allowFiltering: false
  }
]

export const receivedInvoiceAcceptanceColumns = ({ organizationId, currentOrganizationDisabled, reloadGrid, userId, t, invKindDataSource, subject3RoleDataSource, currencyCodesDataSource, processingStatusDataSource, saleDateTagsDataSource }) => [
  ...receivedInvoiceColumns({ t, invKindDataSource, subject3RoleDataSource, currencyCodesDataSource, processingStatusDataSource, saleDateTagsDataSource }).filter(x => x.dataField !== "ManualAcceptStatus"),
  { dataField: "RequiresManualAcceptCount", dataType: "number", caption: "RequiresManualAcceptCount", width: "auto", visible: false, showInColumnChooser: false, allowFiltering: false },
  { dataField: "RequiresManualAcceptUsersStr", dataType: "string", caption: "RequiresManualAcceptUsers", width: "auto", visible: false, showInColumnChooser: false, allowFiltering: false },
  { dataField: "RequiresManualAcceptUsersConfirmedStr", dataType: "string", caption: "RequiresManualAcceptUsers", width: "auto", visible: false, showInColumnChooser: false, allowFiltering: false },
  //  { dataField: "ManualAcceptStatus", dataType: "boolean", caption: t("column-requiresmanualaccept"), width: "auto", visible: false, showInColumnChooser: false, allowFiltering: false},
  {
    dataField: "AcceptanceStatusColumnForIdPurposes", caption: t("column-acceptance-status"), width: "auto", cellRender: renderAcceptanceStatusCell({ organizationId, userId, currentOrganizationDisabled, reloadGrid, t }), allowFiltering: false
  }
]


export const draftColumns = ({ t, invKindDataSource, subject3RoleDataSource, currencyCodesDataSource }) => [
  { dataField: "Status", dataType: "string", caption: t("column-status"), width: "auto", visible: true, cellRender: renderDraftStatusCell(t) },
  {
    dataField: "IsValid", dataType: "boolean", caption: t("column-is-schema-valid"), width: "auto", visible: true
  },
  { dataField: "CreationDateUtc", dataType: "datetime", width: "auto", showInColumnChooser: true, visible: true, cellRender: renderCreationDateCell, caption: t("draft-column-creation-date") },
  { dataField: "ModificationDateUtc", dataType: "datetime", width: "auto", showInColumnChooser: true, visible: true, cellRender: renderModificationDateCell, caption: t("draft-column-edit-date") },
  { dataField: "Subject1VatNumber", dataType: "string", caption: t("column-subject1vatnumber"), width: "auto", showInColumnChooser: true, visible: true },
  { dataField: "Subject1Name", dataType: "string", caption: t("column-subject1name"), width: "auto", visible: true },
  { dataField: "Subject2VatNumber", dataType: "string", caption: t("column-subject2vatnumber"), width: "auto", visible: true },
  { dataField: "Subject2Name", dataType: "string", caption: t("column-subject2name"), width: "auto", visible: true },
  { dataField: "Subject3VatNumber", dataType: "string", caption: t("column-subject3vatnumber"), width: "auto", visible: true },
  { dataField: "Subject3Name", dataType: "string", caption: t("column-subject3name"), width: "auto", visible: true },
  { dataField: "Number", dataType: "string", caption: t("column-number"), width: "auto", visible: true },
  { dataField: "IssueDate", dataType: "date", caption: t("column-issuedate"), width: "auto", format: DateFormat, visible: true },
  { dataField: "SaleDate", dataType: "date", caption: t("column-saledate"), width: "auto", cellRender: renderSaleDateCell, visible: true },
  { dataField: "VatDate", dataType: "date", caption: t("column-vatdate"), width: "auto", format: DateFormat, visible: false },
  { dataField: "TotalAmount", dataType: "number", caption: t("column-totalamount"), width: "auto", format: CurrencyFormat, visible: true },
  {
    dataField: "CurrencyCode", dataType: "string", caption: t("column-currencycode"), width: "100px", visible: true,
    lookupDataSource: currencyCodesDataSource?.map(x => x.value),
    editorOptions: {
      dropDownOptions: {
        minWidth: '130px'
      }
    }
  },
  {
    dataField: "Kind", dataType: "string", caption: t("column-kind"), width: "100px", visible: true,
    lookupDataSource: invKindDataSource?.map(x => x.value),
    editorOptions: {
      dropDownOptions: {
        minWidth: '130px'
      }
    }
  },
  {
    dataField: "Subject3Role", dataType: "string", caption: t("column-subject3role"), width: "auto", visible: true,
    lookupValueExpr: "name", lookupDisplayExpr: "description", lookupDataSource: lookupDataSource(t, subject3RoleDataSource, subject3Prefix),
    cellRender: x => renderSubject3role(x, t, subject3RoleDataSource, subject3Prefix),
    editorOptions: {
      dropDownOptions: {
        minWidth: '250px'
      }
    }
  },
  {
    dataField: "SelfInvoicing", dataType: "boolean", caption: t("column-selfinvoicing"), width: "auto", visible: true, showInColumnChooser: true,
    lookupDataSource: trueFalseDataSource(t), cellRender: x => renderTrueFalseCell(x.data?.SelfInvoicing, t),
    lookupValueExpr: "name", lookupDisplayExpr: "description"
  },
  { dataField: "FKSystem", dataType: "string", caption: t("column-fksystem"), width: "auto", visible: true },
  { dataField: "Subject3RoleDescription", dataType: "string", caption: t("column-subject3roledescription"), width: "auto", visible: false },
  {
    dataField: "Subject3RoleOther", dataType: "boolean", caption: t("column-subject3RoleOther"), width: "auto", visible: false, showInColumnChooser: true,
    lookupDataSource: trueFalseDataSource(t), cellRender: x => renderTrueFalseCell(x.data?.Subject3RoleOther, t),
    lookupValueExpr: "name", lookupDisplayExpr: "description",
    editorOptions: {
      dropDownOptions: {
        minWidth: '200px'
      }
    }
  },
  { dataField: "KsefContext", visible: false, showInColumnChooser: false, allowFiltering: false },
  ...schemaVersionColumn({ t })
]

const migrationColumns = ({ t, organizationId, getOrganizationFullNameByNip }) => [
  {
    dataField: "MigrationContext", dataType: "string", caption: t("column-migratied"), width: "auto", visible: false,
    allowFiltering: false, allowSorting: false,
    cellRender: x => x.data?.MigrationContext === null ? "NIE" : "TAK"
  },
  {
    dataType: "string", caption: t("column-migrate-org-name"), width: "auto", visible: true,
    allowFiltering: false, allowSorting: false,
    cellRender: x => {
      const nip = getMigrationNip(x, organizationId)
      return nip === null ? null : getOrganizationFullNameByNip(nip)
    }
  },
  {
    id: "migration-nip", dataType: "string", caption: t("column-migrate-org-nip"), width: "auto", visible: true,
    allowFiltering: false, allowSorting: false,
    cellRender: x => {
      return getMigrationNip(x, organizationId)
    }
  },
]