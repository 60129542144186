import { useEffect, useState } from 'react'
import { useAuth } from 'contexts/auth'
import { GenericGrid, convertToColumns } from './GenericGrid'
import { GetOdataDataSource } from 'utils/dataSourceHelper'
import { useUserSettingsContext } from 'contexts/UserSettingsProvider';

export const FilterUsageTypes = {
  ODataSourceOptions: 0,
  DataGridDefaultFilterValueProp: 1
}

const GenericOdataGrid = ({ id, ref, data, endpoint, setSelectedItem, setSelectedItems, defaultFilter, defaultSort,
    filterButtons, updateDataGridFilter, multiSelectionMode, setLastUsedQueryParms, setDefaultSort, gridKey, 
    useFilterBuilder, dataSourceOptions, setDataSourceOptions, filterUsageType = FilterUsageTypes.ODataSourceOptions,
    masterDetail, odataCustomFilterFieldName, blockAutoRefresh, setBlockAutoRefresh }) => {
    const [columns, setColumns] = useState()
    const [unprocessedColumns, setUnprocessedColumns] = useState([])
    const [isAccessForbidden, setIsAccessForbidden] = useState(false)
    const { hasAnyPrivilege } = useAuth()
    const { getOrganizationContextSettings, getColumnsVisible, getColumnsFixed, getColumnsToSort, getEmptySorting, getColumnsAvailableInChooser, getColumnVisibleIndex } = useUserSettingsContext()

    useEffect(() => {
        const restoreColumnsPreferences = (toRestoreData) => {
          const cv = getColumnsVisible(id)
          const cf = getColumnsFixed(id)
          const cts = getColumnsToSort(id)
          const caic = getColumnsAvailableInChooser(id)
          const cvi = getColumnVisibleIndex(id)

          const columnVisibilityExists = cv !== undefined && cv !== null
          const columnFixedExists = cf !== undefined && cf !== null
          const columnToSortExists = cts !== undefined && cts !== null
          const columnAvailableInChooserExists = caic !== undefined && caic !== null
          const columnVisibleIndexExists = cvi !== undefined && cvi !== null
    
          return toRestoreData.map(x => {
            return {
              ...x, visible: columnVisibilityExists && columnAvailableInChooserExists && caic?.includes(x.dataField) ? cv?.includes(x.dataField) : x.visible,
              fixed: columnFixedExists && cf[x.dataField] !== undefined ? true : x.fixed,
              fixedPosition: columnFixedExists && cf[x.dataField] !== undefined ? cf[x.dataField] : x.fixedPosition,
              sortOrder: columnToSortExists && cts[x.dataField] !== undefined ? cts[x.dataField] : x.sortOrder,
              visibleIndex: columnVisibleIndexExists && cvi[x.dataField] !== undefined ? cvi[x.dataField] : x.visibleIndex
            }
          })
        }

        setUnprocessedColumns(restoreColumnsPreferences(data))
      }, [data])

    const organizationId = getOrganizationContextSettings()?.NIP
    useEffect(() => {
        if (unprocessedColumns && unprocessedColumns.length > 0) // a co jeśli serio nie ma danych?
        {
            const dataToShow = unprocessedColumns.filter(x => x.requiredPrivilege === undefined || hasAnyPrivilege(x.requiredPrivilege, organizationId))
            const dataSourceFields = ['Id', ...dataToShow.filter(x => x.dataField !== undefined).map(x => x.dataField).filter(x => x !== "")]
            const dso = GetOdataDataSource(endpoint, defaultSort,
                dataSourceFields,
                filterUsageType === FilterUsageTypes.ODataSourceOptions ? defaultFilter : undefined, //This is important if we use filter here we should avoid using filter as Devextreme datagrid prop 'defaultFilterValue'
                setLastUsedQueryParms,
                gridKey,
                setIsAccessForbidden,
                odataCustomFilterFieldName);
                
            setDataSourceOptions(dso)
            setColumns(convertToColumns(dataToShow))
        }
    }, [endpoint, unprocessedColumns, hasAnyPrivilege, defaultFilter])

    return <>
        {GenericGrid({
            id,
            ref,
            columns: columns,
            dataSource: dataSourceOptions, setSelectedItem: setSelectedItem,
            setSelectedItems,
            defaultFilter: defaultFilter, filterButtons: filterButtons,
            updateDataGridFilter: updateDataGridFilter,
            multiSelectionMode,
            gridKey: gridKey,
            setDefaultSort,
            visible: !isAccessForbidden,
            useFilterBuilder: useFilterBuilder,
            filterUsageType: filterUsageType,
            masterDetail: masterDetail,
            blockAutoRefresh: blockAutoRefresh, 
            setBlockAutoRefresh: setBlockAutoRefresh
        })}</>
}

export default GenericOdataGrid